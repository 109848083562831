import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BubbleService } from 'src/app/services/bubble.service';
import { NbComponentStatus, NbToastrService } from '@nebular/theme';
import Icons from '../../../assets/iconJson.json';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'ngx-add-edit-network-dialog',
  templateUrl: './add-edit-network-dialog.component.html',
  styleUrls: ['./add-edit-network-dialog.component.scss']
})
export class AddEditNetworkDialogComponent implements OnInit {

  icon: String = "";
  name: any;
  operation: String = "";
  iconJson: any;
  networkJson: any;
  loading: boolean;


  @Output() afterSave = new EventEmitter();

  constructor(public dialog: MatDialogRef<AddEditNetworkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bubbleService: BubbleService,
    private commonService: CommonService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
    this.iconJson = Icons.cssClass;
    this.networkJson = JSON.parse(localStorage.getItem("serviceNetwork"));

    console.log(this.data);

    if (this.data.editFlag == 0)
      this.operation = "Add";
    else
      this.operation = "Edit";

    if (this.data.editFlag == 1) {
      this.name = this.data.OperationData.name;
      this.icon = this.data.OperationData.icon;
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  showToast(status: NbComponentStatus, message) {
    this.toastrService.show(status, message, { status });
  }

  addEditProcess() {

    if (this.name == "" || this.name == undefined) {
      let message = "Please enter service name";
      this.showToast('warning', message);
      return false;
    }

    if (this.icon == "") {
      let message = "Please select service icon";
      this.showToast('warning', message);
      return false;
    }

    this.loading = true;
    let data = {};
    if (this.data.editFlag == 1) {
      data = {
        "name": this.name,
        "icon": this.icon
      }
      let arrReqData = {
        "inDescription": this.name,
        "inUpdatedUserKey": "12d190b3-3887-4576-9332-ac0603692b06",
        "inNetworkOwnerOfferingKey": this.data.OperationData.key,
        "icon": this.icon,
      }
      this.commonService.updateTierServiceOfferring(arrReqData).subscribe(result => {
        this.loading = false;
        this.addEditNode(this.networkJson, data, this.data.OperationData, 'edit');
        let message = "Service Updated";
        this.showToast('success', message);
      });
    } else {
      let arrReqData = {
        '_AxlePointKey': '',
        '_AxlePointTable': '',
        'codingSystemKey': '',
        'codingSystemSchema': '',
        'codingSystemTable': '',
        'codingSystemValue': '',
        'deleted': false,
        'description': this.name,
        'glapaccount': 0,
        'glaraccount': 0,
        'glcgsaccount': 0,
        'glinventoryAccount': 0,
        'glsalesAccount': 0,
        'icon': this.icon,
        'industryKey': '4d802423-8db6-4b13-90c8-7466fcf6db24',
        'insertedUserKey': '12d190b3-3887-4576-9332-ac0603692b06',
        'isActive': true,
        'maxpricingThreshold': 0,
        'minpricingThreshold': 0,
        'networkOwnerOfferingKey': '',
        'parentKey': this.data.OperationData.key ? this.data.OperationData.key : 'NULL',
        'systemDefault': false,
        'updatedUserKey': ''
      }
      this.commonService.addLicensedOwnerOfferings(arrReqData).subscribe(result => {
        this.loading = false;
        if (result.success === true) {
          let intLevel = 1;
          if (this.data.OperationData == 0) {
            intLevel = 1;
          } else {
            intLevel = this.data.level + 1;
          }
          data = {
            "key": result.body[0].networkOwnerOfferingKey,
            "name": this.name,
            "icon": this.icon,
            "deleted": 0,
            "level": intLevel,
            "parentKey": this.data.OperationData.key ? this.data.OperationData.key : 'NULL',
            "children": []
          }
          if (this.data.OperationData == 0) {
            this.networkJson.push(data);
            localStorage.setItem("serviceNetwork", JSON.stringify(this.networkJson));
            this.bubbleService.setBubbleTreeData();
            this.dialog.close();
            if (this.data.editFlag != 1) {
              this.afterSave.emit();
            }
          } else {
            this.addEditNode(this.networkJson, data, this.data.OperationData, 'add');
          }

          let message = "Service Added";
          this.showToast('success', message);
        } else {
          let message = "Error occured while adding service";
          this.showToast('warning', message);
        }
      });
    }
  }

  addEditNode(networkJson: any, newNode: any, parentNode: any, operation: string) {
    let newNodeJson = JSON.stringify(newNode, null);
    let parentNodeJson = JSON.stringify(parentNode, null);

    for (let i in networkJson) {
      let tempNode = networkJson[i];
      let tempNodeJson = JSON.stringify(tempNode, null);

      // check if interacted node mathced with any node from nodes in iteration context
      if (tempNodeJson === parentNodeJson) {
        if (operation === 'add') {
          newNodeJson = JSON.parse(newNodeJson)
          if (tempNode.children == null || tempNode.children == undefined)
            tempNode.children = [];
          tempNode.children.push(newNodeJson);
        }

        if (operation === 'edit') {
          newNodeJson = JSON.parse(newNodeJson)
          tempNode.name = newNodeJson["name"];
          tempNode.icon = newNodeJson["icon"];
        }
        break;
      }

      // check if child nodes exist. If exist then recursively match interacted node with any node from child nodes in recursive context
      if (tempNode.children) {
        this.addEditNode(tempNode.children, newNode, parentNode, operation);
      }
    }


    localStorage.setItem("serviceNetwork", JSON.stringify(this.networkJson));
    this.bubbleService.setBubbleTreeData();
    this.dialog.close();
    if (this.data.editFlag != 1) {
      this.afterSave.emit();
    }
  }
}
