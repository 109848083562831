<!-- <div class="w3-panel">
    <label>
        The Service Plus profile outlines how you want the world to intract with your business. It customizes
        how you
        display in various searches and how new clients get to know you, your team and your services.
    </label>
</div> -->
<div>
    <legend>
        <span>
            Dispatch Information
        </span>
    </legend>
    <div class="row">
        <div class="col">
            The Service Plus profile outlines how you want the world to intract with your business. It customizes
            how you
            display in various searches and how new clients get to know you, your team and your services.
        </div>
    </div>
</div>
<br>
<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<form [formGroup]="dispatchDetailsForm" class="class-dispatch">
    <div *ngIf="(isParent) || (!isParent && !hasCentralDispatch)">
    <!-- <div>     -->
        <div class="row text-left">
            <!-- <label class="col-12"><strong>How do you want to be contacted for dispatching?</strong></label> -->
            <label class="col-12"><strong>Dispatch Contact Details</strong></label>
        </div>
        <div class="row">
            <!-- <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Toll Free Phone" mask="000-000-0000" formControlName="tollFreePhone">
                    <span *ngIf="dispatchDetailsForm.controls.tollFreePhone.value != '' && dispatchDetailsForm.controls.tollFreePhone.value != null " matTextPrefix>+1&nbsp;</span>
                </mat-form-field>
            </div> -->
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Dispatch Email</mat-label>
                    <input matInput placeholder="Dispatch Email" formControlName="dispatchEmail">
                    <mat-error *ngIf="handleError('dispatchEmail', 'pattern')">
                        Enter valid Dispatch Email
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Dispatch Fax" mask="000-000-0000" formControlName="dispatchFax"> 
                    <span *ngIf="dispatchDetailsForm.controls.dispatchFax.value != '' && dispatchDetailsForm.controls.dispatchFax.value != null " matTextPrefix>+1&nbsp;</span>
                </mat-form-field>
            </div> -->
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Dispatch Day Phone</mat-label>
                    <input matInput mask="000-000-0000" placeholder="Business Phone"
                        formControlName="businessHoursPhone" required>
                    <span *ngIf="dispatchDetailsForm.controls.businessHoursPhone.value != '' && dispatchDetailsForm.controls.businessHoursPhone.value != null " matTextPrefix>+1&nbsp;</span>
                    <mat-error *ngIf="handleError('businessHoursPhone', 'required')">
                        Business Phone is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>After Hours Phone</mat-label>
                    <input matInput placeholder="After Hours Phone" mask="000-000-0000"
                        formControlName="afterHoursPhone">
                    <span *ngIf="dispatchDetailsForm.value.afterHoursPhone != '' && dispatchDetailsForm.controls.afterHoursPhone.value != null " matTextPrefix>+1&nbsp;</span>
                </mat-form-field>
            </div>
        </div>
        <!-- Leave disabled for now.
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select matNativeControl formControlName="dispatchSolution"
                        placeholder="Do you use a software-based, industry dispatching solution ?"
                        (selectionChange)="setDispatchSolutionValidation()">
                        <mat-option value=""></mat-option>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
        <!-- <div class="row" *ngIf="dispatchDetailsForm.controls.dispatchSolution.value">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select matNativeControl formControlName="providerDispatchSoftwareKey"
                        placeholder="Select Dispatch Software" (selectionChange)="setDispatchSoftwareValidation()"
                        required>
                        <mat-option *ngFor="let dispatchSoftware of dispatchSoftwares"
                            [value]="dispatchSoftware.providerDispatchSoftwareKey">
                            {{dispatchSoftware.providerDispatchSoftwareKey}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="dispatchDetailsForm.controls.providerDispatchSoftwareKey.value === 'Other'">
                <mat-form-field>
                    <input type="text" matInput formControlName="otherDispatchSoftware"
                        placeholder="Please Enter Dispatching Software You Use" required>
                    <mat-error *ngIf="handleError('otherDispatchSoftware', 'required')">
                        Please enter Other Dispatch software.
                    </mat-error>
                </mat-form-field>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="col-12 mb-2">
                <span>
                    BreakdownNOW has an electonic dispatch feature where fleets can communicate with service
                    providers who have electronic capabilities.
                </span>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select matNativeControl formControlName="respondEmailServiceRequest"
                        placeholder="Can you respond to emailed service request within 5 min's or less ?" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('respondEmailServiceRequest', 'required')">
                        Please select email service request.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select matNativeControl formControlName="respondPhoneServiceRequest"
                        placeholder="Can you respond to texted service request within 5 min's or less ?" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('respondPhoneServiceRequest', 'required')">
                        Please select phone service request.
                    </mat-error>
                </mat-form-field>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="mt-2" *ngIf="dispatchDetailsForm.controls.respondEmailServiceRequest.value">
                    <input type="text" matInput formControlName="electronicDispatchEmail"
                        placeholder="Email(s) for Electronic Dispatching">
                    <mat-icon class="cursor-pointer" matSuffix (click)="addEmailAndPhone('email')">add</mat-icon>
                    <mat-error *ngIf="handleError('electronicDispatchEmail', 'pattern')">
                        Enter valid Email Address.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="mt-2" *ngIf="dispatchDetailsForm.controls.respondPhoneServiceRequest.value">
                    <input type="text" matInput formControlName="electronicDispatchPhone"
                        placeholder="Cell Phone(s) for Electronic Text Message(s)" mask="000-000-0000">
                    <mat-icon class="cursor-pointer" matSuffix (click)="addEmailAndPhone('phone')">add</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <ul class="list-group"
                    *ngIf="dispatchDetailsForm.controls.respondEmailServiceRequest.value && electronicDispatchEmails.length > 0">
                    <li class="list-group-item" *ngFor="let email of electronicDispatchEmails">
                        <span>{{email.providerElectronicDispatchEmailKey}}</span>
                        <span class="float-right mr-2">
                            <button class="btn btn-default btn-sm btnAnimation" nbTooltip="Delete"
                                (click)="deleteProviderEmailsForDispatching(email.providerElectronicDispatchEmailKey)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </span>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <ul class="list-group"
                    *ngIf="dispatchDetailsForm.controls.respondPhoneServiceRequest.value && electronicDispatchPhones.length > 0">
                    <li class="list-group-item" *ngFor="let phone of electronicDispatchPhones">
                        <span>{{phone.providerElectronicDispatchPhoneNumberKey | mask: '000-000-0000'}}</span>
                        <span class="float-right mr-2">
                            <button class="btn btn-default btn-sm btnAnimation" nbTooltip="Delete"
                                (click)="deleteProviderPhonesForDispatching(phone.providerElectronicDispatchPhoneNumberKey)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <mat-select matNativeControl formControlName="dispatchStatusKey" [disabled]="!isDispatchStatusEditable"
                    placeholder="Dispatch Status">
                    <mat-option *ngFor="let status of dispatchStatus" [value]="status.statusKey">
                        {{status.statusKey}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="(isParent)" class="col-md-6 mt-2">
            <mat-checkbox formControlName="hasCentralDispatch" (change)="showOptions()">
                <strong>Central Dispatch</strong>
            </mat-checkbox>
            <button disabled class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Central dispatch will use this corporate dispatch details for all sublocations">
                <i class="fas fa-circle-question"></i>
            </button>
        </div>
    </div>

    <div *ngIf="showBack" class="card-footer" [ngClass]="{'card-footer-width': sideBarStatus}">
        <div class="row mt-2">
            <div class="col-md-12 text-right">
                <button *ngIf="showBack" mat-button class="btn btn-orange btn-sm p-2 mr-1"
                    (click)="previousPage()">Back</button>
                <button *ngIf="showSave" mat-button class="btn btn-orange btn-sm p-2 ml-1 mr-1"
                    (click)="updateProviderDispatchDetails()">Save</button>
                <button *ngIf="showSaveNext" mat-button class="btn btn-orange btn-sm p-2 ml-1" (click)="saveNextPage()">
                    Save & Next
                </button>
            </div>
        </div>
    </div>

</form>