<app-loader [loading]="loading"></app-loader>
<form [formGroup]="providerDetailsForm">
    <nb-card>
        <!-- class="shadow-lg" -->
        <nb-card-body >
            <div>
                <br>
                <div class="m-4">
                    <!-- <div class="row">
                        <div class="col-md-6">
                            <mat-checkbox formControlName="afterHoursRatesAllowed" class="chkbx mt-2">
                                After HourRates Allowed
                            </mat-checkbox>
                        </div>
                        <div class="col-md-6">
                            <mat-checkbox formControlName="autoDispatch" class="chkbx mt-2">
                                AutoDispatch Allowed
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Membership Status</mat-label>
                                <mat-select placeholder="Membership Status" formControlName="membershipStatus">
                                    <mat-option
                                        *ngFor="let status of membershipStatus | orderBy : 'membershipStatusKey'"
                                        [value]="status.membershipStatusKey">
                                        {{ status.membershipStatusKey }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Quality Rating</mat-label>
                                <mat-select placeholder="Quality Rating" formControlName="qualityRating">
                                    <mat-option *ngFor="let rating of ratings" [value]="rating">
                                        {{ rating }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div class="row">
                        <label class="col-12"><strong>Account Information</strong></label>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Payment Terms</mat-label>
                                <mat-select placeholder="Payment Terms" formControlName="paymentTermKey">
                                    <mat-option *ngFor="let term of paymentTerms" [value]="term.paymentTermKey">
                                        {{ term.paymentTermKey }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Preferred Method of Payment</mat-label>
                                <mat-select placeholder="Preferred Method" formControlName="paymentMethodKey">
                                    <mat-option *ngFor="let method of paymentMethods | orderBy : 'paymentMethodKey'"
                                        [value]="method.paymentMethodKey">
                                        {{ method.paymentMethodKey }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Account Status</mat-label>
                                <mat-select placeholder="Account Status" formControlName="accountStatus"
                                    (selectionChange)="statusChanged()">
                                    <mat-option *ngFor="let status of providerAccountstatuses | orderBy : 'statusKey'"
                                        [value]="status.statusKey" >
                                        <!-- [disabled]="status.statusKey == 'InActive'"> -->
                                        {{ status.statusKey }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6" *ngIf="!showNote && providerDetailsRes.accountStatus== 'Do Not Use'">
                            <mat-form-field>
                                <mat-label>DNU Reason</mat-label>
                                <textarea matInput placeholder="providerDiscontinuedReason" formControlName="providerDiscontinuedReason"
                                    [value]="providerDetailsRes.providerDiscontinuedReason" readonly >
                                </textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 border border-secondary" *ngIf="showNote">
                            <mat-form-field>
                                <mat-label>Note</mat-label>
                                <textarea matInput placeholder="Note" formControlName="note" required></textarea>
                                <mat-error *ngIf="handleError('note', 'required')">
                                    Note is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Discount Type</mat-label>
                                <mat-select placeholder="Discount Type" formControlName="discountTypeKey"  matNativeControl (selectionChange)="discountChanged()">
                                    <mat-option *ngFor="let discount of discountTypes | orderBy : 'discountType'"
                                    [value]="discount.discountTypeKey">
                                {{discount.discountTypeKey}}
                            </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Amount</mat-label>
                                <input formControlName="discountAmount" type="number"  matInput>
                                <!-- <mat-error *ngIf="this.providerDetailsForm.controls.discountTypeKey.value != 'No Discount' && this.providerDetailsForm.controls.discountAmount.value == 0">
                                    Amount is required.
                                </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
                    <hr>
                    <!-- <br> -->
                    
                    <!-- <div class="row">
                        <label class="col-12"><strong>Account Metrics</strong></label>
                    </div>
                    <div *ngIf="providerDetails.isParent" class="row mt-3">
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Work Orders Completed</mat-label>
                                <input [value]="providerMetrics.CountOfWorkOrders" readonly matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Paid Last 30 Days</mat-label>
                                <input [value]="providerMetrics.Last30DayAmount" readonly matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Paid Last 180 Days</mat-label>
                                <input [value]="providerMetrics.Last180DayAmount" readonly matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Paid Last 365 Days</mat-label>
                                <input [value]="providerMetrics.Last365DayAmount" readonly matInput>
                            </mat-form-field>
                        </div>
                    
                    </div> -->
    
                    <div class="row">
                        <label class="col-12"><strong>Account DNU</strong> </label>
                    </div>   
                    <div *ngIf="providerDetails.isParent" class="mt-3">    
                        <div class="table-responsive">
                            <table matSort class="table table-bordered rates">
                                <thead class="text-center">
                                    <tr>
                                        <th mat-sort-header="CustomerName" sortable="CustomerName" direction="asc" (sort)="onSort($event)"> Customer Name </th>
                                        <th> SubCustomer Name </th>
                                        <!-- <th> Category </th> -->
                                        <th> Do Not Use Reason </th>
                                        <th> Actions </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="customerNotPreferredProvidersForServicePlus && customerNotPreferredProvidersForServicePlus.length > 0; else noData">
                                    <tr *ngFor="let provider of customerNotPreferredProvidersForServicePlus; let i = index;">
                                        <!-- <td> {{provider.InsertedTimestamp}} </td> -->
                                        <td> {{provider.CustomerName}} </td>
                                        <td> {{provider.SubCustomerName}} </td>
                                        <td> {{provider.DNUReason}} </td>
                                        <td  class="text-center">
                                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                                            (click)="deleteDoNotUse(provider)"
                                                >
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <ng-template #noData>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" class="text-center">
                                                No Data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </ng-template>
                            </table>
                        </div>

                    </div>

                    <div *ngIf="providerDetails.isParent" class="">
                        <button class="new-application-button" mat-button extended (click)="addToDNUList()" >
                        <mat-icon>add</mat-icon>
                        Add To DNU
                        </button>
                    </div>

                    <br>
                    <hr>

                    <div class="row">
                        <label class="col-12"><strong>Only Use for Specific Customer</strong></label>
                    </div> 

                    <div *ngIf="providerDetails.isParent" class="mt-3">    
                        <div class="table-responsive">
                            <table matSort class="table table-bordered rates">
                                <thead class="text-center">
                                    <tr>
                                        <th mat-sort-header="CustomerName" sortable="CustomerName" direction="asc" (sort)="onSort($event)"> Customer Name </th>
                                    
                                        <th> Actions </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="onlyUseForSpecificCustomer && onlyUseForSpecificCustomer.length > 0; else noData">
                                    <tr *ngFor="let provider of onlyUseForSpecificCustomer; let i = index;">
                                        <!-- <td> {{provider.InsertedTimestamp}} </td> -->
                                        <!-- <td> {{provider.CustomerName}} </td> -->
                                         <td> {{provider.CustomerName}}</td>
                                        <td  class="text-center">
                                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                                            (click)="deleteCustomerPerferredProviders(provider)"
                                                >
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <ng-template #noData>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" class="text-center">
                                                No Data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </ng-template>
                            </table>
                        </div>

                    </div>
                    <div *ngIf="providerDetails.isParent" class="">
                        <button class="new-application-button" mat-button extended (click)="addToOnlyUseForSpecificCustomer()" >
                        <mat-icon>add</mat-icon>
                        Select Customer
                        </button>
                    </div>

                    <br>

                    <div class="">
                        <div class="">
                            <div class="text-right">
                                <button (click)="updateAccountChanges()" class="btn btn-primary btn-sm">Save Account Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-3 custom-height">
                    <app-menulist [selectedProviderKey]="providerKey"
                    [isMasterLicensedOwner]="isMasterLicensedOwner"  [menuList]="childMenus"></app-menulist>
                </div> -->
                <!-- <div class="table-responsive mt-2">
                    <table matSort class="table table-bordered rates">
                        <thead class="text-center">
                            <tr>
                                <th mat-sort-header="CustomerName" sortable="CustomerName" direction="asc"> Customer Name </th>
                                <th> SubCustomer Name </th>
                                <th> Do Not Use Reason </th>
                                <th> Actions </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="customerNotPreferredProvidersForServicePlus && customerNotPreferredProvidersForServicePlus.length > 0; else noData">
                            <tr *ngFor="let provider of customerNotPreferredProvidersForServicePlus; let i = index;">
                                <td> {{provider.CustomerName}} </td>
                                <td> {{provider.SubCustomerName}} </td>
                                <td> {{provider.DNUReason}} </td>
                                <td  class="text-center">
                                    <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                                        >
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No Data available
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div> -->
            </div>
        </nb-card-body>
    </nb-card>
</form>