<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="col-md-12">
    <form [formGroup]="accountDetailForm">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field *ngIf="!isEdit">
                    <input matInput placeholder="Account Name" formControlName="accountName" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.accountName.touched && accountDetailForm.controls.accountName.errors">
                        <span *ngIf="accountDetailForm.controls.accountName.errors.required">
                            Account Name is required.
                        </span>
                    </mat-error>
                </mat-form-field>
                <h5 class="account-name" *ngIf="isEdit && providerAccount">{{providerAccount.accountName}}</h5>
            </div>
            <div class="col-md-6 text-center">
                <mat-checkbox formControlName="defaultRetailAccount">
                    <label>Default Retail Account Setting</label>
                </mat-checkbox>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input type="text" matInput placeholder="Address 1" formControlName="address1" autocomplete="off"
                        required ngx-google-places-autocomplete (onAddressChange)="getDetailedAddress($event)">
                    <mat-error *ngIf="handleError('address1', 'required')">
                        Address is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('address1', 'pattern')">
                        Enter valid Address.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input type="text" matInput placeholder="Address 2" formControlName="address2" autocomplete="off">
                    <mat-error *ngIf="handleError('address1', 'required')">
                        Address is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('address1', 'pattern')">
                        Enter valid Address.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input type="text" matInput placeholder="Address 3" formControlName="address3" autocomplete="off">
                    <mat-error *ngIf="handleError('address1', 'required')">
                        Address is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('address1', 'pattern')">
                        Enter valid Address.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input matInput placeholder="City" formControlName="city" autocomplete="off" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.city.touched && accountDetailForm.controls.city.errors">
                        <span *ngIf="accountDetailForm.controls.city.errors.required">
                            City is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input matInput placeholder="State" formControlName="stateKey" autocomplete="off" required>
                    <mat-error *ngIf="accountDetailForm.controls.stateKey.errors">
                        <span>
                            State is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input matInput placeholder="Zip" formControlName="postalCode" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.postalCode.touched && accountDetailForm.controls.postalCode.errors">
                        <span *ngIf="accountDetailForm.controls.postalCode.errors.required">
                            Zip code is required.
                        </span>
                        <span *ngIf="accountDetailForm.controls.postalCode.errors.pattern">
                            Enter valid Zip code.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input  matInput placeholder="Dispatch Email" formControlName="dispatchEmail">
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input mask="000-000-0000" matInput placeholder="Dispatch Phone" formControlName="dispatchPhone">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input matInput mask="000-000-0000" placeholder="Primary Contact Phone"
                        formControlName="primaryContactPhone">
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-4"
                *ngIf="this.accountDetailForm.controls.afterHoursRatesAllowed.value || !isEdit">
                <mat-checkbox formControlName="afterHoursRatesAllowed">
                    <label>After Hour Rates Allowed</label>
                </mat-checkbox>
            </div>
            <div class="col-md-6 col-lg-4">
                <mat-checkbox formControlName="autoDispatch">
                    <label>Auto Dispatch Allowed</label>
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-2">
                <mat-form-field>
                    <mat-select formControlName="qualityRating" matNativeControl placeholder="Quality Rating">
                        <mat-option disabled="true">Select Rating</mat-option>
                        <mat-option *ngFor="let value of rating" [value]="value">
                            {{value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-2">
                <mat-form-field>
                    <mat-select (selectionChange)="showDiscontinuedReason()" formControlName="accountStatus"
                        matNativeControl placeholder="Account Status" required>
                        <mat-option value="" disabled="true">Select Status</mat-option>
                        <ng-container *ngFor="let val of userStatusDetails">
                            <mat-option *ngIf="val.isSelected" [value]="val.statusKey">
                                {{val.statusKey}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="showDiscontinuedReasonFlag" class="col-md-6">
                <mat-form-field>
                    <input matInput placeholder="Discontinued Reason" formControlName="providerDiscontinuedReason"
                        required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.providerDiscontinuedReason.touched && accountDetailForm.controls.providerDiscontinuedReason.errors">
                        <span *ngIf="accountDetailForm.controls.providerDiscontinuedReason.errors.required">
                            Discontinued Reason is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="showNotesReasonFlag" class="col-md-6">
                <mat-form-field>
                    <input matInput formControlName="activeNotes" placeholder="Note" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.activeNotes.touched && accountDetailForm.controls.activeNotes.errors">
                        <span *ngIf="accountDetailForm.controls.activeNotes.errors.required">
                            Note is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>