<app-loader [loading]="loading"></app-loader>
<form [formGroup]="billingDetailsForm" class="borderPadding">
    <div *ngIf="(isParent) || (!isParent && !hasCentralBilling)">
    <div class="row text-left">
        <!-- <label class="col-12"><strong>How do you want to be contacted for billing?</strong></label> -->
        <label class="col-12"><strong>Billing Contact Details</strong></label>
    </div>
    <br>    
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-label>Billing Email</mat-label>
                <input matInput placeholder="Billing Dedicated Email" formControlName="billingEmail" [readonly]="isMasterLicensedOwner">
                <mat-error
                    *ngIf="billingDetailsForm.controls.billingEmail.touched && billingDetailsForm.controls.billingEmail.errors">
                    <span *ngIf="billingDetailsForm.controls.billingEmail.errors.required">Billing Dedicated Email is
                        required.</span>
                    <span *ngIf="billingDetailsForm.controls.billingEmail.errors.pattern">Enter valid Billing Dedicated
                        Email.</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-label>Billing Email</mat-label>
                <input matInput mask="000-000-0000" placeholder="Billing Phone" formControlName="billingPhone">
                <span *ngIf="billingDetailsForm.controls.billingPhone.value != '' && billingDetailsForm.controls.billingPhone.value != null " matTextPrefix>+1&nbsp;</span>
                <mat-error
                    *ngIf="billingDetailsForm.controls.billingPhone.touched && billingDetailsForm.controls.billingPhone.errors">
                    <span *ngIf="billingDetailsForm.controls.billingPhone.errors.pattern">Enter Valid Billing Phone Number.</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <input matInput mask="000-000-0000" placeholder="Billing Dedicated Fax" formControlName="billingFax" [readonly]="isMasterLicensedOwner">
                <span *ngIf="billingDetailsForm.controls.billingFax.value != '' && billingDetailsForm.controls.billingFax.value != null " matTextPrefix>+1&nbsp;</span>
                <mat-error
                    *ngIf="billingDetailsForm.controls.billingFax.touched && billingDetailsForm.controls.billingFax.errors">
                    <span *ngIf="billingDetailsForm.controls.billingFax.errors.pattern">Enter valid Billing Dedicated
                        Fax.</span>
                </mat-error>
            </mat-form-field>
        </div> -->
        <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-label>Primary Billing Contact</mat-label>
                <input matInput placeholder="Primary Billing Contact" formControlName="primaryBillingContact" [readonly]="isMasterLicensedOwner">
            </mat-form-field>
        </div>
        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-select formControlName="paymentTermKey" matNativeControl placeholder="Payment Term">
                    <mat-option [(value)]="term.paymentTermKey" *ngFor="let term of paymentTerms">
                        {{ term.paymentTermKey }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-select formControlName="paymentMethodKey" matNativeControl placeholder="Payment Method">
                    <mat-option [(value)]="method.paymentMethodKey" *ngFor="let method of paymentMethods">
                        {{ method.paymentMethodKey }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
    </div>
    <div *ngIf="(isParent)" class="row mt-1">
    <mat-checkbox class="ml-3 d-inline" (change)="showOptions()"
        formControlName="hasCentralBilling" [disabled]="isMasterLicensedOwner || !isParent">
        <strong>Central Billing</strong>
    </mat-checkbox>
    <button disabled class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Central billing sets this corporate billing details for all sublocations">
        <i class="fas fa-circle-question"></i>
    </button>
    </div>
    <div class="text-right">
        <button mat-button class="btn btn-primary" (click)="updateProviderBillingDetails()" [disabled]="isMasterLicensedOwner">Save</button>
    </div>
    </div> 

    <div *ngIf="(!isParent && hasCentralBilling)">
        <div  class="paddingBottom">
            <mat-checkbox disabled checked="true">
                <strong>Enrolled in Central Billing</strong>
            </mat-checkbox>
        </div>
    </div>
</form>
