<div class="row w3-panel ml-0 mr-0"
  *ngIf="!showProtocolIndexs  && !showThresholdIndexs && !showOfferingQuestionIndexes">
  <div class="col-md-2">
    <i class="fad fa-check-square fa-2x bubble-user-index-icon" style="margin-top: 8px;"></i>
    <span class="rect-text"> {{addServiceLebel}}</span>
  </div>
  <div class="col-md-2" *ngIf="showRemoveService">
    <i class="fad fa-window-close fa-2x bubble-user-index-icon" style="margin-top: 8px;"></i>
    <span class="rect-text"> {{removeServiceLebel}}</span>
  </div>
</div>
<div class="row w3-panel mx-0" *ngIf="showProtocolIndexs">
  <div class="col-md-2">
    <span class="protocol-box"></span>
    <span> Protocol Exist </span>
  </div>
  <div class="col-md-2">
    <mat-icon class="protocol">stars</mat-icon>
    <span class="ml-3"> Protocol Applied </span>
  </div>
  <div class="col-12 col-md-8">
    <div class="d-inline-block">
      <span class="blank-box"></span>
      <span> No Protocol Entered</span>
    </div>
    <div class="d-inline-block ml-2">
      <span class="default-protocol"></span>
      <span class="ml-1">Default Protocol</span>
    </div>
    <div class="d-inline-block ml-2" *ngIf="isCustomProtocol">
      <span class="custom-protocol-box"></span>
      <span class="ml-1">Custom Protocol</span>
    </div>

    <div class="d-inline-block ml-2">
      <span class="no-default-protocol"></span>
      <span class="ml-1">No Default Protocol</span>
    </div>
  </div>
</div>
<div class="row w3-panel mx-0" *ngIf="showThresholdIndexs">
  <div class="col-md-2">
    <span class="protocol-box"></span>
    <span> Threshold Exist </span>
  </div>
  <div class="col-md-2">
    <mat-icon class="protocol">stars</mat-icon>
    <span class="ml-3"> Threshold Applied </span>
  </div>
  <div class="col-md-3 px-0">
    <span class="blank-box"></span>
    <span> No Threshold Entered</span>
  </div>
</div>
<div class="row w3-panel mx-0" *ngIf="showOfferingQuestionIndexes">
  <div class="col-md-3">
    <!-- <span class="protocol-box"></span> -->
    <mat-icon class="font-large text-success">menu</mat-icon>
    <span class="position-absolute"> Questions Exist </span>
  </div>
  <div class="col-md-3">
    <!-- <span class="no-questions-exists"></span> -->
    <mat-icon class="font-large text-danger">menu</mat-icon>
    <span class="position-absolute">Questions Do Not Exist</span>
  </div>
</div>