import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from './environments/environment';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.issuer,
  // URL of the SPA to redirect the user to after login
  redirectUri: environment.siteUrl + '/login/oauth2/code/cognito',
  clientId: environment.clientId,
  responseType: 'code',
  // set the scope for the permissions the client should request
  scope: 'openid profile',
  postLogoutRedirectUri: environment.siteUrl + '/logout',
  logoutUrl: environment.cognitoUrl + '/logout',
  silentRefreshShowIFrame: true,
  showDebugInformation: true,
  sessionChecksEnabled: false,
  strictDiscoveryDocumentValidation: false,
  dummyClientSecret: environment.secretKey,
};
