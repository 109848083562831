import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { NbToastrService } from '@nebular/theme';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {

  loading = false;
  editDayFlag = false;
  weekData: any;
  startTime: any;
  endTime: any;
  userHoursKey: any;
  //minDate = new Date();
  todayDate: Date = new Date();
  availabilityForm: UntypedFormGroup;
  readOnlyUser = false;
  user: any;
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  @Output() saveCompleted = new EventEmitter<any>();

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  constructor(
    private usersService: UsersService,
    private toast: NbToastrService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.editDayFlag = false;
    this.availabilityForm = this.formBuilder.group({
      outOfOffice: [''],
      OutOfOfficeExpireTimestamp: ['']
    });
    this.readOnlyUser = this.data.readOnlyUser || false;
    if (this.data.readOnlyUser) {
      this.availabilityForm.disable();
    }
    this.getAvailability();
    this.getUser();
  }

  getUser() {
    const filter = {userKey: this.data.userKey, deleted: false};
    this.usersService.getUsers(filter).subscribe(response => {
      if (response.success) {
        this.user = response.body[0];
        if (this.user && this.user !== undefined) {
        this.availabilityForm.setValue({
            outOfOffice: (this.user.outOfOffice !== undefined) ? true : false,
          OutOfOfficeExpireTimestamp: this.user.outOfOfficeExpireTimestamp ?
                                      new Date(this.user.outOfOfficeExpireTimestamp) : ''
        });
        }
      }
    });
  }

  getAvailability() {
    this.loading = true;
    this.weekData = [];
    const filter = { userKey: this.data.userKey, deleted: false };

    this.usersService.getAvailability(filter).subscribe((res: any) => {
      this.weekData = res.body;
      if (this.weekData.length === 0) {
        this.addDefaultHours();
      } else {
        this.weekData.map(date => {
          date.startTime = moment(date.startTime, ["HH:mm:ss"]).format("hh:mm A");
          date.endTime = moment(date.endTime, ["HH:mm:ss"]).format("hh:mm A")
          return date;
        });

        const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

        this.weekData.sort((a, b) => {
          return order[a.daysOfWeekKey] - order[b.daysOfWeekKey];
        });
      }
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  editDay(item) {
    this.editDayFlag = item.daysOfWeekKey;
  }

  closeDayData(daysOfWeekKey) {
    this.editDayFlag = !daysOfWeekKey;
  }

  updateAvailability(startTime, endTime, UserHoursKey) {
    const editData = {
      startTime: moment(startTime, ["h:mm:ss A"]).format("HH:mm:ss"),
      endTime: moment(endTime, ["h:mm:ss A"]).format("HH:mm:ss"),
      userHoursKey: UserHoursKey,
      userKey: this.data.userKey,
      updatedUserKey: sessionStorage.getItem('userKey')
    };
    
    this.loading = true;
    this.usersService.updateAvailability(editData).subscribe((result: any) => {
      this.editDayFlag = !editData;
      if (result.success) {
        this.toast.success(result.message[0], 'Success');
      } else {
        this.toast.danger(result.message[0], 'Error');
      }
        this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  updateAllAvailability24Hour() {
    const updateData = [];
    this.weekData.forEach(data => {
      updateData.push({
        available24Hours: data.available24Hours,
        endTime: '08:00:00',
        startTime: '08:00:00',
        updatedUserKey: sessionStorage.getItem('userKey'),
        userHoursKey: data.userHoursKey,
      });
    });

    this.loading = true;
    this.usersService.updateAvailabilityHoursBatch(updateData).subscribe(result => {
      if (result.success) {
        this.toast.success(result.message[0], 'Success');
        this.getAvailability();
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  addDefaultHours() {
    const operationData = [];

    this.days.forEach(day => {
      operationData.push({
        available24Hours: true,
        daysOfWeekKey: day,
        deleted: false,
        endTime: '8:00:00',
        insertedUserKey: sessionStorage.getItem('userKey'),
        startTime: '8:00:00',
        systemDefault: false,
        updatedUserKey: '',
        userKey: this.data.userKey
      });
    });

    this.loading = true;
    this.usersService.addAvailabilityHoursBatch(operationData).subscribe(result => {
      if (result.success) {
        this.getAvailability();
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  refresh() {
    this.ngOnInit();
  }

  updateAll() {
    if (this.editDayFlag) {
      const dayData =  this.weekData.find(d => d.daysOfWeekKey === this.editDayFlag);
      this.updateAvailability(dayData.startTime, dayData.endTime, dayData.userHoursKey);
    } 
    this.updateOfficeHours();
  }

  updateOfficeHours() {
    const formData = this.availabilityForm.value;
    const updateData = {
      updatedUserKey: sessionStorage.getItem('userKey'),
      userKey: this.data.userKey,
      ...formData
    };

    this.loading = true;
    this.usersService.updateUser(updateData).subscribe(res =>  {
      if (res.success) {
        this.toast.success(res.message[0], 'Success');
        this.saveCompleted.emit('availability');
        this.availabilityForm.markAsUntouched();
      }
      this.loading = false;
    }, (error) => {
      console.log('error,', error);
      this.loading = false;
    });
  }

  canDeactivate(): boolean {
    return (this.availabilityForm.dirty && this.availabilityForm.touched) || !!this.editDayFlag;
  }
}
