import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { CommonService } from 'src/app/services/common.service';
import { forkJoin } from 'rxjs';
import { patterns } from 'src/assets/patterns';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-edit-direct-reports-dialog',
  templateUrl: './edit-direct-reports-dialog.component.html',
  styleUrls: ['./edit-direct-reports-dialog.component.scss']
})
export class EditDirectReportsDialogComponent implements OnInit {
  formDirectReport: UntypedFormGroup;
  departmentList = [];
  statuses = [];
  usersList = [];
  loading = false;

  constructor(public dialogRef: MatDialogRef<EditDirectReportsDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService) { }

  ngOnInit() {
    this.formDirectReport = this.formBuilder.group({
      userKey: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: [''],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      cellPhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(patterns.mobnumPattern)]],
      officePhone: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(patterns.mobnumPattern)]],
      supervisor: ['', [Validators.required]],
      departmentKey: ['', [Validators.required]],
      userStatusKey: ['', [Validators.required]],
    });

    this.getDropDowns();
    this.loadUserDetails();
  }

  getDropDowns() {
    this.loading = true;
    const deptFilter = {
      deleted: false,
    };

    const supervisorFilter = {
      associationKey: this.data.associationKey,
      deleted: false
    };

    const deptReq = this.commonService.getDepartments(deptFilter);
    const supervisorReq = this.usersService.getUsers(supervisorFilter);
    const statusReq = this.commonService.getUserStatuses();

    forkJoin([deptReq, supervisorReq, statusReq]).subscribe(result => {
      this.departmentList = result[0].body;
      this.usersList = result[1].body;
      this.statuses = result[2].body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /****
    * Desc: Get Users by type
    * @params postData
    * @return array response
  ***/
  getUsersByType(event) {
    const objParam = {
      userTypeKey: sessionStorage.getItem('userTypeKey'),
      departmentKey: event.value,
      deleted: false,
    };

    this.usersService.getUsers(objParam).subscribe((result) => {
      this.usersList = result.body;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  loadUserDetails() {
    this.formDirectReport.setValue({
      userKey: this.data.userKey,
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      title: this.data.title,
      email: this.data.email,
      cellPhone: this.data.cellPhone,
      officePhone: this.data.officePhone,
      supervisor: this.data.supervisor,
      departmentKey: this.data.departmentKey,
      userStatusKey: this.data.userStatusKey,
    });
  }

  save() {
    if (this.formDirectReport.invalid) {
      this.formDirectReport.markAllAsTouched();
      return false;
    }

    this.loading = true;
    const data = this.formDirectReport.value;
    this.usersService.updateUser(data).subscribe(res => {
      if (res.success) {
        this.toast.success('Updated User', 'Success');
        this.close();
      }
      this.loading = false;
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.formDirectReport.controls[control].hasError(error);
  }

  close() {
    this.dialogRef.close();
  }
}
