import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { TechnicianSkills } from '../../models/technician-skills.model';
import { TechnicianService } from '../../services/technician.service';
import { patterns } from 'src/assets/patterns';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';

@Component({
  selector: 'ngx-add-edit-technician',
  templateUrl: './add-edit-technician.component.html',
  styleUrls: ['./add-edit-technician.component.css']
})
export class AddEditTechnicianComponent implements OnInit {

  @Input() technicianData: any = [];
  @Input() addEditFlag = 'add';
  @Input() providerKey = '';
  @Output() updateTechniciansList: EventEmitter<boolean> = new EventEmitter<boolean>();
  technicianForm: UntypedFormGroup;
  loading = false;
  skills: TechnicianSkills[] = [];
  expertiseLevel = Array.from({ length: 5 }, (_, i) => i + 1);
  isActive: boolean = false;
  providerTechnicianKey: string;
  technicianSkillsData: any = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private technicianService: TechnicianService,
    private toast: NbToastrService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {

    this.technicianForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(patterns.namePattern)]],
      lastName: ['', [Validators.required, Validators.pattern(patterns.lastNamePattern)]],
      cellPhone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(patterns.emailPattern)]],

      skills: this.formBuilder.array([this.createEmailFormGroup()]),
    });
    this.getDropdownValues();

    if (this.addEditFlag == 'edit') {
      this.providerTechnicianKey = this.technicianData.providerTechnicianKey;
      this.setTechnicianData();
    }
  }

  getDropdownValues() {
    this.loading = true;
    this.technicianService.getSkills().subscribe(response => {
      this.loading = false;
      this.skills = response;
    });
  }

  activeDeactiveTechnician() {
    this.isActive = !this.isActive;
    this.loading = true;
    this.technicianData.active = this.isActive;
    this.technicianService.updateProviderTechnicians(this.technicianData).subscribe(response => {
      this.loading = false;
      if (response.success) {
        this.toast.success(response.message, 'Success');
        this.isActive ? this.technicianForm.enable() : this.technicianForm.disable()
        this.getProviderTechnicianSkills();
      } else {
        this.toast.warning(response.message, 'Error');
      }
    });
  }

  createEmailFormGroup(skill = '', expertiseLevel = ''): UntypedFormGroup {
    return new UntypedFormGroup({
      skill: new UntypedFormControl(skill),
      expertiseLevel: new UntypedFormControl(expertiseLevel)
    })
  }

  get aliasesArrayControl() {
    return (this.technicianForm.get('skills') as UntypedFormArray).controls;
  }

  removeField(index: number) {
    const skills = this.technicianForm.get('skills') as UntypedFormArray;
    if (skills.length > 0) {
      if (this.technicianSkillsData[index]) {
        this.deleteProviderTechnicianSkills(this.technicianSkillsData[index], skills, index);
      }
    } else {
      skills.reset();
    }
  }

  addSkills() {
    const skills = this.technicianForm.get('skills') as UntypedFormArray
    skills.push(this.createEmailFormGroup())
  }

  addUpdateProviderTechnicians() {
    
    if (this.addEditFlag == 'add') {
      this.addProviderTechnicianDetails();
    } else if (this.addEditFlag == 'edit') {
      this.updateProviderTechnicianDetails();
    }
  }

  //method for save new Technician
  addProviderTechnicianDetails() {

    const objAddTechParams = {
      active: true,
      firstName: this.technicianForm.controls.firstName.value,
      lastName: this.technicianForm.controls.lastName.value,
      cellPhone: this.technicianForm.controls.cellPhone.value,
      email: this.technicianForm.controls.email.value,
      providerKey: this.providerKey,
      deleted: false,
      systemDefault: true,
      insertedUserKey: sessionStorage.getItem('userKey'),
      updatedUserKey: '',
    };
    this.loading = true;
    this.technicianService.addProviderTechnicians(objAddTechParams).subscribe(response => {
      if (response.success) {
        this.providerTechnicianKey = response.body[0].providerTechnicianKey;
        this.toast.success(response.message, 'Success');
        this.getProviderTechnicianSkills();
        this.updateTechniciansList.emit(true);
      } else {
        this.toast.warning(response.message, 'Error');
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  updateProviderTechnicianDetails() {

    const objUpdateTechParams = {
      providerTechnicianKey: this.technicianData.providerTechnicianKey,
      active: true,
      firstName: this.technicianForm.controls.firstName.value,
      lastName: this.technicianForm.controls.lastName.value,
      cellPhone: this.technicianForm.controls.cellPhone.value,
      email: this.technicianForm.controls.email.value,
      providerKey: this.providerKey,
      deleted: false,
      systemDefault: true,
      insertedUserKey: sessionStorage.getItem('userKey'),
      updatedUserKey: sessionStorage.getItem('userKey')
    };
    this.loading = true;
    this.technicianService.updateProviderTechnicians(objUpdateTechParams).subscribe(response => {
      this.providerTechnicianKey = this.technicianData.providerTechnicianKey;
      if (response.success) {
        this.toast.success(response.message, 'Success');
        this.getProviderTechnicianSkills();
        this.updateTechniciansList.emit(true);
        } else {
        this.toast.warning(response.message, 'Error');
        }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      });
  }

  getProviderTechnicianSkills() {
    
    let objArrAddTechParams = [];
    let objArrUpdateTechParams = [];
    this.technicianForm.controls.skills.value.map(value => {
      
      const objParams = {
        skillKey: value.skill,
        levelOfExpertise: value.expertiseLevel,
        providerTechnicianKey: this.providerTechnicianKey,
        providerKey: this.providerKey,
        active: this.isActive
      };

      this.technicianService.getProviderTechnicianSkills(objParams).subscribe(response => {
        if (response.body.length > 0) {
          const objUpdateTechSkillsParams = {
            active: this.isActive,
            providerTechnicianSkillKey: response.body[0].providerTechnicianSkillKey,
            skillKey: value.skill,
            levelOfExpertise: value.expertiseLevel,
            providerTechnicianKey: this.providerTechnicianKey,
            providerKey: this.providerKey,
            deleted: false,
            insertedUserKey: sessionStorage.getItem('userKey'),
            systemDefault: true,
            updatedUserKey: sessionStorage.getItem('userKey')
          };
          objArrUpdateTechParams.push(objUpdateTechSkillsParams);
          this.updateProviderTechnicianSkills(objUpdateTechSkillsParams);
        } else {
          const objAddTechSkillsParams = {
            active: this.isActive,
            skillKey: value.skill,
            levelOfExpertise: value.expertiseLevel,
            providerTechnicianKey: this.providerTechnicianKey,
            providerKey: this.providerKey,
            deleted: false,
            insertedUserKey: sessionStorage.getItem('userKey'),
            systemDefault: true,
            updatedUserKey: ''
          };
          objArrAddTechParams.push(objAddTechSkillsParams);
          this.addProviderTechnicianSkills(objAddTechSkillsParams);
        }
      }, (error) => {
        this.loading = false;
      });
    });
  }

  addProviderTechnicianSkills(objAddTechSkillsParams) {

      this.loading = true;
      this.technicianService.addProviderTechnicianSkills(objAddTechSkillsParams).subscribe(response => {
        // if (response.success) {
        //   this.toast.success(response.message, 'Success');
        // } else {
        //   this.toast.warning(response.message, 'Error');
        // }
        this.loading = false;
      }, (error) => {
        this.loading = false;
      });
  }

  updateProviderTechnicianSkills(objUpdateTechSkillsParams) {

    this.loading = true;
    this.technicianService.updateProviderTechnicianSkills(objUpdateTechSkillsParams).subscribe(response => {
      // if (response.success) {
      //   this.toast.success(response.message, 'Success');
      // } else {
      //   this.toast.warning(response.message, 'Error');
      // }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  deleteProviderTechnicianSkills(data, skills, index) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {        
        skills.removeAt(index);
        const objUpdateTechSkillsParams = {
          active: this.isActive,
          providerTechnicianSkillKey: data.providerTechnicianSkillKey,
          providerTechnicianKey: this.providerTechnicianKey,
          providerKey: this.providerKey,
          deleted: true,
          updatedUserKey: sessionStorage.getItem('userKey')
        };

    this.loading = true;
    this.technicianService.deleteProviderTechnicianSkills(this.technicianSkillsData[index].providerTechnicianSkillKey).subscribe(response => {
      if (response.success) {
        this.technicianSkillsData = this.technicianSkillsData.filter(x=> x.providerTechnicianSkillKey != this.technicianSkillsData[index].providerTechnicianSkillKey)
        this.toast.success(response.message, 'Success');
      } else {
        this.toast.warning(response.message, 'Error');
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
    });
  }

  setTechnicianData() {

    const skills = this.technicianForm.get('skills') as UntypedFormArray;
    skills.clear();

    if (this.technicianData) {
      this.isActive = this.technicianData.active;
      this.isActive ? this.technicianForm.enable() : this.technicianForm.disable();
      this.technicianForm.patchValue({
        firstName: this.technicianData.firstName,
        lastName: this.technicianData.lastName,
        cellPhone: this.technicianData.cellPhone,
        email: this.technicianData.email
      });
      const objParams = {
        providerKey: this.technicianData.providerKey,
        providerTechnicianKey: this.providerTechnicianKey,
        deleted: false,
        active: this.isActive
      };
      this.technicianService.getProviderTechnicianSkills(objParams).subscribe(response => {
        if (response.body.length) {
          this.technicianSkillsData = response.body;
          response.body.forEach((technicianSkill: any) => {
            skills.push(this.createEmailFormGroup(technicianSkill.skillKey, technicianSkill.levelOfExpertise))
          });
        }
      });
    } else {
      this.technicianForm.enable();
      this.technicianForm.reset();
      this.isActive = false;
    }
  }
}
