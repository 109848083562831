import { Component, OnInit, Input } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { MatDialog } from '@angular/material/dialog';
import { ProviderService } from 'src/app/services/provider.service';
import { AddEditHolidayDialogComponent } from 'src/app/dialogs/add-edit-holiday-dialog/add-edit-holiday-dialog.component';
import { HoursOfOperationsService } from 'src/app/services/hours-of-operations-service.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-provider-management-hrs-of-operation',
  templateUrl: './provider-management-hrs-of-operation.component.html',
  styleUrls: ['./provider-management-hrs-of-operation.component.scss']
})
export class ProviderManagementHrsOfOperationComponent implements OnInit {
  @Input() isParent = true;
  @Input() providerInputKey: string;
  @Input() isMasterLicensedOwner: boolean;

  loading: boolean;
  weekData: any = [];
  editDayFlag = false;
  Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  providerKey: string;
  openTime: any;
  closeTime: any;
  providerHoursOfOperationKey: any;
  tierCanSetHours = true;
  currentTimezone: any;
  getChildData: any;
  parentData = false;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';


  constructor(
    private toast: NbToastrService,
    public dialog: MatDialog,
    private providerService: ProviderService,
    private hoursOfOperationsService: HoursOfOperationsService,
    private providerAdminService: ProviderAdminService
  ) { }

  ngOnInit() {
    this.providerKey = this.providerInputKey;

    this.getCurrentTimeZone();
    this.getHoursOfOperation();
  }

  getCurrentTimeZone() {
    const data = {
      providerKey: this.providerKey,
      deleted: false
    };

    this.providerService.getProviderDetails(data).subscribe((result: any) => {
      this.currentTimezone = result.body[0]?.timeZoneKey || '';
    }, (error) => {
      console.log(error);
    });
  }

  getHoursOfOperation() {
    this.loading = true;
    this.Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    this.weekData = [];
    const filter = {
      providerKey: this.providerKey,
      deleted: false
    };

    this.loading = true;
    this.hoursOfOperationsService.getProviderHoursOfOperation(filter).subscribe(res => {
      this.loading = false;
      if (res.body.length > 0) {
        this.parentData = false;
        this.Days.forEach(obj => {
          res.body.forEach(weekday => {
            if (obj === weekday.dayOfWeek) {
              this.weekData.push(weekday);
            }
          });
        });
      } else {
        const locationFilter = {
          providerkey: this.providerInputKey
        };

        this.providerService.getProviderDetails(locationFilter).subscribe(result => {
          const locationKey = result.body[0]?.parentKey || '';
          if (locationKey) {
            const data = {
              providerKey: locationKey,
              deleted: false
            };
            this.loading = true;
            this.hoursOfOperationsService.getProviderHoursOfOperation(data).subscribe(response => {
              this.loading = false;
              if (response.body.length > 0) {
                this.Days.forEach(obj => {
                  response.body.forEach(weekday => {
                    if (obj === weekday.dayOfWeek) {
                      this.weekData.push(weekday);
                    }
                  });
                });
                this.parentData = true;
              }
            }, (error) => {
              this.loading = false;
              console.log(error);
            });
          }
        });
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  editDay(data) {
    this.providerHoursOfOperationKey = data.providerHoursOfOperationKey;
    this.editDayFlag = data.dayOfWeek;
    this.openTime = data.openTime;
    this.closeTime = data.closeTime;
  }

  closeDayData(dayOfWeek) {
    this.editDayFlag = !dayOfWeek;
  }

  updateHoursOfOperation(fromTime, toTime, HoursOfOperationKey, isClosed = 0) {
    this.editDayFlag = false;
    if (this.parentData) {
      const operationData = [];
      this.weekData.forEach(item => {
        const data = {
          openTime: HoursOfOperationKey === item.providerHoursOfOperationKey ? fromTime : item.openTime,
          closeTime: HoursOfOperationKey === item.providerHoursOfOperationKey ? toTime : item.closeTime,
          dayOfWeek: item.dayOfWeek,
          deleted: false,
          systemDefault: false,
          providerKey: this.providerKey,
          insertedUserKey: sessionStorage.getItem('userKey'),
          updatedUserKey: sessionStorage.getItem('userKey'),
          closed: isClosed
        };
        operationData.push(data);
      });

      this.loading = true;
      this.hoursOfOperationsService.updateHoursOfOperationBatch(operationData).subscribe((res: any) => {
        this.loading = false;
        this.getHoursOfOperation();
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    } else {
      const editData = {
        openTime: fromTime,
        closeTime: toTime,
        providerHoursOfOperationKey: HoursOfOperationKey,
        providerKey: this.providerKey,
        updatedUserKey: sessionStorage.getItem('userKey'),
        closed: isClosed
      };

      this.loading = true;
      this.hoursOfOperationsService.updateHoursOfOperation(editData).subscribe((result: any) => {
        if (result.success) {
          this.toast.success(result.message[0], 'Success');
          this.getHoursOfOperation();
        } else {
          this.toast.warning(result.message[0], 'Error');
        }

        this.loading = false;
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    }
  }

  openHolidayDialog() {
    const dialogRef = this.dialog.open(AddEditHolidayDialogComponent, {
      hasBackdrop: false,
      width: '70%',
      height: '85%',
      position: {
        top: '3%',
      },
      data: { providerKey: this.providerKey, isMasterLicensedOwner: this.isMasterLicensedOwner }
    });
  }

  setTiersCanSetHours(event) {
    const data = {
      providerKey: this.providerKey,
      tierCanSetHours: event.checked,
      updatedUserKey: sessionStorage.getItem('userKey')
    };

    this.providerService.updateProviderDetails(data).subscribe((result: any) => {
      sessionStorage.setItem('tierCanSetHours', event.checked);
    }, (error) => {
      this.loading = false;
      console.log(error);
    });

    const getParentKey = {
      parentKey: this.providerKey,
      deleted: false
    };

    this.providerService.getProviderDetails(getParentKey).subscribe((res: any) => {
      this.getChildData = res.body;
      if (this.getChildData.length > 0) {
        const childData = [];
        this.getChildData.forEach(x => childData.push({ providerKey: x.providerKey, tierCanSetHours: event.checked, updatedUserKey: sessionStorage.getItem('userKey') }));

        this.providerAdminService.updateProviderChildDetails(childData).subscribe((res: any) => { }, (error) => {
          console.log(error);
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  setHoursOfOperation() {
    if (this.parentData) {
      const operationData = [];
      this.Days.forEach(day => {
        const data = {
          openTime: '8:00 AM',
          closeTime: '8:00 AM',
          dayOfWeek: day,
          deleted: false,
          systemDefault: false,
          providerKey: this.providerKey,
          insertedUserKey: sessionStorage.getItem('userKey'),
          updatedUserKey: sessionStorage.getItem('userKey')
        };
        operationData.push(data);
      });

      this.loading = true;
      this.hoursOfOperationsService.updateHoursOfOperationBatch(operationData).subscribe((res: any) => {
        this.loading = false;
        this.getHoursOfOperation();
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    } else {
      const operationData = [];
      this.weekData.forEach(item => {
        const data = {
          providerHoursOfOperationKey: item.providerHoursOfOperationKey,
          openTime: '8:00 AM',
          closeTime: '8:00 AM',
          deleted: false,
          providerKey: this.providerKey,
          updatedUserKey: sessionStorage.getItem('userKey')
        };
        operationData.push(data);
      });

      this.loading = true;
      this.hoursOfOperationsService.updateHoursOfOperationBatch(operationData).subscribe((res: any) => {
        this.loading = false;
        this.getHoursOfOperation();
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    }
  }

  resetHoursOfOperation() {
    this.openTime = '8:00 AM';
    this.closeTime = '8:00 AM';
  }
}
