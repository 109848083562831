import { Component, OnInit, Input, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { ITreeOptions } from 'angular-tree-component';
import { MatDialog } from '@angular/material/dialog';

import { UtilityService } from 'src/app/services/utility.service';
import { BubbleService } from 'src/app/services/bubble.service';

@Component({
  selector: 'app-provider-management-services',
  templateUrl: './provider-management-services.component.html',
  styleUrls: ['./provider-management-services.component.scss']
})
export class ProviderManagementServicesComponent implements OnInit, OnChanges {
  @Input() providerDetails;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  loading = false;
  serviceData: any;
  providerKey: string;
  parentRecord = false;
  parentProviderKey: string;
  parentServiceCount = 0;
  serviceNames: any = [];
  selectedKey: any;

  options: ITreeOptions = {
    animateExpand: true,
    isExpandedField: 'expanded'
  };

  constructor(
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private bubbleService: BubbleService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.providerDetails.providerKey) {
      this.parentProviderKey = this.providerDetails.parentProviderKey;
      this.providerKey = this.providerDetails.providerKey;
      this.checkIsParent(this.parentRecord ? this.parentProviderKey : this.providerKey);
    }
  }

  checkIsParent(providerKey, updateRecord = false, isDeleted = false, OperationData = {}) {
    this.parentRecord = false;

    if (this.providerDetails?.isParent) {
      this.getProviderServiceOfferingsWithoutlicensedOwnerKey(providerKey, updateRecord, isDeleted, OperationData);
    } else {
      const data = {
        providerKey: this.providerKey,
        deleted: false
      };

      this.loading = true;
      this.bubbleService.getProviderService(data).subscribe(result => {
        this.loading = false;
        if (result.body.length > 0) {
          this.parentRecord = false;
        } else {
          this.parentRecord = true;
        }

        const selectedProviderKey = this.parentRecord ? this.parentProviderKey : this.providerKey;
        this.getProviderServiceOfferingsWithoutlicensedOwnerKey(selectedProviderKey, updateRecord, isDeleted, OperationData);
      }, (error) => {
        this.loading = false;
      });
    }
  }

  getProviderServiceOfferingsWithoutlicensedOwnerKey(providerKey, updateRecord = false, isDeleted = false, OperationData = {}) {
    this.serviceNames = [];
    this.parentServiceCount = 0;
    const filter = {
      providerKey
    };

    this.loading = true;
    this.bubbleService.getProviderServiceOfferingsWithoutlicensedOwnerKey(filter).subscribe(result => {
      this.loading = false;
      this.serviceData = result || [];
      this.filterActiveNodeNames(this.serviceData);

      if (updateRecord) {
        this.updateSelectedRecord(isDeleted, OperationData, providerKey);
      }
      if (this.selectedKey) this.expandSelectedSearchNode(this.serviceData, this.selectedKey, isDeleted);

    }, (error) => {
      this.loading = false;
    });
  }

  filterActiveNodeNames(data) {
    data.forEach(obj => {
      if (obj.deleted === 0) {
        this.serviceNames.push(obj.name);
        if (!obj.parentKey || obj.parentKey === '0' || obj.parentKey === 'NULL') {
          this.parentServiceCount++;
        }
      }

      if (obj.children) {
        this.filterActiveNodeNames(obj.children);
      }
    });
  }

  expandSelectedSearchNode(array, value, isExpand) {
    let found = false;
    array.forEach(element => {
      element.expanded = !isExpand ? this.expandSelectedSearchNode(element.children || [], value, isExpand) || element.key === value : false;
      found = found || element.expanded;
    });
    return found;
  }

  onSelectedChange(serviceData, activeState): void {
    const isDeleted = !activeState ? 1 : 0;

    if (this.parentRecord) {
      this.addProviderAccountDataIntoLocation(isDeleted, serviceData);
    } else {
      const arrReqData = {
        isDeleted,
        networkOwnerOfferingKey: serviceData.key,
        providerKey: this.parentRecord ? this.parentProviderKey : this.providerKey,
        providerServiceOfferingKey: serviceData.providerServiceOfferingKey,
      };

      this.loading = true;
      this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
        this.loading = false;
        this.selectedKey = serviceData.key;
        this.checkIsParent(this.parentRecord ? this.parentProviderKey : this.providerKey);
      });
    }
  }

  addProviderAccountDataIntoLocation(isDeleted, OperationData) {
    const data = {
      locationKey: this.providerKey,
      providerKey: this.parentProviderKey,
    };

    this.loading = true;
    this.bubbleService.setUpServiceAndRates(data).subscribe(result => {
      this.loading = false;
      this.selectedKey = OperationData.key;
      this.checkIsParent(this.providerKey, true, isDeleted, OperationData);
    }, (error) => {
      this.loading = false;
    });
  }

  updateSelectedRecord(isDeleted, operationData, providerKey) {
    const arrReqData = {
      isDeleted,
      networkOwnerOfferingKey: operationData.key,
      providerKey,
    };

    this.loading = true;
    this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
      this.loading = false;
      this.selectedKey = operationData.key;
      this.checkIsParent(providerKey);
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.serviceData = this.utilityService.sortData(this.headers, column, direction, this.serviceData);
  }
}
