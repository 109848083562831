import { Component, OnInit, QueryList, ViewChildren, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { patterns } from 'src/assets/patterns';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { UsersService } from 'src/app/services/users.service';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailDetailsDialogComponent } from '../email-details-dialog/email-details-dialog.component';

@Component({
  selector: 'app-add-user-into-invite-process',
  templateUrl: './add-user-into-invite-process.component.html',
  styleUrls: ['./add-user-into-invite-process.component.scss'],
})
export class AddUserIntoInviteProcessComponent implements OnInit, OnChanges {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() securityGroups: any = [];
  @Input() departmentList: any = [];
  @Input() rolesList: any = [];
  @Input() userInvitationList: any = [];
  @Input() associationKey: string;
  @Input() userTypeKey: string;
  @Input() isCustomer = false;
  @Output() listChanged = new EventEmitter<boolean>();

  addProviderForm: UntypedFormGroup;
  userInvitesList: any = [];
  tempPricing: any = [];
  usersList: any = [];
  isEmailExists: boolean;
  loading = false;
  disableEdit: boolean;
  searchString: string;
  departmentKey: string;
  licensedOwnerName = sessionStorage.getItem('licensedOwnerName');
  emailPattern = patterns.emailPattern;
  dataDictionaryKey = 'c53f0bc6-9002-45cc-8efd-e6949b4248c4';
  schema = 'FleetAssist';

  constructor(
    private usersService: UsersService,
    private userInviteProcess: UserInviteProcess,
    private utilityService: UtilityService,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    private dialogService: NbDialogService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.addProviderForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(patterns.emailPattern)]],
      cellPhone: [''],
      departmentKey: [''],
      supervisor: [''],
      contactCenterUserRoleKey: [''],
      securityGroupKey: ['', [Validators.required]],
    });
    if (!this.isCustomer) {
      this.addProviderForm.controls.departmentKey.setValidators([Validators.required]);
      this.addProviderForm.controls.departmentKey.updateValueAndValidity();
      this.addProviderForm.controls.contactCenterUserRoleKey.setValidators([Validators.required]);
      this.addProviderForm.controls.contactCenterUserRoleKey.updateValueAndValidity();
      this.addProviderForm.controls.supervisor.setValidators([Validators.required]);
      this.addProviderForm.controls.supervisor.updateValueAndValidity();
    }
    this.getUserInvitations();
    this.getExecDeptUsers();
  }

  ngOnChanges() {
    this.getUserInvitations();
  }

  getExecDeptUsers() {
    const objParam = {
      departmentKey: 'Executive',
      deleted: false,
    };

    this.usersService.getUsers(objParam).subscribe((result) => {
      result.body.map((data) => {
        if (data.contactCenterUserRoleKey !== 'Agent') {
          this.usersList.push(data);
        }
      });
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /****
    * Desc: Get Users by type
    * @params postData
    * @return array response
  ***/
  getUsersByType() {
    this.usersList = this.usersList.filter(obj => obj.departmentKey === 'Executive');
    if (this.departmentKey !== 'Executive') {
      const objParam = {
        userTypeKey: this.userTypeKey,
        departmentKey: this.departmentKey,
        deleted: false,
      };
      this.usersService.getUsers(objParam).subscribe((result) => {
        result.body.map((data) => {
          if (data.contactCenterUserRoleKey !== 'Agent') {
            this.usersList.push(data);
          }
        });
      }, (error) => {
      });
    }
  }

  /****
    * Desc: Get User invitations list.
    * @params postData
    * @return array response
  ***/
  getUserInvitations() {
    if (this.securityGroups.length > 0) {
      this.sortSecurityGroup({ column: 'description', direction: 'asc' });
    }
    this.userInvitesList = this.userInvitationList;
    if (this.userInvitesList.length > 0) {
      this.onSort({ column: 'insertedTimestamp', direction: 'desc' });
    }
  }

  /****
  * Desc: Add user invitations
  * @params postData
  * @return response true/false
  ***/
  addToInviteList(FormDirective: FormGroupDirective) {
    if (this.addProviderForm.invalid) {
      this.addProviderForm.markAllAsTouched();
      return false;
    }
    if (!!this.associationKey) {
      const insertData = {
        associationKey: this.associationKey,
        userTypeKey: this.userTypeKey,
        deleted: false,
        insertedUserKey: sessionStorage.getItem('userKey'),
        systemDefault: true,
        updatedUserKey: '',
        emailResponseDate: null,
        emailDate: null,
        subDomainKey: sessionStorage.getItem('subDomainKey') || null,
      };

      this.loading = true;
      this.userInviteProcess.addUserInvitations({ ...insertData, ...this.addProviderForm.value }).subscribe(result => {
        this.toast.success('User added to Invitation List', 'Success');
        this.addProviderForm.reset();
        FormDirective.resetForm();
        this.loading = false;
        this.listChanged.emit(true);
      }, (error) => {
        this.loading = false;
        this.toast.warning(error, 'Error');
      });
    } else {
      this.toast.danger('Please select Customer', 'Warning');
    }
  }

  /****
  * Update User invitation details
  * @params postData
  * @return array response
  ***/
  updateUserInvitations(data: any) {
    const emailRegex = new RegExp(patterns.emailPattern);
    const validEmail = emailRegex.test(data.email);

    if (data.firstName === '' || data.lastName === '' || data.email === ''
      || data.securityGroupKey === '' || this.isEmailExists || !validEmail) {
      return false;
    }

    if (!this.isCustomer) {
      if (data.departmentKey === '' || data.contactCenterUserRoleKey === '' || data.supervisor === '') {
        return false;
      }
    }

    const updateObjParams = {
      userInvitationKey: data.userInvitationKey,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      cellPhone: data.cellPhone,
      securityGroupKey: data.securityGroupKey,
      associationKey: data.associationKey,
      departmentKey: data.departmentKey,
      supervisor: data.supervisorKey,
      contactCenterUserRoleKey: data.contactCenterUserRoleKey,
      userTypeKey: data.userTypeKey,
      deleted: false,
      systemDefault: true,
      updatedUserKey: sessionStorage.getItem('userKey'),
      emailResponseDate: null,
      emailDate: null,
    };

    this.loading = true;
    this.userInviteProcess.updateUserInvitations(updateObjParams).subscribe((response) => {
      this.toast.success('User Invitation details Updated Successfully!', 'Success');
      this.loading = false;
      this.disableEdit = !this.disableEdit;
      this.listChanged.emit(true);
    }, (error) => {
      this.toast.warning(error.message, 'Error');
      this.loading = false;
    });
  }

  /****
  * Delete User invitation details
  * @params postData
  * @return array response
  ***/
  deleteUserInviteDetails(data: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const updateObjParams = {
          userInvitationKey: data.userInvitationKey,
          deleted: true,
          updatedUserKey: sessionStorage.getItem('userKey'),
        };
        this.loading = true;
        this.userInviteProcess.updateUserInvitations(updateObjParams).subscribe((response) => {
          this.toast.success('User Invitation details deleted Successfully!', 'Success');
          this.loading = false;
          this.listChanged.emit(true);
        }, (error) => {
          this.toast.warning(error.message, 'Error');
          this.loading = false;
        });
      }
    });
  }

  // edit inline user invite details.
  editInlineUserInvites(index: number) {
    this.tempPricing = { ...{}, ...this.userInvitesList[index] };
    this.departmentKey = this.userInvitesList[index].departmentKey;
    this.userInvitesList[index].editMode = !this.userInvitesList[index].editMode;
    this.disableEdit = !this.disableEdit;
    this.userInvitesList[index].cellPhone = this.userInvitesList[index].cellPhone.split('+1').join('');
    if (!this.isCustomer) {
      this.getUsersByType();
    }
  }

  openEmailDetails(user) {
    this.dialog.open(EmailDetailsDialogComponent, {
      width: '70%',
      height: '90%',
      disableClose: true,
      position: {
        top: '2%'
      },
      data: user,
    });
  }

  // Cancel update user invite details
  cancelUpdateUserInvite(index: number) {
    this.userInvitesList[index].editMode = !this.userInvitesList[index].editMode;
    this.disableEdit = !this.disableEdit;
    this.userInvitesList[index] = this.tempPricing;
    this.isEmailExists = false;
    this.addProviderForm.controls.email.setErrors({ isEmailExists: false });
  }

  // get users by department
  getUsersByDepartment(event) {
    if (!this.isCustomer) {
      this.departmentKey = event.value;
      this.getUsersByType();
      this.getSecurutyGroupsByDepartment();
    }
  }

  getSecurutyGroupsByDepartment() {
    const securityFilter = {
      platform: 'FleetAssist',
      userTypeKey: this.userTypeKey,
      departmentKey: this.departmentKey,
      deleted: false,
    };

    this.userInviteProcess.getSecurityGroups(securityFilter).subscribe(res => {
      if (res.success && res.body.length > 0) {
        this.securityGroups = res.body;
      }
    });
  }

  /****
  * Check email already exists in user inviations
  * @params postData email
  * @return array response
  ***/
  checkEmailAlreadyExists(event: any, data: any = {}) {
    this.isEmailExists = false;
    const objParams = { email: event.target.value };
    this.userInviteProcess.checkEmailInUserInvitations(objParams).subscribe(result => {
      if (result.body.length > 0) {
        if (data && (data.userInvitationKey === result.body[0].userInvitationKey)) {
          this.isEmailExists = false;
        } else {
          this.isEmailExists = true;
        }
        this.addProviderForm.controls.email.setErrors({ isEmailExists: true });
      }
    }, (error) => {
      this.loading = false;
    });
  }

  // Search data from table grid.
  search(event: any) {
    this.searchString = event;
  }

  // sort data from the table grid
  onSort({ column, direction }: SortEvent) {
    this.userInvitesList = this.utilityService.sortData(this.headers, column, direction, this.userInvitesList);
  }

  // sort security groups
  sortSecurityGroup({ column, direction }: SortEvent) {
    this.securityGroups = this.utilityService.sortData(this.headers, column, direction, this.securityGroups);
  }

  // Manage form errors.
  handleError = (control: string, error: string) => {
    return this.addProviderForm.controls[control].hasError(error);
  }

}
