<h1 mat-dialog-title>Add Customer To Do Not Use</h1>
<div mat-dialog-content>
    <form>
        <mat-form-field>
            <mat-label>Customer</mat-label>
            <input type="text" placeholder="Search Customer" matInput [formControl]="customerControl"
            [matAutocomplete]="customerAuto">
        <mat-autocomplete autoActiveFirstOption #customerAuto="matAutocomplete">
            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.CustomerName"
                (click)="getSubCustomerList(customer.CustomerKey)">
                {{customer.CustomerName}}
            </mat-option>
        </mat-autocomplete>
        </mat-form-field>
        <br>
        <mat-form-field *ngIf="showSubCustomer" >
            <mat-label>Sub Customer</mat-label>
            <input type="text" placeholder="Pick one" matInput [formControl]="subCustomerControl"
                [matAutocomplete]="subcustAuto" >
            <mat-autocomplete autoActiveFirstOption #subcustAuto="matAutocomplete">
                <mat-option *ngFor="let subcust of filteredSubCustomers | async" [value]="subcust.SubCustomerName"
                    (click)="getCustomerLocationList(subcust.SubCustomerKey)">
                    {{subcust.SubCustomerName}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <!-- <br>
        <mat-form-field *ngIf="showLocation">
            <mat-label>Ship To</mat-label>
            <mat-select>
                <mat-option *ngFor="let location of customerLocation" [value]="location.ShipToName"
                    (click)="setCustomerLocationKey(location.ShipToKey)">
                    {{location.ShipToName}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
        <br>
        <div class="" *ngIf="selectedForDNU">
            <mat-form-field>
                <mat-label>Do Not Use Reason</mat-label>
                <textarea matInput placeholder="Please state the reason for the DNU" [formControl]="reasonForDNUControl"></textarea>
                <!-- <mat-error *ngIf="handleError('note', 'required')">
                    Note is required.
                </mat-error> -->
            </mat-form-field>
        </div>
    </form>
    <div *ngIf="noCustomerSelectedMessage == true">
        Please Select a Customer to Add.
    </div>
    <br>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="addCustomerToDNUConfirmationDialog()">Add Customer To DNU</button> 
    </div>
</div>