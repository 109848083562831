<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h5 class="modal-title">{{dialogTitle}}</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    
    <div class="row">
        <div class="col-12">
            <ngx-search-input (search)="search($event)"></ngx-search-input>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="table-responsive approved-protocol-tbl">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th sortable="offerings" (sort)="onSort($event)" class="cursor-pointer align-middle" direction="asc">Offerings</th>
                            <th sortable="supportd" (sort)="onSort($event)" class="cursor-pointer align-middle">Supported</th>
                            <th sortable="operatingScheduleKey" (sort)="onSort($event)" class="align-middle">Schedule</th>
                            <th sortable="agentProtocol" (sort)="onSort($event)" class="cursor-pointer align-middle">Agent Protocol</th>
                            <th sortable="providerProtocol" (sort)="onSort($event)" class="cursor-pointer align-middle">Provider Protocol</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="arrProtocolDetails.length > 0; else noData">
                        <tr *ngFor="let item of arrProtocolDetails | filter : searchString">
                            <td>{{item.offerings}}</td>
                            <td>{{ item.supported == true ? 'Yes' : 'No' }}</td>
                            <td>{{item.operatingScheduleKey}}</td>
                            <td>{{item.agentProtocol}}</td>
                            <td>{{item.providerProtocol}}</td>
                            <td>
                                <div>
                                    <button class="btn btn-default btnAnimation" nbTooltip="Edit" (click)="editCustomerProtocols(item)">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                    <button  class="btn btn-default btnAnimation" nbTooltip="Delete" (click)="removeCustomerProtocols(item)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </div>                                
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="5">No data available.</td>
                            </tr>
                        </tbody>
                    </ng-template>                    
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer text-right">
    <button type="button" class="btn btn-success btn-sm mr-1" (click)="exportToCSVServices()">Print Report</button>
    <button class="btn btn-primary btn-sm ml-1" (click)="closeDialog()">Close</button>
</div>
