import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  strLabel: string = "This job is NOT listed as a Common Job (that is available for New Job selection). Do you want to set it as a Common Job before you close?.";

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, protected ref: MatDialogRef<ConfirmationDialogComponent>) {
    this.strLabel = data.label;
  }

  ngOnInit() {
  }

  submit(val) {
    this.ref.close(val);
  }

}
