import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-add-notes-dialog',
  templateUrl: './add-notes-dialog.component.html',
  styleUrls: ['./add-notes-dialog.component.scss']
})
export class AddNotesDialogComponent implements OnInit {

  loading = false;
  addNoteForm: UntypedFormGroup;
  providerAccountKey: string;

  constructor(
    public dialog: MatDialogRef<AddNotesDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private providerAdminService: ProviderAdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
  ) { }

  ngOnInit() {
    this.addNoteForm = this.formBuilder.group({
      note: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      privateForAccount: [true]
    });
    this.providerAccountKey = this.data.providerAccountKey;
  }

  closeDialog() {
    this.dialog.close();
  }

  handleError = (control: string, error: string) => {
    return this.addNoteForm.controls[control].hasError(error);
  }

  addNote() {
    if (this.addNoteForm.invalid) {
      this.addNoteForm.markAllAsTouched();
      return false;
    }

    const insertData = {
      deleted: false,
      note: this.addNoteForm.controls.note.value,
      privateForAccount: !this.addNoteForm.controls.privateForAccount.value,
      insertedUserKey: sessionStorage.getItem('userKey'),
      privateForProvider: false,
      providerAccountKey: this.providerAccountKey,
      providerAccountNoteKey: null,
      systemDefault: false,
      updatedUserKey: ''
    };

    this.loading = true;
    this.providerAdminService.addProviderAccountNotes(insertData).subscribe(result => {
      this.loading = false;
      if (result.success) {
        this.dialog.close();
        this.toast.success(result.message[0], 'Success');
      } else {
        this.toast.warning(result.message[0], 'Error');
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

  }

}
