import { Component, OnInit, NgModule } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators, ReactiveFormsModule, UntypedFormGroup, FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NbCardModule, NbMenuModule, NbToastrService } from '@nebular/theme';
import { CommonService } from 'src/app/services/common.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { ProviderAdminListComponent } from 'src/app/dialogs/provider-admin-list/provider-admin-list.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationDialogComponent } from '../provider-match/confirmation-dialog/confirmation-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { ProviderSearchResultsTableComponent } from './provider-search-results-table/provider-search-results-table.component';
import { DatePipe } from '@angular/common';
import { LocationService } from 'src/app/services/location.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'ngx-provider-migration',
  standalone: true,
  imports: [ NbCardModule,
    NbMenuModule, 
    RouterModule, 
    SharedModule, 
    CommonModule, 
    MatTableModule, 
    FormsModule, 
    MatInputModule, 
    ReactiveFormsModule, 
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule],
  templateUrl: './provider-migration.component.html',
  styleUrls: ['./provider-migration.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntl }, // Here
  ],
})
export class ProviderMigrationComponent {

  constructor(
    private providerAdminService: ProviderAdminService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private commonService: CommonService,
    private utilityService: UtilityService,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private locationService: LocationService,
  ) { }

  axelPointProviderSearch: UntypedFormGroup;
  providerSelected: boolean = false;
  providerLocationSelected: boolean = false;
  selectedProviderLocation: string;
  parentProviderData: any;
  locationProviderData: any;
  loading = false;

  axelPointProvidersList: any = [];
  axelPointParentList: any = [];
  axelPointSublocationList: any = [];
  axelPointLocationsDetailsList: any = []
  auxiliumProvidersList: any = [];
  auxiliumParentList: any = [];
  providerParentID: any;
  
  noAxelPointProviderFound: boolean = false;
  axelPointProviderFound: boolean = false;
  providerSearchComplete: boolean = false;

  parentLocationOnBoth: boolean = false;
  providerLocationOnBoth: boolean = false;
  providerInformationMatchesOnBoth: boolean = true;

  numberOfProviderLocations: any;
  numberLocations: any;

  currentUTCTimeStamp: any;
  newAddedProviderInfo: any;
  newGetAccountKey: any;

  listOfCheckedSublocationsToAdd: any = [];
  listOfAllSublocationsToAdd: any = [];
  addingParentAndSublocations: boolean = false;

  ngOnInit(){
    this.axelPointProviderSearch = this.formBuilder.group({
      providerID: ['', [Validators.required]],
      locationProviderKey: [''],
  })

  //this.insertprovideraccounts()
  }

  searchForAxelPointProviders(){

    this.axelPointLocationsDetailsList = [];
    this.providerLocationOnBoth = false;
    this.axelPointProviderFound = false;
    this.providerSearchComplete = false;
    this.loading = true;
    this.newAddedProviderInfo = [];
    this.newGetAccountKey = [];

    if(!this.axelPointProviderSearch.controls.providerID.value){
      this.openSnackBar('Provider ID is required for search');
      this.noAxelPointProviderFound = false;
      return;
    }

    this.providerAdminService.getProviderLocationForServicePlus(this.axelPointProviderSearch.value.providerID).subscribe({
      next: res => this.axelPointProvidersList = res.body,
      error: (e) => console.log(e),
      complete: () => this.axelSearchProviderResults(),
    })

    // let dialogRef = this.dialog.open(ProviderSearchResultsTableComponent, {
    //   hasBackdrop: true,
    //   width: '50%',
    //   data: searchAxelData,
    // });

    // dialogRef.afterClosed().subscribe((response) => {
    //   if (!response){
    //     this.providerSelected = false;
    //     this.providerLocationSelected = false;
    //     return false;}})

  }

  axelSearchProviderResults(){
    if( this.axelPointProvidersList.length == 0 || this.axelPointProvidersList.length == 1 ){
      this.noAxelPointProviderFound = true;
      this.axelPointProviderFound = false;
    }
    else if( this.axelPointProvidersList.length > 1) {
      console.log('results found');
      this.noAxelPointProviderFound = false;
      this.providerParentID = this.axelPointProviderSearch.value.providerID;
      this.axelPointProviderFound = true;
      this.numberOfProviderLocations = (this.axelPointProvidersList.length) - 1; 
      this.searchForAuxiliumProviders();

      this.axelPointParentList = this.axelPointProvidersList.filter(obj => obj.AddressType === 'Mailing');
      this.axelPointSublocationList = this.axelPointProvidersList.filter(obj => obj.AddressType === 'Physical')
    }
  }

  searchForAuxiliumProviders(){
    //this.providerLocationSelected = false;

    // if(!this.axelPointProviderSearch.controls.providerID.value){
    //   this.openSnackBar('Provider ID is required for search')
    //   return;
    // }

    const searchData = {
      providerID: this.axelPointProviderSearch.value.providerID
    }

      this.providerAdminService.getProviderParentLocations(searchData).subscribe({
        next: res => {
          this.auxiliumParentList = res.body;
          //this.onSort({ column: 'Name', direction: 'asc' });
        },
        error: (e) => console.log(e),
        complete: () => {console.log('auxilum search completed');
          if(this.auxiliumParentList.length == 0){
            this.parentLocationOnBoth = false;
            this.getOnlyAxelLocations();
          }
          else if(this.auxiliumParentList.length > 0){
            this.parentLocationOnBoth = true;
            this.getLocationList(this.auxiliumParentList[0].ProviderKey)
          }
        } //this.searchCompleted = true,
       }
    )

    // const dialogRef = this.dialog.open(ProviderAdminListComponent, {
    //   hasBackdrop: true,
    //   width: '50%',
    //   data: searchData,
    // });

    // dialogRef.afterClosed().subscribe((response) => {
    //   if (!response){
    //     this.providerSelected = false;
    //     this.providerLocationSelected = false;
    //     return false;}

    //   const provider = response;
    //   this.parentProviderData = response; 

    //   this.providerSelected = true;

    //   this.getLocationList(provider.ProviderKey);

    //   sessionStorage.setItem('providerKey', provider.ProviderKey);
    //   sessionStorage.setItem('providerParentKey', response.ParentKey);

    //   sessionStorage.setItem('SPCompanyName', response.Name);
    //   sessionStorage.setItem('currentTimezone', response.timeZoneKey);
    //   sessionStorage.setItem('tierCanSetHours', response.tierCanSetHours);
    //   sessionStorage.setItem('dispatchableTier', response.dispatchableTier);
    //   sessionStorage.setItem('tierCanSetRates', response.tierCanSetRates);
    //   sessionStorage.setItem('providerId', response.providerId);
  
    // })
  }

  getLocationList(providerKey) {
    // this.isSelectProvider = true;
    // this.providerDetails = [];
    // this.providerAdminForm.controls.locationKey.reset();
    this.locationProviderData = [];
    this.axelPointProviderSearch.controls.locationProviderKey.reset()

    const data = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      location: true,
      parentKey: providerKey,
      searchText: null
    };

    this.loading = true;
    this.providerAdminService.getProviderAdminList(data).subscribe(res => {
    this.loading = false;
    this.auxiliumProvidersList = res.body;
    //this.numberOfProviderLocations = res.body.length;  
   // this.getProviderLocationInfo()


      // if (setLocation && this.locationKey) {
      //   this.providerAdminForm.controls.locationKey.setValue(this.locationKey);
      //   this.providerDetails = this.providerLocationList.find(obj => obj.providerKey === this.locationKey);
      // }
      this.matchLocations();
    }, (error) => {
      console.log('error,', error);
      this.loading = false;
    });
  
  }

  getOnlyAxelLocations(){

    this.axelPointLocationsDetailsList = this.axelPointSublocationList;

    this.axelPointLocationsDetailsList.forEach(obj1 => {
      obj1.existsOnServicePlus = false;}
    )

    this.providerLocationOnBoth = false;
    this.providerSearchComplete = true;
    this.loading = false;

  }

  matchLocations(){

    // let axelPointLocationProviderId = this.axelPointProvidersList.filter(obj => obj.ProviderId === obj.ProviderId)
    // console.log(axelPointLocationProviderId);

    // let AxelProviderID = this.axelPointProvidersList.map(item => item.AddressId);
    // let AuxiliumProviderID = this.auxiliumProvidersList.map(item => item.addressId)

    // for( let i = 0; i < this.axelPointProvidersList.length; i++){
    //   this.axelPointProvidersList[i].AddressId.filter((AuxiliumProviderID ))
    // }
    // this.axelPointProvidersList

    // const sharedAddressId = AxelProviderID.filter(s => )

    // console.log(AxelProviderID)
    // console.log(AuxiliumProviderID)

    this.axelPointLocationsDetailsList = this.axelPointSublocationList;
    let array2 = this.auxiliumProvidersList;

    this.axelPointLocationsDetailsList.forEach(obj1 => {
      if (array2.some(obj2 => obj2.addressId === obj1.AddressId)){
        obj1.existsOnServicePlus = true;
      }
      else{obj1.existsOnServicePlus = false;}
    })

    console.log(this.axelPointLocationsDetailsList)

    // DO NOT DELETE. Is used to set if location is found on both. 
    this.providerLocationOnBoth = this.axelPointLocationsDetailsList.every(obj => obj.existsOnServicePlus === true)
    if(this.providerLocationOnBoth){
      this.providerLocationOnBoth = true
    }
    else{ this.providerLocationOnBoth = false}

    this.providerSearchComplete = true;
    this.loading = false;

  }

  getProviderLocationInfo(){
    this.auxiliumProvidersList[0].providerKey;

    this.providerLocationSelected = true;
    //this.selectedProviderLocation = event.value;
    // const location = this.providerLocationList.find(obj => obj.providerKey === this.selectedProviderLocation);
    // this.locationProviderData = this.providerLocationList.find(obj => obj.providerKey === this.selectedProviderLocation);

    // location.parentProviderKey = this.parentProviderData.ProviderKey;
    // location.parentProviderAccountKey = this.parentProviderData.ProviderAccountKey;
    // location.isParent = false;
    this.locationProviderData = this.auxiliumProvidersList
    this.locationProviderData.parentProviderKey = this.parentProviderData.ProviderKey;
    this.locationProviderData.parentProviderAccountKey = this.parentProviderData.ProviderAccountKey;
    this.locationProviderData.isParent = false;

    console.log(location)
    console.log(this.locationProviderData)
  }

  update(event){
    console.log(event);
    const ranVal = event.source.value;
    if(event.checked == true) {
          this.listOfCheckedSublocationsToAdd.push(event.source.value) 
    }
    else if(event.checked == false){
      //this.listOfSublocationsToAdd.remove(event.source.value) 
      this.listOfCheckedSublocationsToAdd = this.listOfCheckedSublocationsToAdd.filter(item => item.ProviderId !== event.source.value.ProviderId)
    }
   // console.log(ranVal)
    console.log(this.listOfCheckedSublocationsToAdd)
    //console.log(this.provider.checked)
  }
  // onSelectLocation(event){
  //   console.log(event)
  //   this.locationProviderData = [];
  //   if(event.value == ''){
  //     this.providerLocationSelected = false;
  //     return;
  //   }
  //   this.providerLocationSelected = true;
  //   this.selectedProviderLocation = event.value;
  //   const location = this.providerLocationList.find(obj => obj.providerKey === this.selectedProviderLocation);
  //   this.locationProviderData = this.providerLocationList.find(obj => obj.providerKey === this.selectedProviderLocation);

  //   location.parentProviderKey = this.parentProviderData.ProviderKey;
  //   location.parentProviderAccountKey = this.parentProviderData.ProviderAccountKey;
  //   location.isParent = false;

  //   this.locationProviderData.parentProviderKey = this.parentProviderData.ProviderKey;
  //   this.locationProviderData.parentProviderAccountKey = this.parentProviderData.ProviderAccountKey;
  //   this.locationProviderData.isParent = false;

  //   console.log(location)
  //   console.log(this.locationProviderData)
  // }

  confirmAddProvider(){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Migrate Parent Provider', message: 'Are you sure you want to add this provider to Service Plus?',
        yesButton: 'Add Provider'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
      if(result == true){
        console.log('accepted ')
        this.addParentProvider();
      }
    });
  }

  confirmAddProviderLocation(num){
    if(num == 3){
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {header: 'Migrate Provider Sublocation', message: 'Are you sure you want to add this provider sublocation to Service Plus?',
          yesButton: 'Add Provider'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){         
          this.addProviderSublocation(1);
          console.log("adding one missing location")    
        }
      });
    }

    else if(num == 1){
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {header: 'Migrate Provider Sublocations', message: 'Are you sure you want to add all of the missing provider sublocations to Service Plus?',
          yesButton: 'Add Provider'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){         
          this.addProviderSublocation(1);
          console.log("adding all missing locations")    
        }
      });
    }

    else if(num == 2){
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {header: 'Migrate Provider Sublocations', message: 'Are you sure you want to add the selected provider sublocations to Service Plus?',
          yesButton: 'Add Provider'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){         
          this.addProviderSublocation(2);
          console.log("adding all checked locations")    
        }
      });
    }

    // let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //   data: {header: 'Migrate Provider Sublocation', message: 'Are you sure you want to add provider sublocations to Service Plus?',
    //     yesButton: 'Add Provider'
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if(result == true){
    //     console.log('accepted ')
    //     if(num === 1){
    //       this.addProviderSublocation(1);
    //       console.log("adding all missing locations")
    //       }
    //     else if(num === 2){
    //       console.log("adding all checked locations")
    //       this.addProviderSublocation(2);
    //     }
    //   }
    // });
  }

  confirmAddProviderParentAndLocation(num){

    if(num == 3){
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {header: 'Migrate Provider and Sublocation', message: 'Are you sure you want to add this provider and sublocation to Service Plus?',
          yesButton: 'Add Provider'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){ 
          this.addingParentAndSublocations = true;       
          this.addParentProviderAndLocation(1);
          console.log("adding parent and one missing location")    
        }
      });
    }

    else if(num == 2){
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {header: 'Migrate Provider and Sublocation', message: 'Are you sure you want to add this provider and the selected sublocations to Service Plus?',
          yesButton: 'Add Provider'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){         
          this.addParentProviderAndLocation(2);
          this.addingParentAndSublocations = true;       
          console.log("adding parent and checked missing locations")    
        }
      });
    }

    else if(num == 1){
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {header: 'Migrate Provider and Sublocation', message: 'Are you sure you want to add this provider and all sublocations to Service Plus?',
          yesButton: 'Add Provider'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){         
          this.addParentProviderAndLocation(1);
          this.addingParentAndSublocations = true;       
          console.log("adding parent and all missing location")    
        }
      });
    }

    // let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //   data: {header: 'Migrate Provider and Sublocation', message: 'Are you sure you want to add this parent provider and provider sublocations to Service Plus?',
    //     yesButton: 'Add Provider and Sublocation'
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(result)
    //   console.log('The dialog was closed');
    //   if(result == true){
    //     console.log('accepted ')
    //   }
    // });
  }

  addParentProvider(){
    
    const activationDate = new Date().toISOString();
    this.currentUTCTimeStamp = this.datePipe.transform(activationDate, "YYYY-MM-dd HH:mm:ss.SSS", "UTC")
    console.log(this.currentUTCTimeStamp)
    this.currentUTCTimeStamp = this.utilityService.getCurrentTimestamp();

    const providerData ={
      _AxlePointColumn : "na",
      _AxlePointKey : "na",
      _AxlePointTable : "na",
      afterHoursPhone : this.axelPointParentList[0].AfterHoursPhone,
      billingEmail : this.axelPointParentList[0].BillingEmail,
      billingFax : this.axelPointParentList[0].BillingFax,
      billingPhone : this.axelPointParentList[0].BillingPhone,
      businessHoursPhone : this.axelPointParentList[0].BusinessHoursPhone,
      companyFEIN : this.axelPointParentList[0].CompanyFEIN,
      deleted : false, //0,
      dispatchableTier : 0,
      dispatchEmail : this.axelPointParentList[0].DispatchEmail,
      dispatchFax : this.axelPointParentList[0].DispatchFax,
      dispatchStatusKey : this.axelPointParentList[0].DispatchStatusKey,
      hasCentralBilling : 1,
      hasCentralDispatch : 0,
      industryKey : "4d802423-8db6-4b13-90c8-7466fcf6db24", 
      inNetwork : this.axelPointParentList[0].InNetwork,
      insertedTimestamp : this.currentUTCTimeStamp,
      insertedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
      mailingAddress1 : this.axelPointParentList[0].Address,
      mailingCity : this.axelPointParentList[0].City,
      mailingPostalCode : this.axelPointParentList[0].PostalCode,
      mailingStateKey : this.axelPointParentList[0].StateKey,
      name : "TEST TEST TEST", //this.axelPointParentList[0].Name,
      networkOwnerKey : "17c2198a-9d02-45dc-b1b9-218b94d3ae31",
      parentKey : 0,
      paymentMethodKey : this.axelPointParentList[0].PaymentMethodKey,
      paymentTermKey : this.axelPointParentList[0].PaymentTermKey,
      providerDataSourceKey : "ServicePlusProviderMigration",
      providerId : this.axelPointParentList[0].ProviderId,
      providerKey : this.axelPointParentList[0].ProviderKey,
      systemDefault : 0, 
      tierCanSetHours : 1,
      tierCanSetRates : 1,
      tierLevel : 1,
      tollFreePhone : this.axelPointParentList[0].TollFreePhone,
      updatedTimestamp : this.currentUTCTimeStamp, 
      updatedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
      
      // "MailingCountry" : None,
      // "PhysicalPostalCode" : None,
      // "PhysicalCountry" : None,
      // "PhysicalAddress1" : None,
      // "PhysicalCity" : None,
      // "PhysicalStateKey" : None,
      // "PhysicalLatitude" : None,
      // "PhysicalLongitude" : None,
    }

    console.log(providerData)

    this.locationService.addLocationDetails(providerData).subscribe({
      next: res => this.newAddedProviderInfo = res?.body,
      error: (e) => console.log(e),
      complete: () =>{this.insertprovideraccounts(this.newAddedProviderInfo[0].providerKey)},
      //complete: () =>{this.searchForAxelPointProviders()},
    })
    
  }

  addProviderSublocation(num){

    if(num === 1) {
      this.listOfAllSublocationsToAdd = this.axelPointProvidersList.filter(obj => obj.existsOnServicePlus === false)
    }

    else if (num === 2) {
      this.listOfAllSublocationsToAdd = this.listOfCheckedSublocationsToAdd;
    }

  const activationDate = new Date().toISOString();
  this.currentUTCTimeStamp = this.datePipe.transform(activationDate, "YYYY-MM-dd HH:mm:ss.SSS", "UTC")
  console.log(this.currentUTCTimeStamp)
  this.currentUTCTimeStamp = this.utilityService.getCurrentTimestamp();

  for(let i = 0; i < this.listOfAllSublocationsToAdd.length; i++){
    let providerDataSublocation = {
      deleted : 0,
      providerKey : this.listOfAllSublocationsToAdd[i].ProviderKey,
      _AxlePointTable : "na",
      _AxlePointColumn : "na",
      _AxlePointKey : "na",
      networkOwnerKey : "17c2198a-9d02-45dc-b1b9-218b94d3ae31",
      industryKey : "4d802423-8db6-4b13-90c8-7466fcf6db24", 
      tierLevel : 2,
      parentKey : this.auxiliumParentList[0].ProviderKey, //this.newAddedProviderInfo[0].providerKey,
      inNetwork : this.listOfAllSublocationsToAdd[i].InNetwork,
      name : "TEST TEST TEST Sublocation", //this.axelPointParentList[0].Name,
      providerId : this.listOfAllSublocationsToAdd[i].AddressId,
      dispatchableTier : 2,
      tierCanSetHours : 1,
      tierCanSetRates : 1,
      companyFEIN : this.listOfAllSublocationsToAdd[i].CompanyFEIN,
      paymentTermKey : this.listOfAllSublocationsToAdd[i].PaymentTermKey,
      paymentMethodKey : this.listOfAllSublocationsToAdd[i].PaymentMethodKey,
      tollFreePhone : this.listOfAllSublocationsToAdd[i].TollFreePhone,
      businessHoursPhone : this.listOfAllSublocationsToAdd[i].BusinessHoursPhone,
      afterHoursPhone : this.listOfAllSublocationsToAdd[i].AfterHoursPhone,
      dispatchStatusKey : this.listOfAllSublocationsToAdd[i].DispatchStatusKey,
      hasCentralDispatch : 0,
      dispatchEmail : this.listOfAllSublocationsToAdd[i].DispatchEmail,
      dispatchFax : this.listOfAllSublocationsToAdd[i].DispatchFax,
      hasCentralBilling : 1,
      billingFax : this.listOfAllSublocationsToAdd[i].BillingFax,
      billingEmail : this.listOfAllSublocationsToAdd[i].BillingEmail,
      PhysicalPostalCode : this.listOfAllSublocationsToAdd[i].PostalCode,
      PhysicalAddress1 : this.listOfAllSublocationsToAdd[i].Address,
      PhysicalCity : this.listOfAllSublocationsToAdd[i].City,
      PhysicalStateKey : this.listOfAllSublocationsToAdd[i].StateKey,
      PhysicalLatitude : this.listOfAllSublocationsToAdd[i].Latitude,
      PhysicalLongitude : this.listOfAllSublocationsToAdd[i].Longitude,
      systemDefault : 0, 
      insertedTimestamp : this.currentUTCTimeStamp,
      insertedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
      updatedTimestamp : this.currentUTCTimeStamp, 
      updatedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
      billingPhone : this.listOfAllSublocationsToAdd[i].BillingPhone,
      providerDataSourceKey : "ServicePlusProviderMigration",
    }

    console.log(providerDataSublocation)

    this.locationService.addLocationDetails(providerDataSublocation).subscribe({
      next: res => this.newAddedProviderInfo = res?.body,
      error: (e) => console.log(e),
      complete: () => this.insertprovideraccounts(this.listOfAllSublocationsToAdd[i].ProviderKey),
    })
  }
}

addParentProviderAndLocation(num){
    
  const activationDate = new Date().toISOString();
  this.currentUTCTimeStamp = this.datePipe.transform(activationDate, "YYYY-MM-dd HH:mm:ss.SSS", "UTC")
  console.log(this.currentUTCTimeStamp)
  this.currentUTCTimeStamp = this.utilityService.getCurrentTimestamp();

  const providerData ={
    _AxlePointColumn : "na",
    _AxlePointKey : "na",
    _AxlePointTable : "na",
    afterHoursPhone : this.axelPointParentList[0].AfterHoursPhone,
    billingEmail : this.axelPointParentList[0].BillingEmail,
    billingFax : this.axelPointParentList[0].BillingFax,
    billingPhone : this.axelPointParentList[0].BillingPhone,
    businessHoursPhone : this.axelPointParentList[0].BusinessHoursPhone,
    companyFEIN : this.axelPointParentList[0].CompanyFEIN,
    deleted : false, //0,
    dispatchableTier : 0,
    dispatchEmail : this.axelPointParentList[0].DispatchEmail,
    dispatchFax : this.axelPointParentList[0].DispatchFax,
    dispatchStatusKey : this.axelPointParentList[0].DispatchStatusKey,
    hasCentralBilling : 1,
    hasCentralDispatch : 0,
    industryKey : "4d802423-8db6-4b13-90c8-7466fcf6db24", 
    inNetwork : this.axelPointParentList[0].InNetwork,
    insertedTimestamp : this.currentUTCTimeStamp,
    insertedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
    mailingAddress1 : this.axelPointParentList[0].Address,
    mailingCity : this.axelPointParentList[0].City,
    mailingPostalCode : this.axelPointParentList[0].PostalCode,
    mailingStateKey : this.axelPointParentList[0].StateKey,
    name : "TEST TEST TEST", //this.axelPointParentList[0].Name,
    networkOwnerKey : "17c2198a-9d02-45dc-b1b9-218b94d3ae31",
    parentKey : 0,
    paymentMethodKey : this.axelPointParentList[0].PaymentMethodKey,
    paymentTermKey : this.axelPointParentList[0].PaymentTermKey,
    providerDataSourceKey : "ServicePlusProviderMigration",
    providerId : this.axelPointParentList[0].ProviderId,
    providerKey : this.axelPointParentList[0].ProviderKey,
    systemDefault : 0, 
    tierCanSetHours : 1,
    tierCanSetRates : 1,
    tierLevel : 1,
    tollFreePhone : this.axelPointParentList[0].TollFreePhone,
    updatedTimestamp : this.currentUTCTimeStamp, 
    updatedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
    
    // "MailingCountry" : None,
    // "PhysicalPostalCode" : None,
    // "PhysicalCountry" : None,
    // "PhysicalAddress1" : None,
    // "PhysicalCity" : None,
    // "PhysicalStateKey" : None,
    // "PhysicalLatitude" : None,
    // "PhysicalLongitude" : None,
  }

  console.log(providerData)

  //this.addProviderSublocationAfterParent(num);

  this.locationService.addLocationDetails(providerData).subscribe({
    next: res => this.newAddedProviderInfo = res?.body,
    error: (e) => console.log(e),
    complete: () =>{
      this.insertprovideraccounts(this.newAddedProviderInfo[0].providerKey)
      this.addProviderSublocationAfterParent(num)},
  })
  
}

addProviderSublocationAfterParent(num){

  if(num === 1) {
    this.listOfAllSublocationsToAdd = this.axelPointProvidersList.filter(obj => obj.existsOnServicePlus === false)
  }

  else if (num === 2) {
    this.listOfAllSublocationsToAdd = this.listOfCheckedSublocationsToAdd;
  }

  const activationDate = new Date().toISOString();
  this.currentUTCTimeStamp = this.datePipe.transform(activationDate, "YYYY-MM-dd HH:mm:ss.SSS", "UTC")
  console.log(this.currentUTCTimeStamp)
  this.currentUTCTimeStamp = this.utilityService.getCurrentTimestamp();

  for(let i = 0; i < this.listOfAllSublocationsToAdd.length; i++){
    let providerDataSublocation = {
      deleted : 0,
      providerKey : this.listOfAllSublocationsToAdd[i].ProviderKey,
      _AxlePointTable : "na",
      _AxlePointColumn : "na",
      _AxlePointKey : "na",
      networkOwnerKey : "17c2198a-9d02-45dc-b1b9-218b94d3ae31",
      industryKey : "4d802423-8db6-4b13-90c8-7466fcf6db24", 
      tierLevel : 2,
      parentKey : this.axelPointParentList[0].ProviderKey,
      inNetwork : this.listOfAllSublocationsToAdd[i].InNetwork,
      name : "TEST TEST TEST Sublocation", //this.axelPointParentList[0].Name,
      providerId : this.listOfAllSublocationsToAdd[i].AddressId,
      dispatchableTier : 2,
      tierCanSetHours : 1,
      tierCanSetRates : 1,
      companyFEIN : this.listOfAllSublocationsToAdd[i].CompanyFEIN,
      paymentTermKey : this.listOfAllSublocationsToAdd[i].PaymentTermKey,
      paymentMethodKey : this.listOfAllSublocationsToAdd[i].PaymentMethodKey,
      tollFreePhone : this.listOfAllSublocationsToAdd[i].TollFreePhone,
      businessHoursPhone : this.listOfAllSublocationsToAdd[i].BusinessHoursPhone,
      afterHoursPhone : this.listOfAllSublocationsToAdd[i].AfterHoursPhone,
      dispatchStatusKey : this.listOfAllSublocationsToAdd[i].DispatchStatusKey,
      hasCentralDispatch : 0,
      dispatchEmail : this.listOfAllSublocationsToAdd[i].DispatchEmail,
      dispatchFax : this.listOfAllSublocationsToAdd[i].DispatchFax,
      hasCentralBilling : 1,
      billingFax : this.listOfAllSublocationsToAdd[i].BillingFax,
      billingEmail : this.listOfAllSublocationsToAdd[i].BillingEmail,
      PhysicalPostalCode : this.listOfAllSublocationsToAdd[i].PostalCode,
      PhysicalAddress1 : this.listOfAllSublocationsToAdd[i].Address,
      PhysicalCity : this.listOfAllSublocationsToAdd[i].City,
      PhysicalStateKey : this.listOfAllSublocationsToAdd[i].StateKey,
      PhysicalLatitude : this.listOfAllSublocationsToAdd[i].Latitude,
      PhysicalLongitude : this.listOfAllSublocationsToAdd[i].Longitude,
      systemDefault : 0, 
      insertedTimestamp : this.currentUTCTimeStamp,
      insertedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
      updatedTimestamp : this.currentUTCTimeStamp, 
      updatedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
      billingPhone : this.listOfAllSublocationsToAdd[i].BillingPhone,
      providerDataSourceKey : "ServicePlusProviderMigration",
    }

    console.log(providerDataSublocation)

    this.locationService.addLocationDetails(providerDataSublocation).subscribe({
      next: res => this.newAddedProviderInfo = res?.body,
      error: (e) => console.log(e),
      complete: () => this.insertprovideraccounts(this.listOfAllSublocationsToAdd[i].ProviderKey),
    })
  }
  }

  insertprovideraccounts(providerKey){

    this.providerAdminService.insertProviderAccounts(providerKey).subscribe({
      next: res => this.newGetAccountKey = res?.body,
      error: (e) => {console.error(e),  this.openSnackBar('Unable to add provider.')},
      complete: () => {this.openSnackBar('Providers added to Serviceplus.');
        this.searchForAxelPointProviders();
      }
     });

  }


    // const activationDate = new Date().toISOString();
    // this.currentUTCTimeStamp = this.datePipe.transform(activationDate, "YYYY-MM-dd HH:mm:ss.SSS", "UTC")
    // console.log(this.currentUTCTimeStamp)
    // this.currentUTCTimeStamp = this.utilityService.getCurrentTimestamp();

    // const providerDataSublocation ={
    //   deleted : 0,
    //   providerKey : this.axelPointSublocationList[0].ProviderKey,
    //   _AxlePointTable : "na",
    //   _AxlePointColumn : "na",
    //   _AxlePointKey : "na",
    //   networkOwnerKey : "17c2198a-9d02-45dc-b1b9-218b94d3ae31",
    //   industryKey : "4d802423-8db6-4b13-90c8-7466fcf6db24", 
    //   tierLevel : 2,
    //   parentKey : this.auxiliumParentList[0].ProviderKey,
    //   inNetwork : this.axelPointSublocationList[0].InNetwork,
    //   name : "TEST TEST TEST Sublocation", //this.axelPointParentList[0].Name,
    //   providerId : this.axelPointSublocationList[0].ProviderId,
    //   dispatchableTier : 2,
    //   tierCanSetHours : 1,
    //   tierCanSetRates : 1,
    //   companyFEIN : this.axelPointSublocationList[0].CompanyFEIN,
    //   paymentTermKey : this.axelPointSublocationList[0].PaymentTermKey,
    //   paymentMethodKey : this.axelPointSublocationList[0].PaymentMethodKey,
    //   tollFreePhone : this.axelPointSublocationList[0].TollFreePhone,
    //   businessHoursPhone : this.axelPointSublocationList[0].BusinessHoursPhone,
    //   afterHoursPhone : this.axelPointSublocationList[0].AfterHoursPhone,
    //   dispatchStatusKey : this.axelPointSublocationList[0].DispatchStatusKey,
    //   hasCentralDispatch : 0,
    //   dispatchEmail : this.axelPointSublocationList[0].DispatchEmail,
    //   dispatchFax : this.axelPointSublocationList[0].DispatchFax,
    //   hasCentralBilling : 1,
    //   billingFax : this.axelPointSublocationList[0].BillingFax,
    //   billingEmail : this.axelPointSublocationList[0].BillingEmail,
    //   // mailingAddress1 : this.axelPointParentList[0].Address,
    //   // mailingCity : this.axelPointParentList[0].City,
    //   // mailingStateKey : this.axelPointParentList[0].StateKey,
    //   // mailingPostalCode : this.axelPointParentList[0].PostalCode,
    //   // "MailingCountry" : None,
    //   // PhysicalCountry : None,
    //   PhysicalPostalCode : this.axelPointSublocationList[0].PostalCode,
    //   PhysicalAddress1 : this.axelPointSublocationList[0].Address,
    //   PhysicalCity : this.axelPointSublocationList[0].City,
    //   PhysicalStateKey : this.axelPointSublocationList[0].StateKey,
    //   PhysicalLatitude : this.axelPointSublocationList[0].Latitude,
    //   PhysicalLongitude : this.axelPointSublocationList[0].Longitude,
    //   systemDefault : 0, 
    //   insertedTimestamp : this.currentUTCTimeStamp,
    //   insertedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
    //   updatedTimestamp : this.currentUTCTimeStamp, 
    //   updatedUserKey : "faffe2a0-886c-401a-93b4-77eecb6de495", 
    //   billingPhone : this.axelPointSublocationList[0].BillingPhone,
    //   providerDataSourceKey : "ServicePlusProviderMigration",
    // }

    // console.log(providerDataSublocation)

  //}

  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }


}
