<div class="modal-header">
    <h5 class="modal-title">Add Note</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="addNoteForm">
        <div class="col-md-12">
            <mat-form-field>
                <textarea matInput placeholder="Note" formControlName="note" required></textarea>
                <mat-error *ngIf="handleError('note', 'required')">
                    Note is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-12 mt-2">
            <mat-checkbox formControlName="privateForAccount">
                <label>Public</label>
            </mat-checkbox>
        </div>
    </form>
</div>
<div class="modal-footer text-right">
    <button class="btn btn-primary btn-sm" (click)='addNote()'>Save</button>
</div>