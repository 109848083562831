import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { patterns } from 'src/assets/patterns';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { DatePipe } from '@angular/common';
import { UsersService } from 'src/app/services/users.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { ProviderService } from 'src/app/services/provider.service';
import { AlertDialogComponent } from 'src/app/dialogs/alert-dialog/alert-dialog.component';
import { BillingService } from 'src/app/services/billing.service';
import { forkJoin } from 'rxjs';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-provider-management-profile-details',
    templateUrl: './provider-management-profile-details.component.html',
    styleUrls: ['./provider-management-profile-details.component.scss']
})
export class ProviderManagementProfileDetailsComponent implements OnInit, OnChanges {
    @Input() showSave = false;
    @Input() saveDetails = false;
    @Output() saveCompleted = new EventEmitter<string>();
    @ViewChild('file', { static: false }) myInputVariable: ElementRef;
    @Input() providerDetails: any = [];
    @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();

    corporateDetailsForm: UntypedFormGroup;
    contactDetailsForm: UntypedFormGroup;
    fileData: any;
    imgURL: string | ArrayBuffer = '../assets/images/placeholder.png';
    providerKey: any;
    ProviderKey: any;
    loading: boolean;
    companydetails: any;
    states: any;
    message: string;
    imagePath: any;
    providerId: any;
    corpStructureList: any;
    gotoNextPage = false;
    usersGeoCode: any;
    isDisabled: boolean;
    accountName = '';
    getParentKey: any;
    usersList: any = [];
    isMasterLicensedOwner = true;
    providerMetrics: any = [];
    childMenus: any = ['Billing', 'Dispatch',  'National Accounts', ];
    //childMenus: any = ['Billing', 'Dispatch', 'Customer Preferences', 'National Accounts', 'Hrs of Operation', 'Users', 'Shop Capabilities', 'Technician Expertise3'];
    contacInformation : any = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private commonService: CommonService,
        private http: HttpClient,
        private toast: NbToastrService,
        private utilityService: UtilityService,
        private router: Router,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private usersService: UsersService,
        private providerAdminService: ProviderAdminService,
        private providerService: ProviderService,
        private imageCompress: NgxImageCompressService,
        private billingService: BillingService,
    ) { }

    ngOnInit() {
        this.corporateDetailsForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
            corporateStructureKey: [''],
            companyFEIN: [''],
            dispatchableTier: [1],
            mailingAddress1: ['', Validators.pattern(patterns.textPattern)],
            mailingAddress2: ['', Validators.pattern(patterns.textPattern)],
            mailingAddress3: ['', Validators.pattern(patterns.textPattern)],
            mailingCity: ['', Validators.pattern(patterns.cityPattern)],
            mailingStateKey: [''],
            mailingPostalCode: ['', Validators.pattern(patterns.alphanumericZipCodePattern)],
            yearOfIncorporation: [{ value: '', disabled: this.showSave }],
            website: ['', Validators.pattern(patterns.url)],
            insertedTimestamp: [{ value: '', disabled: true }],
            updatedTimestamp: [{ value: '', disabled: true }],
            updatedUserKey: [{ value: '', disabled: true }],
            primaryContactUserKey: [''],
            customerOwnedProvider: [{ value: '', disabled: true }],
            providerKey: [''],
            timeZoneKey: [''],
            physicalLatitude: [{ value: '', disabled: true }],
            physicalLongitude: [{ value: '', disabled: true }],
            physicalAddress1: ['', Validators.pattern(patterns.textPattern)],
            physicalAddress2: ['', Validators.pattern(patterns.textPattern)],
            physicalAddress3: ['', Validators.pattern(patterns.textPattern)],
            physicalCity: ['', Validators.pattern(patterns.cityPattern)],
            physicalStateKey: [''],
            physicalPostalCode: ['', Validators.pattern(patterns.alphanumericZipCodePattern)],
            tierCanSetHours: [{ value: 0, disabled: this.isDisabled }],
            tierCanSetRates: [{ value: 0, disabled: this.isDisabled }],
            customerName: [''],
            deleted: [false],
            tierLevel: [0],
        });

        this.contactDetailsForm = this.formBuilder.group({
            ProviderKey: [''],
            OwnerFullName: [''],
            OwnerPhone: ['', [Validators.pattern(patterns.phonePattern2)]],
            OwnerEmail: ['', [Validators.pattern(patterns.emailPattern)]],
            ManagerFullName: [''],
            ManagerPhone: ['', [Validators.pattern(patterns.phonePattern2)]],
            ManagerEmail: ['', [Validators.pattern(patterns.emailPattern)]],
            UserKey: [''],
        });
    }

    ngOnChanges() {
        if(this.providerDetails.ProviderKey){
        this.providerKey = this.providerDetails.ProviderKey;
        this.getProviderMetricsDetails();
        if (sessionStorage.getItem('providerParentKey') !== 'null') {
            this.isDisabled = true;
        }
        if (this.providerKey) {
            this.imgURL = '../assets/images/placeholder.png';
            // this.getChildExist();
            this.initData();
        }
    }
        else if(this.providerDetails.providerKey){
            this.providerKey = this.providerDetails.providerKey;

            if (sessionStorage.getItem('providerParentKey') !== 'null') {
                this.isDisabled = true;
            }
            if (this.providerKey) {
                this.imgURL = '../assets/images/placeholder.png';
                // this.getChildExist();
                this.initData();
            }
        }
    }

    getProviderMetricsDetails(){

        const data = {
          providerKey: this.providerDetails.ProviderKey,
          deleted: null,
      };

      console.log(data)

      this.providerAdminService.getProviderMetricsForServicePlus(this.providerDetails.ProviderId).subscribe({
        next: res => this.providerMetrics = res[0][0],
        error: (e) => console.error(e),
        complete: () => {
            if(this.providerMetrics.CountOfWorkOrders == null || this.providerMetrics.CountOfWorkOrders == undefined){
                this.providerMetrics.CountOfWorkOrders = 0
              }  
              if(this.providerMetrics.Last30DayAmount == null || this.providerMetrics.Last30DayAmount == undefined){
                this.providerMetrics.Last30DayAmount = '$ 0.00'
              } 
              else{this.providerMetrics.Last30DayAmount = '$ ' + this.providerMetrics.Last30DayAmount}
          
              if(this.providerMetrics.Last180DayAmount == null || this.providerMetrics.Last180DayAmount == undefined){
                this.providerMetrics.Last180DayAmount = '$ 0.00'
              } 
              else{this.providerMetrics.Last180DayAmount = '$ ' + this.providerMetrics.Last180DayAmount}
          
              if(this.providerMetrics.Last365DayAmount == null || this.providerMetrics.Last365DayAmount == undefined){
                this.providerMetrics.Last365DayAmount = '$ 0.00'
              } 
              else{this.providerMetrics.Last365DayAmount = '$ ' + this.providerMetrics.Last365DayAmount}
        }
      })

      this.providerAdminService.selectProviderContactDetails(this.providerDetails.ProviderKey).subscribe({
        next: res => this.contacInformation = res[0],
        error: (e) => console.log(e),
        complete: () => { 
            this.contactDetailsForm.setValue({
                ProviderKey: this.providerDetails.ProviderKey,
                OwnerFullName: this.contacInformation[0].OwnerFullName,
                OwnerPhone: this.contacInformation[0].OwnerPhone,
                OwnerEmail: this.contacInformation[0].OwnerEmail,
                ManagerFullName: this.contacInformation[0].ManagerFullName,
                ManagerPhone: this.contacInformation[0].ManagerPhone,
                ManagerEmail:this.contacInformation[0].ManagerEmail,
                UserKey: sessionStorage.userKey,
            })
        }
      })
    }

    addContactInfo(){
        this.contactDetailsForm.value;
        if(this.contactDetailsForm.value.ManagerFullName == ''){
            this.contactDetailsForm.value.ManagerFullName = null
        }
        if(this.contactDetailsForm.valid){
              this.providerAdminService.insertProviderContactDetails(this.contactDetailsForm.value).subscribe({
                next: res => {console.log(res)},
                error: (e) => console.log(e),
                complete: () => { }
            })
        }
    }

    initData() {
        this.usersList = [];
        const licensedOwnerFilter = {
            licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
            masterLicensedOwner: true
        };
        const filter = {
            associationKey: this.providerKey,
            deleted: false
        };

        const licensedOwnerReq = this.commonService.getLicensedOwners(licensedOwnerFilter);
        const corporateStructureReq = this.commonService.getCorporateStructuresList();
        const usersReq = this.usersService.getUserLists(filter);

        forkJoin([licensedOwnerReq, corporateStructureReq, usersReq])
            .subscribe(([licensedOwnerRes, corporateStructureRes, usersRes]: any) => {
                if (licensedOwnerRes.body.length > 0) {
                    this.isMasterLicensedOwner = false;
                }

                this.corpStructureList = corporateStructureRes.body;
                this.usersList = this.uniqByKey(usersRes.body || [], 'userKey');

                this.getProviderCompanyDetails();
            }, (error) => {
                console.log('error,', error);
                this.loading = false;
            });
    }

    getUserName(userKey) {
        const filter = {
            userKey,
            deleted: false
        };

        this.loading = true;
        this.usersService.getUsersDetails(filter).subscribe((result: any) => {
            this.loading = false;
            if (result.success && result.body.length > 0) {
                this.corporateDetailsForm.controls.updatedUserKey.setValue(
                    result.body[0].firstName + ' ' + result.body[0].lastName);
            }
        }, (error) => {
            this.loading = false;
        });
    }

    FEINFormat(event){
        console.log(event)
        // if(event.key == 'ArrowLeft' || event.key == 'ArrowRight' ){
        //     return;
        // }

        const numberValues = this.corporateDetailsForm.controls.companyFEIN.value.replace(/\D/g, '');

        this.corporateDetailsForm.value.companyFEIN = this.corporateDetailsForm.controls.companyFEIN.value.replace(/\D/g, '');

        this.corporateDetailsForm.patchValue({
            companyFEIN: this.corporateDetailsForm.value.companyFEIN,
        })

        if(this.corporateDetailsForm.value.companyFEIN.length > 2){
            console.log(this.corporateDetailsForm.value.companyFEIN.length)
            let newFEIN = numberValues.slice(0, 2) + '-' + numberValues.slice(2) ;
            console.log(newFEIN)
            this.corporateDetailsForm.patchValue({
                companyFEIN: newFEIN,
            })
        }
        else{
            {emitEvent: false}
        }
   
        //if (number)
        //this.corporateDetailsForm.cont
        console.log(this.corporateDetailsForm.value.companyFEIN)
      

    }

    // check child exist for parent
    // getChildExist() {
    //     const arrData = {
    //         'ParentKey': this.providerKey,
    //         'deleted': false,
    //     };
    //     this.profileService.getChildExist(arrData).subscribe((res: any) => {
    //         const childData = res.body;
    //         if (childData.length > 0 && sessionStorage.getItem('dispatchableTier') === 'false') {
    //             this.isDisabled = true;
    //         }
    //     }, (error) => {
    //         console.log('error', error);
    //     });
    // }

    // get detailed address on change
    public getDetailedAddress(address: Address, type) {
        const lat = address.geometry.location.lat();
        const lng = address.geometry.location.lng();
        address.address_components.forEach(element => {
            element.types.forEach(data => {
                switch (data) {
                    case 'neighborhood':
                        if (type === 'physical') {
                            this.corporateDetailsForm.controls.physicalAddress2.setValue(element.long_name);
                        } else {
                            this.corporateDetailsForm.controls.mailingAddress2.setValue(element.long_name);
                        }
                        break;
                    case 'administrative_area_level_2':
                        if (type === 'physical') {
                            this.corporateDetailsForm.controls.physicalAddress3.setValue(element.long_name);
                        } else {
                            this.corporateDetailsForm.controls.mailingAddress3.setValue(element.long_name);
                        }
                        break;
                    case 'administrative_area_level_1':
                        if (type === 'physical') {
                            this.corporateDetailsForm.controls.physicalStateKey.setValue(element.long_name);
                        } else {
                            this.corporateDetailsForm.controls.mailingStateKey.setValue(element.long_name);
                        }
                        break;
                    case 'postal_code':
                        if (type === 'physical') {
                            this.corporateDetailsForm.controls.physicalPostalCode.setValue(element.long_name);
                        } else {
                            this.corporateDetailsForm.controls.mailingPostalCode.setValue(element.long_name);
                        }
                        break;
                    case 'locality':
                        if (type === 'physical') {
                            this.corporateDetailsForm.controls.physicalCity.setValue(element.long_name);
                        } else {
                            this.corporateDetailsForm.controls.mailingCity.setValue(element.long_name);
                        }
                        break;
                }
            });
        });

        if (type === 'physical') {
            this.corporateDetailsForm.controls.physicalAddress1.setValue(address.name);
            this.providerAdminService.getUsersTimezone({ lat, lng }).subscribe((res: any) => {
                this.corporateDetailsForm.controls.timeZoneKey.setValue(res.timeZoneId);
            });
            this.corporateDetailsForm.controls.physicalLatitude.setValue(lat);
            this.corporateDetailsForm.controls.physicalLongitude.setValue(lng);
        } else {
            this.corporateDetailsForm.controls.mailingAddress1.setValue(address.name);
        }
    }

    // image preview
    preview(files: any, event: any) {
        if (files.length === 0) {
            return false;
        }
        const fileReader = new FileReader();
        fileReader.onload = (res: any) => {
            this.imageCompress.compressFile(res.target.result, -1, 50, 50).then(result => {
                this.utilityService.urltoFile(result, event.target.files[0].name, files[0].type).then((file) => {
                    this.fileData = file;
                    const formData = new FormData();
                    formData.append('file', this.fileData);
                    formData.append('fileType', 'image');

                    const mimeType = files[0].type;
                    if (mimeType.match(/image\/*/) == null) {
                        this.toast.danger('Only image formats are supported.');
                        return false;
                    }

                    const size = files[0].size;
                    if (Math.round(size / 1024) > 5120) {
                        this.toast.danger('Image size should not exceed 5MB.');
                        this.myInputVariable.nativeElement.value = '';
                        return false;
                    }

                    this.loading = true;
                    const reader = new FileReader();
                    this.imagePath = files;

                    reader.readAsDataURL(files[0]);
                    reader.onload = (_event) => {

                        this.providerAdminService.updateProfilePicture(formData).subscribe(resFileDetail => {
                            if (resFileDetail.status) {
                                this.toast.success('Profile Uploaded.!', 'Success');
                                const deleteImg = new FormData();
                                deleteImg.append('file_name', atob(sessionStorage.getItem('loginUserProfile')));
                                this.providerAdminService.deleteProfilePicture(deleteImg).subscribe((response: any) => {
                                    this.loading = false;
                                }, (error) => {
                                    this.loading = false;
                                });
                            } else {
                                this.toast.danger('Failed to upload profile.!', 'Error');
                            }
                            const data = {
                                providerKey: this.providerKey,
                                logoURL: resFileDetail.url,
                            };

                            this.imgURL = resFileDetail.url;

                            this.http.put(`${environment.apiUrl}serviceplus/providers`, data).subscribe((response: any) => {
                                // sessionStorage.setItem('loginUserProfile', btoa(resFileDetail['url']));
                                // this.utilityService.setProfilePicture(resFileDetail['url']);
                                this.loading = false;
                            }, (error) => {
                                this.loading = false;
                                this.toast.danger(error.message, 'Error');
                            });
                        }, (error) => {
                            this.loading = false;
                            this.toast.danger('Failed to update profile picture.!', 'Error');
                        });
                    };
                }, error => {
                    this.toast.danger('Failed to update profile picture.!', 'Error');
                    console.log('Failed to update profile picture.!', error);
                });
            });
        }
        fileReader.readAsDataURL(event.target.files[0]);
    }

    // get company details
    getProviderCompanyDetails() {
        const data = {
            providerKey: this.providerKey,
            deleted: null,
        };

        this.loading = true;
        this.providerService.getProviders(data).subscribe((res: any) => {
            this.companydetails = res.body[0] || [];
            if (this.companydetails.logoURL) {
                this.imgURL = atob(this.companydetails.logoURL);
            }

            if( this.companydetails.companyFEIN != '' && this.companydetails.companyFEIN != null ){
                 if(this.companydetails.companyFEIN.length == 10  && this.companydetails.companyFEIN.includes("-", 3)){
                    console.log('all good fein format.')
                 }
                 else if ( this.companydetails.companyFEIN.length == 9  && !this.companydetails.companyFEIN.includes("-", 3)){
                    console.log('need to fix fein format.')

                    const numberValues = this.companydetails.companyFEIN 
                    let newFEIN = numberValues.slice(0, 2) + '-' + numberValues.slice(2) ;
                    console.log(newFEIN)
                    this.companydetails.companyFEIN = newFEIN;
                 }
            }
           
            this.providerId = this.companydetails.providerId;
            this.corporateDetailsForm.setValue({
                name: this.companydetails.name,
                corporateStructureKey: this.companydetails?.corporateStructureKey ?? null,
                companyFEIN: this.companydetails.companyFEIN,
                dispatchableTier: this.companydetails.dispatchableTier,
                mailingAddress1: this.companydetails.mailingAddress1,
                mailingAddress2: this.companydetails?.mailingAddress2 ?? null,
                mailingAddress3: this.companydetails?.mailingAddress3 ?? null,
                mailingCity: this.companydetails.mailingCity,
                mailingStateKey: this.companydetails.mailingStateKey,
                physicalPostalCode: this.companydetails?.physicalPostalCode ?? null,
                yearOfIncorporation: this.companydetails.yearOfIncorporation == null ? ''
                    : this.datePipe.transform(this.companydetails.yearOfIncorporation, 'yyyy'),
                website: this.companydetails?.website ?? null,
                insertedTimestamp: this.companydetails.insertedTimestamp == null ? ''
                    : this.datePipe.transform(this.companydetails.insertedTimestamp, 'MM-dd-yyyy'),
                updatedTimestamp: this.companydetails.updatedTimestamp == null ? ''
                    : this.datePipe.transform(this.companydetails.updatedTimestamp, 'MM-dd-yyyy'),
                primaryContactUserKey: this.companydetails?.primaryContactUserKey ?? null,
                customerOwnedProvider: this.companydetails?.customerOwnedProvider ?? null,
                providerKey: this.providerKey,
                updatedUserKey: this.companydetails.updatedUserKey,
                timeZoneKey: this.companydetails?.timeZoneKey ?? null,
                physicalLatitude: this.companydetails?.physicalLatitude ?? null,
                physicalLongitude: this.companydetails?.physicalLongitude ?? null,
                physicalAddress1: this.companydetails?.physicalAddress1 ?? null,
                physicalAddress2: this.companydetails?.physicalAddress2 ?? null,
                physicalAddress3: this.companydetails?.physicalAddress3 ?? null,
                physicalCity: this.companydetails?.physicalCity ?? null,
                physicalStateKey: this.companydetails?.physicalStateKey ?? null,
                mailingPostalCode: this.companydetails.mailingPostalCode,
                tierCanSetHours: this.companydetails.tierCanSetHours,
                tierCanSetRates: this.companydetails.tierCanSetRates,
                customerName: '',
                deleted: this.companydetails.deleted ? false : true,
                tierLevel: this.companydetails.tierLevel
            });

            this.changeValidators();

            if (!!this.corporateDetailsForm.controls.updatedUserKey.value) {
                this.getUserName(this.corporateDetailsForm.controls.updatedUserKey.value);
            } else {
                this.corporateDetailsForm.controls.updatedUserKey.setValue('');
            }

            if (this.companydetails.customerOwnedProvider) {
                this.getCustomerNameDetails();
            }

            if (this.corporateDetailsForm.controls.timeZoneKey) {
                sessionStorage.setItem('currentTimezone', this.corporateDetailsForm.controls.timeZoneKey.value);
            }
            this.loading = false;
        }, (error) => {
            this.loading = false;
            this.toast.danger(error.message, 'Error');
        });
    }

    getCustomerNameDetails() {
        const objParams = {
            customerOwnedCustomerKey: this.companydetails.customerOwnedCustomerKey,
            deleted: false,
        };
        this.billingService.getCustomerBilling(objParams).subscribe((result: any) => {
            this.corporateDetailsForm.controls.customerName.setValue(result.body[0].customerName);
        }, (error) => {
        });
    }

    // update company details
    updateCorporateDetails() {
        if (!!this.providerKey) {
            if (this.corporateDetailsForm.invalid || this.contactDetailsForm.invalid) {
                this.corporateDetailsForm.markAllAsTouched();
                return false;
            }
            if (this.corporateDetailsForm.controls.timeZoneKey) {
                sessionStorage.setItem('currentTimezone', this.corporateDetailsForm.controls.timeZoneKey.value);
            }

            let data = Object.assign(this.corporateDetailsForm.value);
            data.updatedUserKey = sessionStorage.getItem('userKey');
            data.updatedTimestamp = this.utilityService.getCurrentTimestamp();
            data.yearOfIncorporation = data.yearOfIncorporation != '' ? Date.parse(data.yearOfIncorporation) : null;
            data.physicalLatitude = this.corporateDetailsForm.get('physicalLatitude').value;
            data.physicalLongitude = this.corporateDetailsForm.get('physicalLongitude').value;
            data.deleted = !data.deleted;
            // delete data.primaryContact;
            if (data.deleted) {
                const updateData = {
                    dispatchableTier: 0,
                    dispatchStatusKey: 'InActive'
                };
                data = { ...data, ...updateData };
            }

            this.loading = true;
            this.providerService.updateProviderDetails(data).subscribe((res: any) => {
                this.loading = false;
                // Update parent sessions to reflect in child
                if (res.success) {
                    this.next.emit(true);
                    this.toast.success(res.message[0], 'Success');
                } else {
                    this.toast.warning(res.message[0], 'Error');
                }

                const getParentKey = {
                    parentKey: this.providerKey,
                    deleted: false,
                };

                this.providerService.getProviderDetails(getParentKey).subscribe((res: any) => {
                    this.getParentKey = res.body;
                    if (this.getParentKey.length > 0) {
                        const childData = [];
                        this.getParentKey.forEach(item =>
                            childData.push({
                                providerKey: item.providerKey,
                                tierCanSetRates: this.corporateDetailsForm.get('tierCanSetRates').value,
                                updatedUserKey: sessionStorage.getItem('userKey'),
                            }));
                        this.providerAdminService.updateProviderChildDetails(childData).subscribe((res: any) => {
                        }, (error) => {
                            this.loading = false;
                            console.log(error);
                        });
                    }
                }, (error) => {
                    this.loading = false;
                    console.log(error);
                });

                sessionStorage.setItem('SPCompanyName', this.corporateDetailsForm.get('name').value);
                sessionStorage.setItem('dispatchableTier', this.corporateDetailsForm.get('dispatchableTier').value);
                sessionStorage.setItem('tierCanSetHours', this.corporateDetailsForm.get('tierCanSetHours').value);
                sessionStorage.setItem('tierCanSetRates', this.corporateDetailsForm.get('tierCanSetRates').value);
                this.utilityService.setCompanyName(this.corporateDetailsForm.get('name').value);
                this.saveCompleted.emit('true');
                if (this.gotoNextPage) {
                    this.utilityService.saveNextRoute();
                    this.router.navigate([sessionStorage.getItem('nextPageLink')], { relativeTo: this.route });
                }
            }, (error) => {
                this.loading = false;
                this.saveCompleted.emit('false');
            });
        } else {
            this.dialog.open(AlertDialogComponent, {
                disableClose: true,
                width: '24%',
                data: {
                    message: 'Please select provider'
                }
            });
        }
        this.addContactInfo();
    }

    saveNextPage() {
        this.gotoNextPage = true;
        this.updateCorporateDetails();
    }

    setPhysicalAddress() {
        if (this.corporateDetailsForm.controls.mailingAddress1.value !== '') {
            this.corporateDetailsForm.controls.physicalAddress1.setValue(this.corporateDetailsForm.controls.mailingAddress1.value);
        }
        if (this.corporateDetailsForm.controls.mailingAddress2.value !== '') {
            this.corporateDetailsForm.controls.physicalAddress2.setValue(this.corporateDetailsForm.controls.mailingAddress2.value);
        }
        if (this.corporateDetailsForm.controls.mailingAddress3.value !== '') {
            this.corporateDetailsForm.controls.physicalAddress3.setValue(this.corporateDetailsForm.controls.mailingAddress3.value);
        }
        if (this.corporateDetailsForm.controls.mailingPostalCode.value !== '') {
            this.corporateDetailsForm.controls.physicalPostalCode.setValue(this.corporateDetailsForm.controls.mailingPostalCode.value);
        }
        if (this.corporateDetailsForm.controls.mailingCity.value !== '') {
            this.corporateDetailsForm.controls.physicalCity.setValue(this.corporateDetailsForm.controls.mailingCity.value);
        }
        if (this.corporateDetailsForm.controls.mailingStateKey.value !== '') {
            this.corporateDetailsForm.controls.physicalStateKey.setValue(this.corporateDetailsForm.controls.mailingStateKey.value);
        }

        const physicalAddress = this.corporateDetailsForm.controls.mailingAddress1.value
            + this.corporateDetailsForm.controls.mailingAddress2.value
            + this.corporateDetailsForm.controls.mailingAddress3.value
            + this.corporateDetailsForm.controls.mailingCity.value
            + this.corporateDetailsForm.controls.mailingStateKey.value;

        if (physicalAddress !== '') {
            this.loading = true;
            this.providerAdminService.getAddressDetails(physicalAddress).subscribe((response: any) => {
                this.loading = false;
                this.corporateDetailsForm.controls.physicalLatitude.setValue(response.results[0].geometry.location.lat);
                this.corporateDetailsForm.controls.physicalLongitude.setValue(response.results[0].geometry.location.lng);
                this.providerAdminService.getUsersTimezone({ lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng }).subscribe((res: any) => {
                    this.corporateDetailsForm.controls.timeZoneKey.setValue(res.timeZoneId);
                });
            }, (error) => {
                this.loading = false;
            });
        }
    }

    updateFormValidition(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.updateValueAndValidity();
        });
    }

    dispatchableTierChanged(event) {
        if (event.checked === true) {
            this.corporateDetailsForm.controls.dispatchableTier.setValue(true);
            this.changeValidators();
        } else {
            this.corporateDetailsForm.controls.dispatchableTier.setValue(false);
            this.changeValidators();
        }
    }

    changeValidators() {
        if (this.corporateDetailsForm.controls.dispatchableTier.value === true || this.corporateDetailsForm.controls.dispatchableTier.value === 1) {
            this.corporateDetailsForm.controls.physicalAddress1.setValidators([Validators.required, Validators.pattern(patterns.textPattern)]);
            this.corporateDetailsForm.controls.physicalAddress2.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.physicalAddress3.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.physicalPostalCode.setValidators([Validators.required, Validators.pattern(patterns.alphanumericZipCodePattern)]);
            this.corporateDetailsForm.controls.physicalCity.setValidators([Validators.required, Validators.pattern(patterns.cityPattern)]);
            this.corporateDetailsForm.controls.physicalStateKey.setValidators(Validators.required);
            this.corporateDetailsForm.controls.mailingAddress1.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.mailingAddress2.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.mailingAddress3.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.mailingPostalCode.setValidators(Validators.pattern(patterns.alphanumericZipCodePattern));
            this.corporateDetailsForm.controls.mailingCity.setValidators(Validators.pattern(patterns.cityPattern));
            this.corporateDetailsForm.controls.mailingStateKey.clearValidators();
            this.updateFormValidition(this.corporateDetailsForm);
        } else {
            this.corporateDetailsForm.controls.mailingAddress1.setValidators([Validators.required, Validators.pattern(patterns.textPattern)]);
            this.corporateDetailsForm.controls.mailingAddress2.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.mailingAddress3.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.mailingPostalCode.setValidators(Validators.pattern(patterns.alphanumericZipCodePattern));
            this.corporateDetailsForm.controls.mailingCity.setValidators([Validators.required, Validators.pattern(patterns.cityPattern)]);
            this.corporateDetailsForm.controls.mailingStateKey.setValidators(Validators.required);
            this.corporateDetailsForm.controls.physicalAddress1.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.physicalAddress2.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.physicalAddress3.setValidators(Validators.pattern(patterns.textPattern));
            this.corporateDetailsForm.controls.physicalPostalCode.setValidators(Validators.pattern(patterns.alphanumericZipCodePattern));
            this.corporateDetailsForm.controls.physicalCity.setValidators(Validators.pattern(patterns.cityPattern));
            this.corporateDetailsForm.controls.physicalStateKey.clearValidators();
            this.updateFormValidition(this.corporateDetailsForm);
        }
    }

    uniqByKey(array, key) {
        return array.reduce((arr, item) => {
            const removed = arr.filter(i => i[key] !== item[key]);
            return [...removed, item];
        }, []);
    }

    saveProfileChanges(){
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {header: 'Save Profile Changes', message: 'Are you sure you want to save the changes made to the profile details?',
              yesButton: 'Yes'
            }
          });
        dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if(result == true){
                this.updateCorporateDetails()
            }
        })  
    }

}
