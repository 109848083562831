<h1 mat-dialog-title>Add Used Only For Specific Client</h1>
<div mat-dialog-content>
    <form>
        <mat-form-field>
            <mat-label>Customer</mat-label>
            <input type="text" placeholder="Search Customer" matInput [formControl]="customerControl"
            [matAutocomplete]="customerAuto">
        <mat-autocomplete autoActiveFirstOption #customerAuto="matAutocomplete">
            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.CustomerName"
                (click)="selectedCustomer(customer.CustomerKey)">
                {{customer.CustomerName}}
            </mat-option>
        </mat-autocomplete>
        </mat-form-field>
        <br>
    </form>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="addCustomerToOnlyUsedForSpecificClient()">Add Customer To Only Used For Specific Client</button> 
    </div>

</div>