import { v4 as uuidv4 } from 'uuid';


export class Question {
  public key: string;
  public title: string;
  public display: string;
  public type: string;
  public order: number;
  public components: string;
  public parentKey: string;
  public childrens: Array<Question>;

  constructor(data: any = []) {
    this.key = data.key || uuidv4();
    this.title = data.title;
    this.display = data.display;
    this.type = data.type;
    this.order = data.order;
    this.components = data.components || '';
    this.parentKey = data.parentKey;
    this.childrens = data.childrens || [];
  }

  hasChildren(question) {
    return question.childrens.length > 0;
  }
}
