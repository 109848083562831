import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProtocolService } from 'src/app/services/protocol.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-approved-protocols-edit-dialog',
  templateUrl: './approved-protocols-edit-dialog.component.html',
  styleUrls: ['./approved-protocols-edit-dialog.component.scss']
})
export class ApprovedProtocolsEditDialogComponent implements OnInit {

  customerKey: string;
  userKey: string;
  licensedOwnerOfferingKey: string;
  customerProtocolKey: string;
  loading = false;
  editApprovedProtocolsForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ApprovedProtocolsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private protocolService: ProtocolService,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService) { }

  ngOnInit() {

    this.userKey = sessionStorage.getItem('userKey');
    this.customerKey = sessionStorage.getItem('customerKey');
    this.customerProtocolKey = this.data.customerProtocolKey;
    this.licensedOwnerOfferingKey = this.data.licensedOwnerOfferingKey;

    this.editApprovedProtocolsForm = this.formBuilder.group({
      agentProtocol: ['', [Validators.required]],
      providerProtocol: ['', [Validators.required]],
    });

    this.getApprovedProtocolsList();
  }

  /***
  * @Desc: get customer ptocol details
  * @params licensedOwnerOfferingKey, customerProtocolKey
  * @returns array result
  */
  getApprovedProtocolsList() {
    this.loading = true;
    const postData = {
      licensedOwnerOfferingKey: this.licensedOwnerOfferingKey,
      customerProtocolKey: this.customerProtocolKey
    };

    this.protocolService.getCustomerProtocolsById(postData).subscribe(response => {
      if (response.body.length > 0) {
        const protocolDetails = response.body[0];
        this.editApprovedProtocolsForm.setValue({
          agentProtocol: protocolDetails.agentProtocol,
          providerProtocol: protocolDetails.providerProtocol,
        });
      }
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  /***
  * @Desc: edit approved services protocols
  * @returns success result
  */
  editApprovedProtocols() {

    if (this.editApprovedProtocolsForm.invalid) {
      this.editApprovedProtocolsForm.markAllAsTouched();
      return false;
    }

    this.loading = true;
    const updateData = {
      customerProtocolKey: this.customerProtocolKey,
      agentProtocol: this.editApprovedProtocolsForm.value.agentProtocol,
      providerProtocol: this.editApprovedProtocolsForm.value.providerProtocol,
      customerKey: this.customerKey,
      insertedUserKey: this.userKey,
      systemDefault: true,
      updatedUserKey: this.userKey,
      deleted: 0,
    };

    this.protocolService.editCustomerProtocols(updateData).subscribe(response => {

      if (response.success) {
        this.toast.success('Approved Protocol Updated Successfully!', 'Success');
      } else {
        this.toast.warning(response.message[0], 'Error');
      }
      this.loading = false;
      this.dialogRef.close();
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /***
  * @Desc: handle
  * @params control
  * @returns error msgs
  */
  handleError = (control: string, error: string) => {
    return this.editApprovedProtocolsForm.controls[control].hasError(error);
  }

}
