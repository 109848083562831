import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NbToastrService } from '@nebular/theme';
import { RatesService } from 'src/app/services/rates.service';
import { patterns } from 'src/assets/patterns';
import { forkJoin } from 'rxjs';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-provider-account-details',
  templateUrl: './provider-account-details.component.html',
  styleUrls: ['./provider-account-details.component.scss']
})
export class ProviderAccountDetailsComponent implements OnInit {

  @Input() providerAccount;
  accountDetailForm: UntypedFormGroup;
  membershipList: any;
  userStatusDetails: any;
  loading: boolean;
  rating = [];
  isEdit = false;
  showDiscontinuedReasonFlag = false;
  @Output() saveCompleted = new EventEmitter<string>();
  enteredBy: any;
  updatedDate: any;
  actStatus: any;
  statusKey: any;
  showNotesReasonFlag: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public commonService: CommonService,
    public utilityService: UtilityService,
    private usersService: UsersService,
    private toastrService: NbToastrService,
    private ratesService: RatesService,
  ) { }
  ngOnInit() {
    this.accountDetailForm = this.formBuilder.group({
      accountName: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      address: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      city: ['', Validators.required],
      stateKey: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.pattern(patterns.alphanumericZipCodePattern)]],
      primaryContactPhone: [''],
      defaultRetailAccount: [false],
      afterHoursRatesAllowed: [false],
      autoDispatch: [false],
      membershipStatus: [''],
      qualityRating: [''],
      accountStatus: ['Active', Validators.required],
      providerDiscontinuedReason: [''],
      activeNotes: ['']
    });

    this.checkDefaultAccounts();
    this.getMembershipStatusList();
    this.getProviderAccountStatus();
    this.setRatingDropDown(100);

    if (this.providerAccount) {
      this.isEdit = true;
      if (this.providerAccount.licensedOwnerKey === '') {
        this.getProviderAccountDetails();
      } else {
        this.getLicensedOwnerDetails();
      }

      if (this.providerAccount.licensedOwnerKey !== '') {
        this.accountDetailForm.controls.accountName.disable();
        this.accountDetailForm.controls.address.disable();
        this.accountDetailForm.controls.city.disable();
        this.accountDetailForm.controls.stateKey.disable();
        this.accountDetailForm.controls.zipCode.disable();
        this.accountDetailForm.controls.primaryContactPhone.disable();
        this.accountDetailForm.controls.afterHoursRatesAllowed.disable();
        this.accountDetailForm.controls.autoDispatch.disable();
        this.accountDetailForm.controls.membershipStatus.disable();
        this.accountDetailForm.controls.qualityRating.disable();
      }
    }
    else {
      this.accountDetailForm.controls.accountStatus.disable();
    }
  }

  checkDefaultAccounts() {
    const data = {
      'providerKey': this.providerAccount ? this.providerAccount.providerKey : sessionStorage.getItem('providerKey'),
      'defaultRetailAccount': true,
      'deleted': false,
    };
    this.ratesService.getProviderDefaultAccountKey(data).subscribe((res: any) => {
      if (res.body.length > 0) {
        this.accountDetailForm.controls.defaultRetailAccount.disable();
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  getLicensedOwnerDetails() {
    const licensedOwnerDataReq = { 'licensedOwnerKey': sessionStorage.getItem('licensedOwnerKey') };
    const providerAccountDataReq = { 'ProviderAccountKey': this.providerAccount.ProviderAccountKey };
    const providerAccountData = this.ratesService.getProviderDefaultAccountKey(providerAccountDataReq);
    const licensedOwnerData = this.ratesService.getLicensedOwnerDetails(licensedOwnerDataReq);
    this.loading = true;
    forkJoin([providerAccountData, licensedOwnerData]).subscribe(result => {
      this.loading = false;
      this.setAccountDetailForm(result[0].body[0], result[1].body[0]);
    });
  }

  getProviderAccountDetails() {
    const data = {
      'ProviderAccountKey': this.providerAccount.ProviderAccountKey
    }
    this.loading = true;
    this.ratesService.getProviderDefaultAccountKey(data).subscribe((res: any) => {
      this.loading = false;
      this.setAccountDetailForm(res.body[0], 0);
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  setAccountDetailForm(providerDetails: any, loDetails: any) {
    this.accountDetailForm.setValue({
      accountName: loDetails === 0 ? providerDetails.accountName : loDetails.licensedOwnerName,
      address: loDetails === 0 ? providerDetails.address : loDetails.mailingAddress,
      city: loDetails === 0 ? providerDetails.city : loDetails.mailingCity,
      stateKey: loDetails === 0 ? providerDetails.stateKey : loDetails.mailingStateKey,
      zipCode: loDetails === 0 ? providerDetails.zipCode : loDetails.mailingZipCode,
      primaryContactPhone: loDetails === 0 ? providerDetails.primaryContactPhone : loDetails.phoneNumber,
      defaultRetailAccount: providerDetails.defaultRetailAccount,
      afterHoursRatesAllowed: providerDetails.afterHoursRatesAllowed,
      autoDispatch: providerDetails.autoDispatch,
      membershipStatus: providerDetails.membershipStatus,
      qualityRating: providerDetails.qualityRating,
      accountStatus: providerDetails.accountStatus,
      providerDiscontinuedReason: providerDetails.providerDiscontinuedReason,
      activeNotes: '',
    });

    if (providerDetails.defaultRetailAccount) {
      this.accountDetailForm.controls.defaultRetailAccount.enable();
    }

    if (this.accountDetailForm.controls.accountStatus.value === 'Do Not Use') {
      this.accountDetailForm.controls.accountStatus.disable();
      this.userStatusDetails = this.userStatusDetails.map(status => {
        status.isSelected = true;
        return status;
      });
    }

    this.actStatus = this.accountDetailForm.controls.accountStatus.value;
  }

  // get Membership Status list
  getMembershipStatusList() {
    this.commonService.getMembershipStatusList().subscribe((response) => {
      this.membershipList = response.body;
    }, (error) => {
      console.log('error', error);
    });
  }

  showDiscontinuedReason() {
    if (this.accountDetailForm.get("accountStatus").value === 'InActive') {
      this.showDiscontinuedReasonFlag = true;
      this.showNotesReasonFlag = false;
      this.accountDetailForm.get("providerDiscontinuedReason").setValidators(Validators.required);
      this.accountDetailForm.get("providerDiscontinuedReason").updateValueAndValidity();
    } else {
      this.showDiscontinuedReasonFlag = false;
      this.showNotesReasonFlag = true;
      this.accountDetailForm.get('providerDiscontinuedReason').clearValidators();
      this.accountDetailForm.get('providerDiscontinuedReason').updateValueAndValidity();
    }
  }

  getProviderAccountStatus() {
    let data = {
      deleted: false
    }
    this.commonService.getProviderAccountStatuses(data).subscribe(response => {
      this.userStatusDetails = response.body.map(status => {
        Object.assign(status, { isSelected: true });
        if (status.description === 'Do Not Use') {
          status.isSelected = false;
        }
        return status;
      });
    }, (error) => {
      console.log(error);
    });
  }

  setRatingDropDown(value) {
    for (let i = 1; i <= value; i++) {
      this.rating.push(i);
    }
  }

  // get detailed address on change
  public getDetailedAddress(address: Address) {
    let streetNumber = '';
    address.address_components.forEach(element => {
      element.types.forEach(data => {
        if (data === 'postal_code') {
          this.accountDetailForm.controls.zipCode.setValue(element.long_name);
        } else if (data === 'administrative_area_level_1') {
          this.accountDetailForm.controls.stateKey.setValue(element.long_name);
        } else if (data === 'locality') {
          this.accountDetailForm.controls.city.setValue(element.long_name);
        } else if (data === 'street_number') {
          streetNumber = element.long_name + ' ';
        }
      });
    });
    const mailingAddress = address.name + ' ' + streetNumber;
    this.accountDetailForm.controls.address.setValue(mailingAddress);
  }

  addProviderAccount() {
    if (this.accountDetailForm.invalid) {
      this.utilityService.validateAllFormFields(this.accountDetailForm);
      this.saveCompleted.emit('false');
      return false;
    }

    const insertData = {
      providerKey: sessionStorage.getItem('providerKey'),
      insertedUserKey: sessionStorage.getItem('userKey'),
      licensedOwnerKey: '',
      deleted: false,
      updatedUserKey: ''
    };

    this.loading = true;
    const data = Object.assign(this.accountDetailForm.value, insertData);
    data.defaultRetailAccount = this.accountDetailForm.controls.defaultRetailAccount.value;
    data.accountStatus = this.accountDetailForm.controls.accountStatus.value;
    this.ratesService.addDefaultProvideraccounts(data).subscribe((res: any) => {
      if (res.success) {
        this.toastrService.success(res.message[0], 'Success');
        this.saveCompleted.emit('true');
      } else {
        this.toastrService.danger(res.message[0], 'Error');
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });

  }

  updateProviderAccount() {
    this.statusKey = this.accountDetailForm.controls.accountStatus.value;

    if (this.accountDetailForm.invalid) {
      this.accountDetailForm.markAllAsTouched();
      this.saveCompleted.emit('false');
      return false;
    }

    if (this.actStatus !== this.accountDetailForm.controls.accountStatus.value) {

      let status = this.accountDetailForm.controls.accountStatus.value;

      let note = '';
      if (this.statusKey === 'InActive') {
        note = 'The ' + this.providerAccount.Name + ' status has been moved to InActive' + ' ' + this.accountDetailForm.controls.providerDiscontinuedReason.value;
      } else {
        let statusDesc = status === 'Active' ? 'Active' : status === 'Do Not Use' ? 'Do Not Use' : 'Provider Discontinued';
        note = 'The ' + this.providerAccount.Name + ' status has been moved to ' + statusDesc;
      }

      let notesData = {
        note: note,
        privateForAccount: false,
        privateForProvider: false,
        ProviderAccountKey: this.providerAccount.ProviderAccountKey,
        insertedUserKey: sessionStorage.getItem('userKey'),
        updatedUserKey: '',
        systemDefault: false,
        deleted: false
      }

      this.ratesService.addProviderAccountNotes(notesData).subscribe(() => {
        this.loading = false;
        this.showNotesReasonFlag = false;
        this.accountDetailForm.controls.activeNotes.setValue("");
      }, (error) => {
        this.loading = false;
      });
    }

    const insertData = {
      providerAccountKey: this.providerAccount.ProviderAccountKey,
      updatedUserKey: sessionStorage.getItem('userKey'),
      // accountStatus: this.statusKey
    };

    this.loading = true;
    const data = Object.assign(this.accountDetailForm.value, insertData);
    this.ratesService.updateProviderAccounts(data).subscribe((res: any) => {
      this.loading = false;
      this.toastrService.success(res.message[0], 'Success');
      this.saveCompleted.emit('true');
    }, (error) => {
      this.loading = false;
      this.toastrService.danger('Failed to save', 'Failed');
    });
  }


}
