import { Component, OnInit, Input } from '@angular/core';
import { CapabilitiesService } from 'src/app/services/capabilities.service';
import { CheckBoxService } from './services/check-box.service';

@Component({
  selector: 'app-checkbox-tree-ui',
  templateUrl: './checkbox-tree-ui.component.html',
  styleUrls: ['./checkbox-tree-ui.component.scss']
})
export class CheckboxTreeUIComponent implements OnInit {

  @Input() levelData: any;
  @Input() level;
  @Input() lastNode: number; // Number of levels to show (If set to -1 tree can go to n levels)
  @Input() providerKey: string;

  networkJson: any;
  levelKey: any = null;

  constructor(
    private checkBoxService: CheckBoxService,
    private capabilitiesService: CapabilitiesService,
  ) { }

  ngOnInit(): void {
    this.setExpandedTreeLevel();
  }

  setExpandedTreeLevel() {
    const expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    this.level = this.level + 1;
    this.levelKey = expandArr[this.level - 1];
  }

  showNetwork(jsonObj) {
    /* Restrict method execution (Expanding tree)
    if node is last node or node is not active or node is having no childrens */
    if (
      this.level === this.lastNode
      || !(jsonObj.children != null && jsonObj.children[0] !== undefined)) {
      return false;
    }

    let expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    if (this.levelKey == null) {
      this.levelKey = jsonObj.providerCapabilityKey;
      expandArr.push(jsonObj.providerCapabilityKey);
    } else if (this.levelKey === jsonObj.providerCapabilityKey) {
      this.levelKey = null;
      expandArr = expandArr.slice(0, this.level - 1);
    } else {
      this.levelKey = jsonObj.providerCapabilityKey;
      if (this.level > expandArr.length) {
        expandArr.push(jsonObj.providerCapabilityKey);
      } else {
        expandArr = expandArr.slice(0, this.level - 1);
        expandArr.push(jsonObj.providerCapabilityKey);
      }
    }
    sessionStorage.setItem('expandedLayer', JSON.stringify(expandArr));
  }

  activateInactivate(data: any) {
    const filter = {
      isSelected: !data.isSelected ? 1 : 0,
      providerCapabilityKey: data.providerCapabilityKey,
      providerKey: this.providerKey,
    };

    this.checkBoxService.setCheckBoxLoader(true);
    this.capabilitiesService.addDeleteProviderCapabilities(filter).subscribe(result => {
      this.checkBoxService.setCheckBoxLoader(false);
      this.getProviderCapabilitiesByProvider();
    }, (error) => {
      this.checkBoxService.setCheckBoxLoader(false);
    });
  }

  getProviderCapabilitiesByProvider() {
    const filter = {
      providerKey: this.providerKey,
    };

    this.checkBoxService.setCheckBoxLoader(true);
    this.capabilitiesService.getProviderCapabilitiesByProvider(filter).subscribe(result => {
      this.checkBoxService.setCheckBoxLoader(false);
      this.networkJson = result;
      this.setMainTreeJson();

      if (this.checkBoxService.getCheckBoxNetworkData() === null ||
        this.checkBoxService.getCheckBoxNetworkData() === undefined) {
        this.checkBoxService.setCheckBoxNetworkData(JSON.stringify(this.networkJson));
      }
    }, (error) => {
      this.checkBoxService.setCheckBoxLoader(false);
    });
  }

  setMainTreeJson() {
    this.checkBoxService.setCheckBoxNetworkData(JSON.stringify(this.networkJson));
    this.checkBoxService.setCheckBoxData();
  }
}
