import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { patterns } from 'src/assets/patterns';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { NbToastrService } from '@nebular/theme';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-edit-broker-agency',
  templateUrl: './add-edit-broker-agency.component.html',
  styleUrls: ['./add-edit-broker-agency.component.scss']
})
export class AddEditBrokerAgencyComponent implements OnInit {

  brokerDetailForm: UntypedFormGroup;
  event = new EventEmitter();
  loading: boolean;
  providerKey: string;
  policyExpirationData: any;
  expiredPolicy: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    public dialog: MatDialogRef<AddEditBrokerAgencyComponent>,
    private providerAdminService: ProviderAdminService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.providerKey = this.data.providerKey;
    this.brokerDetailForm = this.formBuilder.group({
      agencyName: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      agentName: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      agentPhone: ['', Validators.pattern(patterns.mobnumPattern)],
      agentEmail: ['', [Validators.required, Validators.pattern(patterns.emailPattern)]],
      policyExpirationDate: ['', [Validators.required, this.dataValidationCheck]],
    });
  }

  closeDialog() {
    this.dialog.close();
  }

  dataValidationCheck(control: any){
    const today = new Date();
    const selectedDate = new Date(control.value);
    if (selectedDate < today){
      return { pastDate: true};
    }
    return null;
  }

  addNewBroker() {

    this.dataValidationCheck(this.brokerDetailForm.value.policyExpirationDate);

    console.log(this.brokerDetailForm.value)
    let date3 = this.datePipe.transform(this.brokerDetailForm.value.policyExpirationDate, "yyyy-MM-dd HH:mm:ss.SSS")//, "UTC"
    console.log(date3)
    //const formattedDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss.SSS');
    const insuranceExpirationTime = (new Date(date3)).getTime();

    if (this.data.providerKey) {
      if (this.brokerDetailForm.invalid) {
        this.brokerDetailForm.markAllAsTouched();
        return false;
      }

      if(this.brokerDetailForm.value != '' && this.brokerDetailForm.value.agentPhone != null){
      this.brokerDetailForm.value.agentPhone = '+1' + this.brokerDetailForm.value.agentPhone}

      const insertData = {
        providerKey: this.providerKey,
        insertedUserKey: sessionStorage.getItem('userKey'),
        cartificateURL: '',
        deleted: false,
        insuranceTypeKey: '',
        policyExpDate: insuranceExpirationTime,
        policyNumber: '',
        providerInsuranceKey: '',
        systemDefault: true,
        updatedUserKey: '',
      };
      this.loading = true;
      this.providerAdminService.addProviderInsurances({ ...this.brokerDetailForm.value, ...insertData }).subscribe((res: any) => {
        this.closeDialog();
        this.brokerDetailForm.reset();
        this.event.emit();
        this.loading = false;
        if (res.success) {
          this.toast.success(res.message[0], 'Success');
        } else {
          this.toast.warning(res.message[0], 'Error');
        }
      }, (error) => {
        console.log('error', error);
        this.loading = false;

      });
    } else {
      this.toast.warning('Please Select Provider', 'warning');
     }
  }

  handleError = (control: string, error: string) => {
    return this.brokerDetailForm.controls[control].hasError(error);
  }

}

