import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AdditionalAddress } from '../models/additional-address.model';
import { BreakdownProvider } from '../models/breakdown-providers.model';
import { BreakdownDeletedProvider } from '../models/breakdown-deleted-providers.model';
import { Provider } from '../models/provider.model';
import { UserInformation } from '../models/user-information.model';
@Injectable({
  providedIn: 'root'
})
export class BreakDownProvidersService {

  // tslint:disable-next-line: variable-name
  private _provider: BreakdownProvider;

  constructor(private http: HttpClient) { }

  public get provider(): BreakdownProvider {
    if (!this._provider) {
      this.provider = JSON.parse(sessionStorage.getItem('provider')) || null;
    }
    return this._provider;
  }

  public set provider(value: BreakdownProvider) {
    this._provider = value;
    if (value) {
      sessionStorage.setItem('provider', JSON.stringify(value));
    }
  }

  getProvider(data: BreakdownProvider): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    } // spProvidersSelect
    return this.http.post<any>(`${environment.baseUrl}providersSelect/get`, data).pipe(
      map((res: any) => res.body.map((provider: BreakdownProvider) => new BreakdownProvider().deserialize(provider))),
      catchError(this.handleError<BreakdownProvider[]>('providersSelect', []))
    )
  }

  getDeletedProvider(data: BreakdownDeletedProvider): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = true;
    } // spProvidersSelect
    return this.http.post<any>(`${environment.baseUrl}providersSelect/get`, data).pipe(
      map((res: any) => res.body.map((provider: BreakdownDeletedProvider) => new BreakdownDeletedProvider().deserialize(provider))),
      catchError(this.handleError<BreakdownDeletedProvider[]>('providersSelect', []))
    )
  }

  updateProviderApplications(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    } // spProvidersSelect
    return this.http.put<any>(`${environment.baseUrl}providersUpdate`, data);
  }

  updateProviderApplicationDetails(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}providermembershipapplications/providers`, data);
  }

  findMatchingProvider(filter): Observable<any> {
    return this.http.post(`${environment.baseUrl}getMatchingProvider`, filter).pipe(
      // map((res: any) => {
      //   res.map((provider: Provider) => new Provider().deserialize(provider))
      // }),
      catchError(this.handleError<Provider[]>('getMatchingProvider', []))
    )
  }

  findMatchingProviderParent(providerID): Observable<any> {
    return this.http.post(`${environment.baseUrl}getMatchingProviderParent`, providerID).pipe(
    catchError(this.handleError<Provider[]>('getMatchingProviderParent', []))
    )
  }

  getBreakDownUser(data: UserInformation): Observable<any> {
    if (data.deleteFlag === undefined) {
      data.deleteFlag = false;
    }
    return this.http.post<any>(`${environment.breakDownApi}userInformation/userInformationSelect/get`, data).pipe(
      map((res: any) => res.body.map((userInformation: UserInformation) => new UserInformation().deserialize(userInformation))),
      catchError(this.handleError<UserInformation[]>('userInformationSelect', []))
    );
  }

  deleteBreakDownProvider(providerPk: string): Observable<any> {
    return this.http.delete(`${environment.breakDownApi}providers/deleteProviderAndRelated/${providerPk}`);
  }

  // FIX. IT IS NOT BEING USED. NEEDS TO BE CREATED ON BASEURL.
  // updateProvider(data: BreakdownProvider): Observable<any> {
  //   return this.http.put<any>(`${environment.breakDownApi}providers/providersUpdate`, data);
  // }

  // FIX. IT IS NOT BEING USED. NEEDS TO BE CREATED ON BASEURL.
  // deleteBreakDownLocation(additionalAddressPK: string): Observable<any> {
  //   return this.http.delete(`${environment.breakDownApi}providers/deleteLocationAndRelated/${additionalAddressPK}`);
  // }

  // IS NOT BEING USED. NEEDS TO BE CREATED ON BASEURL FIX
  // updateProviderAdditionalAddress(data: AdditionalAddress): Observable<any> {
  //   return this.http.put<any>(`${environment.breakDownApi}providerAddressess/providerAdditionalAddressessUpdate`, data);
  // }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}
