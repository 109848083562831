import { Component, OnInit, Input, ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { ProviderAccountService } from 'src/app/services/provider-account.service';
import { BubbleService } from 'src/app/services/bubble-service.service';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { forkJoin } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';

@Component({
  selector: 'ngx-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentTermsComponent implements OnInit {

  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  @Input() isParent;
  @Input() providerAccount;
  loading: boolean;
  paymentTerms: any;
  paymentMethods: any;
  accountDetailsForm: UntypedFormGroup;
  accountDetails: any;
  providerAccountKey: any;
  licensedOwnerKey: any;
  disablePaymentTerm: boolean = true;
  parentData: boolean = false;
  accountName: string;
  attachments: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private toast: NbToastrService,
    private providerAccountService: ProviderAccountService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    this.providerAccountKey = this.providerAccount.providerAccountKey;
    this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
    this.accountName = this.providerAccount.accountName;
    if (this.licensedOwnerKey == null || this.licensedOwnerKey == undefined || this.licensedOwnerKey == '') {
      this.disablePaymentTerm = false;
    } else {
      this.disablePaymentTerm = true;
    }
    this.accountDetailsForm = this.formBuilder.group({
      paymentTermKey: [{ value: '', disabled: this.disablePaymentTerm }],
      paymentMethodKey: [{ value: '', disabled: this.disablePaymentTerm }],
      providerAccountKey: [''],
      billingEmail: [''],
      billingPhone: [''],
      address1: [''],
      city: [''],
      stateKey: [''],
      postalCode: [''],
    });
    this.getDropDownValues();
    this.getProviderAccountDetails();
    this.getAttachmentsController();
  }

  getAttachmentsController() {
    const filter = {
      deleted: false,
      associationKey: this.providerAccountKey,
      documentTypeKey: ''
    };
    this.loading = true;
    this.providerAccountService.getAttachmentsController(filter).subscribe((result: any) => {
      this.loading = false;
      const data = this.utilityService.sortData(this.headers, 'insertedTimestamp', 'desc', result.body);
      this.attachments = (data && data[0].url) ? atob(data[0].url) : '';
    }, (error) => {
      this.loading = false;
    });
  }

  getProviderAccountDetails() {
    const accountFilter = {
      providerAccountKey: this.providerAccountKey,
      deleted: false
    };
    this.loading = true;
    this.providerAccountService.getProviderAccounts(accountFilter).subscribe((result: any) => {
      this.loading = false;
      this.accountDetails = result.body[0];
        this.accountDetailsForm.setValue({
          paymentTermKey: this.accountDetails.paymentTermKey,
          paymentMethodKey: this.accountDetails.paymentMethodKey,
          providerAccountKey: this.providerAccountKey,
          billingEmail: this.accountDetails.billingEmail || '',
          billingPhone: this.accountDetails?.billingPhone || '',
          address1: this.accountDetails.address1 || '',
          city: this.accountDetails.city || '',
          stateKey: this.accountDetails.stateKey || '',
          postalCode: this.accountDetails.postalCode || '',
        });
    }, (error) => {
      this.loading = false;
    });
  }

  public getDetailedAddress(address: Address) {
    address.address_components.forEach(element => {
      element.types.forEach(data => {
        switch (data) {
          case 'administrative_area_level_1':
            this.accountDetailsForm.controls.stateKey.setValue(element.long_name);
            break;
          case 'postal_code':
            this.accountDetailsForm.controls.postalCode.setValue(element.long_name);
            break;
          case 'locality':
            this.accountDetailsForm.controls.city.setValue(element.long_name);
            break;
        }
      });
    });
    this.accountDetailsForm.controls.address1.setValue(address.name);
  }


  updateProviderAccountDetails() {
    if (this.parentData) {
      this.accountDetailsForm.get('providerAccountKey').setValue(this.providerAccount.providerAccountKey);
    }
    this.loading = true;
    this.providerAccountService.updateProviderAccounts(this.accountDetailsForm.value).subscribe((result: any) => {
      this.loading = false;
      if (result.success) {
        this.toast.success(result.message[0], 'Success');
        this.parentData = false;
      } else {
        this.toast.warning(result.message[0], 'Error');
      }
    }, (error) => {
      this.loading = false;
    });
  }

  getDropDownValues() {
    const filter = { deleted: false };
    const paymentTerm = this.profileService.getPaymentTerms(filter);
    const paymentMethods = this.profileService.getPaymentMethods(filter);
    this.loading = true;
    forkJoin([paymentTerm, paymentMethods]).subscribe((result: any) => {
      this.loading = false;
      const byDay = result[0].body;
      byDay.sort(function (a, b) {
        a = a.paymentTermKey.split(' ')[0];
        b = b.paymentTermKey.split(' ')[0];
        return a - b;
      });
      this.paymentTerms = byDay;
      this.paymentMethods = result[1].body;
    }, (error) => {
      this.loading = false;
    });
  }

}
