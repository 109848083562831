import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { UserInvites } from 'src/app/models/user-invite.model';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { patterns } from 'src/assets/patterns';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit {

  @ViewChild('csvReader', { static: true }) csvReader: any;
  csvRecords: any;
  insertMultipleServiceProvider: any = [];
  loading: boolean;
  fileData: File = null;
  userKey: string;
  associationKey: string;
  userTypeKey: string;
  totalRecordInserted = 0;
  emailRegex: any;
  mobRegex: any;
  isCustomer = false;
  usersList: any = [];
  platForm: string;
  notLoadedRecords: any = [];

  constructor(
    private userInviteProcess: UserInviteProcess,
    private commonService: CommonService,
    public dialog: MatDialogRef<UploadFileDialogComponent>,
    public dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    private fileUploadService: FileUploadService,
    private usersService: UsersService) { }

  ngOnInit() {
    this.userKey = sessionStorage.getItem('userKey');
    this.associationKey = this.data.associationKey;
    this.userTypeKey = this.data.userTypeKey;
    this.isCustomer = this.data.isCustomer;
    this.platForm = this.data.isProvider ? 'ServicePlus' : 'FleetAssist';
    this.emailRegex = new RegExp(patterns.emailPattern);
    this.mobRegex = new RegExp(patterns.mobnumPattern);
    this.getExecDeptUsers();
  }

  getExecDeptUsers() {
    const objParam = {
      departmentKey: 'Executive',
      deleted: false,
    };

    this.usersService.getUsers(objParam).subscribe((result) => {
      result.body.map((data) => {
        if (data.contactCenterUserRoleKey !== 'Agent') {
          this.usersList.push(data);
        }
      });
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  uploadFiles() {
    if (this.fileData === undefined || this.fileData == null) {
      this.toast.warning('Please Select File', 'Error');
      return false;
    } else {
      if (!!this.associationKey) {
        this.loading = true;
        this.userInviteProcess.addUserInvitationsBatch(this.insertMultipleServiceProvider).subscribe((res) => {
          this.loading = false;
          this.toast.success(res.message[0], 'Success');
          const resLength = res.body.length ? res.body.length - 1 : 0;
          const total = this.totalRecordInserted ? this.totalRecordInserted : 0;
          this.toast.info('Total Records in Uploaded File:' + total, 'Information');
          this.toast.info(' Total Records Loaded: ' + resLength, 'Information');
          this.dialog.close(this.notLoadedRecords);
        }, (error) => {
          this.loading = false;
        });
      } else {
        this.toast.danger('Please select Customer', 'Warning');
      }
    }
  }

  onFileSelectedMethod(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;

    const files = fileInput.srcElement.files;
    const isCSVFile = this.fileUploadService.isCSVFile(files[0]);
    if (isCSVFile) {
      const input = fileInput.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = (data) => {
        const csvData: any = reader.result;
        const csvRecordsArray = csvData.split(/\r\n|\n/);
        if (csvRecordsArray[1] === '') {
          this.toast.warning('File is empty', 'Error');
          this.fileUploadService.fileReset(this.csvReader);
          return false;
        }
        const headersRow = this.fileUploadService.getHeaderArray(csvRecordsArray);
        if (this.isCustomer) {
          this.csvRecords = this.getDataRecordsArrayFromCustomerCSVFile(csvRecordsArray, headersRow.length);
        } else {
          this.csvRecords = this.getDataRecordsArrayFromLOCSVFile(csvRecordsArray, headersRow.length);
        }
      };

      reader.onerror = () => {
        this.toast.warning('error occured while reading file!', 'Error');
      };
    } else {
      this.toast.warning('Please import valid .csv file.', 'Error');
      this.fileUploadService.fileReset(this.csvReader);
    }
  }

  getDataRecordsArrayFromLOCSVFile(csvRecordsArray: any, headerLength: any) {
    const tmpEmails: any = [];
    this.notLoadedRecords = [];
    let recordCount = 0;
    this.totalRecordInserted = csvRecordsArray.length - 2;
    this.loading = true;
    csvRecordsArray.forEach((element, index) => {
      this.usersList = this.usersList.filter(obj => obj.departmentKey === 'Executive');

      const userInvites: UserInvites = new UserInvites();
      const data = element.split(',');
      if (data.length === headerLength) {
        if (index !== 0) {
          const description = data[7].trim();
          const objParams = {
            platform: 'FleetAssist',
            userTypeKey: this.userTypeKey,
            description,
            deleted: false,
          };
          const depObjParams = {
            departmentKey: data[4].trim(),
            deleted: false,
          };
          const roleObjParams = {
            contactCenterUserRoleKey: data[6].trim(),
            deleted: false,
          };
          const supervisorObjParams = {
            userTypeKey: this.userTypeKey,
            departmentKey: data[4].trim(),
            deleted: false,
          };
          const userEmailExists = this.userInviteProcess.checkEmailInUserInvitations({ email: data[2].trim() });
          const securityGroups = this.userInviteProcess.getSecurityGroups(objParams);
          const department = this.commonService.getDepartments(depObjParams);
          const roles = this.commonService.getContactCenterUserRoles(roleObjParams);
          const superVisor = this.commonService.getUsersList(supervisorObjParams);

          forkJoin([securityGroups, department, roles, userEmailExists, superVisor]).subscribe(result => {
            recordCount = recordCount + 1;
            let supervisorUserKey;
            let isSupervisorExists = false;
            if (result[4].body.length > 0) {
              if (data[4].trim() !== 'Executive') {
                result[4].body.map((data) => {
                  if (data.contactCenterUserRoleKey !== 'Agent') {
                    this.usersList.push(data);
                  }
                });
              }
            }

            this.usersList.forEach(obj => {
              if ((obj.firstName).trim() + ' ' + (obj.lastName).trim() === data[5].trim()) {
                isSupervisorExists = true;
                supervisorUserKey = obj.userKey;
              }
            });

            let isEmailExists = true;
            if (result[3].body.length > 0) {
              isEmailExists = false;
            }
            const isValidEmail = this.emailRegex.test(data[2].trim());
            const isValidCellPhone = this.mobRegex.test(data[3].trim());
            userInvites.firstName = data[0].trim();
            userInvites.lastName = data[1].trim();
            userInvites.email = data[2].trim();
            userInvites.cellPhone = data[3].trim();
            userInvites.securityGroupKey = result[0].body.length > 0 ? result[0].body[0].securityGroupKey : null;
            userInvites.associationKey = this.associationKey;
            userInvites.departmentKey = data[4].trim();
            userInvites.supervisor = supervisorUserKey;
            userInvites.contactCenterUserRoleKey = data[6].trim();
            userInvites.userTypeKey = this.userTypeKey;
            userInvites.deleted = false;
            userInvites.insertedUserKey = this.userKey;
            userInvites.systemDefault = true;
            userInvites.updatedUserKey = '';
            userInvites.emailResponseDate = null;
            userInvites.emailDate = null;
            userInvites.subDomainKey = sessionStorage.getItem('subDomainKey') || null;

            if (!tmpEmails.find(x => x.email === data[2].trim()) && isValidEmail &&
              result[0].body.length > 0 && result[1].body.length > 0 && result[2].body.length > 0
              && isEmailExists && isSupervisorExists && data[0].trim() && data[1].trim() && data[2].trim()
              && (data[3].trim() ? isValidCellPhone : true)) {
              userInvites.cellPhone = data[3].trim() ? '+1' + data[3].trim() : '+19999999999';
              this.insertMultipleServiceProvider.push(userInvites);
              tmpEmails.push({
                email: data[2].trim()
              });
            } else {
              userInvites.supervisor = '';
              this.notLoadedRecords.push(userInvites);
            }

            if (this.totalRecordInserted === recordCount) {
              this.loading = false;
            }
          });
        }
      }
    });
    return this.insertMultipleServiceProvider;
  }

  getDataRecordsArrayFromCustomerCSVFile(csvRecordsArray: any, headerLength: any) {
    const tmpEmails: any = [];
    this.notLoadedRecords = [];
    let recordCount = 0;
    this.totalRecordInserted = csvRecordsArray.length - 2;
    this.loading = true;
    csvRecordsArray.forEach((element, index) => {
      const userInvites: UserInvites = new UserInvites();
      const data = element.split(',');
      if (data.length === headerLength) {
        if (index !== 0) {
          const description = data[4].trim();
          const objParams = {
            platform: this.platForm,
            userTypeKey: this.userTypeKey,
            description,
            deleted: false,
          };
          const userEmailExists = this.userInviteProcess.checkEmailInUserInvitations({ email: data[2].trim() });
          const securityGroups = this.userInviteProcess.getSecurityGroups(objParams);

          forkJoin([securityGroups, userEmailExists]).subscribe(result => {
            recordCount = recordCount + 1;
            let isEmailExists = true;
            if (result[1].body.length > 0) {
              isEmailExists = false;
            }
            const isValidEmail = this.emailRegex.test(data[2].trim());
            const isValidCellPhone = this.mobRegex.test(data[3].trim());
            userInvites.firstName = data[0].trim();
            userInvites.lastName = data[1].trim();
            userInvites.email = data[2].trim();
            userInvites.cellPhone = data[3].trim();
            userInvites.securityGroupKey = result[0].body.length > 0 ? result[0].body[0].securityGroupKey : null;
            userInvites.associationKey = this.associationKey;
            userInvites.departmentKey = '';
            userInvites.supervisor = '';
            userInvites.contactCenterUserRoleKey = '';
            userInvites.userTypeKey = this.userTypeKey;
            userInvites.deleted = false;
            userInvites.insertedUserKey = this.userKey;
            userInvites.systemDefault = true;
            userInvites.updatedUserKey = '';
            userInvites.emailResponseDate = null;
            userInvites.emailDate = null;
            userInvites.subDomainKey = sessionStorage.getItem('subDomainKey') || null;

            if (!tmpEmails.find(x => x.email === data[2].trim()) && isValidEmail &&
              result[0].body.length > 0 && isEmailExists && !!data[0].trim() && !!data[1].trim()
              && data[2].trim() && (data[3].trim() ? isValidCellPhone : true)) {
              userInvites.cellPhone = data[3].trim() ? '+1' + data[3].trim() : '+19999999999';
              this.insertMultipleServiceProvider.push(userInvites);
              tmpEmails.push({
                email: data[2].trim()
              });
            } else {
              this.notLoadedRecords.push(userInvites);
            }

            if (this.totalRecordInserted === recordCount) {
              this.loading = false;
            }
          });
        }
      }
    });
    return this.insertMultipleServiceProvider;
  }

  closeDialog() {
    this.dialog.close();
  }
}
