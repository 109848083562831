import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderElectronicDispatchService {

  constructor(
    private http: HttpClient,
  ) { }

  getProviderElectronicDispatchEmails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providerelectronicdispatchemails/get`, data);
  }

  getProviderElectronicDispatchPhoneNumbers(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providerelectronicdispatchphonenumbers/get`, data);
  }

  insertProviderElectronicDispatchEmails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providerelectronicdispatchemails`, data);
  }

  insertProviderElectronicDispatchPhoneNumbers(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providerelectronicdispatchphonenumbers`, data);
  }

  deleteProviderElectronicDispatchEmails(data: any = {}): Observable<any> {
    const deleteEmail: any = {
      body: data,
    };
    return this.http.delete(`${environment.apiUrl}serviceplus/providerelectronicdispatchemails`, deleteEmail);
  }

  deleteProviderElectronicDispatchPhoneNumbers(data: any = {}): Observable<any> {
    const deletePhone: any = {
      body: data,
    };
    return this.http.delete(`${environment.apiUrl}serviceplus/providerelectronicdispatchphonenumbers`, deletePhone);
  }
}
