import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITreeOptions } from 'angular-tree-component';

import { BubbleService } from 'src/app/services/bubble.service';
import { CommonService } from 'src/app/services/common.service';
import { AddEditNetworkDialogComponent } from 'src/app/dialogs/add-edit-network-dialog/add-edit-network-dialog.component';
@Component({
  selector: 'ngx-offerings',
  templateUrl: './offerings.component.html',
  styleUrls: ['./offerings.component.scss']
})
export class OfferingsComponent implements OnInit {

  options: ITreeOptions = {
    animateExpand: true,
    isExpandedField: 'expanded'
  };

  loading = false;
  serviceData: any;
  isActiveNode: boolean = true;
  addNode: boolean = true; // If false do not show add button on node.
  editNode: boolean = true; // If false do not show edit button on node.
  delNode: boolean = true; // If false do not show delete button on node.
  lastNode: number = 4; // Number of levels to show (If set to -1 tree can go to n levels)

  constructor(
    public dialog: MatDialog,
    private bubbleService: BubbleService,
    private commonService: CommonService) { }

  ngOnInit() {
    this.getLicensedOwnerOfferings();
    this.bubbleService.getBubbleTreeData().subscribe(result => {
      this.serviceData = JSON.parse(localStorage.getItem("serviceNetwork"));
    });
  }

  getLicensedOwnerOfferings() {
    this.loading = true;
    this.commonService.getLicensedOwnerOfferings().subscribe(result => {
      this.loading = false;
      this.serviceData = result ? this.filterInactiveNodes(result) : [];
      this.setMainTreeJson();
    });
  }

  filterInactiveNodes(serviceData) {
    serviceData.map(obj => {
      if (obj.children) {
        obj.children = this.filterInactiveNodes(obj.children);
      }

      if (obj.deleted) {
        obj.children = [];
      }
    });

    return serviceData;
  }

  activateInactivate(activeState, node) {
    const isActive = !activeState ? 1 : 0;
    const arrReqData = {
      inUpdatedUserKey: '12d190b3-3887-4576-9332-ac0603692b06',
      inNetworkOwnerOfferingKey: node.key,
      inDeleted: isActive
    };

    this.loading = true;
    this.commonService.updateTierServiceOfferring(arrReqData).subscribe(result => {
      this.loading = false;
      this.getLicensedOwnerOfferings();
    });
  }

  addEditNetwork(OperationData, editFlag) {
    const dialogRef = this.dialog.open(AddEditNetworkDialogComponent, {
      hasBackdrop: true,
      width: "60%",
      position: {
        top: "5%"
      },
      data: {
        OperationData: OperationData,
        editFlag: editFlag,
        level: 0
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getLicensedOwnerOfferings();
    });
  }

  deleteNode(removeNode) {
    const arrReqData = {
      inUpdatedUserKey: '12d190b3-3887-4576-9332-ac0603692b06',
      inNetworkOwnerOfferingKey: removeNode.key,
      inDeleted: -2
    };

    this.loading = true;
    this.commonService.updateTierServiceOfferring(arrReqData).subscribe(result => {
      this.loading = false;
      this.getLicensedOwnerOfferings();
    });
  }

  setMainTreeJson() {
    localStorage.setItem("serviceNetwork", JSON.stringify(this.serviceData));
    this.bubbleService.setBubbleTreeData();
  }
}
