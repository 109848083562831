<nb-card class="custom-height">
    <nb-card-body>
        <div class="custom-middle text-center">
            <h1>This feature is not available yet</h1>
            <div class="mt-2 text-center">
                <a class="btn btn-link" routerLink="/pages/4db05620-cede-4fb1-bebc-e275559e5525">
                    <i class="fa fa-home"></i>Take Me To Home
                </a>
            </div>
        </div>
    </nb-card-body>
</nb-card>