import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProviderAccountDetailsComponent } from '../../pages/provider-account/provider-account-details/provider-account-details.component';

@Component({
  selector: 'ngx-add-customer-account-dialog',
  templateUrl: './add-customer-account-dialog.component.html',
  styleUrls: ['./add-customer-account-dialog.component.scss']
})

export class AddCustomerAccountDialogComponent implements OnInit {

  @ViewChild(ProviderAccountDetailsComponent, { static: false }) providerAccountDetailsComponent: ProviderAccountDetailsComponent;
  isEdit: boolean;

  constructor(
    public dialog: MatDialogRef<AddCustomerAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  addProviderAccount() {
    this.providerAccountDetailsComponent.addProviderAccount();
  }

  saveCompleted(status) {
    if (status == 'true') {
      this.dialog.close();
    }
  }

  closeDialog() {
    this.dialog.close();
  }

}
