<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6 class="modal-title">Customers</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th sortable="customerName" (sort)="onSort($event)" class="align-middle" direction="asc">Name</th>
                    <th sortable="ultimateParent" (sort)="onSort($event)" class="align-middle">Ultimate Parent</th>
                    <th sortable="phoneNumber" (sort)="onSort($event)" class="align-middle">Phone</th>
                    <th sortable="physicalCity" (sort)="onSort($event)" class="align-middle">City</th>
                    <th sortable="state" (sort)="onSort($event)" class="align-middle">State</th>
                    <th>View Hierarchy</th>
                </tr>
            </thead>
            <tbody *ngIf="customerList.length > 0; else noData">
                <tr *ngFor="let item of customerList">
                    <td>
                        <a href="" onclick="return false;"
                            (click)="getCustomerAdminDetails(item)">{{item.customerName}}</a>
                    </td>
                    <td>{{item.ultimateParent}}</td>
                    <td>{{item.phoneNumber}}</td>
                    <td>{{item.physicalCity}}</td>
                    <td>{{item.state}}</td>
                    <td>
                        <button class="btn btn-primary btn-sm" (click)="viewHierarchy(item)">Hierarchy</button>
                    </td>
                </tr>
            </tbody>
            <ng-template #noData>
                <tbody>
                    <tr>
                        <td colspan="6" class="text-center">No Data available</td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
</div>