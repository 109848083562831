<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="modal-header">
    <h5 class="modal-title">{{dialogTitle}}</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="addServiceProtocolsForm">    
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-select formControlName="callerTypeKey" placeholder="Caller Types">
                        <mat-option *ngFor="let callerTypes of arrCallerTypes" [value]="callerTypes.callerTypeKey">
                            {{callerTypes.callerTypeKey}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('callerTypeKey', 'required')">
                            Caller Type is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-select formControlName="operatingScheduleKey" placeholder="Schedule" required>
                        <mat-option *ngFor="let schedule of arrOperatingSchedules" [value]="schedule.operatingScheduleKey">
                            {{schedule.operatingScheduleKey}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('operatingScheduleKey', 'required')">
                            Schedule is required.
                    </mat-error>
                </mat-form-field>
            </div>            
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-select formControlName="supported" placeholder="Supported" required>
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('supported', 'required')">
                            Supported is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6"> 
                <mat-form-field>
                    <mat-select formControlName="systemDefault" placeholder="Default" required>
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('systemDefault', 'required')">
                            Default is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer text-right">
    <button class="btn btn-primary btn-sm" (click)="addEditProtocols()">Save</button>
</div>
