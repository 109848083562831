import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class ProviderAccountService {

  constructor(
    private http: HttpClient,
  ) { }

  // Update locartion details
  getProviderAccounts(arrReqData: any = {}): Observable<any> {
    if (!arrReqData.deleted) {
      arrReqData.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/provideraccounts/get`, arrReqData);
  }

  // Get Service Provider Discount Programs
  getServiceProviderDiscountProgram(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}getServiceProviderDiscountProgram`, arrReqData);
  }

  addDefaultProvideraccounts(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts`, data);
  }

  addProviderAccountNotes(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, data);
  }

  // Update provider accounts
  updateProviderAccounts(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/provideraccounts`, data);
  }

  getProviderAccountNotes(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountnotes/get`, data);
  }

  // Update Provider account Notes
  updateProviderAccountNotes(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, data);
  }

  deleteProviderAccountNotes(data): Observable<any> {
    const deleteNotesData: any = {
      body: { providerAccountNoteKey: data.providerAccountNoteKey },
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, deleteNotesData);
  }

  // Update Provider account Notes
  getLineItems(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/lineitems/get`, data);
  }

  getProviderAccountProviderPreference(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderAccountProviderPreference`, data);
  }

  // get Customer Details
  getCustomerDetails(data): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customers/get`, data);
  }

  getAttachmentsController(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/attachments/get`, data);
  }

  getLicensedOwnerContactCenterTeamDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownercontactcenterteamdetails/get`, data);
  }
}
