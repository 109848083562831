<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6 class="modal-title">Field Setting</h6>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12 col-md-7">
            <mat-tab-group>
                <mat-tab label="Display">
                    <div class="p-4">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="editModal.textLabel" placeholder="Short Name" autocomplete="off">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="editModal.textPlaceholder" placeholder="Display Name" (keypress)="getInputDisplayNameValue($event)" autocomplete="off">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Data">
                    <div class="p-4">
                        <div class="row">
                            <div class="col-12">
                                <ng-container *ngIf="editModal.textType != 'select'  && editModal.textType != 'radio' && editModal.textType != 'checkbox'">
                                    <mat-form-field>
                                        <input matInput [(ngModel)]="editModal.textValue" placeholder="Enter Default Value" autocomplete="off">
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="editModal.textType == 'select'">
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="dataSourceType" placeholder="Data Source Type">
                                            <mat-option value="values" selected>Values</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                        <ng-container *ngIf="editModal.textType == 'select' || editModal.textType == 'radio' || editModal.textType == 'checkbox'">
                            <div class="row">
                                <ng-container *ngFor="let values of arrSelectedValues; let i = index;">
                                    <div class="col-12 col-md-5">
                                        <mat-form-field>
                                            <input matInput [(ngModel)]="values.text" placeholder="Enter Label" autocomplete="off" (keypress)="getInputValue($event, values)">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-5">
                                        <mat-form-field>
                                            <input matInput [(ngModel)]="values.id" placeholder="Enter Value" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-2 my-auto">
                                        <button class="btn btn-danger btn-sm" (click)="removeSelectValues(values)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary btn-sm" (click)="addNewSelectValues()">
                                        <i class="fas fa-plus"></i> Add Value
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="col-12 col-md-5">
            <nb-card>
                <nb-card-header class="card-header">
                    Preview
                </nb-card-header>
                <nb-card-body>
                    <div class="row">
                        <div class="col-12">
                            <ng-container *ngIf="editModal.textType != 'select' && editModal.textType != 'radio' && editModal.textType != 'checkbox'">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="editModal.textValue" autocomplete="off" [placeholder]="editModal.textPlaceholder">
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="editModal.textType == 'select'">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="previewSelectDetails" [placeholder]="editModal.textPlaceholder">
                                        <mat-option disabled selected value="0">Select Option</mat-option>
                                        <mat-option *ngFor="let item of arrSelectedValues" [value]="item.id">
                                            {{item.text}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="editModal.textType == 'radio'">
                                <div>
                                    <label>{{editModal.textPlaceholder}}</label>
                                </div>
                                <mat-radio-group>
                                    <mat-radio-button *ngFor="let item of arrSelectedValues" [value]="item.id">{{item.text}}</mat-radio-button>
                                </mat-radio-group>
                            </ng-container>
                            
                            <ng-container *ngIf="editModal.textType == 'checkbox'">
                                <div>
                                    <label>{{editModal.textPlaceholder}}</label>
                                </div>
                                <section class="example-section" *ngFor="let item of arrSelectedValues">
                                    <mat-checkbox  [value]="item.id">{{item.text}}</mat-checkbox>
                                </section>
                            </ng-container>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer class="text-right">
                    <button type="button" class="btn btn-danger btn-sm mr-1" (click)="removeSelectedFormControls()">Remove</button>
                    <button type="button" class="btn btn-primary btn-sm" (click)="closeDialog()">Save</button>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="closeDialog()">Close</button>
</div>
