import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BubbleService } from 'src/app/services/bubble-service.service';
import { SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';
import { ITreeOptions } from 'angular-tree-component';
import { DynamoDBService } from 'src/app/services/dynamo-db.service';

@Component({
  selector: 'ngx-vendor-services',
  templateUrl: './vendor-services.component.html',
  styleUrls: ['./vendor-services.component.scss'],
})
export class VendorServicesComponent implements OnInit {
  // Input provider details
  @Input() providerAccount;
  @Input() isParent;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() isServiceSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = false;
  defaultLocationKey: string;
  providerKey: string;
  providerDetails: any;
  parentRecord: boolean = false; // if location has no data set it to true
  parentProviderKey = (sessionStorage.getItem('providerParentKey') != 'null'
    && sessionStorage.getItem('providerParentKey') != '0') ?
    sessionStorage.getItem('providerParentKey') : sessionStorage.getItem('providerKey');

  //Check box
  serviceData: any;
  selectedCount = 0;
  selectedServices = [];
  service: string;

  options: ITreeOptions = {
    animateExpand: true,
    isExpandedField: 'expanded'
  };

  selectedKey: any;
  searchString: string;
  addRemoveProviderOfferings = []
  providerAccountKey: any;

  constructor(
    public dialog: MatDialog,
    private bubbleService: BubbleService,
    private dynamoDBService: DynamoDBService
  ) { }

  ngOnInit() {

    // ? Get Geohash Provider
    if (!sessionStorage.getItem('geoHashProvider')) this.dynamoDBService.DynamoProviderDetails();

    this.providerKey = this.providerAccount?.providerKey || sessionStorage.getItem('providerKey');

    if (this.isParent) {
      this.getProviderServiceOfferingsWithoutlicensedOwnerKey();
    } else {
      const data = {
        licensedOwnerKey: null,
        providerKey: this.providerKey,
        providerAccountKey: null,
      };

      this.loading = true;
      this.bubbleService.getProviderService(data).subscribe(result => {
        this.loading = false;
        if (result.body.length > 0) {
          this.parentRecord = false;
          this.bubbleService.setIsParentRecord(false);
        } else {
          this.parentRecord = true;
          this.bubbleService.setIsParentRecord(true);
        }

        this.getProviderServiceOfferingsWithoutlicensedOwnerKey();
      }, (error) => {
        this.loading = false;
      });
    }
  }

  expandSelectedSearchNode(array, value, isExpand) {
    let found = false;
    array.forEach(element => {
      element.expanded = !isExpand ? this.expandSelectedSearchNode(element.children || [], value, isExpand) || element.key === value : false;
      found = found || element.expanded;
    });
    return found;
  }

  onSelectedChange(serviceData, event): void {
    this.updateChildNodeCheckbox(serviceData.data, event.checked);
    this.updateParentNodeCheckbox(serviceData);
    this.countAndListServices();

    const isDeleted = !event.checked ? 1 : 0;
    if (this.parentRecord) {
      this.addProviderAccountDataIntoLocation(isDeleted, serviceData.data);
    } else {
      const arrReqData = {
        isDeleted: isDeleted,
        networkOwnerOfferingKey: serviceData.data?.key,
        providerKey: this.providerKey,
        providerServiceOfferingKey: serviceData.data?.providerServiceOfferingKey,
      };

      this.addRemoveOfferings(serviceData.data?.name, isDeleted);
      this.updateGeoHash();
      this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
        // if (this.searchString) {
          this.selectedKey = serviceData.data?.key;
          this.searchString = '';
          this.isServiceSelected.emit(this.selectedCount ? true : false);
        // }
        this.getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, serviceData.data);
      });
    }
  }

  updateGeoHash() {
    if (!this.isParent) return;
    const providerUpdateReq = JSON.parse(sessionStorage.getItem('geoHashProvider'));
    if (!providerUpdateReq) return;
    providerUpdateReq.providerOfferings = this.addRemoveProviderOfferings.join('#');
    this.dynamoDBService.geoHashUpdate([providerUpdateReq]);
  }

  addProviderAccountDataIntoLocation(isDeleted, OperationData) {
    // Condition to check if user is adding or editing location
    let parentKey = sessionStorage.getItem('providerKey');
    if (sessionStorage.getItem('providerParentKey') && sessionStorage.getItem('providerParentKey') !== 'null'
      && sessionStorage.getItem('providerParentKey') !== '0') {
      parentKey = sessionStorage.getItem('providerParentKey');
    }

    const data = {
      accountName: this.providerAccount.accountName,
      licensedOwnerKey: null,
      providerKey: parentKey,
      deleted: false,
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderAccountKey(data).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      const setServicesdata = {
        locationKey: this.providerKey,
        providerKey: parentKey,
        licensedOwnerKey: null,
        providerAccountKey: null,
        locationAccountKey: null,
      };

      this.bubbleService.setBubbleLoader(true);
      this.bubbleService.setUpServiceAndRates(setServicesdata).subscribe(() => {
        this.bubbleService.setBubbleLoader(false);
        this.getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData, true);
      }, (error) => {
        this.bubbleService.setBubbleLoader(false);
      });
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted = 1, OperationData = [], isUpdate = false) {
    const data = {
      providerKey: this.parentRecord ? this.parentProviderKey : this.providerKey,
    };

    this.loading = true;
    this.bubbleService.getProviderServiceOfferingsWithoutlicensedOwnerKey(data).subscribe(result => {
      this.serviceData = result;
      this.countAndListServices();
      this.isServiceSelected.emit(this.selectedCount ? true : false);

      if (isUpdate) {
        this.updateSelectedRecord(isDeleted, OperationData);
      }

      if (this.selectedKey) this.expandSelectedSearchNode(this.serviceData, this.selectedKey, false);

      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  updateSelectedRecord(isDeleted, OperationData) {
    const data = {
      providerKey: this.providerKey,
      licensedOwnerOfferingKey: OperationData.licensedOwnerOfferingKey,
    };

    this.loading = true;
    this.bubbleService.getProviderService(data).subscribe(result => {
      this.loading = false;

      const arrReqData = {
        isDeleted: isDeleted,
        networkOwnerOfferingKey: OperationData.key,
        providerKey: this.providerKey,
        providerServiceOfferingKey: result.body[0].providerServiceOfferingKey,
      };

      this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(() => {
        this.getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData);
      });
    }, (error) => {
      this.loading = false;
    });
  }

  addRemoveOfferings(offering, deleted) {
    this.addRemoveProviderOfferings = [];
    const selectedOffering = this.serviceData.filter(x => x.deleted === 0).map((el) => el.name)
    this.addRemoveProviderOfferings = deleted ? [...selectedOffering] : [...selectedOffering, offering];
    if (deleted) {
      const index = this.addRemoveProviderOfferings.indexOf(offering);
      this.addRemoveProviderOfferings.splice(index, 1)
    }
  }

  countAndListServices(): void {
    this.selectedCount = 0;
    this.selectedServices = [];
    this.serviceData.forEach(service => {
      if (service.deleted === 0) {
        this.selectedCount += 1;
        this.selectedServices.push(service.name);
      }
      if (service.children?.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  addItemRecursively(data): void {
    data.forEach(service => {
      if (service.deleted === 0) {
        this.selectedServices.push(service.name);
      }
      if (service.children?.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  public updateChildNodeCheckbox(node, checked): void {
    node.deleted = checked ? 0 : 1;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
    }
  }

  public updateParentNodeCheckbox(node): void {
    if (!node) { return; }

    let allChildrenChecked = true;
    let noChildChecked = true;

    if (node.children && node.children.length > 0) {
      for (const child of node?.children) {
        if (child.data.deleted === 1) {
          allChildrenChecked = false;
        }
        if (child.data.deleted === 0) {
          noChildChecked = false;
        }
      }

      if (allChildrenChecked) {
        node.data.deleted = 0;
      } else if (noChildChecked) {
        node.data.deleted = 1;
      } else {
        node.data.deleted = 0;
      }
    }
    this.updateParentNodeCheckbox(node.parent);
  }


  onInput(val: string) {
    this.search.emit(this.searchString);
  }


}
