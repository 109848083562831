<app-loader [loading]="loading"></app-loader>

<div class="m-4">

    <div class="row">
        <!-- <div class="col-md-2">
            <mat-form-field placeholder="Select Country">
              <mat-select>
                <mat-option>Select Country</mat-option>
              </mat-select>
            </mat-form-field>
        </div> -->
        <div class="col-md-3">
            <mat-form-field placeholder="Select State">
                <mat-label>Select State</mat-label>
                <mat-select [(ngModel)]="stateFilter" (selectionChange)="filterLocations($event)">
                  <mat-option>Select State</mat-option>
                  <mat-option value="All">All State</mat-option>
                  <mat-option *ngFor="let state of statesData | orderBy:'state'"
                    [value]="state.StateKey">
                    {{state.StateDescription}}
                  </mat-option>
                  
                </mat-select>
                
              </mat-form-field>
        </div>
        <mat-form-field class="col-md-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="filterLocations($event)" [(ngModel)]="filterLocation" placeholder="Filter by name, address, city, zipcode, or phone number">
        </mat-form-field>
    </div>

    <!-- <div class="row">


        <div class="col-md-1 ">
            <button class="btn btn-primary btn-sm" (click)="filterByState()">Filter State</button>
          </div>
    </div> -->

    <hr>

    <!-- <div class="row">
    
        <div class="col-md-12">

            <table matSort class="table table-bordered rates">
                <thead class="text-center">
                    <tr>
                        <th> Location Name </th>
                        <th> Status</th>
                        <th> Address </th>
                        <th> City </th>
                        <th> State</th>
                        <th> Zipcode</th>
                        <th> Geocode </th>
                        <th> Day Phone </th>
                        <th> Night Phone</th>
                        <th> Actions </th>
                    </tr>
                </thead>
                <tbody *ngIf="showLocations && showLocations.length > 0; else noData">
                    <tr  *ngFor="let location of showLocations; let i = index;" >
                        <td> {{location.name}}</td>
                        <td >{{location.dispatchStatusKey}}</td>
                        <td > {{location.physicalAddress1}} &nbsp; {{location.physicalAddress2}} </td>
                        <td > {{location.physicalCity}} </td>
                        <td > {{location.physicalStateKey}}</td>
                        <td> {{location.physicalPostalCode }} </td>
                        <td> {{location.physicalLatitude }} &nbsp; {{location.physicalLongitude }}</td>
                        <td > {{ location.businessHoursPhone  }} </td>
                        <td >{{location.afterHoursPhone }} </td>
                
                        <td  class="text-center">
                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Edit"
                            (click)="editSublocation(location)"
                            >
                            <i class="fas fa-pen"></i>
                        </button>
                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                          
                                >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="6" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>

        </div>
    </div> -->

    <br>

    <div class="example-container border fontSize12px" style="font-size: 14px" >
        <table mat-table [dataSource]="dataSource" matSort class="fontSize12px">
            <ng-container mat-header-cell matColumnDef="locationName">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;"> Location Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.dispatchStatusKey}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;"> Address </th>
                <td mat-cell *matCellDef="let element"> {{element.physicalAddress1}} &nbsp; {{element.physicalAddress2}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;"> City </th>
                <td mat-cell *matCellDef="let element"> {{element.physicalCity}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef style="width: 4%;"> State </th>
                <td mat-cell *matCellDef="let element"> {{element.physicalStateKey}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="zipcode">
                <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Zipcode </th>
                <td mat-cell *matCellDef="let element"> {{element.physicalPostalCode}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="geocode">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;"> Geocode </th>
                <td mat-cell *matCellDef="let element"> {{element.physicalLatitude }} &nbsp; {{element.physicalLongitude }} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="dayPhone">
                <th mat-header-cell *matHeaderCellDef style="width: 8%;"> Day Phone </th>
                <td mat-cell *matCellDef="let element"> {{element.businessHoursPhone }} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="nightPhone">
                <th mat-header-cell *matHeaderCellDef style="width: 8%;"> Night Phone </th>
                <td mat-cell *matCellDef="let element"> {{element.afterHoursPhone }} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Edit</th>
                <td mat-cell *matCellDef="let element" class="text-center"> 
                    <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Edit" (click)="editSublocation(element)">
                        <i class="fas fa-pen"></i>
                    </button>
                    <button disabled class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                    <!-- <button mat-icon-button>
                    <mat-icon>delete</mat-icon> -->
                <!-- </button> -->
            </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="lineForBottomOfTable"></div>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>


    </div>




</div>
  <!-- <pan-zoom [config]="panZoomConfig">

    <div style="position: relative;">

      <div class="row">
        <div class="col-md-12">
            <div class="w3-panel">
                <p class="text-size">
                    Services, offered by a Provider, can be restricted to a low priority during Event Provider
                    Search, which only include them where there are limited Providers available.
                </p>
                <p class="text-size">
                    To restrict an offering by this Provider, click on the Offering Bubble gray box. The
                    outline will be updated to Gray and the box will be updated to
                    <i class="fad fa-times-square fa-lg"></i>
                    Which will indicated a Restricted Service.
                </p>
            </div>
        </div>
        <div class="col-md-12 m-2 ml-3">
            <app-bubble-rates-information></app-bubble-rates-information>
        </div>
        <div class="col-md-12">
            <div class="row noMargin hiddenServicesScroll">
                <div class="col-sm-12">
                    <div *ngIf="loading" id="preloader">
                        <div id="loader"></div>
                    </div>
                    <div class="tree-block">
                        <div class="tree-pane">
                            <div class="tree-paning-container">
                                <div class="tree-roots-elements">
                                    <bubble-tree-provider [restricted]="true" [levelData]="serviceData" [level]="level"
                                        [addNode]="addNode" [editNode]="editNode" [delNode]="delNode"
                                        [isActiveNode]="isActiveNode" [disableDragDrop]="disableDragDrop"
                                        [lastNode]="lastNode" [isSelected]="isSelected" [providerAccount]="providerAccount"
                                        [parentRecord]="parentRecord">
                                    </bubble-tree-provider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

  </pan-zoom> -->

<!-- </div>


TEST -->
<!-- <div style="position: absolute; top: 100px; bottom: 0; left: 0; right: 0;"> -->
<!-- <div style="position: relative;">

  <img src="https://image.freepik.com/free-psd/young-girl-with-yellow-sweater-blue-bandana-her-head-extending-hands_1368-19065.jpg">

</div> -->
