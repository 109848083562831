<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="container">
<div class="row">
    <div class="col-12">
        <div class="w3-panel">
            <p>Update ETAs for a 20 mile radius of your location. This is required for your shop to be dispatched.</p>
        </div>
    </div>
</div>

<div class="row">
        <div class="offset-md-8 col-md-4">
            <ngx-search-input (search)="search($event)"></ngx-search-input>
        </div>
    </div>
        <div class="table-responsive eta-account mt-2">
            <table class="table table-bordered rates" [ngClass]="{'parent-data': parentRecord}">
                <thead>
                    <tr>
                        <th sortable="ServiceName" (sort)="onSort($event)" direction="asc">Service</th>
                        <th sortable="SubServiceName" (sort)="onSort($event)" direction="asc">SubService</th>
                        <th sortable="MorningRushHourETA" (sort)="onSort($event)" direction="asc">Morning Rush Hours
                            ETAs(Min)</th>
                        <th sortable="StandardETA" (sort)="onSort($event)" direction="asc">Normal Business Hour
                            ETAs(Min)</th>
                        <th sortable="AfternoonRushHourETA" (sort)="onSort($event)" direction="asc">Afternoon Rush Hours
                            ETAs(Min)</th>
                        <th sortable="AfterHoursETA" (sort)="onSort($event)" direction="asc">After Hours ETAs (Min)</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="providerETAData && providerETAData.length > 0; else noData">
                    <tr *ngFor="let data of providerETAData | filter : searchString;  let i = index;">
                        <td>{{data.ServiceName}}</td>
                        <td>{{data.SubServiceName}}</td>
                        <td>{{data.MorningRushHourETA}}</td>
                        <td>{{data.StandardETA}}</td>
                        <td>{{data.AfternoonRushHourETA}}</td>
                        <td>{{data.AfterHoursETA}}</td>
                        <td>
                            <button class="btn btn-sm btn-default btn-sm btnAnimation" (click)="openEditETADialog(data)"
                                nbTooltip="Edit">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="7" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </div>

