<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="container my-3">
    <div class="row" *ngIf="!isCustomer">
        <div class="col">
            <h6>{{licensedOwnerName}} Load File to Invite Multiple Users</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-8">
            <div class="row">
                <div class="col-md-3 text-center">
                    <div class="font-weight-bold">Step 1</div>
                    <button class="btn btn-sm btn-default" (click)="downloadHowToUseFile()">
                        <i class="far fa-file-pdf fa-2x text-danger" nbTooltip="Download Guide File"></i><br />
                        Guide
                    </button>
                </div>
                <div class="col-md-3 text-center">
                    <div class="font-weight-bold">Step 2</div>
                    <button class="btn btn-sm btn-default" (click)="downloadModelerFile()">
                        <i class="far fa-file-excel fa-2x text-success" nbTooltip="Download Modeler File"></i><br />
                        Download Modeler File
                    </button>
                </div>
                <div class="col-md-3 text-center">
                    <div class="font-weight-bold">Step 3</div>
                    <button class="btn btn-sm btn-primary" (click)="uploadFile(1)">
                        Upload Modeler File
                    </button>
                </div>
                <div class="col-md-3 text-center">
                    <div class="font-weight-bold">Step 4</div>
                    <button class="btn btn-sm btn-primary" (click)="getUploadedUserInvites()">
                        Click to view on Invite List
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="row">
                <div class="col-12">
                    <p>
                        <span class="font-weight-bold">Step 5 :</span>
                        View/Edit Email Message Tab
                    </p>
                </div>
                <div class="col-12">
                    <p>
                        <span class="font-weight-bold">Step 6 :</span>
                        Send Invite(s) - Click Green Button
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive mt-3">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th sortable="firstName" (sort)="onSort($event)" class="align-middle" direction="asc">First Name
                    </th>
                    <th sortable="lastName" (sort)="onSort($event)" class="align-middle">Last Name</th>
                    <th sortable="email" (sort)="onSort($event)" class="align-middle">Email</th>
                    <th sortable="cellPhone" (sort)="onSort($event)" class="align-middle">Cell Phone</th>
                    <th sortable="departmentKey" (sort)="onSort($event)" class="align-middle" *ngIf="!isCustomer">
                        Department</th>
                    <th sortable="supervisor" (sort)="onSort($event)" class="align-middle" *ngIf="!isCustomer">
                        Supervisor</th>
                    <th sortable="contactCenterUserRoleKey" (sort)="onSort($event)" class="align-middle"
                        *ngIf="!isCustomer">Role</th>
                    <th sortable="description" (sort)="onSort($event)" class="align-middle">Security Group</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="userInvitesList.length > 0; else noData">
                <ng-container *ngFor="let user of userInvitesList let i = index">
                    <tr [ngClass]="{'custom-color': user.invalid}">
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.firstName}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.firstName" #firstName="ngModel" required
                                        placeholder="First Name">
                                    <mat-error *ngIf="handleError('firstName', 'required')">
                                        First Name is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.lastName}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.lastName" #lastName="ngModel" required
                                        placeholder="Last Name">
                                    <mat-error *ngIf="handleError('lastName', 'required')">
                                        Last Name is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.email}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.email" #email="ngModel" required
                                        [pattern]="emailPattern" (change)="checkEmailAlreadyExists($event, user)"
                                        placeholder="Email">
                                    <mat-error *ngIf="handleError('email', 'required')">
                                        Email is required.
                                    </mat-error>
                                    <mat-error *ngIf="handleError('email', 'pattern')">
                                        Enter valid Email.
                                    </mat-error>
                                    <div *ngIf="isEmailExists">
                                        <span class="text-danger">An Account already exists with this email. Please try
                                            another email to add user.</span>
                                    </div>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.cellPhone}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.cellPhone" #cellPhone="ngModel"
                                        mask="000-000-0000" placeholder="Cell Phone">
                                </mat-form-field>
                            </span>
                        </td>
                        <td *ngIf="!isCustomer">
                            <span *ngIf="!(user.editMode)">
                                {{user.departmentKey}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.departmentKey" #departmentKey="ngModel" required
                                        placeholder="Department" (selectionChange)="getUsersByDepartment($event)">
                                        <mat-option disabled>Select Department</mat-option>
                                        <mat-option
                                            *ngFor="let department of departmentList  | orderBy : 'departmentKey'"
                                            [value]="department.departmentKey">
                                            {{department.departmentKey}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('departmentKey', 'required')">
                                        Department is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td *ngIf="!isCustomer">
                            <span *ngIf="!(user.editMode)">
                                {{user.supervisor}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.supervisorKey" #supervisor="ngModel" required
                                        placeholder="Supervisor">
                                        <mat-option value=''>Select Supervisor</mat-option>
                                        <mat-option *ngFor="let supervisor of usersList | orderBy : 'lastName'"
                                            [value]="supervisor.userKey">
                                            {{supervisor.firstName}} {{supervisor.lastName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('supervisor', 'required')">
                                        Supervisor is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td *ngIf="!isCustomer">
                            <span *ngIf="!(user.editMode)">
                                {{user.contactCenterUserRoleKey}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.contactCenterUserRoleKey"
                                        #contactCenterUserRoleKey="ngModel" required placeholder="Role">
                                        <mat-option disabled>Select Role</mat-option>
                                        <mat-option
                                            *ngFor="let role of rolesList | orderBy : 'contactCenterUserRoleKey'"
                                            [value]="role.contactCenterUserRoleKey">
                                            {{role.contactCenterUserRoleKey}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('contactCenterUserRoleKey', 'required')">
                                        Role is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.description}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.securityGroupKey" #securityGroupKey="ngModel" required
                                        placeholder="Security Group">
                                        <mat-option disabled>Security Group</mat-option>
                                        <mat-option *ngFor="let group of securityGroups  | orderBy : 'securityLevel'"
                                            [value]="group.securityGroupKey">
                                            {{group.description}} - {{group.securityLevel}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('securityGroupKey', 'required')">
                                        Security Group is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <button *ngIf="!(user.editMode)" class="btn btn-default btnAnimation" nbTooltip="Edit"
                                (click)="editInlineUserInvites(i)" [disabled]="disableEdit">
                                <i class="fas fa-pencil"></i>
                            </button>
                            <button *ngIf="!(user.editMode)" class="btn btn-default btnAnimation" nbTooltip="Delete"
                                (click)="deleteUserInviteDetails(user)" [disabled]="disableEdit">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button *ngIf="!(user.editMode)" class=" btn btn-default btnAnimation" nbTooltip="info"
                                (click)="openEmailDetails(user)">
                                <i class="fas fa-info-circle"></i>
                            </button>
                            <button *ngIf="user.editMode" class="btn btn-default btnAnimation" nbTooltip="Update"
                                (click)="updateUserInvitations(user)">
                                <i class="fas fa-check"></i>
                            </button>
                            <button *ngIf="user.editMode" class="btn btn-default btnAnimation"
                                (click)="cancelUpdateUserInvite(i)" nbTooltip="Cancel">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody>
                <ng-template #noData>
                    <tr>
                        <td *ngIf="!isCustomer" class="text-center" colspan="9">No data available.</td>
                        <td *ngIf="isCustomer" class="text-center" colspan="6">No data available.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>