<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div>
    <form [formGroup]="locationDetailsForm">
        <div class="row mt-1">
            <div class="col-sm-12" *ngIf="!showBlankForm">
                <label>
                    <span>
                        <span class="font-weight-bold">
                            Location ID:
                        </span>
                        {{locationId}}
                    </span>
                </label>
            </div>
            <legend>
                <span class="pl-3">
                    Location Name
                </span>
            </legend>
            <div class="col-md-6 pl-3 pr-1">
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Location Name" formControlName="name" required>
                    <mat-error
                        *ngIf="locationDetailsForm.controls.name.touched && locationDetailsForm.controls.name.errors">
                        <span *ngIf="locationDetailsForm.controls.name.errors.requ
                        ired">Location Name is
                            required.</span>
                        <span *ngIf="locationDetailsForm.controls.name.errors.pattern">Enter valid
                            Location Name.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="!showBlankForm" class="col-md-7 text-right">
                <mat-slide-toggle class="mt-3" formControlName="deleted">
                    {{ locationDetailsForm.controls.deleted.value ? 'Active' : 'Inactive' }}
                </mat-slide-toggle>
            </div>
        </div>
        <fieldset>
            <legend>
                <span>
                    Physical Address
                </span>
            </legend>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 pl-3 pr-1">
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="physicalAddress1" required ngx-google-places-autocomplete
                            (onAddressChange)="getDetailedAddress($event,'physical')" placeholder="Physical Address">
                        <mat-error *ngIf="handleError('physicalAddress1', 'required')">
                            Location physical address 1 is required.
                        </mat-error>
                        <mat-error *ngIf="handleError('physicalAddress1', 'pattern')">
                            Enter valid Physical Address 1.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 px-1">
                    <!-- goes in div [ngClass]="locationDetailsForm.controls.physicalAddress2.errors ? 'mb-3': ''" -->
                    
                    <mat-form-field appearance="outline">
                        <input type="text" matInput placeholder="Physical Address 2" formControlName="physicalAddress2"
                            autocomplete="off">

                        <mat-error *ngIf="handleError('physicalAddress2', 'pattern')">
                            Enter valid Physical Address 2.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 pr-3 pl-1">
                    <!-- [ngClass]="locationDetailsForm.controls.physicalAddress3.errors ? 'mb-3': ''"> -->
                    <mat-form-field appearance="outline">
                        <input type="text" matInput placeholder="Physical Address 3" formControlName="physicalAddress3"
                            autocomplete="off" required>
                        <mat-error *ngIf="handleError('physicalAddress3', 'required')">
                            Location physical address 3 is required.
                        </mat-error>
                        <mat-error *ngIf="handleError('physicalAddress3', 'pattern')">
                            Enter valid Physical Address 3.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 pl-3 pr-1">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Zip Code" formControlName="physicalPostalCode" required
                            autocomplete="off" required>
                        <mat-error
                            *ngIf="locationDetailsForm.controls.physicalPostalCode.touched && locationDetailsForm.controls.physicalPostalCode.errors">
                            <span *ngIf="locationDetailsForm.controls.physicalPostalCode.errors.required">
                                Zip code is required.
                            </span>
                            <span *ngIf="locationDetailsForm.controls.physicalPostalCode.errors.pattern">
                                Enter valid Zip code.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 px-1">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="City" formControlName="physicalCity" required autocomplete="off"
                            required>
                        <mat-error
                            *ngIf="locationDetailsForm.controls.physicalCity.touched && locationDetailsForm.controls.physicalCity.errors">
                            <span *ngIf="locationDetailsForm.controls.physicalCity.errors.required">City is
                                required.</span>
                            <span *ngIf="locationDetailsForm.controls.physicalCity.errors.pattern">Enter valid
                                City.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 px-1">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="State" formControlName="physicalStateKey" required
                            autocomplete="off" required>
                        <mat-error *ngIf="locationDetailsForm.controls.physicalStateKey.errors">
                            <span>State is required.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>      
            <div class="row">              
                <div class="col-sm-12 col-md-6 col-lg-3 pr-1 pl-3">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Physical Latitude" formControlName="physicalLatitude"
                            autocomplete="off">
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 pl-1 pr-1">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Physical Longitude" formControlName="physicalLongitude"
                            autocomplete="off">
                    </mat-form-field>
                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>
                <span>
                    Mailing Address
                </span>
                <button class="btn btn-orange btn-sm ml-2" (click)=setPhysicalAddress()>
                    Same as Physical Address?
                </button>
            </legend>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 pl-3 pr-1">
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="mailingAddress1" ngx-google-places-autocomplete
                            (onAddressChange)="getDetailedAddress($event,'mailing')" placeholder="Mailing Address"
                            required>
                        <mat-error *ngIf="handleError('mailingAddress1', 'required')">
                            Mailing address 1 is required.
                        </mat-error>
                        <mat-error *ngIf="handleError('mailingAddress1', 'pattern')">
                            Enter valid Location Address.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 px-1"
                    [ngClass]="locationDetailsForm.controls.mailingAddress2.errors ? 'mb-3': ''">
                    <mat-form-field appearance="outline">
                        <input type="text" matInput placeholder="Mailing Address 2" formControlName="mailingAddress2"
                            autocomplete="off">

                        <mat-error *ngIf="handleError('mailingAddress2', 'pattern')">
                            Enter valid Mailing Address 2.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 pr-3 pl-1">
                    <!-- [ngClass]="locationDetailsForm.controls.mailingAddress3.errors ? 'mb-3': ''" -->
                    <mat-form-field appearance="outline">
                        <input type="text" matInput placeholder="Mailing Address 3" formControlName="mailingAddress3"
                            autocomplete="off" required>
                        <mat-error *ngIf="handleError('mailingAddress3', 'required')">
                            Mailing address 3 is required.
                        </mat-error>
                        <mat-error *ngIf="handleError('mailingAddress3', 'pattern')">
                            Enter valid Mailing Address 3.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 pl-3 pr-1">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Zip Code" formControlName="mailingPostalCode" autocomplete="off"
                            required>
                        <mat-error *ngIf="handleError('mailingPostalCode','required')">
                            Zip Code is required.
                        </mat-error>
                        <mat-error
                            *ngIf="locationDetailsForm.controls.mailingPostalCode.touched && locationDetailsForm.controls.mailingPostalCode.errors">
                            <span *ngIf="locationDetailsForm.controls.mailingPostalCode.errors.pattern">
                                Enter valid Zip Code.
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 px-1">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="City" formControlName="mailingCity" autocomplete="off" required>
                        <mat-error *ngIf="handleError('mailingCity','required')">
                            City is required.
                        </mat-error>
                        <mat-error
                            *ngIf="locationDetailsForm.controls.mailingCity.touched && locationDetailsForm.controls.mailingCity.errors">
                            <span *ngIf="locationDetailsForm.controls.mailingCity.errors.pattern">Enter valid
                                City.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 px-1">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="State" formControlName="mailingStateKey" autocomplete="off"
                            required>
                        <mat-error *ngIf="handleError('mailingStateKey','required')">
                            State is required.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>

        <div class="row mt-1" *ngIf="!showBlankForm">
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                    <input matInput formControlName="updatedTimestamp" placeholder="Last Update Date">
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                    <input matInput formControlName="updatedUserKey" placeholder="Last Updated By" [disabled]="true">
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-12 col-md-4">
                <button class="btn btn-default btn-sm mt-2 btnAnimation" nbTooltip="View Update History">
                    <i class="fas fa-info-circle"></i>
                </button>
            </div> -->
        </div>
    </form>
</div>