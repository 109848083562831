import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-common-modal-dialogs',
  templateUrl: './common-modal-dialogs.component.html',
  styleUrls: ['./common-modal-dialogs.component.scss']
})
export class CommonModalDialogsComponent implements OnInit {

  modalTitle = 'Profile Details';
  selectedIndex: number;
  customerKey: string;
  providerKey: string;
  userType = 'LicensedOwner';
  platForm = 'FleetAssist';
  isMasterLicensedOwner = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<CommonModalDialogsComponent>) { }

  ngOnInit() {
    this.selectedIndex = this.data.selectedIndex;
    this.isMasterLicensedOwner = this.data.isMasterLicensedOwner;
    if (this.data.platForm === 'ServiceProvider') {
      this.platForm = 'ServiceProvider';
      this.providerKey = this.data.key;
      switch (this.data.selectedIndex) {
        case 0: {
          this.modalTitle = 'Billing';
          break;
        }
        case 1: {
          this.modalTitle = 'Dispatch';
          break;
        }
        case 2: {
          this.modalTitle = 'National Accounts';
          //this.modalTitle = 'Customer Preferences';
          break;
        }
        case 3: {
          this.modalTitle = 'Hours of Operation';
          //this.modalTitle = 'National Accounts';
          break;
        }
        case 4: {
          this.modalTitle = 'Hours of Operation';
          break;
        }
        case 5: {
          this.modalTitle = 'Users';
          break;
        }
        case 6: {
          this.modalTitle = 'Shop Capabilities';
          break;
        }
        case 7: {
          this.modalTitle = 'Technician Expertise';
          break;
        }
      }
    } else {
      this.customerKey = this.data.key;
      switch (this.data.selectedIndex) {
        case 0: {
          this.modalTitle = 'Authorizations';
          break;
        }
        case 1: {
          this.modalTitle = 'Availability';
          break;
        }
        case 2: {
          this.modalTitle = 'Users';
          break;
        }
        case 3: {
          this.modalTitle = 'Assets';
          break;
        }
        case 4: {
          this.modalTitle = 'Notes';
          break;
        }
        case 5: {
          this.modalTitle = 'National Accounts';
          break;
        }
      }
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  close(event) {
    if (event) {
      this.dialog.close();
    }
  }

}
