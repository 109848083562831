<app-loader [loading]="loading"></app-loader>

<div class="m-4">
    <div>
        <!-- <div class="row ">
            <label>
                To promote your business please keep your insurance updated.
                Clients using search will know if your coverage has lapsed.
            </label>
        </div> -->
        <div class="row">
            <label>
                Please fill in your broker information.
                The system will contact them for proof of coverages and expiration dates on your behalf.
                To promote your business please keep your insurance updated.
                Clients using search will know if your coverage has lapsed.
            </label>
        </div>
        <br>
        <!-- <div class="row text-right">
            <div class="col-12 text-right">
                <button class="btn btn-primary btn-sm" *ngIf="!disableFlag" [disabled]="isAddButton"
                     (click)="addNewbroker()">Add New Broker/Agency
                </button>
            </div>
        </div>
        <div class="row text-right">
            <div class="col-md-12 text-right">
                <button disabled class="btn btn-primary btn-sm mt-2 ">Request
                    Update From Broker</button>
            </div>
        </div> -->
    </div>
<div class="row">
    <div class="col-md-12">
        <div class="table-responsive mt-2">
            <table matSort class="table table-bordered rates">
                <thead class="text-center">
                    <tr>
                        <th mat-sort-header="agencyName" sortable="agencyName" (sort)="onSort($event)" direction="asc"> Broker Agency
                        </th>
                        <th> Agent Name </th>
                        <th> Agent Phone </th>
                        <th> Agene Email </th>
                        <th> Policy Expiration Date</th>
                        <th> Actions </th>
                    </tr>
                </thead>
                <tbody *ngIf="brokerDetails && brokerDetails.length > 0; else noData">
                    <tr *ngFor="let brokerDetail of brokerDetails; let i = index;">
                        <td> {{brokerDetail.agencyName}} </td>
                        <td > {{brokerDetail.agentName}} </td>
                        <td >{{brokerDetail.agentPhone}} </td>
                        <td >{{brokerDetail.agentEmail}}</td>
                        <td> {{brokerDetail.policyExpDate | date:'EEEE, MMMM d, y'}} </td>
                       
                        <!-- <td> {{!notes.privateForAccount ? 'Y': 'N'}} </td> -->
                        <td  class="text-center">
                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                            (click)="getAgencyChilds(brokerDetail.providerInsuranceKey)"
                                >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                            <!-- <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Request Update"
                            (click)="getAgencyChilds(brokerDetail.providerInsuranceKey)"
                                >
                                <i class="fas fa-folder-plus"></i>
                            </button> -->
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="6" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

        <div>
            <!-- <div class="row ">
                <label>
                    To promote your business please keep your insurance updated.
                    Clients using search will know if your coverage has lapsed.
                </label>
            </div> -->
            <br>
            <div class="row text-left">
                <div class="col-12 text-left">
                    <button class="btn btn-primary btn-sm" *ngIf="!disableFlag" [disabled]="isAddButton"
                         (click)="addNewbroker()">Add New Broker/Agency
                    </button>
                </div>
            </div>
            <div class="row text-left">
                <div class="col-md-12 text-left">
                    <button disabled class="btn btn-primary btn-sm mt-2 ">Request
                        Update From Broker</button>
                </div>
            </div>
        </div>
        <!-- <div *ngFor="let brokerDetail of brokerDetails">
            <nb-card class="border mt-2">
                <nb-card-body>
                    <div class="row">
                        <div class="col-md-2">
                            <mat-form-field>
                                <input placeholder="Broker Agency" matInput value="{{brokerDetail.agencyName}}"
                                    readonly>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field>
                                <input placeholder="Agent Name" matInput value="{{brokerDetail.agentName}}" readonly>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field>
                                <input placeholder="Agent Phone" matInput value="{{brokerDetail.agentPhone}}" readonly>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input placeholder="Agent Email" matInput value="{{brokerDetail.agentEmail}}" readonly>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-primary btn-sm mt-2 ">Request
                                Update From Broker</button>
                        </div>
                        <div class="col-md-1 text-right">
                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                                (click)="getAgencyChilds(brokerDetail.providerInsuranceKey)">
                                <i class="fas fa-times-square"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row border m-0">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary btn-sm mt-2 mb-2 text-right"
                                (click)="addEditPolicyType(brokerDetail.providerInsuranceKey, 0)">
                                Add New Policy Type
                            </button>
                        </div>
                        <div class="col-md-12 p-1">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Policy Type</th>
                                         
                                            <th>Policy Number</th>
                                            <th>Insured Amount</th>
                                            <th>Expiration Date</th>
                                            <th class="text-center">Action</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let policyDetail of policyDetails">
                                        <tr *ngIf="policyDetail.parentBrokerKey === brokerDetail.providerInsuranceKey">
                                            <td>
                                                <label>{{policyDetail.insuranceTypeKey}}</label>
                                            </td>
                                        
                                            <td>
                                                <label>{{policyDetail.policyNumber}}</label>
                                            </td>
                                            <td>
                                                <span matPrefix
                                                    *ngIf="policyDetail.insuredAmount != null">$&nbsp;</span>
                                                <label>{{policyDetail.insuredAmount}}</label>
                                            </td>
                                            <td>
                                                <label> {{policyDetail.policyExpDate | date: 'yyyy-MM-dd'}} </label>
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-default btn-sm btnAnimation mr-1"
                                                    nbTooltip="Edit" (click)="addEditPolicyType(policyDetail, 1)">
                                                    <i class="fas fa-pencil"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm btnAnimation mr-1"
                                                    nbTooltip="Delete"
                                                    (click)="deletePolicyDetails(policyDetail.providerInsuranceKey)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <td class="text-center">
                                                <input hidden type="file" accept='image/*,application/pdf' #file
                                                    (change)="preview(file.files, $event, policyDetail)"
                                                    class="uploadFile img" value="Upload Photo">
                                                <button class="btn btn-default btn-sm btnAnimation mr-1"
                                                    (click)="file.click()" nbTooltip="Upload Policy">
                                                    <i class="fas fa-upload"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm btnAnimation mr-1"
                                                    (click)="viewPolicy(policyDetail.cartificateURL)"
                                                    nbTooltip="View Policy"><i class="fas fa-file-alt"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm btnAnimation"
                                                    (click)="showAccountRequirements(policyDetail)"
                                                    nbTooltip="Account Requirements">
                                              
                                                    <i class="fas fa-user-circle"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <ng-template #noData>
                                        <tbody>
                                            <tr>
                                                <td colspan="6" class="text-center">No Data available</td>
                                            </tr>
                                        </tbody>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
            </nb-card>
        </div> -->
    </div>
</div>
</div>