import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class BubbleService {

    public bubbleData = new Subject<any>();
    public bubbleLoader = new Subject<any>();
    public serviceNetworkData: any;
    public isParentRecord = new Subject<any>();

    constructor(
        private http: HttpClient,
    ) { }

    setBubbleTreeData() {
        this.bubbleData.next();
    }

    getBubbleTreeData(): Observable<any> {
        return this.bubbleData.asObservable();
    }

    setserviceNetworkData(serviceNetworkData: any) {
        this.serviceNetworkData = serviceNetworkData;
    }

    getserviceNetworkData() {
        return this.serviceNetworkData;
    }

    setBubbleLoader(message: boolean) {
        this.bubbleLoader.next(message);
    }

    setIsParentRecord(message: boolean) {
        this.isParentRecord.next(message);
    }

    // Add LicensedOwner Offerings
    addLicensedOwnerOfferings(arrReqData): Observable<any> {
        return this.http.post(`${environment.baseUrl}addTierServiceOfferring`, arrReqData);
    }

    // Update Tier Service Offerring
    updateTierServiceOfferring(arrReqData): Observable<any> {
        return this.http.post(`${environment.baseUrl}updateTierServiceOfferring`, arrReqData);
    }

    // Update Tier Service Offerring
    updateActivateInActivateProviderServiceOfferings(arrReqData): Observable<any> {
        return this.http.post(`${environment.baseUrl}updateActivateInActivateProviderServiceOfferings`, arrReqData);
    }

    // API call to get all offering data having no licensedOwnerKey.
    getProviderServiceOfferingsWithoutlicensedOwnerKey(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}getProviderServiceOfferingsWithoutlicensedOwnerKey`, data);
    }

    /**
     * service to get provice offering data
     * @param providerKey
     */
    getProviderService(data): Observable<any> {
        if (!data.deleted) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}serviceplus/providerserviceofferings/get`, data);
    }

    /**
     * if user updates any child record replicated all parent services and rates into location
     * @param locationKey and ProvicerKey
     */
    setUpServiceAndRates(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}setUpServiceAndRates`, data);
    }

    /**
     * get provider (Parent) account key to setup rates into location
     * @param licensedOwnerName, ProvicerKey, licensedOwnerKey
     */
    getProviderAccountKey(data): Observable<any> {
        if (!data.deleted) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts/get`, data);
    }

    getLicensedOwnerOfferings(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerofferings/get`, data);
    }

    getServiceExceptions(licensedOwnerKey): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}getGetDiscountServiceExceptions/${licensedOwnerKey}`);
    }
}
