import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CustomerSearchDialogComponent } from 'src/app/dialogs/customer-search-dialog/customer-search-dialog.component';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit, OnChanges {

  @Input() customerProfile = false;
  @Input() selectedIndex?: number;
  @Output() clearSearch = new EventEmitter<any>();
  @Output() customerKey = new EventEmitter<any>();
  @Input() isClearSearchFilter = false;

  parentCustomerList: any = [];
  customerSearchForm: UntypedFormGroup;
  dataDictionaryKey: string;
  schema: string;
  loading = false;

  constructor(
    private customerService: CustomerService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toast: NbToastrService,
  ) { }

  ngOnInit() {

    this.schema = 'FleetAssist';
    this.dataDictionaryKey = 'c706f7b3-5e09-4731-a89b-b371a5d86758';

    this.customerSearchForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      parent: [null]
    });

    this.updateValidators();

    if (!this.customerProfile) {
      this.getParentCustomerList();
    }
  }

  ngOnChanges(): void {
    if (this.isClearSearchFilter) {
      this.clearSearchFilters();
    }
  }

  updateValidators() {
    if (this.customerProfile) {
      this.customerSearchForm.controls.name.setValidators(Validators.required);
      this.customerSearchForm.controls.name.updateValueAndValidity();
    } else {
      this.customerSearchForm.controls.name.clearValidators();
      this.customerSearchForm.controls.name.updateValueAndValidity();
    }
  }

  getParentCustomerList() {
    const filter = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
    };

    this.loading = true;
    this.customerService.getParentCustomers(filter).subscribe(res => {
      this.loading = false;
      if (res.success) {
        this.parentCustomerList = res.body;
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  checkParent() {
    if (!this.customerProfile) {
      if (!this.customerSearchForm.controls.name.value && !this.customerSearchForm.controls.parent.value) {
        this.toast.warning('Please enter at least one field', 'Warning');
        return false;
      }

      if (!(this.customerSearchForm.controls.name.value)?.trim() && this.customerSearchForm.controls.parent.value) {
        this.getCustomerDetails(this.customerSearchForm.controls.parent.value);
      } else {
        this.searchCustomers();
      }
    } else {
      if (this.customerSearchForm.invalid) {
        this.customerSearchForm.markAllAsTouched();
        return false;
      }

      this.searchCustomers();
    }
  }

  searchCustomers() {
    const dialogRef = this.dialog.open(CustomerSearchDialogComponent, {
      width: '80%',
      disableClose: true,
      position: {
        top: '4%',
      },
      data: {
        searchText: this.customerSearchForm.controls.name.value,
        ultimateParentkey: this.customerSearchForm.controls.parent.value,
        customerProfile: this.customerProfile
      }
    });

    dialogRef.componentInstance.eventCustomer.subscribe(value => {
      this.clearSearch.emit(false);
      this.customerKey.emit(value);
    });
  }

  clearFilters() {
    this.customerSearchForm.reset();
    this.clearSearch.emit(true);
  }

  getCustomerDetails(customerKey) {
    const customer = this.parentCustomerList.find(customer => customer.customerKey === customerKey);
    this.clearSearch.emit(false);
    this.customerKey.emit(customer);
  }

  getParent(event: any) {
    this.customerSearchForm.controls.name.setValue('');
  }

  clearSearchFilters() {
    this.customerSearchForm.reset();
  }
}
