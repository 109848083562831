<div class="modal-header">
    <h6 class="modal-title">Add Location Details</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="col-md-12">
        <mat-horizontal-stepper linear #stepper [selectedIndex]='selectedIndex'
            (selectionChange)='currentStage($event)'>
            <mat-step completed="false">
                <ng-template matStepLabel>Location Details</ng-template>
                <ngx-location-details (saveCompleted)='saveCompleted($event)' (newLocation)='getNewLocation($event)'
                    [showSave]="false" [showBlankForm]="true">
                </ngx-location-details>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>Dispatch</ng-template>
                <ngx-setup-dispatch-details *ngIf="selectedIndex == 1" [locationKey]="locationKey"
                    (saveCompleted)='saveCompleted($event)'>
                </ngx-setup-dispatch-details>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>Billing</ng-template>
                <ngx-billing-details *ngIf="selectedIndex == 2" [locationKey]="locationKey"
                    (saveCompleted)='saveCompleted($event)'>
                </ngx-billing-details>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Service Offerings</ng-template>
                <ngx-vendor-services *ngIf="selectedIndex == 3" [providerAccount]="providerAccount" [isParent]="false">
                </ngx-vendor-services>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Accounts</ng-template>
                <ngx-location-accounts *ngIf="selectedIndex == 4" [locationKey]="locationKey">
                </ngx-location-accounts>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Hour Of Operation</ng-template>
                <ngx-hours-of-operation *ngIf="selectedIndex == 5" [locationKey]="locationKey">
                </ngx-hours-of-operation>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Shop Capabilities</ng-template>
                <ngx-provider-capabilities *ngIf="selectedIndex == 6" [locationKey]="locationKey">
                </ngx-provider-capabilities>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Asset</ng-template>
                <ngx-location-assigned-asset-details *ngIf="selectedIndex == 7" [locationKey]="locationKey">
                </ngx-location-assigned-asset-details>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Users</ng-template>
                <ngx-employee *ngIf="selectedIndex == 8" [locationKey]="locationKey" [isParent]="true"
                    [locationDetails]="providerLocation"></ngx-employee>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="selectedIndex != 0" class="btn btn-orange btn-md buttonCSS mr-2" (click)="previous()">Previous</button>
    <button
        *ngIf="selectedIndex != 3 && selectedIndex != 4 && selectedIndex != 5 && selectedIndex != 6 && selectedIndex != 7 && selectedIndex != 8"
        class="btn btn-orange buttonCSS" type="button" (click)="next(true)">Save & Next</button>
    <button mat-button *ngIf="selectedIndex != 0 && selectedIndex != 1 && selectedIndex != 2 && selectedIndex != 8"
        class="btn btn-orange btn-md p-2 ml-1 mr-1 mt-3" (click)="next(true)">Next</button>
    <button *ngIf="selectedIndex == 8" class="btn btn-orange btn-md mr-2 mt-3" (click)="closeDialog()">Submit</button>
</div>