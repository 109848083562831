import { Component, OnInit, Optional, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProviderAccountService } from 'src/app/services/provider-account.service';
import { SortEvent, SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'ngx-view-line-item-exclusion-list-dialog',
  templateUrl: './view-line-item-exclusion-list-dialog.component.html',
  styleUrls: ['./view-line-item-exclusion-list-dialog.component.scss']
})
export class ViewLineItemExclusionListDialogComponent implements OnInit {

  lineItemExceptions = [];
  loading: boolean = false;
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  constructor(
    public dialog: MatDialogRef<ViewLineItemExclusionListDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: ProviderAccountService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.getProviderDiscountLineItemExceptions();
  }

  getProviderDiscountLineItemExceptions() {
    let arrReqData = {
      deleted: false,
      providerDiscountApplies: false
    };

    this.loading = true;
    this.accountService.getLineItems(arrReqData).subscribe((result: any) => {
      this.lineItemExceptions = result.body;
      this.onSort({ column: 'lineItemKey', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      console.log('error ' + error);
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.lineItemExceptions = this.utilityService.sortData(this.headers, column, direction, this.lineItemExceptions);
  }

  closeDialog() {
    this.dialog.close();
  }

}
