import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddQuestionComponent } from 'src/app/pages/dynamic-questions/add-question/add-question.component';
import { NbToastrService } from '@nebular/theme';
import { OfferingQuestionsService } from 'src/app/services/offering-questions.service';

@Component({
  selector: 'app-add-offering-questions-modal',
  templateUrl: './add-offering-questions-modal.component.html',
  styleUrls: ['./add-offering-questions-modal.component.scss']
})
export class AddOfferingQuestionsModalComponent implements OnInit {

  @ViewChild(AddQuestionComponent) addQuestionComponent: AddQuestionComponent;

  dynamicFormKey = '';
  serviceData: any = [];
  dynamicJson: any = [];
  loading = false;
  isEdit = false;
  isEventProtocol = false;
  isEmitJson = false;

  constructor(
    private offeringQuestionsService: OfferingQuestionsService,
    public dialogRef: MatDialogRef<AddOfferingQuestionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService) { }

  ngOnInit() {
    this.serviceData = this.data.levelData;
    this.isEdit = this.data.isEdit;
    this.isEventProtocol = this.data.isEventProtocol ? this.data.isEventProtocol : false;
    this.isEmitJson = this.data.isEmitJson ? this.data.isEmitJson : false;

    if (this.isEventProtocol) {
      if (this.isEdit) {
        this.dynamicFormKey = this.data.dynamicFormKey;
      }
    } else {
      if (this.isEdit) {
        this.dynamicFormKey = this.data.levelData.dynamicFormKey;
      }
    }
  }

  saveQuestions() {
    this.addQuestionComponent.saveJson();
  }

  onDynamicFormCreated(dynamicFormKey) {
    if (!this.isEventProtocol) {
      this.updateLicensedOwnerOfeering({ dynamicFormKey });
    }
    this.closeDialog(dynamicFormKey);
  }

  onDynamicFormUpdated(dynamicFormKey) {
    this.closeDialog(dynamicFormKey);
  }

  updateLicensedOwnerOfeering(updateData) {

    const objUpdateParams = {
      licensedOwnerOfferingKey: this.serviceData.licensedOwnerOfferingKey,
      dynamicFormKey: updateData.dynamicFormKey,
      updatedUserKey: sessionStorage.getItem('userKey'),
    };
    this.loading = true;
    this.offeringQuestionsService.updateLicensedOwnerOfferingsDynamicFormKey(objUpdateParams).subscribe(res => {
      if (res.success) {
        this.toast.success(res.message, 'Updated');
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      console.log(error.message);
    });
  }

  newDynamicJson(dynamicJson) {
    this.dynamicJson = dynamicJson;
    this.closeDialog();
  }

  closeDialog(dynamicFormKey = '') {
    this.dialogRef.close({ dynamicFormKey: dynamicFormKey, dynamicJson: this.dynamicJson });
  }
}
