import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { BubbleService } from 'src/app/services/bubble.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddProtocolsDialogComponent } from '../add-protocols-dialog/add-protocols-dialog.component';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { LicensedOwnerProtocol } from 'src/app/models/licensed-owner-protocol.model';
import { UtilityService } from 'src/app/services/utility.service';
import { ProtocolService } from 'src/app/services/protocol.service';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-protocol-list-dialog',
  templateUrl: './protocol-list-dialog.component.html',
  styleUrls: ['./protocol-list-dialog.component.scss']
})
export class ProtocolListDialogComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  isLicensedOwnerProtocol: boolean;
  userIsLicensedOwner: boolean;
  protocolList: LicensedOwnerProtocol[] = [];
  searchString: string;
  dialogTitle: string;
  arrProtocolDetails: any = [];
  loading = false;

  constructor(public dialogRef: MatDialogRef<ProtocolListDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toast: NbToastrService,
              private dialogService: NbDialogService,
              private utilityservice: UtilityService,
              private protocolService: ProtocolService,
              private bubbleService: BubbleService) { }

    ngOnInit() {

      this.isLicensedOwnerProtocol = (this.data.isLicensedOwnerProtocol) ? true : false;
      this.userIsLicensedOwner = (sessionStorage.getItem('userTypeKey') === '9c4ef5b4-cadb-400b-adf4-05b33798f1fd') ? true : false;
      this.dialogTitle = this.data.dialogTitle;
        this.getLicensedOwnerProtocols();
      }

    /***
    * @Desc: Get all licnsed owner protocols by LicensedOwnerKey
    * @returns success result
    */
    getLicensedOwnerProtocols() {
      this.loading = true;
      const paramsData = {
        customerKey: '',
        licensedOwnerOfferingKey : this.data.data.licensedOwnerOfferingKey,
        deleted: 0
      };
      this.protocolService.getProtocolList(paramsData).subscribe(response => {
        const protocolList = response.body.filter(data => data.licensedOwnerOfferingKey === paramsData.licensedOwnerOfferingKey);
        this.arrProtocolDetails = protocolList.map(data => new LicensedOwnerProtocol(data));
        this.loading = false;
      }, (error) => {
        console.log('error', error);
        this.loading = false;
      });
    }

    /***
    * @Desc: remove protocols
    * @returns success result
    */
    removeLicensedOwnerProtocols(event: any) {
      this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
        if (isDelete) {
          this.loading = true;
          if (this.data.isLicensedOwnerProtocol) {
            const updateData = {
              customerProtocolKey: event.customerProtocolKey,
              updatedUserKey: event.userKey,
              deleted: 1,
            };

            this.protocolService.editCustomerProtocols(updateData).subscribe(response => {
              if (response.success) {
                this.toast.success('Master Protocol deleted Successfully!', 'Success');
              } else {
                this.toast.warning(response.message[0], 'Error');
              }
              this.getLicensedOwnerOfferings();
              this.loading = false;
              this.dialogRef.close();
            }, (error) => {
              console.log('error', error);
              this.loading = false;
            });
          }
        }
      });
    }

    /***
    * @Desc: get licensed owner offerings
    * @params licenesedOwnerKey
    * @returns array result
    */
    getLicensedOwnerOfferings() {
      this.loading = true;
      const licenesedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
      const objParams = {
        licenesedOwnerKey,
        customerKey: ''
      };
      this.protocolService.getMasterAndCustomersProtocolServices(objParams).subscribe(result => {
        localStorage.setItem('serviceNetworkProtocol', JSON.stringify(result));
        this.bubbleService.setBubbleTreeData();
        this.loading = false;
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    }

    /***
    * @Desc: add protocol
    * @returns success result
    */
    addLicensedOwnerProtocol() {

        const dialogData = {
          data: this.data.data,
          isLicensedOwnerProtocol: this.data.isLicensedOwnerProtocol,
          edit: false,
          dialogTitle: 'Add Protocols'
        };

        const dialogRef = this.dialog.open(AddProtocolsDialogComponent, {
          hasBackdrop: true,
          width: '60%',
          position: {
            top: '4%',
          },
          height: '75%',
          data: dialogData
        });

        dialogRef.afterClosed().subscribe((reponse) => {
            this.getLicensedOwnerProtocols();
        });
      }

    /***
    * @Desc: edit protocol
    * @returns success result
    */
    editLicensedOwnerProtocols(event: any) {

        const dialogData = {data: event, isLicensedOwnerProtocol: this.data.isLicensedOwnerProtocol, edit: true};
        const dialogRef = this.dialog.open(AddProtocolsDialogComponent, {
          hasBackdrop: true,
          width: '60%',
          position: {
            top: '4%',
          },
          data: dialogData
        });

        dialogRef.afterClosed().subscribe((reponse) => {
            this.getLicensedOwnerProtocols();
        });
      }

    /***
    * @Desc: search protocol
    * @returns success result
    */
    search(event) {
      this.searchString = event;
    }

    /***
    * @Desc: sort protocol
    * @returns sorted result
    */
    onSort({ column, direction }: SortEvent) {
      this.arrProtocolDetails = this.utilityservice.sortData(this.headers, column, direction, this.arrProtocolDetails);
    }

    /***
    * @Desc: Export CSV protocol
    * @returns CSV result
    */
    exportToCSVServices() {
      const filterData = [];
      const CSVHeaders = ['Service', 'Caller Type', 'Schedule', 'Supported', 'Default', 'Updated By', 'Updated Time'];
      this.arrProtocolDetails.map(data => {
        const protocolsData = {
          offerings: data.offerings,
          callerTypeKey: data.callerTypeKey,
          operatingScheduleKey: data.operatingScheduleKey,
          supported: (data.supported === true) ? 'Yes' : 'No',
          systemDefault: (data.supported === true) ? 'Yes' : 'No',
          updatedBy: data.updatedBy,
          updatedTimestamp: data.updatedTimestamp
        };
        filterData.push(protocolsData);
      });

      if (filterData.length > 0) {
        this.loading = true;
        const options = {
          filename: 'Protocol-list',
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          useTextFile: false,
          useBom: true,
          headers: CSVHeaders
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(filterData);
        this.loading = false;
        this.toast.success('CSV generated Successfully!', 'Success');
      } else {
        this.toast.warning('No data available.', 'Error');
      }
    }

    closeDialog() {
      this.dialogRef.close();
    }

  }
