import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { TechnicianService } from './services/technician.service';
import { NbToastrService } from '@nebular/theme';
import { Technician } from './models/technician.model';

@Component({
  selector: 'app-provider-technical-expertise',
  templateUrl: './provider-technical-expertise.component.html',
  styleUrls: ['./provider-technical-expertise.component.scss']
})
export class ProviderTechnicalExpertiseComponent implements OnInit {
  @Input() providerInputKey: string;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  loading = false;
  technicianData: any = [];
  searchString: string;
  addEditFlag: string;
  technicianEditData: any = [];
  companydetails:any=[];
  providerID:any;

  constructor(
    private technicianService: TechnicianService,
    private toast: NbToastrService
  ) { }

  ngOnInit(): void {
    this.getTechnicians();
    this.getProviderCompanyDetails();
  }

  addEditTechnician(flag: string, technicianData: any = {}) {
    this.addEditFlag = '';
    this.technicianEditData = [];
    setTimeout(() => {
      this.addEditFlag = flag;
      this.technicianEditData = technicianData
    }, 200);
  }

  getTechnicians() {
    const filter = {
      providerKey: this.providerInputKey
    };
    this.loading = true;
    this.technicianService.getTechnicians(filter).subscribe(response => {
      this.technicianData = response || [];
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  setActiveInactive(event: any, technician: any) {
    const data = new Technician().prepareUpdate({
      active: event,
      providerKey: this.providerInputKey,
      providerTechnicianKey: technician.providerTechnicianKey
    });
    this.loading = true;
    this.technicianService.updateProviderTechnicians(data).subscribe(response => {
      if (response.success) {
        this.addEditFlag = '';
        this.toast.success('', response.message);
        this.getTechnicians();
      } else {
        this.toast.warning('', 'Failed to update');
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.toast.warning('', 'Failed to update');
    });
  }
  updateTechniciansList(event: any) {
    if (event) {
      this.addEditFlag = '';
      this.getTechnicians();
    }
  }
  onSearch(event) {
    this.searchString = event;
  }
  getProviderCompanyDetails() {
    const data = {
        providerKey: this.providerInputKey,
        deleted: null,
    };

    this.loading = true;
    this.technicianService.getProviders(data).subscribe((res: any) => {
        this.companydetails = res.body[0] || [];
        this.providerID= this.companydetails.providerId;
        this.loading = false;
    }, (error) => {
        this.loading = false;
        this.toast.danger(error.message, 'Error');
    });
  }
}
