import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { BreakdownProvider } from '../../models/breakdown-providers.model';
import { BreakDownProvidersService } from '../../services/breakdown-providers.service';
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { Component, Inject, ViewChildren, QueryList } from '@angular/core';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { GooglePlaceDirective, GooglePlaceModule } from 'ngx-google-places-autocomplete-esb';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { patterns } from 'src/assets/patterns';
import { ProviderAdminListComponent } from 'src/app/dialogs/provider-admin-list/provider-admin-list.component';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';
import { MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'ngx-prospect-providers-verification',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    GooglePlaceModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedModule,

  ],
  templateUrl: './prospect-providers-verification.component.html',
  styleUrls: ['./prospect-providers-verification.component.scss']
})
export class ProspectProvidersVerificationComponent {

  constructor(
    private commonService: CommonService,
    private providerAdminService : ProviderAdminService,
    public dialogRef: MatDialogRef<ProspectProvidersVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BreakdownProvider,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private utilityService: UtilityService,
    private breakDownProvidersService: BreakDownProvidersService,

  ) { }

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  locationTypes: string;
  currentStep: number;
  isParentLocation: boolean = true;
  isParentSelected: boolean = false;
  providerSearchForm: UntypedFormGroup;
  providerApplicationForm: UntypedFormGroup;
  selectedParentLocation: any = [];
  duplicateParentData: any = [];
  addAsProviderType: string; 
  statesData: any;
  loading = false;

  ngOnInit(){
    //console.log(this.data)

    this.loading = true;

    this.selectedParentLocation.locationAddress = this.data.address1 + ', ' + this.data.city + ', ' + this.data.state + ', ' + this.data.postalCode;

    this.providerSearchForm = this.formBuilder.group({
      providerName: [null],
      providerID: [null],
      addressLine1: [null],
      city: [null],
      stateKey: [null],
      zipCode: [null],
      phoneNumber: [null],
      companyFEIN: [null],
    })

    this.providerApplicationForm = this.formBuilder.group({
      providerName: [null, [Validators.required]],
      providerID: [null],
      addressLine1: [null, [Validators.required]],
      addressLine2: [null],
      city: [null, [Validators.required]],
      stateKey: [null, [Validators.required]],
      zipCode: [null, [Validators.required]],
      phoneNumber: [null],
      companyFEIN: [null, [Validators.required]],
    })

    this.providerAdminService.getPotentialDuplicateLocations(this.data.providerPK).subscribe({
      next: res => this.duplicateParentData = res[0],
      error: (e) => console.log(e),
      complete: () => {
        this.duplicateParentData = this.utilityService.sortData(this.headers, 'Name', 'asc', this.duplicateParentData);
        this.onSort({ column: 'Name', direction: 'asc' });
        if(this.duplicateParentData.length == 0){
          this.currentStep = 2;
          this.loading = false;
        }
        else{
          this.currentStep = 1;
          this.loading = false;
      }
    }})

    this.getStates();
    this.getApplicationData();
  }

  getStates() {
    if (sessionStorage.getItem('states') == null) {
      this.commonService.getstates().subscribe(result => {
        this.statesData = result.body;
        sessionStorage.setItem('states', JSON.stringify(result));
      });
    } else {
      this.statesData = JSON.parse(sessionStorage.getItem('states')).body;
    }   
  }

  getApplicationData(){
    this.providerApplicationForm.patchValue({
      providerName: this.data.companyName,
      //providerID: this.data.providerID,
      addressLine1: this.data.address1,
      addressLine2: this.data.address2,
      city: this.data.city,
      stateKey: this.data.state,
      zipCode: this.data.postalCode,
      phoneNumber: this.data.businessHoursPhone,
      companyFEIN: this.data.companyFEIN,
    })

      if(this.providerApplicationForm.value.companyFEIN && this.providerApplicationForm.value.companyFEIN.length > 2){

        const numberValues = this.providerApplicationForm.controls.companyFEIN.value.replace(/\D/g, '');
        this.providerApplicationForm.value.companyFEIN = this.providerApplicationForm.controls.companyFEIN.value.replace(/\D/g, '');
    
        //console.log(this.providerApplicationForm.value.companyFEIN.length)
        let newFEIN = numberValues.slice(0, 2) + '-' + numberValues.slice(2) ;
        //console.log(newFEIN)
        this.providerApplicationForm.patchValue({
          companyFEIN: newFEIN,
        })
      }  
  }

  FEINFormat(event){
    console.log(event)

    const numberValues = this.providerApplicationForm.controls.companyFEIN.value.replace(/\D/g, '');

    this.providerApplicationForm.value.companyFEIN = this.providerApplicationForm.controls.companyFEIN.value.replace(/\D/g, '');

    this.providerApplicationForm.patchValue({
      companyFEIN: this.providerApplicationForm.value.companyFEIN,
    })

    if(this.providerApplicationForm.value.companyFEIN.length > 2){
        console.log(this.providerApplicationForm.value.companyFEIN.length)
        let newFEIN = numberValues.slice(0, 2) + '-' + numberValues.slice(2) ;
        console.log(newFEIN)
        this.providerApplicationForm.patchValue({
          companyFEIN: newFEIN,
        })
    }
    else{
        {emitEvent: false}
    }
    console.log(this.providerApplicationForm.value.companyFEIN)
  
  }

  locationType(type){
    if(type == 'parent'){
      this.isParentLocation = true;
      this.isParentSelected = true;
      this.addAsProviderType = 'Parent';
      this.providerApplicationForm.controls.companyFEIN.addValidators([Validators.required]);
      this.providerApplicationForm.controls.companyFEIN.updateValueAndValidity();
    }
    else if(type == 'sublocation'){
      this.isParentLocation = false;
      this.isParentSelected = false;
      this.addAsProviderType = 'Sublocation';
      this.providerApplicationForm.controls.companyFEIN.removeValidators([Validators.required]);
      this.providerApplicationForm.controls.companyFEIN.updateValueAndValidity();
    }
  }

  locationDuplicationCheck(number){
    if(number == 0){
      this.deleteDuplicateProvider()
    }
    else if(number == 1){
      this.currentStep = 2;
    }
  }

  redoParentSearch(){
    this.isParentSelected = false;
    this.selectedParentLocation = [];
    this.providerSearchForm.patchValue({
      providerName: null,
      providerID: null,
      addressLine1: null,
      city: null,
      stateKey: null,
      zipCode: null,
      companyFEIN: null,
    })
  }

  deleteDuplicateProvider(){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Duplicate Application', message: 'Does this provider already exist in ServicePlus?',
        yesButton: 'Yes'
      }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log(result)
        if(result == true){
          const removalData = {
            providerPK: this.data.providerPK,
            removalReason: 'Duplicate Provider'
          }
          this.providerAdminService.deleteProviderApplicationMatch(removalData).subscribe({
            next: res => console.log(res),
            error: (e) => console.log(e),
            complete: () => { this.dialogRef.close();  this.openSnackBar('Application for existing provider has been removed.') }
          })
        }
      })
  }

  getParentLocation(){
    //console.log(this.providerSearchForm.value)

    if(!this.providerSearchForm.controls.providerName.value && !this.providerSearchForm.controls.providerID.value && !this.providerSearchForm.controls.city.value && !this.providerSearchForm.controls.zipCode.value 
      && !this.providerSearchForm.controls.addressLine1.value && !this.providerSearchForm.controls.stateKey.value && !this.providerSearchForm.controls.companyFEIN.value
    ){
      this.openSnackBar('Search Criteria Cannot Be Empty.');
      return;
    }

    if(this.providerSearchForm.value.providerName == ''){
      this.providerSearchForm.value.providerName = null
    }
    if(this.providerSearchForm.value.providerID == ''){
      this.providerSearchForm.value.providerID = null
    }
    if(this.providerSearchForm.value.city == ''){
      this.providerSearchForm.value.city = null
    }
    if(this.providerSearchForm.value.zipCode == ''){
      this.providerSearchForm.value.zipCode = null
    }
    if(this.providerSearchForm.value.addressLine1 == ''){
      this.providerSearchForm.value.addressLine1 = null
    }
    if(this.providerSearchForm.value.stateKey == ''){
      this.providerSearchForm.value.stateKey = null
    }

    const dialogRef = this.dialog.open(ProviderAdminListComponent, {
      hasBackdrop: true,
      width: '50%',
      data: this.providerSearchForm.value,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!response)
        return false;
      //console.log(response)
      let address = response.MailingAddress1 + ', ' + response.MailingCity + ', ' + response.MailingStateKey + ', ' + response.MailingPostalCode

      this.selectedParentLocation.address = address;
      this.selectedParentLocation.name = response.Name;
      this.selectedParentLocation.providerKey = response.ProviderKey;
      this.selectedParentLocation.providerId = response.ProviderId;
      this.isParentSelected = true;

    })
    
  }

  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }

  onSort({ column, direction }: SortEvent) {
    this.duplicateParentData = this.utilityService.sortData(this.headers, column, direction, this.duplicateParentData);
  }

  submitProvider(){
    // console.log(this.data)
    // console.log(this.providerApplicationForm.value)
    // console.log(this.providerSearchForm.value)

    if(!this.providerApplicationForm.valid){
      this.openSnackBar('Please fill out all required fields.');
      return;
    }
    if(!this.addAsProviderType){
      this.openSnackBar('Please select to add as parent location or as sublocation.');
      return;
    }

    if(this.addAsProviderType == 'Sublocation' && !this.selectedParentLocation.providerId){
      this.openSnackBar('Please select a parent location for sublocation.');
      return;
    }

    this.confirmAddingProviderDialog();

    // if (this.providerApplicationForm.value.companyFEIN !== this.data.companyFEIN || this.providerApplicationForm.value.providerName !== this.data.companyName) {

    //   const updateFEIN = {
    //     companyKey: this.data.axlePointCOMlCompanyKey,
    //     companyName: this.providerApplicationForm.value.providerName,
    //     companyFEIN: this.providerApplicationForm.value.companyFEIN,  
    //   }

    //   console.log(updateFEIN)
    //   this.providerAdminService.servicePlus_updateCompany(updateFEIN).subscribe({
    //     next: res => console.log(res),
    //     error: (e) => console.log(e),
    //     complete: () => { this.updateApplicationFEIN(); }
    //   })
    // }

    // if(this.providerApplicationForm.value.providerName !== this.data.companyName){
    //   console.log('Company Name does not match')
    //   const updateData = {
    //     addressKey: this.data.axlePointADDlAddressKey,
    //     companyKey: this.data.axlePointCOMlCompanyKey, // should be parentID if sublocation
    //     address: null,
    //     city: null,
    //     stateKey: null,
    //     zip: null,
    //     lat: null,
    //     lng: null,
    //     email: null,
    //     companyName: this.providerApplicationForm.value.providerName,
    //     phone: null,
    //   }

    //   console.log(updateData)
      // this.providerAdminService.servicePlus_updateAddress(updateData).subscribe({
      //   next: res => console.log(res),
      //   error: (e) => console.log(e),
      //   complete: () => { this.updateApplicationName(); }
      // })
    //}

    // if(this.isParentLocation == true){
    //   this.providerAdminService.addApplicationAsParent(this.data.providerPK).subscribe({ 
    //     next: res => console.log(res),
    //     error: (e) => console.log(e),
    //     complete: () => { this.updateApplicationAfterAddition(); }
    // })
    // }

    // else if(this.isParentLocation == false){
    //   const Data = {
    //     providerPK : this.data.providerPK,
    //     parentKey: this.selectedParentLocation.providerKey,
    //   }
  
    //   this.providerAdminService.addApplicationAsSublocation(Data).subscribe({ 
    //     next: res => console.log(res),
    //     error: (e) => console.log(e),
    //     complete: () => { this.updateApplicationAfterAddition(); }
    //   })
    // }

  }

  confirmAddingProviderDialog(){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Add Provider', message: 'Are you sure you want to add this provider?',
        yesButton: 'Yes'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
      if(result == true){
        if(this.addAsProviderType == "Parent"){
          if (this.providerApplicationForm.value.companyFEIN !== this.data.companyFEIN || this.providerApplicationForm.value.providerName !== this.data.companyName) {

            const updateFEIN = {
              companyKey: this.data.axlePointCOMlCompanyKey,
              companyName: this.providerApplicationForm.value.providerName,
              companyFEIN: this.providerApplicationForm.value.companyFEIN,  
            }
      
            this.providerAdminService.servicePlus_updateCompany(updateFEIN).subscribe({
              next: res => console.log(res),
              error: (e) => console.log(e),
              complete: () => { this.updateParentApplicationServicePlus(); }
            })
          }
          else{ this.updateParentApplicationServicePlus(); }
        }
        else if(this.addAsProviderType == "Sublocation"){

            const updateData = {
              addressKey: this.data.axlePointADDlAddressKey,
              companyKey: this.selectedParentLocation.providerId, //this.data.axlePointCOMlCompanyKey, // should be parentID if sublocation
              address: null,
              city: null,
              stateKey: null,
              zip: null,
              lat: null,
              lng: null,
              email: null,
              companyName: this.providerApplicationForm.value.providerName,
              phone: null,
            }
      
            this.providerAdminService.servicePlus_updateAddress(updateData).subscribe({
              next: res => console.log(res),
              error: (e) => console.log(e),
              complete: () => { this.updateSublocationApplicationServicePlus(); }
            })        
        }
      }
    })
  }

  updateParentApplicationServicePlus(){

    const data = {
      providerPK: this.data.providerPK,
      companyFEIN: this.providerApplicationForm.value.companyFEIN,
      companyName: this.providerApplicationForm.value.providerName,
    }

    this.breakDownProvidersService.updateProviderApplications(data).subscribe({
      next: res => console.log(res),
      error: (e) => console.log(e),
      complete: () => { //this.openSnackBar('Please select a parent location for sublocation.');
        this.providerAdminService.addApplicationAsParent(this.data.providerPK).subscribe({ 
            next: res => console.log(res),
            error: (e) => console.log(e),
            complete: () => { this.updateApplicationAfterAddition(); }
        })
      }
    })
  }

  updateSublocationApplicationServicePlus(){

    const data = {
      providerPK: this.data.providerPK,
      companyName: this.providerApplicationForm.value.providerName,
      axlePointCOMlCompanyKey: this.selectedParentLocation.providerId,
    }

    this.breakDownProvidersService.updateProviderApplications(data).subscribe({
      next: res => console.log(res),
      error: (e) => console.log(e),
      complete: () => { 
        const Data = {
        providerPK : this.data.providerPK,
        parentKey: this.selectedParentLocation.providerKey,
        }
  
        this.providerAdminService.addApplicationAsSublocation(Data).subscribe({ 
          next: res => console.log(res),
          error: (e) => {console.log(e),
            this.openSnackBar('Error. Could not add provider as sublocation.');
          },
          complete: () => { this.updateApplicationAfterAddition(); }
        })
      }
    })
  }

  updateApplicationAfterAddition(){
    const data = {
      providerPK: this.data.providerPK,
      prospect: false,
      onboard: true,
    }

    this.breakDownProvidersService.updateProviderApplications(data).subscribe({
      next: res => console.log(res),
      error: (e) => console.log(e),
      complete: () => { this.dialogRef.close(); this.openSnackBar('Provider has been added to parent location.');
        this.dialogRef.close();
      }
    })
  }

  onNoClick(): void {
    //this.openSnackBar('Match has been canceled.');
    this.dialogRef.close();
  }

}
