<div class="modal-header">
    <h6 class="modal-title">Location</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div>
    <mat-tab-group class="mat-tab-group" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Location Details">
            <ngx-location-details [locationKey]="locationKey" [providerDetails]="providerDetails" [showSave]="false"
                (saveCompleted)='saveCompleted($event)'>
            </ngx-location-details>
        </mat-tab>
        <mat-tab label="Dispatch">
            <ngx-setup-dispatch-details *ngIf="selectedIndex == 1" [locationKey]="locationKey"
                (saveCompleted)='saveCompleted($event)' [providerDetails]="providerDetails" [showSave]="false">
            </ngx-setup-dispatch-details>
        </mat-tab>
        <mat-tab label="Billing">
            <ngx-billing-details *ngIf="selectedIndex == 2" [locationKey]="locationKey"
                [providerDetails]="providerDetails" (saveCompleted)='saveCompleted($event)'></ngx-billing-details>
        </mat-tab>
        <mat-tab label="Service Offerings">
            <ngx-vendor-services *ngIf="selectedIndex == 3" [providerAccount]="providerAccount" [isParent]="false">
            </ngx-vendor-services>
        </mat-tab>
        <mat-tab label="Accounts">
            <ngx-location-accounts *ngIf="selectedIndex == 4" [locationKey]="locationKey"></ngx-location-accounts>
        </mat-tab>
        <mat-tab label="Hours of Operation">
            <ngx-hours-of-operation *ngIf="selectedIndex == 5" [locationKey]="locationKey"
                [providerDetails]="providerDetails"></ngx-hours-of-operation>
        </mat-tab>
        <mat-tab label="Shop Capabilities">
            <ngx-provider-capabilities *ngIf="selectedIndex == 6" [locationKey]="locationKey">
            </ngx-provider-capabilities>
        </mat-tab>
        <mat-tab label="Assets">
            <ngx-location-assigned-asset-details *ngIf="selectedIndex == 7" [locationKey]="locationKey">
            </ngx-location-assigned-asset-details>
        </mat-tab>
        <mat-tab label="Users">
            <ngx-employee *ngIf="selectedIndex == 8" [locationKey]="locationKey" [isParent]="true"
                [locationDetails]="providerDetails"></ngx-employee>
        </mat-tab>
        <mat-tab label="Technician Expertise">
            <ngx-technician-expertise *ngIf="selectedIndex == 9" [locationKey]="locationKey" [isParent]="true"
                [providerDetails]="providerDetails"></ngx-technician-expertise>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="modal-footer">
    <button class="btn btn-orange btn-md mr-2 mt-2" type="button" *ngIf="selectedIndex != 3 && selectedIndex != 4  && selectedIndex != 5 && selectedIndex != 7 && selectedIndex != 6 
        && selectedIndex != 8 && selectedIndex != 9" (click)="next()">Save &
        Next</button>
    <button type="button" *ngIf="selectedIndex != 0 && selectedIndex != 1 && selectedIndex != 2 && selectedIndex != 8 && selectedIndex != 9"
        class="btn btn-orange btn-md ml-2 mt-2" (click)="next()">Next</button>
    <!-- <button class="btn btn-orange btn-md" type="button" (click)="closeDialog()">Close</button> -->
</div>