import { Component, OnInit, ViewChildren, QueryList, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { UploadFileDialogComponent } from '../upload-file-dialog/upload-file-dialog.component';
import { patterns } from 'src/assets/patterns';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { EmailDetailsDialogComponent } from '../email-details-dialog/email-details-dialog.component';

@Component({
  selector: 'app-upload-file-modeler',
  templateUrl: './upload-file-modeler.component.html',
  styleUrls: ['./upload-file-modeler.component.scss']
})
export class UploadFileModelerComponent implements OnInit, OnChanges {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() securityGroups: any = [];
  @Input() departmentList: any = [];
  @Input() rolesList: any = [];
  @Input() userInvitationList: any = [];
  @Input() associationKey: string;
  @Input() userTypeKey: string;
  @Input() isCustomer = false;
  @Input() isProvider = false;
  @Output() listChanged = new EventEmitter<boolean>();

  licensedOwnerName: string;
  addProviderForm: UntypedFormGroup;
  userInvitesList: any = [];
  tempPricing: any = [];
  usersList: any = [];
  isEmailExists: boolean;
  loading: boolean;
  disableEdit: boolean;
  searchString: string;
  departmentKey: string;
  emailPattern = patterns.emailPattern;
  notLoadedRecords: any = [];

  constructor(
    private usersService: UsersService,
    private userInviteProcess: UserInviteProcess,
    private utilityService: UtilityService,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    private dialogService: NbDialogService,
    private dialogRef: MatDialog) { }

  ngOnInit() {
    this.licensedOwnerName = sessionStorage.getItem('licensedOwnerName');
    this.addProviderForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(patterns.emailPattern)]],
      cellPhone: [''],
      departmentKey: ['', [Validators.required]],
      supervisor: ['', [Validators.required]],
      contactCenterUserRoleKey: ['', [Validators.required]],
      securityGroupKey: ['', [Validators.required]],
    });
    this.getUserInvitations();
    this.getExecDeptUsers();
  }

  ngOnChanges() {
    this.getUserInvitations();
  }

  /****
    * Desc: Get Users by type
    * @params postData
    * @return array response
  ***/
  getUsersByType() {
    this.usersList = this.usersList.filter(obj => obj.departmentKey === 'Executive');
    const objParam = {
      userTypeKey: this.userTypeKey,
      departmentKey: this.departmentKey,
      deleted: false,
    };
    this.usersService.getUsers(objParam).subscribe((result) => {
      result.body.map((data) => {
        if (data.contactCenterUserRoleKey !== 'Agent') {
          this.usersList.push(data);
        }
      });
    }, (error) => {
    });
  }

  getExecDeptUsers() {
    const objParam = {
      departmentKey: 'Executive',
      deleted: false,
    };

    this.usersService.getUsers(objParam).subscribe((result) => {
      result.body.map((data) => {
        if (data.contactCenterUserRoleKey !== 'Agent') {
          this.usersList.push(data);
        }
      });
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /****
  * Desc: Get User invitations list.
  * @params postData
  * @return array response
  ***/
  getUserInvitations() {
    this.userInvitesList = this.userInvitationList;
    if (this.notLoadedRecords && this.notLoadedRecords.length > 0) {
      this.notLoadedRecords.forEach(data => {
        data.invalid = true;
        this.userInvitesList.push(data);
      });
    }
    if (this.userInvitesList.length > 0) {
      this.onSort({ column: 'firstName', direction: 'asc' });
    }
  }

  /****
  * Update User invitation details
  * @params postData
  * @return array response
  ***/
  updateUserInvitations(data: any) {
    const emailRegex = new RegExp(patterns.emailPattern);
    const validEmail = emailRegex.test(data.email);

    if (data.firstName === '' || data.lastName === '' || data.email === ''
      || data.securityGroupKey === '' || data.securityGroupKey === null || this.isEmailExists || !validEmail) {
      return false;
    }

    if (!this.isCustomer) {
      if (data.departmentKey === '' || data.contactCenterUserRoleKey === '' || data.supervisorKey === '') {
        return false;
      }
    }

    if (data.invalid) {
      const securityGroups = this.securityGroups.find(item => item.securityGroupKey === data.securityGroupKey);

      if (!securityGroups) {
        return false;
      }

      if (!this.isCustomer) {
      const department = this.departmentList.find(item => item.departmentKey === data.departmentKey);
      const role = this.rolesList.find(item => item.contactCenterUserRoleKey === data.contactCenterUserRoleKey);
      const supervisor = this.usersList.find(item => item.userKey === data.supervisorKey);

      if (!supervisor || !department || !role) {
          return false;
        }
      }
      this.addUserInvitation(data);
    } else {
      const updateObjParams = {
        userInvitationKey: data.userInvitationKey,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        cellPhone: data.cellPhone,
        securityGroupKey: data.securityGroupKey,
        associationKey: data.associationKey,
        departmentKey: data.departmentKey,
        supervisor: data.supervisorKey,
        contactCenterUserRoleKey: data.contactCenterUserRoleKey,
        userTypeKey: data.userTypeKey,
        deleted: false,
        systemDefault: true,
        updatedUserKey: sessionStorage.getItem('userKey'),
        emailResponseDate: null,
        emailDate: null,
      };

      this.loading = true;
      this.userInviteProcess.updateUserInvitations(updateObjParams).subscribe((response) => {
        this.toast.success('User Invitation details Updated Successfully!', 'Success');
        this.loading = false;
        this.disableEdit = !this.disableEdit;
        this.listChanged.emit(true);
      }, (error) => {
        this.toast.warning(error.message, 'Error');
        this.loading = false;
      });
    }
  }

  /****
  * Add User invitation details
  * @params postData
  * @return array response
  ***/
  addUserInvitation(data) {
    const filter = {
      email: data.email
    };

    this.userInviteProcess.checkEmailInUserInvitations(filter).subscribe(result => {
      if (result.body.length > 0) {
        this.toast.warning('Email already exist', 'Error');
      } else {
        const addObjParams = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          cellPhone: data.cellPhone,
          securityGroupKey: data.securityGroupKey,
          associationKey: data.associationKey,
          departmentKey: data.departmentKey,
          supervisor: data.supervisorKey,
          contactCenterUserRoleKey: data.contactCenterUserRoleKey,
          userTypeKey: data.userTypeKey,
          deleted: false,
          systemDefault: true,
          insertedUserKey: sessionStorage.getItem('userKey'),
          updatedUserKey: sessionStorage.getItem('userKey'),
          emailResponseDate: null,
          emailDate: null,
          insertedTimestamp: new Date().getTime(),
          updatedTimestamp: new Date().getTime(),
          subDomainKey: sessionStorage.getItem('subDomainKey') || null,
        };

        this.loading = true;
        this.userInviteProcess.addUserInvitations(addObjParams).subscribe(res => {
          this.toast.success('User Invitation details Updated Successfully!', 'Success');
          this.loading = false;
          this.disableEdit = !this.disableEdit;
          this.deleteNotLoadedInvitationDetails(res.body[0].email);
          this.listChanged.emit(true);
        }, (error) => {
          this.toast.warning(error.message, 'Error');
          this.loading = false;
        });
      }
    });
  }

  /**
   *
   * Method to remove the bad user invitation data from array
   */
  deleteNotLoadedInvitationDetails(email) {
    const index: number = this.notLoadedRecords.findIndex(item => item.email === email);
    this.notLoadedRecords.splice(index, 1);
  }

  /****
  * Delete User invitation details
  * @params postData
  * @return array response
  ***/
  deleteUserInviteDetails(data: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        if (data.invalid) {
          this.deleteNotLoadedInvitationDetails(data.email);
          this.toast.success('User Invitation details deleted Successfully!', 'Success');
          this.listChanged.emit(true);
        } else {
          const updateObjParams = {
            userInvitationKey: data.userInvitationKey,
            deleted: true,
            updatedUserKey: sessionStorage.getItem('userKey'),
          };
          this.loading = true;
          this.userInviteProcess.updateUserInvitations(updateObjParams).subscribe((response) => {
            this.toast.success('User Invitation details deleted Successfully!', 'Success');
            this.loading = false;
            this.listChanged.emit(true);
          }, (error) => {
            this.toast.warning(error.message, 'Error');
            this.loading = false;
          });
        }
      }
    });
  }

  // edit inline user invite details.
  editInlineUserInvites(index: number) {
    this.tempPricing = { ...{}, ...this.userInvitesList[index] };
    this.departmentKey = this.userInvitesList[index].departmentKey;
    this.userInvitesList[index].editMode = !this.userInvitesList[index].editMode;
    this.disableEdit = !this.disableEdit;
    this.userInvitesList[index].cellPhone = this.userInvitesList[index].cellPhone.split('+1').join('');
    this.getUsersByType();
  }

  // Cancel update user invite details
  cancelUpdateUserInvite(index: number) {
    this.userInvitesList[index].editMode = !this.userInvitesList[index].editMode;
    this.disableEdit = !this.disableEdit;
    this.userInvitesList[index] = this.tempPricing;
    this.isEmailExists = false;
    this.addProviderForm.controls.email.setErrors({ isEmailExists: false });
  }

  // get users by department
  getUsersByDepartment(event) {
    this.departmentKey = event.value;
    this.getSecurutyGroupsByDepartment();
    this.getUsersByType();
  }

  getSecurutyGroupsByDepartment() {
    const securityFilter = {
      platform: 'FleetAssist',
      userTypeKey: this.userTypeKey,
      departmentKey: this.departmentKey,
      deleted: false,
    };

    this.userInviteProcess.getSecurityGroups(securityFilter).subscribe(res => {
      if (res.success && res.body.length > 0) {
        this.securityGroups = res.body;
      }
    });
  }

  /****
  * Check email already exists in user inviations
  * @params postData email
  * @return array response
  ***/
  checkEmailAlreadyExists(event: any, data: any = {}) {
    this.loading = true;
    this.isEmailExists = false;
    const objParams = {
      email: event.target.value,
    };
    this.userInviteProcess.checkEmailInUserInvitations(objParams).subscribe(result => {
      this.loading = false;
      if (result.body.length > 0) {
        if (data && (data.userInvitationKey === result.body[0].userInvitationKey)) {
          this.isEmailExists = false;
        } else {
          this.isEmailExists = true;
        }
        this.addProviderForm.controls.email.setErrors({ isEmailExists: true });
      }
    }, (error) => {
      this.loading = false;
    });
  }

  /****
  * Upload users.
  * @params postData
  * @return array response
  ***/
  uploadFile(event: any) {
    const dialogRef = this.dialogRef.open(UploadFileDialogComponent, {
      hasBackdrop: true,
      position: {
        top: '5%',
      },
      data: { associationKey: this.associationKey, userTypeKey: this.userTypeKey, isCustomer: this.isCustomer, isProvider: this.isProvider },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getInviteDetails();
      this.listChanged.emit(true);
      this.notLoadedRecords = result;
    });
  }

  getUploadedUserInvites() {
    this.listChanged.emit(true);
  }

  downloadHowToUseFile() {
    window.open('/assets/asset-import-files/Data Import Modeler How to Guide.pdf', '_blank');
  }

  downloadModelerFile() {
    if (this.isCustomer) {
      window.location.href = '/assets/asset-import-files/User Data Import Modeler - Customer.csv';
    } else {
      window.location.href = '/assets/asset-import-files/User Data Import Modeler - LO.csv';
    }
  }

  // Search data from table grid.
  search(event: any) {
    this.searchString = event;
  }

  // sort data from the table grid
  onSort({ column, direction }: SortEvent) {
    this.userInvitesList = this.utilityService.sortData(this.headers, column, direction, this.userInvitesList);
  }

  handleError = (control: string, error: string) => {
    return this.addProviderForm.controls[control].hasError(error);
  }

  openEmailDetails(user) {
    this.dialogRef.open(EmailDetailsDialogComponent, {
      width: '70%',
      height: '90%',
      disableClose: true,
      position: {
        top: '2%'
      },
      data: user,
    });
  }

}
