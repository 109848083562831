import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProtocolService {

  constructor(private http: HttpClient) { }

  getProtocolList(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = 0;
    }
    return this.http.post<any>(`${environment.baseUrl}getLicensedOwnerProtocol`, filter);
  }

  getMasterAndCustomersProtocolServices(arrPostData = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getCustomersCustomProtocolServices`, arrPostData);
  }

  getCustomerProtocol(licenesedOwnerKey, options): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getLicensedOwnerOfferings/${licenesedOwnerKey}`);
  }

  addLicensedOwnerProtocol(arrPostData = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerprotocols`, arrPostData);
  }

  editLicensedOwnerProtocol(arrPostData = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerprotocols`, arrPostData);
  }

  editBatchLicensedOwnerProtocol(arrPostData = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerprotocols/batch`, arrPostData);
  }

  getCustomerProtocolsList(arrPostData = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getCustomerProtocols`, arrPostData);
  }

  addCustomerProtocols(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customerprotocols`, postData);
  }

  editCustomerProtocols(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customerprotocols`, postData);
  }

  editBatchCustomerProtocols(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customerprotocols/batch`, postData);
  }

  /***
  * @Desc: get customer ptocol details
  * @params licensedOwnerOfferingKey, customerProtocolKey
  * @returns array result
  */
  getCustomerProtocolsById(postData: any = {}): Observable<any> {
    if (postData.deleted === undefined) {
      postData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customerprotocols/get`, postData);
  }
}
