import { Component, Inject } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PipeTransform, Pipe } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProviderAttachmentUpload } from 'src/app/models/outbound/provider-key-and-upload-purpose.interface';

@Component({
  selector: 'ngx-add-attachments-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    HttpClientModule,
    SharedModule,
  ],
  templateUrl: './add-attachments-dialog.component.html',
  styleUrls: ['./add-attachments-dialog.component.scss']
})
export class AddAttachmentsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AddAttachmentsDialogComponent>,
    private _snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private commonService: CommonService,
    private utilityService: UtilityService,
  ) { }

  loading = false;
  addAttachmentForm: UntypedFormGroup;
  providerAccountKey: string;
  ProviderId: number;
  fileName: any;
  selectedFile: File | null = null;
  imagePreview: string | ArrayBuffer = '../assets/images/placeholder.png'; // | null = null;
  attachmentTypes: any = [];

  ngOnInit() {
    this.addAttachmentForm = this.formBuilder.group({
      attachment: ['', Validators.required],
      purpose: ['', [Validators.required, Validators.minLength(3)]],
      //privateForAccount: [true]
    });
    this.providerAccountKey = this.data.providerAccountKey;
    this.ProviderId = this.data.ProviderId

    this.getSelectAttachmentTypes();
  }

  getSelectAttachmentTypes() {

    const filter = { deleted: false };

    this.commonService.getSelectAttachmentTypes().subscribe(
      res => {
        this.attachmentTypes = res.body;
        console.log(res)
        console.log(this.attachmentTypes)
      }
    )
  }

  closeDialog() {
    this.dialogRef.close();
  }

  preview(files: any, event: any) {
    if (files.length === 0) {
      return false;
    }
    const fileReader = new FileReader();
  }

  onFileSelected(event) {

    const file: File = event.target.files[0];

    if (file) {

      this.fileName = file.name;

      this.selectedFile = file;

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      //formData.append('fileType', 'image');

      formData.append("thumbnail", file);

      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      }

      reader.readAsDataURL(file)

    }

    else {
      this.fileName = null;
      this.imagePreview = null;
    }
  }

  // handleError = (control: string, error: string) => {
  //   return this.addAttachmentForm.controls[control].hasError(error);
  // }
  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }

  addAttachment() {
    if (this.addAttachmentForm.invalid) {
      if (this.addAttachmentForm.controls.purpose.invalid && this.addAttachmentForm.controls.attachment.valid) {
        this.openSnackBar('Please Select Purpose of Attachment.')
      }
      else if (this.addAttachmentForm.controls.attachment.invalid && this.addAttachmentForm.controls.purpose.valid) {
        this.openSnackBar('Please Select File Attachment.')
      }
      else if (this.addAttachmentForm.controls.attachment.invalid && this.addAttachmentForm.controls.purpose.invalid) {
        this.openSnackBar('Please Select Purpose of Attachment and File Attachment.')
      }
      return;
    }
    else {
      console.log(this.addAttachmentForm.value);
      const providerAttachmentUpload: ProviderAttachmentUpload = {
        providerId: this.ProviderId,
        purposeCode: this.addAttachmentForm.value.purpose
      };
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("providerAttachmentUpload", JSON.stringify(providerAttachmentUpload))
      this.loading = true;
      if (this.ProviderId) {
        this.commonService.InsertProviderAttachmentsForServicePlus(formData).subscribe({
          next: res => console.log(res),
          error: (e) => {console.log(e), this.openSnackBar('Could not upload file.')},
          complete: () => {
             this.loading = false;
             this.openSnackBar('Successfully uploaded the file.')
             this.dialogRef.close();
          }}
        )
          // if (res.status) {
          //   this.toastrService.success('', res.message);
          //   this.onClose(true);
          // } else {
          //   this.toastrService.warning('', res.message);
          // }
        // }, () => {
        //   this.loading = false;
        //   // this.toastrService.warning('', 'Failed to upload.');
        // });
      } else {
        this.loading = false;
        // this.toastrService.success('', 'Attachment added');
      }
    }
  }



}
