<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6 class="modal-title">
        <span *ngIf="!isEventProtocol">
            <span *ngIf="isEdit">Edit</span>
            <span *ngIf="!isEdit">Add</span> Offering Questions
        </span>
        <span *ngIf="isEventProtocol">
            <span *ngIf="isEdit">Edit</span>
            <span *ngIf="!isEdit">Add</span> Questions
        </span>
    </h6>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <app-add-question [questionKey]="dynamicFormKey" (newDynamicFormKey)="onDynamicFormCreated($event)"
                (newDynamicFormKey)="onDynamicFormUpdated($event)" [isEmitJson]="isEmitJson"
                (newDynamicJson)="newDynamicJson($event)">
            </app-add-question>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm mr-1" (click)="closeDialog()">Close</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="saveQuestions()">Save Questions</button>
</div>