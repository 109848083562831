import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { UtilityService } from 'src/app/services/utility.service';
import { ProfileService } from 'src/app/services/profile.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { patterns } from 'src/assets/patterns';
import { environment } from 'src/environments/environment';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'ngx-add-edit-employee-dialogs',
  templateUrl: './add-edit-employee-dialogs.component.html',
  styleUrls: ['./add-edit-employee-dialogs.component.scss'],
})
export class AddEditEmployeeDialogsComponent implements OnInit {

  userDetails: any = {
    'firstName': '',
    'officePhone': '',
    'cellPhone': '',
    'email': '',
    'supervisor': '',
    'city': '',
    'userStatus': '',
  };

  profileDetailsForm: UntypedFormGroup;
  buttonText: string = 'Add Employee';
  loading: boolean;
  userKey: String;
  profileDetails: any;
  userTypeKey: any;
  disableFlag: boolean = false;
  providerKey: string;
  defaultLocationKey: string;
  saveButtonText: string = 'Add Employee';
  userStatusDetails: any;
  supervisorDetails: any;
  isUserProvider: boolean = false;
  isEdit: boolean = true;
  securityGroup: string;
  isEmailAvailable: boolean = false;
  selectedIndex: number = 0;
  fileData: File;
  imagePath: any;
  imgURL: string | ArrayBuffer = '../assets/images/placeholder.png';
  isDisableMyProfile: boolean = false;
  isProvider = false;

  @Input() data;
  @Output() saveCompleted = new EventEmitter<any>();

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: NbToastrService,
    private utilityService: UtilityService,
    private profileService: ProfileService,
    private http: HttpClient,
    public dialog: MatDialogRef<AddEditEmployeeDialogsComponent>,
    private imageCompress: NgxImageCompressService,
  ) { }

  ngOnInit() {
    this.buttonText = this.data.value;
    if (this.data.userKey === undefined || this.data.userKey === null) {
      this.userKey = sessionStorage.getItem('userKey');
      this.isDisableMyProfile = true;
    } else {
      this.userKey = this.data.userKey;
    }

    this.providerKey = sessionStorage.getItem('providerKey');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');

    if (this.data.locationKey && this.data.locationKey !== 'null') {
      this.providerKey = this.data.locationKey;
    } else if (this.defaultLocationKey && this.defaultLocationKey !== 'null') {
      this.providerKey = this.defaultLocationKey;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
      this.isProvider = true;
    }
    if (this.isProvider) {
      const img = sessionStorage.getItem('loginUserProfile');

      if (img && img !== 'null') {
        this.imgURL = atob(sessionStorage.getItem('loginUserProfile'));
      }
    }

    this.isUserProvider = false;
    this.profileDetailsForm = this.formBuilder.group({
      firstName: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.textPattern)]],
      lastName: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.textPattern)]],
      email: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.emailPattern)]],
      officePhone: ['', Validators.pattern(patterns.mobnumPattern)],
      cellPhone: ['', Validators.pattern(patterns.mobnumPattern)],
      supervisor: [''],
      password: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.passwordPattern)]],
      confirmPassword: ['', [Validators.required]],
      providerKey: [''],
      jobTitle: [''],
      userStatus: [{ value: 'Active', disabled: this.disableFlag }],
      okToTextMsg: [true],
      // profilePictureURL: [''],
    });

    if (this.buttonText === 'Edit Employee') {
      this.isEdit = false;
      this.saveButtonText = 'Save';
      this.getEmployeeDetails();
    } else {
      this.isEdit = true;
      this.saveButtonText = 'Add Employee';
    }

    this.getUserStatus();
    this.getUserSupervisors();
  }

  // get employee list
  getEmployeeDetails() {

    this.loading = true;
    const data = { userKey: this.userKey, deleted: false };
    this.userService.getUsersDetails(data).subscribe(result => {
      this.profileDetails = result.body[0];
      this.imgURL = result.body[0].profilePictureURL ? atob(result.body[0].profilePictureURL) : this.imgURL;
      this.userDetails = result.body[0];
      this.userTypeKey = this.profileDetails.userTypeKey;
      this.isUserProvider = (this.userTypeKey === 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4') ? true : false;

      this.profileDetailsForm = this.formBuilder.group({
        firstName: this.profileDetails.firstName,
        lastName: this.profileDetails.lastName,
        email: this.profileDetails.email,
        officePhone: '',
        cellPhone: '',
        supervisor: this.profileDetails.supervisor,
        password: this.profileDetails.password,
        confirmPassword: this.profileDetails.password,
        providerKey: this.providerKey,
        jobTitle: this.profileDetails.title,
        userStatus: this.profileDetails.userStatusKey,
        okToTextMsg: this.profileDetails.okToTextMsg,
      });

      this.loading = false;

      if (this.data.UserSecurityGroup === 'Provider Billing') {
        this.profileDetailsForm.get('userStatus').setValue('Active');
        this.profileDetailsForm.get('userStatus').disable();
      }

      setTimeout(() => {
        this.profileDetailsForm.controls.officePhone.setValue(this.profileDetails.officePhone);
        this.profileDetailsForm.controls.cellPhone.setValue(this.profileDetails.cellPhone);
      }, 100);
    }, (error) => {
      console.info('error', error);
      this.loading = false;
    });
  }

  // add employe details
  addEditEmployeeDetails() {
    if (this.profileDetailsForm.invalid || this.atleasetOneRequired()) {
      this.profileDetailsForm.markAllAsTouched();
      return false;
    }

    this.loading = true;
    const userData = {
      firstName: this.profileDetailsForm.controls.firstName.value,
      lastName: this.profileDetailsForm.controls.lastName.value,
      email: this.profileDetailsForm.controls.email.value,
      officePhone: this.profileDetailsForm.controls.officePhone.value,
      cellPhone: this.profileDetailsForm.controls.cellPhone.value,
      supervisor: this.profileDetailsForm.controls.supervisor.value,
      password: this.profileDetailsForm.controls.password.value,
      title: this.profileDetailsForm.controls.jobTitle.value,
      userStatusKey: this.profileDetailsForm.controls.userStatus.value,
      okToTextMsg: this.profileDetailsForm.controls.okToTextMsg.value,
    };

    if (this.buttonText === 'Edit Employee') {
      const data = Object.assign(userData, {
        'updatedUserKey': sessionStorage.getItem('userKey'),
        'userKey': this.userKey, 'deleted': false,
      });

      this.userService.updateUserDetails(data).subscribe((result: any) => {
        this.loading = false;
        if (this.userKey === sessionStorage.getItem('userKey')) {
          this.utilityService.setUserName(this.profileDetailsForm.controls.firstName.value.concat(' ',
            this.profileDetailsForm.controls.lastName.value));
        }
        this.toastrService.success('User details updated successfully!.', 'Success');
        const objData = {
          result: true,
          userKey: this.userKey,
        };
        this.saveCompleted.emit(objData);
      }, (error) => {
        this.loading = false;
        console.info(error);
        this.toastrService.danger('Oops! Something went wrong.', 'Danger');
      });
    } else {
      const insertData = {
        insertedUserKey: sessionStorage.getItem('userKey'),
        associationKey: this.providerKey,
        address: '',
        city: '',
        contactPriority: '',
        deleted: false,
        department: '',
        stateKey: '',
        updatedUserKey: '',
        userKey: '',
        userTypeKey: 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4', // Provider
      };

      // code for: check existing email address
      this.loading = true;
      const objParams = { emailAddress: userData.email };
      const licenseOwnerInviteReq = this.userService.getLicensedOwnerProviderUsersDetails(objParams);
      const usersReq = this.userService.getUsersDetails({ email: userData.email, deleted: false });

      // used forkjoin for check multiple tables
      forkJoin([licenseOwnerInviteReq, usersReq]).subscribe(result => {

        // if following condition is true then the new employee will be added 
        // otherwise will return error as < Email id is already exist >.
        if (result[0].body.length === 0 && result[1].body.length === 0) {
          this.isEmailAvailable = false;
          const data = Object.assign(userData, insertData);
          this.userService.addUserDetails(data).subscribe((result: any) => {
            this.addUserSecurityGroup(result.body[0].userKey, this.securityGroup);
            this.loading = false;
            this.toastrService.success('User details added successfully!.', 'Success');
            const objData = {
              result: true,
              userKey: result.body[0].userKey,
            };
            this.saveCompleted.emit(objData);
          }, (error) => {
            console.info('error', error);
            this.loading = false;
            this.toastrService.danger('Oops! Something went wrong.', 'Danger');
          });
        } else {
          // this.isEmailAvailable = true;
          this.loading = false;
          return false;
        }
      }, (error) => {
        this.loading = false;
        this.toastrService.danger('Oops! Something went wrong.', 'Danger');
      });
    }
  }

  preview(files: any, event: any) {
    if (files.length === 0) {
      return false;
    }
    const fileReader = new FileReader();
    fileReader.onload = (res: any) => {
      this.imageCompress.compressFile(res.target.result, -1, 50, 50).then(result => {
        this.utilityService.urltoFile(result, event.target.files[0]['name'], files[0].type).then((file) => {
          this.fileData = file;
          const formData = new FormData();
          formData.append('file', this.fileData);
          formData.append('fileType', 'image');

          const mimeType = files[0].type;
          if (mimeType.match(/image\/*/) == null) {
            this.toastrService.danger('Only image formats are supported.');
            return false;
          }
          const size = files[0].size;
          if (Math.round(size / 1024) > 5120) {
            this.toastrService.danger('Image size should not exceed 5MB.');
            return false;
          }

          const reader = new FileReader();
          this.imagePath = files;

          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {
            this.profileService.updateProfilePicture(formData).subscribe(resFileDetail => {
              if (resFileDetail['status']) {
                this.toastrService.success('Profile Uploaded.!', 'Success');
                this.loading = false;
              } else {
                this.toastrService.danger('Failed to upload profile.!', 'Error');
              }
              // this.profileDetailsForm.controls.profilePictureURL.setValue(resFileDetail['url']);
              const data = {
                'userKey': this.userKey,
                'profilePictureURL': resFileDetail['url'],
              };

              this.imgURL = resFileDetail['url'];

              this.loading = true;
              this.http.put<any>(`${environment.apiUrl}systemsecurities/users`, data).subscribe((response: any) => {
                if (this.userKey === sessionStorage.getItem('userKey')) {
                  sessionStorage.setItem('loginUserProfile', btoa(resFileDetail['url']));
                  this.utilityService.setProfilePicture(resFileDetail['url']);
                }
                this.loading = false;
              }, (error) => {
                this.loading = false;
                this.toastrService.danger(error.message, 'Error');
              });
            }, (error) => {
              this.loading = false;
              this.toastrService.danger('Failed to update profile picture.!', 'Error');
            });
          }
        }, error => {
          this.toastrService.danger('Failed to update profile picture.!', 'Error');
          console.log('Failed to update profile picture.!', error);
        });
      });
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  // get user status
  getUserStatus() {
    const data = {
      deleted: false,
    };
    this.userService.getUserStatus(data).subscribe(response => {
      this.userStatusDetails = response.body;
      if (this.data.UserSecurityGroup === 'Provider Billing') {
        this.profileDetailsForm.get('userStatus').setValue('Active');
        this.profileDetailsForm.get('userStatus').disable();
      }
      if (this.buttonText === 'Add Employee') {
        this.profileDetailsForm.get('userStatus').setValue('Active');
      }
    }, (error) => {
      console.info(error);
    });
  }

  // get user supervisors
  getUserSupervisors() {
    const supervisorFilter = {
      associationKey: this.providerKey,
    };

    this.loading = true;
    this.userService.getUserDetailsByAssociationKey(supervisorFilter).subscribe(response => {
      this.loading = false;
      this.supervisorDetails = response.body || [];
    }, (error) => {
      console.info(error);
      this.loading = false;
    });
  }

  addUserSecurityGroup(userKey, securityGroupKey) {
    const data = {
      deleted: false,
      systemDefault: true,
      securityGroupKey: securityGroupKey,
      userKey: userKey,
    };
    this.loading = true;
    this.userService.addUserSecurityGroup(data).subscribe((res: any) => {
    }, (error) => {
      this.loading = false;
      console.info(error);
    });
  }

  atleasetOneRequired() {
    const form = this.profileDetailsForm.value;
    if (form.officePhone || form.cellPhone) {
      return false;
    } else {
      return true;
    }
  }

}
