import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { BreakdownProvider } from '../../models/breakdown-providers.model';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'ngx-match-application-confirm-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
  ],
  templateUrl: './match-application-confirm-dialog.component.html',
  styleUrls: ['./match-application-confirm-dialog.component.scss']
})

export class MatchApplicationConfirmDialogComponent {

  constructor(
    private providerAdminService : ProviderAdminService,
    public dialogRef: MatDialogRef<MatchApplicationConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BreakdownProvider,
    private _snackBar: MatSnackBar,
  ) { }

 // isParentLocation: boolean;
  result: boolean = false;

  onNoClick(): void {
    this.openSnackBar('Match has been canceled.');
    this.dialogRef.close();
  }

  approveProviderApplication(){
    //this.openSnackBar('Provider has been approved.');
    //console.log(this.data.providerPK);
   // console.log(data.isParentLocation)
    this.dialogRef.close(true);
  }

  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }

}
