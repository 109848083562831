<app-loader [loading]='loading'></app-loader>
<div class="modal-header">
    <h6>{{heading}}</h6>
    <button type="button" class="close" (click)="close()" nbTooltip="close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="addLimitsForm">
        <div class="row">
            <div *ngIf="!isCustomerUser" class="col-12 col-md-3">
                <mat-form-field>
                    <mat-select placeholder="Hierarchy Tier" formControlName="customerTierStructureKey" required>
                        <mat-option disabled>Select Hierarchy Tier</mat-option>
                        <mat-option *ngFor="let hierarchyTier of hierarchyTiers | orderBy : 'tierLevel'"
                            [value]="hierarchyTier.customerTierStructureKey">
                            {{hierarchyTier.tierLevel + '-' + hierarchyTier.tierName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorHandling('customerTierStructureKey', 'required')">Please select Hierarchy
                        Tier
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="!isCustomerUser" class="col-12 col-md-3">
                <mat-form-field>
                    <mat-select placeholder="Security Groups" formControlName="securityGroupKey" required>
                        <mat-option disabled>Select Security Groups</mat-option>
                        <mat-option *ngFor="let securityGroup of securityGroups  | orderBy: 'description'"
                            [value]="securityGroup.securityGroupKey">
                            {{securityGroup.description}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorHandling('securityGroupKey', 'required')">Please select Security Group
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12" [ngClass]="{
                'col-md-3': !isCustomerUser,
                'col-md-4': isCustomerUser
              }">
                <mat-form-field>
                    <mat-select placeholder="Operating Schedule" formControlName="operatingScheduleKey" required>
                        <mat-option disabled>Select Operating Schedule</mat-option>
                        <mat-option *ngFor="let operatingSchedule of OperatingSchedules"
                            [value]="operatingSchedule.operatingScheduleKey">{{operatingSchedule.operatingScheduleKey}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorHandling('operatingScheduleKey', 'required')">Please select Operating
                        Schedule.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12" class="col-12 col-md-3" [ngClass]="{
                'col-md-3': !isCustomerUser,
                'col-md-4': isCustomerUser
              }">
                <mat-form-field>
                    <input matInput type="number" min="0" placeholder="Min $ Limit"
                        (change)="removeRequired('threshold')" formControlName="minimumThreshold" required />
                    <span matPrefix *ngIf="this.addLimitsForm.controls.minimumThreshold.value != ''">$&nbsp;</span>
                    <mat-error *ngIf="errorHandling('minimumThreshold', 'required')">
                        Minimum Threshold is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12" class="col-12 col-md-3" [ngClass]="{
                'col-md-3': !isCustomerUser,
                'col-md-4': isCustomerUser
              }">
                <mat-form-field>
                    <input matInput type="number" min="0" placeholder="Max $ Limit"
                        formControlName="maximumThreshold" />
                    <span matPrefix *ngIf="this.addLimitsForm.controls.maximumThreshold.value != ''">$&nbsp;</span>
                </mat-form-field>
            </div>
            <div *ngIf="!approvalDetails.isOverallLimit" class="col-12" [ngClass]="{
                'col-md-3': !isCustomerUser,
                'col-md-4': isCustomerUser
              }">
                <mat-form-field>
                    <input matInput placeholder="Quantity Minimum" (change)="removeRequired('quantity')"
                        formControlName="quantityMinimum" required />
                    <mat-error *ngIf="errorHandling('quantityMinimum', 'required')">
                        Quantity Minimum is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="!approvalDetails.isOverallLimit" class="col-12" [ngClass]="{
                'col-md-3': !isCustomerUser,
                'col-md-4': isCustomerUser
              }">
                <mat-form-field>
                    <input matInput placeholder="Quantity Maximum" formControlName="quantityMaximum" />
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm pull-right" (click)="saveLimits()">Save</button>
</div>