<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-body>
        <div class="w3-panel">
            <p>
                Based on your services, the following rates are required. Please understand that
                <span class="font-weight-bold">{{accountName}}</span>
                is a full service maintenance provider and events will be managed based on priority. Please price
                accordingly. Emergency rates are a <span class="font-weight-bold">go now</span>, Scheduled are a <span
                    class="font-weight-bold">do later</span>. Based on the rates provided, approval or
                rejection of these rates will be based on market conditions, competition and client expectations.
            </p>
        </div>

        <div class="bg-light " *ngIf="licensedOwnerKey !== '' && lineItemTypeGroupData.length > 0">
            <div class="col-md-12"><strong>How will you charge for the following items?</strong></div>
            <div class="row charge-container">
                <ng-container *ngFor="let data of lineItemTypeGroups;">
                    <div class="col-md-3 custom-border-right">
                        <p class="text-center">{{data.serviceName}}</p>
                        <div class="row mx-0 px-0 classLineItem">
                            <ng-container *ngFor="let lineItem of data.children;">
                                <div class="line-item-button">
                                    <div class="btn btn-secondary btn-sm btn-block"
                                        [ngClass]="{'btn-orange': lineItem.isSelected}"
                                        (click)="selectRateTypeGroup(data.serviceId, lineItem)">
                                        {{lineItem.lineItemTypeGroupName}}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- <div class="col-md-12 text-right">
                <button class="btn btn-orange float-right btn-sm btn-block w-15 m-2" (click)="addToRateList() ">
                    Add To List Below
                </button>
            </div> -->
        </div>

        <div class="table-responsive vendor-rates">
            <div class="row no-gutters">
                <div class="col-md-3 offset-md-9 text-right">
                </div>
            </div>
            <div class="row noMargin mt-2">
                <div class="col-md-3 form-group has-search">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search"
                        (input)="onInput($event)">
                </div>
                <div class="col-md-2 offset-md-2">
                    <button class="btn cursor-default rounded-circle btn-md">
                        <i class="fa fa-check-circle text-success"></i>
                    </button>
                    <span class="small"><small>Service Approved</small></span>
                </div>
                <div class="col-md-2">
                    <button class="btn cursor-default rounded-circle btn-md">
                        <i class="fa fa-times-circle text-danger"></i>
                    </button>
                    <span class="small"><small>Service not Approved</small></span>
                </div>

                <div class="col-md-1 offset-md-1">
                    <label><strong>{{strPaymentTermKey}}</strong></label>
                </div>
            </div>
            <table class="table table-bordered rates text-center" [ngClass]="{'rates-parent': getParentRecord}">
                <thead class="text-center">
                    <tr>
                        <th sortable="lineItemKey" (sort)="onSort($event)" direction="asc" class="v-a-m">
                            Line Item
                        </th>
                        <th sortable="lineItemPricingTypeKey" (sort)="onSort($event)" direction="asc" class="v-a-m">
                            Rate Type
                        </th>
                        <th>
                            Emergency Rate
                            <button class="btn cursor-default rounded-circle btn-md"
                                nbTooltip="Available for a dispatch scheduled hours or days in advance and at a time better suited to meet your operational needs">
                                <i class="fa fa-info-circle text-muted"></i>
                            </button>
                        </th>
                        <th *ngIf="afterHoursRatesAllowed" class="v-a-m">
                            Emergency After Hour Rates
                        </th>
                        <th>
                            Scheduled Rate
                            <button class="btn cursor-default rounded-circle btn-md"
                                nbTooltip="Knowing a schedule of services for a group of vehicles that need to be performed within a window of time e.g. within the week or month">
                                <i class="fa fa-info-circle text-muted"></i>
                            </button>
                        </th>
                        <th class="v-a-m" *ngIf="isDisallowed">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let providerAccountPricing of arrProviderAccountPricing | filter : searchText; let i = index;">
                        <td class="text-left">{{providerAccountPricing.lineItemKey}}</td>
                        <td class="text-center">
                            <label
                                *ngIf="!(providerAccountPricing.editMode) || (providerAccountPricing.emergencyRateApproved == 1 && isValidRateSubsription)">
                                {{providerAccountPricing.lineItemPricingTypeKey}} </label>
                            <span
                                *ngIf="providerAccountPricing.editMode && (providerAccountPricing.emergencyRateApproved != 1 || !isValidRateSubsription)">
                                <span *ngIf="isLicensedOwnerKey">
                                    {{providerAccountPricing.lineItemPricingTypeKey}}
                                </span>
                                <span *ngIf="!isLicensedOwnerKey">
                                    <mat-form-field>
                                        <mat-select placeholder="Rate Type"
                                            [(ngModel)]="providerAccountPricing.lineItemPricingTypeKey">
                                            <mat-option *ngFor="let lineItem of arrLineItemPricingTypes"
                                                [value]="lineItem.lineItemPricingTypeKey">
                                                {{lineItem.lineItemPricingTypeKey}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </span>
                            </span>
                        </td>
                        <td>
                            <label
                                *ngIf="!(providerAccountPricing.editMode) || (providerAccountPricing.emergencyRateApproved == 1 && isValidRateSubsription)">
                                <span *ngIf="providerAccountPricing.emergencyRate > 0">$</span>
                                {{providerAccountPricing.emergencyRate}} </label>
                            <mat-form-field
                                *ngIf="providerAccountPricing.editMode && (providerAccountPricing.emergencyRateApproved != 1 || !isValidRateSubsription)">
                                <input type="text" pattern="^(\d+\.)?\d+$"
                                    (change)="setAfterHoursRates(providerAccountPricing)"
                                    (keyup)="isDecimal(providerAccountPricing.emergencyRate)"
                                    onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                                    matInput [(ngModel)]="providerAccountPricing.emergencyRate">
                                <span matPrefix *ngIf="providerAccountPricing.emergencyRate != null">$&nbsp;</span>
                            </mat-form-field>

                            <button
                                *ngIf="(providerAccountPricing.emergencyRateApproved == 1 || providerAccountPricing.emergencyRateApproved == 0) && isValidRateSubsription"
                                class="btn cursor-default rounded-circle btn-md floatR">
                                <i [ngClass]="{   
                                        'fa':true,                                  
                                        'fa-check-circle text-success': providerAccountPricing.emergencyRateApproved == 1,                                    
                                        'fa-times-circle text-danger': providerAccountPricing.emergencyRateApproved == 0
                                        }">
                                </i>
                            </button>
                            <div [ngClass]="{
                                'mt-3': providerAccountPricing.emergencyRateApproved == 1
                                }" *ngIf="(providerAccountPricing.editMode)">
                                <button class="btn btn-sm btn-warning"
                                    *ngIf="emergencyRateApproved && emergencyRateApproved.rateApproved === false && emergencyRateApproved.deleted === false"
                                    (click)="openAccountPricingException(emergencyRateApproved)" type="button">
                                    Custom Pending
                                </button>
                                <button class="btn btn-sm btn-danger"
                                    *ngIf="emergencyRateApproved && emergencyRateApproved.rateApproved === false && emergencyRateApproved.deleted === true"
                                    (click)="openAccountPricingException(emergencyRateApproved)" type="button">
                                    Custom Denied
                                </button>
                                <button class="btn btn-sm btn-success"
                                    *ngIf="emergencyRateApproved && emergencyRateApproved.rateApproved === true && emergencyRateApproved.deleted === false"
                                    (click)="openAccountPricingException(emergencyRateApproved)" type="button">
                                    Custom Approved
                                </button>
                            </div>
                        </td>
                        <td *ngIf="afterHoursRatesAllowed">
                            <label
                                *ngIf="!(providerAccountPricing.editMode) || (providerAccountPricing.afterHoursRateApproved == 1 && isValidRateSubsription)">
                                <span *ngIf="providerAccountPricing.afterHoursRate > 0">$</span>
                                {{providerAccountPricing.afterHoursRate}} </label>
                            <mat-form-field
                                *ngIf="providerAccountPricing.editMode && (providerAccountPricing.afterHoursRateApproved != 1 || !isValidRateSubsription)">
                                <input type="text" pattern="^(\d+\.)?\d+$"
                                    (keyup)="isDecimal(providerAccountPricing.afterHoursRate)"
                                    onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                                    matInput [(ngModel)]="providerAccountPricing.afterHoursRate">
                                <span matPrefix *ngIf="providerAccountPricing.afterHoursRate != null">$&nbsp;</span>
                            </mat-form-field>
                            <button
                                *ngIf="(providerAccountPricing.afterHoursRateApproved == 1 || providerAccountPricing.afterHoursRateApproved == 0) && isValidRateSubsription"
                                class="btn cursor-default rounded-circle btn-md floatR">
                                <i [ngClass]="{
                                            'fa':true,
                                            'fa-check-circle text-success': providerAccountPricing.afterHoursRateApproved == 1,
                                            'fa-times-circle text-danger': providerAccountPricing.afterHoursRateApproved == 0
                                            }">
                                </i>
                            </button>
                            <div [ngClass]="{
                                'mt-3': providerAccountPricing.afterHoursRateApproved == 1
                                }" *ngIf="(providerAccountPricing.editMode)">
                                <button class="btn btn-sm btn-warning"
                                    *ngIf="emergencyAfterHoursRateApproved && emergencyAfterHoursRateApproved.rateApproved === false && emergencyAfterHoursRateApproved.deleted === false"
                                    (click)="openAccountPricingException(emergencyAfterHoursRateApproved)"
                                    type="button">
                                    Custom Pending
                                </button>
                                <button class="btn btn-sm btn-danger"
                                    *ngIf="emergencyAfterHoursRateApproved && emergencyAfterHoursRateApproved.rateApproved === false && emergencyAfterHoursRateApproved.deleted === true"
                                    (click)="openAccountPricingException(emergencyAfterHoursRateApproved)"
                                    type="button">
                                    Custom Denied
                                </button>
                                <button class="btn btn-sm btn-success"
                                    *ngIf="emergencyAfterHoursRateApproved && emergencyAfterHoursRateApproved.rateApproved === true && emergencyAfterHoursRateApproved.deleted === false"
                                    (click)="openAccountPricingException(emergencyAfterHoursRateApproved)"
                                    type="button">
                                    Custom Approved
                                </button>
                            </div>
                        </td>
                        <td>
                            <label
                                *ngIf="!(providerAccountPricing.editMode) || (providerAccountPricing.scheduledRateApproved == 1 && isValidRateSubsription)">
                                <span *ngIf="providerAccountPricing.scheduledRate > 0">$</span>
                                {{providerAccountPricing.scheduledRate}} </label>
                            <mat-form-field
                                *ngIf="providerAccountPricing.editMode && (providerAccountPricing.scheduledRateApproved != 1 || !isValidRateSubsription)">
                                <input type="text" pattern="^(\d+\.)?\d+$"
                                    (keyup)="isDecimal(providerAccountPricing.scheduledRate)"
                                    onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                                    matInput [(ngModel)]="providerAccountPricing.scheduledRate">
                                <span matPrefix *ngIf="providerAccountPricing.scheduledRate != null">$&nbsp;</span>
                            </mat-form-field>
                            <button
                                *ngIf="(providerAccountPricing.scheduledRateApproved == 1 || providerAccountPricing.scheduledRateApproved == 0) && isValidRateSubsription"
                                class="btn cursor-default rounded-circle btn-md floatR">
                                <i [ngClass]="{
                                        'fa':true,
                                        'fa-check-circle text-success': providerAccountPricing.scheduledRateApproved == 1,
                                        'fa-times-circle text-danger': providerAccountPricing.scheduledRateApproved == 0
                                        }">
                                </i>
                            </button>
                            <div [ngClass]="{
                                'mt-3': providerAccountPricing.scheduledRateApproved == 1
                                }" *ngIf="(providerAccountPricing.editMode)">
                                <button class="btn btn-sm btn-warning"
                                    *ngIf="scheduledRateApproved && scheduledRateApproved.rateApproved === false && scheduledRateApproved.deleted === false"
                                    (click)="openAccountPricingException(scheduledRateApproved)" type="button">
                                    Custom Pending
                                </button>
                                <button class="btn btn-sm btn-danger"
                                    *ngIf="scheduledRateApproved && scheduledRateApproved.rateApproved === false && scheduledRateApproved.deleted === true"
                                    (click)="openAccountPricingException(scheduledRateApproved)" type="button">
                                    Custom Denied
                                </button>
                                <button class="btn btn-sm btn-success"
                                    *ngIf="scheduledRateApproved && scheduledRateApproved.rateApproved === true && scheduledRateApproved.deleted === false"
                                    (click)="openAccountPricingException(scheduledRateApproved)" type="button">
                                    Custom Approved
                                </button>
                            </div>
                        </td>
                        <td *ngIf="isDisallowed">
                            <ng-container *ngIf="(providerAccountPricing.afterHoursRateApproved != 1 && afterHoursRatesAllowed) || 
                            (providerAccountPricing.emergencyRateApproved != 1) || 
                            (providerAccountPricing.scheduledRateApproved != 1) || !isValidRateSubsription">

                                <button *ngIf="!(providerAccountPricing.editMode)" class="btn btn-default btnAnimation"
                                    (click)="editRates(providerAccountPricing)" nbTooltip="Edit"
                                    [disabled]="disableEdit">
                                    <i class="fas fa-pencil"></i>
                                </button>
                            </ng-container>

                            <button *ngIf="providerAccountPricing.editMode" [disabled]="InvalidRate"
                                class="btn btn-default btnAnimation" (click)="saveRates(providerAccountPricing)"
                                nbTooltip="Save">
                                <i class="fas fa-check"></i>
                            </button>
                            <button *ngIf="providerAccountPricing.editMode" class="btn btn-default btnAnimation"
                                (click)="cancelRates(providerAccountPricing)" nbTooltip="Cancel">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="arrProviderAccountPricing.length == 0" class="active">
                        <td *ngIf="!afterHoursRatesAllowed" colspan="5"> Line items not found</td>
                        <td *ngIf="afterHoursRatesAllowed" colspan="6">Line items not found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </nb-card-body>
</nb-card>

<div class="text-right">
    <button mat-button *ngIf="showBack" class="btn btn-orange btn-sm p-2 mr-1">Back</button>
    <button mat-button *ngIf="showNext" class="btn btn-orange btn-sm p-2 ml-1">Next</button>
</div>