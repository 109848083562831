import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  constructor(private http: HttpClient) { }

  public getUsers(filter): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/users/get`, filter);
  }

  getUserSecurityGroups(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/usersecuritygroups/get`, data);
  }

  createCognitoUser(data: any): Observable<any> {
    return this.http.post<any>(`${environment.cognitoSignupUrl}`, data);
  }

  getUserExperience(filter): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/userexperiances/get`, filter);
  }

  getProviderDetails(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getNationalAdvisoryGroup`);
  }

}