import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SystemSecuruityMenuService } from '../../services/system-securuity-menu.service';
import { AddSecurityMenuDialogComponent } from '../../dialogs/add-security-menu-dialog/add-security-menu-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UtilityService } from '../../services/utility.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { SortableHeaderDirective, SortEvent } from '../../share-module/directive/sortable.directive';
import { ConfirmDeleteComponent } from '../../commons/confirm-delete/confirm-delete.component';
import { SecurityGroupsListComponent } from './security-groups-list/security-groups-list.component';
import { ViewChild } from '@angular/core';

interface MenuNode {
  name?: string;
  children?: MenuNode[];
  key: string;
  color?: string;
  bordercolor?: string;
  isExpanded?: boolean;
  parentKey: string;
  displayOrder: number;
  ui: string;
}

let treedata: MenuNode[] = [];
const arrcolor: string[] = [
  '#25ace0',
  '#243e8f',
  '#a7cd45',
  '#7441a2',
  '#3D70FF'
];

@Component({
  selector: 'ngx-system-security-menu-items',
  templateUrl: './system-security-menu-items.component.html',
  styleUrls: ['./system-security-menu-items.component.scss']
})

export class SystemSecurityMenuItemsComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @ViewChild(SecurityGroupsListComponent) securityGroupsList: SecurityGroupsListComponent;
  
  treeControl = new NestedTreeControl<MenuNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<MenuNode>();
  servicePlustreeControl = new NestedTreeControl<MenuNode>(node => node.children);
  servicePlusdataSource = new MatTreeNestedDataSource<MenuNode>();
  resultMenu: any[];
  loading: boolean;
  responseData: any;
  servicePlusResultMenu: any = [];
  fleetAssistResultMenu: any = [];
  dataIndex = 0;
  displayOrder: number;
  parentId: string;
  selectedIndex = 0;

  //Admin
  adminDataSource = new MatTreeNestedDataSource<MenuNode>();
  adminTreeControl = new NestedTreeControl<MenuNode>(node => node.children);
  adminResultMenu: any = [];

  constructor(
    public dialog: MatDialog,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private utilityService: UtilityService,
    private systemSecuruityMenuService: SystemSecuruityMenuService,
  ) { }

  setMenus() {
    if (this.resultMenu.length > 0) {
      const fleetAssistSystem = [];
      const ServicePlusSystem = [];
      const admin = [];

      this.resultMenu.forEach(obj => {
        if (obj.ui === 'FleetAssist') {
          fleetAssistSystem.push(obj);
        } else if (obj.ui === 'ServicePlus') {
          ServicePlusSystem.push(obj);
        } else {
          admin.push(obj);
        }
      });

      this.dataIndex = 0;
      treedata = [];
      this.convertJson2Tree(fleetAssistSystem, 0);
      for (const data of treedata) {
        this.fleetAssistResultMenu[0] = treedata[this.dataIndex];
        this.dataIndex++;
      }

      this.dataSource.data = this.fleetAssistResultMenu;
      this.treeControl.dataNodes = treedata;
      this.treeControl.expand(treedata[2]);

      this.dataIndex = 0;
      treedata = [];
      this.convertJson2Tree(ServicePlusSystem, 0);
      for (const data of treedata) {
        this.servicePlusResultMenu[0] = treedata[this.dataIndex];
        this.dataIndex++;
      }

      let treeSplit = treedata.find(x => x.name === "ServicePlus System");
      const menu = treeSplit.children.filter(obj => obj.name != "Admin");
      this.servicePlusResultMenu[0].children = menu;

      this.servicePlusdataSource.data = this.servicePlusResultMenu;
      this.servicePlustreeControl.dataNodes = treedata;
      this.servicePlustreeControl.expand(treedata[1]);

      this.dataIndex = 0;
      treedata = [];
      this.convertJson2Tree(admin, 0);
      for (const data of treedata) {
        this.adminResultMenu[0] = treedata[this.dataIndex];
        this.dataIndex++;
      }

      this.adminDataSource.data = this.adminResultMenu;
      this.adminTreeControl.dataNodes = this.adminResultMenu;
      this.adminTreeControl.expand(this.adminResultMenu);
    }
  }

  convertJson2Tree(data, depth) {
    if (depth > 0) {
      const arrtree = [];
      for (let i = 0; i < data.length; i++) {
        const eachtree: MenuNode = { name: '', key: '', color: '', bordercolor: '', parentKey: '', children: [], displayOrder: 0, ui: '' };
        eachtree.name = data[i].item;
        eachtree.key = data[i].key;
        eachtree.color = (depth >= arrcolor.length) ? arrcolor[arrcolor.length - 1] : arrcolor[depth];
        eachtree.bordercolor = (depth >= arrcolor.length) ? arrcolor[arrcolor.length - 1] : arrcolor[depth - 1];
        eachtree.parentKey = data[i].parentKey;
        eachtree.displayOrder = data[i].displayOrder;
        eachtree.ui = data[i].ui;
        if (data[i].children != undefined) {
          eachtree.children = this.convertJson2Tree(data[i].children, depth + 1);
          if (eachtree.children == undefined) {
            eachtree.children = [];
          }
        } else {
          eachtree.children = [];
        }
        arrtree.push(eachtree);
      }
      return arrtree;
    } else {
      for (let i = 0; i < data.length; i++) {
        treedata[i] = { name: '', key: '', color: '', bordercolor: '', parentKey: '', children: [], displayOrder: 0, ui: '' };
        treedata[i].name = data[i].item;
        treedata[i].key = data[i].key;
        treedata[i].color = arrcolor[0];
        treedata[i].bordercolor = arrcolor[0];
        treedata[i].parentKey = data[i].parentKey;
        treedata[i].displayOrder = data[i].displayOrder;
        treedata[i].ui = data[i].ui;
        if (data[i].children != undefined) {
          treedata[i].children = this.convertJson2Tree(data[i].children, depth + 1);
        }
      }
      return treedata;
    }
  }

  onSetColor(node) {
    document.documentElement.style.setProperty('--node-focus-color', node.color);
  }

  hasChild = (_: number, node: MenuNode) => !!node.children && node.children.length > 0;

  hasNoContent = (_: number, _nodeData: MenuNode) => _nodeData.name === '';

  ngOnInit() {
    this.getTreeData();
  }


  getTreeData() {
    this.resultMenu = [];
    this.loading = true;
    const menuData = {
      deleted: false
    };
    this.systemSecuruityMenuService.getSystemSecurityMenu(menuData).subscribe(response => {
      this.responseData = response.body;
      this.onSort({ column: 'displayOrder', direction: 'asc' });
      this.createMenu(this.responseData);
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  createMenu(dataFromApi) {
    this.resultMenu = [];
    dataFromApi.forEach(menu => {
      if (menu.parentId == 0) {
        const temp = {
          item: menu.label,
          key: menu.dynamicMenuKey,
          children: [],
          ui: menu.ui,
          parentKey: menu.parentId,
          displayOrder: menu.displayOrder
        };
        this.resultMenu.push(temp);
      }
    });
    this.resultMenu.forEach(menuChild => {
      dataFromApi.forEach(menu => {
        if (menu.parentId == menuChild.key) {
          const temp = {
            item: menu.label,
            key: menu.dynamicMenuKey,
            children: [],
            ui: menu.ui,
            parentKey: menu.parentId,
            displayOrder: menu.displayOrder
          };
          menuChild.children.push(temp);
        }
      });
      this.createSubMenus(dataFromApi, menuChild.children);
    });
    this.setMenus();
  }

  createSubMenus(dataFromApi, resultMenu) {
    resultMenu.forEach(menuChild => {
      dataFromApi.forEach(menu => {
        if (menu.parentId == menuChild.key) {
          const temp = {
            item: menu.label,
            key: menu.dynamicMenuKey,
            children: [],
            ui: menu.ui,
            parentKey: menu.parentId,
            displayOrder: menu.displayOrder
          };
          menuChild.children.push(temp);
        }
      });
      this.createSubMenus(dataFromApi, menuChild.children);
    });
  }

  addEditMenu(node: any, action: string) {
    const dialogRef = this.dialog.open(AddSecurityMenuDialogComponent, {
      width: '45%',
      position: {
        top: '5%'
      },
      data: { node, event: action, menuData: this.responseData },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'event') {
        this.getTreeData();
      }
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.responseData = this.utilityService.sortData(this.headers, column, direction, this.responseData);
  }

  deleteMenu(node: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete: boolean) => {
      if (isDelete) {
        this.loading = true;
        const nodes = [];
        this.displayOrder = node.displayOrder;
        this.parentId = node.parentKey;
        const data = {
          dynamicMenuKey: node.key,
          deleted: true,
          parentId: node.parentKey,
          updatedUserKey: sessionStorage.getItem('userKey')
        };
        nodes.push(data);
        if (node.children.length > 0) {
          node.children.forEach(item => {
            const data = {
              dynamicMenuKey: item.key,
              deleted: true,
              parentId: node.parentKey,
              updatedUserKey: sessionStorage.getItem('userKey')
            };
            nodes.push(data);
          });
        }
        this.systemSecuruityMenuService.deleteSystemSecurityMenu(nodes).subscribe((result: any) => {
          if (result.success) {
            this.deleteSecurityRoles(node);
            this.updateDisplayOrder();
            this.toastrService.success('Successfully deleted.', 'Success');
          } else {
            this.loading = false;
            this.toastrService.danger('Oops! Something went wrong.', 'Danger');
          }
        }, (error) => {
          this.loading = false;
          this.toastrService.danger('Oops! Something went wrong.', 'Danger');
        });
      }
    });
  }

  updateDisplayOrder() {
    const data = {
      deleted: false,
      parentId: this.parentId,
    };
    this.systemSecuruityMenuService.getSystemSecurityMenu(data).subscribe(resultMenus => {
      const updateNodesOrder = [];
      resultMenus.body.forEach(element => {
        if (element.displayOrder > this.displayOrder) {
          const menuData = {
            dynamicMenuKey: element.dynamicMenuKey,
            insertedUserKey: '',
            displayOrder: element.displayOrder - 1,
            updatedUserKey: sessionStorage.getItem('userKey'),
            label: element.label
          };
          updateNodesOrder.push(menuData);
        }
      });
      this.systemSecuruityMenuService.updateSystemSecurityMenu(updateNodesOrder).subscribe((result: any) => {
        this.getTreeData();
      }, (error) => {
        this.loading = false;
        this.toastrService.danger('Oops! Something went wrong.', 'Danger');
      });
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  deleteSecurityRoles(event: any) {

    const objParams = {
      parentId: event.parentKey
    };
    this.systemSecuruityMenuService.getSecurityRoles(objParams).subscribe((result: any) => {
      if (result.body.length > 0) {
        const objUpdateParams = {
          parentId: event.parentKey,
          securityRoleKey: result.body[0].securityRoleKey,
          roleDescription: result.body[0].roleDescription,
          deleted: true,
          updatedUserKey: sessionStorage.getItem('userKey'),
        };
        this.deleteSecurityRolePolicies(result.body[0].securityRoleKey);
        this.systemSecuruityMenuService.updateSecurityRoles(objUpdateParams).subscribe((roleResult: any) => {
        }, (error) => {
          console.log('error', error);
        });
      }
    }, (error) => {
      console.log('error', error);
    });
  }

  deleteSecurityRolePolicies(securityRoleKey: string) {

    const objParams = {
      securityRoleKey
    };
    this.systemSecuruityMenuService.getSecurityRolePolicies(objParams).subscribe((result: any) => {
      if (result.body.length > 0) {
        result.body.map((data) => {
          const objUpdateParams = {
            securityRolePolicyKey: data.securityRolePolicyKey,
            securityPolicyKey: data.securityPolicyKey,
            securityRoleKey: data.securityRoleKey,
            deleted: true,
            updatedUserKey: sessionStorage.getItem('userKey'),
          };
          this.systemSecuruityMenuService.updateSecurityRolePolicies(objUpdateParams).subscribe((roleResult: any) => {
          }, (error) => {
            console.log('error', error);
          });
        });
      }
    }, (error) => {
      console.log('error', error);
    });
  }

  changeStep(event: string) {
    this.selectedIndex = event === 'next' ? this.selectedIndex + 1 : this.selectedIndex - 1;
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
  }

  addNewGroup() {
    this.securityGroupsList.editServiceGroup('', 'add');
  }


}
