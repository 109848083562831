import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-provider-management-customer-preferences',
  templateUrl: './provider-management-customer-preferences.component.html',
  styleUrls: ['./provider-management-customer-preferences.component.scss']
})
export class ProviderManagementCustomerPreferencesComponent implements OnInit {
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() providerInputKey: string;

  preferenceData: any = [];
  loading: boolean;
  showOwnBy = false;
  customerName: string;
  licensedOwnerKey: string;
  isParentData = false;
  searchString: string;
  isParent = false;

  constructor(
    private utilityService: UtilityService,
    private customerService: CustomerService,
    private providerService: ProviderService
  ) { }

  ngOnInit() {
    this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
    this.setOwnByHeader(this.providerInputKey);
    this.getProviderPreference(this.providerInputKey);
  }

  setOwnByHeader(providerKey) {
    this.customerName = '';
    const filter = {
      providerKey,
      customerOwnedProvider: true,
      deleted: false,
    };

    this.loading = true;
    this.providerService.getProviderDetails(filter).subscribe((result: any) => {
      this.loading = false;
      if (result.body.length > 0) {
        this.showOwnBy = true;
        const custFilter = {
          customerKey: result.body[0].customerOwnedCustomerKey,
          deleted: false,
        };
        this.loading = true;
        this.customerService.getCustomerDetails(custFilter).subscribe((res: any) => {
          this.loading = false;
          this.customerName = res.body[0] ? res.body[0].customerName : '';
        }, (error) => {
          this.loading = false;
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }

  getProviderPreference(providerKey) {
    this.preferenceData = [];
    const filter = {
      providerKey,
      licensedOwnerKey: this.licensedOwnerKey,
      customerKey: null,
    };

    this.loading = true;
    this.providerService.getProviderAccountProviderPreference(filter).subscribe((result: any) => {
      this.preferenceData = result.body;
      this.onSort({ column: 'customerName', direction: 'asc' });
      this.loading = false;
      if (result.body.length === 0) {
        this.isParentData = false;
        if (!this.isParent) {
          // load data of parent
          const locationFilter = {
            providerkey: this.providerInputKey
          };
          this.providerService.getProviderDetails(locationFilter).subscribe(res => {
            const locationKey = res.body[0]?.parentKey || '';
            if (locationKey) {
              this.isParentData = true;
              this.isParent = true;
              this.setOwnByHeader(locationKey);
              this.getProviderPreference(locationKey);
            } else {
              this.isParentData = false;
            }
          });
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.preferenceData = this.utilityService.sortData(this.headers, column, direction, this.preferenceData);
  }

  search(event) {
    this.searchString = event;
  }
}
