<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">Edit User Detail</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="editUserDetailsForm">
        <div class="row pt-2 noMargin">
            <div class="col-md-8 col-sm-12">
                <div class="row">
                    <div class="col-md-12 alert alert-danger m-2 text-center" *ngIf="atleasetOneRequired()">
                        Please enter at least one phone number
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field>
                            <input type="text" matInput formControlName="firstName" required placeholder="First Name"
                                autocomplete="off">
                            <mat-error
                                *ngIf="editUserDetailsForm.controls.firstName.touched && editUserDetailsForm.controls.firstName.errors">
                                <span *ngIf="editUserDetailsForm.controls.firstName.errors.required">
                                    First Name is required.
                                </span>
                                <span *ngIf="editUserDetailsForm.controls.firstName.errors.pattern">
                                    Enter valid First Name.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field>
                            <input type="text" matInput formControlName="lastName" required placeholder="Last Name"
                                autocomplete="off">
                            <mat-error
                                *ngIf="editUserDetailsForm.controls.lastName.touched && editUserDetailsForm.controls.lastName.errors">
                                <span *ngIf="editUserDetailsForm.controls.lastName.errors.required">
                                    Last Name is required.
                                </span>
                                <span *ngIf="editUserDetailsForm.controls.lastName.errors.patterns">
                                    Enter valid Last Name.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field>
                            <input matInput mask="000-000-0000" formControlName="officePhone" placeholder="Office Phone"
                                autocomplete="off">
                            <mat-error
                                *ngIf="editUserDetailsForm.controls.officePhone.touched && editUserDetailsForm.controls.officePhone.errors">
                                <span *ngIf="editUserDetailsForm.controls.officePhone.errors.pattern">
                                    Enter valid Office Phone Number.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field>
                            <input matInput mask="000-000-0000" formControlName="cellPhone" placeholder="Cell Phone"
                                autocomplete="off">
                            <mat-error
                                *ngIf="editUserDetailsForm.controls.cellPhone.touched && editUserDetailsForm.controls.cellPhone.errors">
                                <span *ngIf="editUserDetailsForm.controls.cellPhone.errors.pattern">
                                    Enter valid Cell Phone.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-checkbox formControlName="isOutOfOffice" (change)='setOutOfOffice($event);'>Out of Office
                        </mat-checkbox>
                    </div>
                    <div class="col-12 col-md-6">
                        <mat-form-field>
                            <input matInput formControlName="outOfficeExpDate" [matDatepicker]="outOfficeDate"
                                placeholder="Out of Office Expire Date" (focus)="outOfficeDate.open()">
                            <mat-datepicker-toggle matSuffix [for]="outOfficeDate"></mat-datepicker-toggle>
                            <mat-datepicker #outOfficeDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12">
                        <mat-checkbox formControlName="okToTextMsg">
                            <label>I agree to recieve Text Messaged to my Cell Phone from Service Plus.
                                Messaging/Data Rates may apply</label>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="container">
                    <div class="row mt-2">
                        <div class="col-sm-12">
                            <div class="imagePreview">
                                <img [src]="imgURL" height="120" *ngIf="imgURL" />
                            </div>
                            <label class="btn image-button d-block mt-2">
                                Change Profile Picture
                                <input [disabled]="readOnlyUser" type="file" accept='image/*' #file
                                    (change)="preview(file.files, $event)" class="uploadFile img" value="Upload Photo"
                                    style="width: 0px;height: 0px;overflow: hidden;">
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm mr-2" [disabled]="editUserDetailsForm.invalid" (click)="updateDetails()">Save
        &
        Close</button>
</div>