<div>
<nb-card class="mat-elevation-z8">
    <nb-card-header>
        <div class="row">
            <div class="col-md-12">
                <h2>Provider Search</h2>
            </div>
        </div>
        <br>
        <div class="row">
            <div class ="col">
                <form>

                    <mat-form-field>
                        <mat-label>Address</mat-label>
                        <input matInput ngx-google-places-autocomplete #placesRef="ngx-places"
                        (onAddressChange)="handleAddressChange($event)" required />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Distance</mat-label>
                        <mat-select [(ngModel)]="location.range" name="range" required>
                            <mat-option *ngFor="let distance of distances" [value]="distance.value">
                                {{distance.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="row">
                        <mat-form-field class="col-6">
                            <mat-label>Service</mat-label>
                            <mat-select #service [value]="0" (selectionChange)="getSubservices($event.value)">
                                <mat-option [value]="0">None</mat-option>
                                <mat-option *ngFor="let service of (services$ | async)?.body | orderBy:'VendorServiceTypeName'"
                                    [value]="service.VendorServiceTypeID">{{service.VendorServiceTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field class="col-6" *ngIf="service.value != 0">
                            <mat-label>Subservice</mat-label>
                            <mat-select [(ngModel)]="location.subservice" name="subservice">
                                <mat-option *ngFor="let subservice of (subservices$ | async)?.body | orderBy:'VendorSubServiceTypeName'"
                                    [value]="subservice.VendorServiceToSubServiceMappingID">{{subservice.VendorSubServiceTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <button style="width: 100%;" mat-raised-button color="primary" [disabled]="!hasGeolocation"
                    (click)="onSearch()">Search</button>

                    <hr> 

                    <div class="row">
                        <mat-form-field class="col-6">
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="name, address, distance etc...">
                        </mat-form-field>
                    <!-- </div>

                    <div class="row"> -->
                        <mat-form-field class="col-3">
                            <mat-label>Network</mat-label>
                            <mat-select value="both" (selectionChange)="onNetworkFilterChange($event)">
                                <mat-option value="both">Both</mat-option>
                                <mat-option value="in">In Network</mat-option>
                                <mat-option value="out">Out of Network</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <section class="col-3 alignCenter">
                            <mat-checkbox class="alignCenter"
                            [checked]="filters.showDNU"
                            (change)="onDNUFilterChange($event)"
                            >
                                <label>Show DNU</label>
                            </mat-checkbox>
                        </section>
                    </div>

                    <!-- <button style="width: 100%;" mat-raised-button color="primary" [disabled]="!hasGeolocation"
                    (click)="onSearch()">Search</button> -->

                    <!-- <hr> -->

                </form>
            </div>
            <div class="col">
                <google-map height="100%" width="100%" [center]="center">
                    <map-marker [position]="center"></map-marker>
                </google-map>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body>
        <div class="padding22"> 
            <table mat-table [dataSource]="dataSource">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"><img *ngIf="element.IsDNU" src="../../../assets/images/stop.png" height="25px" /> {{element.Name}} </td>
                </ng-container>
        
                <!-- Address Column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Address </th>
                    <td mat-cell *matCellDef="let element"> {{element.PhysicalAddress1}}, {{element.PhysicalCity}}
                        {{element.PhysicalStateKey}}</td>
                </ng-container>
        
                <!-- Distance Column -->
                <ng-container matColumnDef="distance">
                    <th mat-header-cell *matHeaderCellDef> Distance </th>
                    <td mat-cell *matCellDef="let element"> {{element.Distance | number : '1.2-2'}} mi</td>
                </ng-container>
        
                <!-- Port Column -->
                <ng-container matColumnDef="port">
                    <th mat-header-cell *matHeaderCellDef> Port to Port </th>
                    <td mat-cell *matCellDef="let element"> {{element.Distance * 2 | number : '1.2-2'}} mi</td>
                </ng-container>
        
                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.DispatchEmail}} </td>
                </ng-container>
        
                <!-- Phone Column -->
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell *matCellDef="let element"> {{element.BusinessHoursPhone}} </td>
                </ng-container>
        
                <!-- Payment Terms Column -->
                <ng-container matColumnDef="terms">
                    <th mat-header-cell *matHeaderCellDef> Payment Terms </th>
                    <td mat-cell *matCellDef="let element"> {{element.PaymentTermKey}} </td>
                </ng-container>
        
                <!-- In Network Column -->
                <ng-container matColumnDef="network">
                    <th mat-header-cell *matHeaderCellDef> In Network </th>
                    <td [style]="element.InNetwork ? 'color: green;' : 'color: red;'" mat-cell *matCellDef="let element">
                        {{element.InNetwork ? 'Yes' : 'No'}} </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" pageSize="5" showFirstLastButtons>
            </mat-paginator>
        </div>
    </nb-card-body>
</nb-card>    
</div>