<h1 mat-dialog-title>Match Confirmation</h1>
<div mat-dialog-content>
    <div>
    Are you sure you want to approve the application for {{data.companyName}}?
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <!-- <button mat-button [mat-dialog-close]="data.companyName" cdkFocusInitial>Ok</button> -->
    <button mat-button (click)="approveProviderApplication()">Approve Provider</button> 
</div>