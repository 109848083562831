import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-has-unsaved-guard',
  templateUrl: './has-unsaved-guard.component.html',
  styleUrls: ['./has-unsaved-guard.component.scss']
})
export class HasUnsavedGuardComponent implements OnInit {

  constructor(protected ref: NbDialogRef<HasUnsavedGuardComponent>) { }

  ngOnInit() {
  }

  submit(val) {
    this.ref.close(val);
  }

}
