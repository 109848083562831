<app-loader [loading]="loading"></app-loader>
<form [formGroup]="emailTemplateForm">
    <div class="row mt-2">
        <div class="col-md-6 mt-2">
            <mat-form-field>
                <input matInput placeholder="Out Email: " formControlName="sendingEmailAddress"
                    (change)="emailChanged()" (keypress)="onChange($event, 'outEmail')">
                <mat-error *ngIf="handleError('sendingEmailAddress', 'required')">
                    Email is required.
                </mat-error>
                <mat-error *ngIf="handleError('sendingEmailAddress', 'pattern')">
                    Enter valid Email.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 mt-1 text-right">
            <app-instructions [dataDictionaryKey]="dataDictionaryKey" [schema]="schema"></app-instructions>
        </div>
        <div class="col-md-8 mt-2">
            <mat-form-field>
                <input matInput placeholder="Subject: " formControlName="subject" (keypress)="onChange($event, 'subject')">
            </mat-form-field>
        </div>
        <div class="col-md-12 mt-2">
            <ckeditor #editor [editor]="Editor" formControlName="body" (keypress)="onChange($event, 'editor')"></ckeditor>
        </div>
    </div>
</form>