<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">Account Requirements</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th sortable="accountName" (sort)="onSort($event)" class="align-middle" direction="asc"> Account
                        Name </th>
                    <th sortable="minimumInsuredAmount" (sort)="onSort($event)" class="align-middle"> Minimum Insured
                        Amount
                    </th>
                    <th sortable="notes" (sort)="onSort($event)" class="align-middle"> Notes </th>
                </tr>
            </thead>
            <tbody *ngIf="insuranceRequirements.length > 0; else noData">
                <tr *ngFor="let item of insuranceRequirements">
                    <td>{{item.accountName}}</td>
                    <td>{{item.minimumInsuredAmount}}</td>
                    <td>{{item.notes}}</td>
                </tr>
            </tbody>
            <ng-template #noData>
                <tbody>
                    <tr>
                        <td colspan="3" class="text-center">No Data available</td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
</div>
<div class="modal-footer"></div>