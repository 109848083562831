import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'ngx-add-customer-to-dnu-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressBarModule,
  ],
  templateUrl: './add-customer-to-dnu-dialog.component.html',
  styleUrls: ['./add-customer-to-dnu-dialog.component.scss']
})
export class AddCustomerToDNUDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AddCustomerToDNUDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private providerAdminService: ProviderAdminService,
    public dialog: MatDialog,

  ) { }

  customerControl = new FormControl('');
  subCustomerControl = new FormControl('');
  reasonForDNUControl = new FormControl('');

  customers;
  filteredCustomers: Observable<string[]>;

  subCustomers;
  filteredSubCustomers: Observable<string[]>;

  customerLocation;

  customerList: any = [];
  subCustomerList: any = [];

  showSubCustomer: boolean = false
  showLocation: boolean = false

  customerKey: number = 0;
  subCustomerKey: number = 0;
  locationKey: number = 0;

  noCustomerSelected: boolean = true;
  noCustomerSelectedMessage: boolean = false;
  selectedForDNU: any = [];

  ngOnInit(): void{
    this.getCustomerList();
  }

  getCustomerList(): void {
    this.noCustomerSelectedMessage = false;
    this.providerAdminService.getCustomerList()
      .subscribe(res => {
        this.customers = res
        this.filteredCustomers = this.customerControl.valueChanges.pipe(
          map(value => this.customerFilter(value || '')),
        );
      })
      //this.getSubCustomerList(this.customerList[0].CustomerKey)
  }

  getSubCustomerList(customerKey){
    this.noCustomerSelectedMessage = false;
    console.log('this is the customer key selected ' + customerKey)
    this.selectedForDNU = customerKey;
    this.customerKey = customerKey;

    this.providerAdminService.getSubCustomerList(customerKey)
    .subscribe(res => {
      if (res.length) {
        this.showSubCustomer = true
      }
      this.subCustomers = res
      this.filteredSubCustomers = this.subCustomerControl.valueChanges.pipe(
        map(value => this.subCustomerFilter(value || '')),
      );
    })
  }

  getCustomerLocationList(subCustomerKey){
    this.noCustomerSelectedMessage = false;
    this.selectedForDNU = subCustomerKey;
    this.subCustomerKey = subCustomerKey;
    // this.showLocation = true;

    // this.providerAdminService.getCustomerLocationList(subCustomerKey)
    // .subscribe(res => {
    //   if (res.length) {
    //     this.showLocation = true
    //   }
    //   this.customerLocation = res
    // })

  }

  setCustomerLocationKey(locationKey){
    this.selectedForDNU = locationKey;
    this.locationKey = locationKey;
  }

  
  private customerFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.customers.filter(option => option.CustomerName && option.CustomerName.toLowerCase().includes(filterValue)).slice(0, 25);
  }

  private subCustomerFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.subCustomers.filter(option => option.SubCustomerName && option.SubCustomerName.toLowerCase().includes(filterValue)).slice(0, 25);
  }

  onNoClick(): void {
    //this.openSnackBar('Match has been canceled.');
    this.dialogRef.close();
  }

  addCustomerToDNUConfirmationDialog() {

    if( this.selectedForDNU == null || this.selectedForDNU == undefined || this.selectedForDNU == ''){
      this.noCustomerSelectedMessage = true;
      return;
    }
    else{ this.noCustomerSelectedMessage = false;}

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: {header: 'Add To Do Not Use', message: 'Are you sure you want to add this vendor to the Do Not Use List?',
      yesButton: 'Yes'
    }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
      if(result == true){

        this.addCustomerToDNU();
      
      }
    })
  }

  addCustomerToDNU(){

    const data = {
      inCustomerKey: this.selectedForDNU,
      inProviderId: this.data,
      inDNUReason: this.reasonForDNUControl.value
    }
    this.dialogRef.close(data);
  }




}
