import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { switchMap, publishReplay, refCount } from 'rxjs/operators';
import { pagesComponents } from '../pages';
import { providerMatchComponents } from '../pages/provider-match';

@Injectable({
  providedIn: 'root'
})
export class RouteLoaderService {

  routesLoaded: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router) { }

  public load(objParams) {
    if (!this.routesLoaded) {
      this.routesLoaded = this.http.post(`${environment.baseUrl}getDynamicRoutes`, objParams)
        .pipe(switchMap(json =>
          this.createRoutes(json))
        ),
        publishReplay(1),
        refCount()
    }
    return this.routesLoaded;
  }

  private createRoutes(json) {
    // Convert blob to json array
    json = atob(json[0].ReturnJSON).split(', ');

    // convert string to object 
    json = json.map(ob => JSON.parse(ob));

    const componentNames = { ...pagesComponents, ...providerMatchComponents };
    // replace component name string to component
    json = json.map(com => {
      com.component = componentNames[com.component];
      return com;
    });

    let config = this.router.config;

    config.map((root: any) => {
      if (root.path == 'pages') {
//        root._loadedConfig.routes[0].children = json;
        root._loadedRoutes[0].children = json;
      }
    });

    this.router.resetConfig(config);
    return from(config);
  }
}
