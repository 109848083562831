import { Component, OnInit } from '@angular/core';
import { OAuthService} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

import { authConfig } from 'src/auth.config';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { UsersService } from './pages/provider-match/services/users.service';
import { UserExperianceService } from './services/user-experiance.service';
import { forkJoin } from 'rxjs';
import { RouteLoaderService } from './services/route-loader.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  cognitoResponse: any;
  isUserRedirected = false;


  constructor(private oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private routeLoaderService: RouteLoaderService,
    private userExperianceService: UserExperianceService,

) {
    sessionStorage.setItem("cognitoTokenURL", location.href);
    if (sessionStorage.getItem('flow') === 'code' && sessionStorage.getItem('PKCI_verifier') == null || sessionStorage.getItem('PKCI_verifier') == undefined || sessionStorage.getItem('PKCI_verifier') == '') {
      this.configureCodeFlow();
    } else {
      this.configureImplicitFlow();
    }

    // Automatically load user profile
    this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(_ => {
          this.oauthService.loadUserProfile().then(_ => {
            if (sessionStorage.getItem('claims') != null && sessionStorage.getItem('claims') !== '') {
              this.isUserRedirected = true;
              this.redirectUser();
            }
         });

      });
  }


  redirectUser() {
    this.isUserRedirected = true;
    this.cognitoResponse = JSON.parse(sessionStorage.getItem('claims'));

    const filter = {
      userKey: this.cognitoResponse.sub,
      deleted: false,
    };
    const userReq = this.usersService.getUsers(filter);
    const userExperianceReq = this.userExperianceService.getUserExperiances(filter);

    forkJoin([userReq, userExperianceReq]).subscribe(([user, userExperiance]: any) => {
      const userDetails = user.body[0] || [];
      const associationKey = userExperiance[0]?.associationKey || null;

      if (associationKey) {
        this.setUserDetails(userDetails);
      } else if (userDetails.userTypeKey === '0a193949-7f87-432f-835d-1dda91350027'
        || userDetails.userTypeKey === '0be91d44-d301-4e5f-9bbd-707f6acd69a7') {
        this.setUserDetails(userDetails);
      } else {
        this.oauthService.logOut();
      }
    }, (error) => {
      console.info('error', error);
    });
  }

  setUserDetails(userDetails: any) {
    if (userDetails.userStatusKey.toLowerCase() === 'inactive') {
      alert('This User is not Active, Please contact administrator');
      this.oauthService.logOut();
      return false;
    }

    const userName = userDetails.firstName != null ? userDetails.firstName : '';
    sessionStorage.setItem('userKey', this.cognitoResponse.sub);
    sessionStorage.setItem('userName', userName.concat(' ', userDetails.lastName != null ?
      userDetails.lastName : ''));
    sessionStorage.setItem('loginUserProfile', userDetails.profilePictureURL);

    this.checkSecurityGroupExists();
    this.router.navigate(
      ['/pages/de46ed58-fafc-473c-a246-d0c1eec12ad6'],
      { relativeTo: this.route });
  }

  checkSecurityGroupExists() {
    if (!sessionStorage.getItem('securityGroupKey')) {
      const objParams = {
        userKey: this.cognitoResponse.sub,
        deleted: false,
      };

      this.usersService.getUserSecurityGroups(objParams).subscribe(res => {
        if (res.body.length > 0) {
          sessionStorage.setItem('securityGroupKey', res.body[0].securityGroupKey);
          this.loadDymanicRouting();
        } else {
          console.log("User Don't have security Group, not able to load menus");
        }
      });
    } else {
      this.loadDymanicRouting();
    }
  }

  loadDymanicRouting() {
    const securityGroupKey = sessionStorage.getItem('securityGroupKey');
    if (securityGroupKey) {
      const objParams = {
        userKey: sessionStorage.getItem('userKey'),
        securityGroupKey: securityGroupKey,
      };

      this.routeLoaderService.load(objParams).subscribe(res => {
        this.router.navigateByUrl(this.router.url);
      });
    }
  }


  ngOnInit() {
 
    sessionStorage.setItem('licensedOwnerKey', 'a598d5f4-1e96-401c-a220-3a7fe54a0840');   // TTN
//    sessionStorage.setItem('licensedOwnerKey', '5c04e83b-2c49-4abd-ad25-04e800330f7a');   // ServicePlus

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
      if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
        this.oauthService.initImplicitFlow();
      }
    });
  }

  private configureCodeFlow() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
 //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
    // Optional
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private configureImplicitFlow() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
//    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    // Optional
    this.oauthService.setupAutomaticSilentRefresh();
    // Display all events
    this.oauthService.events.subscribe(e => {
      console.debug('oauth/oidc event', e);
    });

    this.oauthService.events
      .pipe(filter(e => e.type === 'session_terminated'))
      .subscribe(e => {
        console.debug('Your session has been terminated!');
      });
  }

}