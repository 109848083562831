import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreakdownProvider } from '../../models/breakdown-providers.model';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'ngx-delete-application-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    ReactiveFormsModule,
  ],
  templateUrl: './delete-application-dialog.component.html',
  styleUrls: ['./delete-application-dialog.component.scss']
})

export class DeleteApplicationDialogComponent {

  constructor(
    private providerAdminService : ProviderAdminService,
    public dialogRef: MatDialogRef<DeleteApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BreakdownProvider,
    private _snackBar: MatSnackBar,
  ) { }

  reasonForApplicationDelete = new FormControl('');

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteProviderApplication(){
    const removalData = {
      providerPK: this.data.providerPK,
      removalReason: this.reasonForApplicationDelete.value,
    }

    //console.log(removalData)
    this.providerAdminService.deleteProviderApplicationMatch(removalData).subscribe((response: any)=> {
      this.openSnackBar('Provider has been deleted.');
    }, (error) => {
      console.log('error,', error);
      this.openSnackBar('Unable to delete provider.');
    });
    //console.log(this.data.providerPK);
    this.dialogRef.close();
  }

  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }

}
