<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="row">
    <div class="col-12 text-right">
        <button (click)="addNewQuestion()" class="btn btn-sm btn-primary mb-2">Add New Question</button>
    </div>
</div>
<div cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let item of arrQuestions;">
    <mat-accordion *ngIf="item.childrens.length > 0; else noChildRecords">
        <mat-expansion-panel class="mb-1">
            <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title>
                        <mat-form-field class="w-100">
                                <input matInput [(ngModel)]="item.title" placeholder="Question Title" autocomplete="off">
                            </mat-form-field>
                </mat-panel-title>
                <mat-panel-description>
                    <button class="btn btn-default" nbTooltip="Delete" (click)="removeFormElements(item)">
                        <i class="fas fa-times"></i>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-12 col-md-9">
                        <label class="mr-2" *ngIf="!isFormPresent(item)">What type of Response is needed</label>
                        <label class="mr-2" *ngIf="isFormPresent(item)">View Response Options</label>
                        <ng-container *ngIf="!isFormPresent(item)">
                            <mat-button-toggle-group [(ngModel)]="item.type" aria-label="Response Type"
                                (change)="openFormComponent($event, item)">
                                <mat-button-toggle *ngFor="let type of responseTypes" value="{{type}}">{{type}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </ng-container>
                        <ng-container *ngIf="isFormPresent(item)">
                            <button class="btn btn-sm btn-primary mr-1"
                                (click)="previewFormDetails(item)">View/Edit</button>
                        </ng-container>
                        <button class="btn btn-sm btn-primary" *ngIf="isFormPresent(item)"
                            (click)="clearFormDetails(item)">Clear
                        </button>
                    </div>
                    <div class="col-12 col-md-3 btn-container my-auto text-right">
                        <button type="button" class="btn btn-sm btn-primary mb-1" (click)="addSubQuestion(item)" [disabled]="false">
                            Add Sub Questions
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="item.childrens.length > 0">
                    <app-add-sub-question (questionChanged)="subQuestionChanged($event)" [arrQuestions]="item.childrens">
                    </app-add-sub-question>
                </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
    <ng-template #noChildRecords>
    <div class="border p-2 mb-1">
        <div class="row">
            <div class="col-12">
                <button class="btn btn-default delete-form-btn float-right" nbTooltip="Delete"
                    (click)="removeFormElements(item)">
                    <i class="fas fa-times"></i>
                </button>
                <div class="row">
                    <div class="col-12 col-md-10" [ngClass]="{'child-questions': item.childrens.length}">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="item.title" placeholder="Question Title"
                                        autocomplete="off">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="mr-2" *ngIf="!isFormPresent(item)">What type of Response is needed</label>
                                <label class="mr-2" *ngIf="isFormPresent(item)">View Response Options</label>
                                <ng-container *ngIf="!isFormPresent(item)">
                                    <mat-button-toggle-group [(ngModel)]="item.type" aria-label="Response Type"
                                        (change)="openFormComponent($event, item)">
                                        <mat-button-toggle *ngFor="let type of responseTypes" value="{{type}}">{{type}}
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </ng-container>
                                <ng-container *ngIf="isFormPresent(item)">
                                    <button class="btn btn-sm btn-primary mr-1"
                                        (click)="previewFormDetails(item)">View/Edit</button>
                                </ng-container>
                                <button class="btn btn-sm btn-primary" *ngIf="isFormPresent(item)"
                                    (click)="clearFormDetails(item)">Clear
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 btn-container my-auto text-right">
                        <div>
                            <button type="button" class="btn btn-sm btn-primary mb-1" (click)="addSubQuestion(item)"
                                [disabled]="false">Add Sub Questions</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="item.childrens.length > 0">
            <app-add-sub-question (questionChanged)="subQuestionChanged($event)" [arrQuestions]="item.childrens">
            </app-add-sub-question>
        </ng-container>
    </div>
        </ng-template>
        </div>
</div>
