import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SystemSecuruityMenuService } from 'src/app/services/system-securuity-menu.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableHeaderDirective, SortEvent } from 'src/app/share-module/directive/sortable.directive';

@Component({
  selector: 'ngx-security-group-permission',
  templateUrl: './security-group-permission.component.html',
  styleUrls: ['./security-group-permission.component.scss']
})
export class SecurityGroupPermissionComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() showNext = true;

  // Instance members
  loading: boolean;
  serviceData: any;

  expandLayer: any = [];
  level = 0;
  isActiveNode = true; // If false do not show active/inactive checkbox on node.
  disableDragDrop = true; // If true disable Drag and Drop.
  lastNode = 4; // If -1 then bubble tree can go upto n layers
  isSelected = -1;
  parentKey = '9f967d87-b8ce-4ffd-91f2-9cd00a2a664f';
  tempBubbleTree: any[];
  resultMenu: any[];
  securityGroupLevelOne: any;
  securityGroupListData: any = [];
  securityGroupMenus: any = [];
  dynamicMenuList: any = [];
  securityGroupKey: string;
  userTypeList: any = [];
  securityGroupData: any = [];
  parentId: string;
  selectedPlatForm: any = [];
  platformTypeList: any = [];

  constructor(
    private systemSecurityService: SystemSecuruityMenuService,
    private utilityService: UtilityService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    localStorage.removeItem('serviceNetwork1');
    sessionStorage.removeItem('expandedLayer1');
    sessionStorage.setItem('expandedLayer1', JSON.stringify(this.expandLayer));
    this.getSecurityGroupsByPlatform();
  }

  getSecurityGroupsByPlatform() {

    this.dynamicMenuList = [];
    this.securityGroupMenus = [];
    this.userTypeList = [];
    this.securityGroupListData = [];
    this.loading = true;
    this.systemSecurityService.getUniquePlatforms().subscribe(response => {
      response.body.map((val) => {
        if (val.platform === 'FleetAssist') {
          val.parentId = 'a034cea9-afcf-4018-8679-7de001e26a3a';
        } else if (val.platform === 'ServicePlus') {
          val.parentId = '9f967d87-b8ce-4ffd-91f2-9cd00a2a664f';
        } else {
          val.parentId = 'b4750447-85a6-11eb-8855-028de0ed50da';
        }
      });
      this.platformTypeList = response.body;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  getUserTypes(event: any) {

    this.userTypeList = [];
    this.dynamicMenuList = [];
    this.securityGroupMenus = [];
    this.securityGroupListData = [];
    this.loading = true;
    this.selectedPlatForm = this.platformTypeList.find(a => a.userTypeKey === event.value);
    const objParams = {
      platForm: this.selectedPlatForm.platform
    };
    this.systemSecurityService.getUserTypesByDescription(objParams).subscribe(response => {
      this.userTypeList = response.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getSecurityGroupsByUserType(event: any) {

    const data = {
      userTypeKey: event.value,
      // systemDefault: 0,
      deleted: false,
    };
    this.dynamicMenuList = [];
    this.securityGroupMenus = [];
    this.loading = true;
    this.systemSecurityService.getSecurityGroupList(data).subscribe(result => {
      this.securityGroupListData = result.body;
      this.onSortMenu({ column: 'description', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  getMenusBySecurityGroups(event: any) {

    this.dynamicMenuList = [];
    this.loading = true;
    this.securityGroupKey = event.value;
    this.securityGroupData = this.securityGroupListData.find(a => a.securityGroupKey === event.value);
    const paltformDetails = this.platformTypeList.find(a => a.platform === this.selectedPlatForm.platform);
    this.parentId = paltformDetails.parentId;

    const objParams = {
      parentId: this.parentId,
      deleted: false,
      ui: this.selectedPlatForm.platform,
      securityGroupKey: this.securityGroupData.securityGroupKey
    };
    this.systemSecurityService.getSystemSecurityMenu(objParams).subscribe(response => {
      this.securityGroupMenus = response.body;
      this.onSort({ column: 'label', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getTreeMenuData(event: any) {

    this.dynamicMenuList = [];
    this.loading = true;
    const menuData = {
      parentId: event.value,
      ui: this.selectedPlatForm.platform,
      deleted: false
    };
    this.systemSecurityService.getSystemSecurityMenu(menuData).subscribe(response => {
      const tempData = [];
      if (response.body.length) {
        response.body.map(menuDetails => {
          const objParamDetails = {
            parentId: menuDetails.dynamicMenuKey,
            ui: this.selectedPlatForm.platform,
            deleted: false
          };
          menuDetails.children = [];
          menuDetails.level = 1;
          this.systemSecurityService.getSystemSecurityMenu(objParamDetails).subscribe(childResponse => {
            const tempData1 = [];
            childResponse.body.map((childData) => {
              childData.children = [];
              const objParamDetails1 = {
                parentId: childData.dynamicMenuKey,
                ui: this.selectedPlatForm.platform,
                deleted: false
              };
              this.systemSecurityService.getSystemSecurityMenu(objParamDetails1).subscribe(childResponse1 => {
                childResponse1.body.map((chResp) => {
                  chResp.children = [];
                  childData.children = childResponse1.body;
                });
              });
            });
            menuDetails.children = childResponse.body;
            menuDetails.level = 1;
            tempData.push(menuDetails);
            this.loading = false;
          }, (error) => {
            console.log(error);
            this.loading = false;
          });
        });
        setTimeout(() => {
          this.loading = false;
          this.dynamicMenuList = tempData;
          if (localStorage.getItem('serviceNetwork1') == null || localStorage.getItem('serviceNetwork1') === undefined) {
            localStorage.setItem('serviceNetwork1', JSON.stringify(this.dynamicMenuList));
          }
        }, 500);
      } else {
        this.getParentMenu(event.value);
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getParentMenu(dynamicMenuId: string) {
    this.dynamicMenuList = [];
    this.loading = true;
    const menuData = {
      dynamicMenuKey: dynamicMenuId,
      ui: this.selectedPlatForm.platform,
      deleted: false
    };
    let tempData: any = [];
    this.systemSecurityService.getSystemSecurityMenu(menuData).subscribe(response => {
      this.loading = false;
      tempData = response.body[0];
      tempData.children = [];
      this.dynamicMenuList.push(tempData);
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.securityGroupMenus = this.utilityService.sortData(this.headers, column, direction, this.securityGroupMenus);
  }

  onSortMenu({ column, direction }: SortEvent) {
    this.securityGroupListData = this.utilityService.sortData(this.headers, column, direction, this.securityGroupListData);
  }
}
