/*** Angular Core ***/
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule }                         from '@angular/core';
import { CommonModule, CurrencyPipe }                     from '@angular/common';
import { RouterModule }                     from '@angular/router';
import { DragDropModule }                   from '@angular/cdk/drag-drop';

/*** Angular Material ***/
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule }         from '@angular/material/card';
import { MatCheckboxModule }     from '@angular/material/checkbox';
import { MatDatepickerModule }   from '@angular/material/datepicker';
import { MatDialogModule }       from '@angular/material/dialog';
import { MatFormFieldModule }    from '@angular/material/form-field';
import { MatIconModule }         from '@angular/material/icon';
import { MatInputModule }        from '@angular/material/input';
import { MatListModule }         from '@angular/material/list';
import { MatNativeDateModule }   from '@angular/material/core';
import { MatSelectModule }       from '@angular/material/select';
import { MatSlideToggleModule }  from '@angular/material/slide-toggle';
import { MatSliderModule }       from '@angular/material/slider';
import { MatStepperModule }      from '@angular/material/stepper';
import { MatTabsModule }         from '@angular/material/tabs';
import { MatTreeModule }         from '@angular/material/tree';
import { NgxMatDatetimePickerModule, 
         NgxMatTimepickerModule, 
         NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

/*** NGX ***/
import { GooglePlaceModule }        from 'ngx-google-places-autocomplete-esb';
import { NgxImageCompressService }  from 'ngx-image-compress';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSmartTableModule }      from 'ngx-smart-table';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'; // NgxMaskModule, IConfig

/*** Nebular ***/
import { NbDialogModule, NbSidebarModule, NbMenuModule, NbToastrModule, 
         NbCardModule, NbTooltipModule, NbAccordionModule, NbLayoutModule } from '@nebular/theme';


/*** Additional @ imports ***/
import { CKEditorModule }   from '@ckeditor/ckeditor5-angular';
import { ThemeModule }      from '../@theme/theme.module';
// import { TimezoneConvertDatePipe } from '../@theme/pipes/timezone-convert-date.pipe';


/*** Components ***/
import { AddEditTechnicianComponent }          from './technician-expertise/components/add-edit-technician/add-edit-technician.component';
import { ApprovalComponent }                   from './approval/approval.component';
import { BillingDetailsComponent }             from './billing-details/billing-details.component';
import { BubbleTreeUiComponent }               from './bubble-tree-ui/bubble-tree-ui.component';
import { BubbleUserInformationIndexComponent } from './bubble-tree-ui/bubble-user-information-index/bubble-user-information-index.component';
import { CustomerHierarchyComponent }          from './customer-hierarchy/customer-hierarchy.component';
import { CustomerSearchComponent }             from './customer-search/customer-search.component';
import { CustomerUsersListComponent }          from './customer-users-list/customer-users-list.component';
import { EmailCommunicationTemplateComponent } from './email-communication-template/email-communication-template.component';
import { EmployeeComponent }                   from './employee/employee.component';
import { HasUnsavedGuardComponent }            from './has-unsaved-guard/has-unsaved-guard.component';
import { HoursOfOperationComponent }           from './hours-of-operation/hours-of-operation.component';
import { InstructionsComponent }               from './instructions/instructions.component';
import { LocationDetailsComponent }            from './location-details/location-details.component';
import { NationalAccountsComponent }           from './national-accounts/national-accounts.component';
import { ProviderCapabilitiesComponent }       from './provider-capabilities/provider-capabilities.component';
import { SetupDispatchDetailsComponent }       from './setup-dispatch-details/setup-dispatch-details.component';
import { SetupEtaComponent }                   from './setup-eta/setup-eta.component';
import { SetupRatesComponent }                 from './setup-rates/setup-rates.component';
import { SortableHeaderDirective }             from './directive/sortable.directive';
import { TechnicianExpertiseComponent }        from './technician-expertise/technician-expertise.component';
import { VendorServicesComponent }             from './vendor-services/vendor-services.component';

/*** NonComponents ***/
import { CityPipe }                             from './pipes/city.pipe';
import { DifferenceInHoursBetweenTwoTimesPipe } from './pipes/difference-in-hours-between-two-times.pipe';
import { FilterPipe }                           from './pipes/filter.pipe';
import { FilterTextPipe }                       from './pipes/filtertext.pipe';
import { LoaderComponent }                      from './loader/loader.component';
import { MenulistComponent }                    from './menulist/menulist.component';
import { MultiColumnFilterPipe }                from './pipes/multi-column-filter.pipe';
import { OrderByPipe }                          from './pipes/order-by.pipe';
import { RecursiveFilterPipe }                  from './pipes/recursive-filter.pipe';
import { SelectFilterPipe }                     from './pipes/select-filter.pipe';
import { ProviderAccountDetailsComponentNew }   from './provider-account-details/provider-account-details.component';
import { TimezoneConvertDatePipe }              from './pipes/timezone-convert-date.pipe';
import { TreeModule }                           from 'angular-tree-component';
import { CapitalizeFirstLetterPipe }            from './pipes/capitalizeFirtstLetterInWords';
//import { CurrencyPipe } from '@angular/common';
import { CurrencyInputPipe } from './pipes/currencyInputPipe';
/*** Commented Out Imports ***/
// import { AddEditEmployeeDialogsComponent }   from '../dialogs/add-edit-employee-dialogs/add-edit-employee-dialogs.component';
// import { AssetManagementComponent }          from '../pages/asset-upload/asset-management/asset-management.component';
// import { AsyncCityValidatorDirective }       from './validation/async-city.validator';
// import { AuthGuard }                         from './auth/auth.guard';
// import { AuthorizationComponent }            from './authorization/authorization.component';
// import { ButtonViewComponent }               from './button-view/button-view.component';
// import { CityValidatorDirective }            from './validation/city.validator';
// import { CustomPreloadingStrategy }          from './preload/custom-preloading.strategy';
// import { CustomerAdminDetailsComponent }     from '../pages/customer-admin/customer-admin-details/customer-admin-details.component';
// import { CustomerAssetDialogComponent }      from '../dialogs/customer-asset-dialog/customer-asset-dialog.component';
// import { CustomerFormComponent }             from './customer-form/customer-form.component';
// import { CustomerNoteComponent }             from './customer-note/customer-note.component';
// import { CustomerTiersComponent }            from './customer-tiers/customer-tiers.component';
// import { DateComponent }                     from './date/date.component';
// import { LeaveComponentGuard }               from './deactivation/LeaveComponentGuard';
// import { PageNotFoundComponent }             from './page-not-found/page-not-found.component';
// import { ProtocolListComponent }             from './protocol-list/protocol-list.component';
// import { RoundTrip }                         from './validation/roundtrip.validator';
// import { SelectCheckAllComponent }           from './select-check-all.component';
// import { TireProtocolsComponent }            from './tire-protocols/tire-protocols.component';
// import { ToggleButtonViewComponent }         from './toggle-button-view/toggle-button-view.component';
// import { UserAuthorizationListComponent }    from './user-authorization-list/user-authorization-list.component';
// import { UserInviteProcessComponent }        from '../dialogs/user-invite-process/user-invite-process.component';
// import { WhiteLabelDialogComponent }         from '../dialogs/white-label-dialog/white-label-dialog.component';


@NgModule({
    imports: [
        CKEditorModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        GooglePlaceModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatStepperModule,
        MatTabsModule,
        MatTreeModule,
        NbAccordionModule,
        NbCardModule,
        NbDialogModule,
        NbLayoutModule,
        NbMenuModule,
        NbSidebarModule,
        NbToastrModule,
        NbTooltipModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatSelectSearchModule,
        NgxMatTimepickerModule,
        NgxSmartTableModule,
        ReactiveFormsModule,
        RouterModule,
        ThemeModule,
        TreeModule,
    ],
    providers: [
        NgxImageCompressService,
        //CurrencyInputPipe,
        CurrencyPipe,
        provideNgxMask()
    ],
    declarations: [
        AddEditTechnicianComponent,
        ApprovalComponent,
        BillingDetailsComponent,
        BubbleTreeUiComponent,
        BubbleUserInformationIndexComponent,
        CapitalizeFirstLetterPipe,
        CityPipe,
        CurrencyInputPipe,
        CustomerHierarchyComponent,
        CustomerSearchComponent,
        CustomerUsersListComponent,
        DifferenceInHoursBetweenTwoTimesPipe,
        EmailCommunicationTemplateComponent,
        EmployeeComponent,
        FilterPipe,
        FilterTextPipe,
        HasUnsavedGuardComponent,
        HoursOfOperationComponent,
        InstructionsComponent,
        LoaderComponent,
        LocationDetailsComponent,
        MenulistComponent,
        MultiColumnFilterPipe,
        NationalAccountsComponent,
        OrderByPipe,
        ProviderAccountDetailsComponentNew,
        ProviderCapabilitiesComponent,
        RecursiveFilterPipe,
        SelectFilterPipe,
        SetupDispatchDetailsComponent,
        SetupEtaComponent,
        SetupRatesComponent,
        SortableHeaderDirective,
        TechnicianExpertiseComponent,
        TimezoneConvertDatePipe,
        VendorServicesComponent,

        // AddEditEmployeeDialogsComponent,
        // AssetManagementComponent,
        // AsyncCityValidatorDirective,
        // AuthorizationComponent,
        // ButtonViewComponent,
        // CityValidatorDirective,
        // CustomerAdminDetailsComponent,
        // CustomerAssetDialogComponent,
        // CustomerFormComponent,
        // CustomerNoteComponent,
        // CustomerTiersComponent,
        // DateComponent,
        // PageNotFoundComponent,
        // ProtocolListComponent,
        // RoundTrip,
        // SelectCheckAllComponent,
        // TimezoneConvertDatePipe
        // TireProtocolsComponent,
        // ToggleButtonViewComponent,
        // UserAuthorizationListComponent,
        // UserInviteProcessComponent,
        // WhiteLabelDialogComponent,
    ],
    exports: [
        AddEditTechnicianComponent,
        ApprovalComponent,
        BillingDetailsComponent,
        BubbleTreeUiComponent,
        BubbleUserInformationIndexComponent,
        CapitalizeFirstLetterPipe,
       // CurrencyPipe,
        CurrencyInputPipe,
        CityPipe,
        CommonModule,
        CustomerHierarchyComponent,
        CustomerSearchComponent,
        CustomerUsersListComponent,
        DifferenceInHoursBetweenTwoTimesPipe,
        EmailCommunicationTemplateComponent,
        EmployeeComponent,
        FilterPipe,
        FilterTextPipe,
        HasUnsavedGuardComponent,
        HoursOfOperationComponent,
        InstructionsComponent,
        LoaderComponent,
        LocationDetailsComponent,
        MenulistComponent,
        MultiColumnFilterPipe,
        NationalAccountsComponent,
        OrderByPipe,
        ProviderAccountDetailsComponentNew,
        ProviderCapabilitiesComponent,
        RecursiveFilterPipe,
        SelectFilterPipe,
        SetupDispatchDetailsComponent,
        SetupEtaComponent,
        SetupRatesComponent,
        SortableHeaderDirective,
        TechnicianExpertiseComponent,
        TimezoneConvertDatePipe,
        VendorServicesComponent,

        // AddEditEmployeeDialogsComponent,
        // AssetManagementComponent,
        // AsyncCityValidatorDirective,
        // AuthorizationComponent,
        // CityValidatorDirective,
        // CustomerAdminDetailsComponent,
        // CustomerAssetDialogComponent,
        // CustomerFormComponent,
        // CustomerNoteComponent,
        // CustomerTiersComponent,
        // DateComponent,
        // PageNotFoundComponent,
        // ProtocolListComponent,
        // RoundTrip,
        // SelectCheckAllComponent,
        // TimezoneConvertDatePipe,
        // TireProtocolsComponent,
        // UserAuthorizationListComponent,
        // UserInviteProcessComponent,
        // WhiteLabelDialogComponent,
    ]
})
export class SharedModule { }
