import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
      <div *ngIf="loading" id="preloader">
      <div id="loader"></div>
    </div>`,
  styleUrls: []
})
export class LoaderComponent implements OnInit {
  @Input() loading: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
