import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RatesService } from '../../services/rates.service';
import { NbToastrService } from '@nebular/theme';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-account-pricing-exception-approval-dialog',
  templateUrl: './account-pricing-exception-approval-dialog.component.html',
  styleUrls: ['./account-pricing-exception-approval-dialog.component.scss']
})
export class AccountPricingExceptionApprovalDialogComponent implements OnInit {
  accountPricingExceptionApprovalDetails: any;
  loading = false;
  isDisabled = false;
  isButton = false;
  accountPricingExceptionForm: UntypedFormGroup;
  isFirstApprover = false;
  isSecondApprover = false;
  eventAccountPricingException = new EventEmitter();
  maxThresholdRate = 0;
  firstNote: any;
  secondNote: any;
  rateAppr: any;
  providerAccountPricingDetails: any;

  constructor(public dialog: MatDialogRef<AccountPricingExceptionApprovalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private ratesService: RatesService,
              private toast: NbToastrService,
              private formBuilder: UntypedFormBuilder,
              private usersService: UsersService) { }

  ngOnInit() {
    this.accountPricingExceptionForm = this.formBuilder.group({
      firstApproverNote: [''],
      secondApproverNote: [''],
      rateApproved: ['']
    });

    let rate;
    const date  = new Date();
    if (!this.data.accountPricingExceptionData) {
      this.getThresholdRate();
      this.getUser('firstApprover');

      if (this.data.lineItemPricingCategoryKey === 'Emergency Rate') {
        rate = this.data.providerAccountPricingData.emergencyRate;
      } else if (this.data.lineItemPricingCategoryKey === 'Emergency After-Hours Rate') {
        rate = this.data.providerAccountPricingData.afterHoursRate;
      } else {
        rate = this.data.providerAccountPricingData.scheduledRate;
      }

      this.accountPricingExceptionApprovalDetails = {
        lineItemKey: this.data.providerAccountPricingData.lineItemKey,
        lineItemPricingCategoryKey: this.data.lineItemPricingCategoryKey,
        requestedRate: rate,
        firstApprovalTimestamp: date.getDate() + ' ' + date.toLocaleString('default', { month: 'long' }) + ', ' + date.getFullYear()
      };
      this.accountPricingExceptionForm.controls.firstApproverNote.setValidators([Validators.required]);
      this.isSecondApprover = true;
      this.isDisabled = true;
    } else {
      this.firstNote = this.data.accountPricingExceptionData.firstApproverNote;
      this.secondNote = this.data.accountPricingExceptionData.secondApproverNote;
      this.rateAppr = this.data.accountPricingExceptionData.rateApproved === '' ? false : this.data.accountPricingExceptionData.rateApproved;
      this.accountPricingExceptionApprovalDetails = this.data.accountPricingExceptionData;
      this.providerAccountPricingDetails = this.data.providerAccountPricingData;

      if (this.accountPricingExceptionApprovalDetails.rateApproved === true ||
       (this.accountPricingExceptionApprovalDetails.rateApproved === false &&
        this.accountPricingExceptionApprovalDetails.deleted === true)) {
        this.isFirstApprover = true;
        this.isSecondApprover = true;
        this.isDisabled = true;
        this.isButton = true;
    } else {
      if (!this.accountPricingExceptionApprovalDetails.secondApproverUserKey) {
        this.getUser('secondApprover');
      } else if (sessionStorage.getItem('userKey') === this.accountPricingExceptionApprovalDetails.secondApproverUserKey) {
        this.isSecondApprover = true;
        this.isDisabled = true;
      }

      if (!this.accountPricingExceptionApprovalDetails.firstApproverUserKey) {
        this.isFirstApprover = true;
        this.isSecondApprover = true;
        this.isDisabled = true;
      } else if (sessionStorage.getItem('userKey') === this.accountPricingExceptionApprovalDetails.firstApproverUserKey) {
        this.isFirstApprover = true;
        this.isSecondApprover = true;
        this.isDisabled = true;
        this.isButton = true;
      } else {
        this.isFirstApprover = true;
        this.accountPricingExceptionForm.controls.secondApproverNote.setValidators([Validators.required]);
      }
    }
    }
  }

  getUser(approver: string) {
    this.loading = true;
    this.usersService.getUsers({userKey: sessionStorage.getItem('userKey')}).subscribe(userRes => {
      const user = userRes.body[0];
      if (approver === 'firstApprover') {
        this.accountPricingExceptionApprovalDetails.firstApproverUser = user.firstName + ' ' + user.lastName;
      } else {
        const date  = new Date();
        this.accountPricingExceptionApprovalDetails.secondApproverUser = user.firstName + ' ' + user.lastName;
        this.accountPricingExceptionApprovalDetails.secondApprovalTimestamp = date.getDate() + ' ' + date.toLocaleString('default', { month: 'long' }) + ', ' + date.getFullYear()
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  getThresholdRate() {
    this.maxThresholdRate = 0;
    const self = this;
    const promise = new Promise<void>(function(resolve, reject) {
      self.data.matchedRegion.forEach((region, index) => {
            const filter = {
              specializedPricingRegionKey: region.specializedPricingRegionKey,
              lineItemKey: self.data.providerAccountPricingData.lineItemKey,
              lineItemPricingTypeKey: self.data.providerAccountPricingData.lineItemPricingTypeKey,
            };

            self.ratesService.getLicensedOwnerSpecializedPricingRegions(filter).subscribe(result => {
              if (result.body.length > 0) {
                if (self.data.lineItemPricingCategoryKey === 'Emergency Rate') {
                  self.getMaximumValue(result.body[0].emergencyRateTo);
                } else if (self.data.lineItemPricingCategoryKey === 'Emergency After-Hours Rate') {
                  self.getMaximumValue(result.body[0].afterHoursRateTo);
                } else {
                  self.getMaximumValue(result.body[0].scheduledRateTo);
                }
              }
              if (index === self.data.matchedRegion.length - 1) {
                resolve();
              }
            });
          });
    });
  }

  getMaximumValue(rate) {
    if (rate > this.maxThresholdRate) {
      this.maxThresholdRate = rate;
      if (this.data.lineItemPricingCategoryKey === 'Emergency Rate') {
        this.accountPricingExceptionApprovalDetails.maxThresholdRate = this.maxThresholdRate;
      } else if (this.data.lineItemPricingCategoryKey === 'Emergency After-Hours Rate') {
        this.accountPricingExceptionApprovalDetails.maxThresholdRate = this.maxThresholdRate;
      } else {
        this.accountPricingExceptionApprovalDetails.maxThresholdRate = this.maxThresholdRate;
      }
    }
  }

  createProviderPricingExceptionRecord() {
    if (!this.accountPricingExceptionForm.valid) {
      this.accountPricingExceptionForm.markAllAsTouched();
      return false;
    }

    this.loading = true;
    let requestedRates;
    if (this.data.lineItemPricingCategoryKey === 'Emergency Rate') {
      requestedRates = this.data.providerAccountPricingData.emergencyRate;
    } else if (this.data.lineItemPricingCategoryKey === 'Emergency After-Hours Rate') {
      requestedRates = this.data.providerAccountPricingData.afterHoursRate;
    } else {
      requestedRates = this.data.providerAccountPricingData.scheduledRate;
    }

    const data = {
      providerAccountPricingKey: this.data.providerAccountPricingData.providerAccountPricingKey,
      rateApproved: false,
      firstApproverNote: this.accountPricingExceptionForm.get('firstApproverNote').value,
      firstApproverUserKey: sessionStorage.getItem('userKey'),
      firstApprovalTimestamp: new Date().getTime(),
      lineItemPricingCategoryKey: this.data.lineItemPricingCategoryKey,
      requestedRate: requestedRates,
      maxThresholdRate: this.maxThresholdRate === 0 ? '' : this.maxThresholdRate,
      insertedTimestamp: new Date().getTime(),
      updatedTimestamp: new Date().getTime(),
      insertedUserKey: sessionStorage.getItem('userKey'),
      updatedUserKey: sessionStorage.getItem('userKey'),
      systemDefault: true,
      deleted: false
    };

    this.ratesService.addProviderAccountPricingException(data).subscribe(res => {
      if (res.success) {
        this.toast.success('Successfully Created New Record', 'Success');
        this.eventAccountPricingException.emit();
        this.dialog.close();
        this.loading = false;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  saveAccountPricingException() {
    if (!this.data.accountPricingExceptionData) {
      this.createProviderPricingExceptionRecord();
    } else {

    if (!this.accountPricingExceptionForm.valid) {
      this.accountPricingExceptionForm.markAllAsTouched();
      return false;
    }
    this.loading = true;
    let updateProviderPricingData;
    const updateData = {
      rateApproved: this.accountPricingExceptionApprovalDetails.rateApproved,
      secondApproverUserKey: sessionStorage.getItem('userKey'),
      secondApprovalTimestamp: new Date().getTime(),
      secondApproverNote: this.accountPricingExceptionApprovalDetails.secondApproverNote,
      providerAccountPricingExceptionKey: this.accountPricingExceptionApprovalDetails.providerAccountPricingExceptionKey
    };

    if (this.accountPricingExceptionApprovalDetails.lineItemPricingCategoryKey === 'Emergency Rate') {
        updateProviderPricingData = {
          providerAccountPricingKey: this.providerAccountPricingDetails.providerAccountPricingKey,
          emergencyRateApproved: updateData.rateApproved
        };
    } else if (this.accountPricingExceptionApprovalDetails.lineItemPricingCategoryKey === 'Emergency After-Hours Rate') {
        updateProviderPricingData = {
          providerAccountPricingKey: this.providerAccountPricingDetails.providerAccountPricingKey,
          afterHoursRateApproved: updateData.rateApproved
        };
    } else {
        updateProviderPricingData = {
          providerAccountPricingKey: this.providerAccountPricingDetails.providerAccountPricingKey,
          scheduledRateApproved: updateData.rateApproved
        };
    }

    const providerPricingException = this.ratesService.updateProviderAccountPricingException(updateData);
    const providerAccountPricing = this.ratesService.putProviderAccountPricing(updateProviderPricingData);

    forkJoin([providerPricingException, providerAccountPricing]).subscribe(result => {
      if (result[0].success && result[1].success) {
        this.toast.success('Saved successfully', 'Success');
        this.eventAccountPricingException.emit();
      }
      this.dialog.close();
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
    }
  }

  closeDialog() {
    if (this.data.accountPricingExceptionData) {
      this.accountPricingExceptionForm.controls.firstApproverNote.setValue(this.firstNote);
      this.accountPricingExceptionForm.controls.secondApproverNote.setValue(this.secondNote);
      this.accountPricingExceptionForm.controls.rateApproved.setValue(this.rateAppr);
    }
    this.dialog.close();
  }

  handleError = (control: string, error: string) => {
    return this.accountPricingExceptionForm.controls[control].hasError(error);
  }

}
