<app-loader [loading]="loading"></app-loader>
<div class="modal-header" *ngIf="searchCompleted" >
    <h6 class="modal-title">Providers</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div *ngIf="searchCompleted">
    <div class="table-responsive table-height">
        <table class="table table-bordered table-striped tableCSS">
            <thead class="text-center">
                <tr>
                    <th> Location Name </th>
                    <th> Address </th>
                    <th> Number of Sublocations </th>
                    <th> Status </th>
                    <!-- <th> Policy Expiration Date</th>
                    <th> Actions </th> -->
                </tr>
            </thead>
            <tbody *ngIf="providersList2.length > 0; else noData">
                <tr *ngFor="let item of providersList2">
                    <td>
                        <a href="" onclick="return false;"
                            (click)="selectProvider(item)">{{item.Name}} </a>
                    </td>
                    <td> {{item.MailingAddress1}}, {{item.MailingCity}}, {{item.MailingStateKey}} </td>
                    <td> {{item.CountOfSublocations}} </td>
                    <td> {{item.AccountStatus}} </td>
                </tr>
            </tbody>
            <ng-template #noData>
                <tbody>
                    <tr>
                        <td colspan="6" class="text-center">No Reults Matching Your Search Criteria</td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
</div>