import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { ProviderAttachmentUpload } from '../models/outbound/provider-key-and-upload-purpose.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public userName = new Subject<any>();
  public systemMenus = new Subject<any>();
  public loading = new Subject<boolean>();
  resultMenu: any = [];
  conutries: Observable<any>;
  states: Observable<any>;
  corporateStructures: Observable<any[]>;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe) { }


  setUserName(userName: string) {
    this.userName.next(userName);
  }

  // API call to get Services from AxelPoint
  getVendorServices(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}GetVendorServiceSearch`);
  }

  getSubService(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}GetVendorSubServiceSearch/${data}`);
  }

  SelectProviderAttachmentsForServicePlus(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}SelectProviderAttachmentsForServicePlus/${data}`);
  }

  // Inserts attachments using Axelpoint database
  InsertProviderAttachmentsForServicePlus(data: FormData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}InsertProviderAttachmentsForServicePlus`, data);
  }

  // Deletes attachments using Axelpoint database 
  deleteProviderAttachmentsFromServicePlus(inPATlKey): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}deleteProviderAttachmentsFromServicePlus/${inPATlKey}`);
  }

  //API call to get all offering data.
  getLicensedOwnerOfferings(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getTierServiceOfferring`);
  }

  // Add LicensedOwner Offerings
  addLicensedOwnerOfferings(arrReqData): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/networkownerofferings`, arrReqData);
  }

  // Update Tier Service Offerring
  updateTierServiceOfferring(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}updateTierServiceOfferring`, arrReqData);
  }

  resetMenuItems() {
    this.systemMenus.next();
  }

  setSystemMenus(menu: any) {
    this.systemMenus.next(menu);
  }

  getUserTypes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/usertypes/get`, data);
  }

  public setLoading(data: boolean) {
    this.loading.next(data);
  }

  // public resetMenuItems() {
  //   this.systemMenus.next();
  // }

  public getJSON(objParams: any = {}): Observable<any> {
    return this.http.post(`${environment.baseUrl}getUserKeySelectDynamicMenus`, objParams);
  }

  // Update Tier Service Offerring
  // updateTierServiceOfferring(arrReqData): Observable<any> {
  //   return this.http.post(`${environment.baseUrl}updateTierServiceOfferring`, arrReqData);
  // }
  // // Update Tier Service Offerring
  updateActivateInActivateLicensedOwnerOfferings(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}updateActivateInActivateLicensedOwnerOfferings`, arrReqData);
  }

  // API call to get all offering data.
  // getLicensedOwnerOfferings(licenesedOwnerKey): Observable<any> {
  //   return this.http.get<any>(`${environment.baseUrl}getLicensedOwnerOfferings/${licenesedOwnerKey}`);
  // }

  getCityStateFromZipCode(zipCode): Observable<any> {
    return this.http.get('https://ziptasticapi.com/' + zipCode);
  }

  // API call to get customer Contact Center Teams.
  getContactCenterTeams(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/contactcenterteams/get`, data);
  }

  getStatuses(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/statuses/get`, data);
  }

  // getlicensedOwnerStandardJobStatuses(data: any = {}): Observable<any> {
  //   if (data.deleted === undefined) {
  //     data.deleted = false;
  //   }
  //   return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/licensedOwnerstandardjobstatuses/get`, data);
  // }

  getUserStatuses(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/userstatuses/get`, data);
  }

  getProviderAccountStatuses(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/provideraccountstatuses/get`, data);
  }

  getProviderDispatchStatuses(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/providerdispatchstatuses/get`, data);
  }

  getCustomerStatuses(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/customerstatuses/get`, data);
  }

  getTimeZones(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/timezones/get`, data);
  }

  // API call to get states.
  getstates(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/states/get`, data);
  }

  // Gets attachment type options for uploading files
  getSelectAttachmentTypes(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}SelectAttachmentTypes`);
  }

  getCurrentTimestamp() {
    const formattedDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss.SSS');
    const currentTimestamp = (new Date(formattedDate)).getTime();
    return currentTimestamp;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  // API call to get network offering data.
  public getNewtorkOfferings(arrReqFilterData): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/networkownerofferings/get`, arrReqFilterData);
  }

  // add Dynamic forms json data
  public addDynamicFormDetails(postData: any, reqHeader: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}addDynamicFormDetails`, postData);
  }

  public getUsersList(postData: any = {}): Observable<any> {
    if (postData.deleted === undefined) {
      postData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/users/get`, postData);
  }

  public getCountryList(filter: any = {}): Observable<any> {
    if (!this.conutries) {
      if (filter.deleted === undefined) {
        filter.deleted = false;
      }
      this.conutries = this.http.post<any>(`${environment.apiUrl}keyvaluepairs/countries/get`, filter)
        .pipe(map(res => res), publishReplay(1), refCount());
    }
    return this.conutries;
  }

  /****
  * Get Licensed Owner details
  * @params postData
  * @return array response
  ***/
  getLicensedOwners(postData: any = {}): Observable<any> {
    if (postData.deleted === undefined) {
      postData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedowners/get`, postData);
  }

  /****
  * Get Licensed Owner details
  * @params postData
  * @return array response
  ***/
  updateLicensedOwnersDetails(postData: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedowners`, postData);
  }

  /****
  * Get Licensed Owner Discount details
  * @params postData
  * @return array response
  ***/
  getLicensedOwnerDiscountDetails(licensedOwnerKey: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}getDiscountDetails/${licensedOwnerKey}`);
  }

  /****
  * Get Discount Types
  * @params postData optional
  * @return array response
  ***/
  getDiscountTypes(postData: any = {}): Observable<any> {
    if (postData.deleted === undefined) {
      postData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/discounttypes/get`, postData);
  }

  /****
  * Get Licensed owner parent details
  * @params postData optional
  * @return array response
  ***/
  getLicensedownerofferings(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerofferings/get`, postData);
  }

  // Generic API to get Licensed Owner Discounts
  getLicensedOwnerDiscountPrograms(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprograms/get`, postData);
  }

  // Generic API to update Licensed Owner Discounts
  updateLicensedOwnerDiscountPrograms(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprograms`, postData);
  }

  /****
  * Add Discount details
  * @params postData
  * @return array response
  ***/
  addDiscountDetails(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprogramdefaults`, postData);
  }

  /****
  * Update Discount details
  * @params postData
  * @return array response
  ***/
  updateDiscountDetails(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprogramdefaults`, postData);
  }

  /****
  * Delete Discount details
  * @params postData
  * @return array response
  ***/
  deleteDiscountDetails(postData: any = {}): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprogramdefaults`, postData);
  }

  /****
  * Get Licensed Owner Custom Discount details
  * @params postData
  * @return array response
  ***/
  getLicensedOwnerCustomDiscountDetails(postData: any = {}): Observable<any> {
    return this.http.post(`${environment.baseUrl}getDiscountCustomDetails/`, postData);
  }

  /****
  * Add Custom Discount details
  * @params postData
  * @return array response
  ***/
  addCustomDiscountDetails(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprograms`, postData);
  }

  /****
  * Update Custom Discount details
  * @params postData
  * @return array response
  ***/
  updateCustomDiscountDetails(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprograms`, postData);
  }

  /****
  * Delete Custom Discount details
  * @params postData
  * @return array response
  ***/
  deleteCustomDiscountDetails(postData: any = {}): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}fleetassist/licensedownerdiscountprograms`, postData);
  }

  /****
  * Get Provider List for CustomDiscount
  * @params licensedOwnerKey
  * @return array response
  ***/
  getProviderListForCustomDiscount(licensedOwnerKey: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getProviderListForCustomDiscount/${licensedOwnerKey}`);
  }

  /****
  * Get Provider List for CustomDiscount
  * @params licensedOwnerKey
  * @return array response
  ***/
  getCustomDiscountsProviderName(licensedOwnerKey: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getCustomDiscountsProviderName/${licensedOwnerKey}`);
  }

  /****
  * Get Licensed owner Active Parent Services
  * @params licensedOwnerKey
  * @return array response
  ***/
  getLicensedOwnerActiveServices(licensedOwnerKey: any = {}): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/getNetworkOfferinsgActiveServices/` + licensedOwnerKey);
  }

  getLicensedOwnerSelectedOfferings(licenesedOwnerKey): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getLicensedOwnerSelectedOfferings/${licenesedOwnerKey}`);
  }

  getCallerTypes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/callertypes/get`, data);
  }

  getOperatingSchedules(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/operatingschedules/get`, data);
  }

  updateProfilePicture(updatedData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}uploadFile`, updatedData);
  }

  deleteProfilePicture(fileName: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}deleteFile`, fileName);
  }

  getContactCenterUserRoles(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/contactcenteruserroles/get`, filter);
  }

  getMembershipStatusList(postData: any = {}): Observable<any> {
    if (postData.deleted === undefined) {
      postData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/membershipstatuses/get`, postData);
  }

  getDepartments(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/departments/get`, filter);

  }

  // getUserTypes(data: any = {}): Observable<any> {
  //   if (data.deleted === undefined) {
  //     data.deleted = false;
  //   }
  //   return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/usertypes/get`, data);
  // }

  // get data dictionary details
  getDataDictionary(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/datadictionaries/get`, data);
  }

  getContactcenteractions(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/contactcenteractions/get`, data);
  }

  getModuleSubscription(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownermodulesubscriptions/get`, data);
  }

  // get service provider data dictionary details
  getProviderDataDictionary(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/datadictionaries/get`, data);
  }

  getCustomerHour(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customeruseoffhourprotocolsprotocols/get`, data);
  }

  getIndustry(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/industry/get`, data);
  }

  sendMailForRequestProtocolUpdate(updatedData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}sendMailForRequestProtocolUpdate`, updatedData);
  }

  getMakes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/makes/get`, data);
  }

  // get licensed owner service description
  getLicensedOwnerServiceOfferingsDetails(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getLicensedOwnerServiceOfferings`, data);
  }

  // get Corporate struture list
  getCorporateStructuresList(postData: any = {}): Observable<any> {
    if (!postData.deleted) {
      postData.deleted = false;
    }
    if (!this.corporateStructures) {
      this.corporateStructures = this.http.post<any>(`${environment.apiUrl}keyvaluepairs/corporatestructures/get`,
        postData).pipe(
          publishReplay(1),
          refCount());
    }
    return this.corporateStructures;
  }

  addDiscounts(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}addLastLevelOfferingDiscount`, postData);
  }

  sendMailWithattachment(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}sendMailWithattachment`, postData);
  }

  /**
   * API to get licensed owner counter value to update customer ID
   */
  getLicensedOwnerCounterValue(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/counters/get`, data);
  }

  /**
   * API to update licensed owner counter value with latest customer ID
   */
  updateLicensedOwnerCounterValue(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}keyvaluepairs/counters`, data);
  }


}
