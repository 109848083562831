<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6>Edit User</h6>
    <button type="button" class="close" (click)="close()" nbTooltip="close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="formDirectReport">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="First Name" formControlName="firstName" required />
                    <mat-error *ngIf="errorHandling('firstName', 'required')">
                        first name is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Last Name" formControlName="lastName" required />
                    <mat-error *ngIf="errorHandling('lastName', 'required')">
                        last name is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Job Title" formControlName="title" />

                    <mat-error *ngIf="errorHandling('title', 'required')">
                        Job title is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email" [disabled]="true" required />
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Department" formControlName="departmentKey" required>
                        <mat-option [disabled]='true'>Select</mat-option>
                        <mat-option *ngFor="let department of departmentList | orderBy : 'departmentKey'"
                            [value]="department.departmentKey">
                            {{department.departmentKey}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorHandling('departmentKey', 'required')">Role is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Supervisor" formControlName="supervisor">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let supervisor of usersList | orderBy : 'lastName'"
                            [value]="supervisor.userKey">
                            {{supervisor.lastName}} {{supervisor.firstName}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorHandling('supervisor', 'required')">
                        supervisor is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Cell Phone" formControlName="cellPhone" mask="000-000-0000" required />
                    <mat-error *ngIf="errorHandling('cellPhone', 'required')">
                        cell phone is required
                    </mat-error>
                    <mat-error *ngIf="errorHandling('cellPhone', 'minlength')">
                        cell phone at least 10 digits
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Office Phone" formControlName="officePhone" mask="000-000-0000" />
                    <mat-error *ngIf="errorHandling('officePhone', 'minlength')">
                        office phone at least 10 digits
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Status" formControlName="userStatusKey">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let status of statuses | orderBy : 'statusKey'" [value]="status.statusKey">
                            {{status.statusKey}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorHandling('userStatusKey', 'required')">
                        Status is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm ml-2" (click)="save()">Save</button>
</div>