<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-body>
        <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Menu Items">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="row mt-2">
                            <h6 class="col">FleetAssist</h6>
                        </div>
                        <div class="row divHeight">
                            <div class="col">
                                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-menu">
                                    <mat-nested-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                        style="overflow: visible">
                                        <li class="example-tree-container-child" [style.borderColor]="node.bordercolor">
                                            <div class="mat-tree-node" (mousedown)="onSetColor(node)"
                                                [style.borderLeft]="'14px solid' + node.color">
                                                <button mat-button class="mat-tree-child-last">{{node.name}}
                                                </button>
                                                <button class="popOverButton" [matMenuTriggerFor]="appMenu">
                                                    <mat-icon>menu</mat-icon>
                                                </button>
                                                <mat-menu #appMenu="matMenu">
                                                    <button (click)="addEditMenu(node, 'add')" matTooltip="Add menu"
                                                        mat-icon-button>
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                    <button (click)="addEditMenu(node, 'edit')" matTooltip="Edit menu"
                                                        mat-icon-button>
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button (click)="deleteMenu(node)" mat-icon-button
                                                        matTooltip="Delete menu">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </li>
                                    </mat-nested-tree-node>

                                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                                        <li class="example-tree-container" [style.borderColor]="node.bordercolor">
                                            <div class="mat-tree-node menu-item">
                                                <button mat-button matTreeNodeToggle
                                                    [attr.aria-label]="'toggle ' + node.name" class="mat-tree-button"
                                                    [style.borderLeft]="'14px solid' + node.color"
                                                    (mousedown)="onSetColor(node)">
                                                    {{node.name}}
                                                    <mat-icon class="mat-icon-rtl-mirror float-left">
                                                        {{treeControl.isExpanded(node) ? 'expand_more' :
                                                        'chevron_right'}}
                                                    </mat-icon>
                                                </button>
                                                <button class="popOverButton" [matMenuTriggerFor]="parentMenu">
                                                    <mat-icon>menu</mat-icon>
                                                </button>
                                                <mat-menu #parentMenu="matMenu">
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'add')"
                                                        matTooltip="Add menu" mat-icon-button>
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'edit')"
                                                        matTooltip="Edit menu" mat-icon-button>
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="deleteMenu(node)"
                                                        mat-icon-button matTooltip="Delete menu">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                            <ul class="example-tree-nested-node" [style.borderColor]="node.bordercolor">
                                                <div *ngIf="treeControl.isExpanded(node)">
                                                    <ng-container matTreeNodeOutlet></ng-container>
                                                </div>
                                            </ul>
                                        </li>
                                    </mat-nested-tree-node>
                                </mat-tree>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="row mt-2">
                            <h6 class="col">Service Plus</h6>
                        </div>
                        <div class="row divHeight">
                            <div class="col">
                                <mat-tree [dataSource]="servicePlusdataSource" [treeControl]="servicePlustreeControl"
                                    class="tree-menu">
                                    <mat-nested-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                        style="overflow: visible">
                                        <li class="example-tree-container-child" [style.borderColor]="node.bordercolor">
                                            <div class="mat-tree-node" (mousedown)="onSetColor(node)"
                                                [style.borderLeft]="'14px solid' + node.color">
                                                <button mat-button class="mat-tree-child-last">
                                                    {{node.name}}
                                                </button>
                                                <button class="popOverButton" [matMenuTriggerFor]="childSpMenu">
                                                    <mat-icon>menu</mat-icon>
                                                </button>
                                                <mat-menu #childSpMenu="matMenu">
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'add')"
                                                        matTooltip="Add menu" mat-icon-button>
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'edit')"
                                                        matTooltip="Edit menu" mat-icon-button>
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="deleteMenu(node)"
                                                        mat-icon-button matTooltip="Delete menu">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </li>
                                    </mat-nested-tree-node>
                                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                                        <li class="example-tree-container" [style.borderColor]="node.bordercolor">
                                            <div class="mat-tree-node menu-item">
                                                <button mat-button matTreeNodeToggle
                                                    [attr.aria-label]="'toggle ' + node.name" class="mat-tree-button"
                                                    [style.borderLeft]="'14px solid' + node.color"
                                                    (mousedown)="onSetColor(node)">
                                                    {{node.name}}
                                                    <mat-icon class="mat-icon-rtl-mirror float-left">
                                                        {{servicePlustreeControl.isExpanded(node) ? 'expand_more' :
                                                        'chevron_right'}}
                                                    </mat-icon>
                                                </button>
                                                <button class="popOverButton" [matMenuTriggerFor]="parentSpMenu">
                                                    <mat-icon>menu</mat-icon>
                                                </button>
                                                <mat-menu #parentSpMenu="matMenu">
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'add')"
                                                        matTooltip="Add menu" mat-icon-button>
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'edit')"
                                                        matTooltip="Edit menu" mat-icon-button>
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="deleteMenu(node)"
                                                        mat-icon-button matTooltip="Delete menu">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                            <ul class="example-tree-nested-node" [style.borderColor]="node.bordercolor">
                                                <div *ngIf="servicePlustreeControl.isExpanded(node)">
                                                    <ng-container matTreeNodeOutlet></ng-container>
                                                </div>
                                            </ul>
                                        </li>
                                    </mat-nested-tree-node>
                                </mat-tree>

                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="row mt-2">
                            <h6 class="col">Service Plus Admin</h6>
                        </div>
                        <div class="row divHeight">
                            <div class="col">
                                <mat-tree [dataSource]="adminDataSource" [treeControl]="adminTreeControl"
                                    class="tree-menu">
                                    <mat-nested-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
                                        style="overflow: visible">
                                        <li class="example-tree-container-child" [style.borderColor]="node.bordercolor">
                                            <div class="mat-tree-node" (mousedown)="onSetColor(node)"
                                                [style.borderLeft]="'14px solid' + node.color">
                                                <button mat-button class="mat-tree-child-last">
                                                    {{node.name}}
                                                </button>
                                                <button class="popOverButton" [matMenuTriggerFor]="childSpMenu">
                                                    <mat-icon>menu</mat-icon>
                                                </button>
                                                <mat-menu #childSpMenu="matMenu">
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'add')"
                                                        matTooltip="Add menu" mat-icon-button>
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="addEditMenu(node, 'edit')"
                                                        matTooltip="Edit menu" mat-icon-button>
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" (click)="deleteMenu(node)"
                                                        mat-icon-button matTooltip="Delete menu">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </li>
                                    </mat-nested-tree-node>
                                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                                        <li class="example-tree-container" [style.borderColor]="node.bordercolor">
                                            <div class="mat-tree-node menu-item">
                                                <button mat-button matTreeNodeToggle
                                                    [attr.aria-label]="'toggle ' + node.name" class="mat-tree-button"
                                                    [style.borderLeft]="'14px solid' + node.color"
                                                    (mousedown)="onSetColor(node)">
                                                    {{node.name}}
                                                    <mat-icon class="mat-icon-rtl-mirror float-left">
                                                        {{adminTreeControl.isExpanded(node) ? 'expand_more' :
                                                        'chevron_right'}}
                                                    </mat-icon>
                                                </button>
                                                <button class="popOverButton" [matMenuTriggerFor]="parentSpMenu">
                                                    <mat-icon>menu</mat-icon>
                                                </button>
                                                <mat-menu #parentSpMenu="matMenu">
                                                    <button class="mat-menu-btn" class="mat-menu-btn"
                                                        (click)="addEditMenu(node, 'add')" matTooltip="Add menu"
                                                        mat-icon-button>
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" class="mat-menu-btn"
                                                        (click)="addEditMenu(node, 'edit')" matTooltip="Edit menu"
                                                        mat-icon-button>
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <button class="mat-menu-btn" class="mat-menu-btn"
                                                        (click)="deleteMenu(node)" mat-icon-button
                                                        matTooltip="Delete menu">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                            <ul class="example-tree-nested-node" [style.borderColor]="node.bordercolor">
                                                <div *ngIf="adminTreeControl.isExpanded(node)">
                                                    <ng-container matTreeNodeOutlet></ng-container>
                                                </div>
                                            </ul>
                                        </li>
                                    </mat-nested-tree-node>
                                </mat-tree>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Security Group Permission">
                <ngx-security-group-permission *ngIf="selectedIndex == 1"></ngx-security-group-permission>
            </mat-tab>
            <mat-tab label="Security Groups List">
                <ngx-security-groups-list *ngIf="selectedIndex == 2"></ngx-security-groups-list>
            </mat-tab>
        </mat-tab-group>
    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button *ngIf="selectedIndex == 2" mat-button class="btn btn-primary btnColor btn-sm m-2"
            (click)="addNewGroup()">Add</button>
        <button *ngIf="selectedIndex > 0" mat-button class="btn btn-primary btn-sm btnColor m-2"
            (click)="changeStep('previous')">Previous</button>
        <button *ngIf="selectedIndex < 2" mat-button class="btn btn-primary btnColor btn-sm m-2"
            (click)="changeStep('next')">Next</button>
    </nb-card-footer>
</nb-card>