import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OfferingEtaService {

  constructor(
    private http: HttpClient,
  ) { }

  // get provider offering ETA
  getProviderServiceOfferingsETA(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderServiceOfferingsETA`, data);
  }

  // get provider offering ETA
  getProviderServiceOfferingsETAs(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerofferingetas/get`, data);
  }

  // update provider offering ETA
  updateProviderOfferingETA(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerofferingetas`, postData);
  }
}
