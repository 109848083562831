import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { forkJoin } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { CustomerUserDeailsDialogComponent } from 'src/app/dialogs/customer-user-deails-dialog/customer-user-deails-dialog.component';
import { CustomerInviteProcessComponent } from 'src/app/dialogs/customer-invite-process/customer-invite-process.component';

@Component({
  selector: 'app-customer-users-list',
  templateUrl: './customer-users-list.component.html',
  styleUrls: ['./customer-users-list.component.scss']
})
export class CustomerUsersListComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  @Input() customerInputKey = '';
  users: any = [];
  userToInvite: User;
  loading = false;
  userTypeKey: any;
  customerKey: string;
  isLoggedInUser: boolean;
  userKey = sessionStorage.getItem('userKey');
  @Input() customerAdmin: boolean;
  searchUser: UntypedFormGroup;
  securityGroupData: any;
  userStatus: any;

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
    public formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.userToInvite = new User();
  }

  ngOnInit() {
    this.searchUser = this.formBuilder.group({
      name: [null],
      firstName: [null],
      lastName: [null],
      securityGroup: [null],
      isEmergencyApprover: [null],
      isApprover: [null],
      userStatusKey: [null]
    });
    if (this.customerInputKey && this.customerInputKey !== '') {
      this.isLoggedInUser = false;
      this.customerKey = this.customerInputKey;
    } else {
      this.isLoggedInUser = true;
      this.customerKey = sessionStorage.getItem('customerKey');
    }

    this.getAllDropDowns();
    this.getUserStatus();
  }

  getAllDropDowns() {
    const securityGroupFilter = {
      userTypeKey: '90dc6b51-9731-439b-bd21-40685f989f24',
      deleted: false
    };
    const securityGroup = this.usersService.getSecurityGroupByPlatform(securityGroupFilter);
    const userTypes: any = this.usersService.getUserTypes({ description: 'Customer' });
    this.loading = true;
    forkJoin([securityGroup, userTypes]).subscribe(results => {
      this.securityGroupData = results[0]['body'] || [];
      this.userTypeKey = results[1]['body'][0].userTypeKey || [];
      this.loading = false;
      this.getUsers();
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getUserStatus() {
    if (sessionStorage.getItem('userStatus') == null || sessionStorage.getItem('userStatus') === '[]') {
      const filter = { association: 'user' };
      this.commonService.getUserStatuses().subscribe(result => {
        this.userStatus = result.body;
        sessionStorage.setItem('userStatus', JSON.stringify(result.body));
      });
    } else {
      this.userStatus = JSON.parse(sessionStorage.getItem('userStatus'));
    }
  }

  getUsers = () => {

    // Get users
    this.loading = true;
    const filter = this.searchUser.value;
    filter.associationKey = this.customerKey;
    filter.userTypeKey = this.userTypeKey;
    if (filter.name) {
      filter.firstName = filter.name;
      filter.lastName = filter.name;
    }

    filter.subDomainKey = (sessionStorage.getItem('userSubDomainKey') && sessionStorage.getItem('userSubDomainKey') !== 'null') ?
      sessionStorage.getItem('userSubDomainKey') : null;

    this.usersService.getUserLists(filter).subscribe((res: any) => {
      this.users = res.body;
      if (this.isLoggedInUser) {
        this.users = this.users.filter(item => item.userKey !== this.userKey);
      }
      this.onSort({ column: 'firstName', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  openEditUserDialog(value, objUserDetail: any) {
    if (this.customerAdmin) {
      objUserDetail.customerAdmin = this.customerAdmin;
    }
    const dialogRef = this.dialog.open(CustomerUserDeailsDialogComponent, {
      width: '90%',
      disableClose: true,
      position: {
        top: '4%',
      },
      data: objUserDetail
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getUsers();
    });
  }

  OpenInviteUserDialog(value) {
    this.dialog.open(CustomerInviteProcessComponent, {
      maxWidth: '95%',
      maxHeight: '95%',
      position: { top: '1%' },
      data: { value, customerInputKey: this.customerInputKey }
    });
  }

  nextPage() {
    this.utilityService.saveNextRoute();
    this.router.navigate([sessionStorage.getItem('nextPageLink')], { relativeTo: this.route });
  }

  onSort({ column, direction }: SortEvent) {
    this.users = this.utilityService.sortData(this.headers, column, direction, this.users);
  }

  clearFilters() {
    this.searchUser.reset();
    this.getUsers();
  }
}
