import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    transform(rawNum:string) {

        if (/^\d+$/.test(rawNum))
        {
          if(rawNum.length == 10)
          {
            rawNum = "+1"+ rawNum;
        
            const countryCodeStr = rawNum.slice(0,2);
            const areaCodeStr = rawNum.slice(2,5);
            const midSectionStr = rawNum.slice(5,8);
            const lastSectionStr = rawNum.slice(8);
        
  //          return `${countryCodeStr} (${areaCodeStr})${midSectionStr}-${lastSectionStr}`;
            return rawNum;
          }
          else
          {
            return rawNum;
          }
        }
        else
        {
          return rawNum;
        } 
      }
}
