<app-loader [loading]="loading"></app-loader>
<nb-card>
    <nb-card-body>
    <div class="m-2">    

        <!-- <div class="row">
            <div class="col-md-12">
                <div class="table-responsive mt-2">
                    <ngx-smart-table [settings]="settings" [source]="notes" (deleteConfirm)="deleteNote($event)">
                    </ngx-smart-table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="col-md-12 text-left" *ngIf="providerDetails.providerAccountKey">
                    <button class="btn btn-primary btn-sm" (click)="addNotes()">Add Note</button>
                </div>
            </div>
        </div>
        
 

        <br> -->
        <br>

        <!-- <div class="table-responsive mt-2">
            <table matSort class="table table-bordered rates">
                <thead class="text-center">
                    <tr>
                        <th mat-sort-header="insertedTimestamp" sortable="insertedTimestamp" (sort)="onSort($event)" direction="asc"> Date & Time 
                        </th>
                        <th> Note </th>
                        <th> Added By </th>
                        <th> Actions </th>
                    </tr>
                </thead>
                <tbody *ngIf="notesData && notesData.length > 0; else noData">
                    <tr *ngFor="let notes of notesData; let i = index;">
                        <td> {{notes.insertedTimestamp}} </td>
                        <td [innerHtml]='notes.note'></td>
                        <td> {{notes.userName}} </td>
                       
                        <td  class="text-center">
                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                            (click)="deleteNote2(notes)"
                                >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="6" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="col-md-12 text-left" *ngIf="providerDetails.providerAccountKey">
                    <button class="btn btn-primary btn-sm" (click)="addNotes()">Add Note</button>
                </div>
            </div>
        </div>

        <br>
        <br> -->
        <div class="row">
            <label class="col-12"><strong>Provider Notes</strong></label>
        </div> 

        <div class="example-container border" >
            <table mat-table [dataSource]="dataSource" matSort matSortActive="insertedTimestamp" matSortDirection="desc">
            <ng-container mat-header-cell matColumnDef="insertedTimestamp">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;" mat-sort-header> Date Added </th>
                <td mat-cell *matCellDef="let element"> {{element.insertedTimestamp | date:'mediumDate'}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef style="width: 70%;"> Note </th>
                <td mat-cell *matCellDef="let element" [innerHtml]='element.note'>  </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Added By </th>
                <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
            </ng-container>
            <ng-container mat-header-cell matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Action </th>
                <td mat-cell *matCellDef="let element" class="text-center"> 
                    <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                    (click)="deleteNote2(element)"
                        >
                        <i class="fas fa-trash-alt"></i>
                    </button>
                    <!-- <button mat-icon-button>
                    <mat-icon>delete</mat-icon> -->
                <!-- </button> -->
            </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="lineForBottomOfTable"></div>
            <div class="row">
                <div class="col-2 new-note-button">
                    <button class="btn btn-primary btn-sm" (click)="addNotes()">
                    Add New Note
                    </button>
                </div>
                <div>
                    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
            <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator> -->
        </div>     
   
        
        <ng-template #noRecord>
            <div class="example-container border text-center p-2"> No Data available </div>
        </ng-template>

        <br> <br>
        <!-- <div class="row">
            <div class="col-md-12">
                [Dispaly any past file uploads here]
            </div>
        </div> -->

        <div class="table-responsive mt-2">
            <table matSort class="table table-bordered rates">
                <thead class="text-center">
                    <tr>
                        <!-- <th mat-sort-header="insertedTimestamp" sortable="insertedTimestamp" (sort)="onSort($event)" direction="asc"> Date & Time 
                        </th> -->
                        <th> File Name </th>
                         <th> Attached File </th>
                        <th> Purpose </th>
                       
                        <th> Actions </th>
                    </tr>
                </thead>
                <tbody *ngIf="filesData && filesData.length > 0; else noData">
                    <tr *ngFor="let files of filesData; let i = index;">
                        <td> {{files.PATvcLabel}} </td>
                        <!-- <td [innerHtml]='notes.note'></td> -->
                        <td> <a href="{{files.PATvcPath}}" target="_blank">{{files.PATvcPath}} </a> </td>

                        <td *ngIf="files.FileType ">{{files.FileType}}</td>
                        <td *ngIf="!files.FileType ">Unknown </td>
                       
                        <td  class="text-center">
                            <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                            (click)="deleteAttachments(files)"
                                >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="6" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

        <div class="row">
            <div class="col-md-12 text-left">
                <button (click)="addAttachment()" class="btn btn-primary btn-sm">Upload Attachments</button>
            </div>
        </div>

    </div>     
    </nb-card-body>
</nb-card>