import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CheckBoxService {

  public checkBoxData = new Subject<any>();
  public checkBoxLoader = new Subject<any>();
  public checkBoxNetworkData: any;

  constructor() { }

  setCheckBoxData() {
    this.checkBoxData.next();
  }

  getCheckBoxData(): Observable<any> {
    return this.checkBoxData.asObservable();
  }

  setCheckBoxNetworkData(checkBoxNetworkData: any) {
    this.checkBoxNetworkData = checkBoxNetworkData;
  }

  getCheckBoxNetworkData() {
    return this.checkBoxNetworkData;
  }

  setCheckBoxLoader(message: boolean) {
    this.checkBoxLoader.next(message);
  }
}
