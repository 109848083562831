<div class="modal-header">
    <h6 class="modal-title">Holiday Schedule</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="w3-panel">
        <label>
            Add Holidays for which your business will be closed or open other than normal business hours.
            <br>
            No entry will be interpreted as open with normal business hours.
        </label>
    </div>
    <form [formGroup]="holidayForm">
        <div *ngIf="loading" id="preloader">
            <div id="loader"></div>
        </div>
        <div class="row noMargin">
            <div class="col-sm-12 col-md-4">
                <mat-form-field>
                    <mat-select formControlName="holidayKey" matNativeControl placeholder="This Year/All"
                        (selectionChange)="holidayKeyChanged()" [disabled]="isEdit == 1" required>
                        <mat-option value="EveryYear">All</mat-option>
                        <mat-option value="ThisYear">This Year</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="holidayForm.controls.holidayKey.touched && holidayForm.controls.holidayKey.errors">
                        <span *ngIf="holidayForm.controls.holidayKey.errors.required">This Year/All is
                            required.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field>
                    <mat-select matNativeControl placeholder="Day Of Observance" formControlName="holidayOccuranceKey"
                        (selectionChange)="observanceChanged()" [disabled]="isEdit == 1" required>
                        <mat-option *ngFor="let day of dayOfObservanceData" [value]="day.holidayKey">
                            {{day.holidayKey}}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="holidayForm.controls.holidayOccuranceKey.touched && holidayForm.controls.holidayOccuranceKey.errors">
                        <span *ngIf="holidayForm.controls.holidayOccuranceKey.errors.required">Day Of Observance is
                            required.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field>
                    <mat-select (selectionChange)="showTime()" formControlName="closed" matNativeControl
                        placeholder="Closed" required>
                        <mat-option value='false'>No</mat-option>
                        <mat-option value='true'>Yes</mat-option>
                    </mat-select>
                    <mat-error *ngIf="holidayForm.controls.closed.touched && holidayForm.controls.closed.errors">
                        <span *ngIf="holidayForm.controls.closed.errors.required">Closed is required.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="showTimeFlag" class="col-sm-12 col-md-4">
                <mat-form-field>
                    <input matInput placeholder="From" formControlName="openTime" [ngxMatDatetimePicker]="fromPicker" required>
                    <ngx-mat-timepicker #fromPicker formControlName="openTime"
                    [(ngModel)]="date"
                    [disabled]="disabled" [showSpinners]="showSpinners"
                    [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [showSeconds]="showSeconds"
                    [color]="color" [enableMeridian]="enableMeridian">

                    </ngx-mat-timepicker>
                    <mat-error *ngIf="holidayForm.controls.openTime.touched && holidayForm.controls.openTime.errors">
                        <span *ngIf="holidayForm.controls.openTime.errors.required">From time is required.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="showTimeFlag" class="col-sm-12 col-md-4">
                <mat-form-field>
                    <input matInput placeholder="To" formControlName="closeTime" [ngxMatDatetimePicker]="fromPicker" required>
                    <ngx-mat-timepicker #fromPicker
                    [disabled]="disabled" 
                    [showSpinners]="showSpinners"
                    [stepHour]="stepHour" [stepMinute]="stepMinute" 
                    [stepSecond]="stepSecond" 
                    [showSeconds]="showSeconds">
                    >
                    </ngx-mat-timepicker>
                    <mat-error *ngIf="holidayForm.controls.closeTime.touched && holidayForm.controls.closeTime.errors">
                        <span *ngIf="holidayForm.controls.closeTime.errors.required">To time is required.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="holidayForm.controls.holidayOccuranceKey.value == 'Misc'" class="col-sm-12 col-md-4">
                <mat-form-field>
                    <input matInput formControlName="holidayOccuranceDate" [matDatepicker]="holidayOccuranceDate"
                        placeholder="Enter the date for this Misc holiday" (focus)="holidayOccuranceDate.open()"
                        [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="holidayOccuranceDate"></mat-datepicker-toggle>
                    <mat-datepicker #holidayOccuranceDate></mat-datepicker>
                    <mat-error
                        *ngIf="holidayForm.controls.holidayOccuranceDate.touched && holidayForm.controls.holidayOccuranceDate.errors">
                        <span *ngIf="holidayForm.controls.holidayOccuranceDate.errors.required">Date is
                            required.</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row noMargin mb-2">
            <div class="col-sm-12">
                <button *ngIf="isEdit" class="btn btn-primary btn-sm floatR ml-2" type="button"
                    (click)="cancelEdit()">Cancel</button>
                <button class="btn btn-primary btn-sm floatR" type="button" (click)="saveHolidayHours()"
                    [disabled]="isMasterLicensedOwner">{{buttonText}}</button>
            </div>
        </div>
    </form>

    <div class="tblHoursOfoperation">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th sortable="holidayKey" (sort)="onSort($event)" direction="asc">This Year/All</th>
                    <th sortable="holidayOccuranceKey" (sort)="onSort($event)" direction="asc">Day Of Observance</th>
                    <th>Date</th>
                    <th class="text-center">Closed</th>
                    <th>Open From</th>
                    <th>Open To</th>
                    <th class="text-center">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let providerHolidayHour of providerHolidayHours">
                    <td>{{providerHolidayHour.holidayKey}}</td>
                    <td>{{providerHolidayHour.holidayOccuranceKey}}</td>
                    <td>
                        <span *ngIf="providerHolidayHour.holidayKey =='EveryYear'">
                            {{ providerHolidayHour.holidayOccuranceDate | date: 'MMM dd':'GMT' }}
                        </span>
                        <span *ngIf="providerHolidayHour.holidayKey !='EveryYear'">
                            {{ providerHolidayHour.holidayOccuranceDate | date: 'MMM dd, yyyy':'GMT' }}
                        </span>
                    </td>
                    <td class="text-center">
                        {{ providerHolidayHour.closed ? 'No': 'Yes' }}
                        <!-- <button *ngIf="providerHolidayHour.closed" class="btn cursor-default rounded-circle btn-md">
                            <i class="fa fa-times-circle text-danger"></i>
                        </button>
                        <button *ngIf="!providerHolidayHour.closed" class="btn cursor-default rounded-circle btn-md">
                            <i class="fa fa-check-circle text-success"></i>
                        </button> -->
                    </td>
                    <td>
                        {{providerHolidayHour.openTime}}
                    </td>
                    <td>
                        {{providerHolidayHour.closeTime}}
                    </td>
                    <td class="text-center">
                        <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Edit"
                            (click)="editHolidayHoursOfOperation(providerHolidayHour)"
                            [disabled]="isMasterLicensedOwner">
                            <i class="fas fa-pencil"></i>
                        </button>
                        <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                            (click)="deleteProviderHolidayHours(providerHolidayHour)"
                            [disabled]="isMasterLicensedOwner">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-primary btn-md mr-2" type="button">Save</button> -->
</div>