import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { ProviderDiscountLineitemDialogComponent } from 'src/app/dialogs/provider-discount-lineitem-dialog/provider-discount-lineitem-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewServiceExclusionListDialogComponent } from 'src/app/dialogs/view-service-exclusion-list-dialog/view-service-exclusion-list-dialog.component';

@Component({
  selector: 'app-provider-admin-discount',
  templateUrl: './provider-admin-discount.component.html',
  styleUrls: ['./provider-admin-discount.component.scss']
})
export class ProviderAdminDiscountComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  isAddRow: boolean = false;
  disableEdit: boolean = false;
  licensedOwnerName: string = 'TTN Fleet Solutions';
  loading: boolean = false;
  licensedOwnerDiscount: any = [];
  tempLicensedOwnerDiscount: any = [];
  discountTypes: any;
  licensedOwnerDetails: any;
  discountForm: UntypedFormGroup;
  tempPricing: any = [];
  licensedOwnerKey: string = sessionStorage.getItem('licensedOwnerKey');
  searchString: string;
  lineItemButtonText: string;
  serviceExceptionButtonText: string;
  customDiscountButtonText: string;
  @Input() isEdit: boolean;
  @Input() isDiscountEditable = false;
  @Input() providerDetails;
  dataDictionaryKey = '1ee5540d-560e-418d-bd51-3089900472fe';
  schema = 'FleetAssist';
  subServices: any;
  assembly: any;

  constructor(
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    private dialogService: NbDialogService,
    private utilityService: UtilityService,
    public dialog: MatDialog) { }

  ngOnChanges() {
    this.discountForm = this.formBuilder.group({
      licensedOwnerOfferingKey: ['', [Validators.required]],
      subServicesKey: [''],
      assembly: [''],
      discountTypeKey: ['', [Validators.required]],
      discountAmount: ['', [Validators.required]],
      notes: ['', [Validators.required]]
    });

    if (this.isEdit) {
      this.lineItemButtonText = 'Edit Line Item Exceptions';
    } else {
      this.lineItemButtonText = 'Line Item Exceptions';
    }

    if (this.isDiscountEditable || this.providerDetails.providerAccountKey) {
      this.getLicensedownerofferings();
      this.getDiscountTypes();
      this.getLicensedOwners();
      this.getLicensedOwnerCustomDiscountDetails();
    }
  }

  ngOnInit() {
  }

  /****
  * Get sub services
  * @params postData
  * @return array response
  */
  getSubservice() {
    this.loading = true;
    const filter = {
      parentKey: this.discountForm.controls.licensedOwnerOfferingKey.value.networkOwnerOfferingKey,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      deleted: false
    };

    this.commonService.getLicensedOwnerServiceOfferingsDetails(filter).subscribe(subServiceResponse => {
      this.subServices = subServiceResponse.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /****
  * Get assembly
  * @params postData
  * @return array response
  */
  getAssembly() {
    this.loading = true;
    const filter = {
      parentKey: this.discountForm.controls.subServicesKey.value.networkOwnerOfferingKey,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      deleted: false
    };

    this.commonService.getLicensedOwnerServiceOfferingsDetails(filter).subscribe(assemblyResponse => {
      this.assembly = assemblyResponse.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /****
  * Get Licensed Owner details
  * @params postData
  * @return array response
  */
  getLicensedOwners() {

    this.loading = true;
    const postData = {
      licensedOwnerKey: this.licensedOwnerKey
    };

    this.commonService.getLicensedOwners(postData)
      .subscribe((response: any) => {
        this.licensedOwnerName = response.body[0].licensedOwnerName;
        this.loading = false;
      }, (error) => {
        console.log('error', error);
        this.loading = false;
      });
  }

  /****
  * Get Licensed Owner Discount details
  * @params postData
  * @return array response
  ***/
  getLicensedOwnerCustomDiscountDetails() {
    let postData = {
      licensedOwnerKey: this.licensedOwnerKey,
      providerKey: this.providerDetails.providerKey
    };
    this.loading = true;
    this.commonService.getLicensedOwnerCustomDiscountDetails(postData)
    .subscribe((response: any) => {
      this.licensedOwnerDiscount = response.body;
      this.onSort({ column: 'Description', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  /****
  * Get Discount Types
  * @return array response
  ***/
  getDiscountTypes() {

    this.commonService.getDiscountTypes()
      .subscribe((response: any) => {
        this.discountTypes = response.body;
      }, (error) => {
        console.log('error', error);
      });
  }

  /****
  * Get Licensed owner parent details
  * @params postData optional
  * @return array response
  ***/
  getLicensedownerofferings() {

    this.commonService.getLicensedOwnerActiveServices(this.licensedOwnerKey)
      .subscribe((response: any) => {
        this.licensedOwnerDetails = response.body;
      }, (error) => {
        console.log('error', error);
      });
  }

  /****
  * Add Discount details
  * @params postData
  * @return array response
  ***/
  addDiscount() {
    if (this.discountForm.invalid) {
      this.discountForm.markAllAsTouched();
      return false;
    }

    // const OfferingKey = this.discountForm.value.assembly ||
    //   this.discountForm.value.subServicesKey.licensedOwnerOfferingKey ||
    //   this.discountForm.value.licensedOwnerOfferingKey.licensedOwnerOfferingKey;


      const OfferingKey = this.discountForm.value.licensedOwnerOfferingKey;


    const postParams = {
      discountAmount: this.discountForm.value.discountAmount,
      discountTypeKey: this.discountForm.value.discountTypeKey,
      notes: this.discountForm.value.notes,
      insertedUserKey: sessionStorage.getItem('userKey'),
      updatedUserKey: sessionStorage.getItem('userKey'),
      licensedOwnerKey: this.licensedOwnerKey,
      licensedOwnerOfferingKey: OfferingKey,
      lockedNegotiation: false,
      providerKey: this.providerDetails.providerKey,
      systemDefault: true,
      deleted: false,
      insertedTimestamp: new Date().getTime(),
      updatedTimestamp: new Date().getTime()
    };

    this.loading = true;
    this.commonService.addCustomDiscountDetails(postParams)
      .subscribe((response) => {
        if (response.success) {
          this.toast.success('Discount Added Successfully!', 'Success');
        } else {
          this.toast.warning(response.message[0], 'Error');
        }
        this.isAddRow = false;
        this.loading = false;
        this.discountForm.reset();
        this.getLicensedOwnerCustomDiscountDetails();
      }, (error) => {
        this.toast.warning(error.message, 'Error');
        this.loading = false;
      });
  }

  // edit discount
  editDiscount(event: any) {
    this.isAddRow = false;
    this.licensedOwnerDiscount.map((data, index) => {
      if (event.LicensedOwnerDiscountProgramKey === data.LicensedOwnerDiscountProgramKey) {
        this.tempPricing = { ...{}, ...this.licensedOwnerDiscount[index] };
        this.licensedOwnerDiscount[index].editMode = !this.licensedOwnerDiscount[index].editMode;
      }
    });
    this.disableEdit = !this.disableEdit;
  }

  /****
  * Update Discount details
  * @params postData
  * @return array response
  ***/
  updateCustomDiscountDetails(data: any) {
    if (!data.DiscountAmount || !data.DiscountTypeKey || !data.LicensedOwnerOfferingKey || !data.DiscountNote) {
      return false;
    }
    
    const postParams = {
      discountAmount: data.DiscountAmount,
      discountTypeKey: data.DiscountTypeKey,
      notes: data.Notes,
      insertedUserKey: sessionStorage.getItem('userKey'),
      updatedUserKey: sessionStorage.getItem('userKey'),
      licensedOwnerKey: this.licensedOwnerKey,
      licensedOwnerOfferingKey: data.LicensedOwnerOfferingKey,
      licensedOwnerDiscountProgramKey: data.LicensedOwnerDiscountProgramKey,
      lockedNegotiation: false,
      providerKey: this.providerDetails.providerKey,
      systemDefault: true,
      deleted: false
    };

    this.loading = true;
    this.commonService.updateCustomDiscountDetails(postParams)
      .subscribe((response) => {
        if (response.success) {
          this.toast.success('Discount Updated Successfully!', 'Success');
        } else {
          this.toast.warning(response.message[0], 'Error');
        }
        this.isAddRow = false;
        this.loading = false;
        this.disableEdit = !this.disableEdit;
        this.getLicensedOwnerCustomDiscountDetails();
      }, (error) => {
        this.toast.warning(error.message, 'Error');
        this.loading = false;
      });
  }

  deleteCustomDiscountDetails(data: any) {

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const postParams = {
          "discountAmount": data.DiscountAmount,
          "discountTypeKey": data.DiscountTypeKey,
          "insertedUserKey": sessionStorage.getItem('userKey'),
          "updatedUserKey": sessionStorage.getItem('userKey'),
          "licensedOwnerKey": this.licensedOwnerKey,
          "licensedOwnerOfferingKey": data.LicensedOwnerOfferingKey,
          "licensedOwnerDiscountProgramKey": data.LicensedOwnerDiscountProgramKey,
          "lockedNegotiation": false,
          "providerKey": this.providerDetails.providerKey,
          "systemDefault": true,
          "deleted": true
        };

        this.loading = true;
        this.commonService.updateCustomDiscountDetails(postParams)
          .subscribe((response) => {
            if (response.success) {
              this.toast.success('Discount details deleted Successfully!', 'Success');
            } else {
              this.toast.warning(response.message[0], 'Error');
            }
            this.loading = false;
            this.getLicensedOwnerCustomDiscountDetails();
          }, (error) => {
            this.toast.warning(error.message, 'Error');
            this.loading = false;
          });
      }
    });
  }

  // Cancel update discount details
  cancelDiscount(event: any) {
    this.disableEdit = !this.disableEdit;
    this.licensedOwnerDiscount.map((data, index) => {
      if (event.LicensedOwnerDiscountProgramKey === data.LicensedOwnerDiscountProgramKey) {
        this.licensedOwnerDiscount[index].editMode = !this.licensedOwnerDiscount[index].editMode;
      }
    });

    this.getLicensedOwnerCustomDiscountDetails();
  }

  // Cancel add discount
  cancelAddDiscount() {
    this.isAddRow = false;
    this.discountForm.reset();
  }

  addRow() {
    this.isAddRow = true;
  }

  search(event) {
    this.searchString = event;
  }

  onSort({ column, direction }: SortEvent) {
    this.licensedOwnerDiscount = this.utilityService.sortData(this.headers, column, direction, this.licensedOwnerDiscount);
  }

  openLineItemExclusion() {
    const dialogRef = this.dialog.open(ProviderDiscountLineitemDialogComponent, {
      width: '40%',
      disableClose: true,
      position: {
        top: '4%'
      },
      data: this.isEdit
    });
  }

  openServiceExclusionList() {
    this.dialog.open(ViewServiceExclusionListDialogComponent, {
      maxHeight: '80%',
      width: '75%',
      hasBackdrop: false,
      position: {
        top: '8%',
      },
      data: this.isEdit
    });
  }
}
