<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<form [formGroup]="billingDetailsForm">
    <div *ngIf="(isParent) || (!isParent && !hasCentralBilling)">
    <div class="row text-left">
        <!-- <label class="col-12"><strong>How do you want to be contacted for billing?</strong></label> -->
        <label class="col-12"><strong>Billing Contact Details</strong></label>

    </div>
    <br>       

    <div class="row mt-3" *ngIf="(isParent) || (!hasCentralBilling && !isParent)">
        <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-label>Billing Email</mat-label>
                <input matInput placeholder="Billing Dedicated Email" formControlName="billingEmail">
                <mat-error *ngIf="handleError('billingEmail', 'required')">
                    Billing Email is required.
                </mat-error>
                <mat-error *ngIf="handleError('billingEmail', 'pattern')">
                    Enter valid Billing Email.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-label>Billing Phone</mat-label>
                <input matInput mask="000-000-0000" placeholder="Billing Phone" formControlName="billingPhone">
                <span *ngIf="billingDetailsForm.controls.billingPhone.value != '' && billingDetailsForm.controls.billingPhone.value != null " matTextPrefix>+1&nbsp;</span>
                <mat-error *ngIf="handleError('billingPhone', 'required')">
                    Billing Phone is required.
                </mat-error>
                <mat-error *ngIf="handleError('billingPhone', 'pattern')">
                    Enter valid Billing Phone.
                </mat-error>
            </mat-form-field>
        </div>
        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <input matInput mask="000-000-0000" placeholder="Billing Dedicated Fax" formControlName="billingFax">
                <span *ngIf="billingDetailsForm.controls.billingFax.value != '' && billingDetailsForm.controls.billingFax.value != null " matTextPrefix>+1&nbsp;</span>
                <mat-error *ngIf="handleError('billingFax', 'pattern')">
                    Enter valid Billing Dedicated Fax.
                </mat-error>
            </mat-form-field>
        </div> -->
        <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-label>Primary Billing Contact</mat-label>
                <input matInput placeholder="Primary Billing Contact" formControlName="primaryBillingContact">
                <!-- <mat-error *ngIf="handleError('primaryBillingContact', 'required')">
                    Primary Billing Contact is required.
                </mat-error> -->
            </mat-form-field>
        </div>
        <!-- <div class="col-sm-12 col-md-6 col-lg-3"
            *ngIf='!showSaveNext && ((!hasCentralBilling && isParent) || (hasCentralBilling && !isParent))'>
            <mat-form-field>
                <mat-select formControlName="paymentTermKey" matNativeControl placeholder="Payment Term">
                    <mat-option [(value)]="term.paymentTermKey" *ngFor="let term of paymentTerms">
                        {{ term.paymentTermKey }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <!-- <div class="col-sm-12 col-md-6 col-lg-3">
            <mat-form-field>
                <mat-select formControlName="paymentMethodKey" matNativeControl placeholder="Payment Method">
                    <mat-option [(value)]="method.paymentMethodKey" *ngFor="let method of paymentMethods">
                        {{ method.paymentMethodKey }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
    </div>
    <div *ngIf="(isParent)" class="row mt-3">
        <div>
            <mat-checkbox class="ml-3 d-inline" [checked] (change)="showOptions()"
                formControlName="hasCentralBilling">
                <strong>Central Billing</strong>
            </mat-checkbox>
            <button disabled class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Central billing sets this corporate billing details for all sublocations">
                <i class="fas fa-circle-question"></i>
            </button>
        </div>
    </div>
    </div>

    <div *ngIf="(!isParent && hasCentralBilling)">
        <div  class="paddingBottom">
            <mat-checkbox disabled checked="true">
                <strong>Enrolled in Central Billing</strong>
            </mat-checkbox>
        </div>
    </div>

</form>

<div *ngIf="showBack" class="card-footer" [ngClass]="{'card-footer-width': sideBarStatus}">
<div class="text-right">
        <button mat-button *ngIf="showBack" class="btn btn-orange btn-sm mr-1" (click)="previousPage()" >Back</button>
        <button mat-button *ngIf="showSave" class="btn btn-orange btn-sm ml-1 mr-1"
        (click)="updateProviderBillingDetails()">Save</button>
        <button mat-button *ngIf="showSaveNext" class="btn btn-orange btn-sm ml-1" (click)="saveNext()">Save &
        Next</button>
    </div>
</div>
