import { BreakdownProvider } from '../../models/breakdown-providers.model';
import { BreakdownDeletedProvider } from '../../models/breakdown-deleted-providers.model';
import { BreakDownProvidersService } from '../../services/breakdown-providers.service';
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { Component, Inject, OnInit, EventEmitter, Input, Output, OnChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, UntypedFormBuilder,ReactiveFormsModule, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { ProviderService } from 'src/app/services/provider.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ngx-deleted-provider-applications',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatRadioModule, MatDialogModule, MatButtonModule, FormsModule, MatDialogModule,
    MatInputModule, ReactiveFormsModule, SharedModule, MatSnackBarModule, MatCheckboxModule, MatPaginatorModule,
    MatSortModule, MatMenuModule, MatIconModule],
  templateUrl: './deleted-provider-applications.component.html',
  styleUrls: ['./deleted-provider-applications.component.scss']
})
export class DeletedProviderApplicationsComponent {

  displayedColumns: string[] = ['insertedTimestamp', 'companyName','address', 'deletedReason'];
  // 'phone', 'email',  'action' 'status',
  dataSource: MatTableDataSource<BreakdownDeletedProvider>;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort , { static: true }) sort: MatSort;

  constructor(
    private breakDownProvidersService: BreakDownProvidersService,
    private providerService: ProviderService,
    public dialog: MatDialog,
    private providerAdminService : ProviderAdminService,
    public dialogRef: MatDialogRef<DeletedProviderApplicationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BreakdownDeletedProvider,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getDeletedApplications()
  }

  getDeletedApplications(): void {

    const obj = new BreakdownDeletedProvider();
    this.breakDownProvidersService.getDeletedProvider(obj)
      .subscribe((providers) => {
        this.dataSource = new MatTableDataSource(providers);
        //this.allProviderApplications = providers;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort

        this.dataSource.data
        console.log(providers)
    
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
