import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NationalAccountService {

  constructor(private http: HttpClient) { }

  getNationalAccountDetails(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/nationalbillingaccounts/get`, filter);
  }

  getNationalAccountData(filter): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getNationalBillingAccounts`, filter);
  }

  insertNationalAccountDetails(insertNationalAccountData): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/nationalbillingaccounts`, insertNationalAccountData);
  }

  updateNationalAccountDetails(updateNationalAccountData): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/nationalbillingaccounts`, updateNationalAccountData);
  }

  getNationalAccountMaster(filter): Observable<any> {
    return this.http.post(`${environment.baseUrl}getNationalBillingAccounts`, filter);
  }

  getNationalAccountList(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}keyvaluepairs/nationalaccounts/get`, filter);
  }

  getProviderNationalAccounts(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providernationalaccounts/get`, data);
  }

  addNationalAccounts(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/providernationalaccounts`, data);
  }

  deleteNationalAccounts(data): Observable<any> {
    const json: any = {
      providerNationalAccountKey: data.providerNationalAccountKey,
    };
    const deleteNationalAccountsData: any = {
      body: json,
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providernationalaccounts`,
      deleteNationalAccountsData);
  }
}
