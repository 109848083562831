import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SystemSecuruityMenuService {

  securityGroupKey: string;

  constructor(private http: HttpClient) { }

  public getSystemSecurityMenu(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}systemsecurities/dynamicmenus/get`, data);
  }

  public addSystemSecurityMenu(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/dynamicmenus`, data);
  }

  public updateSystemSecurityMenu(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}systemsecurities/dynamicmenus/batch`, data);
  }

  public deleteSystemSecurityMenu(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}systemsecurities/dynamicmenus/batch`, data);
  }

  public getSecurityGroupList(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}keyvaluepairs/securitygroups/get`, data);
  }

  public addSecurityGroup(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}keyvaluepairs/securitygroups`, data);
  }

  public updateSecurityGroup(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}keyvaluepairs/securitygroups`, data);
  }

  getSecurityGroupKey() {
    return this.securityGroupKey;
  }

  setSecurityGroupKey(bubbleTree) {
    this.securityGroupKey = bubbleTree;
  }

  //Update Menu Permissions
  public getSecurityRolePolicies(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securityrolepolicies/get`, data);
  }

  public addSecurityRolePolicies(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securityrolepolicies`, data);
  }

  public batchAddSecurityRolePolicies(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securityrolepolicies/batch`, data);
  }

  public updateUserSecurityPermission(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}UpdateUserSecurityPermission`, data);
  }

  // public deleteUserSecurityPermission(data: any): Observable<any> {
  //   return this.http.delete(`${environment.apiUrl}systemsecurities/securitygrouppolicies`, data);
  // }

  public deleteUserSecurityPermission(data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}systemsecurities/securitygrouppolicies`, data);
  }

  public getSecurityGroupPolicies(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}getSPSecurityGroupPolicies`, data);
  }

  public getUserTypesByDescription(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}getSPUserTypesByDescription`, data);
  }

  public getUniquePlatforms(): Observable<any> {
    return this.http.get(`${environment.baseUrl}getSPUniquePlatforms`);
  }

  public getSecurityRoles(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securityroles/get`, data);
  }

  public addSecurityRoles(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securityroles`, data);
  }

  public updateSecurityRoles(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}systemsecurities/securityroles`, data);
  }

  public getSecurityPolicies(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}keyvaluepairs/securitypolicies/get`, data);
  }

  public updateSecurityRolePolicies(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}systemsecurities/securityrolepolicies`, data);
  }

  public batchUpdateSecurityRolePolicies(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}systemsecurities/securityrolepolicies/batch`, data);
  }

  public getSystemSecurityGroupList(): Observable<any> {
    return this.http.get(`${environment.baseUrl}getSystemSecurityGroupList`);
  }

  public insertSecurityGroupPoliciesbatch(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securitygrouppolicies/batch`, data);
  }

}
