import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-hierarchy-dialogs',
  templateUrl: './hierarchy-dialogs.component.html',
  styleUrls: ['./hierarchy-dialogs.component.scss']
})
export class HierarchyDialogsComponent implements OnInit {

  customerKey: string;
  customerHierarchy = false;
  eventCustomerHierarchy = new EventEmitter();

  constructor(
    public dialog: MatDialogRef<HierarchyDialogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data.customerKey) {
      this.customerKey = this.data.customerKey;
    }

    if (this.data.isCustomerHierarchy) {
      this.customerHierarchy = true;
    }
  }

  getCustomerKey(event) {
    this.eventCustomerHierarchy.emit(event);
    this.closeDialog();
  }

  closeDialog() {
    this.dialog.close();
  }

}
