export class ProviderInvites {
    public firstName: string;
    public lastName: string;
    public email: string;
    public cellPhone: string;
    public securityGroupKey: string;
    public associationKey: string;
    public userTypeKey: string;
    public deleted: boolean;
    public insertedUserKey: string;
    public systemDefault: boolean;
    public updatedUserKey: string;
    public emailDate: any;
    public emailResponseDate: string;
  }
