<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-header class="card-header">NAG Setup/Maintenance</nb-card-header>
    <nb-card-body>
        <div class="row mt-3">
            <div class="col-md-12">
                <button type="button" class="btn btn-primary btn-sm" (click)="addEditNag(false)">Add New NAG</button>
            </div>
            <div class="col-md-12">
                <div class="table-responsive table-responsive-sm custom-height mt-3">
                    <table class="table table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th class="align-middle">Provider Name</th>
                                <th class="align-middle">Service Group
                                </th>
                                <th class="align-middle">NAG User
                                </th>
                                <th class="align-middle">Status
                                </th>
                                <th class="align-middle text-center">Actions</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="nagDirectories.length>0; else noData">
                            <tr *ngFor="let item of nagDirectories">
                                <td>{{item.providerName}}</td>
                                <td>{{item.serviceGroupKey}}</td>
                                <td>{{item.userFullName}}</td>
                                <td>{{item.active ? 'Active' : 'Not Active'}}</td>
                                <td>
                                    <div class="row justify-content-center">
                                        <button class="btn btn-default btn-sm btnAnimation" nbTooltip="Edit"
                                            (click)="addEditNag(true,item)">
                                            <i class="fas fa-pencil"></i>
                                        </button>
                                        <button class="btn btn-default btn-sm btnAnimation" nbTooltip="Delete">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <tr>
                                    <td *ngIf="nagDirectories.length == 0" colspan="5" class="text-center">No Data
                                        available
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>