import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { EmailCommunicationTemplateComponent } from 'src/app/shared/email-communication-template/email-communication-template.component';
import { NbToastrService } from '@nebular/theme';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-communication-templates',
  templateUrl: './communication-templates.component.html',
  styleUrls: ['./communication-templates.component.scss']
})
export class CommunicationTemplatesComponent implements OnInit, OnChanges {

  @ViewChild(EmailCommunicationTemplateComponent, { static: true }) emailCommunicationTemplate: EmailCommunicationTemplateComponent;
  @Output() listChanged = new EventEmitter<boolean>();
  @Input() templateKey: string;
  @Input() associationKey: string;
  @Input() isCustomer = false;
  @Input() isProvider = false;

  loading = false;
  userInvitationKeys: any = [];
  customerKey: string;

  constructor(
    private toast: NbToastrService,
    private providerService: ProviderService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.customerKey = this.associationKey;
  }

  sendInvitations(userInvitationKeys) {
    this.userInvitationKeys = userInvitationKeys;
    if (this.userInvitationKeys.length > 0) {
      this.emailCommunicationTemplate.retriveEmailParams();
    } else {
      this.toast.success('All invitations already sent, no new pending invitation.', 'Success');
    }
  }

  sendEmails(emailDetails) {
    let inviteUsers: any;
    if (this.isProvider) {
      inviteUsers = {
        body: emailDetails.body,
        flag: 'Setup',
        from: emailDetails.sendingEmailAddress,
        invitationKey: this.userInvitationKeys,
        subject: emailDetails.subject,
        type: 'UserInvitationKey',
        userKey: sessionStorage.getItem('userKey')
      };
    } else {
      inviteUsers = {
        body: emailDetails.body,
        flag: 'Setup',
        from: emailDetails.sendingEmailAddress,
        invitationKey: this.userInvitationKeys,
        subject: emailDetails.subject,
        type: 'UserInvitationKey',
        referrer: 'LicensedOwner',
        userKey: sessionStorage.getItem('userKey')
      };
    }

    this.loading = true;
    this.providerService.sendInviteEmails(inviteUsers).subscribe(result => {
      this.loading = false;
      this.userInvitationKeys = [];
      this.listChanged.emit(true);
      if (result.success) {
        this.toast.success(result.message, 'Success');
      } else {
        this.toast.warning(result.message, 'Error');
      }
    }, (error) => {
      this.loading = false;
    });
  }

  isEmailParamsValid(): boolean {
    const isvalid = this.emailCommunicationTemplate.isEmailParamsValid();
    return isvalid;
  }

}
