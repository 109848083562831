<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
<nb-card-body>
<div class="row">
    <div class="col-md-9">
        <form [formGroup]="corporateDetailsForm">
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <div class="row">
                        <div class="col-12 col-md-9">
                            <div class="row">
                                <div class="col-3 py-4 pl-4">
                                    <label>
                                        <span>
                                            <span class="font-weight-bold">
                                                Provider ID:
                                            </span>
                                            {{providerId}}
                                        </span>
                                    </label>
                                </div>
                                <!-- <div class="providerParentClassBox pt-2 pb-4 pl-1">
                                    <div class="providerParentClassBox">
                                        <span class="providerParentLabel" [ngClass]="corporateDetailsForm.controls.tierLevel.value === 1 ? 'providerParentSelectedClass' : '' ">
                                            Parent Location
                                        </span>
                                        <span class="providerSubLabel" [ngClass]="corporateDetailsForm.controls.tierLevel.value > 1 ? 'providerParentSelectedClass' : '' ">
                                            Sublocation
                                        </span>
                                    </div>
                                </div> -->

                                <div *ngIf="providerDetails.isParent == true && companydetails" class="providerParentClassBox pt-2 pb-4 pr-1">
                                    <div class="providerParentClassBox">
                                        <span class="inNetworkBox inNetworkY" *ngIf="companydetails.inNetwork === true">
                                            In Network
                                        </span>
                                        <span class="inNetworkBox inNetworkN" *ngIf="companydetails.inNetwork === false" >
                                            Out Of Network
                                        </span>
                                    </div>
                                </div>
                                    <!-- <div class="col-md col-sm-12 pt-2 pb-4 pl-1">
                                        <mat-button-toggle-group>
                                        <mat-button-toggle
                                            [class]="corporateDetailsForm.controls.tierLevel.value === 1 ? 'providerParentToggleOn' : '' ">
                                            Parent Location
                                        </mat-button-toggle>
                                        <mat-button-toggle 
                                            [class]="corporateDetailsForm.controls.tierLevel.value > 1 ? 'providerParentToggleOn' : '' ">
                                            Sublocation
                                        </mat-button-toggle>
                                    </mat-button-toggle-group> 
                                </div> -->           
                            </div>
                            <div class="row">
                                    <div class="col-sm-12 col-md-12 pl-3 pr-3"
                                        [ngClass]="corporateDetailsForm.controls.name.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Company Name</mat-label>
                                            <input matInput placeholder="Company Name" [readonly]="isMasterLicensedOwner"
                                                formControlName="name" required autocomplete="off">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.name.touched && corporateDetailsForm.controls.name.errors">
                                                <span *ngIf="corporateDetailsForm.controls.name.errors.required">
                                                    Company Name is required.
                                                </span>
                                                <span *ngIf="corporateDetailsForm.controls.name.errors.pattern">
                                                    Enter valid Company Name.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- <div class="col-sm-12 col-md-4 pl-3 pr-1">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Corporate Structure</mat-label>
                                        <mat-select formControlName="corporateStructureKey" matNativeControl
                                            placeholder="Corporate Structure" [disabled]="isMasterLicensedOwner">
                                            <mat-option disabled="true">Select Option</mat-option>
                                            <mat-option *ngFor="let val of corpStructureList"
                                                [value]="val.corporateStructureKey">
                                                {{val.corporateStructureKey}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    </div> -->
                                <div class="col-sm-12 col-md-4 pl-3 pr-1">
                                    <mat-form-field appearance="outline">
                                        <mat-label>FEIN</mat-label>
                                        <input matInput placeholder="FEIN" formControlName="companyFEIN" (keyup)="FEINFormat($event)"
                                            autocomplete="off" [readonly]="isMasterLicensedOwner" minlength="10" maxlength="10">
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-sm-12 col-md-4 px-1">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Website</mat-label>
                                        <input matInput placeholder="Website" formControlName="website"
                                            autocomplete="off" [readonly]="isMasterLicensedOwner">
                                        <mat-error
                                            *ngIf="corporateDetailsForm.controls.website.touched && corporateDetailsForm.controls.website.errors">
                                            <span *ngIf="corporateDetailsForm.controls.website.errors.pattern">
                                                Enter valid Website.
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </div> -->
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12 col-md-6 pr-3 pl-3">
                                    <mat-checkbox formControlName="dispatchableTier"
                                        (change)="dispatchableTierChanged($event)" [disabled]="isMasterLicensedOwner">
                                        Location Can Dispatch 
                                    </mat-checkbox>
                                </div>
                                <!-- <div class="col-sm-12 col-md-6 pr-3 pl-1 mt-2"> -->
                                    <!-- <mat-slide-toggle formControlName="deleted">
                                        {{ corporateDetailsForm.controls.deleted.value ? 'Active' : 'Inactive' }}
                                    </mat-slide-toggle> -->
                                    <!-- <mat-checkbox formControlName="deleted" [disabled]="isMasterLicensedOwner">
                                        Location Is Active
                                    </mat-checkbox> -->
                                    <div class="col-sm-12 col-md-6 pr-3 pl-1">
                                        <mat-checkbox formControlName="tierCanSetRates" [disabled]="isMasterLicensedOwner">
                                            Location Can Set Rates
                                        </mat-checkbox>
                                    </div>
                                <!-- </div> -->
                                <div class="col-sm-12 col-md-6 pl-3 pr-1">
                                    <mat-checkbox formControlName="tierCanSetHours" [disabled]="isMasterLicensedOwner">
                                        Location Can Set Hours
                                    </mat-checkbox>
                                </div>
                              
                            </div>
                            <fieldset class="border m-0 position-relative p-2 mt-3">
                                <legend class="font-weight-bold p-1 mb-0 w-auto">
                                    <!-- <span> -->
                                    Mailing Address
                                    <!-- </span> -->
                                </legend>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 pl-3 pr-1"
                                        [ngClass]="corporateDetailsForm.controls.mailingAddress1.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input type="text" matInput placeholder="Mailing Address1"
                                                formControlName="mailingAddress1" autocomplete="off"
                                                ngx-google-places-autocomplete
                                                (onAddressChange)="getDetailedAddress($event,'mailing')"
                                                [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.mailingAddress1.touched && corporateDetailsForm.controls.mailingAddress1.errors">
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.mailingAddress1.errors.required">
                                                    Mailing Address is required.
                                                </span>
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.mailingAddress1.errors.pattern">
                                                    Enter valid Mailing Address.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-6 pr-4 pl-1"
                                        [ngClass]="corporateDetailsForm.controls.mailingAddress2.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Address2</mat-label>
                                            <input type="text" matInput placeholder="Mailing Address2"
                                                formControlName="mailingAddress2" autocomplete="off"
                                                ngx-google-places-autocomplete
                                                (onAddressChange)="getDetailedAddress($event,'mailing')"
                                                [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.mailingAddress2.touched && corporateDetailsForm.controls.mailingAddress1.errors">
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.mailingAddress2.errors.pattern">
                                                    Enter valid Mailing Address.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col-sm-12 col-md-4 pr-3 pl-1"
                                        [ngClass]="corporateDetailsForm.controls.mailingAddress3.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Address3</mat-label>
                                            <input type="text" matInput placeholder="Mailing Address3"
                                                formControlName="mailingAddress3" autocomplete="off"
                                                ngx-google-places-autocomplete
                                                (onAddressChange)="getDetailedAddress($event,'mailing')"
                                                [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.mailingAddress3.touched && corporateDetailsForm.controls.mailingAddress3.errors">
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.mailingAddress3.errors.pattern">
                                                    Enter valid Mailing Address.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col-sm-12 col-md-3 pr-1"
                                        [ngClass]="corporateDetailsForm.controls.mailingCity.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="City" formControlName="mailingCity"
                                                autocomplete="off" [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.mailingCity.touched && corporateDetailsForm.controls.mailingCity.errors">
                                                <span *ngIf="corporateDetailsForm.controls.mailingCity.errors.required">
                                                    City is required.
                                                </span>
                                                <span *ngIf="corporateDetailsForm.controls.mailingCity.errors.pattern">
                                                    Enter valid City.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3 px-1"
                                        [ngClass]="corporateDetailsForm.controls.mailingStateKey.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>State</mat-label>
                                            <input matInput placeholder="State" formControlName="mailingStateKey"
                                                autocomplete="off" [readonly]="isMasterLicensedOwner">
                                            <mat-error *ngIf="corporateDetailsForm.controls.mailingStateKey.errors">
                                                <span>
                                                    State is required.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-sm-12 col-md-3 pl-1"

                                    [ngClass]="corporateDetailsForm.controls.mailingPostalCode.errors ? 'mb-3': ''">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Zip code</mat-label>
                                        <input matInput placeholder="Zip code" formControlName="mailingPostalCode"
                                            autocomplete="off" [readonly]="isMasterLicensedOwner">
                                        <mat-error
                                            *ngIf="corporateDetailsForm.controls.mailingPostalCode.touched && corporateDetailsForm.controls.mailingPostalCode.errors">
                                            <span
                                                *ngIf="corporateDetailsForm.controls.mailingPostalCode.errors.required">
                                                Zip code is required.
                                            </span>
                                            <span
                                                *ngIf="corporateDetailsForm.controls.mailingPostalCode.errors.pattern">
                                                Enter valid Zip code.
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                            </fieldset>

                            <br>
                            
                            <fieldset class="border m-0 position-relative p-2 mt-3">
                                <legend class="font-weight-bold p-1 mb-0 w-auto">
                                    <!-- <span> -->
                                    Provider Metrics
                                    <!-- </span> -->
                                </legend>
                                <div class="row"> 
                                    <div class="col-sm-12 col-md-3 pr-1">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Work Orders Completed</mat-label>
                                        <input [value]="providerMetrics.CountOfWorkOrders" readonly matInput>
                                    </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3 px-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Paid Last 30 Days</mat-label>
                                            <input [value]="providerMetrics.Last30DayAmount" readonly matInput>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3 px-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Paid Last 180 Days</mat-label>
                                            <input [value]="providerMetrics.Last180DayAmount" readonly matInput>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3 pl-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Paid Last 365 Days</mat-label>
                                            <input [value]="providerMetrics.Last365DayAmount" readonly matInput>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </fieldset>   
                            
                            <br>
                            <form [formGroup]="contactDetailsForm">
                            <fieldset class="border m-0 position-relative p-2 mt-3">
                                <legend class="font-weight-bold p-1 mb-0 w-auto">
                                    Contact Information
                                </legend>

                                <div class="row">
                                    <div class="col-sm-12 col-md-4 pr-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Owner Name</mat-label>
                                            <input matInput placeholder="Owner Name" formControlName="OwnerFullName">
                                        </mat-form-field>
                                    </div>    
                                    <div class="col-sm-12 col-md-4 px-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Owner Phone</mat-label>
                                            <input matInput maxlength="12" placeholder="Owner Phone" formControlName="OwnerPhone">
                                            <mat-error
                                                *ngIf="contactDetailsForm.controls.OwnerPhone.touched && contactDetailsForm.controls.OwnerPhone.errors">
                                                <span *ngIf="contactDetailsForm.controls.OwnerPhone .errors.pattern"> Phone number needs +1 prefix</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-sm-12 col-md-4 pl-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Owner Email</mat-label>
                                            <input matInput placeholder="Owner Email" formControlName="OwnerEmail">
                                        </mat-form-field>
                                    </div> 

                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-4 pr-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>General Manager Name</mat-label>
                                            <input matInput placeholder="General Manager Name" formControlName="ManagerFullName">
                                        </mat-form-field>
                                    </div>    
                                    <div class="col-sm-12 col-md-4 px-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>General Manager Phone</mat-label>
                                            <input matInput maxlength="12" placeholder="General Manager Phone" formControlName="ManagerPhone">
                                            <mat-error
                                                *ngIf="contactDetailsForm.controls.ManagerPhone.touched && contactDetailsForm.controls.ManagerPhone.errors">
                                                <span *ngIf="contactDetailsForm.controls.ManagerPhone.errors.pattern"> Phone number needs +1 prefix</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div> 
                                    <div class="col-sm-12 col-md-4 pl-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>General Manager Email</mat-label>
                                            <input matInput placeholder="General Manager Email" formControlName="ManagerEmail">
                                        </mat-form-field>
                                    </div> 

                                </div>
                            </fieldset>
                            </form>
                            <!-- <fieldset class="border m-0 position-relative p-2 mt-3">
                                <legend class="font-weight-bold p-1 mb-0 w-auto">
                                    <span>
                                        Physical Address
                                    </span>
                                    <button class="btn btn-primary btn-sm ml-2" (click)=setPhysicalAddress()
                                        [disabled]="isMasterLicensedOwner">
                                        Same as Mailing Address?
                                    </button>
                                </legend>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 pr-1"
                                        [ngClass]="corporateDetailsForm.controls.physicalAddress1.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input type="text" matInput placeholder="Physical Address1"
                                                formControlName="physicalAddress1" autocomplete="off"
                                                ngx-google-places-autocomplete
                                                (onAddressChange)="getDetailedAddress($event,'physical')"
                                                [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.physicalAddress1.touched && corporateDetailsForm.controls.physicalAddress1.errors">
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.physicalAddress1.errors.required">
                                                    Physical Address is required.
                                                </span>
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.physicalAddress1.errors.pattern">
                                                    Enter valid Physical Address.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-6 pr-4 pl-1"
                                        [ngClass]="corporateDetailsForm.controls.physicalAddress2.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Address2</mat-label>
                                            <input type="text" matInput placeholder="Physical Address2"
                                                formControlName="physicalAddress2" autocomplete="off"
                                                ngx-google-places-autocomplete
                                                (onAddressChange)="getDetailedAddress($event,'physical')"
                                                [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.physicalAddress2.touched && corporateDetailsForm.controls.physicalAddress1.errors">
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.physicalAddress2.errors.pattern">
                                                    Enter valid Physical Address.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div> -->
                                    <!-- <div class="col-sm-12 col-md-4 pl-1"
                                        [ngClass]="corporateDetailsForm.controls.physicalAddress3.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Address3</mat-label>
                                            <input type="text" matInput placeholder="Physical Address3"
                                                formControlName="physicalAddress3" autocomplete="off"
                                                ngx-google-places-autocomplete
                                                (onAddressChange)="getDetailedAddress($event,'physical')"
                                                [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.physicalAddress3.touched && corporateDetailsForm.controls.physicalAddress3.errors">
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.physicalAddress3.errors.pattern">
                                                    Enter valid Physical Address.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div> -->
                                    <!-- <div class="col-sm-12 col-md-3 pr-1"
                                        [ngClass]="corporateDetailsForm.controls.physicalCity.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="City" formControlName="physicalCity"
                                                autocomplete="off" [readonly]="isMasterLicensedOwner">
                                            <mat-error
                                                *ngIf="corporateDetailsForm.controls.physicalCity.touched && corporateDetailsForm.controls.physicalCity.errors">
                                                <span
                                                    *ngIf="corporateDetailsForm.controls.physicalCity.errors.required">
                                                    City is required.
                                                </span>
                                                <span *ngIf="corporateDetailsForm.controls.physicalCity.errors.pattern">
                                                    Enter valid City.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3 px-1"
                                        [ngClass]="corporateDetailsForm.controls.physicalStateKey.errors ? 'mb-3': ''">
                                        <mat-form-field appearance="outline">
                                            <mat-label>State</mat-label>
                                            <input matInput placeholder="State" formControlName="physicalStateKey"
                                                autocomplete="off" [readonly]="isMasterLicensedOwner">
                                            <mat-error *ngIf="corporateDetailsForm.controls.physicalStateKey.errors">
                                                <span>
                                                    State is required.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3 pl-1"
                                    [ngClass]="corporateDetailsForm.controls.physicalPostalCode.errors ? 'mb-3': ''">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Zip Code</mat-label>
                                        <input matInput placeholder="Zip code" formControlName="physicalPostalCode"
                                            autocomplete="off" [readonly]="isMasterLicensedOwner">
                                        <mat-error
                                            *ngIf="corporateDetailsForm.controls.physicalPostalCode.touched && corporateDetailsForm.controls.physicalPostalCode.errors">
                                            <span
                                                *ngIf="corporateDetailsForm.controls.physicalPostalCode.errors.required">
                                                Zip code is required.
                                            </span>
                                            <span
                                                *ngIf="corporateDetailsForm.controls.physicalPostalCode.errors.pattern">
                                                Enter valid Zip code.
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">    
                                    <div class="col-sm-12 col-md-3 pr-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Latitude</mat-label>
                                            <input matInput placeholder="physical Latitude"
                                                formControlName="physicalLatitude" autocomplete="off"
                                                [readonly]="isMasterLicensedOwner">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3 px-1">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Longitude</mat-label>
                                            <input matInput placeholder="physical Longitude"
                                                formControlName="physicalLongitude" autocomplete="off"
                                                [readonly]="isMasterLicensedOwner">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </fieldset> -->
                        </div>
                        <div class="col-sm-12 col-md-3">
                            <!-- <div class="row mt-2">
                                <div class="col-sm-12">
                                    <div class="image-preview">
                                        <img class="w-100" [src]="imgURL" height="185" *ngIf="imgURL">
                                    </div>
                                    <label class="btn image-button d-block mt-2" *ngIf="!isMasterLicensedOwner">
                                        Change Company Logo
                                        <input type="file" accept='image/*' #file (change)="preview(file.files, $event)"
                                            class="uploadFile img" value="Upload Photo"
                                            style="width: 0px;height: 0px;overflow: hidden;">
                                    </label>
                                </div>
                            </div> -->

                            <div class="row mt-2">
                                <div class="col-sm-10 mt-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date Signed Up</mat-label>
                                        <input matInput placeholder="Date Signed Up" formControlName="insertedTimestamp"
                                            readonly>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-10 mt-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Last Update Date</mat-label>
                                        <input matInput formControlName="updatedTimestamp"
                                            placeholder="Last Update Date" readonly>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-10 mt-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Last Updated By</mat-label>
                                        <input matInput formControlName="updatedUserKey" placeholder="Last Updated By"
                                            readonly>
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-sm-2 mt-2 no-padding">
                                    <button class="btn btn-default btn-sm mt-2 btnAnimation"
                                        nbTooltip="View Update History" [disabled]="isMasterLicensedOwner">
                                        <i class="fas fa-info-circle"></i>
                                    </button>
                                </div> -->
                                <!-- <div class="col-sm-10 mt-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Year Business Started</mat-label>
                                        <input matInput formControlName="yearOfIncorporation"
                                            placeholder="Year Business Started" mask="0000"
                                            [readonly]="isMasterLicensedOwner">
                                    </mat-form-field>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12 col-md-8">
                    <div class="row bg-light text-dark ml-1">
                        <div class="col-sm-12 col-md-6 py-2 px-1">
                            <mat-form-field appearance="outline">
                                <!-- <input matInput placeholder="Owner or Primary Business Contact" formControlName="primaryContactUserKey"> -->
                                <mat-select formControlName="primaryContactUserKey" matNativeControl
                                    placeholder="Owner or Primary Business Contact" [disabled]="isMasterLicensedOwner">
                                    <mat-option disabled="true">Select User</mat-option>
                                    <mat-option *ngFor="let user of usersList" [value]="user.userKey">
                                        {{user.firstName}} {{user.lastName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 py-2 px-1">
                            <label>
                                For new products, investment opportunities or other strategic discussions
                            </label>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-12 col-md-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox formControlName="customerOwnedProvider" [disabled]="isMasterLicensedOwner">
                                <label>Customer dedicated location</label>
                            </mat-checkbox>
                        </div>
                        <div class="col-12 pl-2">
                            <mat-form-field appearance="outline">
                                <input matInput formControlName="customerName" placeholder="Dedicated Customer"
                                    autocomplete="off" readonly disabled>
                            </mat-form-field>
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </div>
    <div class="col-md-3 custom-height">
        <app-menulist [platForm]="'ServiceProvider'" [selectedProviderKey]="providerKey"
            [isMasterLicensedOwner]="isMasterLicensedOwner" [menuList]="childMenus"></app-menulist>
    </div>
</div>
<br>
<div class="row m-4">
    <div class="col-md-12 text-right">
        <button (click)="saveProfileChanges()" class="btn btn-primary btn-sm">Save Profile Changes</button>
    </div>
</div>
</nb-card-body>
</nb-card>