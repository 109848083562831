import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { patterns } from 'src/assets/patterns';
import { ProviderService } from 'src/app/services/provider.service';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { NbToastrService } from '@nebular/theme';
import { TemplateVariablesListDialogComponent } from 'src/app/dialogs/template-variables-list-dialog/template-variables-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-email-communication-template',
  templateUrl: './email-communication-template.component.html',
  styleUrls: ['./email-communication-template.component.scss']
})
export class EmailCommunicationTemplateComponent implements OnInit, OnChanges {

  @Input() templateKey: string;
  @Input() customerKey: string;
  @Input() isCustomer = false;
  @Output() isTemplateSaved = new EventEmitter<boolean>();
  @Output() emailParams = new EventEmitter<boolean>();
  @ViewChild('editor') editorComponent: CKEditorComponent;
  @Input() templateList = [];

  public Editor = ClassicEditor;
  loading = false;
  emailTemplateForm: UntypedFormGroup;
  emailTemplateData: any = [];
  tmpEmailTemplates: any = [];
  sendInviteKeys: any = [];
  updateProviderKeys: any = [];
  dataDictionaryKey = '168c347d-fb66-4733-9e6f-9442aa5915ee';
  schema = 'FleetAssist';
  missingEmailCount = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private providerService: ProviderService,
    private userInviteProcess: UserInviteProcess,
    private toast: NbToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.emailTemplateForm = this.formBuilder.group({
      communicationTemplateKey: [''],
      sendingEmailAddress: ['', Validators.required],
      subject: [''],
      body: [''],
    });
    if (this.templateKey) {
      this.getCommunicationTemplates();
    } else if (this.isCustomer) {
      if (this.customerKey) {
        // fetch data by customerKey
        this.getTemplateByUser();
      } else {
        this.getCommunicationTemplates();
      }
    }
  }

  public getEditor() {
    // Warning: This may return "undefined" if the editor is hidden behind the `*ngIf` directive or
    // if the editor is not fully initialised yet.
    return this.editorComponent.editorInstance;
  }

  getTemplateByUser() {
    const customerFilter = {
      associationKey: this.customerKey,
      communicationCategoryKey: 'User Invite',
      templateName: 'Customer User Invitation'
    };
    const licensedOwnerFilter = {
      associationKey: sessionStorage.getItem('licensedOwnerKey'),
      communicationCategoryKey: 'User Invite',
      templateName: 'Customer User Invitation'
    };

    this.loading = true;
    const dataByProviderKey = this.userInviteProcess.getCommunicationTemplateNames(customerFilter);
    const dataByLicenceOwnerKey = this.userInviteProcess.getCommunicationTemplateNames(licensedOwnerFilter);
    this.loading = true;
    forkJoin([dataByProviderKey, dataByLicenceOwnerKey]).subscribe(result => {
      const emailTemplateData = result[0].body.length > 0 ? result[0].body[0] : result[1].body[0];
      this.templateKey = emailTemplateData.communicationTemplateKey;
      this.getCommunicationTemplates();
      this.loading = false;
    });
  }

  getCommunicationTemplates() {
    this.tmpEmailTemplates = this.providerService.getEmailDetails();
    if (!this.tmpEmailTemplates.find(x => x.communicationTemplateKey === this.templateKey)) {
      const filter = {
        communicationTemplateKey: this.templateKey,
        deleted: false,
      };

      this.emailTemplateForm.reset();
      this.loading = true;
      this.userInviteProcess.getCommunicationEmailTemplate(filter).subscribe(result => {
        this.loading = false;
        this.emailTemplateData = [];
        if (result.body.length > 0) {
          this.emailTemplateData = result.body[0];
          this.emailTemplateForm.setValue({
            communicationTemplateKey: this.emailTemplateData.communicationTemplateKey,
            sendingEmailAddress: this.emailTemplateData.sendingEmailAddress,
            subject: this.emailTemplateData.subject,
            body: atob(this.emailTemplateData.body),
          });
        }
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.emailTemplateData = this.tmpEmailTemplates.filter(x => x.communicationTemplateKey === this.templateKey)[0];
      this.emailTemplateForm.setValue({
        communicationTemplateKey: this.emailTemplateData.communicationTemplateKey,
        sendingEmailAddress: this.emailTemplateData.sendingEmailAddress,
        subject: this.emailTemplateData.subject,
        body: this.emailTemplateData.body,
      });
    }
  }

  emailChanged() {
    if (!this.emailTemplateForm.controls.sendingEmailAddress.value.includes('{')) {
      this.emailTemplateForm.controls.sendingEmailAddress.setValidators([Validators.required, Validators.pattern(patterns.emailPattern)]);
    } else {
      this.emailTemplateForm.controls.sendingEmailAddress.clearValidators();
    }
    this.emailTemplateForm.controls.sendingEmailAddress.updateValueAndValidity();
  }

  updateCommunicationTemplate() {
    if (!!this.templateKey) {
      if (this.emailTemplateForm.invalid) {
        this.emailTemplateForm.markAllAsTouched();
        return false;
      }

      const templateKey = this.emailTemplateForm.controls.communicationTemplateKey.value;
      if (!this.tmpEmailTemplates.find(x => x.communicationTemplateKey === templateKey)) {
        this.tmpEmailTemplates.push({
          communicationTemplateKey: this.emailTemplateData.communicationTemplateKey,
          sendingEmailAddress: this.emailTemplateForm.controls.sendingEmailAddress.value,
          subject: this.emailTemplateForm.controls.subject.value,
          body: this.emailTemplateForm.controls.body.value
        });
      } else {
        this.tmpEmailTemplates.forEach(element => {
          if (element.communicationTemplateKey === templateKey) {
            element.communicationTemplateKey = this.emailTemplateData.communicationTemplateKey;
            element.sendingEmailAddress = this.emailTemplateForm.controls.sendingEmailAddress.value;
            element.subject = this.emailTemplateForm.controls.subject.value;
            element.body = this.emailTemplateForm.controls.body.value;
          }
        });
      }
      this.providerService.setEmailDetails(this.tmpEmailTemplates);
      this.toast.success('Template saved successfully!', 'Success');
      this.isTemplateSaved.emit(true);
    } else {
      this.toast.warning('Please select template', 'Warning');
    }
  }

  requestProfileUpdate(providerInvites) {
    if (this.emailTemplateForm.invalid) {
      this.emailTemplateForm.markAllAsTouched();
      return false;
    }

    const promise = new Promise<void>((resolve, reject) => {
      const inviteLength = providerInvites.length;
      let resultLength = 0;
      providerInvites.forEach(async provider => {
        if (provider.email) {
          this.providerService.getProviderInviteDetails({ emailAddress: provider.email }).subscribe(async result => {
            resultLength++;
            if (result.body.length === 0) {
              const response: any = await this.addDefaultProviderInvite(provider);
              if (response.body[0].length === 0) {
                this.toast.danger('Something Went Wrong', 'Error');
                return false;
              } else {
                this.insertDetailsIntoArray(response.body[0], provider);
              }
            } else {
              this.insertDetailsIntoArray(result.body[0], provider);
            }
            if (inviteLength === resultLength) {
              resolve();
            }
          }, (error) => {
            console.log('error,', error);
          });
        } else {
          resultLength++;
          this.missingEmailCount += 1;
          if (inviteLength === resultLength) {
            resolve();
          }
        }
      });
    });

    promise.then(() => {
      this.sendEmails();
    });
  }

  addDefaultProviderInvite(email): Promise<any> {
    const insertData = {
      communicationTemplateKey: this.templateKey,
      deleted: false,
      insertedUserKey: sessionStorage.getItem('userKey'),
      licenseOwnerProviderInviteResponseKey: '',
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      systemDefault: false,
      updatedUserKey: '',
      emailDate: null,
      emailResponseDate: null,
      inviteExpireTimestamp: null,
      providerInviteStatus: '0',
      providerKey: email.providerKey,
      contactName: '',
      emailAddress: email.email,
      fax: '',
      mailingAddress: '',
      mailingCity: '',
      mailingStateKey: '',
      mailingZipCode: '',
      phone: '',
      providerName: '',
    };
    return new Promise((resolve, reject) => {
      this.providerService.addLicensedOwnerProviderInvites(insertData).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  insertDetailsIntoArray(data, email) {
    this.sendInviteKeys.push(data.licensedOwnerProviderInviteKey);
    this.updateProviderKeys.push({
      licensedOwnerProviderInviteKey: data.licensedOwnerProviderInviteKey,
      providerKey: email.providerKey,
    });
  }

  sendEmails() {
    if (this.missingEmailCount > 0) {
      this.toast.warning('Email is missing for ' + this.missingEmailCount + ' records', 'Warning');
    }
    const inviteProviders: any = {
      flag: 'Update',
      invitationKey: this.sendInviteKeys,
      from: this.emailTemplateForm.controls.sendingEmailAddress.value,
      subject: this.emailTemplateForm.controls.subject.value,
      body: this.emailTemplateForm.controls.body.value,
      type: 'LicensedOwnerProviderInviteKey',
      userKey: sessionStorage.getItem('userKey')
    };

    this.loading = true;
    this.providerService.sendInviteEmails(inviteProviders).subscribe(result => {
      this.loading = false;
      if (result.success) {
        this.toast.success(result.message, 'Success');
        // update ProviderKey in LicensedOwnerProviderInvites
        this.providerService.updateLicensedOwnerProviderInvites(this.updateProviderKeys).subscribe(result => {
          this.updateProviderKeys = [];
        });
      } else {
        this.toast.warning(result.message, 'Error');
      }
      this.sendInviteKeys = [];
      this.providerService.setEmailDetails([]);
      this.isTemplateSaved.emit(true);
    }, (error) => {
      console.log('error,', error);
      this.loading = false;
    });
  }

  onChange(event: any, isForm) {
    const currentCursorPosiiton = isForm === 'editor' ? window.getSelection().getRangeAt(0).startOffset : event.target.selectionStart;

    if (event.keyCode === 123 || event.keyCode === 219) {
      const templateDialog = this.dialog.open(TemplateVariablesListDialogComponent, {
        width: '44%',
        disableClose: true,
        position: {
          top: '1%',
        },
      });
      templateDialog.afterClosed().subscribe((data) => {
        if (data) {
          const insertString = '{' + data + '} ';
          if (isForm === 'editor') {
            const editor = this.getEditor();
            editor.model.change(writer => {
              editor.model.insertContent(writer.createText(insertString));
            });
          }
          if (isForm === 'outEmail') {
            const templateBody = this.emailTemplateForm.controls.sendingEmailAddress.value.trim();
            const output = [templateBody.slice(0, currentCursorPosiiton), insertString, templateBody.slice(currentCursorPosiiton)].join('');
            this.emailTemplateForm.controls.sendingEmailAddress.setValue(output);
          }
          if (isForm === 'subject') {
            const templateBody = this.emailTemplateForm.controls.subject.value.trim();
            const output = [templateBody.slice(0, currentCursorPosiiton), insertString, templateBody.slice(currentCursorPosiiton)].join('');
            this.emailTemplateForm.controls.subject.setValue(output);
          }
        }
      });
    }
  }

  retriveEmailParams() {
    if (this.emailTemplateForm.invalid) {
      this.emailTemplateForm.markAllAsTouched();
      this.toast.warning('Invalid sending email address, Please check template', 'Warning');
      return false;
    }
    this.emailParams.emit(this.emailTemplateForm.value);
  }

  isEmailParamsValid(): boolean {
    if (this.emailTemplateForm.invalid) {
      this.emailTemplateForm.markAllAsTouched();
      this.toast.warning('Invalid sending email address, Please check template', 'Warning');
      return false;
    } else {
      return true;
    }
  }

  handleError = (control: string, error: string) => {
    return this.emailTemplateForm.controls[control].hasError(error);
  }

}
