<h1 mat-dialog-title>{{data.header}}</h1>
<div mat-dialog-content>
    <div>
    {{data.message}}
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="approveProviderApplication()">{{data.yesButton}}</button> 
</div>