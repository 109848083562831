<div >
<nb-card >
    <nb-card-header class="card border-primary">
        <div class="row">
            <div class="col-md-12">
              Migrate Providers to ServicePlus TEST
            </div>
        </div>    
        <br>
        <div>Provider Search</div>
        <form [formGroup]="axelPointProviderSearch"> 
        <div class= "row mt-4">
            <div class="col-md-4 mr-4 custom-select-height">
                <mat-form-field>
                    <input matInput placeholder="Provider ID Number" formControlName="providerID">
                </mat-form-field>
            </div>
            <div class="col-md-1 mt-3 text-right">
                <button class="btn btn-primary btn-sm" (click)="searchForAxelPointProviders()">Search</button>
            </div>
        </div>

        <div class="row" *ngIf="noAxelPointProviderFound">
            <div class="col-md-8 custom-select-height">
                <div class="colorRed">No Provider Found Using This Provider ID. Please Try Again.</div>
            </div>
        </div>
      

        <!-- <div class="row" *ngIf="providerSelected">
            <div class="col-md-8 custom-select-height">
                <mat-form-field>
                    <mat-select formControlName="locationProviderKey" placeholder="Select A Location" (selectionChange)="onSelectLocation($event)">
                    <mat-option value="">Select Location</mat-option>
                    <mat-option
                        *ngFor="let providerLocation of providerLocationList | orderBy:'providerName'"
                        [value]="providerLocation.providerKey">
                        {{ providerLocation.locationName }}
                     </mat-option>
                    </mat-select> 
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-4" *ngIf="providerSelected"></div> -->
    </form>    
    </nb-card-header>

    <nb-card-body *ngIf="axelPointProviderFound && providerSearchComplete" class="card border-primary additionalPadding">

        <div class="row mt-2"></div>
        <div class="fontWeight6">Parent Provider Name - {{axelPointProvidersList[0].Name}}</div>
        <div class="fontWeight6"> Parent Provider ID - {{this.providerParentID}}</div>

        <br>

        <!-- <div class="row">
            <div class="col-5">
                <div> Fleet Assist Results</div>
                <div
                    *ngFor="let providerLocation of providerLocationList | orderBy:'providerName'"
                    >
                    &nbsp; {{providerLocation.locationName }}
                </div>
            </div>
            <div class="col-5">
                Service Plus Results
            </div>
        </div> -->

        <!-- <br>
        <div>
            <fieldset class="border m-0 position-relative p-2 mt-3">
                <legend class="font-weight-bold p-1 mb-0 w-auto">
               
                    Missing Parent Location
                
                </legend>
                 <div>hello</div>
            </fieldset>    
        </div>
        <br> -->

        <!-- <div>
            <fieldset class="border m-0 position-relative p-2 mt-3">
                <legend class="font-weight-bold p-1 mb-0 w-auto">
            
                    Missing Locations
              
                </legend>
                <div class="row">
                    <div class="col-7 ">FleetAssist Location Name and Address</div>
                    <div class="col-2 ">On ServicePlus</div>
                    <div class="col-2 ">Add to ServicePlus</div>
                </div>
                <mat-divider></mat-divider>
                <div *ngFor="let provider of providerLocationList | orderBy:'providerName' ">
                    <div class="row">
                        <div class="col-7 centerText">
                            <div>{{provider.locationName }}</div>
                        </div>
                        <div class="col-2 centerText">
                            <div> Yes</div>
                        </div>
                        <div class="col-2">
                            <div> <mat-checkbox [value]="provider.providerKey"   (change)="update($event)"></mat-checkbox></div>
                        </div>
                    </div>
                    <mat-divider ></mat-divider>
                  
        
                </div>
            </fieldset>    
        </div> -->

        <div>
            <fieldset class="border m-0 position-relative p-2 ">
                <legend class="font-weight-bold fontSize16 p-1 mb-0 w-auto">
                Parent Location
                </legend>
                 <div class="paddingBottom"> 
                    <div *ngIf="parentLocationOnBoth">
                        Parent Location exists on both Fleet Assist and Service Plus. &#10003; 
                    </div>
                    <div *ngIf="!parentLocationOnBoth">
                        <div class="paddingBottom" > Parent Location exists on Fleet Assist but not on Service Plus. Would you like to add this
                            provider to Serive Plus? </div>
                        <div > <button disabled (click)="confirmAddProvider()" mat-stroked-button>Add Provider</button> </div> 
                    </div>
                </div>
            </fieldset>    
        </div>
        <br>
    
        <div>
            <fieldset class="border m-0 position-relative p-2">
                <legend class="font-weight-bold fontSize16 p-1 mb-0 w-auto">            
                    Provider Locations
                </legend>
                <!-- <div *ngIf="providerLocationOnBoth" class="paddingBottom">
                    Provider Locations exists on both Fleet Assist and Service Plus. &#10003;
                </div>  -->
                 <!-- <div *ngIf="!providerLocationOnBoth"> -->
                <div>    
                    <div class="row paddingBottom">
                        <!-- <div class="col-7 ">Fleet Assist Location Name and Address</div> -->
                        <div class="col-3 ">Fleet Assist Location Name </div>
                        <div class="col-4 ">Fleet Assist Location Address</div>
                        <div class="col-2 ">On Service Plus</div>
                        <div *ngIf="numberOfProviderLocations > 1" class="col-2 ">Select Location</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div *ngFor="let provider of axelPointLocationsDetailsList | orderBy:'Name' ">
                        <div class="row height40">
                            <!-- <div class="col-7 centerText">
                                <div>{{provider.Name }} &nbsp; {{provider.Address}}, &nbsp; {{provider.City}}, &nbsp; {{provider.StateKey}} </div>
                            </div> -->
                            <div class="col-3 centerText">
                                <div>{{provider.Name }}  -  {{provider.DispatchStatusKey}} </div>
                            </div>
                            <div class="col-4 centerText">
                                <div>{{provider.Address}}, {{provider.City}}, {{provider.StateKey}} </div>
                            </div>
                            <div class="col-2 centerText">
                                <div *ngIf="provider.existsOnServicePlus == true"> Yes</div>
                                <div *ngIf="provider.existsOnServicePlus == false"> No</div>
                            </div>
                            <div *ngIf="numberOfProviderLocations > 1" class="col-2">
                                <div *ngIf="provider.existsOnServicePlus == false" > <mat-checkbox [value]="provider" (change)="update($event)"></mat-checkbox></div>
                                <div *ngIf="provider.existsOnServicePlus == true"> <mat-checkbox checked="true" disabled  (change)="update($event)"></mat-checkbox></div>
                            </div>
                        </div>
                        <mat-divider ></mat-divider>
                    </div>
                    
                    <!--  no? <div class="row"> 
                        <div class="col-9 paddingBottomTop centerText" > Add all missing Provider Locations to Service Plus? </div>
                        <div class="col-2 paddingBottomTop"> <button (click)="confirmAddProvider()" mat-stroked-button>Add Locations</button> </div>
                    </div> -->
                    <div *ngIf="!providerLocationOnBoth && parentLocationOnBoth">
                    <div *ngIf="numberOfProviderLocations > 1" class="paddingBottomTop">
                        <div class="paddingBottom"> Add Fleet Assist's Provider Locations to Service Plus?</div>
                        <div > <button disabled (click)="confirmAddProviderLocation(1)" mat-stroked-button>Add All Locations</button> 
                        &nbsp; <button disabled (click)="confirmAddProviderLocation(2)" mat-stroked-button>Add Checked Locations</button> </div> 
                    </div>
                    <div *ngIf="numberOfProviderLocations == 1"class="paddingBottomTop">
                        <div class="paddingBottom"> Add Fleet Assist's Provider Location to Service Plus?</div>
                        <div > <button disabled (click)="confirmAddProviderLocation(3)" mat-stroked-button>Add Location</button> </div>
                    </div>
                    </div>

                    <div *ngIf="!providerLocationOnBoth && !parentLocationOnBoth">
                        <div *ngIf="numberOfProviderLocations > 1" class="paddingBottomTop">
                            <div class="paddingBottom"> Add Fleet Assist's Provider Locations to Service Plus?</div>
                            <div > <button disabled (click)="confirmAddProviderParentAndLocation(1)" mat-stroked-button>Add Provider and All Locations</button> 
                            &nbsp; <button disabled (click)="confirmAddProviderParentAndLocation(2)" mat-stroked-button>Add Provider and Checked Locations</button> </div> 
                        </div>
                        <div *ngIf="numberOfProviderLocations == 1"class="paddingBottomTop">
                            <div class="paddingBottom"> Add Fleet Assist's Provider Location to Service Plus?</div>
                            <div > <button disabled (click)="confirmAddProviderParentAndLocation(3)" mat-stroked-button>Add Provider and Location</button> </div>
                        </div>
                    </div>


                   </div> 
                   <div *ngIf="providerLocationOnBoth" class="paddingBottom">
                    <br>
                    Provider Locations exists on both Fleet Assist and Service Plus. &#10003;
                </div> 
            </fieldset>    
        </div>

        <br>

        <!-- <div *ngIf="missingProviderLocation">
        <div class="row">
            <div class="col-7 ">FleetAssist Location Name and Address</div>
            <div class="col-2 ">On ServicePlus</div>
            <div class="col-2 ">Add to ServicePlus</div>
        </div>
        <mat-divider></mat-divider>
        <div *ngFor="let provider of providerLocationList | orderBy:'providerName' ">
            <div class="row">
                <div class="col-7 centerText">
                    <div>{{provider.locationName }}</div>
                </div>
                <div class="col-2 centerText">
                    <div> Yes</div>
                </div>
                <div class="col-2">
                    <div> <mat-checkbox [value]="provider.providerKey"   (change)="update($event)"></mat-checkbox></div>
                </div>
            </div>
            <mat-divider ></mat-divider>
          

        </div>
       </div> -->
       <!-- <div>
        <fieldset class="border m-0 position-relative p-2 ">
            <legend class="font-weight-bold fontSize16 p-1 mb-0 w-auto">
            Provider Information Differences
            </legend>
             <div class="paddingBottom"> 
                <div *ngIf="providerInformationMatchesOnBoth">
                    Information on Fleet Assist and Service Plus Matches. &#10003;
                </div>

                <div *ngIf="!providerInformationMatchesOnBoth">
                    <div>Provider ID - 12345</div>
                    <div class="row">
                        <div class="col-2">Data</div>
                        <div class="col-5">Fleet Assist</div>
                        <div class="col-5">Service Plus</div>
                    </div>
                    <mat-divider></mat-divider>

                    <div class="row">
                        <div class="col-2 centerText">Name</div>
                        <div class="col-5 centerText"> <mat-checkbox></mat-checkbox>XYZ</div>
                        <div class="col-5 centerText">ABC <mat-checkbox></mat-checkbox></div>
                    </div>
                    <mat-divider></mat-divider>

                    <div class="row">
                        <div class="col-2 centerText">Select All</div>
                        <div class="col-5 centerText"> <mat-checkbox></mat-checkbox>XYZ</div>
                        <div class="col-5 centerText"> <mat-checkbox>ABC</mat-checkbox></div>
                    </div>

                  

                    <br>

                    <div class="paddingBottom" > Provider information differs on Fleet Assist and Service Plus. Would you 
                        like to update the Provider information with the selected data? </div>
                    <div > <button (click)="confirmAddProvider()" mat-stroked-button>Update Provider Information</button> </div> 
                </div>
            </div>
        </fieldset>    
    </div>
    <br> -->

    </nb-card-body>
</nb-card>
</div>