import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-template-variables-list-dialog',
  templateUrl: './template-variables-list-dialog.component.html',
  styleUrls: ['./template-variables-list-dialog.component.scss']
})
export class TemplateVariablesListDialogComponent implements OnInit {

  @Output() communicationTemplateVariableKey: EventEmitter<any> = new EventEmitter<any>();

  loading: boolean;
  searchString: string;
  allTemplateVariables: any = [];
  dataDictionaryKey = '168c347d-fb66-4733-9e6f-9442aa5915ee';
  schema = 'FleetAssist';

  constructor(
    private userInviteProcess: UserInviteProcess,
    public dialog: MatDialogRef<TemplateVariablesListDialogComponent>
  ) { }

  ngOnInit() {
    this.getAllCommunicationTemplateVariables();
  }

  /****
  * Desc: Get all Commmunicatiom template variables
  * @params postData
  * @return array response
  ***/
  getAllCommunicationTemplateVariables() {

    const objParams = {
      deleted: 0,
    };
    this.loading = true;
    this.userInviteProcess.getAllCommunicationTemplateVariables(objParams).subscribe(result => {
      result.body.map((data) => {
        this.allTemplateVariables.push({ communicationTemplateVariableKey: data.communicationTemplateVariableKey });
      });
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  getVariableDetails(data: any) {
    this.dialog.close(data.communicationTemplateVariableKey);
    // this.communicationTemplateVariableKey.emit(data.communicationTemplateVariableKey);
  }

  search(event) {
    this.searchString = event;
  }

  closeDialog() {
    this.dialog.close();
  }
}
