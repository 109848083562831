import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { forkJoin } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { ProviderUsersDialogComponent } from 'src/app/dialogs/provider-users-dialog/provider-users-dialog.component';
import { ProviderUserInviteProcessComponent } from 'src/app/dialogs/provider-user-invite-process/provider-user-invite-process.component';
import { ProviderService } from 'src/app/services/provider.service';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';

@Component({
  selector: 'app-provider-management-users',
  templateUrl: './provider-management-users.component.html',
  styleUrls: ['./provider-management-users.component.scss']
})
export class ProviderManagementUsersComponent implements OnInit {
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() isParent: boolean;
  @Input() providerInputKey: string;
  @Input() isMasterLicensedOwner: boolean;

  disableFlag = false;
  loading = false;
  employeeData: any = [];
  companyName: string;
  providerKey: string;
  providerDetails: any;
  searchString: string;
  isLocation = false;
  templateKey: string;

  constructor(
    private dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    private utilityService: UtilityService,
    private usersService: UsersService,
    private providerService: ProviderService,
    private userInviteProcess: UserInviteProcess
  ) { }

  ngOnInit() {
    this.companyName = sessionStorage.getItem('SPCompanyName');
    this.providerKey = this.providerInputKey;

    this.getEmployeesList();
    this.checkLocation();
  }

  checkLocation() {
    const filter = {
      providerKey: this.providerKey
    };

    this.providerService.getProviderDetails(filter).subscribe(res => {
      const provider = res.body[0] || [];
      if (provider.parentKey) {
        this.isLocation = true;
      }
      this.companyName = provider.name;
      this.getTemplate();
    });
  }

  getTemplate() {
    const templateFilter = {
      associationKey: sessionStorage.getItem('licensedOwnerKey'),
      communicationCategoryKey: 'User Invite',
      templateName: this.isLocation ? 'Provider Location User Invitation' : 'Provider User Invitation'
    };

    this.userInviteProcess.getCommunicationTemplateNames(templateFilter).subscribe(res => {
      this.templateKey = res.body[0].communicationTemplateKey;
    });
  }

  // get employee list details.
  getEmployeesList() {
    this.loading = true;
    const data = {
      associationKey: this.providerKey,
      deleted: false,
    };

    const filter = { associationKey: sessionStorage.getItem('licensedOwnerKey'), deleted: false };

    const userReq = this.usersService.getUsersDetails(data).subscribe((result: any) => {
      this.employeeData = this.utilityService.sortJsonByKey(result.body, 'insertedTimestamp', 'DESC');
      this.loading = false;
    }, (error) => {
      console.log('error,', error);
      this.loading = false;
    });
  }

  openUserInviteDialog() {
    const dialogRef = this.dialog.open(ProviderUserInviteProcessComponent, {
      maxWidth: '95%',
      maxHeight: '95%',
      position: { top: '1%' },
      data: {
        providerKey: this.providerKey,
        isLocation: this.isLocation,
        templateKey: this.templateKey
      }
    });

    // on close reload employee details
    dialogRef.afterClosed().subscribe(result => {
      this.getEmployeesList();
    });
  }

  addNewVendorEmployee(type, user) {
    const vendorEmployeeDialog = this.dialog.open(ProviderUsersDialogComponent, {
      width: '85%',
      position: {
        top: '2%'
      },
      data: { value: type, userKey: user.userKey }
    });

    // on close reload employee details
    vendorEmployeeDialog.afterClosed().subscribe(result => {
      this.getEmployeesList();
    });
  }

  search(event: any) {
    this.searchString = event;
  }

  onSort({ column, direction }: SortEvent) {
    this.employeeData = this.utilityService.sortData(this.headers, column, direction, this.employeeData);
  }
}
