import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component';
import { map } from 'rxjs/operators';


@Component({
  selector: 'ngx-add-customer-perferred-providers',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSelectModule,
    ReactiveFormsModule,

  ],
  templateUrl: './add-customer-perferred-providers.component.html',
  styleUrls: ['./add-customer-perferred-providers.component.scss']
})
export class AddCustomerPerferredProvidersComponent {

  constructor(
    public dialogRef: MatDialogRef<AddCustomerPerferredProvidersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private providerAdminService: ProviderAdminService,
    public dialog: MatDialog,

  ) { }

  customerControl = new FormControl('');
  customers;
  filteredCustomers: Observable<string[]>;
  customerList: any = [];
  customerKey: number = 0;
  noCustomerSelectedMessage: boolean = false;
  selectedForDNU: any = [];


  ngOnInit(): void{
    this.getCustomerList();
  }

  getCustomerList(): void {
    this.noCustomerSelectedMessage = false;
    this.providerAdminService.getCustomerList()
      .subscribe(res => {
        this.customers = res
        this.filteredCustomers = this.customerControl.valueChanges.pipe(
          map(value => this.customerFilter(value || '')),
        );
      })
      //this.getSubCustomerList(this.customerList[0].CustomerKey)
  }
 
  private customerFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.customers.filter(option => option.CustomerName && option.CustomerName.toLowerCase().includes(filterValue)).slice(0, 25);
  }

  onNoClick(): void {
    //this.openSnackBar('Match has been canceled.');
    this.dialogRef.close();
  }

  selectedCustomer(customerKey){
    this.noCustomerSelectedMessage = false;
    console.log('this is the customer key selected ' + customerKey)
    this.selectedForDNU = customerKey;
    this.customerKey = customerKey;
  }

  addCustomerToOnlyUsedForSpecificClient(){
    
    if( this.selectedForDNU == null || this.selectedForDNU == undefined || this.selectedForDNU == ''){
      this.noCustomerSelectedMessage = true;
      return;
    }
    else{ this.noCustomerSelectedMessage = false;}

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: {header: 'Add To Used Only For Specific Client', message: 'Are you sure you want to add this vendor to Used Only For Specific Client?',
      yesButton: 'Yes'
    }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.addCustomerToCustomerPerferred();
      }
    })
    
  }
  

    addCustomerToCustomerPerferred(){
      const data = {
        inProviderId: this.data,
        inCustomerKey: this.selectedForDNU,
      }

      this.dialogRef.close(data);
      
      
    }


}
