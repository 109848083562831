import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { ProfileService } from 'src/app/services/profile.service';
import { LocationService } from 'src/app/services/location.service';
import { NbToastrService, NbComponentStatus } from '@nebular/theme';
import { SetupService } from 'src/app/services/setup.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { UserService } from 'src/app/services/user.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ngx-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss'],
})
export class LocationDetailsComponent implements OnInit {

  // Input data to manipulate provider data
  @Input() providerDetails: any; // Input data
  @Input() showSave = true;
  @Input() locationKey: string;
  @Output() saveCompleted = new EventEmitter<string>();
  @Input() showBlankForm: boolean = false;
  @Output() newLocation = new EventEmitter<string>();

  locationDetailsForm: UntypedFormGroup;
  states: any;
  membershipStatuses: any;
  loading: any;
  usersGeoCode: any;
  addressComponents: any;
  providerKey: string;
  locationId: String = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private toastrService: NbToastrService,
    private setupService: SetupService,
    private profileService: ProfileService,
    private utilityService: UtilityService,
    private userService: UserService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    if (this.showBlankForm == false) {
      this.getProvidreDetails();
    }

    this.locationDetailsForm = this.formBuilder.group({
      providerKey: [''],
      name: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      physicalAddress1: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      physicalAddress2: ['', Validators.pattern(patterns.textPattern)],
      physicalAddress3: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      physicalCity: ['', [Validators.required, Validators.pattern(patterns.cityPattern)]],
      physicalStateKey: ['', Validators.required],
      physicalPostalCode: ['', [Validators.required, Validators.pattern(patterns.alphanumericZipCodePattern)]],
      updatedTimestamp: [{ value: '', disabled: true }],
      updatedUserKey: [{ value: '', disabled: true }],
      timeZoneKey: [''],
      physicalLatitude: [{ value: '', disabled: true }],
      physicalLongitude: [{ value: '', disabled: true }],
      mailingAddress1: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      mailingAddress2: ['', Validators.pattern(patterns.textPattern)],
      mailingAddress3: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      mailingCity: ['', [Validators.required, Validators.pattern(patterns.cityPattern)]],
      mailingStateKey: ['', Validators.required],
      mailingPostalCode: ['', [Validators.required, Validators.pattern(patterns.alphanumericZipCodePattern)]],
      //mailingCountry: ['', [Validators.required, Validators.pattern(patterns.cityPattern)]],
      deleted: [false]
    });
  }

  // get detailed address on change
  public getDetailedAddress(address: Address, type: String) {
    let streetNumber = '';
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    address.address_components.forEach(element => {
      element.types.forEach(data => {
        switch (data) {
          case 'neighborhood':
            if (type === 'physical') {
              this.locationDetailsForm.controls.physicalAddress2.setValue(element.long_name);
            } else {
              this.locationDetailsForm.controls.mailingAddress2.setValue(element.long_name);
            }
            break;

          case 'administrative_area_level_2':
            if (type === 'physical') {
              this.locationDetailsForm.controls.physicalAddress3.setValue(element.long_name);
            } else {
              this.locationDetailsForm.controls.mailingAddress3.setValue(element.long_name);
            }
            break;
          case 'administrative_area_level_1':
            if (type === 'physical') {
              this.locationDetailsForm.controls.physicalStateKey.setValue(element.long_name);
            } else {
              this.locationDetailsForm.controls.mailingStateKey.setValue(element.long_name);
            }
            break;
          case 'postal_code':
            if (type === 'physical') {
              this.locationDetailsForm.controls.physicalPostalCode.setValue(element.long_name);
            } else {
              this.locationDetailsForm.controls.mailingPostalCode.setValue(element.long_name);
            }
            break;
          case 'locality':
            if (type === 'physical') {
              this.locationDetailsForm.controls.physicalCity.setValue(element.long_name);
            } else {
              this.locationDetailsForm.controls.mailingCity.setValue(element.long_name);
            }
            break;
            // Use for adding Country data
          // case 'country':
          //   if (type === 'physical') {
          //     this.locationDetailsForm.controls.physicalCity.setValue(element.long_name);
          //   } else {
          //     this.locationDetailsForm.controls.mailingCity.setValue(element.long_name);
          //   }
          //   break;
        }
      });
    });

    if (type === 'physical') {
      this.locationDetailsForm.controls.physicalLatitude.setValue(lat);
      this.locationDetailsForm.controls.physicalLongitude.setValue(lng);
      this.locationDetailsForm.controls.physicalAddress1.setValue(address.name);
      this.profileService.getUsersTimezone({ 'lat': lat, 'lng': lng })
        .subscribe((res: any) => {
          this.locationDetailsForm.controls.timeZoneKey.setValue(res.timeZoneId);
        });
    } else {
      this.locationDetailsForm.controls.mailingAddress1.setValue(address.name);
    }
  }

  // show toastr
  showToast(status: NbComponentStatus, message) {
    this.toastrService.show(status, message, { status });
  }

  // Update location Details
  addUpdateLocationDetails() {
    if (this.locationDetailsForm.invalid) {
      this.utilityService.validateAllFormFields(this.locationDetailsForm);
      return false;
    }

    let formData = this.locationDetailsForm.value;
    if (sessionStorage.getItem('defaultNewLocationKey') && this.showBlankForm == true) {
      formData.providerKey = sessionStorage.getItem('defaultNewLocationKey');
    }
    if(this.locationDetailsForm.value.parentKey == '' || this.locationDetailsForm.value.parentKey == null){
      if(sessionStorage.providerParentKey != null && sessionStorage.providerParentKey != 0){
        formData.parentKey = sessionStorage.getItem("providerParentKey")}
      else{ formData.parentKey = sessionStorage.getItem("providerKey")} 
      };

    //formData.parentKey = sessionStorage.getItem("providerKey")}  //Works for location add not parent providers
    formData.updatedUserKey = sessionStorage.getItem('userKey');
    formData.updatedTimestamp = this.utilityService.getCurrentTimestamp();
    formData.physicalLatitude = this.locationDetailsForm.get('physicalLatitude').value;
    formData.physicalLongitude = this.locationDetailsForm.get('physicalLongitude').value;
    formData.insertedUserKey = sessionStorage.getItem('userKey');
    //formData.deleted = !formData.deleted;
    if (formData.deleted) {
      const updateData = {
        dispatchableTier: 0,
        dispatchStatusKey: 'InActive'
      }
      formData = { ...formData, ...updateData };}
    if(!formData.deleted){
      const updateData = {
        dispatchableTier: 1,
        dispatchStatusKey: 'Active'
      }
      formData = { ...formData, ...updateData };
    }
    
    this.loading = true;
    this.locationService.updateLocationDetails(formData).subscribe((res: any) => {
      this.loading = false;
      this.saveCompleted.emit('true');
      this.newLocation.emit(res?.body[0]);
      const message = 'Location data saved successfully.';
      this.showToast('success', message);

      if (formData.providerKey === sessionStorage.getItem('providerKey') && formData.deleted) {
        sessionStorage.setItem('dispatchableTier', formData.dispatchableTier);
      }
    }, (error) => {
      this.loading = false;
      this.saveCompleted.emit('false');
    });
  }

  // Add location Details
  addLocationDetails() {
    if (this.locationDetailsForm.invalid) {
      this.utilityService.validateAllFormFields(this.locationDetailsForm);
      return false;
    }
    this.loading = true;
    this.setupService.addDefaultLocation(sessionStorage.getItem('providerKey')).
      subscribe((result: any) => {
        this.loading = false;
        sessionStorage.setItem('defaultNewLocationKey', result[0].locationKey);
        this.addUpdateLocationDetails();
      }, (error) => {
        this.loading = false;
        console.info('error', error);
      });
  }

  getProvidreDetails() {
    let data = {};
    if (this.locationKey === undefined) {
      data = {
        providerKey: sessionStorage.getItem('defaultLocationKey'),
        deleted: null,
      };
    } else {
      data = {
        providerKey: this.locationKey,
        deleted: null,
      };
    }

    this.loading = true;
    this.profileService.getProviderDetails(data).subscribe((result: any) => {
      this.loading = false;
      this.providerDetails = result.body[0];
      this.locationId = this.providerDetails.providerId == null ? '' : this.providerDetails.providerId;
      this.locationDetailsForm.setValue({
        providerKey: this.providerDetails.providerKey,
        name: this.providerDetails.name,
        physicalAddress1: this.providerDetails.physicalAddress1,
        physicalAddress2: this.providerDetails.physicalAddress2,
        physicalAddress3: this.providerDetails.physicalAddress3,
        physicalCity: this.providerDetails.physicalCity,
        physicalStateKey: this.providerDetails.physicalStateKey,
        physicalPostalCode: this.providerDetails.physicalPostalCode,
        updatedTimestamp: this.providerDetails.updatedTimestamp == null ? '' : this.datePipe.transform(this.providerDetails.updatedTimestamp, 'MM-dd-yyyy'),
        updatedUserKey: '',
        timeZoneKey: this.providerDetails.timeZoneKey,
        physicalLongitude: this.providerDetails.physicalLongitude,
        physicalLatitude: this.providerDetails.physicalLatitude,
        mailingAddress1: this.providerDetails.mailingAddress1,
        mailingAddress2: this.providerDetails.mailingAddress2,
        mailingAddress3: this.providerDetails.mailingAddress3,
        mailingCity: this.providerDetails.mailingCity,
        mailingStateKey: this.providerDetails.mailingStateKey,
        mailingPostalCode: this.providerDetails.mailingPostalCode,
        deleted: this.providerDetails.deleted ? false : true
      });

      this.getUserName(this.providerDetails.updatedUserKey);
    }, (error) => {
      this.loading = false;
    });
  }

  handleError = (control: string, error: string) => {
    return this.locationDetailsForm.controls[control].hasError(error);
  }

  setPhysicalAddress() {
    if (this.locationDetailsForm.controls.physicalAddress1.value !== '')
      this.locationDetailsForm.controls.mailingAddress1.setValue(this.locationDetailsForm.controls.physicalAddress1.value);
    if (this.locationDetailsForm.controls.physicalAddress2.value !== '')
      this.locationDetailsForm.controls.mailingAddress2.setValue(this.locationDetailsForm.controls.physicalAddress2.value);
    if (this.locationDetailsForm.controls.physicalAddress3.value !== '')
      this.locationDetailsForm.controls.mailingAddress3.setValue(this.locationDetailsForm.controls.physicalAddress3.value);
    if (this.locationDetailsForm.controls.physicalPostalCode.value !== '')
      this.locationDetailsForm.controls.mailingPostalCode.setValue(this.locationDetailsForm.controls.physicalPostalCode.value);
    if (this.locationDetailsForm.controls.physicalCity.value !== '')
      this.locationDetailsForm.controls.mailingCity.setValue(this.locationDetailsForm.controls.physicalCity.value);
    if (this.locationDetailsForm.controls.physicalStateKey.value !== '')
      this.locationDetailsForm.controls.mailingStateKey.setValue(this.locationDetailsForm.controls.physicalStateKey.value);
  }

  getUserName(userKey) {
    const data = { userKey: userKey };
    this.loading = true;
    this.userService.getUsersDetails(data).subscribe(result => {
      this.loading = false;
      if (result.body[0]) {
        this.locationDetailsForm.controls.updatedUserKey.setValue(result.body[0].firstName + ' ' + result.body[0].lastName);
      } else {
        this.locationDetailsForm.controls.updatedUserKey.setValue('');
      }
    });
  }
}
