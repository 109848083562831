<app-loader [loading]="loading"></app-loader>
<ng-container *ngIf="isEdit">
    <div class="text-center">
        <h6 class="modal-title">Discounts - LineItem Exceptions</h6>
    </div>
    <form [formGroup]="lineItemForm">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select formControlName="lineItemKey" placeholder="Select Line Item">
                        <mat-option *ngFor="let data of providerDiscountAppliesLineItems" [value]="data.lineItemKey">
                            {{data.lineItemKey}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4 mt-3">
                <button class="btn btn-primary btn-sm"
                    (click)="updateProviderDiscountAppliesLineItems('', 'addProviderDiscountApplies')">
                    Add To List
                </button>
            </div>
        </div>
    </form>
</ng-container>
<div class="table-responsive custom-height">
    <table class="table table-bordered table-striped">
        <thead>
            <tr>
                <th class="header-height" sortable="lineItemKey" (sort)="onSort($event)">Provider Discount Line Item
                    Exception</th>
                <th *ngIf="isEdit" class="header-height">Delete</th>
            </tr>
        </thead>
        <tbody *ngIf="lineItems.length > 0 else noData">
            <tr *ngFor="let lineItem of lineItems">
                <td>{{lineItem.lineItemKey}}</td>
                <td *ngIf="isEdit">
                    <button class="btn btn-default btnAnimation" nbTooltip="Delete"
                        (click)="updateProviderDiscountAppliesLineItems(lineItem, 'updateProviderDiscountApplies')">
                        <i class="fas fa-times"></i>
                    </button>
                </td>
            </tr>
        </tbody>
        <ng-template #noData>
            <tbody>
                <tr>
                    <td colspan="2" class="text-center">No Data available</td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>
<div class="modal-footer mt-3">
    <button class="btn btn-primary btn-sm mr-2 mt-3" (click)="closeDialog()">
        Close
    </button>
</div>