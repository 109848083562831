<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6 class="modal-title">Email Details</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="container my-2" *ngIf="emailData else noDataAvailable">
        <div class="row">
            <div class="col-md-2">
                <label>Email Status: </label>
            </div>
            <div class="col-md-10">
                <h6 [ngClass]="{'text-success': emailData.statusKey == 'Successful',
          'text-danger': emailData.statusKey == 'Failed'
          }">{{emailData.statusKey}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Our Email: </label>
            </div>
            <div class="col-md-10">
                <p>{{emailData.emailFrom}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Email To: </label>
            </div>
            <div class="col-md-10">
                <p>{{emailData.emailTo}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Subject: </label>
            </div>
            <div class="col-md-10">
                <p>{{emailData.subject}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Body: </label>
            </div>
            <div class="col-md-10">
                <p [innerHtml]="emailData.body"></p>
            </div>
        </div>
    </div>
    <ng-template #noDataAvailable>
        <p class="text-center alert alert-primary">No email logs data Available !!</p>
    </ng-template>
</div>