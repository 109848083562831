<app-loader [loading]="loading"></app-loader>
  <nb-card>
    <nb-card-body class="p-md-3">
      <div class="row">
        <div class="col-12 col-md-9">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th *ngFor="let day of arrDays">{{day}}</th>
                </tr>
              </thead>
              <tbody *ngIf="weekData.length > 0; else noData">
                <tr>
                  <td *ngFor="let item of weekData">
                    <div *ngIf="!(editAvailabilityFlag === item.daysOfWeekKey)">
                      {{ item.startTime }} - {{ item.endTime }}
                      <button class="btn btn-default btn-sm btnAnimation mr-1 floatR" nbTooltip="Edit" (click)="editAvailabilityHours(item)">
                        <i class="fas fa-pencil"></i>
                      </button>
                    </div>
                    <div *ngIf="editAvailabilityFlag === item.daysOfWeekKey">
                      <mat-form-field>
                        <input matInput placeholder="From" [ngxMatDatetimePicker]="MFromPicker" [value]="item.startTime" [(ngModel)]="item.startTime">
                        <ngx-mat-timepicker #MFromPicker>
                          [(ngModel)]="item.startTime" [disabled]="disabled" 
                          [showSpinners]="showSpinners"
                          [stepHour]="stepHour" [stepMinute]="stepMinute" 
                          [stepSecond]="stepSecond" 
                          [showSeconds]="showSeconds">
                        </ngx-mat-timepicker>
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput placeholder="To" [ngxMatDatetimePicker]="MToPicker" [value]="item.endTime" [(ngModel)]="item.endTime">
                        <ngx-mat-timepicker #MToPicker>
                          [(ngModel)]="item.endTime" [disabled]="disabled" 
                          [showSpinners]="showSpinners"
                          [stepHour]="stepHour" [stepMinute]="stepMinute" 
                          [stepSecond]="stepSecond" 
                          [showSeconds]="showSeconds">
                        </ngx-mat-timepicker>
                      </mat-form-field>
                      <button class="btn btn-default btn-sm btnAnimation ml-1" (click)="updateUserAvailabilityHours(item)">
                        <i class="far fa-check"></i>
                      </button>
                      <button class="btn btn-default btn-sm btnAnimation ml-1 floatR " (click)="closeDayData(editAvailabilityFlag)">
                        <i class="fal fa-times"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <ng-template #noData>
                <tbody>
                  <tr>
                    <td colspan="7" class="text-center">No data available</td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
        </div>
        <div class="col-md-3">
          <form [formGroup]="availabilityForm">
            <div class="row">
              <div class="col-md-12">
                <mat-checkbox formControlName="available" (change)="hoursAvailabilityOnChange($event)"> 24 Hour Available</mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-checkbox formControlName="outOfOffice"> Out of Office </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Out Of Office Expire Date</mat-label>
                  <input matInput [matDatepicker]="myDatepicker" [min]="minDate" formControlName="outOfOfficeExpDate">
                  <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #myDatepicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
  <div class="modal-footer">
    <!-- <button class="btn btn-sm btn-primary btn-md" (click)="addUserSkills('add')">Add Skills</button> -->
  </div>
  