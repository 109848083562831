<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="modal-header">
    <h6 class="modal-title text-capitalize">Edit Security Group</h6>
    <button type="button" class="close" (click)="cancelClick()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>

<div class="modal-body">
    <nb-card>
        <nb-card-body>
            <form [formGroup]="securityGroupForm">
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field>
                            <input type="text" matInput formControlName="description" required placeholder="Description"
                                autocomplete="off">
                            <mat-error
                                *ngIf="securityGroupForm.controls.description.touched && securityGroupForm.controls.description.errors">
                                <span *ngIf="securityGroupForm.controls.description.errors.required">
                                    Description is required.
                                </span>
                                <span *ngIf="securityGroupForm.controls.description.errors.pattern">
                                    Enter valid Description.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-select formControlName="userType" placeholder="User Type">
                                <mat-option *ngFor="let val of userTypeList | orderBy: 'description'"
                                    [value]="val.userTypeKey">
                                    {{val.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <input type="text" matInput formControlName="platform" required placeholder="Platform"
                                autocomplete="off" [readonly]='isEdit'>
                            <mat-error
                                *ngIf="securityGroupForm.controls.platform.touched && securityGroupForm.controls.platform.errors">
                                <span *ngIf="securityGroupForm.controls.platform.errors.required">
                                    Platform is required.
                                </span>
                                <span *ngIf="securityGroupForm.controls.platform.errors.pattern">
                                    Enter valid platform.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <input matInput formControlName="securityLevel" required placeholder="Security Level"
                                autocomplete="off">
                            <mat-error
                                *ngIf="securityGroupForm.controls.securityLevel.touched && securityGroupForm.controls.securityLevel.errors">
                                <span *ngIf="securityGroupForm.controls.securityLevel.errors.required">
                                    Security level is required.
                                </span>
                                <span
                                    *ngIf="securityGroupForm.controls.securityLevel.errors.pattern && securityGroupForm.controls.securityLevel.value < 0">
                                    Enter valid security level.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-select formControlName="deleted" placeholder="Delete">
                                <mat-option *ngFor="let val of deletedValue" [value]="val.value">
                                    {{val.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <Label class="float-right" *ngIf="data.group.createdBy && !data.group.updatedBy">Created By:
                            <strong>{{data.group.createdBy}}</strong></Label>
                        <Label class="float-right" *ngIf="data.group.updatedBy">Updated By:
                            <strong>{{data.group.updatedBy}}</strong></Label>
                    </div>
                </div>
            </form>
        </nb-card-body>
    </nb-card>
</div>

<div class="modal-footer">
    <button class="btn btn-primary btn-sm mr-2" (click)="cancelClick()">Cancel</button>
    <button class="btn btn-primary btn-sm mr-2 text-capitalize" type="button"
        (click)="addUpdateSecurityGroup(data.action)">Save</button>
</div>
