import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class AssetService {

  make: any;

  constructor(
    private http: HttpClient,
  ) { }

  // API call to get provider vehicle details.
  getProviderVehicleDetails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providervehicles/get`, data);
  }

  // API call to get Asset make.
  getMakes(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/makes/get`, data);
  }

  // API call to get Asset vehicle status.
  getVehicleStatuses(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/vehiclestatuses/get`, data);
  }

  // API call to get Asset Equipment Vocations.
  getEquipmentVocations(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/equipmentvocations/get`, data);
  }

  // API call to get Asset Equipment Categories.
  getEquipmentCategories(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/equipmentcategories/get`, data);
  }

  // API call to get Asset Engine Manufacturers.
  getEngineManufacturers(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/enginemanufacturers/get`, data);
  }

  // API call to get Body Style/Configurations.
  getBodyStyleConfigurations(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/bodystyleconfigurations/get`, data);
  }

  // API call to get Asset Engine Sizes.
  getEngineSizes(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/enginesizes/get`, data);
  }

  // API call to get Asset Severity Of Service.
  getSeverityOfService(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/severityofservice/get`, data);
  }

  // API call to get Asset Axle Configurations.
  getAxleConfigurations(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/axleconfigurations/get`, data);
  }

  // API call to get Asset Cab Types.
  getCabTypes(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/cabtypes/get`, data);
  }

  // API call to get Asset Power Sources.
  getPowerSources(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/powersources/get`, data);
  }

  // API call to get Asset Transmission Types.
  getTransmissionTypes(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/transmissiontypes/get`, data);
  }

  // API call to get Asset Oil Types.
  getOilTypes(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/oiltypes/get`, data);
  }

  // API call to get Asset Power TakeOffs.
  getPowerTakeOffs(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/powertakeoffs/get`, data);
  }

  // API call to get Asset Trailer Lengths.
  getTrailerLengths(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/trailerlengths/get`, data);
  }

  // API call to add new provider asset.
  addProviderVehiclesDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providervehicles`, data);
  }

  // API call to update provider asset.
  updateProviderVehiclesDetails(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providervehicles`, data);
  }

  // API call to delete provider asset.
  deleteProviderVehiclesDetails(data): Observable<any> {
    const deleteVehicleData: any = {
      body: { providerVehicleKey: data.providerVehicleKey },
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providervehicles`, deleteVehicleData);
  }

  // API call to get provider vehicle additional details.
  getProviderVehicleAdditionalDetails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providervehiclesadditionaldetails/get`, data);
  }

  // API call to add provider vehicle addditional details.
  addProviderVehiclesAdditionalDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providervehiclesadditionaldetails`, data);
  }

  // API call to update provider vehicle additional details.
  updateProviderVehiclesAddDetails(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providervehiclesadditionaldetails`, data);
  }

  // API call to delete provider additional details.
  deleteProviderVehiclesAdditionalDetails(data): Observable<any> {
    const deleteVehicleData: any = {
      body: { providerVehiclesAdditionalDetailKey: data.providerVehiclesAdditionalDetailKey },
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providervehiclesadditionaldetails`,
    deleteVehicleData);
  }

}
