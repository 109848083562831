import { Component, ViewChildren, QueryList, OnChanges, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEditBrokerAgencyComponent } from 'src/app/dialogs/add-edit-broker-agency/add-edit-broker-agency.component';
import { AddEditPolicyComponent } from 'src/app/dialogs/add-edit-policy/add-edit-policy.component';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { ConfirmDeleteComponent } from 'src/app/commons//confirm-delete/confirm-delete.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { InsuranceRequirements } from 'src/app/models/insurance-requirements.model';
import { AccountRequirementsDialogComponent } from 'src/app/dialogs/account-requirements-dialog/account-requirements-dialog.component';

@Component({
  selector: 'app-provider-admin-insurance',
  templateUrl: './provider-admin-insurance.component.html',
  styleUrls: ['./provider-admin-insurance.component.scss']
})
export class ProviderAdminInsuranceComponent implements OnInit, OnChanges {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @ViewChild('file', { static: false }) myInputVariable: ElementRef;
  @Input() providerDetails: any = [];
  @Input() isAddButton = false;

  loading = false;
  insuranceDetails: any = [];
  brokerDetails: any = [];
  policyDetails: any = [];
  requirements: any = [];
  policyDetailsArr: any = [];
  disableFlag = false;

  fileData: File;

  constructor(
    public dialog: MatDialog,
    private toast: NbToastrService,
    private dialogService: NbDialogService,
    private utilityService: UtilityService,
    private providerAdminService: ProviderAdminService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.providerDetails.providerKey) {
      this.getInsuranceDetails();
    }
    else if (this.providerDetails.providerKey == undefined){
      this.providerDetails.providerKey = this.providerDetails.ProviderKey;
      this.getInsuranceDetails();
    }
  }

  getInsuranceDetails() {
    this.providerAdminService.getLicensedOwnerInsuranceRequirements({ deleted: false }).subscribe((result: any) => {
      this.requirements = result.body;
      this.getBrokerDetails();
    });
  }

  getBrokerDetails() {
    const filter = {
      providerKey: this.providerDetails.providerKey,
      deleted: false
    };
    this.brokerDetails = [];
    this.policyDetails = [];
    this.policyDetailsArr = [];
    this.loading = true;
    this.providerAdminService.getProviderInsuranceDetails(filter).subscribe((res: any) => {
      this.loading = false;
      this.insuranceDetails = res.body;
      this.insuranceDetails.forEach(element => {
        if (element.parentBrokerKey == null) {
          this.brokerDetails.push(element);
        } else {
          this.policyDetailsArr.push(element);
        }
      });
      this.policyDetailsArr.forEach(element => {
        if (this.requirements.find(a => a.insuranceTypeKey === element.insuranceTypeKey)) {
          this.policyDetails.push(new InsuranceRequirements(element, this.requirements));
        }
      });
      this.brokerDetails = this.utilityService.sortData(this.headers, 'agencyName', 'asc', this.brokerDetails);
      this.onSort({ column: 'insuranceTypeKey', direction: 'asc' });
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.policyDetails = this.utilityService.sortData(this.headers, column, direction, this.policyDetails);
  }

  addNewbroker() {
    const dialogRef = this.dialog.open(AddEditBrokerAgencyComponent, {
      width: '70%',
      disableClose: true,
      position: {
        top: '4%'
      },
      data: {
        providerKey: this.providerDetails.providerKey
      }
    });
    dialogRef.componentInstance.event.subscribe(() => {
      this.getBrokerDetails();
    });
  }

  addEditPolicyType(data, flag) {
    let value;
    if (flag === 0) {
      value = {
        parentKey: data,
        providerKey: this.providerDetails.providerKey
      };
    } else {
      value = data;
    }
    const dialogRef = this.dialog.open(AddEditPolicyComponent, {
      width: '70%',
      disableClose: true,
      position: {
        top: '4%'
      },
      data: { value }
    });
    dialogRef.componentInstance.eventNewPolicy.subscribe(() => {
      this.getBrokerDetails();
    });
  }

  deletePolicyDetails(policyKey) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const updateData = {
          providerInsuranceKey: policyKey,
          deleted: true,
        };
        this.loading = true;
        this.providerAdminService.updateProviderInsurances(updateData).subscribe((res: any) => {
          this.loading = false;
          if (res.success) {
            this.toast.success(res.message[0], 'Success');
          } else {
            this.toast.warning(res.message[0], 'Error');
          }
          this.getBrokerDetails();
        }, (error) => {
          this.loading = false;
          console.log(error);
        });

      }
    });
  }

  getAgencyChilds(providerInsuranceKey) {
    const filter = {
      ParentBrokerKey: providerInsuranceKey,
    };

    this.providerAdminService.getProviderInsuranceDetails(filter).subscribe((res: any) => {
      const updateData = [];
      res.body.forEach(element => {
        if (element.providerInsuranceKey) {
          updateData.push({
            providerInsuranceKey: element.providerInsuranceKey,
            deleted: true,
          });
        }
      });

      updateData.push({
        providerInsuranceKey,
        deleted: true,
      });

      this.deleteProviderInsuranceBatch(updateData);
    }, (error) => {
      console.log('error', error);
    });
  }

  deleteProviderInsuranceBatch(updateData) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        this.loading = true;
        this.providerAdminService.updateProviderInsurancesBatch(updateData).subscribe((res: any) => {
          this.loading = false;

          if (res.success) {
            this.toast.success(res.message[0], 'Success');
          } else {
            this.toast.warning(res.message[0], 'Error');
          }

          this.getBrokerDetails();
        }, (error) => {
          console.log('error', error);
          this.loading = false;
        });
      }

    });
  }

  // image preview
  preview(files: any, event: any, policyDetail) {
    this.fileData = <File>event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('fileType', 'image');
    if (files.length === 0) {
      return false;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null && mimeType.match('application/pdf') == null) {
      this.toast.danger('Only image and PDF formats are supported.');
      return false;
    }

    const size = files[0].size;
    if (Math.round(size / 1024) > 5120) {
      this.toast.danger('Size should not exceed 5MB.');
      this.myInputVariable.nativeElement.value = '';
      return false;
    }

    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {

      this.loading = true;
      this.providerAdminService.updateProfilePicture(formData).subscribe(resFileDetail => {
        this.loading = false;
        if (resFileDetail['status']) {
          this.toast.success('Policy Uploaded.!', 'Success');

          if (policyDetail.cartificateURL) {
            const deleteImg = new FormData();
            deleteImg.append('file_name', atob(policyDetail.cartificateURL));

            this.loading = true;
            this.providerAdminService.deleteProfilePicture(deleteImg).subscribe((response: any) => {
              this.loading = false;
            }, (error) => {
              this.loading = false;
            });
          }

        } else {
          this.toast.danger('Failed to upload policy.!', 'Error');
        }

        const updateData = {
          providerInsuranceKey: policyDetail.providerInsuranceKey,
          cartificateURL: resFileDetail.url,
        };

        this.loading = true;
        this.providerAdminService.updateProviderInsurances(updateData).subscribe((response: any) => {
          this.loading = false;
          this.getBrokerDetails();
        }, (error) => {
          this.loading = false;
          this.toast.danger(error.message, 'Error');
        });
      }, (error) => {
        this.loading = false;
        this.toast.danger('Failed to update policy.!', 'Error');
      });
    };
  }

  viewPolicy(cartificateURL) {
    if (cartificateURL) {
      window.open(atob(cartificateURL));
    } else {
      this.toast.info('No Policy Found', 'Information');
    }
  }

  showAccountRequirements(policyDetail) {
    this.dialog.open(AccountRequirementsDialogComponent, {
      hasBackdrop: false,
      width: '70%',
      position: {
        top: '5%',
      },
      data: { policyDetail },
    });
  }

}
