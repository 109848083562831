<app-loader [loading]="loading"></app-loader>
<ng-container *ngIf="customerAuthorization">
  <form [formGroup]="searchThresholdForm">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field>
          <mat-select placeholder="Select Hierarchy Tier" formControlName="hierarchyTier">
            <mat-option disabled>Select Hierarchy Tier</mat-option>
            <mat-option *ngFor="let customerTier of customerTiers | orderBy : 'tierLevel'"
              [value]="customerTier.customerTierStructureKey">
              {{customerTier.tierLevel + '-' +customerTier.tierName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <mat-select placeholder="Select Security Group" formControlName="securityGroup">
            <mat-option disabled>Select Security Group</mat-option>
            <mat-option *ngFor="let securityGroup of securityGroups | orderBy : 'description'"
              [value]="securityGroup.securityGroupKey">
              {{securityGroup.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <button type="button" class="btn btn-primary btn-sm mt-2 mr-3" (click)="searchThreshold()">Search</button>
        <button type="button" class="btn btn-primary btn-sm mt-2" (click)="clearSearchAndGetThresholds()">Clear</button>
      </div>
      <div class="col-md-1">
        <app-instructions [dataDictionaryKey]="customerAuthorizationDataDictionaryKey" [schema]="schema">
        </app-instructions>
      </div>
    </div>
  </form>
</ng-container>
<div class="ml-1" *ngIf="getOverallParentRecord || getServiceParentRecord"><h6>Default Thresholds:</h6></div>
<div class="row">
  <div class="col-md-12 ml-1 row mt-2">
    <div class="col-md-7 row">
      <div class="mt-2">
        <h6>Overall Thresholds</h6>
      </div>
      <div *ngIf="!customerAuthorization" class="col-md-4 icon-size">
        <app-instructions [dataDictionaryKey]="overallDataDictionaryKey" [schema]="schema"></app-instructions>
      </div>
    </div>
    <div [ngClass]="{
      'col-md-2': !customerAuthorization,
      'col-md-5 text-right ml-1': customerAuthorization
    }" class="mt-2">
      <button type="button" class="btn btn-primary btn-sm" [disabled]="readOnlyUser || !isUltimateParent" (click)="addThreshold(true)">Add
        record</button>
    </div>
    <div *ngIf="!readOnlyUser && !customerAuthorization" class="col-md-2 ml-4 mt-2">
      <button [ngClass]="{
      'btn btn-sm bg-success': userDetails.isApprover === true,
      'btn btn-sm bg-secondary text-white': userDetails.isApprover === false || userDetails.isApprover === null
    }" class="font-weight-light" [disabled]="readOnlyUser" type="button" (click)="approver()">{{buttonText}}</button>
    </div>
  </div>
  <div [ngClass]="{
    'col-md-8': isApproverSection === true && !readOnlyUser,
    'col-md-12': isApproverSection === false || readOnlyUser
  }" class="table-responsive div-margin">
    <table class="table table-bordered table-margin table-striped" [ngClass]="{'threshold-parent': getOverallParentRecord}">
      <thead>
        <tr>
          <th *ngIf="customerAuthorization" sortable="tierName" (sort)="onOverallLimitsSort($event)">Hierarchy Tier</th>
          <th *ngIf="customerAuthorization" sortable="description" (sort)="onOverallLimitsSort($event)">Security Group
          </th>
          <th sortable="operatingScheduleKey" (sort)="onOverallLimitsSort($event)">Operating Schedule</th>
          <th sortable="minimumThreshold" (sort)="onOverallLimitsSort($event)">Min $ Threshold</th>
          <th sortable="maximumThreshold" (sort)="onOverallLimitsSort($event)">Max $ Threshold</th>
          <th *ngIf="!getOverallParentRecord">Action</th>
        </tr>
      </thead>
      <tbody *ngIf="overallLimits.length > 0; else noOverallData">
        <tr *ngFor="let overallLimit of overallLimits; let i = index">
          <td *ngIf="customerAuthorization">{{overallLimit.tierName}}</td>
          <td *ngIf="customerAuthorization">{{overallLimit.description}}</td>
          <td>
            <span *ngIf="!(overallLimit.editMode)">{{overallLimit.operatingScheduleKey}}</span>
            <span *ngIf="overallLimit.editMode">
              <mat-form-field>
                <mat-select placeholder="Operating Schedule" [(ngModel)]="overallLimit.operatingScheduleKey" required>
                  <mat-option disabled>Select Operating Schedule</mat-option>
                  <mat-option *ngFor="let operatingSchedule of operatingSchedules"
                    [value]="operatingSchedule.operatingScheduleKey">{{operatingSchedule.operatingScheduleKey}}
                  </mat-option>
                </mat-select>
                <mat-error>Please select Operating Schedule.</mat-error>
              </mat-form-field>
            </span>
          </td>
          <td>
            <label *ngIf="!(overallLimit.editMode)">
              <span *ngIf="overallLimit.minimumThreshold > 0">$</span>
              {{overallLimit.minimumThreshold}}</label>
            <span *ngIf="overallLimit.editMode">
              <mat-form-field>
                <input matInput type="number" min="0" [(ngModel)]="overallLimit.minimumThreshold" required placeholder="Min $ Limit">
                <span matPrefix *ngIf="overallLimit.minimumThreshold > 0">$</span>
                <mat-error>
                  Minimum Threshold is required.
                </mat-error>
              </mat-form-field>
            </span>
          </td>
          <td>
            <label *ngIf="!(overallLimit.editMode)">
              <span *ngIf="overallLimit.maximumThreshold > 0">$</span>
              {{overallLimit.maximumThreshold}}</label>
            <span *ngIf="overallLimit.editMode">
              <mat-form-field>
                <input matInput type="number" min="0" [(ngModel)]="overallLimit.maximumThreshold" placeholder="Max $ Limit">
                <span matPrefix *ngIf="overallLimit.maximumThreshold > 0">$</span>
              </mat-form-field>
            </span>
          </td>
          <td *ngIf="!getOverallParentRecord">
            <button *ngIf="!(overallLimit.editMode)" class="btn btn-default btnAnimation" nbTooltip="Edit"
              (click)="editOverallLimit(i)" [disabled]="readOnlyUser || overallLimit.isDisabled || disableOverallLimitsEdit || !isUltimateParent">
              <i class="fas fa-pencil"></i>
            </button>
            <button *ngIf="!(overallLimit.editMode)" [disabled]="readOnlyUser || overallLimit.isDisabled || !isUltimateParent"
              class="btn btn-sm btn-default btn-sm btnAnimation" nbTooltip="Delete"
              (click)="deleteLimits(overallLimit)">
              <i class="fas fa-trash-alt"></i>
            </button>
            <button *ngIf="overallLimit.editMode" class="btn btn-default btnAnimation" nbTooltip="Update"
              (click)="updateLimits(overallLimit, 'Overall Limit')">
              <i class="fas fa-check"></i>
            </button>
            <button *ngIf="overallLimit.editMode" class="btn btn-default btnAnimation" (click)="cancelOverallLimits(i)"
              nbTooltip="Cancel">
              <i class="fas fa-times"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <ng-template #noOverallData>
        <tbody>
          <tr>
            <td *ngIf="!customerAuthorization" colspan="4" class="text-center">No data available</td>
            <td *ngIf="customerAuthorization" colspan="6" class="text-center">No data available</td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <ng-container *ngIf="!readOnlyUser">
    <div *ngIf="isApproverSection" class="col-md-3 row approval-section">
      <div *ngIf="isEmergencyApprover" class="col-md-10 mt-2">
        <mat-slide-toggle labelPosition="before" [checked]="userDetails.isEmergencyApprover"
          (change)="emergencyApprover($event)"><label class="bold-font">Emergency Approver</label>
        </mat-slide-toggle>
      </div>
      <div *ngIf="isEmergencyApprover" class="col-md-1 mt-1 icon-size">
        <app-instructions [dataDictionaryKey]="approverDataDictionaryKey" [schema]="schema"></app-instructions>
      </div>
      <div class="col-md-12">
        <mat-slide-toggle [disabled]="!userDetails.isEmergencyApprover" labelPosition="before" [checked]="userDetails.emergencyProceedAuthorization"
          (change)="emergencyProceedAuthorization($event)"><label class="bold-font">Proceed & Notify</label>
        </mat-slide-toggle>
      </div>
    </div>
  </ng-container>
</div>
<div class="row mt-3">
  <div class="col-md-10">
    <h6>Specific Service Exception Thresholds</h6>
  </div>
  <div class="col-md-2">
    <button type="button" class="btn btn-primary btn-sm ml-1" (click)="openServiceThreshold()" [disabled]="readOnlyUser || !isUltimateParent">Add
      record</button>
  </div>
</div>
<div class="mt-2 table-responsive">
  <table class="table table-bordered table-striped" [ngClass]="{'threshold-parent': getServiceParentRecord}">
    <thead>
      <tr>
        <th class="header-width" *ngIf="customerAuthorization" sortable="tierName" (sort)="onserviceExceptionLimitsSort($event)">Hierarchy
          Tier</th>
        <th class="header-width" *ngIf="customerAuthorization" sortable="description" (sort)="onserviceExceptionLimitsSort($event)">Security
          Group</th>
        <th class="header-width" sortable="offerings" (sort)="onserviceExceptionLimitsSort($event)">Service</th>
        <th class="header-width" sortable="operatingScheduleKey" (sort)="onserviceExceptionLimitsSort($event)">Operating Schedule</th>
        <th sortable="minimumThreshold" (sort)="onserviceExceptionLimitsSort($event)">Min $ Threshold</th>
        <th sortable="maximumThreshold" (sort)="onserviceExceptionLimitsSort($event)">Max $ Threshold</th>
        <th sortable="quantityMinimum" (sort)="onserviceExceptionLimitsSort($event)">Min Quant</th>
        <th sortable="quantityMaximum" (sort)="onserviceExceptionLimitsSort($event)">Max Quant</th>
        <th *ngIf="!getServiceParentRecord">Action</th>
      </tr>
    </thead>
    <tbody *ngIf="serviceExceptionLimits.length > 0; else noServiceData">
      <tr *ngFor="let serviceExceptionLimit of serviceExceptionLimits; let i = index">
        <td *ngIf="customerAuthorization">{{serviceExceptionLimit.tierName}}</td>
        <td *ngIf="customerAuthorization">{{serviceExceptionLimit.description}}</td>
        <td>{{serviceExceptionLimit.offerings}}</td>
        <td>
          <span *ngIf="!(serviceExceptionLimit.editMode)">
            {{serviceExceptionLimit.operatingScheduleKey}}
          </span>
          <span *ngIf="serviceExceptionLimit.editMode">
            <mat-form-field>
              <mat-select placeholder="Operating Schedule" [(ngModel)]="serviceExceptionLimit.operatingScheduleKey"
                required>
                <mat-option disabled>Select Operating Schedule</mat-option>
                <mat-option *ngFor="let operatingSchedule of operatingSchedules"
                  [value]="operatingSchedule.operatingScheduleKey">{{operatingSchedule.operatingScheduleKey}}
                </mat-option>
              </mat-select>
              <mat-error>Please select Operating Schedule.</mat-error>
            </mat-form-field>
          </span>
        </td>
        <td>
          <label *ngIf="!(serviceExceptionLimit.editMode)">
            <span *ngIf="serviceExceptionLimit.minimumThreshold > 0">$</span>
            {{serviceExceptionLimit.minimumThreshold}}
          </label>
          <span *ngIf="serviceExceptionLimit.editMode">
            <mat-form-field>
              <input matInput type="number" min="0" [(ngModel)]="serviceExceptionLimit.minimumThreshold" required
                placeholder="Min $ Threshold">
                <span matPrefix *ngIf="serviceExceptionLimit.minimumThreshold > 0">$</span>
              <mat-error>
                Minimum Threshold is required.
              </mat-error>
            </mat-form-field>
          </span>
        </td>
        <td>
          <label *ngIf="!(serviceExceptionLimit.editMode)">
            <span *ngIf="serviceExceptionLimit.maximumThreshold > 0">$</span>
            {{serviceExceptionLimit.maximumThreshold}}
          </label>
          <span *ngIf="serviceExceptionLimit.editMode">
            <mat-form-field>
              <input matInput type="number" min="0" [(ngModel)]="serviceExceptionLimit.maximumThreshold" placeholder="Max $ Threshold">
              <span matPrefix *ngIf="serviceExceptionLimit.maximumThreshold > 0">$</span>
            </mat-form-field>
          </span>
        </td>
        <td>
          <label *ngIf="!(serviceExceptionLimit.editMode)">
            {{serviceExceptionLimit.quantityMinimum}}
          </label>
          <span *ngIf="serviceExceptionLimit.editMode">
            <mat-form-field>
              <input matInput [(ngModel)]="serviceExceptionLimit.quantityMinimum" required placeholder="Min Quant">
              <mat-error>
                Maximum Quantity is required.
              </mat-error>
            </mat-form-field>
          </span>
        </td>
        <td>
          <label *ngIf="!(serviceExceptionLimit.editMode)">
            {{serviceExceptionLimit.quantityMaximum}}
          </label>
          <span *ngIf="serviceExceptionLimit.editMode">
            <mat-form-field>
              <input matInput [(ngModel)]="serviceExceptionLimit.quantityMaximum" placeholder="Max Quant">
            </mat-form-field>
          </span>
        </td>
        <td *ngIf="!getServiceParentRecord">
          <button *ngIf="!(serviceExceptionLimit.editMode)" class="btn btn-default btnAnimation" nbTooltip="Edit"
            (click)="editServiceExceptionLimits(i)" [disabled]="readOnlyUser || serviceExceptionLimit.isDisabled || disableServiceExceptionLimitsEdit || !isUltimateParent">
            <i class="fas fa-pencil"></i>
          </button>
          <button *ngIf="!(serviceExceptionLimit.editMode)" [disabled]="readOnlyUser || serviceExceptionLimit.isDisabled || !isUltimateParent"
            class="btn btn-sm btn-default btn-sm btnAnimation" nbTooltip="Delete"
            (click)="deleteLimits(serviceExceptionLimit)">
            <i class="fas fa-trash-alt"></i>
          </button>
          <button *ngIf="serviceExceptionLimit.editMode" class="btn btn-default btnAnimation" nbTooltip="Update"
            (click)="updateLimits(serviceExceptionLimit, 'Service Exception')">
            <i class="fas fa-check"></i>
          </button>
          <button *ngIf="serviceExceptionLimit.editMode" class="btn btn-default btnAnimation"
            (click)="cancelServiceExceptionLimits(i)" nbTooltip="Cancel">
            <i class="fas fa-times"></i>
          </button>
        </td>
      </tr>
    </tbody>
    <ng-template #noServiceData>
      <tbody>
        <tr>
          <td *ngIf="!customerAuthorization" colspan="7" class="text-center">No data available</td>
          <td *ngIf="customerAuthorization" colspan="9" class="text-center">No data available</td>
        </tr>
      </tbody>
    </ng-template>
  </table>
</div>