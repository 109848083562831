import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { Question } from '../model/question.model';
import { DynamicQuestionsService } from 'src/app/services/dynamic-questions.service';
import { DynamicYesNoFormComponent } from '../dynamic-yes-no-form/dynamic-yes-no-form.component';
import { CreateDynamicFormComponent } from '../create-dynamic-form-modal/create-dynamic-form.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})

export class AddQuestionComponent implements OnInit, OnChanges {

  @Input() questionKey?: any;
  @Input() isCreateNew?: any;
  @Input() isEmitJson = false;
  @Output() newDynamicFormKey = new EventEmitter();
  @Output() newDynamicJson = new EventEmitter();

  arrQuestions: Array<Question> = [new Question()];
  loading = false;
  userKey: string;
  otherTextDescriptions: string;
  questionsResponseTypes: any = [];
  arrSubQuestions: any = [];
  isLoadChildQuestions = false;
  responseTypes = ['form', 'YesNo'];

  constructor(
    private dynamicQuestionsService: DynamicQuestionsService,
    private toast: NbToastrService,
    public dialog: MatDialog,
    private dialogService: NbDialogService) { }

  ngOnInit() {
    this.userKey = sessionStorage.getItem('userKey');
    this.arrQuestions = [];

    if (this.questionKey) {
      this.getQuestions();
    }
  }

  ngOnChanges() {
    this.arrQuestions = [];
    if (this.questionKey) {
      this.getQuestions();
    }
  }

  getQuestions() {
    this.loading = true;
    const filter = { dynamicFormKey: this.questionKey };
    this.dynamicQuestionsService.getDynamicFormQuestions(filter).subscribe(response => {
      if (response.success && response.body.length) {
        try {
          this.arrQuestions = JSON.parse(atob(response.body[0].json));
        } catch (e) {
          this.arrQuestions = [];
          console.log(e); // error in the above string (in this case, yes)!
        }
        // this.arrQuestions = JSON.parse(atob(response.body[0].json));
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toast.danger(error.message, 'Error');
    });
  }

  trackByQustionKey(index: number, question: any) {
    return question.key;
  }

  isFormPresent(item) {
    if (item.type === 'form' && Object.keys(item.components).length > 0) {
      return true;
    } else if (item.type === 'YesNo' && item.childrens.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  createDynamicForm() {
    const data = {
      json: JSON.stringify(this.arrQuestions),
      deleted: false,
      insertedUserKey: sessionStorage.getItem('userKey'),
      systemDefault: false,
      updatedUserKey: '',
    };
    this.loading = true;
    this.dynamicQuestionsService.addDynamicFormQuestions(data).subscribe(response => {
      if (response.success) {
        this.newDynamicFormKey.emit(response.body[0].dynamicFormKey);
        // this.toast.success(response.message, 'Success');
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toast.danger(error.message, 'Error');
    });
  }

  updateQuestions() {
    const data = {
      dynamicFormKey: this.questionKey,
      json: JSON.stringify(this.arrQuestions),
      updatedUserKey: sessionStorage.getItem('userKey'),
    };

    this.loading = true;
    this.dynamicQuestionsService.updateDynamicFormQuestions(data).subscribe(response => {
      if (response.success) {
        this.toast.success(response.message, 'Success');
        this.newDynamicFormKey.emit(this.questionKey);
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toast.danger(error.message, 'Error');
    });
  }

  saveJson() {
    if (this.isEmitJson) {
      this.newDynamicJson.emit(this.arrQuestions);
    } else {
      if (this.questionKey) {
        this.updateQuestions();
      } else {
        this.createDynamicForm();
      }
    }
  }

  addNewQuestion() {
    const question = new Question();
    question.order = this.arrQuestions.length + 1;
    this.arrQuestions.push(question);
  }

  addSubQuestion(item: any = {}) {
    const question = new Question();
    question.order = item.childrens.length + 1;
    question.parentKey = item.key;
    item.childrens.push(question);
  }

  subQuestionChanged(event) {
    console.log(event);
  }

  previewFormDetails(question: Question) {
    if (question.type === 'form') {
      const dialogRef = this.dialog.open(CreateDynamicFormComponent, {
        hasBackdrop: true,
        width: '90%',
        height: '80%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: {
          title: question.title,
          arrFieldModels: question.components,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.jsonText) {
          question.display = 'Form';
          question.components = result.jsonText;
        }
      });

    } else if (question.type === 'YesNo') {
      const dialogRef = this.dialog.open(DynamicYesNoFormComponent, {
        hasBackdrop: true,
        width: '66%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: {
          question
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          question.display = 'YesNo';
          question.childrens = result.arrQuestions;
        }
      });
    }
  }

  openFormComponent(event, question) {

    if (event.value === 'form') {
      const dialogRef = this.dialog.open(CreateDynamicFormComponent, {
        hasBackdrop: true,
        width: '90%',
        height: '80%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: { title: question.title }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.jsonText) {
          question.display = 'Form';
          question.components = result.jsonText;
        }
      });

    } else if (event.value === 'YesNo') {
      const dialogRef = this.dialog.open(DynamicYesNoFormComponent, {
        hasBackdrop: true,
        width: '66%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: {
          question
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          const arrQuestions = result.arrQuestions.filter(obj => obj.title);
          question.display = 'YesNo';
          question.childrens = arrQuestions;
        }
      });
    }
  }

  removeFormElements(item: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const itemIndex = this.arrQuestions.indexOf(item);
        if (itemIndex > -1) {
          this.arrQuestions.splice(itemIndex, 1);
        }
      }
    });
  }

  clearFormDetails(item: any) {
    if (item.type === 'form') {
      item.components = '';
      item.type = '';
    } else if (item.type === 'YesNo') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        height: 'auto',
        maxHeight: '50%',
        width: '33%',
        data: {
          label: 'Do you also want to remove Child Questions?'
        }
      });

      dialogRef.afterClosed().subscribe(isValue => {
        if (isValue) {
          item.type = '';
          item.display = '';
          item.childrens = [];
        }
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arrQuestions, event.previousIndex, event.currentIndex);
    this.resetQuestionsOrder();
  }

  resetQuestionsOrder() {

    this.arrQuestions.map((questions, index) => {
      questions.order = index + 1;
    });
  }
}
