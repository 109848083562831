import { Component, OnInit, QueryList, ViewChildren, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LineItemService } from '../../services/line-item.service';
import { UtilityService } from '../../services/utility.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-provider-discount-lineitem-dialog',
  templateUrl: './provider-discount-lineitem-dialog.component.html',
  styleUrls: ['./provider-discount-lineitem-dialog.component.scss']
})

export class ProviderDiscountLineitemDialogComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  lineItemForm: UntypedFormGroup;
  lineItems = [];
  loading = false;
  isEdit = false;
  providerDiscountAppliesLineItems = [];

  constructor(
    private dialog: MatDialogRef<ProviderDiscountLineitemDialogComponent>,
    private lineItemService: LineItemService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public Data: any,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    private dialogService: NbDialogService) { }

  ngOnInit() {
    this.lineItemForm = this.formBuilder.group({
      lineItemKey: [''],
    });

    this.isEdit = this.Data;
    this.getLineItems();
    this.getProviderDiscountAppliesLineItems();
  }

  getLineItems() {
    this.loading = true;
    const filter = {
      providerDiscountApplies: false,
      deleted: false
    };

    this.lineItemService.getLineItem(filter).subscribe(lineItemResponse => {
      this.lineItems = lineItemResponse.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getProviderDiscountAppliesLineItems() {
    this.loading = true;
    const filter = {
      providerDiscountApplies: true,
      deleted: false
    };

    this.lineItemService.getLineItem(filter).subscribe(lineItemResponse => {
      this.providerDiscountAppliesLineItems = lineItemResponse.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  updateProviderDiscountAppliesLineItems(data, providerApplies) {
    let updateLineItem;
    if (providerApplies === 'updateProviderDiscountApplies') {
      this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
        if (isDelete) {
          updateLineItem = {
            lineItemKey: data.lineItemKey,
            providerDiscountApplies: true
          };
          this.updateLineItem(updateLineItem, providerApplies);
        }
      });
    } else {
      if (this.lineItemForm.controls.lineItemKey.value === '' || this.lineItemForm.controls.lineItemKey.value === null) {
        this.loading = false;
        this.toast.warning('Select Line Item', 'Warning');
        return false;
      }
      updateLineItem = {
        lineItemKey: this.lineItemForm.controls.lineItemKey.value,
        providerDiscountApplies: false
      };
      this.updateLineItem(updateLineItem, providerApplies);
    }
  }

  updateLineItem(updateLineItem, providerApplies) {
    this.loading = true;
    this.lineItemService.updateLineItem(updateLineItem).subscribe(lineItemResponse => {
      if (lineItemResponse.success) {
        if (providerApplies === 'updateProviderDiscountApplies') {
          this.toast.success('Removed From List', 'Success');
        } else {
          this.toast.success('Added To List', 'Success');
        }
        this.getLineItems();
        this.getProviderDiscountAppliesLineItems();
        this.lineItemForm.reset();
      }
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  closeDialog() {
    this.dialog.close();
  }

  onSort({ column, direction }: SortEvent) {
    this.lineItems = this.utilityService.sortData(this.headers, column, direction, this.lineItems);
  }

}
