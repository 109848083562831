<app-loader [loading]="loading"></app-loader>
<form [formGroup]="customerSearchForm">
    <div class="row customer-search">
        <div class="col-md-4 p-0">
            <span *ngIf="!customerProfile">
                <mat-form-field>
                    <mat-select placeholder="Search for Corporate (Parent Customer) Level" formControlName="parent" (selectionChange)="getParent($event)">
                        <ng-container *ngFor="let customer of parentCustomerList">
                            <mat-option [value]="customer.customerKey">
                                {{ customer.customerName }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </span>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <input matInput placeholder="Enter Name or Account Number" formControlName="name">
                <mat-error>
                    Name or Account Number is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3 m-auto">
            <button class="btn btn-primary btn-sm mr-1" (click)="checkParent()">Search</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="clearFilters()">Clear</button>
        </div>
        <div class="col-md-1 m-auto">
            <app-instructions [dataDictionaryKey]="dataDictionaryKey" [schema]="schema"></app-instructions>
        </div>
    </div>
</form>
