import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ProviderService } from 'src/app/services/provider.service';
import { NbToastrService } from '@nebular/theme';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BubbleService } from 'src/app/services/bubble.service';
import { patterns } from 'src/assets/patterns';

@Component({
  selector: 'app-add-edit-eta-dialog',
  templateUrl: './add-edit-eta-dialog.component.html',
  styleUrls: ['./add-edit-eta-dialog.component.scss']
})
export class AddEditEtaDialogComponent implements OnInit {

  updateETA = new EventEmitter();

  etaForm: UntypedFormGroup;
  loading = false;
  providerAccountKey: any;

  constructor(
    private providerService: ProviderService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: NbToastrService,
    public dialog: MatDialogRef<AddEditEtaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bubbleService: BubbleService
  ) {
  }

  ngOnInit() {
    this.etaForm = this.formBuilder.group({
      providerOfferingETAKey: [''],
      morningRushHourETA: ['', [Validators.pattern(patterns.numberPattern)]],
      standardETA: ['', [Validators.pattern(patterns.numberPattern)]],
      afternoonRushHourETA: ['', [Validators.pattern(patterns.numberPattern)]],
      afterHoursETA: ['', [Validators.pattern(patterns.numberPattern)]],
      updatedUserKey: [''],
      providerServiceOfferingKey: ['']
    });

    this.etaForm.setValue({
      providerOfferingETAKey: this.data.data.ProviderOfferingETAKey,
      morningRushHourETA: this.data.data.MorningRushHourETA,
      standardETA: this.data.data.StandardETA,
      afternoonRushHourETA: this.data.data.AfternoonRushHourETA,
      afterHoursETA: this.data.data.AfterHoursETA,
      updatedUserKey: sessionStorage.getItem('userKey'),
      providerServiceOfferingKey: this.data.data.ProviderServiceOfferingKey
    });
  }


  editETA() {
    if (this.etaForm.invalid) {
      return false;
    }
    if (this.data.parentRecord) {
      const data = {
        locationKey: this.data.providerAccount.providerKey,
        providerKey: sessionStorage.getItem('providerKey'),
        licensedOwnerKey: this.data.providerAccountlicensedOwnerKey,
        providerAccountKey: this.data.providerAccountKey,
        locationAccountKey: this.data.providerAccount.providerAccountKey,
      };
      this.loading = true;
      this.bubbleService.setUpServiceAndRates(data).subscribe(result => {
        this.loading = false;
        this.providerAccountKey = this.data.providerAccount.providerAccountKey;
        this.bubbleService.setBubbleTreeData();
        this.updateSelectedRecord();
      }, (error) => {
        this.loading = false;
      });
    } else {
      this.updateProviderOfferingETA();
    }

  }

  updateProviderOfferingETA() {
    this.loading = true;
    this.providerService.updateProviderOfferingETA(this.etaForm.value).subscribe((res: any) => {
      this.toastrService.success('Updated SuccessFully!', 'Success');
      this.loading = false;
      this.updateETA.emit();
      this.dialog.close();
    }, (error) => {
      this.loading = false;
      this.toastrService.danger(error, 'Error');
    });
  }

  // close dialog
  closeDialog() {
    this.dialog.close();
  }

  updateSelectedRecord() {
    const data = {
      providerAccountKey: this.providerAccountKey,
      providerKey: this.data.providerAccount.providerKey,
      providerServiceOfferingKey: this.etaForm.get('providerServiceOfferingKey').value
    };

    this.providerService.getProviderServiceOfferingsETAs(data).subscribe((response) => {
      this.etaForm.get('providerOfferingETAKey').setValue(response.body[0].providerOfferingETAKey);
      this.updateProviderOfferingETA();
    });
  }

}
