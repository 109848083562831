<app-loader [loading]='loading'></app-loader>
<nb-card>
  <nb-card-header *ngIf="isLoggedInUser">National Accounts</nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12 col-md-4">
        <ngx-search-input (search)="search($event)"></ngx-search-input>
      </div>
      <div class="col-12 col-md-8 text-right my-md-auto">
        <button class="btn btn-primary btn-sm" (click)="addEditNationalAccount('Add', '')">Add</button>
      </div>
    </div>
    <div class="table-responsive mt-2 national-account-tbl">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th sortable="Level_1_Desc" (sort)="onSort($event)" class="align-middle" direction="asc">Service Type
            </th>
            <th sortable="Level_2_Desc" (sort)="onSort($event)" class="align-middle" direction="asc">Sub Service
              Type</th>
            <th sortable="nationalAccountKey" (sort)="onSort($event)" class="align-middle" direction="asc">Account Name
            </th>
            <th sortable="priority" (sort)="onSort($event)" class="align-middle">Priority</th>
            <th sortable="accountNumber" (sort)="onSort($event)" class="align-middle">Account Number</th>
            <th sortable="pOMask" (sort)="onSort($event)" class="align-middle">PO Mask</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="nationalAccountData.length > 0; else noData">
          <tr *ngFor="let item of nationalAccountData | filter : searchString;">
            <td>{{ item.Level_1_Desc }}</td>
            <td>{{ item.Level_2_Desc }}</td>
            <td>{{ item.nationalAccountKey }}</td>
            <td>{{ item.priority }}</td>
            <td>{{ item.accountNumber }}</td>
            <td>{{ item.pOMask }}</td>
            <td> 
              <a (click)="addEditNationalAccount('Save',item)">Edit / </a>
              <a (click)="deactivate(item)">{{item.saveText}}</a>
            </td>
          </tr>
        </tbody>
        <ng-template #noData>
          <tbody>
            <tr>
              <td colspan="13" class="text-center">No Data available</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
    <div class="mt-2 text-right" *ngIf="isLoggedInUser">
      <button class="btn btn-primary btn-sm mt-2" (click)="nextPage()">Next</button>
    </div>
  </nb-card-body>
</nb-card>
