<h1 mat-dialog-title>{{data.companyName}} - {{data.fullAddress}}</h1>
<div mat-dialog-content>


    <h1 mat-dialog-title>Matching Location</h1>

    <table mat-table [dataSource]="dataSource1">

        <!-- Select Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef> Select </th>
            <td mat-cell *matCellDef="let element">
                <mat-radio-group [(ngModel)]="selectedLocation" (change)="onChangeLocation($event)" (change)="log()">
                    <mat-radio-button [value]="element"></mat-radio-button>
                </mat-radio-group>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element"> {{element.physicalAddress1}}, {{element.physicalCity}},
                {{element.physicalStateKey}} </td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let element"> {{element.businessHoursPhone}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colSpan]="4" style="text-align: center; padding: 20px 0;">
                No data matching.
            </td>
        </tr>
    </table>

    <h1 mat-dialog-title>Matching Parent</h1>

    <table mat-table [dataSource]="dataSource2">
        <!-- Select Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef> Select </th>
            <td mat-cell *matCellDef="let element">
                <mat-radio-group [(ngModel)]="selectedParent" (change)="onChangeParent($event)" (change)="log()">
                    <mat-radio-button [value]="element"></mat-radio-button>
                </mat-radio-group>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.corporateName}} </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element"> {{element.mailingAddress1}}, {{element.mailingCity}},
                {{element.mailingStateKey}} </td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let element"> {{element.businessHoursPhone ? element.businessHoursPhone : 'N/A'}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colSpan]="4" style="text-align: center; padding: 20px 0;">
                No data matching.
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Close</button>
    <button mat-button (click)="matchApplication(data)">Match</button>
    <button mat-button (click)="deleteApplication(data)">Delete</button>
</div>