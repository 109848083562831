<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="row">
    <div class="col-12 col-md-4">
        <label>
            <span class="font-weight-bold">
                Location ID:
            </span>
            {{locationId}}
        </label>
    </div>
    <div class="col-12 col-md-4">
        <mat-form-field appearance="outline">
            <mat-label>Location Name</mat-label>
            <input matInput [value]="this.providerDetails?.name">
        </mat-form-field>
    </div>
    <div class="col-12 col-md-4 text-right has-search pt-2">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" [(ngModel)]="searchString" placeholder="Search"
            (input)="onInputChange($event)">
    </div>
</div>
<hr class="m-0">
<div class="row my-1">
    <div class="col-md-12 text-right">
        <button class="btn btn-orange btn-sm mt-2" (click)="addEditTechnician('add')">Add New</button>
    </div>
</div>
<div class="row">
    <div class="col-md-12 employee-section">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th class="text-center">First Name</th>
                        <th class="text-center">Last Name</th>
                        <th class="text-center">Cell Phone</th>
                        <th class="text-center">Email</th>
                        <th class="text-center">Edit</th>
                        <th class="text-center">Active</th>
                    </tr>
                </thead>
                <tbody *ngIf="technicianData.length > 0; else noData">
                    <tr *ngFor="let technician of technicianData | multiColumnFilter : searchString: ['firstName', 'lastName'];">
                        <td class="text-center">{{technician.firstName}}</td>
                        <td class="text-center">{{technician.lastName}}</td>
                        <td class="text-center">{{technician.cellPhone | mask:"000-000-0000"}}</td>
                        <td class="text-center">{{technician.email}}</td>
                        <td class="text-center">
                            <button class="btn btn-sm btn-default btn-sm btnAnimation" style="color: #ef8a3d;"
                                (click)="addEditTechnician('edit', technician)" nbTooltip="Edit">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>
                        <td class="text-center">
                            <label class="toggle-label mr-3">No</label>
                            <mat-slide-toggle class="example-margin" [color]="'#ef8a3d'" [checked]="technician.active"
                            (change)="setActiveInactive($event.checked, technician)">
                                <label class="toggle-label m-2">Yes</label>
                            </mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="6" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </div>
</div>
<hr>
<div #addEdit id="addEdit" *ngIf="addEditFlag">
    <fieldset>
        <legend>
            <span>
                <h6 class="text-capitalize">{{addEditFlag}} Technician</h6>
            </span>
        </legend>
        <div class="col-md-12">
            <ngx-add-edit-technician (updateTechniciansList)="updateTechniciansList($event)" [addEditFlag]="addEditFlag" [technicianData]="technicianEditData" [providerKey]="locationKey"></ngx-add-edit-technician>
        </div>
    </fieldset>
</div>
