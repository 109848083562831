export class InsuranceRequirements {
    public providerInsuranceKey: string;
    public providerKey: string;
    public insuranceTypeKey: string;
    public parentBrokerKey: string;
    public agencyName: string;
    public agentName: string;
    public agentPhone: number;
    public agentEmail: string;
    public agentFax: number;
    public policyExpDate: Date;
    public policyNumber: string;
    public cartificateURL: string;
    public systemDefault: boolean;
    public insertedTimestamp: Date;
    public insertedUserKey: string;
    public updatedTimestamp: Date;
    public updatedUserKey: string;
    public notRequired: boolean;
    public deleted: boolean;
    public requirement: any;
    public insuredAmount: number;

    constructor(data: any, insurances: any) {
        this.providerInsuranceKey = data.providerInsuranceKey;
        this.providerKey = data.providerKey;
        this.insuranceTypeKey = data.insuranceTypeKey;
        this.parentBrokerKey = data.parentBrokerKey;
        this.agencyName = data.agencyName;
        this.agentName = data.agentName;
        this.agentPhone = data.agentPhone;
        this.agentEmail = data.agentEmail;
        this.agentFax = data.agentFax;
        this.policyExpDate = data.policyExpDate;
        this.policyNumber = data.policyNumber;
        this.cartificateURL = data.cartificateURL;
        this.systemDefault = data.systemDefault;
        this.insertedTimestamp = data.insertedTimestamp;
        this.insertedUserKey = data.insertedUserKey;
        this.updatedTimestamp = data.updatedTimestamp;
        this.updatedUserKey = data.updatedUserKey;
        this.notRequired = data.notRequired;
        this.deleted = data.deleted;
        const insurance = insurances.filter((item) => item.insuranceTypeKey === this.insuranceTypeKey)[0];
        this.requirement = 'Minimum Insured Amount Must be $' + insurance.minimumInsuredAmount + '. ' + insurance.notes;
        this.insuredAmount = data.insuredAmount;
    }
}
