import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { UtilityService } from 'src/app/services/utility.service';
import { HoursOfOperationsService } from 'src/app/services/hours-of-operations-service.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';


@Component({
  selector: 'app-add-edit-holiday-dialog',
  templateUrl: './add-edit-holiday-dialog.component.html',
  styleUrls: ['./add-edit-holiday-dialog.component.scss']
})
export class AddEditHolidayDialogComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  holidayForm: UntypedFormGroup;
  showTimeFlag = false;
  providerHolidayHours: any;
  loading: any;
  isEdit: any = 0;
  buttonText = 'Add';
  dayOfObservanceData: any;
  // minDate = new Date();
  // maxDate = new Date(this.minDate.getFullYear(), 12, 0);
  isMasterLicensedOwner = false;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';


  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

    constructor(
    public dialog: MatDialogRef<AddEditHolidayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    private dialogService: NbDialogService,
    private utilityService: UtilityService,
    private hoursOfOperationsService: HoursOfOperationsService,
  ) { }

  ngOnInit() {
    this.holidayForm = this.formBuilder.group({
      holidayKey: ['', Validators.required],
      holidayOccuranceKey: ['', Validators.required],
      closed: ['', Validators.required],
      openTime: ['', Validators.required],
      closeTime: ['', Validators.required],
      providerKey: sessionStorage.getItem('providerKey'),
      insertedUserKey: sessionStorage.getItem('userKey'),
      providerHolidayHoursOfOperationKey: '',
      updatedUserKey: [''],
      insertedTimestamp: [''],
      holidayOccuranceDate: [''],
    });

    const dayOfObservance = JSON.parse(sessionStorage.getItem('dayOfObservance'));
    this.dayOfObservanceData = dayOfObservance ? dayOfObservance.body : null;
    this.isMasterLicensedOwner = this.data ? this.data.isMasterLicensedOwner : false;
    if (!this.dayOfObservanceData) {
      this.getDayOfObservance();
    }else{
      this.getProviderHolidayHoursOfOperation();
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  showTime() {
    if (this.holidayForm.get('closed').value == 'false') {
      this.showTimeFlag = true;
      this.holidayForm.get('openTime').setValidators(Validators.required);
      this.holidayForm.get('closeTime').setValidators(Validators.required);
      this.holidayForm.get('openTime').updateValueAndValidity();
      this.holidayForm.get('closeTime').updateValueAndValidity();
    } else {
      this.showTimeFlag = false;
      this.holidayForm.get('openTime').clearValidators();
      this.holidayForm.get('closeTime').clearValidators();
      this.holidayForm.get('openTime').updateValueAndValidity();
      this.holidayForm.get('closeTime').updateValueAndValidity();
    }
  }

  getDayOfObservance() {
    const data = { deleted: false };

    this.hoursOfOperationsService.getDayOfObservance(data).subscribe(result => {
      this.dayOfObservanceData = result.body || null;
      sessionStorage.setItem('dayOfObservance', JSON.stringify(result));
      this.getProviderHolidayHoursOfOperation();
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  getProviderHolidayHoursOfOperation() {
    const filter = {
      providerKey: this.data.providerKey,
      deleted: false,
    };

    this.loading = true;
    this.hoursOfOperationsService.getProviderHolidayHoursOfOperation(filter).subscribe((res: any) => {
      this.providerHolidayHours = res.body;

      this.providerHolidayHours.map(obj => {
        if (obj.holidayOccuranceKey !== 'Misc') {
          const holidayOccuranceDate = this.dayOfObservanceData.find((item) => item.holidayKey
            === obj.holidayOccuranceKey);
          obj.holidayOccuranceDate = holidayOccuranceDate ? holidayOccuranceDate.holidayOccuranceDate : '';
        }
      });

      this.onSort({ column: 'holidayKey', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  saveHolidayHours() {
    if (this.holidayForm.invalid) {
      this.utilityService.validateAllFormFields(this.holidayForm);
      return false;
    }

    const holidayData = {
      holidayKey: this.holidayForm.controls.holidayKey.value,
      holidayOccuranceKey: this.holidayForm.controls.holidayOccuranceKey.value,
      closed: this.holidayForm.controls.closed.value == "false" ? true : false,
      openTime: this.showTimeFlag ? this.holidayForm.controls.openTime.value : null,
      closeTime: this.showTimeFlag ? this.holidayForm.controls.closeTime.value : null,
      holidayOccuranceDate: this.holidayForm.controls.holidayOccuranceDate.value ?
        this.utilityService.converDateInUnix(this.holidayForm.controls.holidayOccuranceDate.value) : '',
      insertedUserKey: sessionStorage.getItem('userKey'),
      providerKey: this.data.providerKey,
      systemDefault: false,
      deleted: false,
      updatedUserKey: '',
    };

    this.checkHolidayIsAlreadyPresent(holidayData);
  }

  checkHolidayIsAlreadyPresent(holidayData) {
    const filter = {
      holidayOccuranceKey: this.holidayForm.controls.holidayOccuranceKey.value,
      providerKey: this.data.providerKey,
    };

    this.loading = true;
    this.hoursOfOperationsService.getProviderHolidayHoursOfOperation(filter).subscribe((res: any) => {
      this.loading = false;

      const hrsOfOperationKey = this.holidayForm.controls.providerHolidayHoursOfOperationKey.value;
      const holidayKey = this.holidayForm.controls.holidayKey.value;

      if (this.holidayForm.controls.holidayOccuranceKey.value !== 'Misc') {

        const closed = this.holidayForm.controls.closed.value == "false" ? true : false;
        const isAvailable = res.body.some(element => (closed === element.closed || holidayKey === element.holidayKey)
          && hrsOfOperationKey !== res.body[0].providerHolidayHoursOfOperationKey) ? true : false;

        if (isAvailable) {
          this.toast.warning('Holiday is already exist on same date', 'Warning');
        } else {
          this.addOrEditHolidayyHours(holidayData);
        }

      } else {
        const holidayDate = moment(this.holidayForm.controls.holidayOccuranceDate.value);
        let isAlreadyExists = false;

        res.body.forEach(element => {
          const closed = this.holidayForm.controls.closed.value == "false" ? true : false;
          const resultHolidayDate = moment(element.holidayOccuranceDate);
          if (holidayDate.month() === resultHolidayDate.month()
            && holidayDate.date() === resultHolidayDate.date()
            && (closed === element.closed || holidayKey === element.holidayKey)
            && hrsOfOperationKey !== element.providerHolidayHoursOfOperationKey) {
            isAlreadyExists = true;
          }
        });

        if (isAlreadyExists) {
          this.toast.warning('Holiday is already exist on same date', 'Warning');
        } else {
          this.addOrEditHolidayyHours(holidayData);
        }

      }
    }, (error) => {
      this.loading = false;
    });
  }

  addOrEditHolidayyHours(holidayData) {
    if (!this.isEdit) {
      this.addHolidayHours(holidayData);
    } else {
      this.updateHolidayHours(holidayData);
    }
  }

  addHolidayHours(holidayData) {
    holidayData.providerKey = this.data.providerKey;
    this.loading = true;
    this.hoursOfOperationsService.saveHolidayHours(holidayData).subscribe((res: any) => {
      if (res.success) {
        this.toast.success(res.message[0], 'Success');
        this.getProviderHolidayHoursOfOperation();
      } else {
        this.toast.warning(res.message[0], 'Error');
      }
      this.holidayForm.reset();
      this.buttonText = 'Add';
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  updateHolidayHours(holidayData) {
    holidayData.providerKey = this.holidayForm.controls.providerKey.value;
    holidayData.openTime = holidayData.closed ? this.holidayForm.controls.openTime.value : '';
    holidayData.closeTime = holidayData.closed ? this.holidayForm.controls.closeTime.value : '';
    holidayData.updatedUserKey = sessionStorage.getItem('userKey');

    const data = Object.assign(holidayData, {
      providerHolidayHoursOfOperationKey:
        this.holidayForm.controls.providerHolidayHoursOfOperationKey.value,
    });

    this.loading = true;
    this.hoursOfOperationsService.updateProviderHolidayHours(data).subscribe((res: any) => {
      this.showTimeFlag = false;
      if (res.success) {
        this.toast.success(res.message[0], 'Success');
        this.getProviderHolidayHoursOfOperation();
      } else {
        this.toast.warning(res.message[0], 'Error');
      }
      this.isEdit = 0;
      this.showTime();
      this.buttonText = 'Add';
      this.holidayForm.reset();
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  editHolidayHoursOfOperation(providerHolidayHour) {
    this.isEdit = 1;
    this.showTime();
    this.buttonText = 'Save';
    this.showTimeFlag = providerHolidayHour.closed;
    this.holidayForm.setValue({
      providerKey: providerHolidayHour.providerKey,
      holidayKey: providerHolidayHour.holidayKey,
      holidayOccuranceKey: providerHolidayHour.holidayOccuranceKey,
      closed: providerHolidayHour.closed ? 'false' : 'true',
      openTime: providerHolidayHour.openTime,
      closeTime: providerHolidayHour.closeTime,
      insertedUserKey: providerHolidayHour.insertedUserKey,
      providerHolidayHoursOfOperationKey: providerHolidayHour.providerHolidayHoursOfOperationKey,
      holidayOccuranceDate: providerHolidayHour.holidayOccuranceDate ?
        moment(providerHolidayHour.holidayOccuranceDate).toDate() : '',
      insertedTimestamp: providerHolidayHour.insertedTimestamp,
      updatedUserKey: '',
    });

    this.observanceChanged();
  }

  deleteProviderHolidayHours(providerHolidayHour) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        this.loading = true;
        this.hoursOfOperationsService.deleteProviderHolidayHours(providerHolidayHour).subscribe(res => {
          this.getProviderHolidayHoursOfOperation();
        }, (error) => {
          this.loading = false;
          console.log(error);
        });
      }
    });
  }

  cancelEdit() {
    this.showTimeFlag = false;
    this.isEdit = 0;
    this.buttonText = 'Add';
    this.holidayForm.reset();
  }

  observanceChanged() {
    if (this.holidayForm.controls.holidayOccuranceKey.value === 'Misc') {
      this.holidayForm.controls.holidayOccuranceDate.setValidators(Validators.required);
      this.holidayForm.controls.holidayOccuranceDate.updateValueAndValidity();
    } else {
      this.holidayForm.controls.holidayOccuranceDate.setValue('');
      this.holidayForm.controls.holidayOccuranceDate.clearValidators();
      this.holidayForm.controls.holidayOccuranceDate.updateValueAndValidity();
    }
  }

  holidayKeyChanged() {
    if (this.holidayForm.controls.holidayKey.value === 'ThisYear') {
      // this.minDate = new Date();
      // this.maxDate = new Date(this.minDate.getFullYear(), 12, 0);
      this.minDate = this.date;
      this.maxDate = this.date;
    } else {
      this.minDate = null;
      this.maxDate = null;
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.providerHolidayHours = this.utilityService.sortData(this.headers, column, direction, this.providerHolidayHours);
  }
}
