import { Component, OnInit, ViewChildren, Input, QueryList } from '@angular/core';
import { SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { CapabilitiesService } from 'src/app/services/capabilities.service';
import { CheckBoxService } from 'src/app/share-module/checkbox-tree-ui/services/check-box.service';

@Component({
  selector: 'app-provider-capabilities',
  templateUrl: './provider-capabilities.component.html',
  styleUrls: ['./provider-capabilities.component.scss']
})
export class ProviderCapabilitiesComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() providerInputKey: string;

  loading = false;

  // checkbox tree
  lastNode = -1;
  level = 0;
  expandLayer: any = [];
  providerCapabilities: any;

  constructor(
    private checkBoxService: CheckBoxService,
    private capabilitiesService: CapabilitiesService,
  ) { }

  ngOnInit() {
    this.initDropdown();
  }

  initDropdown() {
    this.checkBoxService.setCheckBoxNetworkData(null);
    sessionStorage.removeItem('expandedLayer');

    this.getProviderServiceCapabilities();
    sessionStorage.setItem('expandedLayer', JSON.stringify(this.expandLayer));

    this.checkBoxService.getCheckBoxData().subscribe(message => {
      this.providerCapabilities = JSON.parse(this.checkBoxService.getCheckBoxNetworkData());
    });

    this.checkBoxService.checkBoxLoader.subscribe(message => {
      this.loading = message;
    });
  }

  getProviderServiceCapabilities() {
    const filter = {
      providerKey: this.providerInputKey
    };

    this.loading = true;
    this.capabilitiesService.getProviderCapabilitiesByProvider(filter).subscribe(result => {
      this.loading = false;
      this.providerCapabilities = result;

      if (this.checkBoxService.getCheckBoxNetworkData() === null
        || this.checkBoxService.getCheckBoxNetworkData() === undefined) {
        this.checkBoxService.setCheckBoxNetworkData(JSON.stringify(this.providerCapabilities));
      }
    }, (error) => {
      this.loading = false;
    });
  }
}
