<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6>Service Thresholds</h6>
    <button type="button" class="close" (click)="close()" nbTooltip="close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="row">
        <!-- <div class="col-md-4">
            <ngx-search-input (search)="search($event)"></ngx-search-input>
        </div> -->
        <div class="col-md-12 text-right">
            <button class="btn btn-primary btn-sm" (click)="addThreshold(false)">Add New</button>
        </div>
    </div>
    <div class="table-responsive mt-2">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th *ngIf="customerAuthorization">Hierarchy Tier</th>
                    <th *ngIf="customerAuthorization">Security Group</th>
                    <th>Service</th>
                    <th>Operating Schedule</th>
                    <th>Min $ Threshold</th>
                    <th>Max $ Threshold</th>
                    <th>Min Quant</th>
                    <th>Max Quant</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="serviceThresholds.length > 0; else noData">
                <tr *ngFor="let serviceThreshold of serviceThresholds; let i = index">
                    <ng-container *ngIf="serviceThreshold.licensedOwnerOfferingKey == licensedOwnerOfferingKey">
                        <td *ngIf="customerAuthorization">{{serviceThreshold.tierName}}</td>
                        <td *ngIf="customerAuthorization">{{serviceThreshold.description}}</td>
                        <td>{{serviceThreshold.offerings}}</td>
                        <td><label
                                *ngIf="!(serviceThreshold.editMode)">{{serviceThreshold.operatingScheduleKey}}</label>
                            <span *ngIf="serviceThreshold.editMode">
                                <mat-form-field>
                                    <mat-select placeholder="Operating Schedule"
                                        [(ngModel)]="serviceThreshold.operatingScheduleKey" required>
                                        <mat-option disabled>Select Operating Schedule</mat-option>
                                        <mat-option *ngFor="let operatingSchedule of OperatingSchedules"
                                            [value]="operatingSchedule.operatingScheduleKey">
                                            {{operatingSchedule.operatingScheduleKey}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Please select Operating Schedule.</mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td><label
                                *ngIf="!(serviceThreshold.editMode)"><span>$</span>{{serviceThreshold.minimumThreshold}}</label>
                            <span *ngIf="serviceThreshold.editMode">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="serviceThreshold.minimumThreshold" required
                                        placeholder="Min $ Threshold">
                                    <mat-error>
                                        Minimum Threshold is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td><label *ngIf="!(serviceThreshold.editMode)">
                                <span>$</span>
                                {{serviceThreshold.maximumThreshold}}
                            </label>
                            <span *ngIf="serviceThreshold.editMode">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="serviceThreshold.maximumThreshold"
                                        placeholder="Max $ Threshold">
                                </mat-form-field>
                            </span>
                        </td>
                        <td><label *ngIf="!(serviceThreshold.editMode)">{{serviceThreshold.quantityMinimum}}</label>
                            <span *ngIf="serviceThreshold.editMode">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="serviceThreshold.quantityMinimum" required
                                        placeholder="Min Quant">
                                    <mat-error>
                                        Minium Quantity is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td><label *ngIf="!(serviceThreshold.editMode)">{{serviceThreshold.quantityMaximum}}</label>
                            <span *ngIf="serviceThreshold.editMode">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="serviceThreshold.quantityMaximum"
                                        placeholder="Max Quant">
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <button *ngIf="!(serviceThreshold.editMode)" class="btn btn-default btnAnimation" (click)="edit(i)"
                                nbTooltip="Edit">
                                <i class="fas fa-pencil"></i>
                            </button>
                            <button *ngIf="!(serviceThreshold.editMode)"
                                class="btn btn-sm btn-default btn-sm btnAnimation" nbTooltip="Delete" (click)="delete(serviceThreshold)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                            <button *ngIf="serviceThreshold.editMode" class="btn btn-default btnAnimation" (click)="update(serviceThreshold)"
                                nbTooltip="Update">
                                <i class="fas fa-check"></i>
                            </button>
                            <button *ngIf="serviceThreshold.editMode" class="btn btn-default btnAnimation"
                                (click)="cancel(i)" nbTooltip="Cancel">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
            <ng-template #noData>
                <tbody>
                    <tr>
                        <td colspan="9" class="text-center">No data available</td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
</div>
<div class="modal-footer">

</div>