import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalField } from '../model/global-field';

@Component({
  selector: 'app-form-settings-dialog',
  templateUrl: './form-settings-dialog.component.html',
  styleUrls: ['./form-settings-dialog.component.scss']
})
export class FormSettingsDialogComponent implements OnInit {

  loading: boolean;
  currentGlobalField: GlobalField = new GlobalField();
  dataSourceType = 'values';
  previewSelectDetails = '0';
  editModal = {
    textType: '',
    textLabel: '',
    textPlaceholder: '',
    textValue: '',
    isRequired: false
  };
  arrSelectedValues: any = [{
    id: '',
    text: '',
  }];
  editModalDetails: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FormSettingsDialogComponent>,
  ) { }

  ngOnInit() {
    this.editModalDetails = this.data.editModalDetails;
    if (this.data.isEdit) {
      this.editModal.textType = (this.data.editModalDetails.type) ? this.data.editModalDetails.type : 'text';
      this.editModal.textLabel = (this.data.editModalDetails.label) ? this.data.editModalDetails.label : '';
      this.editModal.textPlaceholder = (this.data.editModalDetails.placeholder) ? this.data.editModalDetails.placeholder : '';
      this.editModal.textValue = (this.data.editModalDetails.value) ? this.data.editModalDetails.value : '';
      this.editModal.isRequired = (this.data.editModalDetails.required) ? this.data.editModalDetails.required : false;
      if (this.editModal.textType === 'select') {
        this.arrSelectedValues = this.data.editModalDetails.items;
      } else if (this.editModal.textType === 'radio' || this.editModal.textType === 'checkbox') {
        this.arrSelectedValues = this.data.editModalDetails.listitems;
      }
    } else {
      this.editModal.textType = (this.data.editModalDetails.type) ? this.data.editModalDetails.type : 'text';
    }
  }

  addNewSelectValues() {
    this.arrSelectedValues.push({
      id: '',
      text: '',
    });
  }

  removeSelectedFormControls() {
    this.editModal.textLabel = '';
    this.editModal.textPlaceholder = '';
    this.editModal.textValue = '';
    this.editModal.isRequired = false;
    this.arrSelectedValues = [];
  }

  removeSelectValues(event: any) {
    const itemIndex = this.arrSelectedValues.indexOf(event);
    if (itemIndex > -1) {
      this.arrSelectedValues.splice(itemIndex, 1);
    }
  }

  getInputValue(event: any, item: any) {
    item.id += event.key;
  }

    getInputDisplayNameValue(event: any) {
      this.editModal.textLabel += event.key;
    }

  closeDialog() {
    const objParams = {
      editModalDetails: this.editModalDetails,
      editModal: this.editModal,
      arrValues: this.arrSelectedValues,
    };
    this.dialogRef.close(objParams);
  }
}
