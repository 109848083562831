import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UserExperiance } from '../models/user-experiance.model';

@Injectable({
  providedIn: 'root'
})
export class UserExperianceService {

  constructor(private http: HttpClient) { }

  // Get User Experiances
  getUserExperiances(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/userexperiances/get`, data).pipe(
      map((res: any) => res.body.map((userExperiance: UserExperiance) => new UserExperiance().deserialize(userExperiance))));;
  }

  // Add User Experiances
  addUserExperiances(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/userexperiances`, data);
  }

  // Update User Experiances
  updateUserExperiances(data: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}systemsecurities/userexperiances`, data);
  }
}
