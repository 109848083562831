import { Component, OnInit, Input, ViewChildren, EventEmitter, QueryList, Output } from '@angular/core';
import { AddEditEtaDialogComponent } from 'src/app/dialogs/add-edit-eta-dialog/add-edit-eta-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OfferingEtaService } from 'src/app/services/offering-eta.service';
import { BubbleService } from 'src/app/services/bubble-service.service';
import { SortableDirective, SortEvent } from '../directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'ngx-setup-eta',
  templateUrl: './setup-eta.component.html',
  styleUrls: ['./setup-eta.component.scss']
})
export class SetupEtaComponent implements OnInit {
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  @Input() providerAccount;
  @Input() isParent;

  loading: boolean = false;
  providerETAData: any = [];
  providerKey: any;
  defaultLocationKey: string;
  providerAccountKey: any;
  licensedOwnerKey: any;
  accountName: any;
  parentRecord: boolean = false;
  searchText: string;
  parentProviderKey = (sessionStorage.getItem('providerParentKey') != "null" && sessionStorage.getItem('providerParentKey') !== '0') ?
    sessionStorage.getItem('providerParentKey') :
    sessionStorage.getItem('providerKey');

  constructor(
    private offeringEtaService: OfferingEtaService,
    private dialog: MatDialog,
    private bubbleService: BubbleService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.providerAccountKey = this.providerAccount.providerAccountKey;
    this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
    this.accountName = this.providerAccount.accountName;
    this.providerKey = this.providerAccount.providerKey;
    if (this.isParent) {
      this.getProviderServiceOfferingsETA();
    } else {
      this.getLocationServiceOfferingsETA();
    }
  }

  // get provider offerings ETA
  getProviderServiceOfferingsETA() {
    let data = {};
    if (this.licensedOwnerKey !== '') {
      data = {
        'licensedOwnerKey': this.licensedOwnerKey,
        'providerAccountKey': this.providerAccountKey,
        'providerKey': this.parentRecord ? this.parentProviderKey : this.providerKey
      }
    } else {
      data = {
        'licensedOwnerKey': '',
        'providerAccountKey': this.providerAccountKey,
        'providerKey': this.parentRecord ? this.parentProviderKey : this.providerKey
      }
    }
    this.loading = true;
    this.offeringEtaService.getProviderServiceOfferingsETA(data).subscribe((response) => {
      this.providerETAData = response;
      this.onSort({ column: 'ServiceName', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log('error', error);
    })
  }

  // open edit ETA dialog
  openEditETADialog(value) {
    let dialogRef = this.dialog.open(AddEditEtaDialogComponent, {
      width: '33%',
      position: {
        top: '4%'
      },
      data: {
        data: value,
        parentRecord: this.parentRecord,
        providerAccount: this.providerAccount,
        providerAccountKey: this.providerAccountKey
      }
    });

    dialogRef.componentInstance.updateETA.subscribe(result => {
      this.parentRecord = false;
      this.providerAccountKey = this.providerAccount.providerAccountKey;
      this.getProviderServiceOfferingsETA();
    });
  }

  getLocationServiceOfferingsETA() {
    const data = {
      'providerAccountKey': this.providerAccountKey,
      'providerKey': this.providerKey,
      'deleted': false
    }
    this.loading = true;
    this.offeringEtaService.getProviderServiceOfferingsETAs(data).subscribe((response) => {
      if (response.body.length === 0) {
        this.parentRecord = true;
      } else {
        this.parentRecord = false;
      }

      if (this.isParent) {
        this.parentRecord = false;
      }

      if (this.parentRecord) {
        let data = {
          'accountName': this.accountName,
          'licensedOwnerKey': this.licensedOwnerKey,
          'providerKey': this.parentProviderKey,
          'deleted': false
        };
        this.loading = true;
        this.bubbleService.getProviderAccountKey(data).subscribe(result => {
          this.loading = false;
          this.providerAccountKey = result.body[0].providerAccountKey;
          this.getProviderServiceOfferingsETA();
        }, (error) => {
          this.loading = false;
        });
      } else {
        this.getProviderServiceOfferingsETA();
      }

      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log('error', error);
    })
  }

  onInput(val: string) {
    this.search.emit(this.searchText);
  }

  onSort({ column, direction }: SortEvent) {
    this.providerETAData = this.utilityService.sortData(this.headers, column, direction, this.providerETAData);
  }

}


