import { Deserializable } from "../../models/deserializable.model";

export class TechnicianSkills implements Deserializable  {

    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    skillKey: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
