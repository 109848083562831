import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfirmDeleteComponent } from  'src/app/commons/confirm-delete/confirm-delete.component';
import { NationalAccountService } from 'src/app/services/national-account.service';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-provider-management-national-accounts',
  templateUrl: './provider-management-national-accounts.component.html',
  styleUrls: ['./provider-management-national-accounts.component.scss']
})
export class ProviderManagementNationalAccountsComponent implements OnInit {
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() providerInputKey: string;
  @Input() isMasterLicensedOwner: boolean;

  nationalAccountData: any;
  providerNationalAccountData: any;
  loading: any;
  flag = true;

  constructor(
    public dialog: MatDialog,
    private dialogService: NbDialogService,
    private toast: NbToastrService,
    private utilityservice: UtilityService,
    private nationalAccountService: NationalAccountService
  ) { }

  ngOnInit() {
    if (this.providerInputKey) {
      this.getNationalAccounts();
      this.getProviderNationalAccounts();
    }
  }

  getNationalAccounts() {
    this.loading = true;
    this.nationalAccountService.getNationalAccountList({ deleted: false }).subscribe(res => {
      this.nationalAccountData = res.body;
      this.onNationalAccountSort({ column: 'nationalAccountKey', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  addNationalAccount(nationalAccount) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Add National Account', message: 'Are you sure you want to add this National Account?',
        yesButton: 'Yes'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){ 
      this.providerNationalAccountData.forEach(element => {
        if (element.nationalAccountKey === nationalAccount) {
          this.toast.warning('Already Selected!', 'Warning');
          this.flag = false;
        }
      });

    if (this.flag) {
      const data = {
        nationalAccountKey: nationalAccount,
        providerKey: this.providerInputKey,
        systemDefault: true,
        insertedUserKey: sessionStorage.getItem('userKey'),
        updatedUserKey: '',
        deleted: false
      };
      this.loading = true;
      this.nationalAccountService.addNationalAccounts(data).subscribe(res => {
        this.toast.success(res.message[0], 'Success');
        this.getProviderNationalAccounts();
        this.loading = false;
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    } else {
      this.flag = true;
    }
  } 
  })}

  removeNationalAccount(data) {

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: {header: 'Delete National Account', message: 'Are you sure you want to delete this National Account?',
      yesButton: 'Yes'
    }
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
      if(result == true){
        this.loading = true;
        this.nationalAccountService.deleteNationalAccounts(data).subscribe(res => {
          this.toast.success(res.message[0], 'Success');
          this.getProviderNationalAccounts();
          this.loading = false;
        }, (error) => {
          this.loading = false;
          console.log(error);
        });
    }
    })
  }

  // removeNationalAccount(data) {
  //   this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
  //     if (isDelete) {
  //       this.loading = true;
  //       this.nationalAccountService.deleteNationalAccounts(data).subscribe(res => {
  //         this.toast.success(res.message[0], 'Success');
  //         this.getProviderNationalAccounts();
  //         this.loading = false;
  //       }, (error) => {
  //         this.loading = false;
  //         console.log(error);
  //       });
  //     }
  //   });
  // }

  getProviderNationalAccounts() {
    const data = {
      providerKey: this.providerInputKey,
      deleted: false
    };

    this.loading = true;
    this.nationalAccountService.getProviderNationalAccounts(data).subscribe(res => {
      this.providerNationalAccountData = res.body;
      this.onSort({ column: 'nationalAccountKey', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  onNationalAccountSort({ column, direction }: SortEvent) {
    this.nationalAccountData = this.utilityservice.sortData(this.headers, column, direction, this.nationalAccountData);
  }

  onSort({ column, direction }: SortEvent) {
    this.providerNationalAccountData = this.utilityservice.sortData(this.headers, column, direction, this.providerNationalAccountData);
  }
}
