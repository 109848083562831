import { Component, OnInit, Input, QueryList, OnChanges, SimpleChanges, ViewChildren, HostBinding, ViewEncapsulation,Renderer2, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/dialogs/alert-dialog/alert-dialog.component';
import { BubbleService } from 'src/app/services/bubble.service';
import { BubbleTreeProviderComponent } from 'src/app/share-module/bubble-tree-provider/bubble-tree-provider.component';
import { Subscription } from 'rxjs';
import { PanZoomConfig, PanZoomAPI, PanZoomModel, PanZoomConfigOptions } from 'ngx-panzoom';
import { contentItems } from  '../../../shared/bubble-tree-ui/contentItems';
import * as utils from '../../../shared/bubble-tree-ui/utils';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { ProviderService } from 'src/app/services/provider.service';
import { CommonService } from 'src/app/services/common.service';
import { EditSublocationDataComponent } from '../edit-sublocation-data/edit-sublocation-data.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';

interface Point {
  x: number;
  y: number;
}

@Component({
  selector: 'app-provider-admin-services',
  templateUrl: './provider-admin-services.component.html',
  styleUrls: ['./provider-admin-services.component.scss']
})
export class ProviderAdminServicesComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() providerDetails: any = [];
  @ViewChild(BubbleTreeProviderComponent, { static: true }) bubbleComponent: BubbleTreeProviderComponent;

  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  displayedColumns: string[] = ['locationName','status', 'address', 'city', 'state', 'zipcode', 'geocode', 'dayPhone', 'nightPhone', 'action'];
  // Input provider details

  // Instance members
  loading: boolean;
  serviceData: any;
  // panZoomConfig: PanZoomConfig = new PanZoomConfig;

  // Params required for bubble tree
  expandLayer: any = [];
  level = 0;
  addNode = false; // If false do not show add button on node.
  editNode = false; // If false do not show edit button on node.
  delNode = false; // If false do not show delete button on node.
  isActiveNode = true; // If false do not show active/inactive checkbox on node.
  disableDragDrop = true; // If true disable Drag and Drop.
  lastNode = 4; // If -1 then bubble tree can go upto n layers
  isSelected = -1; // If -1 then no node will have select option
  defaultLocationKey: string;
  providerKey: string;
  providerAccountKey: string;
  licensedOwnerKey: string;
  parentRecord = false; // if location has no data set it to true
  providerAccount: any = [];
  arrowDirection: any;

  constructor(
    public dialog: MatDialog,
    private bubbleService: BubbleService,
    private changeDetector: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2,
    private providerAdminService: ProviderAdminService,
    private providerService: ProviderService,
    private commonService: CommonService,
    private utilityService: UtilityService,
  ) { }

  // @ViewChild('gridElement', { static: true }) private gridElement: ElementRef;

  // private panZoomConfigOptions: PanZoomConfigOptions = {
  //   zoomLevels: 10,
  //   scalePerZoomLevel: 2.0,
  //   zoomStepDuration: 0.2,
  //   freeMouseWheelFactor: 0.01,
  //   zoomToFitZoomLevelFactor: 0.9,
  //   dragMouseButton: 'left'
  // };
  // panzoomConfig: PanZoomConfig = new PanZoomConfig(this.panZoomConfigOptions);
  // private panZoomAPI: PanZoomAPI;
  // private apiSubscription: Subscription;
  // panzoomModel: PanZoomModel;
  // private modelChangedSubscription: Subscription;
  // contentItems = contentItems;
  // canvasWidth = 2400;
  // initialZoomHeight: number = null; // set in resetZoomToFit()
  // initialZoomWidth = this.canvasWidth;
  // initialised = false;
  // scale = this.getCssScale(this.panzoomConfig.initialZoomLevel);
  // private isMobile = false;

  panZoomConfig: PanZoomConfig = new PanZoomConfig();

  providerSubLocationList: any = [];
  statesData : any = [];
  stateFilter: any; 
  showLocations: any = [];
  filterLocation: any;

  ngOnInit() {

    // this.renderer.setStyle(this.el.nativeElement.ownerDocument.body, 'background-color', 'black');
    // this.renderer.setStyle(this.el.nativeElement.ownerDocument.body, 'overflow', 'hidden');
    // this.apiSubscription = this.panzoomConfig.api.subscribe( (api: PanZoomAPI) => this.panZoomAPI = api );
    // this.modelChangedSubscription = this.panzoomConfig.modelChanged.subscribe( (model: PanZoomModel) => this.onModelChanged(model) );
    // this.isMobile = this.isMobileDevice();
    // if (this.isMobile) {
    //   this.contentItems = this.contentItems.slice(0, 13);
    // }

    this.getStates();
    this.getProviderStates();

  }

  ngAfterViewInit(): void {
    // this.resetZoomToFit();
    // this.initialised = true;
    // this.changeDetector.detectChanges();
  }



  ngOnDestroy(): void {
    // this.modelChangedSubscription.unsubscribe();
    // this.apiSubscription.unsubscribe();
  }



  private isMobileDevice(): boolean {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }



  // onModelChanged(model: PanZoomModel): void {
  //   this.panzoomModel = utils.deepCopy(model);
  //   this.scale = this.getCssScale(this.panzoomModel.zoomLevel);
  //   this.changeDetector.markForCheck();
  //   this.changeDetector.detectChanges();
  // }



  // private getCssScale(zoomLevel: any): number {
  //   return Math.pow(this.panzoomConfig.scalePerZoomLevel, zoomLevel - this.panzoomConfig.neutralZoomLevel);
  // }



  // resetZoomToFit(): void {
  //   let height = this.gridElement.nativeElement.clientHeight;
  //   const width = this.gridElement.nativeElement.clientWidth;
  //   height = this.canvasWidth * height / width;
  //   this.panzoomConfig.initialZoomToFit = {
  //     x: 0,
  //     y: 0,
  //     width: this.canvasWidth,
  //     height
  //   };
  //   this.initialZoomHeight = height;
  // }



  // onZoomInClicked(): void {
  //   this.panZoomAPI.zoomIn('viewCenter');
  // }



  // onZoomOutClicked(): void {
  //   this.panZoomAPI.zoomOut('viewCenter');
  // }



  // onResetViewClicked(): void {
  //   this.panZoomAPI.resetView();
  // }



  // onPanLeft100Clicked(): void {
  //   this.panZoomAPI.panDelta( { x: -100, y: 0 } );
  // }



  // onPanRight100Clicked(): void {
  //   this.panZoomAPI.panDelta( { x: 100, y: 0 } );
  // }



  // onPanUp100Clicked(): void {
  //   this.panZoomAPI.panDelta( { x: 0, y: -100 } );
  // }



  // onPanDown100Clicked(): void {
  //   this.panZoomAPI.panDelta( { x: 0, y: 100 } );
  // }



  // onPanLeftPercentClicked(): void {
  //   this.panZoomAPI.panDeltaPercent( { x: -20, y: 0 } );
  // }



  // onPanRightPercentClicked(): void {
  //   this.panZoomAPI.panDeltaPercent( { x: 20, y: 0 } );
  // }



  // onPanUpPercentClicked(): void {
  //   this.panZoomAPI.panDeltaPercent( { x: 0, y: -20 } );
  // }



  // onPanDownPercentClicked(): void {
  //   this.panZoomAPI.panDeltaPercent( { x: 0, y: 20 } );
  // }



  // onPanToPointClicked(): void {
  //   this.panZoomAPI.panToPoint( { x: 2400, y: 4270 } );
  // }


  // onCenterContentClicked(): void {
  //   this.panZoomAPI.centerContent();
  // }



  // onCenterXClicked(): void {
  //   this.panZoomAPI.centerX();
  // }



  // onCenterYClicked(): void {
  //   this.panZoomAPI.centerY();
  // }



  // onCenterTopLeftClicked(): void {
  //   this.panZoomAPI.centerTopLeft();
  // }



  // onCenterBottomLeftClicked(): void {
  //   this.panZoomAPI.centerBottomLeft();
  // }



  // onCenterTopRightClicked(): void {
  //   this.panZoomAPI.centerTopRight();
  // }



  // onCenterBottomRightClicked(): void {
  //   this.panZoomAPI.centerBottomRight();
  // }





  ngOnChanges() {
    // if (this.providerDetails.providerKey) {
    //   this.providerAccount = [];
    //   this.parentRecord = false;
    //   this.getSevice();
    //   this.providerAccount = {
    //     providerKey: this.providerDetails.providerKey,
    //     providerAccountKey: this.providerDetails.providerAccountKey,
    //     licensedOwnerKey: this.providerDetails.licensedOwnerKey,
    //     parentProviderKey: this.providerDetails.parentProviderKey
    //   };
    // }

    if (this.providerDetails.ProviderKey){
      console.log(this.providerDetails)
      this.getSubLocations(this.providerDetails.ProviderKey);
      this.getProviderStates();
    }
  }

  getSubLocations(parentProviderKey){

    const data = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      location: true,
      parentKey: parentProviderKey,
      searchText: null
    };

    const searchProviderByParent = {
      parentKey: parentProviderKey,
      delete: false,
    }

    this.providerService.getProviderDetails(searchProviderByParent).subscribe((res: any) => {
      this.providerSubLocationList = res.body;
      this.showLocations = this.providerSubLocationList;
      this.onSort({ column: 'insertedTimestamp', direction: 'desc' });
      this.dataSource = new MatTableDataSource(this.showLocations);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
     // console.log(this.providerSubLocationList)
    }, (error) => {
      console.info(error);
    });
    
  }

  onSort({ column, direction }: SortEvent) {
    this.showLocations = this.utilityService.sortData(this.headers, column, direction, this.showLocations);
    this.arrowDirection = direction;
  }

  getStates() {
    // GETS ALL THE STATES  
    // if (sessionStorage.getItem('states') == null) {
    //   this.commonService.getstates().subscribe(result => {
    //     this.statesData = result.body;
    //     sessionStorage.setItem('states', JSON.stringify(result));
    //   });
    // } else {
    //   this.statesData = JSON.parse(sessionStorage.getItem('states')).body;
    // }   
  }

  getProviderStates(){

    this.providerAdminService.getProviderOccupiedStates(this.providerDetails.ProviderKey).subscribe({
      next: (res) => this.statesData = res.body,
      error: (e) => console.log(e),
      complete: () => { console.log('provider states successfuly retrieved.')} 
    })
  }

  // selectionChange(event){
  //   console.log(event)
  //   this.filterByState()
  // }

  // filterByState(){
  //   console.log(this.stateFilter)
  //   if(this.stateFilter != undefined){
  //     let filteredProviderSubLocationList = this.providerSubLocationList;
  //     this.showLocations = [];
  //     filteredProviderSubLocationList.forEach(element => {
  //       if (element.physicalStateKey == this.stateFilter){
  //         this.showLocations.push(element)
  //       }
  //     })
  //   }
  //   else if(this.stateFilter == undefined){
  //     this.showLocations = this.providerSubLocationList
  //   }
  // }
  

  filterLocations(event: Event){

    if(this.stateFilter != undefined && this.stateFilter != 'All'){
      let filteredProviderSubLocationList = this.providerSubLocationList;
      this.showLocations = [];
      filteredProviderSubLocationList.forEach(element => {
        if (element.physicalStateKey == this.stateFilter){
          this.showLocations.push(element)
        }
        this.dataSource = new MatTableDataSource(this.showLocations);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
      let filteredItem = this.filterLocation.toLowerCase();
      this.showLocations = this.showLocations.filter(item => Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filteredItem)));
      this.dataSource = new MatTableDataSource(this.showLocations);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      return;
    }

    else if(this.stateFilter == 'All') {
      this.showLocations = [];
      this.showLocations = this.providerSubLocationList;
      this.dataSource = new MatTableDataSource(this.showLocations);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

    let filteredSubLocationList2 = this.providerSubLocationList;
    this.showLocations = [];

    let filteredItem = this.filterLocation.toLowerCase();
    console.log(filteredItem)
    
    filteredSubLocationList2 = this.providerSubLocationList.filter(item => Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filteredItem)))

    this.showLocations = filteredSubLocationList2;
    this.dataSource = new MatTableDataSource(this.showLocations);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log('this show locations after filter' + this.showLocations)

  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.showLocations.filter = filterValue.trim().toLowerCase();
  // }

  editSublocation(subLocation){
    let dialogRef = this.dialog.open(EditSublocationDataComponent, {
      width: '85%',
      height: '95%',
      disableClose: true,
      data: {sublocation: subLocation}
    })
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with result:', result);
      if(result = true){
        this.getSubLocations(this.providerDetails.ProviderKey);
      }
      else{return}
  })
  }

  getSevice() {
    this.bubbleService.setserviceNetworkData(null);
    sessionStorage.removeItem('expandedLayer');
    sessionStorage.setItem('expandedLayer', JSON.stringify(this.expandLayer));
    this.bubbleComponent.setExpandedTreeLevel();
    this.serviceData = [];

    if (this.providerDetails.isParent) {
      this.getProviderServiceOfferingRestrictions();
    } else {
      const locationData = {
        providerKey: this.providerDetails.providerKey,
        deleted: false
      };

      this.loading = true;
      this.bubbleService.getProviderService(locationData).subscribe(result => {
        this.loading = false;
        if (result.body.length > 0) {
          this.parentRecord = false;
        } else {
          this.parentRecord = true;
        }

        this.getProviderServiceOfferingRestrictions();
      }, (error) => {
        this.loading = false;
      });
    }

    this.bubbleService.getBubbleTreeData().subscribe(message => {
      this.serviceData = JSON.parse(this.bubbleService.getserviceNetworkData());
      this.parentRecord = false;
    });

    this.bubbleService.bubbleLoader.subscribe(message => {
      this.loading = message;
    });
  }

  getProviderServiceOfferingRestrictions() {
    const data = {
      providerKey: this.parentRecord ? this.providerDetails.parentProviderKey : this.providerDetails.providerKey,
    };

    this.loading = true;
    this.bubbleService.getProviderServiceOfferingRestrictions(data).subscribe(result => {
      this.serviceData = result;
      if (this.bubbleService.getserviceNetworkData() === null
        || this.bubbleService.getserviceNetworkData() === undefined) {
        this.bubbleService.setserviceNetworkData(JSON.stringify(this.serviceData));
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

}

