<div *ngIf="loading" id="preloader">
  <div id="loader"></div>
</div>
<form [formGroup]="formProfileDetails" novalidate>
  <div class="row m-2">
    <div class="col-sm-12 col-md-9">
      <div class="row">
        <div class="col-md-12 alert alert-danger m-2 text-center" *ngIf="atleasetOneRequired()">
          Please enter at least one phone number
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput placeholder="First Name" formControlName="firstName" required />
            <!-- error -->
            <mat-error *ngIf="errorHandling('firstName', 'required')">
              first name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput placeholder="Last Name" formControlName="lastName" required />
            <!-- error -->
            <mat-error *ngIf="errorHandling('lastName', 'required')">
              last name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="!userDetail.adminUser">
          <mat-form-field>
            <mat-select placeholder="Company Name" formControlName="customerKey" required>
              <mat-option [disabled]='true'>Select Company</mat-option>
              <mat-option *ngFor="let company of customers | orderBy : 'customerName'" [value]="company.customerKey">
                {{company.customerName }} {{ !company.tierName ? '': '-' }} {{company.tierName}}</mat-option>
            </mat-select>
            <mat-error *ngIf="errorHandling('customerKey', 'required')">You must select a Company</mat-error>
            <!-- <input matInput placeholder="Company Name" *ngIf="userDetail.adminUser" [value]="licensedOwnerName" [disabled]="true" required /> -->
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput placeholder="Job Title" formControlName="title" />

            <mat-error *ngIf="errorHandling('title', 'required')">
              Job title is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput placeholder="Email" formControlName="email" [disabled]="true" required />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput placeholder="Cell Phone" formControlName="cellPhone" mask="000-000-0000" />
            <mat-error *ngIf="errorHandling('cellPhone', 'minlength')">
              cell phone at least 10 digits
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput placeholder="Office Phone" formControlName="officePhone" mask="000-000-0000" />
            <mat-error *ngIf="errorHandling('officePhone', 'minlength')">
              office phone at least 10 digits
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select placeholder="Supervisor" formControlName="supervisor">
              <mat-option></mat-option>
              <mat-option *ngFor="let supervisor of supervisorData | orderBy : 'lastName'" [value]="supervisor.userKey">
                {{supervisor.lastName}} {{supervisor.firstName}}</mat-option>
            </mat-select>
            <!-- error -->
            <mat-error *ngIf="errorHandling('supervisor', 'required')">
              supervisor is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="userDetail.adminUser">
          <mat-form-field>
            <mat-select placeholder="Role" formControlName="contactCenterUserRoleKey" required>
              <mat-option [disabled]='true'>Select</mat-option>
              <mat-option *ngFor="let role of roleData | orderBy : 'contactCenterUserRoleKey'"
                [value]="role.contactCenterUserRoleKey">
                {{role.contactCenterUserRoleKey}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="errorHandling('contactCenterUserRoleKey', 'required')">Role is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="userDetail.adminUser">
          <mat-form-field>
            <mat-select placeholder="Department" formControlName="departmentKey" required>
              <mat-option [disabled]='true'>Select</mat-option>
              <mat-option *ngFor="let department of departmentData | orderBy : 'departmentKey'"
                [value]="department.departmentKey">
                {{department.departmentKey}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="errorHandling('departmentKey', 'required')">Role is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select placeholder="Status" formControlName="userStatusKey" required>
              <mat-option></mat-option>
              <mat-option *ngFor="let status of userStatus | orderBy : 'statusKey'" [value]="status.statusKey">
                {{status.statusKey}}
              </mat-option>
            </mat-select>
            <!-- error -->
            <mat-error *ngIf="errorHandling('userStatusKey', 'required')">
              Status is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-8">
          <mat-checkbox formControlName="okToTextMsg">
            <label>I agree to recieve Text Messaged to my Cell Phone from Service Plus.
              Messaging/Data Rates may apply</label>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="container">
        <div class="row mt-2">
          <div class="col-sm-12">
            <div class="imagePreview">
              <img [src]="imgURL" height="185" *ngIf="imgURL" />
            </div>
            <label class="btn image-button d-block mt-2" *ngIf="!readOnlyUser">
              Change Profile Picture
              <input type="file" accept='image/*' #file (change)="preview(file.files, $event)"
                class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;">
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>