import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AddEditEmployeeDialogsComponent } from '../add-edit-employee-dialogs/add-edit-employee-dialogs.component';
import { SkillsLevelExpertiseDailogComponent } from '../skills-level-expertise-dailog/skills-level-expertise-dailog.component';
import { SystemAccessRoleDailogComponent } from '../system-access-role-dailog/system-access-role-dailog.component';

@Component({
  selector: 'ngx-add-vendor-employee',
  templateUrl: './add-vendor-employee.component.html',
  styleUrls: ['./add-vendor-employee.component.scss']
})
export class AddVendorEmployeeComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(AddEditEmployeeDialogsComponent) addEditEmpDetails: AddEditEmployeeDialogsComponent;
  @ViewChild(SkillsLevelExpertiseDailogComponent) addSkillsComponent: SkillsLevelExpertiseDailogComponent;
  @ViewChild(SystemAccessRoleDailogComponent) systemRoles: SystemAccessRoleDailogComponent;

  userKey: String;
  selectedIndex: number = 0;
  userData: any;
  saveButtonText: string;
  goNextPage: boolean = false;

  constructor(
    public dialog: MatDialogRef<AddVendorEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.userData = this.data;
    if (this.data.value === 'Edit Employee') {
      this.userKey = this.userData.userKey;
      this.saveButtonText = 'Save';
    } else {
      this.saveButtonText = 'Add User';
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  currentStage(event) {
    this.selectedIndex = event.selectedIndex;
  }

  saveCompleted(event) {
    if (event.result === true && this.goNextPage) {
      this.userData.userKey = event.userKey;
      this.stepper.selected.completed = true;
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  saveAndNext(goNext) {
    this.goNextPage = goNext;
    this.addEditEmpDetails.addEditEmployeeDetails();
  }

  next(goNext) {
    // if (this.selectedIndex === 0) {
    //   this.addEditEmpDetails.addEditEmployeeDetails();
    // }

    const arrNoNextPage = [0, 1, 2,];
    this.goNextPage = goNext;
    if (goNext && arrNoNextPage.indexOf(this.selectedIndex) === -1) {
      this.selectedIndex = this.selectedIndex + 1;
    }
    if ((goNext && this.selectedIndex === 0) ||
      (goNext && this.selectedIndex === 1)
      // || (goNext && this.selectedIndex === 2)
    ) {
      this.stepper.selected.completed = true;
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  previous() {
    this.selectedIndex = this.selectedIndex - 1;
  }
}
