<app-loader [loading]="loading"></app-loader>
<form [formGroup]="hierarchyForm">
  <div class="row px-2">
    <div class="col-12 col-md-4">
      <mat-form-field>
        <mat-select placeholder="Hierarchy Tier" formControlName="tierName"
          (selectionChange)="tierChanged($event,'name')">
          <ng-container *ngIf="isLicensedOwner || isViewMasterCustomerDetails">
            <mat-option *ngFor="let tier of tiers" [value]="tier.customerTierStructureKey">
              {{tier.tierName}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-4">
      <mat-form-field>
        <mat-select placeholder="Hierarchy Tier Short Name" formControlName="tierNickname"
          (selectionChange)="tierChanged($event,'nickname')">
          <ng-container *ngIf="isLicensedOwner || isViewMasterCustomerDetails">
            <mat-option *ngFor="let tier of tiers " [value]="tier.customerTierStructureKey">
              {{tier.tierNickname}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="div-grid">
  <mat-tree class="custom-mat-tree mt-2" [dataSource]="dataSource" [treeControl]="treeControl" cdkDropList
    (cdkDropListDropped)="drop($event)">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding cdkDrag [cdkDragData]="node"
      (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
      (cdkDragReleased)="dragEnd()">
      <div mat-icon-button disabled></div>
      {{ node.filename }} : {{ node.type }}
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle class="btn btn-sm" [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <div class="hide">
        <span><a class="node-size" [ngClass]="{
        'selectedCustomer': selectedCustomerHierarchy == node.customerKey
        }" href="" onclick="return false;" (click)="displayCustomers(node)">{{node.item}}<span *ngIf="node.isCustomer"
              class="ml-1 badge badge-pill badge-secondary">{{node.count}}</span></a></span>
        <button *ngIf="!customerHierarchy" mat-icon-button class="btn btn-sm hide-item" (click)="addNewItem(node)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
<div class="div-grid" *ngIf="customerData.length > 0;">
  <fieldset class="border m-0 position-relative p-2 mt-2">
    <legend class="font-weight-bold p-1 mb-0 w-auto node-size">
      Customer Names:
    </legend>
    <mat-list *ngFor="let customer of customerData" role="list">
      <mat-list-item class="node-size" role="listitem"><i class="fa fa-circle circle-size mr-2"></i><a class="node-size"
          href="" onclick="return false;" (click)="displayProfile(customer)">{{ customer.customerName}}</a>
      </mat-list-item>
    </mat-list>
  </fieldset>
</div>
<div class="text-right m-2" *ngIf="isDomainBreakdown">
  <button class="btn btn-primary btn-sm mr-1" [disabled]="disableBtn" (click)="saveHierarchy()">Save</button>
</div>