import { Component, OnInit, Input, EventEmitter, Output, Inject, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

// Services
import { UsersService } from 'src/app/services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { patterns } from 'src/assets/patterns';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { EditUserDetailsDialogComponent } from 'src/app/dialogs/edit-user-details-dialog/edit-user-details-dialog.component';
import { UserExperianceService } from 'src/app/services/user-experiance.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})

export class UserDetailsComponent implements OnInit {

  @ViewChild('file') myInputVariable: ElementRef;
  formProfileDetails: UntypedFormGroup;
  companyData: any;
  securityGroupData: any;
  hierarchyLevelNameData: any;
  supervisorData: any;
  userStatus: any;
  loading = false;
  user: any;
  customer: any;
  customerTier: any;
  customers: any;
  contactCenterTeams: any;
  fileData: any;
  imgURL: string | ArrayBuffer = '../assets/images/placeholder.png';
  imagePath: any;
  oldImageUrl: string;
  licensedOwnerName = sessionStorage.getItem('licensedOwnerName');
  @Input() userDetail: any;
  roleData = [];
  departmentData = [];
  readOnlyUser = false;
  userTypeKey: string;
  @Output() saveCompleted = new EventEmitter<any>();

  constructor(
    public formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private toast: NbToastrService,
    private commonService: CommonService,
    private customerService: CustomerService,
    public dialogService: MatDialog,
    private imageCompress: NgxImageCompressService,
    private userExperianceService: UserExperianceService,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit() {
    this.readOnlyUser = this.userDetail.readOnlyUser || false;
    this.formProfileDetails = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      customerKey: ['', [Validators.required]],
      title: [''],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      cellPhone: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(patterns.mobnumPattern)]],
      officePhone: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(patterns.mobnumPattern)]],
      supervisor: [''],
      contactCenterUserRoleKey: [''],
      departmentKey: [''],
      userStatusKey: ['', [Validators.required]],
      okToTextMsg: [true],
    });

    if (this.userDetail.adminUser) {
      this.formProfileDetails.get('customerKey').clearValidators();
      this.formProfileDetails.updateValueAndValidity();
      // Licensed Owner
      this.userTypeKey = '9c4ef5b4-cadb-400b-adf4-05b33798f1fd';
    } else {
      this.formProfileDetails.get('contactCenterUserRoleKey').clearValidators();
      this.formProfileDetails.get('departmentKey').clearValidators();
      this.formProfileDetails.updateValueAndValidity();
      // Customer
      this.userTypeKey = '90dc6b51-9731-439b-bd21-40685f989f24';
    }

    if (this.userDetail.readOnlyUser) {
      this.formProfileDetails.disable();
    }
    this.loadUserDetails();
    this.getDropDowns();
  }

  loadUserDetails() {
    this.loading = true;
    const filter = { userKey: this.userDetail.userKey, deleted: false };

    this.usersService.getUsers(filter).subscribe(response => {
      if (response.success) {
        this.user = response.body[0];
        if (this.user.profilePictureURL) {
          this.imgURL = atob(this.user.profilePictureURL);
          this.oldImageUrl = this.imgURL;
        }

        this.formProfileDetails.setValue({
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          customerKey: this.userDetail.customerKey,
          title: this.user.title,
          email: this.user.email,
          cellPhone: this.user.cellPhone ? this.user.cellPhone.split('+1').join('') : '',
          officePhone: this.user.officePhone ? this.user.officePhone.split('+1').join('') : '',
          supervisor: this.user.supervisor,
          contactCenterUserRoleKey: this.user.contactCenterUserRoleKey,
          departmentKey: this.user.departmentKey,
          userStatusKey: this.user.userStatusKey,
          okToTextMsg: this.user.okToTextMsg,
        });
        this.loading = false;
      } else {
        console.log('error in response');
      }
    }, error => {
      console.log(error.message);
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.formProfileDetails.controls[control].hasError(error);
  }

  canDeactivate(): boolean {
    return this.formProfileDetails.dirty && this.formProfileDetails.touched;
  }

  refresh() {
    this.ngOnInit();
  }

  atleasetOneRequired() {
    const form = this.formProfileDetails.value;
    if (form.officePhone || form.cellPhone) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Method to update user details
   */
  public updateUser() {
    if (this.formProfileDetails.invalid || this.atleasetOneRequired()) {
      this.formProfileDetails.markAllAsTouched();
      return false;
    }

    if (this.formProfileDetails.dirty && this.formProfileDetails.valid && this.userDetail) {
      const userUpdateDetails = this.formProfileDetails.value;
      const updateReqs = [];

      if (this.userDetail.adminUser) {
        delete userUpdateDetails.team;
      } else {
        delete userUpdateDetails.contactCenterUserRoleKey;
        delete userUpdateDetails.departmentKey;
        userUpdateDetails.associationKey = userUpdateDetails.customerKey;
      }

      userUpdateDetails.userKey = this.user.userKey;
      userUpdateDetails.updatedUserKey = sessionStorage.getItem('userKey');

      updateReqs.push(this.usersService.updateUser(userUpdateDetails));

      if (!this.userDetail.adminUser) {
        const updateData = {
          userExperianceKey: this.userDetail.userExperianceKey,
          associationKey: userUpdateDetails.customerKey,
        }

        if (updateData.userExperianceKey) {
          updateReqs.push(this.userExperianceService.updateUserExperiances(updateData));
        }
      }

      this.loading = true;
      forkJoin(updateReqs).subscribe((results: any) => {
        if (results[0].success) {
          this.toast.success('User with ' + this.userDetail.email + ' updated successfully.', 'Success');
          this.saveCompleted.emit('user');
          this.formProfileDetails.markAsUntouched();
        } else {
          this.toast.danger('Unable to update the user details.', 'Something went wrong!');
        }
        this.loading = false;
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }

  getDropDowns() {
    this.loading = true;
    const departmentReq = this.commonService.getDepartments({ deleted: false });
    const supervisorFilter = {
      departmentKey: 'Executive',
      deleted: false,
    };
    let supervisorReq = this.usersService.getUsers(supervisorFilter);

    if (this.userDetail.adminUser) {
      supervisorReq = this.usersService.getSupervisorUsers(sessionStorage.getItem('licensedOwnerKey'));
    }

    const customersReq = this.customerService.getCustomerList({ deleted: false });
    const filter = { association: 'user', deleted: false };
    const userstatusesReq = this.commonService.getUserStatuses(filter);
    const rolesReq = this.commonService.getContactCenterUserRoles({ deleted: false });

    forkJoin([supervisorReq, customersReq, userstatusesReq, rolesReq, departmentReq]).subscribe(results => {
      this.supervisorData = results[0].body || [];
      this.customers = results[1].body || [];
      this.userStatus = results[2].body || [];
      this.roleData = results[3].body || [];
      this.departmentData = results[4].body || [];
      this.loading = false;
      this.getUsersByType();
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /**
   *  Method to get Supervisors based on departmentKey
   */
  getUsersByType() {
    if (this.userDetail.departmentKey) {
      if (this.userDetail.departmentKey !== 'Executive') {
        const objParam = {
          userTypeKey: this.userTypeKey,
          departmentKey: this.userDetail.departmentKey,
          deleted: false,
        };
        this.loading = true;
        this.usersService.getUsers(objParam).subscribe((result) => {
          result.body.map((data) => {
            this.supervisorData.push(data);
          });
          this.loading = false;
        }, (error) => {
          console.log(error);
          this.loading = false;
        });
      }
    }
  }

  preview(files: any, event: any) {
    if (files.length === 0) {
      return false;
    }
    const fileReader = new FileReader();
    fileReader.onload = (res: any) => {
      this.imageCompress.compressFile(res.target.result, -1, 50, 50).then(result => {
        this.utilityService.urltoFile(result, event.target.files[0].name, files[0].type).then((file) => {
          this.fileData = file;
          const formData = new FormData();
          formData.append('file', this.fileData);
          formData.append('fileType', 'image');

          const mimeType = files[0].type;
          if (mimeType.match(/image\/*/) == null) {
            this.toast.danger('Only image formats are supported.');
            return false;
          }

          const size = files[0].size;
          if (Math.round(size / 1024) > 5120) {
            this.toast.danger('Image size should not exceed 5MB.');
            this.myInputVariable.nativeElement.value = '';
            return false;
          }

          this.loading = true;
          const reader = new FileReader();
          this.imagePath = files;

          reader.readAsDataURL(files[0]);
          reader.onload = () => {

            this.commonService.updateProfilePicture(formData).subscribe(resFileDetail => {
              if (resFileDetail.status) {
                this.toast.success('Profile Uploaded.!', 'Success');
                if (this.oldImageUrl) {
                  const deleteImg = new FormData();
                  deleteImg.append('file_name', this.oldImageUrl);
                  this.commonService.deleteProfilePicture(deleteImg).subscribe((response: any) => {
                    this.loading = false;
                  }, (error) => {
                    this.loading = false;
                  });
                }

              } else {
                this.toast.danger('Failed to upload profile.!', 'Error');
              }
              const data = {
                userKey: this.userDetail.userKey,
                profilePictureURL: resFileDetail.url
              };

              this.imgURL = resFileDetail.url;

              this.usersService.updateUser(data).subscribe((response: any) => {
                this.loading = false;
                this.usersService.setProfilePicture(resFileDetail.url);
              }, (error) => {
                this.loading = false;
                this.toast.danger(error.message, 'Error');
              });
            }, (error) => {
              this.loading = false;
              this.toast.danger('Failed to update profile picture.!', 'Error');
            });
          };
        }, error => {
          this.toast.danger('Failed to update profile picture.!', 'Error');
          console.log('Failed to update profile picture.!', error);
        });
      });
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  editUserDetails() {
    const dialogRef = this.dialogService.open(EditUserDetailsDialogComponent, {
      hasBackdrop: false,
      width: '60%',
      data: { profileDetails: this.user },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.loadUserDetails();
      }
    });
  }
}
