<div class="mat-elevation-z8">
    <!-- <h6 mat-dialog-title>Deleted Providers </h6> -->
    <div class="modal-header mr-4 ml-4 ">
        <h6 class="modal-title">Deleted Providers List
        </h6>
        <button class="close"
                type="button"  
                (click)="closeDialog()" 
                nbTooltip="Close"
                nbTooltipPlacement="top">&times;
        </button>
    </div>


    <div > 
        <!-- <hr> -->

        <div class="mr-4 ml-4 mt-4">
            <div class="row">
                <mat-form-field class="col-md-6">
                    <mat-label>Filter Deleted Applications</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Filter by name, address, or deletion reason">
                </mat-form-field>
            </div>
        </div>
    <div>
        <table mat-table [dataSource]="dataSource" matSort matSortActive="insertedTimestamp" matSortDirection="desc">
           
            <!-- Date Column -->
            <ng-container matColumnDef="insertedTimestamp">
                <th mat-header-cell *matHeaderCellDef style="width: 15%;" mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.insertedTimestamp | date: 'short'}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;" mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>

            
            <!-- Address Column -->
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Address </th>
                <td mat-cell *matCellDef="let element"> {{element.fullAddress}} </td>
            </ng-container>

            <!-- Phone Column -->
            <!-- <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Phone </th>
                <td mat-cell *matCellDef="let element"> {{element.businessHoursPhone}} </td>
            </ng-container> -->

            <!-- Email Column -->
            <!-- <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="width: 12%;"> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.dispatchEmail}} </td>
            </ng-container> -->

            <!-- Phone Column -->
            <!-- <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Phone </th>
                <td mat-cell *matCellDef="let element"> {{element.businessHoursPhone}} </td>
            </ng-container> -->

            <!-- Email Column -->
            <ng-container matColumnDef="deletedReason">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Deletion Reason </th>
                <td mat-cell *matCellDef="let element"> {{element.removalReason}} </td>
            </ng-container>

            <!-- Action Column -->
            <!-- <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Action </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item >Delete Application</button>
                    </mat-menu>
                </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
           aria-label="Select page of periodic elements">
        </mat-paginator> 

    </div>
    <br>
    </div>

</div>
