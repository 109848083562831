import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TierService {

  public tierData = new Subject<any>();
  tierDetails = {
    tierName: '',
    tierNickname: ''
  }
  constructor(private http: HttpClient) { }

  getTierDetails(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customertierstructures/get`, filter);
  }

  addTier(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customertierstructures`, data);
  }

  // Update customer tier structure
  updateTier(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customertierstructures`, data);
  }

  addMultipleTiers(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customertierstructures/batch`, data);
  }

  updateMultipleTiers(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customertierstructures/batch`, data);
  }

  deleteTiers(data): Observable<any> {
    const deleteNotesData: any = {
      body: data
    };
    return this.http.delete<any>(`${environment.apiUrl}fleetassist/customertierstructures`, deleteNotesData);
  }

  setTierData() {
    this.tierData.next();
  }

  getTierData(): Observable<any> {
    return this.tierData.asObservable();
  }

  getTierName() {
    return this.tierDetails;
  }

  setTierName(tierName, tierNickname) {
    this.tierDetails.tierName = tierName;
    this.tierDetails.tierNickname = tierNickname;
  }

}
