import { Component } from '@angular/core';

@Component({
  selector: 'ngx-discountproviderview',
  templateUrl: './discountproviderview.component.html',
  styleUrls: ['./discountproviderview.component.scss']
})

export class DiscountProviderViewComponent {

  flag: boolean = false;

  constructor() { }

  DiscountProviderView = [
    { id: "1", discount: "20" },
    { id: "2", discount: "10" },
    { id: "3", discount: "10" },
    { id: "4", discount: "10" },
    { id: "5", discount: "10" },
    { id: "6", discount: "10" },
    { id: "7", discount: "10" },
    { id: "8", discount: "10" },
    { id: "9", discount: "10" },
    { id: "10", discount: "30" }
  ]

  test() {
    this.flag = true;
  }

}