import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditProviderUsersComponent } from 'src/app/pages/provider-admin/provider-management-users/edit-provider-users/edit-provider-users.component';

@Component({
  selector: 'app-provider-users-dialog',
  templateUrl: './provider-users-dialog.component.html',
  styleUrls: ['./provider-users-dialog.component.scss']
})
export class ProviderUsersDialogComponent implements OnInit {
  @ViewChild(EditProviderUsersComponent) addEditEmpDetails: EditProviderUsersComponent;

  userKey: String;
  selectedIndex = 0;
  userData: any;
  saveButtonText: string;
  goNextPage = false;

  constructor(
    public dialog: MatDialogRef<ProviderUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.userData = this.data;
    if (this.data.value === 'Edit Employee') {
      this.userKey = this.userData.userKey;
      this.saveButtonText = 'Save';
    } else {
      this.saveButtonText = 'Add User';
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  tabChange(event) {
    this.selectedIndex = event.index;
  }

  saveCompleted(event) {
    if (event.result === true && this.goNextPage) {
      this.userData.userKey = event.userKey;
      this.selectedIndex = this.selectedIndex;
    }
  }

  next(goNext) {
    if (this.selectedIndex === 0) {
      this.addEditEmpDetails.addEditEmployeeDetails();
    }

    const arrNoNextPage = [0, 1, 2];
    this.goNextPage = goNext;
    if (goNext && arrNoNextPage.indexOf(this.selectedIndex) === -1) {
      this.selectedIndex = this.selectedIndex + 1;
    }
    if ((goNext && this.selectedIndex === 0) || (goNext && this.selectedIndex === 1)) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  previous() {
    this.selectedIndex = this.selectedIndex - 1;
  }

}
