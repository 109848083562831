import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { SortEvent, SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';
import { ProviderAccountService } from 'src/app/services/provider-account.service';
import { ProfileService } from 'src/app/services/profile.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ngx-customer-preferences',
  templateUrl: './customer-preferences.component.html',
  styleUrls: ['./customer-preferences.component.scss'],
})
export class CustomerPreferencesComponent implements OnInit {

  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  @Input() providerAccount;
  @Input() isParent: boolean = true;

  loading: boolean = false;
  showOwnBy: boolean = false;
  isParentData = false;
  preferenceData: any = [];
  customerName: string;
  licensedOwnerKey: string;
  searchString: string;

  constructor(
    private utilityService: UtilityService,
    private profileService: ProfileService,
    private providerAccountService: ProviderAccountService,
  ) { }

  ngOnInit() {
    this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
    this.getOwnByAndProviderPreference(this.providerAccount.providerKey);
  }

  getOwnByAndProviderPreference(providerKey) {
    this.customerName = '';
    this.preferenceData = [];

    const filter = {
      providerKey: providerKey,
      customerOwnedProvider: true,
      deleted: false,
    };
    const prefFilter = {
      providerKey: providerKey,
      licensedOwnerKey: this.licensedOwnerKey,
      customerKey: null,
    };
    const providerPrefReq = this.providerAccountService.getProviderAccountProviderPreference(prefFilter);
    const custOwnReq = this.profileService.getProviderDetails(filter);

    this.loading = true;
    forkJoin([providerPrefReq, custOwnReq]).subscribe((result: any) => {
      this.loading = false;

      this.preferenceData = result[0].body;
      this.onSort({ column: 'customerName', direction: 'asc' });
      if (result[0].body.length === 0 && this.isParent === false && this.isParentData === false) {
        // load data of parent
        this.isParentData = true;
        this.getOwnByAndProviderPreference(sessionStorage.getItem('providerKey'));
      }

      if (result[1].body.length > 0) {
        this.showOwnBy = true;
        const custFilter = {
          customerKey: result[1].body[0].customerOwnedCustomerKey,
          deleted: false,
        };

        this.loading = true;
        this.providerAccountService.getCustomerDetails(custFilter).subscribe((res: any) => {
          this.loading = false;
          this.customerName = res.body[0] ? res.body[0].customerName : '';
        }, (error) => {
          this.loading = false;
        });
      }

    }, (error) => {
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.preferenceData = this.utilityService.sortData(this.headers, column, direction, this.preferenceData);
  }

  search(event) {
    this.searchString = event;
  }

}
