<div class="modal-header">
    <h6 class="modal-title">My Profile</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <app-loader [loading]="loading"></app-loader>
    <form [formGroup]="profileForm">
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Customer Name" formControlName="customerName" required>
                            <mat-error *ngIf="handleError('customerName', 'required')">
                                Customer Name is required
                            </mat-error>
                            <mat-error *ngIf="handleError('customerName', 'pattern')">
                                Enter valid Customer Name
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Account Number" formControlName="accountNumber">
                            <mat-error *ngIf="handleError('accountNumber', 'pattern')">
                                Enter valid Account Number
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Alternate Account Number"
                                formControlName="alternateAccountNumber">
                            <mat-error *ngIf="handleError('alternateAccountNumber', 'pattern')">
                                Enter valid Alternate Account Number
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Address1" formControlName="physicalAddress1" required
                                ngx-google-places-autocomplete (onAddressChange)="getDetailedAddress($event)">
                            <mat-error *ngIf="handleError('physicalAddress1', 'required')">
                                Address is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Address2" formControlName="physicalAddress2">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Address3" formControlName="physicalAddress3">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="City" formControlName="physicalCity" required>
                            <mat-error *ngIf="handleError('physicalCity', 'required')">
                                City is required
                            </mat-error>
                            <mat-error *ngIf="handleError('physicalCity', 'pattern')">
                                Enter valid City
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-select placeholder="State" formControlName="physicalStateKey" required>
                                <mat-option *ngFor="let state of states" [value]="state.stateKey">
                                    {{state.stateDescription}}
                                </mat-option>
                            </mat-select>
                            <!-- <input placeholder="State" formControlName="physicalStateKey"
                                [(ngModel)]="userDetail.physicalStateKey" matInput required>  -->
                            <mat-error *ngIf="handleError('physicalStateKey', 'required')">
                                State is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Zip Code" formControlName="physicalPostalCode" required>
                            <mat-error *ngIf="handleError('physicalPostalCode', 'required')">
                                Zip Code is required
                            </mat-error>
                            <mat-error *ngIf="handleError('physicalPostalCode', 'pattern')">
                                Enter valid Zip Code
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Company Short Name" formControlName="customerShortName">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Dedicated Breakdown Line" mask="000-000-0000"
                                formControlName="dedicatedBreakdownPhone" required>
                            <mat-error *ngIf="handleError('dedicatedBreakdownPhone', 'required')">
                                Dedicated Breakdown Line is required
                            </mat-error>
                            <mat-error *ngIf="handleError('dedicatedBreakdownPhone', 'pattern')">
                                Enter valid Dedicated Breakdown Line
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <input matInput placeholder="Dedicated Claim Line" mask="000-000-0000"
                                formControlName="dedicatedClaimPhone">
                            <mat-error *ngIf="handleError('dedicatedBreakdownPhone', 'pattern')">
                                Enter valid Dedicated Claim Line
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="container">
                    <div class="row mt-2">
                        <div class="col-sm-12">
                            <div class="image-preview">
                                <img class="w-100" [src]="imgURL" height="185">
                            </div>
                            <label class="btn image-button d-block mt-2">
                                Change Profile Picture
                                <input type="file" accept='image/*' #file (change)="preview(file.files, $event)"
                                    class="uploadFile img" value="Upload Photo"
                                    style="width: 0px;height: 0px;overflow: hidden;">
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-md-12 text-right">
            <button class="btn btn-sm btn-primary" (click)="updateProfile()">Save</button>
        </div>
    </div>
</div>