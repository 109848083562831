import { Component, OnInit, ElementRef, ViewChild, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { CustomerService } from 'src/app/services/customer.service';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { CommonService } from 'src/app/services/common.service';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-my-profile-dialog',
  templateUrl: './my-profile-dialog.component.html',
  styleUrls: ['./my-profile-dialog.component.scss']
})
export class MyProfileDialogComponent implements OnInit {

  @ViewChild('file') myInputVariable: ElementRef;
  eventProvfile = new EventEmitter();
  loading = false;
  profileForm: UntypedFormGroup;
  customerDetailsData: any = [];
  imgURL: string | ArrayBuffer = '../assets/images/placeholder.png';
  fileData: any;
  imagePath: any;
  userDetail: any;
  customerKey: any;
  mailAddressObj: Address;
  customerList: any[];
  states: any = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    public dialog: MatDialogRef<MyProfileDialogComponent>,
    private http: HttpClient,
    private toast: NbToastrService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private imageCompress: NgxImageCompressService,
  ) { }

  ngOnInit() {
    this.userDetail = this.data;
    this.profileForm = this.formBuilder.group({

      customerName: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      accountNumber: ['', Validators.pattern(patterns.textPattern)],
      alternateAccountNumber: ['', Validators.pattern(patterns.textPattern)],
      physicalAddress1: ['', Validators.pattern(patterns.textPattern)],
      physicalAddress2: ['', Validators.pattern(patterns.textPattern)],
      physicalAddress3: ['', Validators.pattern(patterns.textPattern)],
      physicalCity: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      physicalStateKey: ['', Validators.required],
      physicalPostalCode: ['', [Validators.required, Validators.pattern(patterns.alphanumericZipCodePattern)]],
      customerShortName: [''],
      dedicatedBreakdownPhone: ['', [Validators.required, Validators.pattern(patterns.mobnumPattern)]],
      dedicatedClaimPhone: ['', [Validators.pattern(patterns.mobnumPattern)]]
    });
    this.getProfileDetail();
    this.getStates();

  }

  getStates() {
    this.loading = true;
    this.commonService.getstates().subscribe(res => {
      this.loading = false;
      this.states = res.body || [];
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getProfileDetail() {

    const filter = {
      customerKey: this.data.customerKey
    };
    this.loading = true;

    this.customerService.getProfileDetails(filter).subscribe(res => {
      this.customerDetailsData = res.body[0];
      this.profileForm.setValue({
        customerName: this.customerDetailsData.customerName,
        accountNumber: this.customerDetailsData.accountNumber,
        alternateAccountNumber: this.customerDetailsData.alternateAccountNumber,
        physicalAddress1: this.customerDetailsData.physicalAddress1 || '',
        physicalAddress2: this.customerDetailsData.physicalAddress2 || '',
        physicalAddress3: this.customerDetailsData.physicalAddress3 || '',
        physicalCity: this.customerDetailsData.physicalCity,
        physicalStateKey: this.customerDetailsData.physicalStateKey,
        physicalPostalCode: this.customerDetailsData.physicalPostalCode || '',
        customerShortName: this.customerDetailsData.customerShortName,
        dedicatedBreakdownPhone: this.customerDetailsData.dedicatedBreakdownPhone,
        dedicatedClaimPhone: this.customerDetailsData.dedicatedClaimPhone
      });

      if (this.customerDetailsData.logoURL) {
        //   this.imgURL = atob(this.customerDetailsData.logoURL);

        this.imgURL = res.body[0].logoURL ? atob(res.body[0].logoURL) : this.imgURL;
      }
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }
  updateProfile() {
    if (this.profileForm.invalid) {
      this.profileForm.markAllAsTouched();
      return false;
    }
    const insertData = {
      customerKey: this.customerDetailsData.customerKey,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
    };
    const filter = { ...this.profileForm.value, ...insertData }
    this.loading = true;
    this.customerService.updateCustomerDetails(filter).subscribe(result => {
      if (result.success) {
        this.toast.success('Profile updated Successfully!', 'Success');
      } else {
        this.toast.warning(result.message[0], 'Error');
      }
      this.loading = false;
      this.eventProvfile.emit();
      this.dialog.close();
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  handleError = (control: string, error: string) => {
    return this.profileForm.controls[control].hasError(error);
  }

  closeDialog() {
    this.dialog.close();
  }

  public getDetailedAddress(address: Address) {
    this.mailAddressObj = address;
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    let streetNumber = '';
    address.address_components.forEach(element => {
      element.types.forEach(data => {
        if (data === 'postal_code') {
          this.profileForm.controls.physicalPostalCode.setValue(element.long_name);
        } else if (data === 'neighborhood') {
          this.profileForm.controls.physicalAddress2.setValue(element.long_name);
        } else if (data === 'administrative_area_level_1') {
          this.profileForm.controls.physicalStateKey.setValue(element.short_name);
        } else if (data === 'administrative_area_level_2') {
          this.profileForm.controls.physicalAddress3.setValue(element.long_name);
        } else if (data === 'locality') {
          this.profileForm.controls.physicalCity.setValue(element.long_name);
        } else if (data === 'street_number') {
          streetNumber = element.long_name + ' ';
        }
      });
    });
    const physicalAddress = address.name + ' ' + streetNumber;
    this.profileForm.controls.physicalAddress1.setValue(physicalAddress);
  }

  preview(files: any, event: any) {
    if (files.length === 0) {
      return false;
    }
    const fileReader = new FileReader();
    fileReader.onload = (res: any) => {
      this.imageCompress.compressFile(res.target.result, -1, 50, 50).then(result => {
        this.utilityService.urltoFile(result, event.target.files[0].name, files[0].type).then((file) => {
          this.fileData = file;
          const formData = new FormData();
          formData.append('file', this.fileData);
          formData.append('fileType', 'image');

          var mimeType = files[0].type;
          if (mimeType.match(/image\/*/) == null) {
            this.toast.danger('Only image formats are supported.');
            return false;
          }

          var size = files[0].size;
          if (Math.round(size / 1024) > 5120) {
            this.toast.danger('Image size should not exceed 5MB.');
            this.myInputVariable.nativeElement.value = "";
            return false;
          }

          this.loading = true;
          var reader = new FileReader();
          this.imagePath = files;

          reader.readAsDataURL(files[0]);
          reader.onload = (_event) => {

            this.customerService.updateProfilePicture(formData).subscribe(resFileDetail => {
              if (resFileDetail['status']) {
                this.toast.success('Profile Uploaded.!', 'Success');
                const deleteImg = new FormData();
                deleteImg.append('file_name', atob(sessionStorage.getItem('loginUserProfile')));
                this.customerService.deleteProfilePicture(deleteImg).subscribe((response: any) => {
                  this.loading = false;
                }, (error) => {
                  this.loading = false;
                });
              } else {
                this.toast.danger('Failed to upload profile.!', 'Error');
              }
              let data = {
                "customerKey": this.customerDetailsData.customerKey,
                "logoURL": resFileDetail['url']
              };

              this.imgURL = resFileDetail['url'];

              this.http.put(`${environment.apiUrl}fleetassist/customers`, data).subscribe((response: any) => {
                sessionStorage.setItem('loginUserProfile', btoa(resFileDetail['url']));
                this.utilityService.setProfilePicture(resFileDetail['url']);
                this.loading = false;
              }, (error) => {
                this.loading = false;
                this.toast.danger(error.message, 'Error');
              });
            }, (error) => {
              this.loading = false;
              this.toast.danger('Failed to update profile picture.!', 'Error');
            });
          }
        }, error => {
          this.toast.danger('Failed to update profile picture.!', 'Error');
          console.log('Failed to update profile picture.!', error);
        });
      });
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

}