// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://prod-serviceplusapi.auxiliumworldwide.com/', //this is servicePlus API
  // baseUrl: 'http://localhost:8081/',
  dispatchUrl: 'https://prod-dispatchapi.auxiliumworldwide.com/', //this is Dispatch API
  apiUrl: 'https://prod-admin.auxiliumworldwide.com/api/',
  breakDownApi: 'https://apiaccess.breakdownnow.com/api/',
  cognitoSignupUrl: 'https://prod-admin.auxiliumworldwide.com/api/aws/idp',
  siteUrl: 'https://serviceplus.auxiliumworldwide.com',
  // siteUrl: 'http://localhost:8082',

  // Cognito Setting test
  issuer: 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_m30PCILwC',
  clientId: '2bs1dmtpro22s9qi1nm561v15c',
  cognitoUrl: 'https://auxilium-prod.auth.us-east-2.amazoncognito.com',
  secretKey: '4639hi2s6nkpr1ienvos274mb56j73r5ag4a2mvmrumljvjpk60'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

