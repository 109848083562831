import { Component, Testability } from '@angular/core';

@Component({
  selector: 'ngx-discountsetup',
  templateUrl: './discountsetup.component.html',
  styleUrls: ['./discountsetup.component.scss']
})

export class DiscountsetupComponent {
  isAddRow: boolean = false;
  disableEdit: boolean = false;

  constructor() { }

  DiscountSetup = [
    { id: "1", service: "MDiscountw", discount: "20", editFlag: false },
    { id: "2", service: "Michelin New", discount: "10", editFlag: false },
    { id: "3", service: "Michelin New", discount: "10", editFlag: false },
    { id: "4", service: "Michelin New", discount: "10", editFlag: false },
    { id: "5", service: "Michelin New", discount: "10", editFlag: false },
    { id: "6", service: "Michelin New", discount: "10", editFlag: false },
    { id: "7", service: "Michelin New", discount: "10", editFlag: false },
    { id: "8", service: "Michelin New", discount: "10", editFlag: false },
    { id: "9", service: "Michelin New", discount: "10", editFlag: false },
    { id: "10", service: "Michelin New", discount: "30", editFlag: false }
  ]

  editDiscount(service) {
    service.editFlag = !service.editFlag;
    //this.disableEdit = !this.disableEdit;
  }
  // CancleApprovals(service){
  //   service.editFlag = !service.editFlag;
  // }

  addRow() {
    this.isAddRow = true;
  }
}