import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AddLocationDialogComponent } from 'src/app/dialogs/add-location-dialog/add-location-dialog.component';
import { AlertDialogComponent } from 'src/app/dialogs/alert-dialog/alert-dialog.component';
import { ProviderAdminListComponent } from 'src/app/dialogs/provider-admin-list/provider-admin-list.component';
import { CommonService } from 'src/app/services/common.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ProviderAdminDetailsComponent } from './provider-admin-details/provider-admin-details.component';
import { ProviderManagementProfileDetailsComponent } from './provider-management-profile-details/provider-management-profile-details.component';
import { ConfirmationDialogComponent } from '../provider-match/confirmation-dialog/confirmation-dialog.component';
import { patterns } from 'src/assets/patterns';
import { LocationDetailsListComponent } from './location-details-list/location-details-list.component';

@Component({
  selector: 'app-provider-admin',
  templateUrl: './provider-admin.component.html',
  styleUrls: ['./provider-admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderAdminComponent implements OnInit {

  @ViewChild(ProviderManagementProfileDetailsComponent) providerManagementProfileDetailsComponent:
    ProviderManagementProfileDetailsComponent;
  @ViewChild(ProviderAdminDetailsComponent) providerDetailsReq: ProviderAdminDetailsComponent;

  loading = false;
  selectedIndex = 0;
  providerDetails: any = [];
  isDataChanged = false;
  providerList: any;
  searchCtrl = '';
  searchLocationList = '';
  providerLocationList: any;
  providerAdminForm: UntypedFormGroup;
  isSelectProvider = false;
  isAddButton: boolean;
  isDiscount = true;
  providerData: any;
  selectedProviderKey: string;
  locationKey: string;
  providerSet: any = false;
  providerColor: any = "";
  advancedSearch: any = true; // false;

  statesData: any;
  usStates: any;
  parentInfo: any = [];
  returnToParentOption: boolean = false;

  constructor(
    private providerAdminService: ProviderAdminService,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.getStates();
    sessionStorage.removeItem('providerKey');

    this.providerAdminForm = this.formBuilder.group({
      provider: [null],
      locationKey: [null],
      searchText: [null],
      providerIDText: [null],
      addressText: [null],
      stateText: [null],
      cityText: [null],
      phoneNumberText: ['', Validators.pattern(patterns.mobnumPattern)],
      zipCodeText: [null, Validators.pattern(/^[0-9]\d*$/)],
      companyFEIN: [null]
    });

    this.getDiscountModuleSubscription();

  }

  changeSearch()
  {
    this.advancedSearch = !this.advancedSearch;
  }

  getStates() {
    if (sessionStorage.getItem('states') == null) {
      this.commonService.getstates().subscribe(result => {
        this.statesData = result.body;
        sessionStorage.setItem('states', JSON.stringify(result));
      });
    } else {
      this.statesData = JSON.parse(sessionStorage.getItem('states')).body;
    }   
  }

  getProviderParentLocations(){

    if(this.providerAdminForm.value.searchText == ''){
      this.providerAdminForm.value.searchText = null
    }
    if(this.providerAdminForm.value.providerIDText == ''){
      this.providerAdminForm.value.providerIDText = null
    }
    if(this.providerAdminForm.value.addressText == ''){
      this.providerAdminForm.value.addressText = null
    }
    if(this.providerAdminForm.value.cityText == ''){
      this.providerAdminForm.value.cityText = null
    }
    if(this.providerAdminForm.value.stateText == ''){
      this.providerAdminForm.value.stateText = null
    }
    if(this.providerAdminForm.value.zipCodeText == ''){
      this.providerAdminForm.value.zipCodeText = null
    }

    if(this.providerAdminForm.value.phoneNumberText != null && this.providerAdminForm.value.phoneNumberText.length == 10){
        this.providerAdminForm.value.phoneNumberText = '+1' + this.providerAdminForm.value.phoneNumberText
      }
    else if(this.providerAdminForm.value.phoneNumberText == ''){
      this.providerAdminForm.value.phoneNumberText = null
    }

    const searchData = {
      providerName: this.providerAdminForm.value.searchText,
      providerID: this.providerAdminForm.value.providerIDText,
      addressLine1: this.providerAdminForm.value.addressText,
      city: this.providerAdminForm.value.cityText,
      stateKey: this.providerAdminForm.value.stateText,
      zipCode: this.providerAdminForm.value.zipCodeText,
      phoneNumber: this.providerAdminForm.value.phoneNumberText,
      companyFEIN: this.providerAdminForm.value.companyFEIN,
    }
    this.setProvider2(searchData)
  }

  setProvider2(searchData) {
    if (!this.providerAdminForm.controls.searchText.value && !this.providerAdminForm.controls.providerIDText.value && !this.providerAdminForm.controls.cityText.value && !this.providerAdminForm.controls.addressText.value && !this.providerAdminForm.controls.stateText.value && !this.providerAdminForm.controls.zipCodeText.value && !this.providerAdminForm.controls.phoneNumberText.value && !this.providerAdminForm.controls.companyFEIN.value) {
      this.toast.warning('Search Criteria Cannot Be Empty', 'Warning');
      return false;
    }

    this.providerColor = "";      
    const dialogRef = this.dialog.open(ProviderAdminListComponent, {
      hasBackdrop: true,
      width: '50%',
      data: searchData,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!response)
        return false;

      const provider = response;
      const location = response; 
      this.parentInfo = response;

      this.providerAdminForm.controls.searchText.setValue(response.Name);
      this.disabledAddInsurance();
      this.getLocationList(provider.ProviderKey);
      this.isSelectProvider = true;
      this.providerData = provider;
      this.selectedProviderKey = provider.ProviderKey;
      sessionStorage.setItem('providerKey', provider.ProviderKey);
      sessionStorage.setItem('providerParentKey', response.ParentKey);

      sessionStorage.setItem('SPCompanyName', response.Name);
      sessionStorage.setItem('currentTimezone', response.timeZoneKey);
      sessionStorage.setItem('tierCanSetHours', response.tierCanSetHours);
      sessionStorage.setItem('dispatchableTier', response.dispatchableTier);
      sessionStorage.setItem('tierCanSetRates', response.tierCanSetRates);
      sessionStorage.setItem('providerId', response.ProviderId);
      if (response.physicalLatitude && response.physicalLongitude)
        sessionStorage.setItem('coordinates', JSON.stringify([response.physicalLatitude, response.physicalLongitude]))

      this.providerSet = true;
      this.locationKey = null;

      if (response.parentKey && response.parentKey !== '0') {
        location.parentProviderKey = provider.ProviderKey;
        location.parentProviderAccountKey = provider.ProviderAccountKey;
        location.isParent = false;
        this.providerDetails = location;
      } else {
        provider.isParent = true;
        this.providerDetails = provider;
      }
    });}

  alertDialog(alertMessage) {
    this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      width: '24%',
      data: {
        message: alertMessage
      }
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedIndex = tabChangeEvent.index;

    if (!this.isSelectProvider) {
      this.alertDialog('Please select provider');
    }
    this.disabledAddInsurance();
  }

  nextPage() {

    if (this.selectedIndex === 0) {
      this.providerManagementProfileDetailsComponent.updateCorporateDetails();
    } else if (this.selectedIndex === 1) {
      this.providerDetailsReq.updateProviderAccountDetails();
    } else if (this.selectedIndex === 4 && this.isDiscount) {
      this.selectedIndex = this.selectedIndex + 2;
    } else if (this.selectedIndex === 8) {
      this.utilityService.saveNextRoute();
      this.router.navigate([sessionStorage.getItem('nextPageLink')], { relativeTo: this.route });
    } else {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  nextTab(event) {
    if (event === true) {
      if (this.selectedIndex === 0) {
        this.getLocationList(this.selectedProviderKey, true);
      }
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  detailsChanged(event) {
    this.isDataChanged = event;
  }

  onSelectLocation(event) {
    this.locationKey = event.value;
    this.providerDetails = [];
    this.providerColor = "primary";
    const location = this.providerLocationList.find(obj => obj.providerKey === this.locationKey);
    location.parentProviderKey = this.providerData.ProviderKey;
    location.parentProviderAccountKey = this.providerData.ProviderAccountKey;
    sessionStorage.setItem('providerKey', location.providerKey);
    sessionStorage.setItem('providerParentKey', this.providerData.ProviderKey);

    location.isParent = false;
    this.providerDetails = location;
    this.returnToParentOption = true;
  }

  getLocationList(providerKey, setLocation = false) {
    this.isSelectProvider = true;
    this.providerDetails = [];
    this.providerAdminForm.controls.locationKey.reset();

    const data = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      location: true,
      parentKey: providerKey,
      searchText: null
    };

    this.loading = true;
    this.providerAdminService.getProviderAdminList(data).subscribe(res => {
      this.loading = false;
      this.providerLocationList = res.body;

      if (setLocation && this.locationKey) {
        this.providerAdminForm.controls.locationKey.setValue(this.locationKey);
        this.providerDetails = this.providerLocationList.find(obj => obj.providerKey === this.locationKey);
      }
    }, (error) => {
      console.log('error,', error);
      this.loading = false;
    });
  }

  returnToParent() {
    if (!this.providerAdminForm.controls.searchText.value) {
      this.toast.warning('Provider is required', 'Warning');
      return false;
    }

    this.returnToParentOption = false;
    const location = this.parentInfo; 
    const provider = this.parentInfo;

    this.providerAdminForm.controls.searchText.setValue(this.parentInfo.Name);
    this.disabledAddInsurance();
    this.getLocationList(this.parentInfo.ProviderKey);
    this.isSelectProvider = true;
    this.providerData = this.parentInfo;
    this.selectedProviderKey = this.parentInfo.ProviderKey;
    sessionStorage.setItem('providerKey', this.parentInfo.ProviderKey);
    sessionStorage.setItem('providerParentKey', this.parentInfo.ParentKey);
    sessionStorage.setItem('SPCompanyName', this.parentInfo.Name);
    sessionStorage.setItem('currentTimezone', this.parentInfo.timeZoneKey);
    sessionStorage.setItem('tierCanSetHours', this.parentInfo.tierCanSetHours);
    sessionStorage.setItem('dispatchableTier', this.parentInfo.dispatchableTier);
    sessionStorage.setItem('tierCanSetRates', this.parentInfo.tierCanSetRates);
    sessionStorage.setItem('providerId', this.parentInfo.providerId);
    if (this.parentInfo.physicalLatitude && this.parentInfo.physicalLongitude)
      sessionStorage.setItem('coordinates', JSON.stringify([this.parentInfo.physicalLatitude, this.parentInfo.physicalLongitude]))

    this.providerSet = true;
    this.locationKey = null;

    if (this.parentInfo.parentKey && this.parentInfo.ParentKey !== '0') {
      location.parentProviderKey = this.parentInfo.ProviderKey;
      location.parentProviderAccountKey = this.parentInfo.ProviderAccountKey;
      location.isParent = false;
      this.providerDetails = location;
    } else {
      provider.isParent = true;
      this.providerDetails = provider;
    }
  }

  locationDetailsList(){
    let dialogRef = this.dialog.open(LocationDetailsListComponent, {
      data: this.providerLocationList,
    })
  }

  disabledAddInsurance() {
    if (this.selectedIndex === 4) {
      const location = this.providerAdminForm.controls.locationKey.value;
      this.isAddButton = location ? true : false;
    }
  }

  addLocation(event) {
    const dialogRef = this.dialog.open(AddLocationDialogComponent, {
      hasBackdrop: false,
      width: '90%',
      height: '90%',
      position: {
        top: '4%',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      const arrReqFilterData = {
        parentKey: sessionStorage.getItem('providerKey'),
//        deleted: this.showDeletedLocations ? null : false
      };
//      this.getLocations(arrReqFilterData);
    });
  }

  getDiscountModuleSubscription() {
    const filter = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      moduleKey: 'Provider Discount',
      Deleted: false
    };
    this.commonService.getModuleSubscription(filter).subscribe(res => {
      if (res.success && res.body.length > 0) {
        const discountModuleSubscription = res.body[0];
        const currentDate = new Date().getTime();
        if (discountModuleSubscription.subscriptionStart < currentDate &&
          discountModuleSubscription.subscriptionEnd > currentDate) {
          this.isDiscount = false;
        }
      }
    });
  }
}

