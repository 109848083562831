<nb-card>
    <nb-card-header class="card-header">Discount Setup</nb-card-header>
    <nb-card-body>
        <div class="col-md-12 p-1 divtable">
            <table class="table table-bordered table-fixed">
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>% Discount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let discountsetup of DiscountSetup">
                        <td *ngIf="!discountsetup.editFlag">{{discountsetup.service}}</td>
                        <td *ngIf="discountsetup.editFlag">
                            <div style="width: 100px;" class="row no-gutters divWidth">
                                <mat-form-field class="col-md-5">
                                    <input matInput [value]="discountsetup.service">
                                </mat-form-field>
                            </div>
                        </td>

                        <td *ngIf="!discountsetup.editFlag">{{discountsetup.discount}}%</td>
                        <td *ngIf="discountsetup.editFlag">
                            <div class="row no-gutters divWidth">
                                <mat-form-field style="width:74px" class="col-md-5">
                                    <input matInput [value]="discountsetup.discount">
                                    <span matPrefix>%&nbsp;</span>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <button *ngIf="!discountsetup.editFlag" class="btn btn-default btnAnimation btn-sm floatR"
                                (click)="editDiscount(discountsetup)">
                                <i class="fas fa-pencil"></i>
                            </button>
                            <button *ngIf="discountsetup.editFlag" class="btn btn-default btn-sm btnAnimation">
                                <i class="far fa-check"></i>
                            </button>
                            <button *ngIf="discountsetup.editFlag" class="btn btn-default btn-sm btnAnimation"
                                (click)="editDiscount(discountsetup)">
                                <i class="fal fa-times"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="isAddRow">
                        <td>
                            <mat-form-field class="col-md-5">
                                <input matInput [value]="">
                            </mat-form-field>
                        </td>
                        <td>
                            <div class="row no-gutters divWidth">
                                <mat-form-field class="col-md-5">
                                    <input matInput [value]="0">
                                    <span matPrefix>%&nbsp;</span>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <button class="btn btn-default btn-sm btnAnimation">
                                <i class="far fa-check"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <button class="btn btn-primary btn-sm mt-2 btn-margin" (click)="addRow()">Add</button>
            <button class="btn btn-primary btn-sm mt-2">Close</button>
        </div>
    </nb-card-body>
</nb-card>