import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BubbleService } from 'src/app/services/bubble.service';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { ProtocolService } from 'src/app/services/protocol.service';

@Component({
  selector: 'app-add-protocols-dialog',
  templateUrl: './add-protocols-dialog.component.html',
  styleUrls: ['./add-protocols-dialog.component.scss']
})
export class AddProtocolsDialogComponent implements OnInit {

  userKey: string;
  licensedOwnerOfferingKey: string;
  customerProtocolKey: string;
  arrOperatingSchedules: any = [];
  arrCallerTypes: any = [];
  loading = false;
  addServiceProtocolsForm: UntypedFormGroup;
  arrProtocolDetails: any = [];
  dialogTitle = 'Add Service Protocols';
  isLicensedOwnerProtocol: any;
  licensedOwnerKey: string;

  constructor(public dialogRef: MatDialogRef<AddProtocolsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private bubbleService: BubbleService,
              private commonService: CommonService,
              private protocolService: ProtocolService,
              private formBuilder: UntypedFormBuilder,
              private toast: NbToastrService) { }

  ngOnInit() {

    this.isLicensedOwnerProtocol = this.data.isLicensedOwnerProtocol;
    this.dialogTitle = this.data.dialogTitle;
    this.userKey = sessionStorage.getItem('userKey');
    this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
    this.licensedOwnerOfferingKey = this.data.data.licensedOwnerOfferingKey;

    const isDisabled = (this.isLicensedOwnerProtocol) ? false : true;
    this.addServiceProtocolsForm = this.formBuilder.group({
      callerTypeKey: ['', [Validators.required]],
      operatingScheduleKey: ['', [Validators.required]],
      supported: ['', [Validators.required]],
      systemDefault: [{value: '', disabled: isDisabled}]
    });

    this.getOperatingSchedules();
    this.getCallerTypes();

    if (this.isLicensedOwnerProtocol && this.data.edit) {
      this.dialogTitle = 'Edit Licensed Owner Protocols';
      this.customerProtocolKey = (this.data.data.customerProtocolKey) ? this.data.data.customerProtocolKey : '';
      this.getLicensedOwnerProtocols();
    }
  }

  /***
  * @Desc: add/edit licensed owner protocols
  * @returns success result
  */
  addEditProtocols() {

    if (this.addServiceProtocolsForm.invalid) {
      this.addServiceProtocolsForm.markAllAsTouched();
      return false;
    }
    this.loading = true;

    const defaultData: any = {
      customerKey: '',
      licensedOwnerKey: this.licensedOwnerKey,
      licensedOwnerOfferingKey: this.licensedOwnerOfferingKey,
      insertedUserKey: this.userKey,
      updatedUserKey: this.userKey,
      deleted: 0,
    };

    const protocolFormData = this.addServiceProtocolsForm.value;
    protocolFormData.systemDefault = protocolFormData.systemDefault === '1' ? 'true' : 'false';
    protocolFormData.supported = protocolFormData.supported === '1' ? 'true' : 'false';

    if (this.data.edit) {
      defaultData.customerProtocolKey = this.customerProtocolKey;
      defaultData.customerKey = this.data.data.customerKey;
      delete defaultData.insertedUserKey;
      const data = {...protocolFormData, ...defaultData};

      this.protocolService.editCustomerProtocols(data).subscribe(response => {
        if (response.success) {
          this.toast.success('Licensed Owner Protocol Updated Successfully!', 'Success');
        } else {
          this.toast.warning(response.message[0], 'Error');
        }
        this.loading = false;
        this.dialogRef.close();
      }, (error) => {
        console.log('error', error);
        this.loading = false;
      });
    } else {
      defaultData.updatedUserKey = '';
      const paramsData = {
        licensedOwnerKey: this.licensedOwnerKey,
        licensedOwnerOfferingKey: this.licensedOwnerOfferingKey,
        callerTypeKey: protocolFormData.callerTypeKey,
        operatingScheduleKey: protocolFormData.operatingScheduleKey,
        systemDefault: protocolFormData.systemDefault,
        supported: protocolFormData.supported,
      };
      this.protocolService.getCustomerProtocolsById(paramsData).subscribe(responseDuplicate => {
        if (responseDuplicate.body.length > 0) {
          this.toast.warning('Record already exist with same details.', 'Error');
          this.loading = false;
        } else {
      const data = {...protocolFormData, ...defaultData};
      this.protocolService.addCustomerProtocols(data).subscribe(response => {
        if (response.success) {
          this.toast.success('License Provider Protocol Added Successfully!', 'Success');
        } else {
          this.toast.warning(response.message[0], 'Error');
        }
        this.loading = false;
        this.dialogRef.close();
          }, (error) => {
            console.log('error', error);
            this.loading = false;
          });
        }
      }, (error) => {
        console.log('error', error);
        this.loading = false;
      });
    }
  }

  /***
  * @Desc: Get Customer approved services protocols
  * @returns arrResponse
  */
  getLicensedOwnerProtocols() {

    this.loading = true;
    const paramsData = {
      licensedOwnerOfferingKey: this.licensedOwnerOfferingKey,
      customerProtocolKey: this.customerProtocolKey
    };
    this.protocolService.getProtocolList(paramsData).subscribe(response => {
      if (response.body.length > 0) {
        const protocolDetails = response.body[0];
        this.addServiceProtocolsForm.setValue({
          callerTypeKey: protocolDetails.callerTypeKey,
          operatingScheduleKey: protocolDetails.operatingScheduleKey,
          supported: (protocolDetails.supported === true) ? '1' : '0',
          systemDefault: protocolDetails.systemDefault ? '1' : '0'
        });
      }
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  /***
  * Desc: Get schedules
  * @response result array
  */
getOperatingSchedules() {

    this.loading = true;
    this.commonService.getOperatingSchedules().subscribe(response => {
      this.arrOperatingSchedules = response.body;
      this.loading = false;
    }, (error) => {
      console.log('error', error);
    });
  }

  /***
  * Desc: Get caller types
  * @response result array
  */
getCallerTypes() {
    this.loading = true;
    this.commonService.getCallerTypes().subscribe(response => {
      this.arrCallerTypes = response.body;
      this.loading = false;
    }, (error) => {
      console.log('error', error);
    });
  }

closeDialog() {
    this.dialogRef.close();
  }

handleError = (control: string, error: string) => {
    return this.addServiceProtocolsForm.controls[control].hasError(error);
  }
}

