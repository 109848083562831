import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditSecurityGroupListDataComponent } from 'src/app/dialogs/edit-security-group-list-data/edit-security-group-list-data.component';
import { SystemSecuruityMenuService } from 'src/app/services/system-securuity-menu.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableHeaderDirective, SortEvent } from 'src/app/share-module/directive/sortable.directive';

@Component({
  selector: 'ngx-security-groups-list',
  templateUrl: './security-groups-list.component.html',
  styleUrls: ['./security-groups-list.component.scss']
})
export class SecurityGroupsListComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  serviceGroupList: any;
  serviceGroupData: any;
  loading: boolean;
  searchString: string;

  constructor(
    private systemSecurityMenuService: SystemSecuruityMenuService,
    private utilityService: UtilityService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getServiceGroupList();
  }

  getServiceGroupList() {
    this.loading = true;
    this.systemSecurityMenuService.getSystemSecurityGroupList().subscribe(response => {
      this.serviceGroupData = response.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.serviceGroupData = this.utilityService.sortData(this.headers, column, direction, this.serviceGroupData);
  }


  onResponseSort({ column, direction }: SortEvent) {
    this.serviceGroupList = this.utilityService.sortData(this.headers, column, direction, this.serviceGroupList);
  }

  editServiceGroup(data: any, action: string) {
    const dialogRef = this.dialog.open(EditSecurityGroupListDataComponent, {
      width: '50%',
      position: {
        top: "6%"
      },
      data: { group: data, action: action },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'updated') {
        this.getServiceGroupList();
      }
    });
  }

  search(event) {
    this.searchString = event;
  }
}
