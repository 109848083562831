import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

/**
 * Node for tier item
 */
export class TierNode {
  children: TierNode[];
  item: string;
  parentNode: TierNode;
  customerKey: string;
}

/** Flat tier item node with expandable and level information */
export class TierFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  isCustomer: boolean;
  count: number;
  customerKey: string;
  customerName: string;
}

/**
 * The Json object for tier list data.
 */
const TREE_DATA = {
  // Test:null
};

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {
  public userName = new Subject<any>();
  dataChange = new BehaviorSubject<TierNode[]>([]);
  treeData: any;

  get data(): TierNode[] { return this.dataChange.value; }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TierNode` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TierNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): TierNode[] {
    return Object.keys(obj).reduce<TierNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TierNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to tier list */
  insertItem(parent: TierNode, name: string) {
      parent.children.push({item: name} as TierNode);
      this.dataChange.next(this.data);
  }

  updateItem(node: TierNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }

  setUserName(userName: string) {
    this.userName.next(userName);
  }

  setTreeData(data: any) {
    this.treeData = data;
  }
}
