import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BillingService {

  serviceFeeCharging: Observable<any>;
  data: any = {};
  serviceFeeBilling: Observable<any>;
  feeFrequencyFilter: Observable<any>;

  constructor(private http: HttpClient) { }

  getCustomerEventFeeStructures(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customereventfeestructures/get`, filter);
  }
  updateCustomerEventFeeStructures(filter): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customereventfeestructures`, filter);
  }

  addCustomerEventFeeStructures(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customereventfeestructures`, filter);
  }

  getCustomerBilling(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customers/get`, filter);
  }
  getAllTiers(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customereventfeestructures/get`, filter);
  }

  getPreAccountingCalculator(customerKey): Observable<any> {
    return this.http.get(`${environment.baseUrl}getPreAccountingCalculators/${customerKey}`);
  }

  getOptimizerDetails(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customers/get`, filter);
  }

  updateFormCustomerBilling(filter): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customers`, filter);
  }

  updateAllTiers(filter): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customereventfeestructures`, filter);
  }
  updateBillingOptimizer(filter): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customers`, filter);
  }

  // get customer Additional Fee Structures
  getCustAdditionalFeeStructures(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures/get`, filter);
  }

  // add customer Additional Fee Structures
  addCustAdditionalFeeStructures(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures`, filter);
  }

  // update customer Additional Fee Structures
  updateCustAdditionalFeeStructures(filter): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customeradditionalfeestructures`, filter);
  }

  getServiceFeeChargingFrequency(filter: any = {}): Observable<any> {
    if (!this.serviceFeeCharging) {
      if (filter.deleted === undefined) {
        filter.deleted = false;
      }
      this.serviceFeeCharging = this.http.post(`${environment.apiUrl}keyvaluepairs/servicefeechargingfrequencies/get`, this.data).pipe(
        map(res => res),
        publishReplay(1),
        refCount()
      );
    }
    return this.serviceFeeCharging;
  }

  getServiceFeeBillingFrequency(filter: any = {}): Observable<any> {
    if (!this.serviceFeeBilling) {
      if (filter.deleted === undefined) {
        filter.deleted = false;
      }
      return this.http.post(`${environment.apiUrl}keyvaluepairs/servicefeebillingfrequencies/get`, this.data).pipe(
        map(res => res),
        publishReplay(1),
        refCount()
      );
    }
    return this.serviceFeeBilling;
  }

  getMMSPricing(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures/get`, filter);
  }
  addMMSPricing(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures`, filter);
  }
  updateMMSPricing(filter): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customeradditionalfeestructures`, filter);
  }

  getConsultingFee(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures/get`, filter);
  }

  updateConsultingBilling(filter): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/customeradditionalfeestructures`, filter);
  }

  addNewConsultingBilling(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures`, filter);
  }

  getUserSecurityPolicies(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.baseUrl}getUserSecurityPolicies`, filter);
  }

  getServiceFeeFrequency(filter: any = {}): Observable<any> {
    if (!this.feeFrequencyFilter) {
      if (filter.deleted === undefined) {
        filter.deleted = false;
      }
      return this.http.post(`${environment.apiUrl}keyvaluepairs/feefrequencies/get`, this.data).pipe(
        map(res => res),
        publishReplay(1),
        refCount()
      );
    }
    return this.feeFrequencyFilter;
  }

  getCustomerChanged(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures/get`, filter);
  }

  getLicensedOwnerOffering(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerofferings/get`, filter);
  }

  getInvoices(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}keyvaluepairs/invoicetypes/get`, filter);
  }

  // Add record to pre accounting calculators
  addPreAccountingCalculatorDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/preaccountingcalculators`, data);
  }

  // Get record to pre accounting calculators
  getPreAccountingCalculatorDetails(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/preaccountingcalculators/get`, filter);
  }

  // Update record to pre accounting calculators
  updatePreAccountingCalculators(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/preaccountingcalculators`, data);
  }

  customerAdditionalFeeStructuresBatch(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customeradditionalfeestructures/batch`, filter);
  }

  customerEventFeeStructuresBatch(filter): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/customereventfeestructures/batch`, filter);
  }

  preAccountingCalculatorDetailsBatch(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/preaccountingcalculators/batch`, data);
  }

}
