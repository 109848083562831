<mat-grid-list cols="1" rowHeight="fit">
    <mat-grid-tile>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-menu">
            <mat-nested-tree-node *matTreeNodeDef="let node" matTreeNodeToggle style="overflow: visible">
                <li class="example-tree-container-child" [style.borderColor]="node.bordercolor">
                    <div class="mat-tree-node" (mousedown)="onSetColor(node)"
                        [style.borderLeft]="'14px solid' + node.color">
                        <button mat-button [disableRipple]="true" class="mat-tree-child-last"
                            (click)="navigateToPage(node)">{{node.name}}</button>
                    </div>
                </li>
            </mat-nested-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li class="example-tree-container" [style.borderColor]="node.bordercolor">
                    <div class="mat-tree-node menu-item">
                        <button mat-button matTreeNodeToggle [disableRipple]="true"
                            [attr.aria-label]="'toggle ' + node.name" class="mat-tree-button"
                            [style.borderLeft]="'14px solid' + node.color" (mousedown)="onSetColor(node)"
                            (click)="navigateToPage(node)">
                            {{node.name}}
                            <mat-icon class="mat-icon-rtl-mirror float-right mt-1">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                    </div>
                    <ul class="example-tree-nested-node" [style.borderColor]="node.bordercolor">
                        <div *ngIf="treeControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </div>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </mat-grid-tile>
</mat-grid-list>