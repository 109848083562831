<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h5 class="modal-title">User Invite</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body px-1">
    <mat-tab-group [selectedIndex]='selectedIndex' (selectedTabChange)='tabChanged($event)'>
        <mat-tab label="Add Users to receive an Invitation">
            <app-add-user-into-invite-process [securityGroups]="securityGroups" [departmentList]="departmentList"
                [userInvitationList]="userInvitesList" (listChanged)="userListChanged($event)"
                [associationKey]="providerKey" [userTypeKey]="userTypeKey" [isCustomer]="true">
            </app-add-user-into-invite-process>
        </mat-tab>
        <mat-tab label="Upload File for New User Invites">
            <app-upload-file-modeler [securityGroups]="securityGroups" [departmentList]="departmentList"
                [userInvitationList]="userInvitesList" (listChanged)="userListChanged($event)"
                [associationKey]="providerKey" [userTypeKey]="userTypeKey" [isProvider]="true" [isCustomer]="true">
            </app-upload-file-modeler>
        </mat-tab>
        <mat-tab label="View/Edit Email Message">
            <app-communication-templates (listChanged)="userListChanged($event)" [templateKey]="templateKey"
                [associationKey]="providerKey" [isProvider]="true" [isCustomer]="true">
            </app-communication-templates>
        </mat-tab>
        <mat-tab label="Invitation History">
            <app-user-invite-history [userInvitationList]="userInviteSentList"
                (userInviteList)="selectedInvitesChanged($event)" (listChanged)="userListChanged($event)"
                (usersSelected)="selecionChanged($event)" [isCustomer]="true">
            </app-user-invite-history>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col text-right">
            <button *ngIf="selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 3"
                class="btn btn-sm btn-success mr-2 " (click)="viewTemplate()">View Templates</button>
            <button *ngIf="selectedIndex === 0 || selectedIndex === 1" class="btn btn-sm btn-success mr-2 "
                (click)="sendInvitations()">Send Invite(s)</button>
            <button *ngIf="selectedIndex === 2" class="btn btn-sm btn-success mr-2 "
                (click)="sendInviteWithEditedTemplate()">Send Invite(s) with Edited Template</button>
            <button *ngIf="selectedIndex === 3" class="btn btn-sm btn-success mr-2" (click)="reSendInvitations()">Resend
                Invite(s)</button>
        </div>
    </div>
</div>