<div class="modal-header">
    <h6 class="modal-title">Edit User</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <mat-tab-group [selectedIndex]='selectedIndex' (selectedTabChange)='tabChange($event)'>
        <mat-tab label="User Detail">
            <app-edit-provider-users [data]="userData" (saveCompleted)='saveCompleted($event)'></app-edit-provider-users>
        </mat-tab>
        <!-- <mat-tab label="Skill & Expertise">
            <app-skills-level-expertise *ngIf="selectedIndex == 1" [userDetails]="userData"></app-skills-level-expertise>
        </mat-tab> -->
        <mat-tab label="Security Groups">
            <app-security-groups *ngIf="selectedIndex == 1" [isCustomerUser]="true" [isProviderUser]="true" [userDetails]="userData"></app-security-groups>
        </mat-tab>
        <mat-tab label="Availability">
            <app-provider-hrs-of-operation *ngIf="selectedIndex == 2" [userDetails]="userData"></app-provider-hrs-of-operation>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="modal-footer">
    <button *ngIf="selectedIndex == 0" type="button" class="btn btn-primary btn-sm mt-3"
        (click)="next(false)">{{saveButtonText}}</button>
    <button *ngIf="selectedIndex == 0" type="button" class="btn btn-primary btn-sm mt-3" (click)="next(true)">Save &
        Next</button>
    <button *ngIf="selectedIndex > 0" type="button" class="btn btn-primary btn-sm mt-3"
        (click)="previous()">Previous</button>
    <button *ngIf="selectedIndex < 2" type="button" class="btn btn-primary btn-sm mt-3"
        (click)="next(true)">Next</button>
</div>