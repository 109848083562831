import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { ProviderAccountService } from 'src/app/services/provider-account.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableDirective, SortEvent } from 'src/app/shared/directive/sortable.directiveReg';
import { BubbleService } from 'src/app/services/bubble-service.service';
import { ViewLineItemExclusionListDialogComponent } from '../../view-line-item-exclusion-list-dialog/view-line-item-exclusion-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewServiceExclusionListComponent } from '../../view-service-exclusion-list/view-service-exclusion-list.component';

@Component({
  selector: 'ngx-vendor-discounts',
  templateUrl: './vendor-discounts.component.html',
  styleUrls: ['./vendor-discounts.component.scss']
})
export class VendorDiscountsComponent implements OnInit {
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() providerAccount;
  @Input() isParent;

  loading: boolean = false;
  discountData: any = [];
  accountName: string;
  isNoData: boolean = false;
  searchText: string;
  providerAccountKey: any;

  constructor(
    private accountService: ProviderAccountService,
    private utilityService: UtilityService,
    private bubbleService: BubbleService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.accountName = this.providerAccount.accountName;
    this.providerAccountKey = this.providerAccount.providerAccountKey;
    if (!this.isParent) {
      let data = {
        'accountName': this.accountName,
        'licensedOwnerKey': this.providerAccount.licensedOwnerKey,
        'providerKey': sessionStorage.getItem('providerKey'),
        'deleted': false
      };
      this.loading = true;
      this.bubbleService.getProviderAccountKey(data).subscribe(result => {
        this.loading = false;
        this.providerAccountKey = result.body[0].providerAccountKey;
        this.getServiceProviderDiscountProgram();
      }, (error) => {
        this.loading = false;
      });
    } else {
      this.getServiceProviderDiscountProgram();
    }
  }

  getServiceProviderDiscountProgram() {
    let arrReqData = {
      providerAccountKey: this.providerAccountKey,
      licensedOwnerKey: this.providerAccount.licensedOwnerKey,
      providerKey: sessionStorage.getItem('providerKey')
    };

    this.loading = true;
    this.accountService.getServiceProviderDiscountProgram(arrReqData).subscribe((result: any) => {
      this.discountData = result;
      this.onSort({ column: 'Description', direction: 'asc' });
      if (this.discountData.length === 0) {
        this.isNoData = true;
      } else if (this.discountData[0].hasSubscriptions === 0) {
        this.isNoData = true;
      }
      this.loading = false;
    }, (error) => {
      console.log('error ' + error);
      this.loading = false;
    });
  }

  onInput(val: string) {
    this.search.emit(this.searchText);
  }

  onSort({ column, direction }: SortEvent) {
    this.discountData = this.utilityService.sortData(this.headers, column, direction, this.discountData);
  }

  openLineItemExclusionList() {
    this.dialog.open(ViewLineItemExclusionListDialogComponent, {
      maxHeight: '80%',
      width: '60%',
      hasBackdrop: false,
      position: {
        top: '5%',
      },
    });
  }

  openServiceExclusionList() {
    this.dialog.open(ViewServiceExclusionListComponent, {
      maxHeight: '80%',
      width: '60%',
      hasBackdrop: false,
      position: {
        top: '5%',
      },
      data: this.providerAccount
    });
  }
}
