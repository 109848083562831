import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  getHeaderArray(csvRecordsArr: any) {
    const headers = csvRecordsArr[0].split(',');
    const headerArray = [];
    headers.forEach((item) => headerArray.push(item));
    return headerArray;
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  fileReset(csvReader) {
    csvReader.nativeElement.value = '';
  }
}
