import { Component, OnInit, Input, HostBinding, ViewEncapsulation,Renderer2, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';

// import { DndDropEvent } from 'ngx-drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { BubbleService } from 'src/app/services/bubble-service.service';
import { NbToastrService, NbComponentStatus } from '@nebular/theme';
import { DynamoDBService } from 'src/app/services/dynamo-db.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Component({
  selector: 'bubble-tree-ui',
  templateUrl: './bubble-tree-ui.component.html',
  styleUrls: ['./bubble-tree-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BubbleTreeUiComponent implements OnInit {

  // static members
  public static treeRecursiveOperationException = false;

  // Input data to manipulate bubble tree data and features
  @Input() levelData: any; // Input data
  @Input() level: number; // Level of bubble tree
  @Input() addNode: boolean; // add remove add button
  @Input() editNode: boolean; // add remove edit button
  @Input() delNode: boolean; // add remove delete button
  @Input() isActiveNode: boolean; // add remove active inactive checkbox
  @Input() disableDragDrop: boolean; // enable disable drag drop feature
  @Input() lastNode: number; // Number of levels to show (If set to -1 tree can go to n levels)
  // Number of level from which nodes can be selected (If -1 then no node will have select option)
  @Input() isSelected: number;
  @Input() providerAccount: any; // If bubble called from locationp
  @Input() parentRecord: boolean; // If record are of parent profile

  // Instance members
  networkJson: any;
  levelKey: any = null;
  initialTree: any;
  // Tree drag and drop config
  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    'data': null,
    'effectAllowed': 'all',
    'disable': false,
    'handle': false,
  };

  defaultLocationKey: string;
  providerKey: string;
  serviceData: any;
  licensedOwnerKey: any;
  providerAccountKey: any;
  addRemoveProviderOfferings = []

  constructor(
    public dialog: MatDialog,
    private bubbleService: BubbleService,
    private toastrService: NbToastrService,
    private dynamoDBService: DynamoDBService
  ) { }

  ngOnInit() {
    this.setExpandedTreeLevel();

    // ? Get Geohash Provider
    if (!sessionStorage.getItem('geoHashProvider')) this.dynamoDBService.DynamoProviderDetails();

    this.draggable.disable = this.disableDragDrop;
    this.providerKey = sessionStorage.getItem('providerKey');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');

    if (this.providerAccount != null || this.providerAccount !== undefined) {
      this.providerKey = this.providerAccount.providerKey;
      // this.providerAccountKey = this.providerAccount.providerAccountKey;
      this.providerAccountKey = null;
    } else if (this.defaultLocationKey != null && this.defaultLocationKey !== undefined) {
      this.providerKey = this.defaultLocationKey;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
      // this.providerAccountKey = sessionStorage.getItem('providerAccountKey');
      this.providerAccountKey = null;
    }
  }

  showToast(status: NbComponentStatus, message) {
    this.toastrService.show(status, message, { status });
  }

  // method to set level of tree
  setExpandedTreeLevel() {
    const expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    this.level = this.level + 1;
    this.levelKey = expandArr[this.level - 1];
  }

  showNetwork(jsonObj) {
    /* Restrict method execution (Expanding tree)
    if node is last node or node is not active or node is having no childrens */
    if (
      this.level === this.lastNode
      || !(jsonObj.children != null && jsonObj.children[0] !== undefined)) {
      return false;
    }

    let expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    sessionStorage.setItem('parentSelected', jsonObj.name);
    if (this.levelKey == null) {
      this.levelKey = jsonObj.key;
      expandArr.push(jsonObj.key);
    } else if (this.levelKey === jsonObj.key) {
      this.levelKey = null;
      expandArr = expandArr.slice(0, this.level - 1);
    } else {
      this.levelKey = jsonObj.key;
      if (this.level > expandArr.length) {
        expandArr.push(jsonObj.key);
      } else {
        expandArr = expandArr.slice(0, this.level - 1);
        expandArr.push(jsonObj.key);
      }
    }
    sessionStorage.setItem('expandedLayer', JSON.stringify(expandArr));
  }

  // method to update tree active inactive status
  activateInactivate(activeState, OperationData) {
    // this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
    this.licensedOwnerKey = null;
    // if bubble rendered called from account / location
    if (this.providerAccount != null || this.providerAccount !== undefined) {
      // this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
      this.licensedOwnerKey = null;
    }
    const isDeleted = !activeState ? 1 : 0;
    if (this.parentRecord) {
      this.addProviderAccountDataIntoLocation(isDeleted, OperationData);
    } else {
      const arrReqData = {
        isDeleted: isDeleted,
        networkOwnerOfferingKey: OperationData.key,
        providerKey: this.providerKey,
        providerServiceOfferingKey: OperationData.providerServiceOfferingKey,
      };

      this.addRemoveOfferings(OperationData.name, isDeleted);

      //Update provider into Dynamo Db
      this.updateGeoHash();

      this.bubbleService.setBubbleLoader(true);
      this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
        this.getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData);
        //Insert provider into Dynamo Db
        // this.dynamoDBService.geoHashInsert(this.providerKey);
      });

      // this.networkJson = JSON.parse(this.bubbleService.getserviceNetworkData());
      // this.functionActiveInactiveNode(this.networkJson, OperationData, isDeleted);
      // this.setMainTreeJson();
    }
  }

  // recursive method to update tree active inactive status
  functionActiveInactiveNode(networkJson: any, nodeToUpdate: AnimationKeyframesSequenceMetadata, activeFlag: number) {
    const nodeToUpdateJson = JSON.stringify(nodeToUpdate, null);

    for (const i in networkJson) {
      if (Object.prototype.hasOwnProperty.call(networkJson, i)) {
        const tempNode = networkJson[i];
        const tempNodeJson = JSON.stringify(tempNode, null);
        // check if interacted node mathced with any node from nodes in iteration context
        if (tempNodeJson === nodeToUpdateJson) {
          tempNode.deleted = activeFlag;
          if (tempNode.children) {
            // method to set all children active or inactive
            this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
          }
          break;
        }

        // check if child nodes exist.
        // If exist then recursively match interacted node with any node from child nodes in recursive context
        if (tempNode.children) {
          this.functionActiveInactiveNode(tempNode.children, nodeToUpdate, activeFlag);
        }
      }
    }
  }

  // method to set all children active or inactive
  makeAllBelowActiveInactive(childNode, activeFlag) {
    for (const i in childNode) {
      if (Object.prototype.hasOwnProperty.call(childNode, i)) {
        const tempNode = childNode[i];
        tempNode.deleted = activeFlag;
        if (tempNode.children) {
          this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
        }
      }
    }
  }

  setMainTreeJson() {
    this.bubbleService.setserviceNetworkData(JSON.stringify(this.networkJson));
    this.bubbleService.setBubbleTreeData();
  }

  // method to get selected nodes
  selectNode(event, OperationData) {
    const OperationArr = {
      ServiceName: sessionStorage.getItem('parentSelected'),
      SubServiceName: OperationData.name,
    };
    const OperationDataJson = JSON.stringify(OperationArr);
    let index = 0;
    let selectArr;
    selectArr = JSON.parse(sessionStorage.getItem('serviceETA')) === null
      ? []
      : JSON.parse(sessionStorage.getItem('serviceETA'));

    if (event.checked) {
      selectArr.push(OperationArr);
    } else {
      for (const i in selectArr) {
        if (Object.prototype.hasOwnProperty.call(selectArr, i)) {
          const tempNode = selectArr[i];
          const tempNodeJson = JSON.stringify(tempNode, null);

          if (tempNodeJson === OperationDataJson) {
            selectArr.splice(index, 1);
            break;
          }
          index++;
        }
      }
    }
    sessionStorage.setItem('serviceETA', JSON.stringify(selectArr));
  }

  addProviderAccountDataIntoLocation(isDeleted, OperationData) {
    // Condition to check if user is adding or editing location
    let parentKey = sessionStorage.getItem('providerKey');
    if (sessionStorage.getItem('providerParentKey') && sessionStorage.getItem('providerParentKey') !== 'null'
      && sessionStorage.getItem('providerParentKey') !== '0') {
      parentKey = sessionStorage.getItem('providerParentKey');
    }

    const data = {
      accountName: this.providerAccount.accountName,
      licensedOwnerKey: this.licensedOwnerKey,
      providerKey: parentKey,
      deleted: false,
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderAccountKey(data).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      const setServicesdata = {
        locationKey: this.providerKey,
        providerKey: parentKey,
        licensedOwnerKey: this.licensedOwnerKey,
        providerAccountKey: null,
        locationAccountKey: null,
      };

      this.bubbleService.setBubbleLoader(true);
      this.bubbleService.setUpServiceAndRates(setServicesdata).subscribe(() => {
        this.bubbleService.setBubbleLoader(false);
        this.getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData, true);
      }, (error) => {
        this.bubbleService.setBubbleLoader(false);
      });
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  updateSelectedRecord(isDeleted, OperationData) {
    const data = {
      licensedOwnerKey: this.licensedOwnerKey,
      providerKey: this.providerKey,
      licensedOwnerOfferingKey: OperationData.licensedOwnerOfferingKey,
      providerAccountKey: this.providerAccountKey,
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderService(data).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      const arrReqData = {
        isDeleted: isDeleted,
        networkOwnerOfferingKey: OperationData.key,
        providerKey: this.providerKey,
        providerServiceOfferingKey: result.body[0].providerServiceOfferingKey,
      };

      this.bubbleService.setBubbleLoader(true);
      this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(() => {
        this.getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData);
      });

      this.networkJson = JSON.parse(this.bubbleService.getserviceNetworkData());
      this.functionActiveInactiveNode(this.networkJson, OperationData, isDeleted);
      this.setMainTreeJson();
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData, isUpdate = false) {
    const data = {
      providerKey: this.providerKey,
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderServiceOfferingsWithoutlicensedOwnerKey(data).subscribe(result => {
      this.networkJson = result;
      this.setMainTreeJson();
      this.bubbleService.setBubbleLoader(false);

      if (this.bubbleService.getserviceNetworkData() === null ||
        this.bubbleService.getserviceNetworkData() === undefined) {
        this.bubbleService.setserviceNetworkData(JSON.stringify(this.networkJson));
      }

      if (isUpdate) {
        this.updateSelectedRecord(isDeleted, OperationData);
      }
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  addRemoveOfferings(offering, deleted) {
    this.addRemoveProviderOfferings = [];
    const selectedOffering = this.levelData.filter(x => x.deleted === 0).map((el) => el.name)
    this.addRemoveProviderOfferings = deleted ? [...selectedOffering] : [...selectedOffering, offering];
    if (deleted) {
      const index = this.addRemoveProviderOfferings.indexOf(offering);
      this.addRemoveProviderOfferings.splice(index, 1)
    }
  }

  // ? Update GeoHash Provider
  updateGeoHash() {

    const providerUpdateReq = JSON.parse(sessionStorage.getItem('geoHashProvider'));
    if (!providerUpdateReq) return;
    providerUpdateReq.providerOfferings = JSON.stringify(this.addRemoveProviderOfferings);
    this.dynamoDBService.geoHashUpdate([providerUpdateReq]);

  }
}
