import { Component, OnInit, Input, OnChanges, HostBinding, ViewEncapsulation,Renderer2, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';
import { AddEditNetworkDialogComponent } from 'src/app/dialogs/add-edit-network-dialog/add-edit-network-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { BubbleService } from 'src/app/services/bubble.service';
import { CommonService } from 'src/app/services/common.service';
import { NbToastrService, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { RatesService } from 'src/app/services/rates.service';
import { ProtocolListDialogComponent } from 'src/app/dialogs/protocol-list-dialog/protocol-list-dialog.component';
import { AddProtocolsDialogComponent } from 'src/app/dialogs/add-protocols-dialog/add-protocols-dialog.component';
import { ProtocolService } from 'src/app/services/protocol.service';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { ApprovedProtocolsListDialogComponent } from 'src/app/dialogs/approved-protocols-list-dialog/approved-protocols-list-dialog.component';
import { AddLimitsComponent } from 'src/app/dialogs/add-limits/add-limits.component';
import { UsersService } from 'src/app/services/users.service';
import { ThresholdListDialogComponent } from 'src/app/dialogs/threshold-list-dialog/threshold-list-dialog.component';
import { AddOfferingQuestionsModalComponent } from 'src/app/pages/standard-jobs-admin/offering-questions/add-offering-questions-modal/add-offering-questions-modal.component';
import { OfferingQuestionsService } from 'src/app/services/offering-questions.service';
import { DynamicQuestionsService } from 'src/app/services/dynamic-questions.service';
import { Subscription } from 'rxjs';
import { PanZoomConfig, PanZoomAPI, PanZoomModel, PanZoomConfigOptions } from 'ngx-panzoom';
import { contentItems } from  '../../shared/bubble-tree-ui/contentItems';
import * as utils from '../../shared/bubble-tree-ui/utils';
interface Point {
  x: number;
  y: number;
}

@Component({
  selector: 'bubble-tree-ui',
  templateUrl: './bubble-tree-ui.component.html',
  styleUrls: ['./bubble-tree-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleTreeUiComponent implements OnInit, OnChanges {

  // static members
  public static treeRecursiveOperationException = false;

  // Input data to manipulate bubble tree data and features
  @Input() levelData: any; // Input data
  @Input() level: number; // Level of bubble tree
  @Input() addNode: boolean; // add remove add button
  @Input() editNode: boolean; // add remove edit button
  @Input() delNode: boolean; // add remove delete button
  @Input() isActiveNode: boolean; // add remove active inactive checkbox
  @Input() disableDragDrop: boolean; // enable disable drag drop feature
  @Input() lastNode: number; // Number of levels to show (If set to -1 tree can go to n levels)
  @Input() isSelected: number; // Number of level from which nodes can be selected (If -1 then no node will have select option)
  @Input() isAllParentActive: number;
  @Input() isFromRateGroup: boolean; // flag to check if the ui for rate type  group
  @Input() isCapability = false; // flag to check if the ui for capability

  @Input() providerAccount: any;
  @Input() parentRecord: boolean;
  @Input() showSelectDeselect = true;
  @Input() showProtocol = false;
  @Input() isLicensedOwnerProtocol = false;
  @Input() isServiceProtocol = false;
  @Input() isApprovderProtocol = false;
  @Input() isParentActive = false;
  @Input() callerType = '';
  @Input() showThreshold = false;
  @Input() isServiceThreshold = false;
  @Input() userDetails: any;
  @Input() isCustomerServiceProtocol = false;
  @Input() offeringQuestions = false;
  // Instance members
  networkJson: any;
  levelKey: any = null;
  initialTree: any;
  loading: boolean;
  providerKey: string;
  serviceData: any;
  licensedOwnerKey: any;
  providerAccountKey: any;
  defaultLocationKey: string;
  // Tree drag and drop config
  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    'data': null,
    'effectAllowed': "all",
    'disable': false,
    'handle': false
  };

  userIsLicensedOwner: boolean;
  parentKeys: any = [];
  currentKey: string;

  constructor(
    public dialog: MatDialog,
    private bubbleService: BubbleService,
    private commonService: CommonService,
    private toastrService: NbToastrService,
    private rateService: RatesService,
    private protocolService: ProtocolService,
    private dialogService: NbDialogService,
    private toast: NbToastrService,
    private usersService: UsersService,
    private offeringQuestionsService: OfferingQuestionsService,
    private dynamicQuestionsService: DynamicQuestionsService,
    private el: ElementRef,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef
  ) { }


  ngOnInit() {


    this.userIsLicensedOwner = (sessionStorage.getItem('userTypeKey') === '9c4ef5b4-cadb-400b-adf4-05b33798f1fd') ? true : false;

    this.setExpandedTreeLevel();
    this.draggable.disable = this.disableDragDrop;
    this.providerKey = sessionStorage.getItem('providerKey');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');

    if (this.providerAccount != null || this.providerAccount != undefined) {
      this.providerKey = this.providerAccount.ProviderKey;
      this.providerAccountKey = this.providerAccount.ProviderAccountKey;
    } else if (this.defaultLocationKey != null || this.defaultLocationKey != undefined) {
      this.providerKey = this.defaultLocationKey;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
      this.providerAccountKey = sessionStorage.getItem('providerAccountKey');
    }
  }



  ngOnChanges() {
    if (this.isServiceProtocol && this.levelData !== 'undefined' && this.levelData !== undefined) {
      this.levelData.map((data) => {
        if (data.children && data.children !== undefined) {
          const isDefaultProtocol = data.children.find(d => d.isDefaultProtocol === 1);
          data.isChildProtocol = false;
          if (isDefaultProtocol) {
            data.isChildProtocol = true;
          }
        }
      });
    }

    if (this.isServiceThreshold) {
      const offerings = [];
      this.levelData.map((data) => {
        if (data.isProtocol || data.isChildProtocol) {
          offerings.push(data);
        }
      });
      this.levelData = offerings;
    }
  }



  showToast(status: NbComponentStatus, message) {
    this.toastrService.show(status, message, { status });
  }


  // method to set level of tree
  setExpandedTreeLevel() {
    const expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    this.level = this.level + 1;
    this.levelKey = expandArr[this.level - 1];
  }

  /**
  * Method as 'dndDrop' event callback
  *
  * @param { event: DndDropEvent } DndDropEvent object
  * @param { dropZoneNode: any } A custom object denoting a node
  */

  onDrop(event: DndDropEvent, dropZoneNode: any) {
    // Default tree 
    this.networkJson = JSON.parse(localStorage.getItem('serviceNetwork'));
    this.draggable.data = JSON.parse(JSON.stringify(this.networkJson));
    this.initialTree = JSON.parse(localStorage.getItem('serviceNetwork'));

    if (!dropZoneNode) { return false; }
    const draggedNode = event.data;
    const destinationNode = dropZoneNode;

    if (destinationNode.level > draggedNode.level || destinationNode.level >= 4) {
      const message = 'Cannot move ' + draggedNode.name + ' service to lower level';
      this.showToast('warning', message);
      return;
    }

    const draggedNodeJson = JSON.stringify(draggedNode, null);
    const destinationNodeJson = JSON.stringify(destinationNode, null);

    // Avoid adding dragged node to leaf nodes and avoid dropping dragged node on itself
    if (destinationNode.children && draggedNodeJson !== destinationNodeJson) {
      // Avoid dropping node in same parent node 
      let isSameParent = false;

      if (dropZoneNode.children) {
        isSameParent = destinationNode.children.some((node) => {
          return JSON.stringify(node) === draggedNodeJson;
        });
      }

      if (isSameParent === true) { return false; }

      // Remove dragged node from tree
      BubbleTreeUiComponent.manipulateNestedTree(this.networkJson, draggedNode, destinationNode, 'remove');

      // Add dragged node as children to destination node
      BubbleTreeUiComponent.manipulateNestedTree(this.networkJson, destinationNode, draggedNode, 'add');

      // Reset tree to initial state if any recursive operation failed
      if (BubbleTreeUiComponent.treeRecursiveOperationException === true) {
        BubbleTreeUiComponent.treeRecursiveOperationException = false;
        this.setMainTreeJson();
      } else {
        this.setMainTreeJson();
      }

      const arrReqData = {
        inUpdatedUserKey: sessionStorage.getItem('userKey'),
        inNetworkOwnerOfferingKey: draggedNode.key,
        inParentKey: destinationNode.key
      };

      this.loading = true;
      this.commonService.updateTierServiceOfferring(arrReqData).subscribe(result => {
        this.loading = false;
      });

    }
  }

  showNetwork(jsonObj) {

    if (jsonObj.level === 1 && jsonObj.systemDefault === 1) {
      this.isParentActive = true;
    }
    if (jsonObj.level === 1 && jsonObj.systemDefault === 0) {
      this.isParentActive = false;
    }

    let expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    sessionStorage.setItem('parentSelected', jsonObj.name);
    if (this.levelKey == null) {
      this.levelKey = jsonObj.key;
      expandArr.push(jsonObj.key);
    } else if (this.levelKey === jsonObj.key) {
      this.levelKey = null;
      expandArr = expandArr.slice(0, this.level - 1);
    } else {
      this.levelKey = jsonObj.key;
      if (this.level > expandArr.length) {
        expandArr.push(jsonObj.key);
      } else {
        expandArr = expandArr.slice(0, this.level - 1);
        expandArr.push(jsonObj.key);
      }
    }
    sessionStorage.setItem('expandedLayer', JSON.stringify(expandArr));
  }

  addEditNetwork(OperationData, editFlag) {
    const dialogref = this.dialog.open(AddEditNetworkDialogComponent, {
      hasBackdrop: true,
      width: '60%',
      position: {
        top: '5%'
      },
      data: {
        OperationData,
        editFlag,
        level: this.level
      }
    });

    dialogref.componentInstance.afterSave.subscribe(() => {
      let expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));

      this.levelKey = OperationData.key;
      if (this.level > expandArr.length) {
        expandArr.push(OperationData.key);
      } else {
        expandArr = expandArr.slice(0, this.level - 1);
        expandArr.push(OperationData.key);
      }
      sessionStorage.setItem('expandedLayer', JSON.stringify(expandArr));
    });
  }

  // method to delete tree node
  deleteNode(removeNode) {
    this.networkJson = JSON.parse(localStorage.getItem('serviceNetwork'));
    BubbleTreeUiComponent.manipulateNestedTree(this.networkJson, removeNode, 0, 'remove');
    this.setMainTreeJson();
    this.loading = true;
    const arrReqData = {
      inUpdatedUserKey: sessionStorage.getItem('userKey'),
      inNetworkOwnerOfferingKey: removeNode.key,
      inDeleted: 1
    };

    this.commonService.updateTierServiceOfferring(arrReqData).subscribe(result => {
      this.loading = false;
    });
  }

  // method to update tree active inactive status
  activateInactivate(activeState, OperationData) {
    if (this.isCapability) {
      this.levelData.map(obj => {
        if (obj.name === OperationData.name) {
          obj.deleted = !obj.deleted;
        }
      });
      localStorage.removeItem('capabilityNetwork');
      localStorage.setItem('capabilityNetwork', JSON.stringify(this.levelData));
      this.bubbleService.setBubbleTreeData();
    } else {

      if (this.isAllParentActive == 1) {
        const deleted = !activeState ? 1 : 0;

        const arrReqData = {
          inUpdatedUserKey: sessionStorage.getItem('userKey'),
          licensedOwnerOfferingKey: OperationData.licensedOwnerOfferingKey,
          isDeleted: deleted,
          licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
          providerServiceOfferingKey: OperationData.providerServiceOfferingKey,

        };
        if (this.isFromRateGroup && OperationData.level == 1) {
          this.rateService.setServiceForLineItem(deleted, OperationData.licensedOwnerOfferingKey);
        } else {
          this.loading = true;
          this.commonService.updateActivateInActivateLicensedOwnerOfferings(arrReqData).subscribe(result => {
            this.loading = false;
          });
        }
        this.networkJson = JSON.parse(localStorage.getItem('serviceNetwork'));
        this.functionActiveInactiveNode(this.networkJson, OperationData, deleted);
        this.setMainTreeJson();
      } else {

        this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');

        if (this.providerAccount != null || this.providerAccount != undefined) {
          this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
        }
        const isDeleted = !activeState ? 1 : 0;

        if (this.parentRecord) {
          this.addProviderAccountDataIntoLocation(isDeleted, OperationData);
        } else {
          const arrReqData = {
            "isDeleted": isDeleted,
            "licensedOwnerOfferingKey": OperationData.licensedOwnerOfferingKey,
            "licensedOwnerKey": this.licensedOwnerKey,
            "providerKey": this.providerAccount.ProviderKey,
            "providerServiceOfferingKey": OperationData.providerServiceOfferingKey,
            "providerAccountKey": this.providerAccount.ProviderAccountKey
          };
          this.bubbleService.setBubbleLoader(true);
          if (this.isFromRateGroup && OperationData.level == 1) {
            this.rateService.setServiceForLineItem(isDeleted, OperationData.licensedOwnerOfferingKey);
          } else {
            this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
              this.bubbleService.setBubbleLoader(false);
            });
          }
          this.networkJson = JSON.parse(this.bubbleService.getserviceNetworkData());
          this.functionActiveInactiveNodeService(this.networkJson, OperationData, isDeleted);
          this.setMainTreeJsonService();
        }
      }
    }
  }

  functionActiveInactiveNodeService(networkJson: any, nodeToUpdate: AnimationKeyframesSequenceMetadata, activeFlag: number) {
    let nodeToUpdateJson = JSON.stringify(nodeToUpdate, null);
    for (let i in networkJson) {
      let tempNode = networkJson[i];
      let tempNodeJson = JSON.stringify(tempNode, null);
      // check if interacted node mathced with any node from nodes in iteration context
      if (tempNodeJson === nodeToUpdateJson) {
        tempNode.deleted = activeFlag;
        if (tempNode.children && !this.isFromRateGroup) {
          // method to set all children active or inactive

          this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
          // this.makeAllBelowActiveInactiveService(tempNode.children, activeFlag);
        }
        break;
      }

      // check if child nodes exist. If exist then recursively match interacted node with any node from child nodes in recursive context
      if (tempNode.children) {
        this.functionActiveInactiveNodeService(tempNode.children, nodeToUpdate, activeFlag);
      }
    }
  }

  // makeAllBelowActiveInactiveService(childNode, activeFlag) {
  //   for (let i in childNode) {
  //     let tempNode = childNode[i];
  //     tempNode.deleted = activeFlag;
  //     if (tempNode.children) {
  //       this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
  //     }
  //   }
  // }
  addProviderAccountDataIntoLocation(isDeleted, OperationData) {

    const data = {
      accountName: this.providerAccount.accountName,
      licensedOwnerKey: this.licensedOwnerKey,
      providerKey: sessionStorage.getItem('providerKey'),
    };
    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderAccountKey(data).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      const data = {
        locationKey: this.providerKey,
        providerKey: sessionStorage.getItem('providerKey'),
        licensedOwnerKey: this.licensedOwnerKey,
        providerAccountKey: result.body[0].providerAccountKey,
        locationAccountKey: this.providerAccount.providerAccountKey,
      };

      this.bubbleService.setBubbleLoader(true);
      this.bubbleService.setUpServiceAndRates(data).subscribe(result => {
        this.bubbleService.setBubbleLoader(false);
        if (this.licensedOwnerKey != '') {
          this.getProviderServiceOfferings(isDeleted, OperationData);
        } else {
          this.getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData);
        }
      }, (error) => {
        this.bubbleService.setBubbleLoader(false);
      });
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  UpdateSelectedRecord(isDeleted, OperationData) {
    const data = {
      licensedOwnerKey: this.licensedOwnerKey,
      providerKey: this.providerAccount.providerKey,
      licensedOwnerOfferingKey: OperationData.licensedOwnerOfferingKey,
      providerAccountKey: this.providerAccount.providerAccountKey,

    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderService(data).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      const arrReqData = {
        isDeleted: isDeleted,
        licensedOwnerOfferingKey: OperationData.licensedOwnerOfferingKey,
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        providerKey: this.providerAccount.ProviderKey,
        providerServiceOfferingKey: result.body[0].providerServiceOfferingKey,
        providerAccountKey: this.providerAccount.ProviderAccountKey
      };

      this.bubbleService.setBubbleLoader(true);
      this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
        this.bubbleService.setBubbleLoader(false);
      });

      this.networkJson = JSON.parse(this.bubbleService.getserviceNetworkData());
      this.functionActiveInactiveNode(this.networkJson, OperationData, isDeleted);
      this.setMainTreeJsonService();

    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  getProviderServiceOfferings(isDeleted, OperationData) {
    const arrProviderServiceOfferingsFilters = {
      licensedOwnerKey: this.licensedOwnerKey,
      providerKey: this.providerKey,
      providerAccountKey: this.providerAccountKey
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderServiceOfferings(arrProviderServiceOfferingsFilters).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      this.serviceData = result;
      if (this.bubbleService.getserviceNetworkData() == null || this.bubbleService.getserviceNetworkData() == undefined) {
        this.bubbleService.setserviceNetworkData(JSON.stringify(this.serviceData));
      }
      this.UpdateSelectedRecord(isDeleted, OperationData);
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  getProviderServiceOfferingsWithoutlicensedOwnerKey(isDeleted, OperationData) {

    const data = {
      providerKey: this.providerKey,
      providerAccountKey: this.providerAccountKey
    };
    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderServiceOfferingsWithoutlicensedOwnerKey(data).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      this.serviceData = result;
      if (this.bubbleService.getserviceNetworkData() == null || this.bubbleService.getserviceNetworkData() == undefined) {
        this.bubbleService.setserviceNetworkData(JSON.stringify(this.serviceData));
      }
      this.UpdateSelectedRecord(isDeleted, OperationData);
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  // recursive method to update tree active inactive status
  functionActiveInactiveNode(networkJson: any, nodeToUpdate: AnimationKeyframesSequenceMetadata, activeFlag: number) {
    const nodeToUpdateJson = JSON.stringify(nodeToUpdate, null);

    // tslint:disable-next-line: forin
    for (const i in networkJson) {
      const tempNode = networkJson[i];
      const tempNodeJson = JSON.stringify(tempNode, null);
      // check if interacted node mathced with any node from nodes in iteration context
      if (tempNodeJson === nodeToUpdateJson) {
        tempNode.deleted = activeFlag;
        if (tempNode.children) {
          // method to set all children active or inactive
          this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
        }
        break;
      }

      // check if child nodes exist. If exist then recursively match interacted node with any node from child nodes in recursive context
      if (tempNode.children) {
        this.functionActiveInactiveNode(tempNode.children, nodeToUpdate, activeFlag);
      }
    }
  }

  // method to set all children active or inactive
  makeAllBelowActiveInactive(childNode, activeFlag) {
    // tslint:disable-next-line: forin
    for (const i in childNode) {
      const tempNode = childNode[i];
      tempNode.deleted = activeFlag;
      if (tempNode.children) {
        this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
      }
    }
  }

  /**
  * Static recursive method to update tree by adding or removing nodes
  *
  * @param { nodes: any } A nested tree array of objects (Nodes)
  * @param { nodeToMatch: any } Node to be found/matched
  * @param { nodeToAddRemove: any } Node to be added or removed
  * @param { action: string } Action to be performed 'add' or 'remove' (For now)
  */

  // tslint:disable-next-line: member-ordering
  static manipulateNestedTree(nodes: any, nodeToMatch: any, nodeToAddRemove: any, action: string) {
    try {
      const nodeJsonToMatch = JSON.stringify(nodeToMatch, null);
      const nodeJsonToAddRemove = JSON.stringify(nodeToAddRemove, null);

      // tslint:disable-next-line: forin
      for (const i in nodes) {
        const tempNode = nodes[i];
        const tempNodeJson = JSON.stringify(tempNode, null);

        // check if interacted node mathced with any node from nodes in iteration context
        if (tempNodeJson === nodeJsonToMatch) {
          if (action === 'remove') {
            nodes.splice(i, 1);
          } else if (action === 'add') {
            const nodeToAdd = JSON.parse(nodeJsonToAddRemove);
            tempNode.children.push(nodeToAdd);
          }

          break;
        }
        // check if child nodes exist. If exist then recursively match interacted node with any node from child nodes in recursive context
        if (tempNode.children) {
          BubbleTreeUiComponent.manipulateNestedTree(tempNode.children, nodeToMatch, nodeToAddRemove, action);
        }
      }
    } catch (err) {
      BubbleTreeUiComponent.treeRecursiveOperationException = true;
    }
  }

  setMainTreeJson() {
    localStorage.setItem('serviceNetwork', JSON.stringify(this.networkJson));
    this.bubbleService.setBubbleTreeData();
  }

  setMainTreeJsonService() {
    this.bubbleService.setserviceNetworkData(JSON.stringify(this.networkJson));
    this.bubbleService.setBubbleTreeData();
  }

  //method to get selected nodes
  selectNode(event, OperationData) {
    const OperationArr = {
      ServiceName: sessionStorage.getItem('parentSelected'),
      SubServiceName: OperationData.name
    };
    const OperationDataJson = JSON.stringify(OperationArr);
    let index = 0;
    let selectArr;
    selectArr = JSON.parse(localStorage.getItem('serviceETA')) == null ? [] : JSON.parse(localStorage.getItem('serviceETA'));

    if (event.checked) {
      selectArr.push(OperationArr);
    } else {
      // tslint:disable-next-line: forin
      for (const i in selectArr) {
        const tempNode = selectArr[i];
        const tempNodeJson = JSON.stringify(tempNode, null);

        if (tempNodeJson === OperationDataJson) {
          selectArr.splice(index, 1);
          break;
        }
        index++;
      }
    }

    localStorage.setItem('serviceETA', JSON.stringify(selectArr));
  }

  // -------------------------Protocol---------------------------------------
  addProtocol(levelData: any) {
    const dialogRef = this.dialog.open(AddProtocolsDialogComponent, {
      hasBackdrop: true,
      width: '60%',
      position: {
        top: '5%',
      },
      height: '60%',
      data: {
        data: levelData,
        isLicensedOwnerProtocol: true,
        dialogTitle: 'Add Master Protocols',
        edit: false
      }
    });

    dialogRef.afterClosed().subscribe((reponse) => {
      this.getLicensedOwnerOfferings();
    });
  }

  getLicensedOwnerOfferings() {

    this.loading = true;
    const licenesedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
    const objParams = {
      licenesedOwnerKey,
      customerKey: ''
    };
    this.protocolService.getMasterAndCustomersProtocolServices(objParams).subscribe(result => {
      this.networkJson = result;
      localStorage.setItem('serviceNetworkProtocol', JSON.stringify(this.networkJson));
      this.bubbleService.setBubbleTreeData();
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  viewApprovedProtocols(event: any) {
    let dialogData = {};
    dialogData = {
      data: event,
      dialogTitle: 'Approved Protocols',
      edit: false,
      callerTypeKey: this.callerType
    };

    const dialogRef = this.dialog.open(ApprovedProtocolsListDialogComponent, {
      hasBackdrop: true,
      width: '75%',
      position: {
        top: '5%',
      },
      height: '75%',
      data: dialogData
    });
  }

  viewProtocols(event: any) {
    const dialogData = {
      data: event,
      isLicensedOwnerProtocol: true,
      dialogTitle: 'Master Protocols',
      edit: false
    };

    const dialogRef = this.dialog.open(ProtocolListDialogComponent, {
      hasBackdrop: true,
      width: '75%',
      disableClose: true,
      position: {
        top: '5%',
      },
      data: dialogData
    });
  }

  deleteProtocols(event: any) {

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const paramsData = {
          licensedOwnerOfferingKey: event.licensedOwnerOfferingKey,
          deleted: 0
        };
        this.protocolService.getProtocolList(paramsData).subscribe(response => {
          if (response.body.length > 0) {
            const batchUpdateData = [];
            this.loading = true;
            response.body.forEach((value) => {
              const updateData = {
                customerProtocolKey: value.customerProtocolKey,
                updatedUserKey: sessionStorage.getItem('userKey'),
                deleted: 1,
              };
              batchUpdateData.push(updateData);
            });

            this.protocolService.editBatchCustomerProtocols(batchUpdateData).subscribe(resp => {
              if (resp.success) {
                this.toast.success('Master Protocols deleted Successfully!', 'Success');
                this.getLicensedOwnerOfferings();
              } else {
                this.toast.warning(resp.message[0], 'Error');
              }
              this.loading = false;
            }, (error) => {
              console.log('error', error);
              this.loading = false;
            });
          } else {
            this.toast.warning('No records available to delete', 'Warning');
          }
          this.loading = false;
        }, (error) => {
          console.log('error', error);
          this.loading = false;
        });
      }
    });
  }

  addThreshold(levelData: any, isOverallLimit) {
    const dialogRef = this.dialog.open(AddLimitsComponent, {
      hasBackdrop: true,
      width: '70%',
      position: {
        top: '5%',
      },
      data: {
        licensedOwnerOfferingKey: levelData.licensedOwnerOfferingKey,
        isLicensedOwnerProtocol: this.isLicensedOwnerProtocol,
        isOverallLimit,
        userDetails: this.userDetails
      }
    });

    dialogRef.afterClosed().subscribe((reponse) => {
      this.getLicensedOwnerOfferingsThreshold();
    });
  }

  viewThreshold(event: any) {
    const dialogData = {
      data: event,
      isLicensedOwnerProtocol: this.isLicensedOwnerProtocol,
      userDetails: this.userDetails
    };

    const dialogRef = this.dialog.open(ThresholdListDialogComponent, {
      hasBackdrop: true,
      width: '75%',
      disableClose: true,
      position: {
        top: '4%',
      },
      height: '75%',
      data: dialogData
    });
  }

  deleteThreshold(event: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const paramsData = {
          userKey: this.userDetails.userKey,
          licensedOwnerOfferingKey: event.licensedOwnerOfferingKey,
          deleted: false
        };
        this.usersService.getAuthorizationThresholds(paramsData).subscribe(response => {
          if (response.body.length > 0) {
            const batchUpdateData = [];
            this.loading = true;
            response.body.forEach((value) => {
              const updateData = {
                authorizationThresholdKey: value.authorizationThresholdKey,
                updatedUserKey: sessionStorage.getItem('userKey'),
                deleted: true,
              };
              batchUpdateData.push(updateData);
            });

            this.usersService.editBatchLicensedOwnerThreshold(batchUpdateData).subscribe(resp => {
              if (resp.success) {
                this.toast.success('Master Thresholds deleted Successfully!', 'Success');
                this.getLicensedOwnerOfferingsThreshold();
              } else {
                this.toast.warning(resp.message[0], 'Error');
              }
              this.loading = false;
            }, (error) => {
              console.log('error', error);
              this.loading = false;
            });
          } else {
            this.toast.warning('No records available to delete', 'Warning');
          }
          this.loading = false;
        }, (error) => {
          console.log('error', error);
          this.loading = false;
        });
      }
    });
  }

  getLicensedOwnerOfferingsThreshold() {
    const data = {
      associationKey: this.userDetails.associationKey,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      userKey: this.userDetails.userKey
    };
    this.loading = true;
    this.usersService.getLicensedOwnerOfferingsThreshold(data).subscribe(result => {
      this.networkJson = result;
      localStorage.setItem('serviceNetworkThreshold', JSON.stringify(this.networkJson));
      this.bubbleService.setBubbleTreeData();
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  /***
   * @description: Add Offering Questions in Dynamic forms
   * @params: array object
   * @result: array response
  ***/
  addOfferingQuestions(levelData: any) {

    const dialogRef = this.dialog.open(AddOfferingQuestionsModalComponent, {
      hasBackdrop: true,
      width: '95%',
      position: {
        top: '5%'
      },
      data: { levelData }
    });

    dialogRef.afterClosed().subscribe((reponse) => {
      this.getOfferingQuestionsServices();
    });
  }

  /***
   * @description: Update Offering Questions in Dynamic forms
   * @params: array object
   * @result: array response
  ***/
  editOfferingQuestions(levelData: any) {

    const dialogRef = this.dialog.open(AddOfferingQuestionsModalComponent, {
      hasBackdrop: true,
      width: '95%',
      position: {
        top: '5%'
      },
      data: { levelData, isEdit: true }
    });

    dialogRef.afterClosed().subscribe((reponse) => {
      this.getOfferingQuestionsServices();
    });
  }

  /***
   * @description: Delete Offering Questions from Dynamic forms
   * @params: array object
   * @result: array response
   ***/
  deleteOfferingQuestions(levelData: any) {

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const data = {
          dynamicFormKey: levelData.dynamicFormKey,
          deleted: true,
          updatedUserKey: sessionStorage.getItem('userKey'),
        };
        this.loading = true;
        this.dynamicQuestionsService.updateDynamicFormQuestions(data).subscribe(response => {
          if (response.success) {
            this.toast.success(response.message, 'Success');
          }
          this.getOfferingQuestionsServices();
          this.loading = false;
        }, error => {
          this.loading = false;
          this.toast.danger(error.message, 'Error');
        });
      }
    });
  }

  /***
   * @description: get Offering Question services
   * @params: licenesedOwnerKey
   * @result: array response
   ***/
  getOfferingQuestionsServices() {

    const licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
    this.loading = true;
    this.offeringQuestionsService.getofferingQuestionsServices(licensedOwnerKey).subscribe(result => {
      this.networkJson = result;
      localStorage.setItem('serviceNetworkProtocol', JSON.stringify(this.networkJson));
      this.bubbleService.setBubbleTreeData();
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }
}
