import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    centerData: Observable<any>;
    public profilePicture = new Subject<any>();
    userhoursLength: number;

    constructor(private http: HttpClient) { }
    data: any = { deleted: false };
    securityGroups: Observable<any>;
    userTypes: Observable<any>;
    /**
     * Service method to get users from API
     *
     * @returns Observable
     */
    setProfilePicture(url) {
        sessionStorage.setItem('profilePictureURL', url);
        this.profilePicture.next();
    }

    getProfilePicture(): Observable<any> {
        return this.profilePicture.asObservable();
    }
    getUsers(filter: any = {}): Observable<any> {
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}systemsecurities/users/get`, filter);
    }

    getCustomerUsersList(data): Observable<any> {
        return this.http.post(`${environment.baseUrl}getAllUserDetails`, data);
    }

    getUserLists(filter = {}): Observable<any> {
        return this.http.post(`${environment.baseUrl}getUsersList`, filter).pipe(
            map((response: any) => {
                if (environment.production) {
                    const filteredData = response.body.filter(user => user.email && user.email.indexOf('yopmail.com') === -1);
                    response.body = filteredData;
                    return response;
                } else {
                    return response;
                }
            })
        );
    }

    /**
     * Service method to add a new user
     *
     * @returns Observable
     */
    addUser(user: any = null): Observable<any> {
        return this.http.post(`${environment.apiUrl}systemsecurities/users`, user);
    }

    /**
     * Service method to add/invite a new user
     *
     * @returns Observable
     */
    addInviteUser(inviteUser: any = null): Observable<any> {
        return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations`, inviteUser);
    }

    /**
     * Service method to update user details
     *
     * @returns Observable
     */
    updateUser(user: any = null): Observable<any> {
        return this.http.put(`${environment.apiUrl}systemsecurities/users`, user);
    }

    /**
     * Service method to update customer details
     *
     * @returns Observable
     */
    updateCustomerDetails(customerData: any = null): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/customers`, customerData);
    }

    /**
     * Service method to update customer tier details
     *
     * @returns Observable
     */
    updateCustomerTireDetails(customerTierData: any = null): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/customertierstructures`, customerTierData);
    }

    /**
     * Service method to get user skills
     *
     * @returns Observable
     */
    getSkills(filter): Observable<any> {
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}serviceplus/userskills/get`, filter);
    }

    /**
     * Service method to update user skills
     *
     * @returns Observable
     */
    updateUserSkills(data: any = {}): Observable<any> {
        return this.http.put(`${environment.apiUrl}serviceplus/userskills`, data);
    }

    /**
     * Service method to add user skills
     *
     * @returns Observable
     */
    addUserSkills(data: any = {}): Observable<any> {
        return this.http.post(`${environment.apiUrl}serviceplus/userskills`, data);
    }

    /**
     * Service method to get current timestamp (As per client's system time)
     *
     * @returns Number of milliseconds since 1970/01/01
     */
    getTimestamp() {
        const date = new Date();
        const timestamp = date.getTime();

        return timestamp;
    }

    /**
     * Service method to get company names
     *
     * @returns Observable
     */
    getCompanyName(): Observable<any> {
        return this.http.post(`${environment.apiUrl}fleetassist/customers/get`, this.data);
    }

    /**
     * Service method to get customer tier names
     *
     * @returns Observable
     */
    getHierarchyLevel(data: any = {}): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}fleetassist/customertierstructures/get`, data);
    }

    getAvailability(filter: any = {}): Observable<any> {
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}fleetassist/userhours/get`, filter);
    }

    updateAvailability(data): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/userhours`, data);
    }

    getUserGroup(filter: any = {}): Observable<any> {
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}systemsecurities/usersecuritygroups/get`, filter);
    }

    getUserSubscribedCommunications(filter: any = {}): Observable<any> {
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}systemsecurities/usersubscribedcommunications/get`, filter);
    }

    getApprovals(filter: any = {}): Observable<any> {
        // return this.http.post(`${environment.apiUrl}fleetassist/customerapprovallimits/get`, filter);
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}fleetassist/customersecurityroleapprovaldefaults/get`, filter);
    }

    getSecurityGroup(data: any = {}): Observable<any> {
        if (!this.securityGroups) {
            if (data.deleted === undefined) {
                data.deleted = false;
            }
            this.securityGroups = this.http.post(`${environment.apiUrl}keyvaluepairs/securitygroups/get`, data).pipe(
                map(res => res),
                publishReplay(1),
                refCount()
            );
        }
        return this.securityGroups;
    }

    getUserType(data: any = {}): Observable<any> {
        if (!this.userTypes) {
            if (data.deleted === undefined) {
                data.deleted = false;
            }
            this.userTypes = this.http.post(`${environment.apiUrl}keyvaluepairs/usertypes/get`, data).pipe(
                map(res => res),
                publishReplay(1),
                refCount()
            );
        }
        return this.userTypes;
    }

    /**
     * Service method to add multiple users
     *
     * @returns Observable
     */
    addMultipleUsers(users): Observable<any> {
        return this.http.post(`${environment.apiUrl}systemsecurities/users/batch`, users);
    }

    updateMultipleUsers(users): Observable<any> {
        return this.http.put(`${environment.apiUrl}systemsecurities/users/batch`, users);
    }

    addUserGroups(userGroups): Observable<any> {
        return this.http.post(`${environment.apiUrl}systemsecurities/usersecuritygroups/batch`, userGroups);
    }

    addUserSecurityGroup(userGroups): Observable<any> {
        return this.http.post(`${environment.apiUrl}systemsecurities/usersecuritygroups`, userGroups);
    }

    updateUserGroups(userGroups): Observable<any> {
        return this.http.put(`${environment.apiUrl}systemsecurities/usersecuritygroups`, userGroups);
    }

    updateMultipleUserGroups(userGroups): Observable<any> {
        return this.http.put(`${environment.apiUrl}systemsecurities/usersecuritygroups/batch`, userGroups);
    }

    getContactCenterTeam(filter: any = {}): Observable<any> {
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}fleetassist/licensedownercontactcenterteams/get`, filter);
    }

    getContactCenterTeamDetails(filter: any = {}): Observable<any> {
        if (filter.deleted === undefined) {
            filter.deleted = false;
        }
        return this.http.post(`${environment.baseUrl}getLicensedOwnerContactCenterTeams`, filter);
    }

    getLicensedOwnerContactCenterTeams(filter: any = {}): Observable<any> {
        if (!this.centerData) {
            if (filter.deleted === undefined) {
                filter.deleted = false;
            }
            this.centerData = this.http.post(`${environment.apiUrl}keyvaluepairs/contactcenterteams/get`, filter).pipe(
                map(res => res),
                publishReplay(1),
                refCount()
            );
        }
        return this.centerData;
    }

    addContactCenterTeams(filter): Observable<any> {
        return this.http.post(`${environment.apiUrl}fleetassist/licensedownercontactcenterteams`, filter);
    }

    updateContactCenterTeams(filter): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/licensedownercontactcenterteams`, filter);
    }

    deleteContactCenterTeams(postData): Observable<any> {
        const deleteTeamData: any = {
            body: { licensedOwnerContactCenterTeamKey: postData.licensedOwnerContactCenterTeamKey },
        };
        return this.http.delete<any>(`${environment.apiUrl}fleetassist/licensedownercontactcenterteams`, deleteTeamData);
    }

    addAvailabilityHoursBatch(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}fleetassist/userhours/batch`, data);
    }

    updateAvailabilityHoursBatch(data): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/userhours/batch`, data);
    }

    getSecurityGroupByPlatform(data: any = {}): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}keyvaluepairs/securitygroups/get`, data);
    }

    getUserSecurityGroups(data = {}): Observable<any> {
        return this.http.post(`${environment.baseUrl}getUserSecurityGroups`, data);
    }

    // getUserStatus(data: any = {}): Observable<any> {
    //     if (data.deleted === undefined) {
    //         data.deleted = false;
    //     }
    //     return this.http.post(`${environment.apiUrl}keyvaluepairs/statuses/get`, data);
    // }

    getUsersDetails(data: any = {}): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/users/get`, data).pipe(
            map((response: any) => {
                if (environment.production) {
                    const filteredData = response.body.filter(user => user.email && user.email.indexOf('yopmail.com') === -1);
                    response.body = filteredData;
                    return response;
                } else {
                    return response;
                }
            })
        );
    }

    getUserTypes(data: any = {}): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/usertypes/get`, data);
    }
    // getSupervisorUsers(filter = {}): Observable<any> {
    //     return this.http.get(`${environment.baseUrl}getLicensedOwnerSupervisor`);
    // }
    getSupervisorUsers(licensedOwnerKey): Observable<any> {
        return this.http.get(`${environment.baseUrl}getLicensedOwnerSupervisor/${licensedOwnerKey}`);
    }

    getUserServiceSkills(data: any = {}): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}getContactCenterUserServiceSkills`, data);
    }

    getContactCenterUserServiceSkills(data: any = {}): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}fleetassist/contactcenteruserserviceskills/get`, data);
    }

    addContactCenterUserServiceSkills(data: any = {}): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}fleetassist/contactcenteruserserviceskills`, data);
    }

    updateContactCenterUserServiceSkills(data: any = {}): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}fleetassist/contactcenteruserserviceskills`, data);
    }

    getContactCenterActionSkills(userKey: string): Observable<any> {
        return this.http.get(`${environment.baseUrl}getContactCenterUserActionSkills/${userKey}`);
    }

    getContactCenterActionSkillsByUserKey(data: any = {}): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}fleetassist/contactcenteruseractionskills/get`, data);
    }

    addContactCenterActionSkills(filter): Observable<any> {
        return this.http.post(`${environment.apiUrl}fleetassist/contactcenteruseractionskills`, filter);
    }

    updateContactCenterActionSkills(data: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/contactcenteruseractionskills`, data);
    }

    getAuthorizationThresholdsDetails(data: any): Observable<any> {
        return this.http.post(`${environment.baseUrl}getAuthorizationThresholds`, data);
    }

    getAuthorizationThresholds(data: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}fleetassist/authorizationthresholds/get`, data);
    }

    addAuthorizationThresholds(data: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}fleetassist/authorizationthresholds`, data);
    }

    updateAuthorizationThresholds(data: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/authorizationthresholds`, data);
    }

    getLicensedOwnerOfferingsThreshold(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}getLicensedOwnerOfferingsThreshold`, data);
    }

    addBatchLicensedOwnerThreshold(data: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}fleetassist/authorizationthresholds/batch`, data);
    }

    editBatchLicensedOwnerThreshold(data: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}fleetassist/authorizationthresholds/batch`, data);
    }

    getUserAuthorizationList(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}getUserAuthorizationList`, data);
    }

    // It will add new user into cognito pool
    addNewUserToCognito(data): Observable<any> {
        return this.http.post<any>(`${environment.cognitoSignupUrl}`, data);
    }

    getUsersEmergencyApprover(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}getEmergencyApprover`, data);
    }

    getDirectReports(userKey): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}getDirectReports/${userKey}`);
    }

    getUserHoursDetails(data: any = {}): Observable<any> {
        if (!data.deleted) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}serviceplus/userhours/get`, data);
    }

    setUserHours(usersHoursLength: number) {
        this.userhoursLength = usersHoursLength;
    }

    /***
    * Desc: Add user availability hours
    * @params postParams
    * @return result success
    */
    addProviderAvailabilityHoursBatch(data: any = {}): Observable<any> {
        return this.http.post(`${environment.apiUrl}serviceplus/userhours/batch`, data);
    }

    /***
    * Desc: Update user availability hours
    * @params postParams
    * @return result success
    */
    updateUserAvailabilityHours(filter: any = {}): Observable<any> {
        return this.http.put(`${environment.apiUrl}serviceplus/userhours`, filter);
    }

    // get User Details By AssociationKey
    getUserDetailsByAssociationKey(data:any = {}): Observable<any> {
        return this.http.post(`${environment.baseUrl}getUserDetailsByAssociationKey`, data);
    }
}
