<app-loader [loading]="loading"></app-loader>
    <!-- <nb-card>
        <nb-card-body>
            <br>

        </nb-card-body>
    </nb-card> -->

    <div class="m-4">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field >
                    <mat-label>Filter Service</mat-label>
                    <input matInput (keyup)="filterServices($event)" [(ngModel)]="filterService" placeholder="service name, service category, etc...">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">

                <table matSort class="table table-bordered rates">
                    <thead class="text-center">
                        <tr>
                            <!-- <th mat-sort-header="agencyName" sortable="agencyName" direction="asc"> Broker Agency
                            </th> -->
                            <th mat-sort-header="ASVvcName" (sort)="onSort($event)" sortable="ASVvcName" direction="asc"> Service Name </th>
                            <th> Service Category </th>
                            <th> Rate </th>
                            <th> Date Approved</th>
                            <th> Approved By</th>
                            <th> Actions </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="showServices && showServices.length > 0; else noData">
                        <tr  *ngFor="let service of showServices; let i = index;" >
                            <td> {{service.ASVvcName }}</td>
                            <td > {{service.LSSvc255ServiceName  }}</td>
                            <td > 
                                <mat-label>Amount: &nbsp;</mat-label>
                                <input type="number" matInput [(ngModel)]="service.PACfPrice" [value]="service.PACfPrice" >
                                <!-- <input type="number" matInput appCurrencyInput currencyCode="USD" [(ngModel)]="service.PACfPrice" [value]="service.PACfPrice" > -->
                            </td>
                            <td > {{service.PACDateUpdated | date:'yyyy-MM-dd HH:mm' }}</td>
                            <td> {{service.PACvc45UpdatedBy  }} </td>
                            <td  class="text-center">
                                <!-- <button class="btn btn-default btnAnimation mr-1" nbTooltip="Add/Edit"
                                    (click)="editServiceLineItem(service)">
                                    <i class="fas fa-check"></i>
                                </button>
                                <button class="btn btn-default btnAnimation mr-1" nbTooltip="Delete"
                                    (click)="deleteServiceLineItem(service)">
                                        <i class="fas fa-trash-alt"></i>
                                </button> -->
                                <button class="btn btn-default btnAnimation mr-1" nbTooltip="Clear Line"
                                (click)="deleteServiceLineItem(service)">
                                    <i class="fas fa-eraser"></i>
                            </button>
                            </td>
                 
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="6" class="text-center">
                                    No Data available
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>

            </div>

        </div>

        <br>
        <div class="row">
            <div class="col-md-12 text-right">
                <button (click)="saveServiceLineChanges()" class="btn btn-primary btn-sm">Save Service Changes</button>
            </div>
        </div>

    </div>

<!-- <app-loader [loading]="loading"></app-loader>
    <nb-card>
        <nb-card-body>
        <div class="row">
            <div class="col-md-4">
                        <ngx-search-input (search)="search($event)"></ngx-search-input>
                    </div>
            <div class="col-md-3">
                        <button class="btn cursor-default rounded-circle btn-md">
                            <i class="fa fa-check-circle text-success"></i>
                        </button>
                        <span class="small"><small>Service Approved</small></span>
                    </div>
                    <div class="col-md-3">
                        <button class="btn cursor-default rounded-circle btn-md">
                            <i class="fa fa-times-circle text-danger"></i>
                        </button>
                        <span class="small"><small>Service not Approved</small></span>
                    </div>
            <div class="col-md-2">
                        <label><strong>{{strPaymentTermKey}}</strong></label>
                    </div>
                </div>
        <div class="table-responsive vendor-rates">            
                <table class="table table-bordered rates text-center" [ngClass]="{'rates-parent': getParentRecord}">
                    <thead class="text-center">
                        <tr>
                            <th sortable="lineItemKey" (sort)="onSort($event)" direction="asc" class="v-a-m">
                                Line Item
                            </th>
                            <th sortable="lineItemPricingTypeKey" (sort)="onSort($event)" direction="asc" class="v-a-m">
                                Rate Type
                            </th>
                            <th>
                                Emergency Rate
                                <button class="btn cursor-default rounded-circle btn-md"
                                    nbTooltip="Available for a dispatch scheduled hours or days in advance and at a time better suited to meet your operational needs">
                                    <i class="fa fa-info-circle text-muted"></i>
                                </button>
                            </th>
                            <th *ngIf="afterHoursRatesAllowed" class="v-a-m">
                                Emergency After Hour Rates
                            </th>
                            <th>
                                Scheduled Rate
                                <button class="btn cursor-default rounded-circle btn-md"
                                    nbTooltip="Knowing a schedule of services for a group of vehicles that need to be performed within a window of time e.g. within the week or month">
                                    <i class="fa fa-info-circle text-muted"></i>
                                </button>
                            </th>
                            <th class="v-a-m" *ngIf="isDisallowed">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let providerAccountPricing of arrProviderAccountPricing | filter : searchString; let i = index;">
                            <td class="text-left">{{providerAccountPricing.lineItemKey}}</td>
                            <td class="text-center">
                                <label
                                    *ngIf="!(providerAccountPricing.editMode) || providerAccountPricing.emergencyRateApproved == 1">
                                    {{providerAccountPricing.lineItemPricingTypeKey}} </label>
                                <span
                                    *ngIf="providerAccountPricing.editMode && providerAccountPricing.emergencyRateApproved != 1">
                                    <span *ngIf="isLicensedOwnerKey">
                                        {{providerAccountPricing.lineItemPricingTypeKey}}
                                    </span>
                                    <span *ngIf="!isLicensedOwnerKey">
                                        <mat-form-field>
                                            <mat-select placeholder="Rate Type"
                                                [(ngModel)]="providerAccountPricing.lineItemPricingTypeKey">
                                                <mat-option *ngFor="let lineItem of arrLineItemPricingTypes"
                                                    [value]="lineItem.lineItemPricingTypeKey">
                                                    {{lineItem.lineItemPricingTypeKey}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </span>
                                </span>
                            </td>
                            <td>
                                <label
                                    *ngIf="!(providerAccountPricing.editMode) || providerAccountPricing.emergencyRateApproved == 1">
                                    <span *ngIf="providerAccountPricing.emergencyRate > 0">$</span>
                                    {{providerAccountPricing.emergencyRate}} </label>
                                <mat-form-field
                                    *ngIf="providerAccountPricing.editMode && providerAccountPricing.emergencyRateApproved != 1">
                                    <input type="text" pattern="^(\d+\.)?\d+$"
                                        (change)="setAfterHoursRates(providerAccountPricing)"
                                        (keyup)="isDecimal(providerAccountPricing.emergencyRate)"
                                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                                        matInput [(ngModel)]="providerAccountPricing.emergencyRate">
                                    <span matPrefix *ngIf="providerAccountPricing.emergencyRate != null">$&nbsp;</span>
                                </mat-form-field>
    
                                <button
                                    *ngIf="providerAccountPricing.emergencyRateApproved == 1 || providerAccountPricing.emergencyRateApproved == 0"
                                    class="btn cursor-default rounded-circle btn-md floatR">
                                    <i [ngClass]="{   
                                            'fa':true,                                  
                                            'fa-check-circle text-success': providerAccountPricing.emergencyRateApproved == 1,                                    
                                            'fa-times-circle text-danger': providerAccountPricing.emergencyRateApproved == 0
                                            }">
                                    </i>
                                </button>
                                <div [ngClass]="{
                                    'mt-3': providerAccountPricing.emergencyRateApproved == 1
                                    }"
                                     *ngIf="(providerAccountPricing.editMode)">
                                    <button class="btn btn-sm btn-warning" *ngIf="emergencyRateApproved && emergencyRateApproved.rateApproved === false && emergencyRateApproved.deleted === false" (click)="openAccountPricingException(emergencyRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Pending
                                    </button>
                                    <button class="btn btn-sm btn-danger" *ngIf="emergencyRateApproved && emergencyRateApproved.rateApproved === false && emergencyRateApproved.deleted === true" (click)="openAccountPricingException(emergencyRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Denied
                                    </button>
                                    <button class="btn btn-sm btn-success" *ngIf="emergencyRateApproved && emergencyRateApproved.rateApproved === true && emergencyRateApproved.deleted === false" (click)="openAccountPricingException(emergencyRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Approved
                                    </button>
                                    <button class="btn btn-sm btn-secondary" *ngIf="isEmergencyRate && !emergencyRateApproved && providerAccountPricing.emergencyRateApproved === 0" (click)="openAccountPricingException(emergencyRateApproved, providerAccountPricing, 'Emergency Rate')" type="button">
                                        Request Custom Rate
                                    </button>
                                </div>
                            </td>
                            <td *ngIf="afterHoursRatesAllowed">
                                <label
                                    *ngIf="!(providerAccountPricing.editMode) || providerAccountPricing.afterHoursRateApproved == 1">
                                    <span *ngIf="providerAccountPricing.afterHoursRate > 0">$</span>
                                    {{providerAccountPricing.afterHoursRate}} </label>
                                <mat-form-field
                                    *ngIf="providerAccountPricing.editMode && providerAccountPricing.afterHoursRateApproved != 1">
                                    <input type="text" pattern="^(\d+\.)?\d+$"
                                        (keyup)="isDecimal(providerAccountPricing.afterHoursRate)"
                                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                                        matInput [(ngModel)]="providerAccountPricing.afterHoursRate">
                                    <span matPrefix *ngIf="providerAccountPricing.afterHoursRate != null">$&nbsp;</span>
                                </mat-form-field>
                                <button
                                    *ngIf="providerAccountPricing.afterHoursRateApproved == 1 || providerAccountPricing.afterHoursRateApproved == 0"
                                    class="btn cursor-default rounded-circle btn-md floatR">
                                    <i [ngClass]="{
                                                'fa':true,
                                                'fa-check-circle text-success': providerAccountPricing.afterHoursRateApproved == 1,
                                                'fa-times-circle text-danger': providerAccountPricing.afterHoursRateApproved == 0
                                                }">
                                    </i>
                                </button>
                                <div [ngClass]="{
                                    'mt-3': providerAccountPricing.afterHoursRateApproved == 1
                                    }"
                                    *ngIf="(providerAccountPricing.editMode)">
                                    <button class="btn btn-sm btn-warning" *ngIf="emergencyAfterHoursRateApproved && emergencyAfterHoursRateApproved.rateApproved === false && emergencyAfterHoursRateApproved.deleted === false" (click)="openAccountPricingException(emergencyAfterHoursRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Pending
                                    </button>
                                    <button class="btn btn-sm btn-danger" *ngIf="emergencyAfterHoursRateApproved && emergencyAfterHoursRateApproved.rateApproved === false && emergencyAfterHoursRateApproved.deleted === true" (click)="openAccountPricingException(emergencyAfterHoursRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Denied
                                    </button>
                                    <button 
                                         class="btn btn-sm btn-success" *ngIf="emergencyAfterHoursRateApproved && emergencyAfterHoursRateApproved.rateApproved === true && emergencyAfterHoursRateApproved.deleted === false" (click)="openAccountPricingException(emergencyAfterHoursRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Approved
                                    </button>
                                    <button class="btn btn-sm btn-secondary" *ngIf="isEmergencyAfterHoursRate && !emergencyAfterHoursRateApproved && providerAccountPricing.afterHoursRateApproved === 0" (click)="openAccountPricingException(emergencyAfterHoursRateApproved, providerAccountPricing, 'Emergency After-Hours Rate')" type="button">
                                        Request Custom Rate
                                    </button>
                                </div>
                            </td>
                            <td>
                                <label
                                    *ngIf="!(providerAccountPricing.editMode) || providerAccountPricing.scheduledRateApproved == 1">
                                    <span *ngIf="providerAccountPricing.scheduledRate > 0">$</span>
                                    {{providerAccountPricing.scheduledRate}} </label>
                                <mat-form-field
                                    *ngIf="providerAccountPricing.editMode && providerAccountPricing.scheduledRateApproved != 1">
                                    <input type="text" pattern="^(\d+\.)?\d+$"
                                        (keyup)="isDecimal(providerAccountPricing.scheduledRate)"
                                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47"
                                        matInput [(ngModel)]="providerAccountPricing.scheduledRate">
                                    <span matPrefix *ngIf="providerAccountPricing.scheduledRate != null">$&nbsp;</span>
                                </mat-form-field>
                                <button
                                    *ngIf="providerAccountPricing.scheduledRateApproved == 1 || providerAccountPricing.scheduledRateApproved == 0"
                                    class="btn cursor-default rounded-circle btn-md floatR">
                                    <i [ngClass]="{
                                            'fa':true,
                                            'fa-check-circle text-success': providerAccountPricing.scheduledRateApproved == 1,
                                            'fa-times-circle text-danger': providerAccountPricing.scheduledRateApproved == 0
                                            }">
                                    </i>
                                </button>
                                <div [ngClass]="{
                                    'mt-3': providerAccountPricing.scheduledRateApproved == 1
                                    }"
                                    *ngIf="(providerAccountPricing.editMode)">
                                    <button class="btn btn-sm btn-warning" *ngIf="scheduledRateApproved && scheduledRateApproved.rateApproved === false && scheduledRateApproved.deleted === false" (click)="openAccountPricingException(scheduledRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Pending
                                    </button>
                                    <button class="btn btn-sm btn-danger" *ngIf="scheduledRateApproved && scheduledRateApproved.rateApproved === false && scheduledRateApproved.deleted === true" (click)="openAccountPricingException(scheduledRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Denied
                                    </button>
                                    <button class="btn btn-sm btn-success" *ngIf="scheduledRateApproved && scheduledRateApproved.rateApproved === true && scheduledRateApproved.deleted === false" (click)="openAccountPricingException(scheduledRateApproved, providerAccountPricing, '')" type="button">
                                        Custom Approved
                                    </button>
                                    <button class="btn btn-sm btn-secondary" *ngIf="isScheduleRate && !scheduledRateApproved && providerAccountPricing.scheduledRateApproved === 0" (click)="openAccountPricingException(scheduledRateApproved, providerAccountPricing, 'Scheduled Rate')" type="button">
                                        Request Custom Rate
                                    </button>
                                </div>
                            </td>
                            <td *ngIf="isDisallowed">
                        
                                    <button *ngIf="!(providerAccountPricing.editMode)" class="btn btn-default btnAnimation"
                                        (click)="editRates(providerAccountPricing)" nbTooltip="Edit"
                                        [disabled]="disableEdit">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                   
                                <button *ngIf="providerAccountPricing.editMode" [disabled]="InvalidRate"
                                    class="btn btn-default btnAnimation" (click)="saveRates(providerAccountPricing)"
                                    nbTooltip="Save">
                                    <i class="fas fa-check"></i>
                                </button>
                                <button *ngIf="providerAccountPricing.editMode" class="btn btn-default btnAnimation"
                                    (click)="cancelRates(providerAccountPricing)" nbTooltip="Cancel">
                                    <i class="fas fa-times"></i>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="arrProviderAccountPricing.length == 0" class="active">
                            <td *ngIf="!afterHoursRatesAllowed" colspan="5"> Line items not found</td>
                            <td *ngIf="afterHoursRatesAllowed" colspan="6">Line items not found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </nb-card-body>
    </nb-card>
    
    <div class="text-right">
        <button mat-button *ngIf="showBack" class="btn btn-primary btn-sm p-2 mr-1">Back</button>
        <button mat-button *ngIf="showNext" class="btn btn-primary btn-sm p-2 ml-1">Next</button>
    </div> -->
