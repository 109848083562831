import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AddUserIntoInviteProcessComponent } from '../user-invite-process/add-user-into-invite-process/add-user-into-invite-process.component';
import { UploadFileModelerComponent } from '../user-invite-process/upload-file-modeler/upload-file-modeler.component';
import { CommunicationTemplatesComponent } from '../user-invite-process/communication-templates/communication-templates.component';
import { UserInviteHistoryComponent } from '../user-invite-process/user-invite-history/user-invite-history.component';
import { CommonService } from 'src/app/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserInviteProcessComponent } from '../user-invite-process/user-invite-process.component';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { forkJoin } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-invite-process',
  templateUrl: './customer-invite-process.component.html',
  styleUrls: ['./customer-invite-process.component.scss']
})
export class CustomerInviteProcessComponent implements OnInit {

  @ViewChild(AddUserIntoInviteProcessComponent, { static: true }) addUserIntoInviteProcess: AddUserIntoInviteProcessComponent;
  @ViewChild(UploadFileModelerComponent, { static: true }) uploadFileModeler: UploadFileModelerComponent;
  @ViewChild(CommunicationTemplatesComponent, { static: true }) communicationTemplate: CommunicationTemplatesComponent;
  @ViewChild(UserInviteHistoryComponent, { static: true }) inviteHistory: UserInviteHistoryComponent;

  loading = false;
  selectedIndex = 0;
  securityGroups: any = [];
  departmentList: any = [];
  rolesList: any = [];
  userInvitesList: any = [];
  userInviteSentList: any = [];
  isSelected = false;
  userTypeKey = '90dc6b51-9731-439b-bd21-40685f989f24';
  templateKey = 'db44c78e-e5f1-467e-a5c4-1731c265d6e1';
  selectedCustomerKey: string;
  isLicensedOwner = false;
  customers: any = [];
  customerName: string;

  constructor(
    private commonService: CommonService,
    public dialog: MatDialogRef<UserInviteProcessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userInviteProcess: UserInviteProcess,
    private customerService: CustomerService) {
  }

  ngOnInit() {
    if (!sessionStorage.getItem('customerKey') && !this.data.customerInputKey) {
      this.isLicensedOwner = true;
    } else {
      this.isLicensedOwner = false;
      this.selectedCustomerKey = sessionStorage.getItem('customerKey') || this.data.customerInputKey;
      this.getUserInvitations();
    }
    this.initDropdowns();
  }

  initDropdowns() {
    const securityFilter = {
      platform: 'FleetAssist',
      userTypeKey: this.userTypeKey,
      deleted: false,
    };
    const roleFilter = {
      deleted: false,
    };
    const deptFilter = {
      deleted: false,
    };
    const securityGrpReq = this.userInviteProcess.getSecurityGroups(securityFilter);
    const userRolesReq = this.commonService.getContactCenterUserRoles(roleFilter);
    const deptReq = this.commonService.getDepartments(deptFilter);
    const customerReq = this.customerService.getCustomerDetails();

    this.loading = true;
    forkJoin([securityGrpReq, userRolesReq, deptReq, customerReq]).subscribe(result => {
      this.loading = false;
      if (sessionStorage.getItem('subDomainKey') == 'breakdownnow') {
        result[0].body.forEach(element => {
          if (element.securityGroupKey == sessionStorage.getItem('securityGroupKey')) {
            if (element.systemDefault == false && element.userTypeKey == '90dc6b51-9731-439b-bd21-40685f989f24') {
              this.securityGroups.push(element);
            } else if (element.systemDefault == true && element.userTypeKey == '90dc6b51-9731-439b-bd21-40685f989f24') {
              this.securityGroups.push(element);
            }
          }

        });
      } else {
        this.securityGroups = result[0].body;
      }
      // this.securityGroups = result[0].body || [];
      this.rolesList = result[1].body || [];
      this.departmentList = result[2].body || [];
      this.customers = result[3].body || [];
    }, (error) => {
      this.loading = false;
    });
  }

  getUserInvitations() {
    this.isSelected = false;
    this.userInvitesList = [];
    this.loading = true;
    const inviteFilter = {
      userKey: sessionStorage.getItem('userKey'),
      isEmailSent: false,
      userTypeKey: this.userTypeKey,
      associationKey: this.selectedCustomerKey
    };
    const historyFilter = {
      userKey: sessionStorage.getItem('userKey'),
      isEmailSent: true,
      userTypeKey: this.userTypeKey,
      associationKey: this.selectedCustomerKey
    };
    const inviteReq = this.userInviteProcess.getUserInvitations(inviteFilter);
    const inviteHistoryReq = this.userInviteProcess.getUserInvitations(historyFilter);

    this.loading = true;
    forkJoin([inviteReq, inviteHistoryReq]).subscribe(result => {
      this.loading = false;
      this.userInvitesList = result[0].body || [];
      this.userInviteSentList = result[1].body || [];
    }, (error) => {
      this.loading = false;
    });
  }

  sendInvitations() {
    if (this.selectedIndex === 0 || this.selectedIndex === 1) {
      const userInvitationKeys = [];
      this.userInvitesList.map(obj => {
        if (obj.userInvitationKey) {
          userInvitationKeys.push(obj.userInvitationKey);
        }
      });
      this.communicationTemplate.sendInvitations(userInvitationKeys);
    }
  }

  userListChanged(event) {
    if (event === true) {
      this.getUserInvitations();
    }
  }

  sendInviteWithEditedTemplate() {
    if (this.isSelected === true) {
      this.reSendInvitations();
    } else {
      const userInvitationKeys = [];
      this.userInvitesList.map(obj => {
        if (obj.userInvitationKey) {
          userInvitationKeys.push(obj.userInvitationKey);
        }
      });
      this.communicationTemplate.sendInvitations(userInvitationKeys);
    }
  }

  reSendInvitations() {
    const isValid = this.communicationTemplate.isEmailParamsValid();
    if (isValid) {
      this.inviteHistory.resendInvitations();
    }
  }

  tabChanged(event: any) {
    this.selectedIndex = event.index;
  }

  viewTemplate() {
    this.selectedIndex = 2;
  }

  selecionChanged(status) {
    this.isSelected = status;
  }

  selectedInvitesChanged(userInvitationKeys) {
    this.communicationTemplate.sendInvitations(userInvitationKeys);
  }

  closeDialog() {
    this.dialog.close();
  }

  getCustomerKey(event) {
    this.customerName = '';
    this.selectedCustomerKey = event.customerKey;
    this.customerName = event.customerName;
    this.getUserInvitations();
  }

}
