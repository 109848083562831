<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title class="dialog-title">
        <h2>Add Service Provider</h2>
        <button type="button" class="close" nbTooltip="close" nbTooltipPlacement="top" mat-dialog-close>&times;</button>
    </div>

    <form [formGroup]="providerProfileForm">
        <!-- <div class="row mb-3" *ngIf="!parent">
            <div class="col-12 offset-md-1 col-md-10 map-box text-center">
                <app-map [latitude]="latitude" [longitude]="longitude"></app-map>
            </div>
        </div> -->
        <div class="row" *ngIf="parent">

            <div class="col-12 col-md-12">
                <mat-form-field>
                    <mat-label>Service Provider location</mat-label>
                    <input matInput #location ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
                    formControlName="splocation" />
                    </mat-form-field>
            </div>


            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-label>Company Name</mat-label>
                    <input type="text" matInput formControlName="companyName" placeholder="Company Name" required>
                    <mat-error *ngIf="handleError('companyName', 'required')">
                        Company Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-label>First Name</mat-label>
                    <input type="text" matInput formControlName="ownerFirstName" placeholder="Primary Contact First Name" required>
                    <mat-error *ngIf="handleError('ownerFirstName', 'required')">
                        Primary Contact First Name is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('ownerFirstName', 'pattern')">
                        Primary Contact First name must contain alphabets and must not begin with space.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-label>Last Name</mat-label>
                    <input type="text" matInput formControlName="ownerLastName" placeholder="Primary Contact Last Name" required>
                    <mat-error *ngIf="handleError('ownerLastName', 'required')">
                        Primary Contact Last Name is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('ownerLastName', 'pattern')">
                        Primary Contact Last name must contain alphabets and must not begin with space.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="!parent">
            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-label>Location Name</mat-label>
                    <input type="text" matInput formControlName="name" placeholder="Location Name" required>
                    <mat-error *ngIf="handleError('name', 'required')">
                        Location Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-3 col-md-4">
                <mat-form-field>
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phoneNumber" [placeholder]="addrLableText + ' Phone Number'"  (ngModelChange)="onChange($event)" >
                    <!-- <mat-error *ngIf="handleError('phoneNumber', 'required')">
                        {{addrLableText}} Phone Number is required.
                    </mat-error> -->

                    <mat-error *ngIf="providerProfileForm.get('phoneNumber').hasError('required')">
                        {{addrLableText}} Phone Number is required.
                    </mat-error>
                
                    <mat-error *ngIf="providerProfileForm.get('phoneNumber').hasError('pattern')">
                        {{addrLableText}} Phone Number must follow this pattern <b><u><i>+12145551212</i></u></b> !
                    </mat-error>


                </mat-form-field>
            </div>
            <div class="col-3 col-md-4">
                <mat-form-field>
                    <mat-label>Address 1</mat-label>
                    <input type="text" matInput formControlName="address1" [placeholder]="addrLableText + ' Address 1'"
                        ngx-google-places-autocomplete (onAddressChange)="getDetailedAddress($event)" required>
                    <mat-error *ngIf="handleError('address1', 'required')">
                        {{addrLableText}} Address 1 is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-3 col-md-4">
                <mat-form-field>
                    <mat-label>Address 2</mat-label>
                    <input type="text" matInput formControlName="address2" [placeholder]="addrLableText + ' Address 2'">
                </mat-form-field>
            </div>
            <div class="col-3 col-md-4">
                <mat-form-field>
                    <mat-label>County</mat-label>
                    <input type="text" matInput formControlName="address3" [placeholder]="addrLableText + ' County'" required>
                    <mat-error *ngIf="handleError('address3', 'required')">
                        {{addrLableText}} County is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-3 col-md-4">
                <mat-form-field>
                    <mat-label>Email Address</mat-label>
                    <input type="text" matInput formControlName="emailAddress" [placeholder]="addrLableText + ' Email'" required>
                    <mat-error *ngIf="handleError('emailAddress', 'required')">
                        {{addrLableText}} Email is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4" [ngClass]="{'col-md-3': !parent}">
                <mat-form-field>
                    <mat-label>City</mat-label>
                    <input type="text" matInput formControlName="city" [placeholder]="addrLableText + ' City'" required>
                    <mat-error *ngIf="handleError('city', 'required')">
                        {{addrLableText}} City is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4" [ngClass]="{'col-md-3' : !parent}">
                <mat-form-field>
                    <mat-label>State</mat-label>
                    <mat-select matNativeControl formControlName="state" [placeholder]="addrLableText + ' State/Province'"
                        required>
                        <mat-option>
                            <ngx-mat-select-search [(ngModel)]="searchState" [placeholderLabel]="'Search State/Province'"
                                [noEntriesFoundLabel]="'Not found'" name="search state"
                                [ngModelOptions]="{standalone: true}">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let state of bdStates | filtertext: searchState : 'stateName'" [value]="state.stateKey">
                            {{state.stateName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('state', 'required')">
                        {{addrLableText}} state/Providence is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4" [ngClass]="{'col-md-3' : !parent}">
                <mat-form-field>
                    <mat-label>Zipcode</mat-label>
                    <input type="text" matInput formControlName="postalCode" [placeholder]="addrLableText + ' Postal Code'"
                        required>
                    <mat-error *ngIf="handleError('postalCode', 'required')">
                        {{addrLableText}} Postal Code is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('postalCode', 'pattern')">
                        Enter valid {{addrLableText}} Postal Code.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4" [ngClass]="{'col-md-3' : !parent}">
                <mat-form-field>
                    <mat-label>Country</mat-label>
                    <input type="text" matInput formControlName="country" [placeholder]="addrLableText + ' Country'"
                        required>
                    <mat-error *ngIf="handleError('country', 'required')">
                        {{addrLableText}} Country is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('country', 'pattern')">
                        Enter valid {{addrLableText}} Postal Code.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-4" [ngClass]="{'col-md-3' : !parent}">
                <mat-form-field>
                    <mat-label>Supervisor</mat-label>
                    <mat-select matNativeControl formControlName="supervisorTSLKey" [placeholder]="addrLableText + ' supervisor Name'">
                        <mat-option>
                            <ngx-mat-select-search [(ngModel)]="searchSupervisor" [placeholderLabel]="'supervisor Name'"
                                [noEntriesFoundLabel]="'Not found'" name="search Supervisor"
                                [ngModelOptions]="{standalone: true}">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let supervisor of supervisors | filtertext: searchSupervisor : 'TSLvc255Name'" [value]="supervisor.TSLlKey">
                            {{supervisor.TSLvc255Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
 
            <div class="col-12 col-md-12" *ngIf="!parent">
                <label class="mt-0"> Does this location provide the same: </label>
                <mat-checkbox class="ml-2" formControlName="performsSameService">
                    Services?
                </mat-checkbox>
                <mat-checkbox class="ml-2" formControlName="allLocationsSameHours">
                    Hours?
                </mat-checkbox>
                <mat-checkbox class="ml-2" formControlName="isSameCapabilities">
                    Capabilities?
                </mat-checkbox>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-raised-button class="btn btn-semi-round w-40 mb-2 pull-right btn-success" (click)="addRequest()">
                Save
            </button>
            <button #btnClose mat-raised-button class="btn btn-semi-round w-40 mb-2 pull-right btn-success"
                (click)="onNoClick()">
                Close
            </button>
        </div>
    </form>
</div>    