import { Component, Inject, OnInit, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakDownProvidersService } from '../../services/breakdown-providers.service';
import { BreakdownProvider } from '../../models/breakdown-providers.model';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef, MatDialogContent,} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeleteApplicationDialogComponent } from '../delete-application-dialog/delete-application-dialog.component';
import { MatchApplicationConfirmDialogComponent } from '../match-application-confirm-dialog/match-application-confirm-dialog.component';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'ngx-match-application-dialog',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatRadioModule, MatDialogModule, MatButtonModule, FormsModule, MatSnackBarModule
  ],
  templateUrl: './match-application-dialog.component.html',
  styleUrls: ['./match-application-dialog.component.scss']
})
export class MatchApplicationDialogComponent implements OnInit {
  displayedColumns1: string[] = ['select', 'name', 'address', 'phone'];
  dataSource1 = [];

  displayedColumns2: string[] = ['select', 'name', 'address', 'phone'];
  dataSource2 = [];

  selectedLocation: string;
  selectedParent: string;
  selectedValue: string;
  isParentLocation: boolean = true; 
  isUpdatedLocation: boolean = false; 
  parentKeyValue: string;
  matchingLocationKeyValue: string;

  constructor(
    private breakDownProvidersService: BreakDownProvidersService,
    public dialogRef: MatDialogRef<MatchApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BreakdownProvider,
    public dialog: MatDialog,
    private providerAdminService : ProviderAdminService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getMatchingProviders();
    this.getMatchingProviderParent();
  }

  getMatchingProviders(): void {
    const input = {
      providerID: this.data.providerPK,
      fuzzyNameSearch: 0,
    };

    console.log(input)

    this.breakDownProvidersService.findMatchingProvider(input)
      .subscribe((providers) => {
        this.dataSource1 = providers[0]
      })
  }

  getMatchingProviderParent(): void {
    const input = {
      providerID: this.data.providerPK,
      fuzzyNameSearch: 0,
    };

    this.breakDownProvidersService.findMatchingProviderParent(input)
      .subscribe((providers) => {
        this.dataSource2 = providers[0]
      })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  log() {
    //console.log(this.selectedLocation)
  }

  onChangeLocation(event: any) {
    this.selectedValue = event.value;
    this.matchingLocationKeyValue = event.value.providerKey;
    this.isUpdatedLocation = true;
  }

  onChangeParent(event: any) {
    this.selectedValue = event.value;
    this.parentKeyValue = event.value.providerKey;
    this.isParentLocation = false;
  }


  deleteApplication(provider): void {
    let dialogRef = this.dialog.open(DeleteApplicationDialogComponent, {
      data: provider,
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     // this.dialogRef.close();
    });
  }

  matchApplication(provider): void{
    let dialogRef = this.dialog.open(MatchApplicationConfirmDialogComponent, {
      data: provider
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      console.log('The dialog was closed');
      if(result == true){
        this.matchApproved()
      }
    });
  }

  matchApproved(){

    if(this.isParentLocation == true && !this.isUpdatedLocation){
      this.providerAdminService.insertProviderParentApplicationMatch(this.data.providerPK).subscribe({
        next: res => this.openSnackBar('Provider successfully added as a parent location.'),
        error: (e) => {console.error(e),  this.openSnackBar('Unable to add provider.')},
        complete: () => this.deleteApplicationFromList() });
    }

    const data ={
      providerKey : this.data.providerPK,
      parentKey : this.parentKeyValue,
    }

    if(this.isParentLocation == false){
      this.providerAdminService.insertProviderLocationApplicationMatch(data).subscribe({
        next: res => this.openSnackBar('Provider successfully added as a location.'),
        error: (e) => {console.error(e),  this.openSnackBar('Unable to add provider.')},
        complete: () => this.deleteApplicationFromList() });
    }

    const updateLocationData = {
      providerKey : this.data.providerPK,
      matchingProviderKey : this.matchingLocationKeyValue,
    }

    if(this.isUpdatedLocation == true){
      this.providerAdminService.updateProviderMembershipApplicationMatch(updateLocationData).subscribe({
        next: res => this.openSnackBar('Provider successfully updated.'),
        error: (e) => {console.error(e),  this.openSnackBar('Unable to update provider.')},
        complete: () => this.deleteApplicationFromList() });
    }

    this.dialogRef.close();

  }

  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }

  deleteApplicationFromList(){
    //Needs updating to be used.
    // this.providerAdminService.deleteProviderApplicationMatch(this.data.providerPK).subscribe({
    //   next: res => this.openSnackBar('Provider successfully deleted from this listing after completion.'),
    //   error: (e) => {console.error(e),  this.openSnackBar('Failure to delete added application from list.')},
    //   complete: () => console.log('fully completed the application process.') });

    // this.dialogRef.close();

    console.log("the delete application from list needs to be updated to work.")
    
  }
  

}