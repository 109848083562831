import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { SystemSecuruityMenuService } from 'src/app/services/system-securuity-menu.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-add-security-menu-dialog',
  templateUrl: './add-security-menu-dialog.component.html',
  styleUrls: ['./add-security-menu-dialog.component.scss']
})
export class AddSecurityMenuDialogComponent implements OnInit {

  securityMenuForm: UntypedFormGroup;
  menuNode: any = [];
  disableFlag: boolean;
  userKey: string;
  loading: boolean;
  resultMenu: any[];
  responseMenu: any[];
  arrDisplayOrder: any = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastrService: NbToastrService,
    private systemSecurityMenuService: SystemSecuruityMenuService,
    public dialogRef: MatDialogRef<AddSecurityMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.userKey = sessionStorage.getItem('userKey');
    this.securityMenuForm = this.formBuilder.group({
      menuName: [{ value: '', disabled: this.disableFlag }, [Validators.required, Validators.pattern(patterns.textPattern)]],
      displayOrder: [''],
    });
    if (this.data.event === 'edit') {
      this.setMenuName();
    }
  }
  setMenuName() {
    this.securityMenuForm.setValue({
      menuName: this.data.node.name,
      displayOrder: this.data.node.displayOrder
    });
  }

  cancelClick(): void {
    this.dialogRef.close({ event: 'Close' });
  }

  addEditMenu() {
    this.loading = true;

    const menuData = {
      label: this.securityMenuForm.controls.menuName.value,
      parentId: this.data.node.key,
      deleted: false
    };
    this.systemSecurityMenuService.getSystemSecurityMenu(menuData).subscribe(response => {
      if (response.body.length > 0) {
        this.loading = false;
        this.toastrService.warning('Menu already present.', 'warning');
      } else {
        if (this.data.event === 'add') {
          const description = this.buildRoleDescription();
          // Insert into Security Roles table
          const objeInsertSecurityRolesData = {
            parentId: this.data.node.key,
            roleDescription: description,
            insertedUserKey: sessionStorage.getItem('userKey'),
            updatedUserKey: '',
            deleted: false,
            systemDefault: false,
          };
          this.systemSecurityMenuService.addSecurityRoles(objeInsertSecurityRolesData).subscribe((roleResult: any) => {
            // Insert into Security Role policies table
            this.addIntoSecurityRolePoliciesTable(roleResult.body[0].securityRoleKey, description);
            this.addIntoSystemSecurityMenu(roleResult.body[0].securityRoleKey);
          }, (error) => {
            console.log('error', error);
          });
        } else {
          this.checkSecurityRolePresent();
        }
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  addIntoSystemSecurityMenu(securityRoleKey: string) {
    const objInsertData = {
      deleted: false,
      label: this.securityMenuForm.controls.menuName.value,
      displayOrder: this.securityMenuForm.controls.displayOrder.value,
      securityRoleKey,
      insertedUserKey: sessionStorage.getItem('userKey'),
      parentId: this.data.node.key,
      systemDefault: true,
      ui: this.data.node.ui,
      updatedUserKey: ''
    };
    this.systemSecurityMenuService.addSystemSecurityMenu(objInsertData).subscribe((result: any) => {
      this.toastrService.success('Menu Added successfully!.', 'Success');
      this.dialogRef.close({ event: 'event' });
    }, (error) => {
      this.loading = false;
      this.toastrService.danger('Oops! Something went wrong.', 'Danger');
    });
  }

  updateMenu(secRoleKey) {
    const objUpdateParams = [{
      dynamicMenuKey: this.data.node.key,
      displayOrder: this.securityMenuForm.controls.displayOrder.value,
      label: this.securityMenuForm.controls.menuName.value,
      updatedUserKey: sessionStorage.getItem('userKey'),
      securityRoleKey: secRoleKey
    }];

    this.systemSecurityMenuService.updateSystemSecurityMenu(objUpdateParams).subscribe(result => {

    }, (error) => {
      console.log(error);
      this.toastrService.danger('Oops! Something went wrong.', 'Error');
      this.loading = false;
    });
  }

  checkSecurityRolePresent() {
    const secRoleKey = this.getSecurityRolekey();
    if (secRoleKey) {
      this.updateMenu(secRoleKey);
      this.updateSecurityRoleDesciption(secRoleKey);
    } else {
      const description = this.buildRoleDescription();
      const objeInsertSecurityRolesData = {
        parentId: this.data.node.key,
        roleDescription: description,
        insertedUserKey: sessionStorage.getItem('userKey'),
        updatedUserKey: '',
        deleted: false,
        systemDefault: false,
      };

      this.systemSecurityMenuService.addSecurityRoles(objeInsertSecurityRolesData).subscribe((roleResult: any) => {
        if (roleResult.success && roleResult.body.length > 0) {
          this.updateMenu(roleResult.body[0].securityRoleKey);
          this.updateSecurityRoleDesciption(roleResult.body[0].securityRoleKey);
        } else {
          this.toastrService.danger('Could not create security role', 'Error');
          return false;
        }
      });
    }
  }

  updateSecurityRoleDesciption(secRoleKey) {
    const description = this.buildRoleDescription();
    const updateRoleDescription = {
      securityRoleKey: secRoleKey,
      roleDescription: description,
      updatedUserKey: sessionStorage.getItem('userKey')
    };

    this.systemSecurityMenuService.updateSecurityRoles(updateRoleDescription).subscribe(result => {
      if (result.success) {
        this.updateSecurityRolePolicyDescription(updateRoleDescription);
      } else {
        this.toastrService.danger('Could not update security role', 'Error');
        this.loading = false;
      }
    }, (error) => {
      console.log(error);
      this.toastrService.danger('Oops! Something went wrong.', 'Error');
      this.loading = false;
    });
  }


  updateSecurityRolePolicyDescription(roleData) {
    const objData = {
      deleted: false,
      securityRoleKey: roleData.securityRoleKey,
    };

    this.systemSecurityMenuService.getSecurityRolePolicies(objData).subscribe((policesResult) => {
      const arrUpdateSecurityRolesPoliciesData = [];
      if (policesResult.body.length > 0) {
        policesResult.body.map((value) => {
          arrUpdateSecurityRolesPoliciesData.push({
            securityRolePolicyKey: value.securityRolePolicyKey,
            securityRoleDescription: roleData.roleDescription,
            updatedUserKey: sessionStorage.getItem('userKey'),
          });
        });

        if (arrUpdateSecurityRolesPoliciesData.length > 0) {
          this.systemSecurityMenuService.batchUpdateSecurityRolePolicies(arrUpdateSecurityRolesPoliciesData).subscribe((result: any) => {
            this.toastrService.success('Menu Edited successfully!.', 'Success');
            this.loading = false;
            this.dialogRef.close({ event: 'event' });
          }, (error) => {
            console.log('error', error);
            this.loading = false;
          });
        }
      } else {
        this.addIntoSecurityRolePoliciesTable(roleData.securityRoleKey, roleData.roleDescription);
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }


  getSecurityRolekey(): string {
    return this.data.menuData.find(d => d.dynamicMenuKey == this.data.node.key).securityRoleKey;
  }

  buildRoleDescription(): string {
    const arrMenuString = [];
    let isParentAvailable = !!this.data.node.parentKey;
    let parentKey = this.data.node.parentKey;
    arrMenuString.push(this.securityMenuForm.controls.menuName.value);
    while (isParentAvailable) {
      const parent = this.data.menuData.find(d => d.dynamicMenuKey == parentKey);
      if (parent) {
        isParentAvailable = !!parent.parentId;
        parentKey = parent.parentId;
        arrMenuString.push(parent.label);
      } else {
        isParentAvailable = false;
      }
    }

    return arrMenuString.reverse().join(' - ');
  }

  addIntoSecurityRolePoliciesTable(roleKey: any, description: string) {
    const objData = {
      deleted: false
    };
    this.systemSecurityMenuService.getSecurityPolicies(objData).subscribe((policesResult) => {
      if (policesResult.body.length > 0) {
        const objInsertSecurityRolesPoliciesData = [];
        policesResult.body.map((value) => {
          objInsertSecurityRolesPoliciesData.push({
            securityRoleKey: roleKey,
            securityRoleDescription: description,
            securityPolicyKey: value.securityPolicyKey,
            securityPolicyDescription: value.description,
            insertedUserKey: sessionStorage.getItem('userKey'),
            updatedUserKey: '',
            deleted: false,
            systemDefault: false
          });
        });

        if (objInsertSecurityRolesPoliciesData.length > 0) {
          this.systemSecurityMenuService.batchAddSecurityRolePolicies(objInsertSecurityRolesPoliciesData).subscribe((result: any) => {
            if (this.data.event == 'edit') {
              this.toastrService.success('Menu updated successfully!.', 'Success');
              this.dialogRef.close({ event: 'event' });
            }
          }, (error) => {
            console.log('error', error);
          });
        }
      }
    }, (error) => {
    });
  }
}
