import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RatesService {
  pricingTypes: Observable<any>;
  lineItems: Observable<any>;
  arrForRateGroup: any = [];
  public rateTypeGroupList = new Subject<any>();
  public resolveRates = new Subject<any>();

  constructor(private http: HttpClient) { }

  setResolveRates() {
    this.resolveRates.next();
  }


  getLicensedownerOfferingsLineItemAssociations(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerofferingslineitemassociations/get`, data);
  }

  getProviderAccountsPricingCount(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}getProviderRatesApprovedCount`, arrReqData);
  }

  saveBatchProviderAccountPricing(arrSaveRequest): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/provideraccountpricing/batch`, arrSaveRequest);
  }

  
  getRatesList(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerspecializedpricingregions/get`, filter);
  }

  getRateRangeList(data): Observable<any> {
    return this.http.get(`${environment.baseUrl}getRateRange/${data}`);
  }

  updateRates(updateData) {
    return this.http.put(`${environment.apiUrl}fleetassist/licensedownerspecializedpricingregions`, updateData);
  }

  getRegionList(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/specializedpricingregions/get`, filter);
  }

  createRegion(insertData: any) {
    return this.http.post(`${environment.apiUrl}fleetassist/specializedpricingregions`, insertData);
  }

  updateRegion(updateData: any) {
    return this.http.put(`${environment.apiUrl}fleetassist/specializedpricingregions`, updateData);
  }

  getCustomRegion(filter: any = {}) {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customregions/get`, filter);
  }

  insertCustomRegionDetails(insertData: any) {
    return this.http.post(`${environment.apiUrl}fleetassist/customregions`, insertData);
  }

  updateCustomRegionDetails(updateData: any) {
    return this.http.put(`${environment.apiUrl}fleetassist/customregions`, updateData);
  }

  getPricingTypes(filter: any = {}): Observable<any> {
    if (!this.pricingTypes) {
      if (filter.deleted === undefined) {
        filter.deleted = false;
      }
      this.pricingTypes = this.http.post(`${environment.apiUrl}keyvaluepairs/lineitempricingtypes/get`, filter).pipe(
        map(res => res),
        publishReplay(1),
        refCount()
      );
    }
    return this.pricingTypes;
  }

  getLineItems(filter: any = {}): Observable<any> {
    if (!this.lineItems) {
      if (filter.deleted === undefined) {
        filter.deleted = false;
      }
      this.lineItems = this.http.post(`${environment.apiUrl}keyvaluepairs/lineitems/get`, filter).pipe(
        map(res => res),
        publishReplay(1),
        refCount()
      );
    }
    return this.lineItems;
  }

  addNewRate(insertData: any) {
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerspecializedpricingregions`, insertData);
  }

  updateServiceData(updateData) {
    return this.http.put(`${environment.apiUrl}fleetassist/licensedownerofferings`, updateData);
  }

  getServiceList(filter: any = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerofferings/get`, filter);
  }

  getLicensedOwnerLineItemTypeGroups(data): Observable<any> {
    return this.http.get(`${environment.baseUrl}getLineItemTypeGroups/${data}`);
  }

  getLineitemTypeGroupData(): Observable<any> {
    return this.rateTypeGroupList.asObservable();
  }

  checkForExistingGroup(data: any = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerlineitemtypegroups/get`, data);
  }

  deleteLicensedOwnerLineItemTypeGroup(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/licensedownerlineitemtypegroups`, data);
  }

  addLicensedOwnerLineItemTypeGroups(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerlineitemtypegroups/batch`, data);
  }

  getLicensedOwnerTypeGroupOptions(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerlineitemtypegroupnames/get`, data);
  }

  addtLicensedOwnerTypeGroupName(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerlineitemtypegroupnames`, data);
  }

  getLicensedOwnerLineItems(data): Observable<any> {
    return this.http.post(`${environment.baseUrl}getLineItemBasedOnOffering`, data);
  }

  setServiceForLineItem(deleted, key) {
    if (deleted === 0) {
      this.arrForRateGroup.push(key);
    } else {
      const index = this.arrForRateGroup.indexOf(key);
      if (index >= 0) {
        this.arrForRateGroup.splice(index, 1);
      }
    }
  }

  getServiceForLineItem() {
    return this.arrForRateGroup;
  }

  removeServiceForLineItem() {
    this.arrForRateGroup = [];
  }

  getProviderPricingDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/provideraccountpricing/get`, data);
  }

  getProviderAccountPricing(arrProviderAccountPricingFilters): Observable<any> {
    return this.http.post(`${environment.baseUrl}getProviderAccountPricing`, arrProviderAccountPricingFilters);
  }

  getLineItemPricingTypes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}keyvaluepairs/lineitempricingtypes/get`, data);
  }

  getProviderDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providers/get`, data);
  }

  putProviderAccountPricing(arrPutRequest): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/provideraccountpricing`, arrPutRequest);
  }

  getPaymentTerms(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/paymentterms/get`, data);
  }

  getLicensedOwnerSpecializedPricingRegions(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerspecializedpricingregions/get`, data);
  }

  getProviderAccounts(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/provideraccounts/get`, data);
  }

  setRateTypeGroupList() {
    this.rateTypeGroupList.next();
  }

  getProviderAccountPricingException(providerAccountPricingExceptionFilter): Observable<any> {
    return this.http.post(`${environment.baseUrl}getProviderRatesApproved`, providerAccountPricingExceptionFilter);
  }

  addProviderAccountPricingException(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/provideraccountpricingexceptions`, data);
  }

  updateProviderAccountPricingException(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/provideraccountpricingexceptions`, data);
  }

  getProviderDefaultAccountKey(data: any = {}) {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts/get`, data);
  }

  getLicensedOwnerDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedowners/get`, data);
  }

  addProviderAccountNotes(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, data);
  }

  addDefaultProvideraccounts(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts`, data);
  }

  updateProviderAccounts(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/provideraccounts`, data);
  }

  setDefaultLineItemsPricingRegions(licensedOwnerKey): Observable<any> {
    return this.http.get(`${environment.baseUrl}setupDefaultLineItemsPricingRegions/${licensedOwnerKey}`);
  }

  getLicensedOwnerLineItemTypeGroup(data): Observable<any> {
    return this.http.post(`${environment.baseUrl}getLicensedOwnerLineItemTypeGroup`, data);
  }

  getLicensedownerLineItemTypeGroupsList(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerlineitemtypegroups/get`, data);
  }

  saveProviderAccountPricing(arrSaveRequest): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/provideraccountpricing`, arrSaveRequest);
  }

  updateProviderPricingLineItems(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/provideraccountpricing/batch`, data);
  }

  getLicensedOwnerRegions(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/specializedpricingregions/get`, filter);
  }
}
