<div *ngFor="let levelData of levelData; let i = index;" class="tree-root tree-child tree-node">
    <div class="line-to"></div>
    <div class="tree-element-container tree-element-has-children" [nbTooltip]="levelData.name">

        <ng-container *ngIf="!offeringQuestions; else offeringQuestionsServices">
            <ng-container *ngIf="isServiceProtocol; else otherServices">
                <div class="tree-element-main" [dndDraggable]="levelData" [dndEffectAllowed]="draggable.effectAllowed"
                    [dndDisableIf]="draggable.disable" dndDropzone (dndDrop)="onDrop($event, levelData)">
                    <div (click)="showNetwork(levelData)" class="rect"
                        [ngClass]="{'selected': levelData.systemDefault == 1 && levelData.deleted == 0 && levelData.isProtocol == 1, 'deselected': levelData.systemDefault == 0 && levelData.deleted == 0}">
                        <span *ngIf="levelData.isAllParentActive == 1 && isAllParentActive == 1"
                            class="selection-icon-wrapper" (click)="activateInactivate(levelData.deleted, levelData)">
                            <i *ngIf="showSelectDeselect" [title]="levelData.deleted == 1 ? 'Select' : 'Deselect'"
                                class="fad fa-lg selection-icon" [ngClass]="{
                        'selected': levelData.deleted == 1, 
                        'deselected': levelData.deleted == 0,
                        'fa-check-circle': levelData.deleted == 1,
                        'fa-window-close': levelData.deleted == 0
                    }"></i>
                        </span>
                        <span *ngIf="showProtocol" class="context-menu selection-icon-wrapper" mat-icon-button
                            aria-label="Action">
                            <span
                                [ngClass]="{'protocolBox': levelData.isChildProtocol == 1, 'blankBox': !levelData.isChildProtocol && levelData.isProtocol == 0 || levelData.isCustomProtocol}"></span>
                            <mat-icon class="protocol"
                                *ngIf="!levelData.isChildProtocol && levelData.isProtocol && !levelData.isCustomProtocol">
                                stars
                            </mat-icon>
                            <span *ngIf="!levelData.isChildProtocol && !isCustomerServiceProtocol" class="context-menu"
                                mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                                <mat-icon>menu</mat-icon>
                            </span>
                            <mat-menu #menu="matMenu">
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && !levelData.isChildProtocol && !levelData.isProtocol || levelData.isCustomProtocol"
                                    (click)="addProtocol(levelData)">
                                    <mat-icon><i class="fas fa-plus menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Add Protocol</span>
                                </span>
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && levelData.isProtocol && !levelData.isCustomProtocol"
                                    (click)="viewProtocols(levelData)">
                                    <mat-icon><i class="fas fa-file-excel menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">View Protocol</span>
                                </span>
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && levelData.isProtocol && !levelData.isCustomProtocol"
                                    (click)="deleteProtocols(levelData)">
                                    <mat-icon><i class="fas fa-trash-alt menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Delete Protocol</span>
                                </span>
                            </mat-menu>
                        </span>
                        <div class="buttons" *ngIf="levelData.deleted == 0">
                            <div class="delete button-border" (click)="deleteNode(levelData)"
                                *ngIf="levelData.deleted == 1 && delNode && isActiveNode"></div>
                            <div class="edit button-border" (click)="addEditNetwork(levelData,1)"
                                *ngIf="levelData.deleted == 1 && editNode && isActiveNode">
                                <i class="fas fa-pencil-alt"></i>
                            </div>
                            <div *ngIf="!(level == lastNode) && levelData.deleted == 0 && !levelData.isProtocol"
                                class="button-border"
                                [ngClass]="{'toggler' : levelData.children != null && levelData.children[0] != undefined}">
                            </div>
                        </div>
                        <div class="classContent">
                            <span class="ic mt-3">
                                <i [attr.class]="levelData.icon"></i>
                            </span>
                            <br>
                            <span class="divText">{{ levelData.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="tree-children"
                    *ngIf="levelData.key == levelKey && levelData.deleted == 0 && !levelData.isProtocol && showProtocol && !isCustomerServiceProtocol">
                    <div class="tree-elements-group">
                        <bubble-tree-ui [isServiceProtocol]="isServiceProtocol" [isParentActive]="isParentActive"
                            [isLicensedOwnerProtocol]="isLicensedOwnerProtocol"
                            [showSelectDeselect]="showSelectDeselect" [showProtocol]="showProtocol"
                            [levelData]="levelData.children" [level]="level" [addNode]="addNode" [editNode]="editNode"
                            [delNode]="delNode" [isActiveNode]="levelData.deleted == 0"
                            [disableDragDrop]="disableDragDrop" [lastNode]="lastNode" [isSelected]="isSelected"
                            [isAllParentActive]="isAllParentActive"
                            [isCustomerServiceProtocol]="isCustomerServiceProtocol"
                            *ngIf="levelData.children != null && levelData.children[0] != undefined">
                        </bubble-tree-ui>
                    </div>
                </div>
            </ng-container>

            <ng-template #otherServices>
                <div class="tree-element-main" [dndDraggable]="levelData" [dndEffectAllowed]="draggable.effectAllowed"
                    [dndDisableIf]="draggable.disable" dndDropzone (dndDrop)="onDrop($event, levelData)">
                    <div (click)="showNetwork(levelData)" class="rect"
                        [ngClass]="{'selected': levelData.deleted == 0, 'deselected': levelData.deleted == 1}">
                        <span *ngIf="levelData.isAllParentActive == 1 && isAllParentActive == 1"
                            class="selection-icon-wrapper" (click)="activateInactivate(levelData.deleted, levelData)">
                            <i *ngIf="showSelectDeselect" [title]="levelData.deleted == 1 ? 'Select' : 'Deselect'"
                                class="fad fa-lg selection-icon" [ngClass]="{
                        'selected': levelData.deleted == 1, 
                        'deselected': levelData.deleted == 0,
                        'fa-check-circle': levelData.deleted == 1,
                        'fa-window-close': levelData.deleted == 0
                    }"></i>
                        </span>
                        <span *ngIf="showProtocol" class="context-menu selection-icon-wrapper" mat-icon-button
                            aria-label="Action">
                            <span *ngIf="!isApprovderProtocol"
                                [ngClass]="{'protocolBox': levelData.isChildProtocol, 'blankBox': !levelData.isChildProtocol && levelData.isProtocol == 0}"></span>
                            <mat-icon class="protocol"
                                *ngIf="!levelData.isChildProtocol && levelData.isProtocol && !isApprovderProtocol">stars
                            </mat-icon>

                            <mat-icon class="approved-protocol float-right" (click)="viewApprovedProtocols(levelData)"
                                *ngIf="levelData.callerTypeKey && levelData.isProtocol && isApprovderProtocol">stars
                            </mat-icon>

                            <span *ngIf="!levelData.isChildProtocol && !isApprovderProtocol" class="context-menu"
                                mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                                <mat-icon>menu</mat-icon>
                            </span>
                            <mat-menu #menu="matMenu">
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && !levelData.isChildProtocol && !levelData.isProtocol && isLicensedOwnerProtocol"
                                    (click)="addProtocol(levelData)">
                                    <mat-icon><i class="fas fa-plus menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Add Protocol</span>
                                </span>
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && levelData.isProtocol && isLicensedOwnerProtocol"
                                    (click)="viewProtocols(levelData)">
                                    <mat-icon><i class="fas fa-file-excel menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">View Protocol</span>
                                </span>
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && levelData.isProtocol && isLicensedOwnerProtocol"
                                    (click)="deleteProtocols(levelData)">
                                    <mat-icon><i class="fas fa-trash-alt menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Delete Protocol</span>
                                </span>
                            </mat-menu>
                        </span>

                        <span *ngIf="showThreshold" class="context-menu selection-icon-wrapper" mat-icon-button
                            aria-label="Action">
                            <span *ngIf="!isApprovderProtocol"
                                [ngClass]="{'protocolBox': levelData.isChildThreshold, 'blankBox': !levelData.isChildThreshold && levelData.isThreshold == 0}"></span>
                            <mat-icon class="protocol"
                                *ngIf="!levelData.isChildThreshold && levelData.isThreshold && !isApprovderProtocol">
                                stars
                            </mat-icon>

                            <mat-icon class="approved-protocol float-right" (click)="viewApprovedProtocols(levelData)"
                                *ngIf="levelData.callerTypeKey && levelData.isThreshold && isApprovderProtocol">stars
                            </mat-icon>

                            <span *ngIf="!levelData.isChildThreshold && !isApprovderProtocol" class="context-menu"
                                mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                                <mat-icon>menu</mat-icon>
                            </span>
                            <mat-menu #menu="matMenu">
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && !levelData.isChildThreshold && !levelData.isThreshold && isLicensedOwnerProtocol"
                                    (click)="addThreshold(levelData, false)">
                                    <mat-icon><i class="fas fa-plus menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Add Threshold</span>
                                </span>
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && levelData.isThreshold && isLicensedOwnerProtocol"
                                    (click)="viewThreshold(levelData)">
                                    <mat-icon><i class="fas fa-file-excel menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">View Threshold</span>
                                </span>
                                <span mat-menu-item
                                    *ngIf="levelData.deleted == 0 && isActiveNode && levelData.isThreshold && isLicensedOwnerProtocol"
                                    (click)="deleteThreshold(levelData)">
                                    <mat-icon><i class="fas fa-trash-alt menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Delete Threshold</span>
                                </span>
                            </mat-menu>
                        </span>

                        <div class="buttons" *ngIf="levelData.deleted == 0">
                            <div class="delete button-border" (click)="deleteNode(levelData)"
                                *ngIf="levelData.deleted == 1 && delNode && isActiveNode"></div>
                            <div class="edit button-border" (click)="addEditNetwork(levelData,1)"
                                *ngIf="levelData.deleted == 1 && editNode && isActiveNode">
                                <i class="fas fa-pencil-alt"></i>
                            </div>
                            <div *ngIf="!(level == lastNode) && levelData.deleted == 0" class="button-border"
                                [ngClass]="{'toggler' : levelData.children != null && levelData.children[0] != undefined}">
                            </div>
                            <div class="add button-border" (click)="addEditNetwork(levelData,0)"
                                *ngIf="levelData.deleted == 0 && addNode && !(level == lastNode) && isActiveNode"></div>
                        </div>
                        <mat-checkbox class="disable-services" (change)="selectNode($event,levelData)"
                            *ngIf="level >= isSelected && isSelected != -1">
                        </mat-checkbox>
                        <div class="classContent">
                            <span class="ic mt-3">
                                <i [attr.class]="levelData.icon"></i>
                            </span>
                            <br>
                            <span class="divText">{{ levelData.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="tree-children"
                    *ngIf="levelData.key == levelKey && levelData.deleted == 0 && !showThreshold && !offeringQuestions">
                    <div class="tree-elements-group">
                        <bubble-tree-ui [isServiceProtocol]="isServiceProtocol"
                            [isApprovderProtocol]="isApprovderProtocol"
                            [isLicensedOwnerProtocol]="isLicensedOwnerProtocol"
                            [showSelectDeselect]="showSelectDeselect" [showProtocol]="showProtocol"
                            [levelData]="levelData.children" [level]="level" [addNode]="addNode" [editNode]="editNode"
                            [delNode]="delNode" [isActiveNode]="levelData.deleted == 0"
                            [disableDragDrop]="disableDragDrop" [lastNode]="lastNode" [isSelected]="isSelected"
                            [isAllParentActive]="isAllParentActive"
                            *ngIf="levelData.children != null && levelData.children[0] != undefined"
                            [isFromRateGroup]="isFromRateGroup">
                        </bubble-tree-ui>
                    </div>
                </div>
                <div class="tree-children" *ngIf="levelData.key == levelKey && levelData.deleted == 0 && showThreshold">
                    <div class="tree-elements-group">
                        <bubble-tree-ui [isServiceThreshold]="isServiceThreshold"
                            [isApprovderProtocol]="isApprovderProtocol" [userDetails]="userDetails"
                            [isLicensedOwnerProtocol]="isLicensedOwnerProtocol"
                            [showSelectDeselect]="showSelectDeselect" [showThreshold]="showThreshold"
                            [levelData]="levelData.children" [level]="level" [addNode]="addNode" [editNode]="editNode"
                            [delNode]="delNode" [isActiveNode]="levelData.deleted == 0"
                            [disableDragDrop]="disableDragDrop" [lastNode]="lastNode" [isSelected]="isSelected"
                            [isAllParentActive]="isAllParentActive"
                            *ngIf="levelData.children != null && levelData.children[0] != undefined"
                            [isFromRateGroup]="isFromRateGroup">
                        </bubble-tree-ui>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #offeringQuestionsServices>
            <div class="tree-element-main" [dndDraggable]="levelData" [dndEffectAllowed]="draggable.effectAllowed"
                [dndDisableIf]="draggable.disable" dndDropzone (dndDrop)="onDrop($event, levelData)">
                <div (click)="showNetwork(levelData)" class="rect"
                    [ngClass]="{'selected': levelData.isOfferingQuestion == 1, 'deselected': levelData.isOfferingQuestion == 0}">
                    <span *ngIf="offeringQuestions" class="context-menu selection-icon-wrapper" mat-icon-button
                        aria-label="Action">
                        <span class="context-menu" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                            <mat-icon>menu</mat-icon>
                        </span>
                        <mat-menu #menu="matMenu">
                            <span mat-menu-item *ngIf="levelData.isOfferingQuestion == 0 && isActiveNode"
                                (click)="addOfferingQuestions(levelData)">
                                <mat-icon><i class="fas fa-plus menu-icon mt-1"></i></mat-icon>
                                <span class="menu-icon">Add Questions</span>
                            </span>
                            <span mat-menu-item *ngIf="levelData.isOfferingQuestion == 1 && isActiveNode"
                                (click)="editOfferingQuestions(levelData)">
                                <mat-icon><i class="fas fa-file-excel menu-icon mt-1"></i></mat-icon>
                                <span class="menu-icon">Edit Questions</span>
                            </span>
                            <span mat-menu-item *ngIf="levelData.isOfferingQuestion == 1 && isActiveNode"
                                (click)="deleteOfferingQuestions(levelData)">
                                <mat-icon><i class="fas fa-trash-alt menu-icon mt-1"></i></mat-icon>
                                <span class="menu-icon">Delete Questions</span>
                            </span>
                        </mat-menu>
                    </span>

                    <div class="buttons" *ngIf="levelData.deleted == 0">
                        <div *ngIf="!(level == lastNode) && levelData.deleted == 0" class="button-border"
                            [ngClass]="{'toggler' : levelData.children != null && levelData.children[0] != undefined}">
                        </div>
                    </div>
                    <div class="classContent">
                        <span class="ic mt-3">
                            <i [attr.class]="levelData.icon"></i>
                        </span>
                        <br>
                        <span class="divText">{{ levelData.name }}</span>
                    </div>
                </div>
            </div>
            <div class="tree-children" *ngIf="levelData.key == levelKey && levelData.deleted == 0 && offeringQuestions">
                <div class="tree-elements-group">
                    <bubble-tree-ui [isServiceProtocol]="isServiceProtocol" [showProtocol]="showProtocol"
                        [showSelectDeselect]="showSelectDeselect" [levelData]="levelData.children" [level]="level"
                        [addNode]="addNode" [editNode]="editNode" [delNode]="delNode"
                        [isActiveNode]="levelData.deleted == 0" [disableDragDrop]="disableDragDrop"
                        [lastNode]="lastNode" [isSelected]="isSelected" [isAllParentActive]="isAllParentActive"
                        [offeringQuestions]="offeringQuestions"
                        *ngIf="levelData.children != null && levelData.children[0] != undefined">
                    </bubble-tree-ui>
                </div>
            </div>
        </ng-template>
    </div>
</div>

