<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-body>
        <div class="row m-3 border p-4">
            <div class="col-md-12 text-right mb-2">
                <button mat-button class="btn btn-sm btn-primary" (click)="addEditNetwork(0,0)" *ngIf="addNode">
                    Add New Service
                </button>
            </div>
            <ng-container *ngFor="let data of serviceData">
                <tree-root [nodes]="[data]" #tree [options]="options" class="col-md-6">
                    <ng-template #treeNodeTemplate let-node="node" let-index="index">
                        <mat-checkbox [checked]="node.data.deleted === 0"
                            (change)="activateInactivate(node.data.deleted, node.data)">
                            <strong>{{ node.data.name }}</strong>
                        </mat-checkbox>
                        <span class="text-right" *ngIf="!node.data.deleted && node.data.deleted == 0">
                            <span class="context-menu" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                                <mat-icon>menu</mat-icon>
                            </span>
                            <mat-menu #menu="matMenu">
                                <span mat-menu-item (click)="addEditNetwork(node.data,0)"
                                    *ngIf="node.data.deleted == 0 && addNode && isActiveNode && (node.level < lastNode)">
                                    <mat-icon><i class="fas fa-plus menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Add</span>
                                </span>
                                <span mat-menu-item (click)="addEditNetwork(node.data,1)"
                                    *ngIf="node.data.deleted == 0 && editNode && isActiveNode">
                                    <mat-icon><i class="fas fa-pencil-alt menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Edit</span>
                                </span>
                                <span mat-menu-item (click)="deleteNode(node.data)"
                                    *ngIf="node.data.deleted == 0 && delNode && isActiveNode">
                                    <mat-icon><i class="fas fa-trash-alt menu-icon mt-1"></i></mat-icon>
                                    <span class="menu-icon">Delete</span>
                                </span>
                            </mat-menu>
                        </span>
                    </ng-template>
                </tree-root>
            </ng-container>
        </div>
    </nb-card-body>
</nb-card>