<div class="row">
    <ng-container *ngFor="let data of levelData">
        <div class="p-2" [ngClass]="{'col-md-12 ml-4': data.levelNumber > 1, 'col-md-4': data.levelNumber == 1}">
            <div>
                <ng-container *ngIf="data.children != null && data.children[0] != undefined">
                    <span class="cursor-pointer" (click)="showNetwork(data)"
                        [ngClass]="{'d-none': data.providerCapabilityKey == levelKey}">
                        <i class="fa fa-plus mr-2"></i>
                    </span>
                    <span class="cursor-pointer" (click)="showNetwork(data)"
                        [ngClass]="{'d-none': data.providerCapabilityKey !== levelKey}">
                        <i class="fa fa-minus mr-2"></i>
                    </span>
                </ng-container>
                <label class="container-chk-custom"
                    [ngClass]="{'ml-4': data.children == null || data.children[0] == undefined}">
                    <input type="checkbox" (change)="activateInactivate(data)" [checked]="data.isSelected == 1">
                    <span class="checkmark"></span>
                </label>
                <span>{{ data.description }}</span>
            </div>

            <!-- load data if data has children -->
            <ng-container *ngIf="data.providerCapabilityKey == levelKey">
                <app-checkbox-tree-ui [levelData]="data.children" [level]="level" [lastNode]="lastNode"
                    [providerKey]="providerKey" *ngIf="data.children != null && data.children[0] != undefined">
                </app-checkbox-tree-ui>
            </ng-container>
        </div>
    </ng-container>
</div>