<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="modal-header">
    <h6 class="modal-title text-capitalize">{{data.event}} menu</h6>
    <button type="button" class="close" (click)="cancelClick()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>

<div class="modal-body">
    <nb-card>
        <nb-card-body>
            <form [formGroup]="securityMenuForm">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <input type="text" matInput formControlName="menuName" required placeholder="Enter Menu"
                                autocomplete="off">
                            <mat-error
                                *ngIf="securityMenuForm.controls.menuName.touched && securityMenuForm.controls.menuName.errors">
                                <span *ngIf="securityMenuForm.controls.menuName.errors.required">
                                    Menu Name is required.
                                </span>
                                <span *ngIf="securityMenuForm.controls.menuName.errors.pattern">
                                    Enter valid menu Name.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-select formControlName="displayOrder" required placeholder="Select Display Order">
                                <mat-option *ngFor="let number of arrDisplayOrder" [value]="number">
                                    {{number}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="securityMenuForm.controls.displayOrder.touched && securityMenuForm.controls.displayOrder.errors">
                                <span *ngIf="securityMenuForm.controls.displayOrder.errors.required">
                                    Display Order is required.
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </nb-card-body>
    </nb-card>
</div>

<div class="modal-footer">
    <button class="btn btn-primary btn-sm mr-2" (click)="cancelClick()">Cancel</button>
    <button class="btn btn-primary btn-sm mr-2 text-capitalize" type="button" (click)="addEditMenu()"
        [disabled]="securityMenuForm.invalid">Save</button>
</div>
