import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { UsersService } from 'src/app/services/users.service';
import { forkJoin } from 'rxjs';
import { AlertDialogComponent } from 'src/app/dialogs/alert-dialog/alert-dialog.component';
import { AddLimitsComponent } from 'src/app/dialogs/add-limits/add-limits.component';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { CommonService } from 'src/app/services/common.service';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { ServiceThresholdsComponent } from 'src/app/dialogs/service-thresholds/service-thresholds.component';
import { TierService } from 'src/app/services/tier.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SystemSecuruityMenuService } from 'src/app/services/system-securuity-menu.service';
import { UserExperianceService } from 'src/app/services/user-experiance.service';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  @Input() userData: any;
  @Input() customerAuthorization = false;
  @Input() customerInputKey: any;
  @Input() customerAdmin = false;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  searchThresholdForm: UntypedFormGroup;
  buttonText = 'Is an Approver';
  schema = 'FleetAssist';
  overallDataDictionaryKey = '3ac310f5-1cf3-4737-86be-20d22fba12b2';
  approverDataDictionaryKey = 'b6bf9892-fdb9-46a7-a834-2a18f9a29c04';
  customerAuthorizationDataDictionaryKey = 'b6bf9892-fdb9-46a7-a834-2a18f9a29c04';
  customerKey = sessionStorage.getItem('customerKey');
  licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
  tierLevel: number;
  selectedIndex = 0;
  customerTiers = [];
  securityGroups = [];
  overallLimits: any = [];
  serviceExceptionLimits: any = [];
  masterUser: any;
  userDetails: any;
  tempOverallLimits: any;
  ultimateParentKey: any;
  tempOverallThreshold: any;
  tempServiceExceptionLimits: any;
  operatingSchedules: any;
  tempServiceExceptionThreshold: any;
  loading = false;
  readOnlyUser = false;
  isUltimateParent = false;
  isMasterCustomer = false;
  isParentCustomer = false;
  isApproverSection = true;
  isEmergencyApprover = false;
  getOverallParentRecord = false;
  getServiceParentRecord = false;
  editOverallThresholdFlag = false;
  disableOverallLimitsEdit = false;
  disableServiceExceptionLimitsEdit = false;
  editServiceExcpetionThresholdFlag = false;

  constructor(
    public dialog: MatDialog,
    private toast: NbToastrService,
    private customerService: CustomerService,
    private usersService: UsersService,
    private dialogService: NbDialogService,
    private commonService: CommonService,
    private utilityService: UtilityService,
    private tierService: TierService,
    public formBuilder: UntypedFormBuilder,
    private systemSecuruityMenuService: SystemSecuruityMenuService,
    private userExperianceService: UserExperianceService,
  ) { }

  ngOnInit() {
    if (this.customerAuthorization) {
      this.searchThresholdForm = this.formBuilder.group({
        hierarchyTier: [''],
        securityGroup: ['']
      });
      this.isApproverSection = false;
      this.userDetails = {
        associationKey: this.customerInputKey || this.customerKey || this.licensedOwnerKey,
        userKey: null,
        userTypeKey: null,
        customerAuthorization: this.customerAuthorization,
        customerAdmin: this.customerAdmin
      };
    } else {
      this.userDetails = this.userData;
      this.readOnlyUser = this.userData.readOnlyUser || false;
      if (this.userDetails.isApprover) {
        this.checkCustomerInvoice();
      } else {
        this.buttonText = 'Is Not an Approver';
        this.isApproverSection = false;
        this.checkIsApprover();
      }
    }
    this.checkMasterCustomer();

    if (this.customerKey) {
      this.checkIsParentCustomer();
    } else {
      this.getDropDownListData();
    }
  }

  checkIsParentCustomer() {
    this.isParentCustomer = false;
    const filter = {
      customerKey: this.customerKey,
      deleted: false,
    };

    this.loading = true;
    this.customerService.getCustomerDetails(filter).subscribe(res => {
      this.loading = false;
      if (res.body.length > 0 && (!res.body[0].parentKey || res.body[0].parentKey === '0')) {
        this.isParentCustomer = true;
      }

      this.getDropDownListData();
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  checkMasterCustomer() {
    const filter = {
      customerKey: this.customerInputKey || this.customerKey,
      masterCustomer: true,
    };

    this.customerService.getCustomerDetails(filter).subscribe(res => {
      if (res.body.length > 0) {
        this.isMasterCustomer = true;
      }
    });
  }

  getDropDownListData() {
    const filter = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      masterCustomer: true
    };

    const user = this.customerService.getCustomerDetails(filter);
    const operatingScheduleFilter = this.customerAuthorization ? { systemDefault: true, deleted: false } : {};
    const operatingSchedules = this.commonService.getOperatingSchedules(operatingScheduleFilter);
    const customerTier = this.tierService.getTierDetails({ customerKey: this.customerInputKey || sessionStorage.getItem('customerKey') });

    // Customer Security Groups
    const securityGroup = this.systemSecuruityMenuService.getSecurityGroupList({ userTypeKey: '90dc6b51-9731-439b-bd21-40685f989f24' });
    const customerTierLevel = this.customerService.getCustomerDetails({ customerKey: this.userDetails.associationKey });

    this.loading = true;
    forkJoin([user, operatingSchedules, customerTier, securityGroup, customerTierLevel]).subscribe(result => {
      const customer = result[4].body[0];
      if (this.customerAuthorization && ((this.customerAdmin || this.isParentCustomer) ? true : (!customer.parentKey || customer.parentKey === '0'))) {
        this.isUltimateParent = true;
      }
      const masterCustomer = result[0].body[0];
      this.operatingSchedules = result[1].body;
      this.customerTiers = this.isUltimateParent ? result[2].body : [];
      this.securityGroups = result[3].body;
      this.userDetails.parentKey = customer.parentKey || null;
      if (this.customerAuthorization && customer && this.customerTiers.length > 0) {
        this.userDetails.customerTierStructureKey = customer.customerTierStructureKey;
        this.tierLevel = this.customerTiers.find(item => item.customerTierStructureKey ===
          this.userDetails.customerTierStructureKey).tierLevel;
      }
      this.loading = false;
      this.getMaterCustomerUser(masterCustomer);
      this.getThresholds();
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  getMaterCustomerUser(masterCustomer) {
    this.masterUser = [];
    if (masterCustomer.customerKey) {
      const filter = {
        associationKey: masterCustomer.customerKey
      };

      this.loading = true;
      this.usersService.getUserDetailsByAssociationKey(filter).subscribe(res => {
        this.loading = false;
        this.masterUser = res.body[0] || [];
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    }
  }

  /**
   * Method to get customer invoice for displaying emergency approver field
   */
  checkCustomerInvoice() {
    const data = {
      customerKey: this.userDetails.associationKey,
      invoice: true
    };

    this.loading = true;
    this.customerService.getCustomerDetails(data).subscribe(res => {
      if (res.body.length > 0) {
        this.isEmergencyApprover = true;
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  /**
   * Method to set isApprover = true when match the default security groups
   */
  checkIsApprover() {
    let thresholds = [];
    let securityGroups = [];
    let isDefault = false;

    const filter = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      ultimateFilter: 0,
      customerKey: this.userDetails.associationKey
    };
    // To get customer with ultimate customer parent
    this.customerService.getCustomersWithUltimateParent(filter).subscribe(customerResponse => {
      if (customerResponse.success) {
        const customer = customerResponse.body;
        const ultimateCustomerKey = customer.ultimateParentKey || this.userDetails.associationKey;

        const authorizationFilter = {
          associationKey: ultimateCustomerKey
        };

        const securityGroupFilter = {
          userKey: this.userDetails.userKey,
          platform: 'FleetAssist'
        };

        const authorizationThresholds = this.usersService.getAuthorizationThresholds(authorizationFilter);
        const secGrps = this.usersService.getUserSecurityGroups(securityGroupFilter);

        forkJoin([authorizationThresholds, secGrps]).subscribe(result => {
          console.log('resss', result);
          thresholds = result[0].body || [];
          securityGroups = result[1].body || [];

          this.loading = true;
          if (result[0].success && result[1].success && thresholds.length > 0) {
            thresholds.forEach(authorizationThreshold => {
              const defaultSecurityGroup = securityGroups.find(securityGroup => {
                return authorizationThreshold.securityGroupKey ===
                  securityGroup.securityGroupKey && securityGroup.systemDefault === true;
              });

              if (defaultSecurityGroup && !isDefault) {
                isDefault = true;
                const data = {
                  userExperianceKey: this.userDetails.userExperianceKey,
                  isApprover: true
                };
                this.loading = true;
                this.userExperianceService.updateUserExperiances(data).subscribe(res => {
                  this.userDetails.isApprover = true;
                  this.isApproverSection = true;
                  this.buttonText = 'Is an Approver';
                  this.loading = false;
                  this.checkCustomerInvoice();
                });
              }
            });
          }
          this.loading = false;
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  /**
   * Method to get overall and service exception thresholds
   */
  getThresholds() {
    this.loading = true;
    const overallLimitsFilter = {
      customerKey: this.userDetails.associationKey || this.userDetails.customerKey,
      userKey: this.userDetails.userKey,
      overallLimits: true
    };
    const serviceExceptionFilter = {
      customerKey: this.userDetails.associationKey || this.userDetails.customerKey,
      userKey: this.userDetails.userKey,
      overallLimits: false
    };

    const overallThreshold = this.usersService.getAuthorizationThresholdsDetails(overallLimitsFilter);
    const serviceExceptionThreshold = this.usersService.getAuthorizationThresholdsDetails(serviceExceptionFilter);

    forkJoin([overallThreshold, serviceExceptionThreshold]).subscribe(result => {
      if (result[0].success && result[0].body.length > 0) {
        this.overallLimits = result[0].body || [];
        this.getOverallParentRecord = false;

        if (this.customerAuthorization) {
          this.checkHigherTiers(this.overallLimits);
        }
      } else {
        this.checkOverallThresholdParentData(this.userDetails.parentKey);
      }

      if (result[1].success && result[1].body.length > 0) {
        this.serviceExceptionLimits = result[1].body || [];
        this.getServiceParentRecord = false;

        if (this.customerAuthorization) {
          this.checkHigherTiers(this.serviceExceptionLimits);
        }
      } else {
        this.checkServiceThresholdParentData(this.userDetails.parentKey);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  /**
   * Method to get overall threshold parent data
   */
  checkOverallThresholdParentData(parentKey) {
    let data: any;
    let isMasterCustomer = false;
    parentKey = (parentKey === '0' || !parentKey) ? null : parentKey;
    if (parentKey) {
      data = {
        customerKey: parentKey
      };
    } else {
      data = {
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        masterCustomer: true
      };
      isMasterCustomer = true;
    }
    this.customerService.getCustomer(data).subscribe(res => {
      if (res.success && res.body.length > 0) {
        const inCustomerKey = res.body[0].customerKey;
        parentKey = res.body[0].parentKey;
        let overallLimitsFilter: any;
        if (isMasterCustomer) {
          overallLimitsFilter = {
            customerKey: inCustomerKey,
            userKey: this.customerAuthorization ? null : this.masterUser?.userKey,
            overallLimits: true
          };
        } else {
          overallLimitsFilter = {
            customerKey: inCustomerKey,
            userKey: this.userDetails.userKey,
            overallLimits: true
          };
        }

        this.usersService.getAuthorizationThresholdsDetails(overallLimitsFilter).subscribe(result => {
          if (result.success && result.body.length > 0 || isMasterCustomer) {
            this.overallLimits = result.body || [];
            this.getOverallParentRecord = this.overallLimits.length > 0 ? true : false;
            this.loading = false;
          } else {
            this.checkOverallThresholdParentData(parentKey);
          }
        }, (error) => {
          this.loading = false;
          console.log(error);
        });
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  /**
   * Method to get service exception threshold parent data
   */
  checkServiceThresholdParentData(parentKey) {
    let data: any;
    let isMasterCustomer = false;
    parentKey = parentKey === '0' ? null : parentKey;
    if (parentKey) {
      data = {
        customerKey: parentKey
      };
    } else {
      data = {
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        masterCustomer: true
      };
      isMasterCustomer = true;
    }

    this.customerService.getCustomer(data).subscribe(res => {
      if (res.success && res.body.length > 0) {
        const inCustomerKey = res.body[0].customerKey;
        parentKey = res.body[0].parentKey;
        let serviceThresholdFilter: any;
        if (isMasterCustomer) {
          serviceThresholdFilter = {
            customerKey: inCustomerKey,
            userKey: this.customerAuthorization ? null : this.masterUser?.userKey,
            overallLimits: false
          };
        } else {
          serviceThresholdFilter = {
            customerKey: inCustomerKey,
            userKey: this.userDetails.userKey,
            overallLimits: false
          };
        }
        this.usersService.getAuthorizationThresholdsDetails(serviceThresholdFilter).subscribe(result => {
          if (result.success && result.body.length > 0 || isMasterCustomer) {
            this.serviceExceptionLimits = result.body || [];
            this.getServiceParentRecord = this.serviceExceptionLimits.length > 0 ? true : false;
            this.loading = false;
          } else {
            this.checkServiceThresholdParentData(parentKey);
          }
        }, (error) => {
          this.loading = false;
          console.log(error);
        });
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  emergencyProceedAuthorization(event) {
    this.loading = true;
    const data = {
      userExperianceKey: this.userDetails.userExperianceKey,
      emergencyProceedAuthorization: event.checked
    };

    this.userExperianceService.updateUserExperiances(data).subscribe(res => {
      this.userDetails.emergencyProceedAuthorization = res.body[0].emergencyProceedAuthorization;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  approver() {
    this.loading = true;
    const data = {
      userExperianceKey: this.userDetails.userExperianceKey,
      isApprover: !this.userDetails.isApprover
    };

    this.userExperianceService.updateUserExperiances(data).subscribe(res => {
      this.loading = false;
      this.userDetails.isApprover = res.body[0].isApprover;
      if (!this.userDetails.isApprover) {
        this.buttonText = 'Is Not an Approver';
        this.isApproverSection = false;
      } else {
        this.buttonText = 'Is an Approver';
        this.isApproverSection = true;
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /**
   * Method to check customer hierarchy has emergency approver
   */
  emergencyApprover(event) {
    if (this.userDetails.isEmergencyApprover) {
      const ultimateCustomerFilter = {
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        ultimateFilter: 0,
        customerKey: this.userDetails.associationKey
      };

      // get ultimate parent
      this.customerService.getCustomersWithUltimateParent(ultimateCustomerFilter).subscribe(res => {
        const ultimateParentCustomer = res.body[0];

        const filter = {
          customerKey: ultimateParentCustomer.ultimateParentKey || ultimateParentCustomer.customerKey,
          userKey: this.userDetails.userKey
        };

        this.usersService.getUsersEmergencyApprover(filter).subscribe(result => {
          if (result.length > 0) {
            this.updateEmergencyApprover();
          } else {
            this.dialog.open(AlertDialogComponent, {
              disableClose: true,
              width: '40%',
              data: {
                message: 'At least one (1) User must be setup as an Emergency Approver for this hierarchy. Please add another before this record can be updated.'
              }
            });
            event.source.checked = true;
          }
        });
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.updateEmergencyApprover();
    }
  }

  updateEmergencyApprover() {
    const data = {
      userExperianceKey: this.userDetails.userExperianceKey,
      isEmergencyApprover: !this.userDetails.isEmergencyApprover,
      emergencyProceedAuthorization: !this.userDetails.isEmergencyApprover ? true : false
    };

    this.loading = true;
    this.userExperianceService.updateUserExperiances(data).subscribe(res => {
      this.userDetails.isEmergencyApprover = res.body[0].isEmergencyApprover;
      this.userDetails.emergencyProceedAuthorization = res.body[0].emergencyProceedAuthorization;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  recursiveCustomerHierarchy(customers, child) {
    child.forEach(parent => {
      customers.forEach(customer => {
        if (customer.customerKey === parent.parentKey) {
          const childCustomer = {
            item: customer.customerName,
            customerKey: customer.customerKey,
            parentKey: customer.parentKey,
          };

          if (childCustomer.parentKey === null || childCustomer.parentKey === '0') {
            this.ultimateParentKey = childCustomer.customerKey;
            parent = [];
          }
        }
      });
      this.recursiveCustomerHierarchy(customers, parent);
    });
  }

  deleteLimits(limitData) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {

        const data = {
          deleted: true,
          authorizationThresholdKey: limitData.authorizationThresholdKey
        };

        this.loading = true;
        this.usersService.updateAuthorizationThresholds(data).subscribe(res => {
          this.clearSearchAndGetThresholds();
          this.loading = false;
        }, (error) => {
          console.log(error);
          this.loading = false;
        });
      }
    });
  }

  updateLimits(limitData, value) {
    let filter;
    if (value === 'Overall Limit') {
      filter = {
        customerKey: limitData.associationKey,
        userKey: limitData.userKey,
        securityGroupKey: limitData.securityGroupKey,
        customerTierStructureKey: limitData.customerTierStructureKey,
        operatingScheduleKey: limitData.operatingScheduleKey,
        overallLimits: true
      };
    } else {
      filter = {
        customerKey: limitData.associationKey,
        userKey: limitData.userKey,
        securityGroupKey: limitData.securityGroupKey,
        customerTierStructureKey: limitData.customerTierStructureKey,
        operatingScheduleKey: limitData.operatingScheduleKey,
        licensedOwnerOfferingKey: limitData.licensedOwnerOfferingKey,
        overallLimits: false
      };
    }

    this.usersService.getAuthorizationThresholdsDetails(filter).subscribe(result => {
      if (result.body.length > 0) {
        this.toast.warning('Record already exist', 'Error');
      } else {
        this.updateThreshold(limitData, value);
      }
    });
  }

  updateThreshold(limitData, value) {
    let updateServiceExceptionData;
    if (value === 'Service Exception') {
      if (limitData.minimumThreshold === '' || limitData.quantityMinimum === '') {
        return false;
      } else {
        updateServiceExceptionData = {
          authorizationThresholdKey: limitData.authorizationThresholdKey,
          operatingScheduleKey: limitData.operatingScheduleKey,
          minimumThreshold: limitData.minimumThreshold,
          maximumThreshold: limitData.maximumThreshold,
          quantityMinimum: limitData.quantityMinimum,
          quantityMaximum: limitData.quantityMaximum
        };
      }
    } else {
      if (limitData.minimumThreshold === '') {
        return false;
      } else {
        updateServiceExceptionData = {
          authorizationThresholdKey: limitData.authorizationThresholdKey,
          operatingScheduleKey: limitData.operatingScheduleKey,
          minimumThreshold: limitData.minimumThreshold,
          maximumThreshold: limitData.maximumThreshold,
        };
      }
    }
    this.loading = true;
    this.usersService.updateAuthorizationThresholds(updateServiceExceptionData).subscribe(res => {
      this.getThresholds();
      this.clearFlags();
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  updateAll(value) {
    if (this.editOverallThresholdFlag && value === 'overall Threshold') {
      this.updateLimits(this.tempOverallThreshold, value);
    }

    if (this.editServiceExcpetionThresholdFlag && value === 'Service Exception') {
      this.updateLimits(this.tempServiceExceptionThreshold, value);
    }

  }

  addThreshold(isOverallLimit: boolean) {
    const dialogRef = this.dialog.open(AddLimitsComponent, {
      width: '70%',
      disableClose: true,
      position: {
        top: '8%',
      },
      data: {
        isOverallLimit,
        userDetails: this.userDetails,
        isMasterCustomer: this.isMasterCustomer
      }
    });

    dialogRef.afterClosed().subscribe(isValue => {
      this.clearSearchAndGetThresholds();
    });
  }

  searchThreshold() {
    if (this.searchThresholdForm.invalid) {
      this.searchThresholdForm.markAllAsTouched();
      return false;
    }
    const searchOverallThresholds = {
      customerKey: this.userDetails.associationKey,
      userKey: null,
      overallLimits: true,
      securityGroupKey: this.searchThresholdForm.controls.securityGroup.value,
      customerTierStructureKey: this.searchThresholdForm.controls.hierarchyTier.value
    };
    const searchServiceExceptionThresholds = {
      customerKey: this.userDetails.associationKey,
      userKey: null,
      overallLimits: false,
      securityGroupKey: this.searchThresholdForm.controls.securityGroup.value,
      customerTierStructureKey: this.searchThresholdForm.controls.hierarchyTier.value
    };

    const overallThreshold = this.usersService.getAuthorizationThresholdsDetails(searchOverallThresholds);
    const serviceThreshold = this.usersService.getAuthorizationThresholdsDetails(searchServiceExceptionThresholds);
    this.loading = true;
    forkJoin([overallThreshold, serviceThreshold]).subscribe(result => {
      this.overallLimits = result[0].body;
      this.serviceExceptionLimits = result[1].body;
      this.checkHigherTiers(this.overallLimits);
      this.checkHigherTiers(this.serviceExceptionLimits);
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  clearSearchAndGetThresholds() {
    if (this.searchThresholdForm) {
      this.searchThresholdForm.reset();
    }
    this.getThresholds();
  }

  /**
   * Method to disable edit functionality of higher tiers thresholds
   */
  checkHigherTiers(limits: any) {
    limits.forEach(limit => {
      if (this.tierLevel > limit.tierLevel) {
        limit.isDisabled = true;
      } else {
        limit.isDisabled = false;
      }
    });
  }

  openServiceThreshold() {
    const dialogRef = this.dialog.open(ServiceThresholdsComponent, {
      width: '90%',
      disableClose: true,
      position: {
        top: '5%',
      },
      data: {
        userDetails: this.userDetails
      }
    });

    dialogRef.afterClosed().subscribe(isValue => {
      this.clearSearchAndGetThresholds();
    });
  }

  refresh() {
    this.clearFlags();
    this.ngOnInit();
  }

  clearFlags() {
    this.editOverallThresholdFlag = false;
    this.editServiceExcpetionThresholdFlag = false;
    this.disableOverallLimitsEdit = false;
    this.disableServiceExceptionLimitsEdit = false;
  }

  editOverallLimit(index: number) {
    this.tempOverallLimits = { ...{}, ...this.overallLimits[index] };
    this.tempOverallThreshold = this.overallLimits[index];
    this.overallLimits[index].editMode = !this.overallLimits[index].editMode;
    this.disableOverallLimitsEdit = !this.disableOverallLimitsEdit;
    this.editOverallThresholdFlag = !this.editOverallThresholdFlag;
  }

  cancelOverallLimits(index: number) {
    this.overallLimits[index].editMode = !this.overallLimits[index].editMode;
    this.disableOverallLimitsEdit = !this.disableOverallLimitsEdit;
    this.overallLimits[index] = this.tempOverallLimits;
    this.editOverallThresholdFlag = !this.editOverallThresholdFlag;
  }

  editServiceExceptionLimits(index: number) {
    this.tempServiceExceptionLimits = { ...{}, ...this.serviceExceptionLimits[index] };
    this.tempServiceExceptionThreshold = this.serviceExceptionLimits[index];
    this.serviceExceptionLimits[index].editMode = !this.serviceExceptionLimits[index].editMode;
    this.disableServiceExceptionLimitsEdit = !this.disableServiceExceptionLimitsEdit;
    this.editServiceExcpetionThresholdFlag = !this.editServiceExcpetionThresholdFlag;
  }

  cancelServiceExceptionLimits(index: number) {
    this.serviceExceptionLimits[index].editMode = !this.serviceExceptionLimits[index].editMode;
    this.disableServiceExceptionLimitsEdit = !this.disableServiceExceptionLimitsEdit;
    this.serviceExceptionLimits[index] = this.tempServiceExceptionLimits;
    this.editServiceExcpetionThresholdFlag = !this.editServiceExcpetionThresholdFlag;
  }

  onOverallLimitsSort({ column, direction }: SortEvent) {
    this.overallLimits = this.utilityService.sortData(this.headers, column, direction, this.overallLimits);
  }

  onserviceExceptionLimitsSort({ column, direction }: SortEvent) {
    this.serviceExceptionLimits = this.utilityService.sortData(this.headers, column, direction, this.serviceExceptionLimits);
  }

  canDeactivate(): boolean {
    return this.editOverallThresholdFlag || this.editServiceExcpetionThresholdFlag;
  }
}
