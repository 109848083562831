<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<ng-container *ngIf="levelData.length > 0">
    <div *ngFor="let levelData of levelData; let i = index;" class="tree-root tree-child tree-node">
        <div class="line-to"></div>
        <div class="tree-element-container tree-element-has-children" [nbTooltip]="levelData.label">
            <div class="tree-element-main">
                <div (click)="showNetwork(levelData)" class="rect"
                    [ngClass]="{'selected': levelData.deleted == 0, 'deselected': levelData.deleted == 1}">
                    <span *ngIf="levelData"
                        class="context-menu selection-icon-wrapper" mat-icon-button aria-label="Action">
                        <span class="context-menu" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action" (click)="checkSecurityGroupPermissionExists(levelData)">
                            <mat-icon>menu</mat-icon>
                        </span>
                        <mat-menu #menu="matMenu">
                            <div *ngIf="isDataLoaded" [ngClass]="{'activeClass': (permissionDescription == 'Read')}">
                                <span mat-menu-item (click)="updateMenuPermission(levelData, 'Read')">
                                    <mat-icon class="mr-2">
                                        <i class="fas fa-book menu-icon"></i>
                                    </mat-icon>
                                    <span class="menu-icon">Read</span>
                                </span>
                            </div>                            
                            <div *ngIf="isDataLoaded" [ngClass]="{'activeClass': permissionDescription == 'Write'}">
                                <span mat-menu-item (click)="updateMenuPermission(levelData, 'Write')">
                                    <mat-icon class="mr-2">
                                        <i class="fas fa-pen menu-icon"></i>
                                    </mat-icon>
                                    <span class="menu-icon">Write</span>
                                </span>
                            </div>
                            <div *ngIf="isDataLoaded">
                                <span mat-menu-item (click)="clearPermission(levelData)">
                                    <mat-icon class="mr-2">
                                        <i class="fas fa-trash menu-icon"></i>
                                    </mat-icon>
                                    <span class="menu-icon">Clear Permissions</span>
                                </span>
                            </div>
                        </mat-menu>
                    </span>
    
                    <div class="buttons" *ngIf="levelData.deleted == 0">
                        <div *ngIf="!(level == lastNode) && levelData.deleted == 0" class="button-border toggler"
                            [ngClass]="{'toggler' : (levelData.children != null && levelData.children[0] != undefined)}">
                        </div>
                        <!-- <div class="add button-border"
                            *ngIf="levelData.deleted == 0 && !(level == lastNode) && isActiveNode"></div> -->
                    </div>
                    <mat-checkbox class="disable-services" *ngIf="level >= isSelected && isSelected != -1">
                    </mat-checkbox>
                    <div class="classContent">
                        <span class="ic mt-3">
                            <i [attr.class]="levelData.icon"></i>
                        </span>
                        <br>
                        <span class="divText">{{ levelData.label }}</span>
                    </div>
                </div>
            </div>
            <div class="tree-children" *ngIf="levelData.children.length > 0 && levelData.children && levelData.dynamicMenuKey == parentKey">
                <div class="tree-elements-group">
                    <ngx-bubble-tree-security-group [levelData]="levelData.children" [level]="level"
                        [isActiveNode]="levelData.deleted == 0" [isSelected]="isSelected" [securityGroupKey]="securityGroupKey"
                        [isAllParentActive]="isAllParentActive" [parentKey]="levelData.dynamicMenuKey"
                        *ngIf="levelData.children != null && levelData.children[0] != undefined">
                    </ngx-bubble-tree-security-group>
                </div>
            </div>
        </div>
    </div>
</ng-container>

