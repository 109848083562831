import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ProviderService } from 'src/app/services/provider.service';
import { CommonService } from 'src/app/services/common.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { NbToastrService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { AddCustomerToDNUDialogComponent } from '../add-customer-to-dnu-dialog/add-customer-to-dnu-dialog.component';
import { AddCustomerPerferredProvidersComponent } from 'src/app/pages/provider-admin/add-customer-perferred-providers/add-customer-perferred-providers.component';

@Component({
  selector: 'app-provider-admin-details',
  templateUrl: './provider-admin-details.component.html',
  styleUrls: ['./provider-admin-details.component.scss']
})
export class ProviderAdminDetailsComponent implements OnInit, OnChanges {

  @Output() next = new EventEmitter<boolean>();
  @Input() providerDetails: any;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  providerAccountRecords = [];
  providerDetailsForm: UntypedFormGroup;
  loading = false;
  membershipStatus: any = [];
  providerAccountstatuses: any = [];
  //ratings: any = [];
  paymentTerms: any = [];
  paymentMethods: any = [];
  providerDetailsRes: any = [];
  menus: any = [];
  childMenus: any = [];
  showNote = false;
  providerKey: any;
  isMasterLicensedOwner = true;
  licensedOwnerCustomDiscountDetails: any;
  providerMetrics: any = [];
  parentProviderInfo: any = [];
  discountTypes: any = [];
  customerNotPreferredProvidersForServicePlus: any = [];
  arrowDirection: any;
  onlyUseForSpecificCustomer: any = [];
  listOfProvidersforPerferred: any = [];
  listOfProvidersforPerferredToRemove: any = [];
  providerAddedToDNU: any;
  accountStatusIsDNU: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private providerService: ProviderService,
    private commonService: CommonService,
    private providerAdminService: ProviderAdminService,
    private toast: NbToastrService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.providerDetailsForm = this.formBuilder.group({
      afterHoursRatesAllowed: [''],
      membershipStatus: [''],
      qualityRating: [''],
      paymentTermKey: [''],
      paymentMethodKey: [''],
      accountStatus: [''],
      note: [''],
      autoDispatch: [''],
      discountTypeKey: [''],
      discountAmount:[''],
      providerDiscontinuedReason:[''],
    });

    //this.ratings = [...Array(100).keys()].map(i => i + 1);
    this.initializeDropDowns();
  }

  ngOnChanges() {
    // if(this.providerDetails.isParent){
    //   this.getProviderMetricsDetails();
    // }

    if(this.providerDetails.isParent){
      let searchData = {
        providerKey: this.providerDetails.ProviderKey,
        deleted: false,
      }
      this.commonService.getLicensedOwnerDiscountPrograms(searchData)
      .subscribe({
        next: res => this.licensedOwnerCustomDiscountDetails = res.body[0],
        error: (e) => console.log(e),
        complete: () => {
          if (this.providerDetails.providerAccountKey) {
            this.getAccountDetails();
            this.selectCustomerNotPreferredProvidersForServicePlus();
          }
          else if(this.providerDetails.ProviderAccountKey){
            this.providerDetails.providerAccountKey = this.providerDetails.ProviderAccountKey;
            this.getAccountDetails();
            this.selectCustomerNotPreferredProvidersForServicePlus();
          }
        }
      })
    }

    // if (this.providerDetails.providerAccountKey) {
    //   this.getAccountDetails();
    //   //this.getLicensedOwnerCustomDiscountDetails();
    //   //this.getDynamicMenus();
    //   this.selectCustomerNotPreferredProvidersForServicePlus();
    // }
    // else if(this.providerDetails.ProviderAccountKey){
    //   this.providerDetails.providerAccountKey = this.providerDetails.ProviderAccountKey;
    //   this.getAccountDetails();
    //   //this.getLicensedOwnerCustomDiscountDetails();
    //   //this.getDynamicMenus();
    //   this.selectCustomerNotPreferredProvidersForServicePlus();
    // }
  }

  getAccountDetails() {
    const filter = {
      providerAccountKey: this.providerDetails.providerAccountKey,
    };
    this.loading = true;
    // this.providerDetailsForm.reset();
    this.providerService.getProviderAccounts(filter).subscribe(result => {
      this.loading = false;
      this.providerDetailsRes = result.body[0];
      this.setInitialValues();
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  setInitialValues() {
    this.providerDetailsForm.reset();
    this.showNote = false;
    if (this.providerDetailsRes.length !== 0) {
      this.providerDetailsForm.setValue({
        afterHoursRatesAllowed: this.providerDetailsRes.afterHoursRatesAllowed,
        membershipStatus: this.providerDetailsRes.membershipStatus || '',
        qualityRating: this.providerDetailsRes.qualityRating,
        paymentTermKey: this.providerDetailsRes.paymentTermKey,
        paymentMethodKey: this.providerDetailsRes.paymentMethodKey,
        accountStatus: this.providerDetailsRes.accountStatus,
        note: '',
        autoDispatch: this.providerDetailsRes.autoDispatch,
        discountTypeKey: this.licensedOwnerCustomDiscountDetails.discountTypeKey,
        discountAmount: this.licensedOwnerCustomDiscountDetails.discountAmount,
        providerDiscontinuedReason: this.providerDetailsRes.providerDiscontinuedReason || '',
      });
    }

    // if(this.providerDetailsForm.value.discountTypeKey == 'Flat Rate' || this.providerDetailsForm.value.discountTypeKey == 'Rebate Flat Rate'){
    //   this.providerDetailsForm.value.discountAmount = '$ ' + this.providerDetailsForm.value.discountAmount
    // }

    // else if(this.providerDetailsForm.value.discountTypeKey == 'Percentage' || this.providerDetailsForm.value.discountTypeKey == 'Rebate Percentage'){
    //   this.providerDetailsForm.value.discountAmount = this.providerDetailsForm.value.discountAmount + ' %'
    // }

    this.providerDetailsForm.controls.note.clearValidators();
    this.providerDetailsForm.controls.note.updateValueAndValidity();
  }

  getLicensedOwnerCustomDiscountDetails(){
    if(this.providerDetails.isParent){
      let searchData = {
        providerKey: this.providerDetails.ProviderKey,
        deleted: false,
      }
      this.commonService.getLicensedOwnerDiscountPrograms(searchData)
      .subscribe((response: any) => {
          this.licensedOwnerCustomDiscountDetails = response.body[0];
        }, (error) => {
          console.log('error', error);
        });
    }
    else if(this.providerDetails.isParent === false){
      let searchData = {
        providerKey: this.providerDetails.parentProviderKey,
        deleted: false,
       }
       this.commonService.getLicensedOwnerDiscountPrograms(searchData)
       .subscribe((response: any) => {
           this.licensedOwnerCustomDiscountDetails = response.body[0];
           console.log(this.licensedOwnerCustomDiscountDetails)
        }, (error) => {
           console.log('error', error);
      });
    }
  }

  selectCustomerNotPreferredProvidersForServicePlus(){
    this.providerAdminService.selectCustomerNotPreferredProvidersForServicePlus(this.providerDetails.ProviderId)
    .subscribe({
      next: res => this.customerNotPreferredProvidersForServicePlus = res[0],
      error: (e) => console.error(e),
      complete: () => { this.onSort({ column: 'CustomerName', direction: 'asc' })
        this.getCustomerPerferredProviders();
      },
    })
  }

  getCustomerPerferredProviders(){
    this.providerAdminService.getCustomerPerferredProviders(this.providerDetails.ProviderId)
    .subscribe({
      next: res => this.onlyUseForSpecificCustomer = res[0],
      error: (e) => console.error(e),
      complete: () => {console.log(this.onlyUseForSpecificCustomer)
        this.onSort({ column: 'CustomerName', direction: 'asc' })
      },
    })
  }

  // getProviderMetricsDetails(){
  //   const data = {
  //     providerKey: this.providerDetails.ProviderKey,
  //     deleted: null,
  // };
  //   this.providerService.getProviders(data).subscribe({
  //     next: res => this.parentProviderInfo = res.body,
  //     error: (e) => console.error(e),
  //     complete: () => this.getProviderMetricsDetails2(),
  //   })
  // }
  
  // getProviderMetricsDetails2(){
  //   this.providerAdminService.getProviderMetricsForServicePlus(this.parentProviderInfo[0].providerId).subscribe({
  //     next: res => this.providerMetrics = res[0][0],
  //     error: (e) => console.error(e),
  //     complete: () => this.getProviderMetricsDetails3()
  //   })
  // }

  // getProviderMetricsDetails3(){
  //   if(this.providerMetrics.CountOfWorkOrders == null || this.providerMetrics.CountOfWorkOrders == undefined){
  //     this.providerMetrics.CountOfWorkOrders = 0
  //   }  
  //   if(this.providerMetrics.Last30DayAmount == null || this.providerMetrics.Last30DayAmount == undefined){
  //     this.providerMetrics.Last30DayAmount = '$ 0.00'
  //   } 
  //   else{this.providerMetrics.Last30DayAmount = '$ ' + this.providerMetrics.Last30DayAmount}
  //   if(this.providerMetrics.Last180DayAmount == null || this.providerMetrics.Last180DayAmount == undefined){
  //     this.providerMetrics.Last180DayAmount = '$ 0.00'
  //   } 
  //   else{this.providerMetrics.Last180DayAmount = '$ ' + this.providerMetrics.Last180DayAmount}
  //   if(this.providerMetrics.Last365DayAmount == null || this.providerMetrics.Last365DayAmount == undefined){
  //     this.providerMetrics.Last365DayAmount = '$ 0.00'
  //   } 
  //   else{this.providerMetrics.Last365DayAmount = '$ ' + this.providerMetrics.Last365DayAmount}
  //   this.selectCustomerNotPreferredProvidersForServicePlus();
  // }

  // getDynamicMenus() {
  //   this.childMenus = [];
  //   this.menus = [];
  //   this.commonService.getJSON({ userKey: this.providerDetails.userKey }).subscribe(resultMenu => {
  //     resultMenu.forEach(dynamicMenu => {
  //       if (dynamicMenu.system === 'ServicePlus System') {
  //         if (!this.menus.some(e => e.label === dynamicMenu.menu)) {
  //           const temp = {
  //             label: dynamicMenu.menu,
  //             link: '/pages/' + dynamicMenu.menuKey,
  //             permission: dynamicMenu.subMenu1Key != null ? '' : dynamicMenu.policy,
  //             children: []
  //           };
  //           this.menus.push(temp);
  //         }
  //       }
  //     });
  //     this.assignSubMenuOne(this.menus, resultMenu);
  //     this.createMenuUltimateChildArray(this.menus);
  //   }, (error) => {
  //     console.log(error);
  //   });
  // }

  assignSubMenuOne(systemMenus, dynamicMenu) {
    systemMenus.forEach(systemMenus => {
      dynamicMenu.forEach(dynamicMenu => {
        if (dynamicMenu.system === 'ServicePlus System') {
          if (dynamicMenu.menu === systemMenus.label && dynamicMenu.subMenu1 != null) {
            let label = dynamicMenu.subMenu1.replace(' & ', '-');
            label = label.split(' ').join('-');
            label = label.split('/').join('-');

            if (!systemMenus.children.some(e => e.label === dynamicMenu.subMenu1)) {
              const temp = {
                label: dynamicMenu.subMenu1,
                link: systemMenus.link + '/' + label.toLowerCase(),
                permission: dynamicMenu.subMenu2 != null ? '' : dynamicMenu.policy,
                children: []
              }
              systemMenus.children.push(temp);
            }
          }
        }
      });
      this.assignSubMenuTwo(systemMenus.children, dynamicMenu);
    });
  }

  assignSubMenuTwo(systemMenus, dynamicMenu) {
    systemMenus.forEach(systemMenus => {
      dynamicMenu.forEach(dynamicMenu => {
        if (dynamicMenu.system === 'ServicePlus System') {
          if (dynamicMenu.subMenu1 === systemMenus.label && dynamicMenu.subMenu2 != null && dynamicMenu.subMenu1 !== 'Locations') {
            let label = dynamicMenu.subMenu2.replace(' & ', '-');
            label = label.split(' ').join('-');
            label = label.split('/').join('-');

            if (!systemMenus.children.some(e => e.label === dynamicMenu.subMenu2)) {
              const temp = {
                label: dynamicMenu.subMenu2,
                link: systemMenus.link + '/' + label.toLowerCase(),
                permission: dynamicMenu.subMenu2 != null ? '' : dynamicMenu.policy,
                children: []
              }
              systemMenus.children.push(temp);
            }
          }
        }
      });
      this.assignSubMenuThree(systemMenus.children, dynamicMenu);
    });
  }

  assignSubMenuThree(systemMenus, dynamicMenu) {
    systemMenus.forEach(systemMenus => {
      dynamicMenu.forEach(dynamicMenu => {
        if (dynamicMenu.system === 'ServicePlus System') {
          if (dynamicMenu.subMenu2 === systemMenus.label && dynamicMenu.subMenu3 != null && dynamicMenu.subMenu2 !== 'Accounts') {
            let label = dynamicMenu.subMenu3.replace(' & ', '-');
            label = label.split(' ').join('-');
            label = label.split('/').join('-');

            if (!systemMenus.children.some(e => e.label === dynamicMenu.subMenu3)) {
              const temp = {
                label: dynamicMenu.subMenu3,
                link: systemMenus.link + '/' + label.toLowerCase(),
                permission: dynamicMenu.policy,
                children: []
              }
              systemMenus.children.push(temp);
            }
          }
        }
      });
    });
  }

  createMenuUltimateChildArray(networkJson: any) {
    for (let i in networkJson) {
      let tempNode = networkJson[i];
      if (tempNode.children.length === 0) {
        this.childMenus.push(tempNode.label);
      } else {
        this.createMenuUltimateChildArray(tempNode.children);
      }
    }
  }

  updateAccountChanges(){
    if(this.providerDetailsForm.invalid){
      if(this.providerDetailsForm.controls.note.invalid){
        this.openSnackBar('Add Note for the Account Status Change reason')
        return;
      }
    }

    if(this.providerDetailsForm.controls.discountTypeKey.value != 'No Discount' && (this.providerDetailsForm.controls.discountAmount.value == null || this.providerDetailsForm.controls.discountAmount.value === '')){
      this.openSnackBar('Please add a discount amount')
      return;
    }  

    if(this.providerDetailsForm.controls.discountTypeKey.value != 'No Discount' && (this.providerDetailsForm.controls.discountAmount.value === 0)){
      this.openSnackBar('Please add a discount amount other than 0 or change discount type to No Discount') 
      return;
    }

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Save Account Changes', message: 'Are you sure you want to save the changes made to the profile account?',
        yesButton: 'Yes'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if(result == true){
        this.updateProviderAccountDetails()
      }
    })  
  }

  initializeDropDowns() {
    const filter = { deleted: false };
    const membershipStatusReq = this.providerService.getMembershipStatuses(filter);
    const paymentTermsReq = this.providerService.getPaymentTerms(filter);
    const paymentMethodsReq = this.providerService.getPaymentMethods(filter);
    const statusReq = this.commonService.getProviderAccountStatuses({ deleted: false });
    const discountTypesReq = this.providerAdminService.getDiscountTypes(filter);

    this.loading = true;
    forkJoin([membershipStatusReq, paymentTermsReq, paymentMethodsReq, statusReq, discountTypesReq]).subscribe(results => {
      this.membershipStatus = results[0].body || [];
      this.paymentTerms = results[1].body || [];
      this.paymentMethods = results[2].body || [];
      this.providerAccountstatuses = results[3].body || [];
      this.discountTypes = results[4].body || [];
      if (this.paymentTerms.length > 0) {
        let byDay = this.paymentTerms;
        byDay.sort(function (a, b) {
          a = a.paymentTermKey.split(' ')[0];
          b = b.paymentTermKey.split(' ')[0];
          return a - b;
        });
        this.paymentTerms = byDay;
      }
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  updateProviderAccountDetails() {
    if (!!this.providerDetails.providerAccountKey) {
      if (this.providerDetailsForm.invalid) {
        this.providerDetailsForm.markAllAsTouched();
        return false;
      }

      if(this.showNote == true && this.providerDetailsForm.value.accountStatus == 'Do Not Use'){
        this.providerDetailsForm.value.providerDiscontinuedReason = this.providerDetailsForm.value.note;
      }
      else if(this.showNote == true && this.providerDetailsForm.value.accountStatus != 'Do Not Use'){
        this.providerDetailsForm.value.providerDiscontinuedReason = '';
      }

      if(this.licensedOwnerCustomDiscountDetails.discountTypeKey != this.providerDetailsForm.value.discountTypeKey || this.licensedOwnerCustomDiscountDetails.discountAmount != this.providerDetailsForm.value.discountAmount){
        if(this.providerDetailsForm.value.discountTypeKey == 'No Discount'){
          this.providerDetailsForm.value.discountAmount = 0;
          this.providerDetailsForm.controls.discountAmount.setValue(0);
        }
        this.updateLicensedOwnerDiscountPrograms();
      }
 
      const updateData = { ...{ providerAccountKey: this.providerDetails.providerAccountKey }, ...this.providerDetailsForm.value };
      this.loading = true;
      this.providerAdminService.updateProviderAccount(updateData).subscribe(result => {
        this.loading = false;
        if (result.success) {
          if (this.showNote === true) {
            this.insertNote();
            //this.updateAllChildRecords();
            this.updateSublocationAccountStatus();          
          }
          this.next.emit(true);
          this.toast.success(result.message[0], 'Success');
        } else {
          this.toast.warning(result.message[0], 'Error');
        }
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.dialog.open(AlertDialogComponent, {
        disableClose: true,
        width: '24%',
        data: {
          message: 'Please select provider'
        }
      });
    }
  }

  updateLicensedOwnerDiscountPrograms(){
    let searchData = {
      licensedOwnerDiscountProgramKey : this.licensedOwnerCustomDiscountDetails.licensedOwnerDiscountProgramKey ,
      providerKey: this.providerDetails.ProviderKey,
      discountTypeKey: this.providerDetailsForm.value.discountTypeKey,
      discountAmount: this.providerDetailsForm.value.discountAmount,
      deleted: false,
    }
    this.commonService.updateLicensedOwnerDiscountPrograms(searchData)
    .subscribe({
      next: res => {res},
      error: (e) => console.log(e),
      complete: () => {
        console.log('completed updating generic api for licensed owner discount programs')
      }
    })
  }

  statusChanged() {
    console.log(this.providerDetailsRes.accountStatus)
    if(this.providerDetailsForm.value.accountStatus != this.providerDetailsRes.accountStatus) {
      this.showNote = true;
      this.providerDetailsForm.controls.note.setValidators([Validators.required]);
      this.providerDetailsForm.controls.note.updateValueAndValidity();
    }

    else if(this.providerDetailsForm.value.accountStatus == this.providerDetailsRes.accountStatus){
      this.showNote = false;
      this.providerDetailsForm.controls.note.removeValidators([Validators.required]);
      this.providerDetailsForm.controls.note.updateValueAndValidity();
    }
  }

  discountChanged(){
    this.providerDetailsForm.value;
    this.providerDetailsForm.controls.paymentMethodKey.value;
  }

  handleError = (control: string, error: string) => {
    return this.providerDetailsForm.controls[control].hasError(error);
  }

  // updateAllChildRecords() {

  //   if(this.providerDetails.providerKey == null || !this.providerDetails.providerKey){
  //     this.providerDetails.providerKey = this.providerDetails.ProviderKey;
  //   }
  //   const filter = {
  //     parentKey: this.providerDetails.providerKey,
  //   };
  //   this.loading = true;
  //   this.providerService.getProviderDetails(filter).subscribe(result => {
  //     this.loading = false;
  //     const res: any = result.body;
  //     if (res.length > 0) {
  //       const providerKeyArr = [];
  //       res.forEach(element => {
  //         providerKeyArr.push(this.providerService.getProviderAccounts({ providerKey: element.providerKey }));
  //       });

  //       this.loading = true;
  //       forkJoin(providerKeyArr).subscribe(result => {
  //         this.loading = false;
  //         if (result.length > 0) {
  //           result.forEach(element => {
  //             element['body'].forEach(childElement => {
  //               this.providerAccountRecords.push(childElement.providerAccountKey);
  //             });
  //           });

  //           const updateAccounts = [];
  //           const insertNotes = [];
  //           const currentTime = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
  //           this.providerAccountRecords.forEach(element => {
  //             const updateData = {
  //               providerAccountKey: element,
  //               accountStatus: this.providerDetailsForm.controls.accountStatus.value,
  //             };
  //             let note = '';
  //             if (!!this.providerDetailsForm.controls.note.value) {
  //               note = 'Status updated by ' + this.providerDetails.userName + ' on ' + this.providerDetailsForm.controls.note.value + ' on ' + currentTime;
  //             } else {
  //               note = 'Status updated by ' + this.providerDetails.userName + ' on ' + currentTime;
  //             }
  //             const notesData = {
  //               providerAccountKey: element,
  //               note,
  //               privateForProvider: false,
  //               privateForAccount: false,
  //               systemDefault: false,
  //               insertedUserKey: sessionStorage.getItem('userKey'),
  //               updatedUserKey: '',
  //               deleted: false
  //             };
  //             insertNotes.push(notesData);
  //             updateAccounts.push(updateData);
  //           });

  //           // Insert Notes in child and Update all child status to Do Not Use
  //           const addNotesReq = this.providerAdminService.addProviderAccountNotesBatch(insertNotes);
  //           const updateAccountReq = this.providerAdminService.updateProviderAccountBatch(updateAccounts);

  //           this.loading = true;
  //           forkJoin([addNotesReq, updateAccountReq]).subscribe(results => {
  //             this.loading = false;
  //           }, (error) => {
  //             console.log(error);
  //             this.loading = false;
  //           });

  //         }
  //       }, (error) => {
  //         console.log(error);
  //         this.loading = false;
  //       });
  //     }
  //   }, (error) => {
  //     console.log(error);
  //     this.loading = false;
  //   });
  // }

  updateSublocationAccountStatus(){
    this.providerAdminService.updateProviderAccountToMatchParent(this.providerDetails.ProviderKey).subscribe({
      next: res => console.log(res),
      error: (e) => console.log(e),
      complete: () => {;}
    })
  }

  insertNote() {
    const notesData = {
      providerAccountKey: this.providerDetails.providerAccountKey,
      note: this.providerDetailsForm.controls.note.value,
      privateForProvider: false,
      privateForAccount: false,
      systemDefault: false,
      insertedUserKey: sessionStorage.getItem('userKey'),
      updatedUserKey: '',
      deleted: false
    };

    this.loading = true;
    this.providerAdminService.addProviderAccountNotes(notesData).subscribe(result => {
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  // openSnackBar(message): void {
  //   this._snackBar.open(message, '', { duration: 3000 })
  // }

  onSort({ column, direction }: SortEvent) {
    this.customerNotPreferredProvidersForServicePlus = this.utilityService.sortData(this.headers, column, direction, this.customerNotPreferredProvidersForServicePlus);
    this.arrowDirection = direction;
  }

  deleteDoNotUse(provider) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: {header: 'Remove Do Not Use Status', message: 'Are you sure you want to delete this provider from the Do Not Use List?',
      yesButton: 'Yes'
    }
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result == true){
        this.providerAdminService.deleteCustomerNotPreferredProvidersFromServicePlus(provider.TSPlKey).subscribe({
          next: res => {res},
          error: (e) => {console.error(e)},
          complete: () => {this.openSnackBar('Vendor successfully removed from DNU.'),
            this.selectCustomerNotPreferredProvidersForServicePlus()},
        });
      }
    })
  }

  addToDNUList(){
      let dialogRef = this.dialog.open(AddCustomerToDNUDialogComponent, {
        data: this.providerDetails.ProviderId,
        width: '800px',
        //height: '800px'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      
      this.providerAdminService.insertCustomerNotPreferredProvidersFromServicePlus(result).subscribe({
        next: res => this.providerAddedToDNU = res.body[0],
        error: (e) => console.log(e),
        complete: () => { this.selectCustomerNotPreferredProvidersForServicePlus();}
      })
    })
  }  

  addToOnlyUseForSpecificCustomer(){
    let dialogRef = this.dialog.open(AddCustomerPerferredProvidersComponent, {
      data: this.providerDetails.ProviderId,
      width: '800px',
      //height: '800px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)

      if( this.onlyUseForSpecificCustomer != null && this.onlyUseForSpecificCustomer.length > 0 ){
        let checkForDouble = this.onlyUseForSpecificCustomer.filter(obj => obj.CustomerKey == result.inCustomerKey)
        console.log(checkForDouble);
        if(checkForDouble != ''){
          this.openSnackBar('Vendor is already on the only use for specific customer list.')
          return;
        }
      }

      if(this.onlyUseForSpecificCustomer != null && this.onlyUseForSpecificCustomer != undefined && this.onlyUseForSpecificCustomer != '' && this.onlyUseForSpecificCustomer.length != 0){
        for(let i = 0; i < this.onlyUseForSpecificCustomer.length; i++){
          this.listOfProvidersforPerferred = this.onlyUseForSpecificCustomer.map(item => item.CustomerKey)
        }
        this.listOfProvidersforPerferred.push(result.inCustomerKey)}

      else if(this.onlyUseForSpecificCustomer == null || this.onlyUseForSpecificCustomer == undefined || this.onlyUseForSpecificCustomer == '' || this.onlyUseForSpecificCustomer.length == 0){
        this.listOfProvidersforPerferred = result.inCustomerKey}

      if(this.listOfProvidersforPerferred != '' && this.listOfProvidersforPerferred != null && this.listOfProvidersforPerferred != undefined ){
        this.listOfProvidersforPerferred = this.listOfProvidersforPerferred.toString()}

      const data = {
        inProviderId: result.inProviderId,
        inListOfCustomerKeys: this.listOfProvidersforPerferred
      }

      this.providerAdminService.updateCustomerPerferredProviders(data).subscribe({
        next: res => {res},
        error: (e) => console.log(e),
        complete: () => {this.openSnackBar("Vendor successfully added to 'Only Use for Specific Customer'."),
          this.getCustomerPerferredProviders();}
      })
    })
  }  

  deleteCustomerPerferredProviders(provider){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Remove Customer Preferred Status', message: 'Are you sure you want to delete this provider from the only use for specific customer status?',
        yesButton: 'Yes'
      }
      });

    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        if(this.onlyUseForSpecificCustomer.length == 1){
          this.listOfProvidersforPerferredToRemove = null;}
        else if(this.onlyUseForSpecificCustomer.length > 1){
          this.listOfProvidersforPerferredToRemove = this.onlyUseForSpecificCustomer.filter(obj => obj.CustomerKey != provider.CustomerKey)
          this.listOfProvidersforPerferredToRemove = this.listOfProvidersforPerferredToRemove.map(item => item.CustomerKey)  
          this.listOfProvidersforPerferredToRemove = this.listOfProvidersforPerferredToRemove.toString()
        } 

      const data = {
        inProviderId: this.providerDetails.ProviderId,
        inListOfCustomerKeys: this.listOfProvidersforPerferredToRemove
      }

      this.providerAdminService.updateCustomerPerferredProviders(data).subscribe({
        next: res => console.log(res),
        error: (e) => console.log(e),
        complete: () => {this.openSnackBar("Vendor successfilly removed from 'Only Use for Specific Customer' list."), 
          this.getCustomerPerferredProviders();}
      })
      }
    })
  }

openSnackBar(message): void {
  this._snackBar.open(message, '', { duration: 3000 })
}

}
