import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalDialogsComponent } from 'src/app/dialogs/common-modal-dialogs/common-modal-dialogs.component';

@Component({
  selector: 'app-menulist',
  templateUrl: './menulist.component.html',
  styleUrls: ['./menulist.component.scss']
})
export class MenulistComponent implements OnInit, OnChanges {

  @Input() menuList: any;
  @Input() selectedCustomerKey: any;
  @Input() selectedProviderKey: any;
  @Input() platForm: string;
  @Input() isMasterLicensedOwner = false;
  @Input() isViewMasterCustomerDetails = false;
  childMenus: any = [];
  customerKey: string;
  providerKey: string;
  indexedArray = [0, 1, 2, 3, 4, 5];

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.platForm === 'ServiceProvider') {
      this.indexedArray = [0, 1, 2, 3, 4, 5, 6, 7];
      this.providerKey = this.selectedProviderKey;
    } else {
      this.customerKey = this.selectedCustomerKey;
    }
    this.childMenus = this.menuList;
  }

  openEditAvailabilityDialog(index: any) {
    if (this.indexedArray.lastIndexOf(index) !== -1) {
      const height = index === 1 || index === 3  ? '95%' : 'auto'
      const dialogRef = this.dialog.open(CommonModalDialogsComponent, {
        width: '90%',
        maxHeight: '96%',
        height,
        disableClose: true,
        position: {
          top: '4%',
        },
        data: {
          key: this.customerKey || this.providerKey,
          selectedIndex: index,
          platForm: this.platForm,
          isMasterLicensedOwner: this.isMasterLicensedOwner
        }
      });
    }
  }
}
