<nb-card class="custom-height">
    <nb-card-body>
        <div class="row">
            <div class="col-md-12">
                <div class="text-middle text-center">
                    <h1>This feature is not available yet</h1>
                    <div class="mt-2 text-center">
                        <!-- <a class="btn btn-link" href="/#/pages/de46ed58-fafc-473c-a246-d0c1eec12ad6">
                            <i class="fa fa-home"></i>Take Me To Dashboard
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>