import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NbToastrService } from '@nebular/theme';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { TierService } from 'src/app/services/tier.service';
import { forkJoin } from 'rxjs';
import { SystemSecuruityMenuService } from 'src/app/services/system-securuity-menu.service';

@Component({
  selector: 'app-add-limits',
  templateUrl: './add-limits.component.html',
  styleUrls: ['./add-limits.component.scss']
})
export class AddLimitsComponent implements OnInit {
  addLimitsForm: UntypedFormGroup;
  licensedOwnerOfferingData: any;
  licensedOwnerOffering: any;
  subServiceData: any;
  loading = false;
  OperatingSchedules: any;
  approvalDetails: any;
  securityGroups: any;
  heading = 'Add Overall Threshold';
  services: any;
  hierarchyTiers: any;
  customerAuthorization = false;
  customerAdmin: any;
  isCustomerUser = false;

  constructor(private commonService: CommonService,
              private toast: NbToastrService,
              public formBuilder: UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddLimitsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private usersService: UsersService,
              private tierService: TierService,
              private systemSecuruityMenuService: SystemSecuruityMenuService) { }

  ngOnInit() {
    this.approvalDetails = this.data;
    this.customerAuthorization = this.approvalDetails.userDetails.customerAuthorization;
    this.customerAdmin = this.approvalDetails.userDetails.customerAdmin;
    this.isCustomerUser = this.approvalDetails.userDetails.userKey ? true : false;

    this.addLimitsForm = this.formBuilder.group({
        operatingScheduleKey: ['', Validators.required],
        minimumThreshold: ['', Validators.required],
        maximumThreshold: [''],
        quantityMinimum: ['', Validators.required],
        quantityMaximum: [''],
        securityGroupKey: ['', Validators.required],
        associationKey: this.approvalDetails.userDetails.associationKey,
        userKey: this.approvalDetails.userDetails.userKey,
        userTypeKey: this.approvalDetails.userDetails.userTypeKey,
        customerTierStructureKey: ['', Validators.required],
        licensedOwnerOfferingKey: [''],
        deleted: false,
        systemDefault: true,
        insertedUserKey: sessionStorage.getItem('userKey'),
        updatedUserKey: sessionStorage.getItem('userKey'),
        insertedTimestamp: new Date().getTime(),
        updatedTimestamp: new Date().getTime()
      });

    if (!this.approvalDetails.isOverallLimit) {
      this.heading = 'Add Service Exception Threshold';
      this.addLimitsForm.controls.licensedOwnerOfferingKey.setValue(this.approvalDetails.licensedOwnerOfferingKey);
    } else {
      if (this.data.isMasterCustomer) {
        this.addLimitsForm.controls.minimumThreshold.setValue(1);
        this.addLimitsForm.controls.maximumThreshold.setValue(1);
      }
      this.addLimitsForm.get('quantityMinimum').clearValidators();
      this.addLimitsForm.updateValueAndValidity();
    }

    if (this.isCustomerUser) {
      this.addLimitsForm.get('customerTierStructureKey').clearValidators();
      this.addLimitsForm.get('securityGroupKey').clearValidators();
      this.addLimitsForm.updateValueAndValidity();
    }
    this.getDropDowns();
  }

  getDropDowns() {
    this.loading = true;
    const tierData = {
      customerKey : this.customerAdmin ? this.approvalDetails.userDetails.associationKey : sessionStorage.getItem('customerKey'),
      deleted : false
    };
    const operatingScheduleFilter = this.customerAuthorization ? {systemDefault: true, deleted: false} : {};
    const operatingSchedule = this.commonService.getOperatingSchedules(operatingScheduleFilter);
    // Customers security Groups
    const securityGroup = this.systemSecuruityMenuService.getSecurityGroupList({userTypeKey : '90dc6b51-9731-439b-bd21-40685f989f24'});
    const hierarchyTier = this.tierService.getTierDetails(tierData);

    forkJoin([operatingSchedule, securityGroup, hierarchyTier]).subscribe(result => {
      this.OperatingSchedules = result[0].body;
      this.securityGroups = result[1].body;
      this.hierarchyTiers = result[2].body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  saveLimits() {
    if (this.addLimitsForm.invalid) {
      this.addLimitsForm.markAllAsTouched();
      return false;
    } else {
      let filter;

      if (this.approvalDetails.isOverallLimit) {
          filter = {
            customerKey: this.addLimitsForm.controls.associationKey.value,
            userKey: this.addLimitsForm.controls.userKey.value,
            securityGroupKey: this.addLimitsForm.controls.securityGroupKey.value,
            customerTierStructureKey: this.addLimitsForm.controls.customerTierStructureKey.value,
            operatingScheduleKey: this.addLimitsForm.controls.operatingScheduleKey.value,
            overallLimits: true
          };
      } else {
          filter = {
            customerKey: this.addLimitsForm.controls.associationKey.value,
            userKey: this.addLimitsForm.controls.userKey.value,
            securityGroupKey: this.addLimitsForm.controls.securityGroupKey.value,
            customerTierStructureKey: this.addLimitsForm.controls.customerTierStructureKey.value,
            operatingScheduleKey: this.addLimitsForm.controls.operatingScheduleKey.value,
            licensedOwnerOfferingKey: this.addLimitsForm.controls.licensedOwnerOfferingKey.value,
            overallLimits: false
          };
      }

      this.usersService.getAuthorizationThresholdsDetails(filter).subscribe(result => {
        if (result.body.length > 0) {
          if (this.addLimitsForm.controls.licensedOwnerOfferingKey.value) {
            let data = result.body.filter(item => item.licensedOwnerOfferingKey ===
                                                  this.addLimitsForm.controls.licensedOwnerOfferingKey.value);

            if (data) {
              this.toast.warning('Record already exist', 'Error');
            } else {
              this.saveThreshold();
            }
          } else {
            this.toast.warning('Record already exist', 'Error');
          }
        } else {
          this.saveThreshold();
        }
      });
    }
  }

  saveThreshold() {
    this.loading = true;
    const data = this.addLimitsForm.value;
    this.usersService.addAuthorizationThresholds(data).subscribe(res => {
      if (res.success) {
        this.toast.success(res.message[0], 'success');
      } else {
        this.toast.warning(res.message[0], 'Error');
      }
      this.close();
      this.loading = false;
    });
  }

  removeRequired(event) {
    if (event === 'threshold') {
      this.addLimitsForm.get('quantityMinimum').clearValidators();
      this.addLimitsForm.get('quantityMinimum').updateValueAndValidity();
      this.addLimitsForm.controls.minimumThreshold.setValidators([Validators.required]);
      this.addLimitsForm.get('minimumThreshold').updateValueAndValidity();
    } else {
      this.addLimitsForm.get('minimumThreshold').clearValidators();
      this.addLimitsForm.get('minimumThreshold').updateValueAndValidity();
      this.addLimitsForm.controls.quantityMinimum.setValidators([Validators.required]);
      this.addLimitsForm.get('quantityMinimum').updateValueAndValidity();
    }
  }

  close() {
    this.dialogRef.close();
  }

  public errorHandling = (control: string, error: string) => {
    return this.addLimitsForm.controls[control].hasError(error);
  }

}
