<div class="modal-header accountClass">
    <h6 class="modal-title">Provider Account Setup</h6>
    <span class="accountName">{{accountName}}</span>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div [ngClass]="selectedIndex == 0 ? 'add-scroll' : '' ">
    <mat-tab-group class="mat-tab-group" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Details">
            <ngx-provider-account-details [providerAccount]="providerAccount" [isParent]="isParent"
                (saveCompleted)='saveCompleted($event)' *ngIf="selectedIndex == 0">
            </ngx-provider-account-details>
        </mat-tab>
        <!-- <mat-tab label="Services">
            <ngx-vendor-services [providerAccount]="providerAccount" [isParent]="isParent"></ngx-vendor-services>
        </mat-tab> -->
        <mat-tab label="Rates">
            <ngx-setup-rates [providerAccount]="providerAccount" [isParent]="isParent" *ngIf="selectedIndex == 1">
            </ngx-setup-rates>
        </mat-tab>
        <mat-tab label="Discounts">
            <ngx-vendor-discounts [providerAccount]="providerAccount" [isParent]="isParent" *ngIf="selectedIndex == 2">
            </ngx-vendor-discounts>
        </mat-tab>
        <mat-tab label="ETAs">
            <ngx-setup-eta [providerAccount]="providerAccount" [isParent]="isParent" *ngIf="selectedIndex == 3">
            </ngx-setup-eta>
        </mat-tab>
        <mat-tab label="Billing">
            <ngx-payment-terms [providerAccount]="providerAccount" [isParent]="isParent" *ngIf="selectedIndex == 4">
            </ngx-payment-terms>
        </mat-tab>
        <mat-tab label="Notes">
            <ngx-account-notes [providerAccount]="providerAccount" [isParent]="isParent" *ngIf="selectedIndex == 5">
            </ngx-account-notes>
        </mat-tab>
        <!-- <mat-tab label="Insurance">
            <ngx-account-insurance *ngIf="selectedIndex == 7">
            </ngx-account-insurance>
        </mat-tab> -->
        <mat-tab *ngIf="licensedOwnerKey" label="Customer Preferences">
            <ngx-customer-preferences [providerAccount]="providerAccount" [isParent]="isParent"
                *ngIf="selectedIndex == 6"></ngx-customer-preferences>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="modal-footer">
    <button *ngIf="(tab === 4 && !disablePaymentTerm) || tab === 0" class="btn btn-orange btn-md mt-2 mr-2"
        (click)="next(false)">
        Save
    </button>
    <button class="btn btn-orange btn-md mt-2 mr-2" (click)="next(true)" *ngIf="tab !== 0">
        Next
    </button>
    <button *ngIf="tab === 4 && (!disablePaymentTerm) || tab === 0" class="btn btn-orange btn-md mr-2 mt-2"
        type="button" (click)="next(true)">
        Save & Next
    </button>
</div>