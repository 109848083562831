import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { globalVariables } from 'src/assets/global_variables';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderAdminService {

  constructor(private http: HttpClient) { }

  // get provider admin list
  getProviderAdminList(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderAdminList`, data);
  }

  // Gets states that the provider has sublocations in
  getProviderOccupiedStates(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getProviderOccupiedStates/${data}`);
  }

  // Gets list of providers from AxelPoint
  getProviderSearchV3(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderSearchV3`, data).pipe(map((res) => res.body || []));
  }

  //get provider parent locations, for provider search
  getProviderParentLocations(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderParentLocations`, data);
  }

  //get provier locations from AxlePoint, for provider migration
  getProviderLocationForServicePlus(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getProviderLocationForServicePlus/${data}`);
  }

  // Get provier contact information details
  selectProviderContactDetails(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}selectProviderContactDetails/${data}`);
  }

  // Insert a new service line item for Axlepoint
  insertProviderContactDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}insertProviderContactDetails`, data);
  }

  //get provier service line items from AxlePoint
  selectProviderServiceLineItemsForServicePlus(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}selectProviderServiceLineItemsForServicePlus/${data}`);
  }

  // Get provier services that the provider offeres
  selectProviderOfferedSubservices(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}selectProviderOfferedSubservices/${data}`);
  }

  //insert a new service line item for Axlepoint
  insertProviderServiceLineItemsForServicePlus(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}insertProviderServiceLineItemsForServicePlus`, data);
  }

  //update an existing service line item for Axlepoint
  updateProviderServiceLineItemsForServicePlus(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}updateProviderServiceLineItemsForServicePlus`, data);
  }

  // Deletes an existing service line item for Axlepoint
  deleteProviderServiceLineItemsForServicePlus(data): Observable<any> {
    let deleteData: any = {
      body: data
    }
    return this.http.delete<any>(`${environment.baseUrl}deleteProviderServiceLineItemsForServicePlus`, deleteData);
  }

  // Insert provider's account information
  insertProviderAccounts(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}insertProviderAccounts/${data}`, data);
  }

  // update provider account details
  updateProviderAccount(updateData): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/provideraccounts`, updateData);
  }

  // Updates sublocations account status to match parent's account status
  updateProviderAccountToMatchParent(ParentProviderKey): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}updateProviderAccountToMatchParent/${ParentProviderKey}`, ParentProviderKey);
  }

  // get provider insurance details
  getProviderInsuranceDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerinsurances/get`, data);
  }

  // get licensed owner insurance requirements
  getLicensedOwnerInsuranceRequirements(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerinsurancerequirements/get`, data);
  }

  // add provider insurances
  addProviderInsurances(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerinsurances`, data);
  }

  // Gets Customer List from AxelPoint 
  getCustomerList(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getCustomerList`);
  }

  // Gets SubCustomer List from AxelPoint 
  getSubCustomerList(customerKey: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getSubCustomerList/${customerKey}`);
  }

  // Get Customer Location List from AxelPoint
  getCustomerLocationList(subCustomerKey: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getCustomerLocationList/${subCustomerKey}`);
  }

  // Add Customer Not Preferred Provider from Service Plus
  insertCustomerNotPreferredProvidersFromServicePlus(data): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}insertCustomerNotPreferredProvidersFromServicePlus`, data);
  }

  getProviderServices(inProviderKey): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}selectProviderServices/${inProviderKey}`);
  }

  //insert a new service for a provider
  insertProviderServices(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}insertProviderServices`, data);
  }

  // delete provider insurances
  deleteProviderServiceOfferings(data): Observable<any> {
    const deleteServiceData: any = {
      body: data
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providerserviceofferings`, deleteServiceData);
  }

  //addNewProviderApplication on AxelPoint Database
  addNewProviderApplicationAxel(data): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}addNewProviderApplicationAxel`, data);
  }

  //addNewProviderApplication on Auxilium
  addNewProviderApplication(data): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}addNewProviderApplication`, data);
  }

  // delete provider matching application 
  deleteProviderApplicationMatch(data): Observable<any> {
    let deleteData: any = {
      body: data
    }
    return this.http.delete<any>(`${environment.baseUrl}deleteProviderApplicationMatch`, deleteData);
  }

  // Add Provider Application as a Parent
  addApplicationAsParent(inProviderPK): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}addApplicationAsParent/${inProviderPK}`, inProviderPK);
  }

  // Add Provider Application as a Sublocation 
  addApplicationAsSublocation(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}addApplicationAsSublocation`, data);
  }

  // Update Customer's perferred providers list from AxelPoint for ServicePlus
  servicePlus_updateCompany(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}servicePlus_updateCompany`, data);
  }

  // Update Customer's perferred providers list from AxelPoint for ServicePlus
  servicePlus_updateAddress(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}servicePlus_updateAddress`, data);
  }

  removeOnboardProvidersWithActiveStatus(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}removeOnboardProvidersWithActiveStatus`);
  }

  // insert provider parent matching application 
  insertProviderParentApplicationMatch(inProviderPK): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}insertProviderParentApplicationMatch/${inProviderPK}`, inProviderPK);
  }

  // insert provider location matching application 
  insertProviderLocationApplicationMatch(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}insertProviderLocationApplicationMatch`, data);
  }

  updateProviderMembershipApplicationMatch(data): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}updateProviderMembershipApplicationMatch`, data);
  }

  // Gets provider's metrics from AxelPoint for ServicePlus
  getProviderMetricsForServicePlus(data): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}getProviderMetricsForServicePlus/${data}`);
  }

  // Gets Customer's Do Not Use Providers list from AxelPoint for ServicePlus
  selectCustomerNotPreferredProvidersForServicePlus(data): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}selectCustomerNotPreferredProvidersForServicePlus/${data}`);
  }

  // Deletes Provider from Customer's Do Not Use Providers list 
  deleteCustomerNotPreferredProvidersFromServicePlus(inTSPLKey): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}deleteCustomerNotPreferredProvidersFromServicePlus/${inTSPLKey}`);
  }

  // Gets Customer's perferred providers list from AxelPoint for ServicePlus
  getCustomerPerferredProviders(data): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}getCustomerPerferredProviders/${data}`);
  }

  // Update Customer's perferred providers list from AxelPoint for ServicePlus
  updateCustomerPerferredProviders(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}updateCustomerPerferredProviders`, data);
  }

  // Get potential duplicates for provider applications of prospect providers
  getPotentialDuplicateLocations(data): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}getPotentialDuplicateLocations/${data}`);
  }

  // get insurance types
  getInsuranceTypes(data: any = {}): Observable<any> {if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/insurancetypes/get`, data);
  }

  // update provider insurances
  updateProviderInsurances(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerinsurances`, data);
  }

  // delete provider insurances
  deleteProviderInsurances(data): Observable<any> {
    const deleteInsuranceData: any = {
      body: data
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providerinsurances`, deleteInsuranceData);
  }

  // get discount types
  getDiscountTypes(data: any = {}): Observable<any> {if (data.deleted === undefined) {
    data.deleted = false;
    }
  return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/discounttypes/get`, data);
  }

    // update provider account details batch
  updateProviderAccountBatch(updateData): Observable<any> {
    return this.http.put(`${environment.apiUrl}/serviceplus/provideraccounts/batch`, updateData);
  }

  // add provider account notes batch
  addProviderAccountNotesBatch(insertData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/serviceplus/provideraccountnotes/batch`, insertData);
  }

  // get provider account notes
  getProviderAccountNotes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountnotes/get`, data);
  }

  // add provider account notes
  addProviderAccountNotes(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, data);
  }

  // delete provider account notes
  deleteProviderAccountNotes(data): Observable<any> {
    const deleteNotesData: any = {
      body: data
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, deleteNotesData);
  }

  getUsersTimezone(geocode) {
    return this.http.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${geocode.lat},${geocode.lng}&timestamp=1458000000&key=${globalVariables.googleMapApiKey}`);
  }

  updateProfilePicture(updatedData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}uploadFile`, updatedData);
  }

  deleteProfilePicture(fileName: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}deleteFile`, fileName);
  }

  updateProviderChildDetails(childData): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/providers/batch`, childData);
  }

  getAddressDetails(address) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${globalVariables.googleMapApiKey}`);
  }

  // Update provider insurances batch
  updateProviderInsurancesBatch(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerinsurances/batch`, data);
  }

}
