<div class="row ml-0 mr-0">
    <div class="col-md-12 text-center alert-warning">
        Please select the services you can perform. Click on the lower center edge of the bubble to view the lower level
        services.
    </div>
</div>
<div class="row w3-panel ml-0 mr-0" *ngIf="isParentRecord">
    <!-- <div class="col-md-2 pr-1 pl-1">
        <i class="far fa-rectangle-landscape fa-3x red-rect"></i>
        <span class="rect-text"> Service not selected</span>
    </div>
    <div class="col-md-2 pr-1 pl-1">
        <i class="far fa-rectangle-landscape fa-3x green-rect"></i>
        <span class="rect-text"> Service selected (Primary Approved)</span>
    </div>
    <div class="col-md-2 pr-1 pl-1">
        <i class="far fa-rectangle-landscape fa-3x yellow-rect"></i>
        <span class="rect-text"> Service selected (Secondary Approved)</span>
    </div>
    <div class="col-md-2">
        <i class="fad fa-check-square fa-2x bubble-user-index-icon"></i>
        <span class="rect-text"> Add Service</span>
    </div>
    <div class="col-md-2">
        <i class="fad fa-window-close fa-2x bubble-user-index-icon"></i>
        <span class="rect-text"> Remove Service</span>
    </div> -->
    <div class="col-md-12 pr-1 pl-1">
        <i class="far fa-rectangle-landscape fa-3x blue-rect"></i>
        <span class="rect-text mr-1"><p class="ml-2">A Bold Blue border indicates that the data is being displayed from the Corporate
            Office. Any updates will update this data for the Location.</p></span>
    </div>
</div>