import { Component, Inject, OnInit, EventEmitter, Input, Output, OnChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakDownProvidersService } from '../../services/breakdown-providers.service';
import { BreakdownProvider } from '../../models/breakdown-providers.model';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, UntypedFormBuilder,ReactiveFormsModule, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { patterns } from 'src/assets/patterns';
import { AddressComponent } from 'ngx-google-places-autocomplete-esb/lib/objects/addressComponent';
import { GooglePlaceDirective, GooglePlaceModule } from 'ngx-google-places-autocomplete-esb';
import { BrowserModule } from '@angular/platform-browser';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { CommonService } from 'src/app/services/common.service';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'ngx-add-new-application-dialog',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatRadioModule, MatDialogModule, MatButtonModule, FormsModule, MatDialogModule,
    MatInputModule, GooglePlaceModule, ReactiveFormsModule, MatSelectModule, SharedModule, MatSnackBarModule,
    MatCheckboxModule],
  templateUrl: './add-new-application-dialog.component.html',
  styleUrls: ['./add-new-application-dialog.component.scss']
})
export class AddNewApplicationDialogComponent implements OnInit {
 
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  providerApplicationForm: UntypedFormGroup;
  searchAddress: any;
  formattedAddress: string;
  latitude = 33.013438;
  longitude = -96.772337;
  street_number: any;
  route: any;
  subpremise: any;
  statesData: any;
  addNewProviderAxelInfo: any = [];

  constructor(
    private breakDownProvidersService: BreakDownProvidersService,
    public dialogRef: MatDialogRef<AddNewApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BreakdownProvider,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private providerAdminService : ProviderAdminService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loadFormDetails();
    this.getStates();
  }

  loadFormDetails(){
    this.providerApplicationForm = this.formBuilder.group({
      //providerPK: [''],
      companyName: ['', [Validators.required]],
      providerAddress: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      ownerFirstName: ['', [Validators.required]],
      ownerLastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required,Validators.pattern('[0-9]{10}')]],
      emailAddress:  ['', [Validators.required,Validators.pattern(patterns.emailPattern)]],
      einNumber: [null, [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [null],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      county: ['', Validators.required],
      country: ['', Validators.required],
      category: ['', [Validators.required, Validators.minLength(3)]],
    })
  }

  getStates() {
    if (sessionStorage.getItem('states') == null) {
      this.commonService.getstates().subscribe(result => {
        this.statesData = result.body;
        sessionStorage.setItem('states', JSON.stringify(result));
      });
    } else {
      this.statesData = JSON.parse(sessionStorage.getItem('states')).body;
    }   
  }

   /**
   * Method to get selected address and set latitude and longitude
   * @param { address }, Searched Address
   */
  public addressChanged(address: Address){

    this.searchAddress = address.address_components;

    this.providerApplicationForm.controls.address1.setValue(address.formatted_address);
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();

    this.searchAddress.forEach(element => {
      element.types.forEach(data => {

        switch(data) { 
          case 'street_number': { 
            this.street_number = element.long_name;
             break; 
          } 
          case 'route': { 
            this.route = element.long_name;
             break; 
          } 
            default: { 
              //statements; 
              break; 
            } }

        if (data === 'postal_code') {
          this.providerApplicationForm.controls.postalCode.setValue(element.long_name);
        } 
        else if (data === 'country') {
          this.providerApplicationForm.controls.country.setValue(element.long_name);
        } 
        else if (data === 'administrative_area_level_1') {
          this.providerApplicationForm.controls.state.setValue(element.short_name);
        } 
        else if (data === 'locality') {
          this.providerApplicationForm.controls.city.setValue(element.long_name);
        } 
        else if (data === 'subpremise') {
          this.providerApplicationForm.controls.address2.setValue(element.long_name)
        }
        else if (data === 'administrative_area_level_2'){
          this.providerApplicationForm.controls.county.setValue(element.long_name)
        }
      });

      this.providerApplicationForm.controls.address1.setValue(this.street_number + ' ' + this.route);

    });
  }

  FEINFormat(event){
    console.log(event)

    const numberValues = this.providerApplicationForm.controls.einNumber.value.replace(/\D/g, '');

    this.providerApplicationForm.value.einNumber = this.providerApplicationForm.controls.einNumber.value.replace(/\D/g, '');

    this.providerApplicationForm.patchValue({
      einNumber: this.providerApplicationForm.value.einNumber,
    })

    if(this.providerApplicationForm.value.einNumber.length > 2){
        console.log(this.providerApplicationForm.value.einNumber.length)
        let newFEIN = numberValues.slice(0, 2) + '-' + numberValues.slice(2) ;
        console.log(newFEIN)
        this.providerApplicationForm.patchValue({
          einNumber: newFEIN,
        })
    }
    else{
        {emitEvent: false}
    }
    console.log(this.providerApplicationForm.value.einNumber)
  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitApplication(){
  
    console.log(this.providerApplicationForm)
    if (this.providerApplicationForm.invalid){
      console.log("application has invalid or missing inputs");
      this.openSnackBar('Missing or invalid information.');
      return;}
  
    const providerData = {
      companyName: this.providerApplicationForm.get('companyName').value,
      ownerFirstName: this.providerApplicationForm.get('ownerFirstName').value,
      ownerLastName: this.providerApplicationForm.get('ownerLastName').value,
      address1: this.providerApplicationForm.get('address1').value,
      address2: this.providerApplicationForm.get('address2').value,
      city: this.providerApplicationForm.get('city').value,
      countyName: this.providerApplicationForm.get('county').value,
      latitude: this.latitude,
      longitude: this.longitude,
      stateAbv: this.providerApplicationForm.get('state').value,
      postalCode: this.providerApplicationForm.get('postalCode').value,
      phoneNumber: '+1' + this.providerApplicationForm.get('phoneNumber').value,
      emailaddress: this.providerApplicationForm.get('emailAddress').value,
      userKey: sessionStorage.getItem('userKey'),
      axlePointCOMlCompanyKey: 0,
      axlePointADDlAddressKey: 0,
      supervisorTSLKey: null,
      woKey: null,
    }

    this.providerAdminService.addNewProviderApplicationAxel(providerData).subscribe({
      next: res => this.addNewProviderAxelInfo = res.body[0],
      error: (e) =>  {this.openSnackBar('Unable to add new provider.'); console.log(e)},
      complete: () => this.addNewProvider() 
    })
  }

  addNewProvider(){

    const providerData2 = {
      companyName: this.providerApplicationForm.get('companyName').value,
      ownerFirstName: this.providerApplicationForm.get('ownerFirstName').value,
      ownerLastName: this.providerApplicationForm.get('ownerLastName').value,
      address1: this.providerApplicationForm.get('address1').value,
      address2: this.providerApplicationForm.get('address2').value,
      city: this.providerApplicationForm.get('city').value,
      countyName: this.providerApplicationForm.get('county').value,
      latitude: this.latitude,
      longitude: this.longitude,
      stateAbv: this.providerApplicationForm.get('state').value,
      postalCode: this.providerApplicationForm.get('postalCode').value,
      phoneNumber: '+1' + this.providerApplicationForm.get('phoneNumber').value,
      emailaddress: this.providerApplicationForm.get('emailAddress').value,
      userKey: sessionStorage.getItem('userKey'),
      axlePointCOMlCompanyKey: this.addNewProviderAxelInfo[0].tmp_COMlCompanyKey,
      axlePointADDlAddressKey: this.addNewProviderAxelInfo[0].tmp_ADDlAddressKey,
      inCompanyFEIN: this.providerApplicationForm.get('einNumber').value,
      category: this.providerApplicationForm.get('category').value,
      workOrder: null,
    }

    this.providerAdminService.addNewProviderApplication(providerData2).subscribe({
      next: (v) => console.log('added new axel provider application successfully'),
      error: (e) => {this.openSnackBar('Unable to add new provider.'); console.log(e)},
      complete: () => { this.openSnackBar('New provider successfully added.'); this.dialogRef.close() },
    })
  }

  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }
    
}
