import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { NationalAccountService } from 'src/app/services/national-account.service';
import { MatDialog } from '@angular/material/dialog';
import { NationalAccountDialogComponent } from 'src/app/dialogs/national-account-dialog/national-account-dialog.component';
import { CustomerService } from '../../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';

@Component({
  selector: 'app-national-accounts',
  templateUrl: './national-accounts.component.html',
  styleUrls: ['./national-accounts.component.scss']
})
export class NationalAccountsComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  @Input() customerInputKey = '';
  isLoggedInUser: boolean;
  loading = false;
  nationalAccountData: any = [];
  customerData: any;
  customerKey: any;
  searchString: string;

  constructor(
    private nationalAccountService: NationalAccountService,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private toast: NbToastrService) { }

  ngOnInit() {
    if (this.customerInputKey && this.customerInputKey !== '') {
      this.isLoggedInUser = false;
      this.customerKey = this.customerInputKey;
    } else {
      this.isLoggedInUser = true;
      this.customerKey = sessionStorage.getItem('customerKey');
    }
    this.getNationalAccoounts();
    this.getCustomerDetails();
  }

  getNationalAccoounts() {
    const filter = {
      customerKey: this.customerKey,
    };
    this.loading = true;
    this.nationalAccountService.getNationalAccountData(filter)
      .subscribe((res: any) => {
        if (res.body.length > 0) {
          this.nationalAccountData = res.body;
          this.nationalAccountData.map(obj => obj.saveText = 'Inactive');
          this.nationalAccountData.forEach(element => {
            if (element.deleted) {
              element.saveText = 'Inactive';
            } else {
              element.saveText = 'Active';
            }
          });
          this.nationalAccountData.forEach(element => {
            if (element.Level_1_Desc === null) {
              element.Level_1_Desc = element.Level_2_Desc;
              element.Level_2_Desc = null;
            }
            return element;
          });
          this.onSort({ column: 'Level_1_Desc', direction: 'asc' });
        }
        this.loading = false;
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
  }

  addEditNationalAccount(value: string, data: any) {

    const dialogRef = this.dialog.open(NationalAccountDialogComponent, {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      height: 'auto',
      maxHeight: '90%',
      width: '90%',
      disableClose: true,
      data: {
        value,
        data,
        arrNationalAccountDetails: this.nationalAccountData,
        customerKey: this.customerKey,
      }
    });

    dialogRef.componentInstance.eventNationalAccounts.subscribe(value => {
      this.getNationalAccoounts();
    });
  }

  getCustomerDetails() {
    const objParams = {
      customerKey: this.customerKey,
    };
    this.customerService.getCustomerDetails(objParams).subscribe((res: any) => {
      if (res.success) {
        this.customerData = res.body[0];
      } else {
        console.log('Error', res.error);
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  deactivate(data: any) {

    let labelText = 'Are you sure you want to DeActivate this record?';
    if (data.deleted) {
      labelText = 'Are you sure you want to Activate this record?';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      height: 'auto',
      maxHeight: '50%',
      width: '33%',
      data: {
        label: labelText
      }
    });

    dialogRef.afterClosed().subscribe(isValue => {

      if (isValue) {
        const editData = {
          nationalBillingAccountKey: data.nationalBillingAccountKey,
          deleted: !data.deleted
        };

        this.loading = true;
        this.nationalAccountService.updateNationalAccountDetails(editData).subscribe(res => {
          if (res.success) {
            if (data.deleted === false) {
              this.toast.success('Record Activated Successfully', 'Success');
            } else {
              this.toast.success('Record Inactive Successfully', 'Success');
            }
            this.getNationalAccoounts();
          } else {
            this.loading = false;
            this.toast.warning('Oops Something went wrong.', 'Warning');
            console.log('Error', res.error);
          }
        }, (error) => {
          console.log(error);
          this.loading = false;
        });
      }
    });
  }

  nextPage() {
    this.utilityService.saveNextRoute();
    this.router.navigate([sessionStorage.getItem('nextPageLink')], { relativeTo: this.route });
  }

  search(event) {
    this.searchString = event;
  }

  onSort({ column, direction }: SortEvent) {
    this.nationalAccountData = this.utilityService.sortData(this.headers, column, direction, this.nationalAccountData);
  }

}
