<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="../../../assets/images/ServicePlusLogo.jpg"></a>
  </div>
  <div>
    <strong>Service Plus Portal</strong>
  </div>
</div>

<nb-actions>
  <nb-action>
    <div class="all_devices header-container">
      <div class="dropdown">
        <img src="../../../assets/images/nick.png" class="rounded-circle" alt="">
        <span class="mr-2 ml-2">{{userDetails[0].firstname}} {{userDetails[0].lastname}}</span><i
          class="fa fa-caret-down"></i>
        <ul class="dropdown-content">
          <a (click)="gotoMyProfile()"><span class="m-r-10"><i class="fa fa-user" aria-hidden="true"></i></span>My
            Profile</a>
          <a (click)="logout()"><span class="m-r-10"><i class="fa fa-sign-out" aria-hidden="true"></i></span>Logout</a>
        </ul>
      </div>
    </div>
    <div class="small_devices header-container">
      <div class="dropdown">
        <img src="../../../assets/images/nick.png" class="rounded-circle" alt="">
        <i class="fa fa-caret-down"></i>
        <ul class="dropdown-content">
          <span class="m-r-10 user-name">{{userDetails[0].firstname}} {{userDetails[0].lastname}}</span>
          <a (click)="gotoMyProfile()"><span class="m-r-10"><i class="fa fa-user" aria-hidden="true"></i></span>My
            Profile</a>
          <a (click)="logout()"><span class="m-r-10"><i class="fa fa-sign-out" aria-hidden="true"></i></span>Logout</a>
        </ul>
      </div>
    </div>
  </nb-action>
</nb-actions>