<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">
        Provider Discount Line Item Exceptions
    </h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="table-responsive mt-3">
        <table class="table table-bordered">
            <tbody *ngIf="lineItemExceptions.length > 0">
                <tr *ngFor="let lineItem of lineItemExceptions">
                    <td>{{lineItem.lineItemKey}}</td>
                  </tr>
            </tbody>
            <tbody *ngIf="lineItemExceptions.length === 0">
                <tr>
                    <td class="text-center"> No Data Available</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>