import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Offerings',
    icon: 'car-outline',
    link: '/pages/offerings',
  },
  {
    title: 'Provider Match',
    icon: 'repeat-outline',
    link: '/pages/provider-match/application-process',
  },
  {
    title: 'System Securities Mgmt.',
    icon: 'car-outline',
    link: '/pages/system-securities',
  }
];
