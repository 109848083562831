import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateDynamicFormComponent } from '../create-dynamic-form-modal/create-dynamic-form.component';
import { Question } from '../model/question.model';
// import { PreviewFormDetailsModalComponent } from '../preview-form-details-modal/preview-form-details-modal.component';

@Component({
  selector: 'app-dynamic-yes-no-form',
  templateUrl: './dynamic-yes-no-form.component.html',
  styleUrls: ['./dynamic-yes-no-form.component.scss']
})
export class DynamicYesNoFormComponent implements OnInit {

  arrQuestions: Array<Question> = [new Question({ order: 1 }), new Question({ order: 2 })];
  loading = false;
  dialogTitle: string;
  arrTitlePlaceHolder = ['Enter label for option Yes', 'Enter label for option No'];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DynamicYesNoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.question.childrens.length > 0) {
      this.arrQuestions = this.data.question.childrens;
    }
    this.dialogTitle = (this.data.question.title) ? this.data.question.title : '';
  }

  isFormPresent(item: any) {
    return Object.keys(item.components).length > 0;
  }

  trackByQustionKey(index: number, question: any) {
    return question.key;
  }

  addYesNoFormDetails() {
    this.dialogRef.close({ arrQuestions: this.arrQuestions });
  }

  openFormComponent(event, question) {
    if (event.value === 'form') {
      const dialogRef = this.dialog.open(CreateDynamicFormComponent, {
        hasBackdrop: true,
        width: '90%',
        height: '80%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: { title: question.title }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.jsonText) {
          question.display = 'form';
          question.components = result.jsonText;
        }
      });
    } else if (event.value === 'YesNo') {
      const dialogRef = this.dialog.open(DynamicYesNoFormComponent, {
        hasBackdrop: true,
        width: '66%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: {
          question
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          question.display = 'YesNo';
          question.childrens = (result.arrQuestions) ? result.arrQuestions : [];
        }
      });
    }
  }

  previewFormDeatils(question: Question) {
    const dialogRef = this.dialog.open(CreateDynamicFormComponent, {
      hasBackdrop: true,
      width: '90%',
      height: '80%',
      disableClose: true,
      position: {
        top: '5%',
      },
      data: {
        title: question.title,
        arrFieldModels: question.components,
        otherTextDescriptions: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.jsonText) {
        question.components = result.jsonText;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
