<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="modal-header">
    <h6 class="modal-title">{{dialogHeader}}</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <nb-card>
        <nb-card-body>
            <form [formGroup]="securityGroupsForm">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-select required formControlName="groupName" placeholder="Select Group name">
                                <mat-option *ngFor="let val of groupData | orderBy:'description'" [value]="val.securityGroupKey">
                                    {{val.description}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="securityGroupsForm.controls.groupName.touched && securityGroupsForm.controls.groupName.errors">
                                <span>Group Name is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </nb-card-body>
    </nb-card>
</div>
<div class="modal-footer">
    <button class="btn btn-orange btn-sm mr-2" type="button" (click)="addEditUserSecurityGroups()"
        [disabled]="securityGroupsForm.invalid">{{buttonText}}</button>
</div>