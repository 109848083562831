import { Deserializable } from 'src/app/pages/provider-match/models/deserializable.model';

export class DynamicMenu implements Deserializable {
    id: number;
    menu: string;
    menuKey: string;
    policy: string;
    securityRoleKey: string;
    subMenu1: string;
    subMenu1Key: string;
    subMenu2: string;
    subMenu2Key: string;
    subMenu3: string;
    subMenu3Key: string;
    system: string;

    deserialize(input: any): any {
        Object.assign(this, input);
        return this;
    }
}
