<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">{{dialogTitle}}</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="col-md-12 text right">
        <div class="col-md-12 text-right">
            <button class="btn btn-orange btn-sm mt-2" (click)="openEquipment()">Installed Equipment</button>
        </div>
    </div>
    <div class="col-md-12 mt-2">
        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step [stepControl]="assetFormOne">
                <form [formGroup]="assetFormOne">
                    <div class="row mt-2">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput formControlName="assetDescription" placeholder="Vehicle Description"
                                    required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="externalAssetId" placeholder="External Asset Id"
                                    required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="year" placeholder="Year" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="makeKey" matNativeControl placeholder="Make" required>
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let make of makeData | orderBy:'makeKey'"
                                        [value]=" make.makeKey">
                                        {{make.makeKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="model" placeholder="Model" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="color" placeholder="Color" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="vin" placeholder="VIN/Serial Number" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <mat-select matNativeControl formControlName="vehicleStatusKey" placeholder="Status"
                                    required>
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let status of vehicleStatusesData | orderBy:'vehicleStatusKey'"
                                        [value]="status.vehicleStatusKey">
                                        {{status.vehicleStatusKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="license" placeholder="License">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field>
                                <mat-select placeholder="License State" formControlName="licenseStateKey"
                                    matNativeControl>
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let state of statesData | orderBy:'stateDescription'"
                                        [value]="state.stateKey">
                                        {{state.stateDescription}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="licenseExpDate" [matDatepicker]="licenseExpDate"
                                    placeholder="License Exp Date" (focus)="licenseExpDate.open()">
                                <mat-datepicker-toggle matSuffix [for]="licenseExpDate"></mat-datepicker-toggle>
                                <mat-datepicker #licenseExpDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput formControlName="note" placeholder="Note">
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <button mat-button matStepperNext class="btn btn-orange btn-sm floatR">Next</button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="assetFormTwo">
                <form [formGroup]="assetFormTwo">
                    <div class="row mt-2">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput formControlName="hotalert" placeholder="Hot Alert">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="inServiceDate" [matDatepicker]="inServiceDate"
                                    placeholder="In Service Date" (focus)="inServiceDate.open()">
                                <mat-datepicker-toggle matSuffix [for]="inServiceDate"></mat-datepicker-toggle>
                                <mat-datepicker #inServiceDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="inActiveSoldDate" [matDatepicker]="inActiveSoldDate"
                                    placeholder="Active Sold Date" (focus)="inActiveSoldDate.open()">
                                <mat-datepicker-toggle matSuffix [for]="inActiveSoldDate"></mat-datepicker-toggle>
                                <mat-datepicker #inActiveSoldDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="equipmentVocationKey" placeholder="Equipment Vocation">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let equipment of equipmentVocationsData | orderBy:'equipmentVocationKey'"
                                        [value]="equipment.equipmentVocationKey">
                                        {{equipment.equipmentVocationKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="equipmentCategoryKey" placeholder="Equipment Category">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let equipment of equipmentCategoriesData | orderBy:'equipmentCategoryKey'"
                                        [value]="equipment.equipmentCategoryKey">
                                        {{equipment.equipmentCategoryKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="engineSizeKey" placeholder="Engine Size">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let size of engineSizesData | orderBy:'engineSizeKey'"
                                        [value]="size.engineSizeKey">
                                        {{size.engineSizeKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="severityOfserviceKey" placeholder="Severity Of Service">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let service of severityOfServiceData | orderBy:'severityOfServiceKey'"
                                        [value]="service.severityOfServiceKey">
                                        {{service.severityOfServiceKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="bodyStyleConfigurationKey"
                                    placeholder="Body Style/Configuration">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let data of bodyStyleConfigurationsData | orderBy:'bodyStyleConfigurationKey'"
                                        [value]="data.bodyStyleConfigurationKey">
                                        {{data.bodyStyleConfigurationKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="engineManufacturerKey" placeholder="Engine Manufacturer">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let manufacturer of engineManufacturersData | orderBy:'engineManufacturerKey'"
                                        [value]="manufacturer.engineManufacturerKey">
                                        {{manufacturer.engineManufacturerKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="axleConfigurationKey" placeholder="Axle Configuration">
                                    <mat-option></mat-option>
                                    <mat-option
                                        *ngFor="let data of axleConfigurationsData | orderBy:'axleConfigurationKey'"
                                        [value]="data.axleConfigurationKey">
                                        {{data.axleConfigurationKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="cabTypeKey" placeholder="Cab Type">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let type of cabTypesData | orderBy:'cabTypeKey'"
                                        [value]="type.cabTypeKey">
                                        {{type.cabTypeKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="powerSourceKey" placeholder="Power Source">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let source of powerSourcesData | orderBy:'powerSourceKey'" [value]="source.powerSourceKey">
                                        {{source.powerSourceKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <button mat-button matStepperNext class="btn btn-orange floatR btn-sm">Next</button>
                            <button mat-button matStepperPrevious
                                class="btn btn-orange floatR btn-sm mr-2">Previous</button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="assetFormThree">
                <form [formGroup]="assetFormThree">
                    <div class="row mt-2">
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="transmissionTypeKey" placeholder="Transmission Type">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let type of transmissionTypesData | orderBy:'transmissionTypeKey'"
                                        [value]="type.transmissionTypeKey">
                                        {{type.transmissionTypeKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput formControlName="dotnumber" placeholder="DOT Number">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="trailerLength" placeholder="Trailer Length">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let length of trailerLengthsData | orderBy:'trailerLengthKey'"
                                        [value]="length.trailerLengthKey">
                                        {{length.trailerLengthKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="powerTakeOff" placeholder="Power Take Off">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let data of powerTakeOffsData | orderBy:'powerTakeOffKey'" [value]="data.powerTakeOffKey">
                                        {{data.powerTakeOffKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select formControlName="oilTypeKey" placeholder="Oil Type">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let type of oilTypesData | orderBy:'oilTypeKey'" [value]="type.oilTypeKey">
                                        {{type.oilTypeKey}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput type="number" formControlName="lastValidatedMileage"
                                    placeholder="Current Mileage">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput type="number" formControlName="lastValidatedHours"
                                    placeholder="Current Hours">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" *ngIf="this.data.editStatus == 1">
                            <mat-form-field>
                                <input matInput formControlName="updatedTimestamp" placeholder="Last Update Date">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" *ngIf="this.data.editStatus == 1">
                            <mat-form-field>
                                <input matInput formControlName="updatedBy" placeholder="Last Updated By">
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <button mat-button class="btn btn-orange floatR btn-sm"
                                (click)="addEditAssetDetails()">Save</button>
                            <button mat-button matStepperPrevious
                                class="btn btn-orange floatR  btn-sm mr-2">Previous</button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>