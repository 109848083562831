import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfferingQuestionsService {

  constructor(private http: HttpClient) { }

  getofferingQuestionsServices(licenesedOwnerKey): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getOfferingsQuestions/${licenesedOwnerKey}`);
  }

  updateLicensedOwnerOfferingsDynamicFormKey(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerofferings`, data);
  }
}
