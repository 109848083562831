import { Deserializable } from './deserializable.model';

export class UserInformation implements Deserializable {

  userInformationID: number;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  phone: string;
  officePhone: string;
  active: boolean;
  addedBy: number;
  updatedBy: number;
  deleteFlag: boolean;

  deserialize(input: any): any {
    Object.assign(this, input);
    return this;
  }
}
