import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit, OnChanges {

  @Input() schema: string;
  @Input() dataDictionaryKey: string;

  loading = false;
  emailInstructions = 'Instructions Not Available';

  constructor(
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    // this.getInstructions();
  }

  ngOnChanges() {
    this.getInstructions();
  }

  getInstructions() {
    let req;
    if (this.schema === 'FleetAssist') {
      const filter = {
        dataDictionaryKey: this.dataDictionaryKey,
      };
      req = this.commonService.getDataDictionary(filter);
    } else {
      const emailFilter = {
        dataDictonaryKey: this.dataDictionaryKey,
      };
      req = this.commonService.getProviderDataDictionary(emailFilter);
    }

    this.emailInstructions = 'Instructions Not Available';
    req.subscribe(result => {
      if (result.body.length > 0) {
        this.emailInstructions = atob(result.body[0].displayText);
      }
    }, (error) => {
    });
  }

}
