import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { UserService } from 'src/app/services/user.service';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-add-skills-level-expertise-dailog',
  templateUrl: './add-skills-level-expertise-dailog.component.html',
  styleUrls: ['./add-skills-level-expertise-dailog.component.scss']
})
export class AddSkillsLevelExpertiseDailogComponent implements OnInit {

  userSkillsForm: UntypedFormGroup;
  loading: boolean = false;
  dialogHeader: string = 'Add Skills';
  buttonText: string = 'Add';
  skillsData: any;
  userKey: string = '';
  userSkillKey: string = '';

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    private dialog: MatDialogRef<AddSkillsLevelExpertiseDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.userKey = (this.data.userKey) ? this.data.userKey : sessionStorage.getItem('userKey');
    if (this.data.edit) {
      this.dialogHeader = 'Edit Skills';
      this.buttonText = 'Save';
      this.userSkillKey = this.data.value.userSkillKey;
      this.getUserSkills();
    }

    this.userSkillsForm = this.formBuilder.group({
      skillKey: ['', [Validators.required]],
      levelOfExpertise: ['', [Validators.required]],
    });

    this.getSkills();
  }

  /***
  * Desc: get all skills
  * @params postParams
  * @return result success
  */
  getSkills() {
    this.loading = true;
    this.userService.getSkills().subscribe(response => {
      this.skillsData = response.body;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.toast.danger(error, 'Error');
    });
  }

  /***
  * Desc: get user skills
  * @params postParams
  * @return result success
  */
  getUserSkills() {

    this.loading = true;
    const postParams = {
      userSkillKey: this.userSkillKey,
    };
    this.userService.getUserSkills(postParams).subscribe(response => {
      this.loading = false;
      this.userSkillsForm.setValue({
        skillKey: response.body[0].skillKey,
        levelOfExpertise: response.body[0].levelOfExpertise,
      });
    }, (error) => {
      this.loading = false;
      this.toast.danger(error, 'Error');
    });
  }

  /***
  * Desc: add/update user skills
  * @params postParams
  * @return result success
  */
  addEditSkills() {
    if (this.userSkillsForm.invalid) {
      this.userSkillsForm.markAllAsTouched();
      return false;
    }

    if (this.data.edit) {
      this.updateSkills();
    } else {
      this.addSkills();
    }
  }

  addSkills() {
    const postParams = {
      skillKey: this.userSkillsForm.value.skillKey,
      userKey: this.userKey,
      deleted: false,
    };

    this.userService.getUserSkills(postParams).subscribe(response => {
      if (response.body.length > 0) {
        this.toast.warning('Skill already exists', 'Warning');
      } else {
        const insertData = {
          userKey: this.userKey,
          skillKey: this.userSkillsForm.value.skillKey,
          levelOfExpertise: this.userSkillsForm.value.levelOfExpertise,
          systemDefault: true,
          insertedTimestamp: '',
          insertedUserKey: this.userKey,
          updatedTimestamp: '',
          updatedUserKey: '',
          deleted: false,
        };

        this.loading = true;
        this.userService.addUserSkills(insertData).subscribe((res: any) => {
          this.toast.success('User Skills added SuccessFully!', 'Success');
          this.loading = false;
          this.closeDialog();
        }, (error) => {
          this.toast.danger(error, 'Error');
          this.loading = false;
        });
      }
    }, (error) => {
      this.toast.danger(error, 'Error');
      this.loading = false;
    });
  }

  updateSkills() {
    const updateData = {
      userSkillKey: this.userSkillKey,
      skillKey: this.userSkillsForm.value.skillKey,
      levelOfExpertise: this.userSkillsForm.value.levelOfExpertise,
    };

    this.loading = true;
    this.userService.updateUserSkills(updateData).subscribe((res: any) => {
      this.toast.success('User Skills updated SuccessFully!', 'Success');
      this.loading = false;
      this.closeDialog();
    }, (error) => {
      this.toast.danger(error, 'Error');
      this.loading = false;
    });
  }

  // close dialog
  closeDialog() {
    this.dialog.close();
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

}
