<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<form [formGroup]="accountDetailsForm">
    <div class="row m-2">
        <div class="col-sm-6 col-md-4 col-lg-4">
            <mat-form-field appearance="outline">
                <mat-select matNativeControl placeholder="Payment Term" formControlName="paymentTermKey">
                    <mat-option [(value)]="term.paymentTermKey"
                        *ngFor="let term of paymentTerms  | orderBy:'paymentTermDays'">
                        {{ term.paymentTermKey }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
            <mat-form-field appearance="outline">
                <mat-select matNativeControl placeholder="Preferred Method" formControlName="paymentMethodKey">
                    <mat-option [(value)]="method.paymentMethodKey"
                        *ngFor="let method of paymentMethods | orderBy:'paymentMethodKey'">
                        {{ method.paymentMethodKey }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Billing Email</mat-label>
                <input matInput formControlName="billingEmail">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label> Billing Phone</mat-label>
                <input matInput formControlName="billingPhone" mask="000-000-0000">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Billing Address</mat-label>
                <input matInput formControlName="address1" autocomplete="off" ngx-google-places-autocomplete
                    (onAddressChange)="getDetailedAddress($event)">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Billing City</mat-label>
                <input matInput formControlName="city">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Billing State</mat-label>
                <input matInput formControlName="stateKey">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label>Billing Zip</mat-label>
                <input matInput formControlName="postalCode">
            </mat-form-field>
        </div>
        <div class="col-md-4 text-center" *ngIf="attachments">
            <label class="border p-3 w-100">
                <span><a href="{{attachments}}" target="_blank">Job Service Agreement</a></span>
            </label>
        </div>
    </div>
</form>