import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timezoneConvertDate'
})
export class TimezoneConvertDatePipe implements PipeTransform {

  transform(value: any, timeZone?: string): any {
    if (value == null || value == '0000-00-00 00:00:00' || value == '-') {
      return value;
    }

    let userTimezone = sessionStorage.getItem('currentTimezone');

    if (!userTimezone || userTimezone === 'null') {
      userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (timeZone) userTimezone = timeZone;

    if (timeZone && timeZone === 'BrowserTimeZone') userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return moment.tz(value, userTimezone).format('MMM DD, YYYY hh:mm a z');
  }
}