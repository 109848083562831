<div class="modal-header">
    <h6 class="modal-title">User Profile</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <mat-horizontal-stepper #stepper [selectedIndex]='selectedIndex' (selectionChange)='currentStage($event)'>
        <mat-step>
            <ng-template matStepLabel>User Details</ng-template>
            <ng-container>
                <ngx-add-edit-employee-dialogs [data]="userData" (saveCompleted)='saveCompleted($event)'>
                </ngx-add-edit-employee-dialogs>
            </ng-container>
        </mat-step>
        <!-- <mat-step>
            <ng-template matStepLabel>Skill & Expertise</ng-template>
            <ng-container *ngIf="selectedIndex == 1">
                <ngx-skills-level-expertise-dailog [userDetails]="userData"></ngx-skills-level-expertise-dailog>
            </ng-container>
        </mat-step> -->
        <mat-step>
            <ng-template matStepLabel>Security Groups</ng-template>
            <ng-container *ngIf="selectedIndex == 1">
                <ngx-system-access-role-dailog [userDetails]="userData"></ngx-system-access-role-dailog>
            </ng-container>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Availability</ng-template>
            <ng-container *ngIf="selectedIndex == 2">
                <ngx-employee-availability [userDetails]="userData"></ngx-employee-availability>
            </ng-container>
        </mat-step>
    </mat-horizontal-stepper>
</div>
<div class="modal-footer">
    <button *ngIf="selectedIndex == 0" type="button" class="btn btn-orange btn-sm mt-3"
        (click)="saveAndNext(false)">{{saveButtonText}}</button>
    <button *ngIf="selectedIndex == 0" type="button" class="btn btn-orange btn-sm mt-3"
        (click)="saveAndNext(true)">Save &
        Next</button>
    <button *ngIf="selectedIndex > 0" type="button" class="btn btn-orange btn-sm mt-3"
        (click)="previous()">Previous</button>
    <button *ngIf="selectedIndex < 2" type="button" class="btn btn-orange btn-sm mt-3"
        (click)="next(true)">Next</button>
</div>