<div class="container">
    <div class="row">
        <div class="col-12 my-2">
            <h6>Assigning Permissions to User Security Groups.</h6>
        </div>
    </div>
    <div class="row hiddenServicesScroll">
        <div class="col-sm-12">
            <div *ngIf="loading" id="preloader">
                <div id="loader"></div>
            </div>
            <div class="row">
                <div class="col col-md-3">
                    <mat-form-field>
                        <mat-select (selectionChange)="getUserTypes($event)" required placeholder="Select Platform">
                            <mat-option *ngFor="let platform of platformTypeList" [value]="platform.userTypeKey">
                                {{platform.platform}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-md-3">
                    <mat-form-field>
                        <mat-select (selectionChange)="getSecurityGroupsByUserType($event)" required
                            placeholder="Select User Type">
                            <mat-option *ngFor="let val of userTypeList" [value]="val.userTypeKey">
                                {{val.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-md-3">
                    <mat-form-field>
                        <mat-select required (selectionChange)="getMenusBySecurityGroups($event)"
                            placeholder="Select Security Group">
                            <mat-option *ngFor="let data of securityGroupListData" [value]="data.securityGroupKey">
                                {{ data.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-md-3">
                    <mat-form-field>
                        <mat-select required (selectionChange)="getTreeMenuData($event)" placeholder="Select Menus">
                            <mat-option *ngFor="let data of securityGroupMenus" [value]="data.dynamicMenuKey">
                                {{ data.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="tree-block" *ngIf="dynamicMenuList.length > 0; else selectData">
                        <div class="tree-pane">
                            <div class="tree-paning-container">
                                <div class="tree-roots-elements">
                                    <ngx-bubble-tree-security-group [parentKey]="parentKey"
                                        [levelData]="dynamicMenuList" [level]="level" [isActiveNode]="isActiveNode"
                                        [isSelected]="isSelected" [isAllParentActive]="1"
                                        [securityGroupKey]="securityGroupKey">
                                    </ngx-bubble-tree-security-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #selectData>
                <div class="text-center p-5">
                    <h5>Select Security Group to see data.</h5>
                </div>
            </ng-template>

        </div>
    </div>
</div>