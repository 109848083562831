<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">Edit ETA</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <nb-card>
        <nb-card-body>
            <form [formGroup]="etaForm">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <input matInput formControlName="morningRushHourETA"
                                placeholder="Morning Rush Hours ETAs(Min)">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <input matInput formControlName="standardETA" placeholder="Normal Business Hour ETAs(Min)">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <input matInput formControlName="afternoonRushHourETA"
                                placeholder="Afternoon Rush Hours ETAs(Min)">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <input matInput formControlName="afterHoursETA" placeholder="After Hours ETAs (Min)">
                        </mat-form-field>
                    </div>
                </div>

            </form>
        </nb-card-body>
    </nb-card>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm mr-2" type="button" (click)="editETA()"
        [disabled]="etaForm.invalid">Save</button>
    <button class="btn btn-primary btn-sm" type="button" (click)="closeDialog()">Close</button>
</div>