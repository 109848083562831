import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

// @Pipe({
//   name: 'capitalizeFirstLetter'
// })

@Directive({
  selector: '[appCurrencyInput]'
})

export class CurrencyInputPipe {

  @Input() currencyCode: string = 'USD';

  constructor(private el: ElementRef, private currencyPipe: CurrencyPipe) { }

  @HostListener('focus') onFocus() {
    // Remove currency formatting on focus
    this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9.-]+/g, '');
  }

  @HostListener('blur') onBlur() {
    // Apply currency formatting on blur
    let value = parseFloat(this.el.nativeElement.value);
    if (!isNaN(value)) {
      this.el.nativeElement.value = this.currencyPipe.transform(value, this.currencyCode, 'symbol', '1.2-2');
    }
  }

  // transform(value: string): string {
  //   if(!value) return value;
  //   return value
  //   .toLowerCase()
  //   .replace(/\b\w/g, char => char.toUpperCase())
  // }

}
