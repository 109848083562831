import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BreakDownProvidersService } from '../../services/breakdown-providers.service';
import { BreakdownProvider } from '../../models/breakdown-providers.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationDialogComponent } from '../application-dialog/application-dialog.component';
import { MatchApplicationDialogComponent } from '../match-application-dialog/match-application-dialog.component';
import { DeleteApplicationDialogComponent } from '../delete-application-dialog/delete-application-dialog.component';
import { AddNewApplicationDialogComponent } from '../add-new-application-dialog/add-new-application-dialog.component';
import { DeletedProviderApplicationsComponent } from '../deleted-provider-applications/deleted-provider-applications.component';
import { ProspectProvidersVerificationComponent } from '../prospect-providers-verification/prospect-providers-verification.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ProviderService } from 'src/app/services/provider.service';
@Component({
  selector: 'ngx-provider-processing-v2',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule, 
    MatTabsModule,
  ],
  templateUrl: './provider-processing-v2.component.html',
  styleUrls: ['./provider-processing-v2.component.scss']
})
export class ProviderProcessingV2Component implements OnInit {
  displayedColumns: string[] = ['insertedTimestamp', 'companyName', 'owner', 'address', 'phone', 'email', 'category', 'workOrder', 'action'];
  displayedColumnsProspect: string[] = ['insertedTimestamp', 'companyName', 'owner', 'address', 'phone', 'email', 'action'];
  dataSource: MatTableDataSource<BreakdownProvider>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort , { static: true }) sort: MatSort;
  applicationStatusFilter: any;
  filteredDataSource: any = [];
  allProviderApplications: any = [];
  tempPage: boolean = true;
  providerApplicationPage: number = 0;

  constructor(
    private breakDownProvidersService: BreakDownProvidersService,
    private providerService: ProviderService,
    public dialog: MatDialog,
    private providerAdminService : ProviderAdminService,

  ) { }

  ngOnInit(): void {
    this.getApplications()
  }

  getApplications(): void {
    const obj = new BreakdownProvider();
    this.breakDownProvidersService.getProvider(obj)
      .subscribe((providers) => {
        this.dataSource = new MatTableDataSource(providers);
        this.allProviderApplications = providers;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort

        this.dataSource.data
        if(this.providerApplicationPage == 0){
            this.tempApplicationsFilter(providers)
        }
        else if(this.providerApplicationPage == 1){
          this.prospectApplicationsFilter(providers)
        }
        else if(this.providerApplicationPage == 2){
          this.onboardApplicationsFilter(providers)
        }
      
      })
  }

  tempApplicationsFilter(providers){
    this.applicationStatusFilter = providers;
    this.applicationStatusFilter = this.applicationStatusFilter.filter(obj => obj.prospect == 0 && obj.onboard == 0)
    this.dataSource = new MatTableDataSource(this.applicationStatusFilter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
  }

  prospectApplicationsFilter(providers){
    this.applicationStatusFilter = providers;
    this.applicationStatusFilter = this.applicationStatusFilter.filter(obj => obj.prospect == 1)
    this.dataSource = new MatTableDataSource(this.applicationStatusFilter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
  }

  onboardApplicationsFilter(providers){
    this.applicationStatusFilter = providers;
    this.applicationStatusFilter = this.applicationStatusFilter.filter(obj => obj.onboard == 1)
    this.dataSource = new MatTableDataSource(this.applicationStatusFilter);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
  }

  onTabChange(event: MatTabChangeEvent) {
    //console.log('Selected tab index:', event.index);
    if(event.index == 0){
      console.log("temp providers")
      this.tempPage = true;
      this.providerApplicationPage = 0;
      this.tempApplicationsFilter(this.allProviderApplications)
    }
    else if(event.index == 1){
      console.log("prospect providers")
      this.tempPage = false;
      this.providerApplicationPage = 1;
      this.prospectApplicationsFilter(this.allProviderApplications)
    }
    else if(event.index == 2){
      console.log("onboard providers")
      this.tempPage = false;
      this.providerApplicationPage = 2;
      //this.onboardApplicationsFilter(this.allProviderApplications)

      this.providerAdminService.removeOnboardProvidersWithActiveStatus().subscribe({
        next: res => console.log(res),
        error: (e) => console.log(e),
        complete: () => { console.log('removed onboard providers with active status from onboard');
          this.onboardApplicationsFilter(this.allProviderApplications)
         }
      })
    }
  
  }

  editApplication(provider): void {
    let dialogRef = this.dialog.open(ApplicationDialogComponent, {
      data: provider,
      width: '520px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getApplications()
    });
  }

  matchApplication(provider): void {
    let dialogRef = this.dialog.open(MatchApplicationDialogComponent, {
      data: provider,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  deleteApplication(provider): void {
    let dialogRef = this.dialog.open(DeleteApplicationDialogComponent, {
      data: provider,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      this.getApplications()
    });
  }

  addProviderApplicationPrompt(){
    let dialogRef = this.dialog.open(AddNewApplicationDialogComponent, {
      //data: provider,
      width: '820px',
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.getApplications();
    });
  }

  getDeletedApplications(){
    let dialogRef = this.dialog.open(DeletedProviderApplicationsComponent, {
      //data: provider,
      width: "85%",
      //height: '95%'
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      //this.getApplications();
    });
  }

  prospectProviderVerification(provider){
    let dialogRef = this.dialog.open(ProspectProvidersVerificationComponent, {
      data: provider,
      width: '820px',
      height: '85%'
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.getApplications();
    });
  }

  addAsProspectDialog(element){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Set As Prospect', message: 'Are you sure you want to set this provider as a prospect?',
        yesButton: 'Yes'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        console.log('Add this as a prospect')

        const data = {
          providerPK: element.providerPK,
          prospect: true
        }

        this.breakDownProvidersService.updateProviderApplications(data).subscribe({
          next: res => console.log(res),
          error: (e) => console.log(e),
          complete: () => { this.getApplications();}
        })
      }
    })

  }

}
