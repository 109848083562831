export class Notes {
    public providerAccountNoteKey: string;
    public providerAccountKey: string;
    public note: string;
    public privateForProvider: string;
    public privateForAccount: string;
    public systemDefault: string;
    public insertedTimestamp: Date;
    public insertedUserKey: string;
    public updatedTimestamp: Date;
    public updatedUserKey: string;
    public deleted: string;
    public userName: string;
    public userTypeKey: string;
    public userCatagory: string;

    constructor(data: any, notes: any) {
        this.providerAccountNoteKey = data.providerAccountNoteKey;
        this.providerAccountKey = data.providerAccountKey;
        this.note = data.note;
        this.privateForProvider = data.privateForProvider;
        this.privateForAccount = (data.privateForAccount === false && data.privateForProvider === false) ? 'Y' : 'N';
        this.systemDefault = data.systemDefault;
        this.insertedTimestamp = data.insertedTimestamp;
        this.insertedUserKey = data.insertedUserKey;
        this.updatedTimestamp = data.updatedTimestamp;
        this.updatedUserKey = data.updatedUserKey;
        this.deleted = data.deleted;
        const user = notes.filter((item) => item.userKey === this.insertedUserKey)[0];
        this.userName = user.firstName + ' ' + user.lastName;
    }
}
