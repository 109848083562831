import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { CommonService } from 'src/app/services/common.service';
import { SystemSecuruityMenuService } from 'src/app/services/system-securuity-menu.service';
import { patterns } from 'src/assets/patterns';
import { AddSecurityMenuDialogComponent } from '../add-security-menu-dialog/add-security-menu-dialog.component';

interface deletedValue {
  value: number;
  desc: string;
}

@Component({
  selector: 'ngx-edit-security-group-list-data',
  templateUrl: './edit-security-group-list-data.component.html',
  styleUrls: ['./edit-security-group-list-data.component.scss']
})
export class EditSecurityGroupListDataComponent implements OnInit {

  securityGroupForm: UntypedFormGroup;
  userKey: string;
  loading: boolean;
  userTypeList: any;

  deletedValue: deletedValue[] = [
    { value: 1, desc: 'Yes' },
    { value: 0, desc: 'No' }
  ];
  securityGroups: any;
  userType: string;
  isEdit: boolean = true;
  departments: any;

  constructor(
    private FormBuilder: UntypedFormBuilder,
    private toastrService: NbToastrService,
    private commonService: CommonService,
    private systemSecurityMenuService: SystemSecuruityMenuService,
    public dialogRef: MatDialogRef<AddSecurityMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.userKey = sessionStorage.getItem('userKey');
    this.securityGroupForm = this.FormBuilder.group({
      description: ['',
        [Validators.required, Validators.pattern(patterns.textPattern)]],
      userType: ['', Validators.required],
      platform: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      securityLevel: ['',
        [Validators.required, Validators.pattern(patterns.numberPattern)]],
      deleted: ['', Validators.required]
    });
    this.getUserTypes();
    if (this.data.action == 'edit') {
      this.isEdit = true;
      this.setSecurityGroupForm();
    } else {
      this.isEdit = false;
    }
  }

  setSecurityGroupForm() {
    this.securityGroupForm.setValue({
      description: this.data.group.description,
      userType: this.data.group.userTypeKey,
      platform: this.data.group.platform,
      securityLevel: this.data.group.securityLevel,
      deleted: this.data.group.deleted ? 1 : 0,
    });
  }
  getUserTypes() {
    this.loading = true;
    const data = {
      deleted: false
    };
    this.commonService.getUserTypes(data).subscribe(response => {
      this.userTypeList = response.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  cancelClick(): void {
    this.dialogRef.close({ event: 'Close' });
  }
  addUpdateSecurityGroup(event: string) {

    if (this.securityGroupForm.invalid) {
      this.securityGroupForm.markAllAsTouched();
      return false;
    }
    this.loading = true;
    if (event === 'add') {
      const addGroup = {
        deleted: this.securityGroupForm.controls.deleted.value ? true : false,
        description: this.securityGroupForm.controls.description.value,
        platform: this.securityGroupForm.controls.platform.value,
        securityLevel: this.securityGroupForm.controls.securityLevel.value,
        userTypeKey: this.securityGroupForm.controls.userType.value,
        insertedUserKey: sessionStorage.getItem('userKey'),
        updatedUserKey: '',
        systemDefault: true,
        parentKey: ''
      };

      this.systemSecurityMenuService.addSecurityGroup(addGroup).subscribe((result: any) => {
        this.loading = false;
        if (result.success) {
          this.toastrService.success('Group added successfully', 'success');
          this.dialogRef.close({ event: 'updated' });
        } else {
          this.loading = false;
          this.toastrService.danger('Oops! Something went wrong.', 'Danger');
        }
      }, (error) => {
        this.loading = false;
        this.toastrService.danger('Oops! Something went wrong.', 'Danger');
      });

    } else {
      const updateGroup = {
        securityGroupKey: this.data.group.securityGroupKey,
        deleted: this.securityGroupForm.controls.deleted.value ? true : false,
        description: this.securityGroupForm.controls.description.value,
        securityLevel: this.securityGroupForm.controls.securityLevel.value,
        userTypeKey: this.securityGroupForm.controls.userType.value,
        insertedUserKey: '',
        updatedUserKey: sessionStorage.getItem('userKey')
      };

      this.systemSecurityMenuService.updateSecurityGroup(updateGroup).subscribe((result: any) => {
        this.loading = false;
        if (result.success) {
          this.toastrService.success('Group updated successfully', 'success');
          this.dialogRef.close({ event: 'updated' });
        } else {
          this.loading = false;
          this.toastrService.danger('Oops! Something went wrong.', 'Danger');
        }
      }, (error) => {
        this.loading = false;
        this.toastrService.danger('Oops! Something went wrong.', 'Danger');
      });
    }
  }
}
