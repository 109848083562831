export class User{
    address: any;
    cellPhone: any;
    city: any;
    contactPriority: any;
    deleted: boolean = false;
    departmentKey: any;
    email: any;
    firstName: any;
    insertedTimestamp: any;
    insertedUserKey: any;
    lastName: any;
    officePhone: any;
    password: any;
    providerKey: any;
    stateKey: any;
    supervisor: any;
    title: any;
    updatedTimestamp: any;
    updatedUserKey: any;
    userKey: any;
    userStatusKey: any;
    userTypeKey: any;
    userAssociation: any;
    securityGroup: any;
    associationKey: any;
}

