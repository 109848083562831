import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { NbToastrService } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { SortEvent, SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'ngx-add-system-roles-dialog',
  templateUrl: './add-system-roles-dialog.component.html',
  styleUrls: ['./add-system-roles-dialog.component.scss']
})
export class AddSystemRolesDialogComponent implements OnInit {

  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  groupData: any = [];
  securityGroupsForm: UntypedFormGroup;
  disableFlag: boolean = false;
  loading: boolean = false;
  action: string = 'add';
  dialogHeader: string = 'Add Security Group';
  buttonText: string = 'Add';
  securityRoleKey: string;
  userKey: string;
  level: any = 0;

  constructor(
    private userService: UserService,
    private FormBuilder: UntypedFormBuilder,
    private toastrService: NbToastrService,
    public dialog: MatDialogRef<AddSystemRolesDialogComponent>,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.userKey = (this.data.userKey) ? this.data.userKey : sessionStorage.getItem('userKey');
    this.level = this.data.level == undefined ? 0 : this.data.level;

    this.securityGroupsForm = this.FormBuilder.group({
      groupName: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.textPattern)]],
    });

    this.getGroups();
  }

  // get user groups
  getGroups() {

    const postData = {
      'platform': 'ServicePlus',
      'userTypeKey': 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4',
      'deleted': false
    };

    this.userService.getGroups(postData).subscribe((response) => {

      response.body.forEach(element => {
        if (element.securityLevel > this.level) {
          this.groupData.push(element);
        }
      });
      this.onSort({ column: 'description', direction: 'asc' });
    }, (error) => {
      console.log('error', error);
    });
  }

  // add/Edit user security group roles
  addEditUserSecurityGroups() {

    if (this.securityGroupsForm.invalid) {
      return false;
    }

    const securityGroupKey = this.securityGroupsForm.value.groupName;
    const data = {
      userKey: this.userKey,
      securityGroupKey: securityGroupKey,
    };
    this.loading = true;
    this.userService.getUserSecurityGroups(data).subscribe((response: any) => {
      if (response.body.length > 0) {
        this.toastrService.danger('', 'Security group already added.');
        this.loading = false;
        return false;
      } else {
        const securityGroups = {
          'userKey': this.userKey,
          'securityGroupKey': securityGroupKey,
          'systemDefault': true,
          'deleted': false,
        };

        this.userService.addUserSecurityGroup(securityGroups)
          .subscribe((responseGrups: any) => {
            this.toastrService.success('Security Groups added SuccessFully!', 'Success');
            this.loading = false;
            this.dialog.close({ event: 'Add' });
          }, (error) => {
            this.toastrService.danger(error, 'Error');
            this.loading = false;
          });
        this.loading = false;
      }
    }, (error) => {
      console.log('error', error);
      this.loading = false;
      return false;
    });
  }

  // close dialog
  closeDialog() {
    this.dialog.close({ event: 'Close' });
  }

  onSort({ column, direction }: SortEvent) {
    this.groupData = this.utilityService.sortData(this.headers, column, direction, this.groupData);
  }
}

