export const patterns = {
    namePattern: "^[A-Za-z0-9][A-Za-z0-9 _]*$",
    mobnumPattern: "^(?=.*?[1-9])[0-9()-]{10,15}$",
    phonePattern: "^[+](([(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})",
    phonePattern2: /^\+1\d{10}$/,
    passwordPattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
    lastNamePattern: "^[A-Za-z0-9][A-Za-z0-9 _!@#$&()^\\-`.+,\%\"'*-={}|<>?`~]*$",
    emailPattern: "([a-zA-Z0-9_.+-]+@[a-zA-Z0-9]+[.])([a-zA-Z])+(.[a-zA-Z]*)",
    cityPattern: "^[a-zA-Z][A-Za-z .,()-/']*$",
    zipCodePattern: "^([0-9]*)$",
    numberPattern: "^([0-9]*)$",
    yearPattern: "^[0-9]{4}$",
    onlyTextPattern: "^[A-Za-z][A-Za-z _]*$",
    textPattern: "^[A-Za-z0-9~`_!@#$&()^\\-`.+,\%\"'*-={}[\]|<>?][A-Za-z0-9 ~`_[\]!@#$&()^\\-`.+,\%\"'*-={}|<>?]*$",
    alphanumericZipCodePattern: "^([a-zA-Z0-9 ]*)$",
    url: "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$",
    decimalNumberPattern: "[0-9]+(\.[0-9][0-9]?)?",
    decimalTimePattern: "[0-9]+(\.[0-5][0-9]?)?",
};
