import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { CommonService } from '../services/common.service';
import { RouteLoaderService } from '../services/route-loader.service';
import { UsersService } from '../pages/provider-match/services/users.service';
import { UserExperianceService } from '../services/user-experiance.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ngx-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  cognitoResponse: any;
  isUserRedirected = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private oauthService: OAuthService,
    private usersService: UsersService,
    private routeLoaderService: RouteLoaderService,
    private userExperianceService: UserExperianceService,
  ) { }

  ngOnInit() {

  //     this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
  //       if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
  //         this.oauthService.initCodeFlow();
  //       }
  //       else
  //       {
  //         console.log('valid token')
  // //        this.redirectUser();

  //         if (sessionStorage.getItem('claims') != null && sessionStorage.getItem('claims') !== '') {
  //           this.isUserRedirected = true;
  //           this.redirectUser();
  //         }
  //       }
  //     });

    if (sessionStorage.getItem('claims') != null && sessionStorage.getItem('claims') !== '') {
      this.isUserRedirected = true;
      this.redirectUser();
    }

    this.commonService.systemMenus.subscribe(message => {
      if (!this.isUserRedirected) {
        this.redirectUser();
      }
    });
  }

  redirectUser() {
    this.isUserRedirected = true;
    this.cognitoResponse = JSON.parse(sessionStorage.getItem('claims'));

    const filter = {
      userKey: this.cognitoResponse.sub,
      deleted: false,
    };
    const userReq = this.usersService.getUsers(filter);
    const userExperianceReq = this.userExperianceService.getUserExperiances(filter);

    forkJoin([userReq, userExperianceReq]).subscribe(([user, userExperiance]: any) => {
      const userDetails = user.body[0] || [];
      const associationKey = userExperiance[0]?.associationKey || null;

      if (associationKey) {
        this.setUserDetails(userDetails);
      } else if (userDetails.userTypeKey === '0a193949-7f87-432f-835d-1dda91350027'
        || userDetails.userTypeKey === '0be91d44-d301-4e5f-9bbd-707f6acd69a7') {
        this.setUserDetails(userDetails);
      } else {
        this.oauthService.logOut();
      }
    }, (error) => {
      console.info('error', error);
    });
  }

  setUserDetails(userDetails: any) {
    if (userDetails.userStatusKey.toLowerCase() === 'inactive') {
      alert('This User is not Active, Please contact administrator');
      this.oauthService.logOut();
      return false;
    }

    const userName = userDetails.firstName != null ? userDetails.firstName : '';
    sessionStorage.setItem('userKey', this.cognitoResponse.sub);
    sessionStorage.setItem('userName', userName.concat(' ', userDetails.lastName != null ?
      userDetails.lastName : ''));
    sessionStorage.setItem('loginUserProfile', userDetails.profilePictureURL);

    this.checkSecurityGroupExists();
    this.router.navigate(
      ['/pages/de46ed58-fafc-473c-a246-d0c1eec12ad6'],
      { relativeTo: this.route });
  }

  checkSecurityGroupExists() {
    if (!sessionStorage.getItem('securityGroupKey')) {
      const objParams = {
        userKey: this.cognitoResponse.sub,
        deleted: false,
      };

      this.usersService.getUserSecurityGroups(objParams).subscribe(res => {
        if (res.body.length > 0) {
          sessionStorage.setItem('securityGroupKey', res.body[0].securityGroupKey);
          this.loadDymanicRouting();
        } else {
          console.log("User Don't have security Group, not able to load menus");
        }
      });
    } else {
      this.loadDymanicRouting();
    }
  }

  loadDymanicRouting() {
    const securityGroupKey = sessionStorage.getItem('securityGroupKey');
    if (securityGroupKey) {
      const objParams = {
        userKey: sessionStorage.getItem('userKey'),
        securityGroupKey: securityGroupKey,
      };

      this.routeLoaderService.load(objParams).subscribe(res => {
        this.router.navigateByUrl(this.router.url);
      });
    }
  }
}
