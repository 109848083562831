<app-loader [loading]="loading"></app-loader>
<div class="borderPadding"> 
<!-- <div class="w3-panel">
    <label>
        This is a list of National Accounts you can bill through. Any services offered to you under this
        national account program must be billed through the national account directly. Selecting accounts you do
        not support could result in not getting paid on those specific transactions if supporting Service Plus
        clients.
    </label>
</div> -->
    <div class="row text-left">
        <label class="col-12"><strong> This is a list of National Accounts you can bill through. Any services offered to you under this
            national account program must be billed through the national account directly. Selecting accounts you do
            not support could result in not getting paid on those specific transactions if supporting Service Plus
            clients.</strong></label>
    </div>
<div id="nationalAccountTab" class="mt-3 mb-3">
    <div class="row">
        <div class="col-md-6">
            <div class="col-md-12 mt-2 text-center">
                <h6>Select the National Accounts Supported</h6>
            </div>
            <div class="boxBorder ml-5">
                <div class="row Name-Selector no-gutters">
                    <ng-container *ngFor="let data of nationalAccountData; let i=index">
                        <div class="col-sm-6 align-center">
                            <div class="boxstyle mb-2" [ngClass]="{'disableBox': isMasterLicensedOwner == true}">
                                <label (click)="addNationalAccount(data.nationalAccountKey)">
                                    {{ data.nationalAccountKey }}
                                </label>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="col-md-12 mt-2 text-center">
                <h6>National Accounts Selected</h6>
            </div>
            <div class="boxBorder  ml-4">
                <div class="row Name-Selector no-gutters">
                    <ng-container *ngFor="let data of providerNationalAccountData; let i=index">
                        <div class="col-sm-6 align-center">
                            <div class="boxstyle mb-2">
                                <label sortable="nationalAccountKey" (sort)="onSort($event)">
                                    {{ data.nationalAccountKey }}
                                </label>
                            </div>
                            <div class="boxRemove">
                                <button class="btn btn-default btn-sm" nbTooltip="Remove"
                                    (click)="removeNationalAccount(data)" [disabled]="isMasterLicensedOwner">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
</div>