<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6 class="modal-title">Upload File</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="col-sm-12">
    <div class="modal-body text-center mt-2">
        <div class="btn btn-sm">
                <input type="file" #csvReader accept=".csv" (change)="onFileSelectedMethod($event)">
        </div>
    </div>
</div>
<div class="floatR mt-2">
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="uploadFiles()">Uplaod</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="closeDialog()">Close</button>
</div>