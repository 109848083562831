import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RatesDialogComponent } from '../../dialogs/rates-dialog/rates-dialog.component';
import { AddCustomerAccountDialogComponent } from '../../dialogs/add-customer-account-dialog/add-customer-account-dialog.component';
import { ProviderAccountService } from 'src/app/services/provider-account.service';
import { SortEvent, SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'ngx-location-accounts',
  templateUrl: './location-accounts.component.html',
  styleUrls: ['./location-accounts.component.scss'],
})
export class LocationAccountsComponent implements OnInit {
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  @Input() locationKey: string;
  loading: boolean = false;
  providerKey: string;
  licensedOwnerKey: string;
  arrProviderAccount: any;
  searchText: any;

  constructor(public dialog: MatDialog,
    private providerAccountService: ProviderAccountService,
    private utilityService: UtilityService,
    private userService: UserService) { }

  ngOnInit() {
    this.providerKey = this.locationKey;
    const arrProviderAccountFilters = {
      providerKey: this.providerKey,
      deleted: false,
    };
    this.getProviderAccounts(arrProviderAccountFilters);
  }

  getProviderAccounts(arrProviderAccountFilters) {
    this.loading = true;
    this.providerAccountService.getProviderAccounts(arrProviderAccountFilters).subscribe((result: any) => {
      this.arrProviderAccount = result.body;
      this.onSort({ column: 'accountName', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  openRatesDialog(providerAccount) {
    const dialogRef = this.dialog.open(RatesDialogComponent, {
      width: '95%',
      height: '92%',
      position: {
        top: '4%'
      },
      data: { providerAccount, isParent: false },
    });
  }

  addAccount(value) {
    const dialogRef = this.dialog.open(AddCustomerAccountDialogComponent, {
      width: '80%',
      position: {
        top: '5%'
      },
      data: { value },
    });
  }

  onInput(val: string) {
    this.search.emit(this.searchText);
  }

  onSort({ column, direction }: SortEvent) {
    this.arrProviderAccount = this.utilityService.sortData(this.headers, column, direction, this.arrProviderAccount);
  }

}
