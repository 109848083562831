<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="w3-panel">
    <label>
        Note: Do not select capabilities you cannot support; doing so is violation of the Terms & Conditions of
        membership in the Service Plus Network.
    </label>
</div>
<div class="row">
    <div class="col mx-3 mt-2 border p-4">
        <app-checkbox-tree-ui *ngIf="providerCapabilities" [levelData]="providerCapabilities" [level]="level"
            [lastNode]="lastNode" [providerKey]="providerInputKey">
        </app-checkbox-tree-ui>
    </div>
</div>