<app-loader [loading]="loading"></app-loader>
<nb-card>
    <nb-card-body>
        <form [formGroup]="profileDetailsForm">
            <div class="row mt-3">
                <div class="col-12 col-md-9">
                    <div class="row">
                        <div class="col-md-12 alert alert-danger m-2 text-center" *ngIf="atleasetOneRequired()">
                            Please enter at least one phone number
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <input type="text" matInput formControlName="firstName" required
                                    placeholder="First Name" autocomplete="off">
                                <mat-error
                                    *ngIf="profileDetailsForm.controls.firstName.touched && profileDetailsForm.controls.firstName.errors">
                                    <span *ngIf="profileDetailsForm.controls.firstName.errors.required">
                                        First Name is required.
                                    </span>
                                    <span *ngIf="profileDetailsForm.controls.firstName.errors.pattern">
                                        Enter valid First Name.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <input type="text" matInput formControlName="lastName" required placeholder="Last Name"
                                    autocomplete="off">
                                <mat-error
                                    *ngIf="profileDetailsForm.controls.lastName.touched && profileDetailsForm.controls.lastName.errors">
                                    <span *ngIf="profileDetailsForm.controls.lastName.errors.required">
                                        Last Name is required.
                                    </span>
                                    <span *ngIf="profileDetailsForm.controls.lastName.errors.patterns">
                                        Enter valid Last Name.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <input type="email" matInput formControlName="email" [readonly]="!isEdit" required
                                    [disabled]="isDisableMyProfile" placeholder="Email" autocomplete="off">
                                <mat-error
                                    *ngIf="profileDetailsForm.controls.email.touched && profileDetailsForm.controls.email.errors">
                                    <span *ngIf="profileDetailsForm.controls.email.errors.required">
                                        Email is required.
                                    </span>
                                    <span *ngIf="profileDetailsForm.controls.email.errors.pattern">
                                        Enter valid email address.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                            <mat-error *ngIf="isEmailAvailable">
                                <span>
                                    Email id is already exist.
                                </span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <input matInput mask="000-000-0000" formControlName="officePhone"
                                    placeholder="Office Phone" autocomplete="off">
                                <mat-error
                                    *ngIf="profileDetailsForm.controls.officePhone.touched && profileDetailsForm.controls.officePhone.errors">
                                    <span *ngIf="profileDetailsForm.controls.officePhone.errors.required">
                                        Office Phone Number is required.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <input matInput mask="000-000-0000" formControlName="cellPhone" placeholder="Cell Phone"
                                    autocomplete="off">
                                <mat-error
                                    *ngIf="profileDetailsForm.controls.cellPhone.touched && profileDetailsForm.controls.cellPhone.errors">
                                    <span *ngIf="profileDetailsForm.controls.cellPhone.errors.pattern">
                                        Enter valid Cell Phone.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <mat-select formControlName="supervisor" placeholder="Supervisor"
                                    [disabled]="isDisableMyProfile">
                                    <mat-option *ngFor="let val of supervisorDetails | orderBy:'firstName'"
                                        [value]="val.userKey">
                                        {{val.firstName}} {{val.lastName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <input type="text" matInput formControlName="email" readonly placeholder="UserName"
                                    autocomplete="off" [disabled]="!isEdit">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <input type="text" matInput formControlName="jobTitle" [readonly]="isDisableMyProfile"
                                    [disabled]="isDisableMyProfile" placeholder="Job Title" autocomplete="off">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <mat-form-field>
                                <mat-select formControlName="userStatus" placeholder="User Status"
                                    [disabled]="isDisableMyProfile">
                                    <mat-option *ngFor="let val of userStatusDetails | orderBy:'statusKey'"
                                        [value]="val.statusKey">
                                        {{val.statusKey}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4" *ngIf="isEdit">
                            <mat-form-field>
                                <input type="password" matInput formControlName="password" required
                                    placeholder="Password">
                                <mat-error
                                    *ngIf="profileDetailsForm.controls.password.invalid && (profileDetailsForm.controls.password.dirty || profileDetailsForm.controls.password.touched)">
                                    <span *ngIf="profileDetailsForm.controls.password.errors.required">
                                        Password is required.
                                    </span>
                                    <span *ngIf="profileDetailsForm.controls.password.errors.pattern">
                                        Password must be of 8 character long having at least one upper case ,one
                                        lower case ,one digit,one special character.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4" *ngIf="isEdit">
                            <mat-form-field>
                                <input matInput type="password" formControlName="confirmPassword" required
                                    placeholder="Confirm Password">
                                <mat-error
                                    *ngIf="profileDetailsForm.controls.confirmPassword.errors && profileDetailsForm.controls.confirmPassword.errors.compareEqual">
                                    <span>Password do not match.</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="!isEdit">
                        <div class="col-md-12">
                            <mat-checkbox formControlName="okToTextMsg">
                                <label>I agree to recieve Text Messaged to my Cell Phone from Service Plus.
                                    Messaging/Data Rates may apply</label>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 text-center">
                    <div class="row mt-2">
                        <div class="col-sm-12">
                            <div class="image-preview">
                                <img [src]="imgURL" height="140px" *ngIf="imgURL">
                                <div>
                                    <label class="btn image-button mt-2">
                                        Update Profile Picture
                                        <input type="file" accept='image/*' #file (change)="preview(file.files, $event)"
                                            class="uploadFile img" value="Upload Photo"
                                            style="width: 0px;height: 0px;overflow: hidden;">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>