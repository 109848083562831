<div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort matSortActive="accountName" matSortDirection="desc">
        <!-- Date Column -->
        <ng-container matColumnDef="accountName">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;" mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.accountName}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef style="width: 15%;" mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
        </ng-container>

        <!-- Owner Column -->
        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Owner </th>
            <td mat-cell *matCellDef="let element"> {{element.ownerFirstName}} {{element.ownerLastName}}</td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Address </th>
            <td mat-cell *matCellDef="let element"> {{element.fullAddress}} </td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Phone </th>
            <td mat-cell *matCellDef="let element"> {{element.businessHoursPhone}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef style="width: 12%;"> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.dispatchEmail}} </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Category </th>
            <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>

        <!-- Work Order Column -->
        <ng-container matColumnDef="workOrder">
            <th mat-header-cell *matHeaderCellDef style="width: 7%;"> Work Order </th>
            <td mat-cell *matCellDef="let element"> {{element.workOrder}} </td>
        </ng-container>

        <!-- Action Column -->
        <!-- <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;"> Action </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item >Add Prospect</button>
                    <button mat-menu-item (click)="editApplication(element)">Edit Application</button>
                    <button mat-menu-item (click)="deleteApplication(element)">Delete Application</button>
                </mat-menu>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
        aria-label="Select page of periodic elements">
    </mat-paginator>  -->

    <hr>
    <div class="row bottomRow">
        <!-- <div class="col-2 new-application-button">
            <button mat-button extended (click)="addProviderApplicationPrompt()">
            <mat-icon>add</mat-icon>
            New Application
            </button>
        </div> -->
        <div class="col-10 pageNav">
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </div>
    
</div>