import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PaymentTermsComponent } from './payment-terms/payment-terms.component';
import { SetupRatesComponent } from 'src/app/shared/setup-rates/setup-rates.component';
import { ProviderAccountDetailsComponent } from '../../pages/provider-account/provider-account-details/provider-account-details.component';

@Component({
  selector: 'ngx-rates-dialog',
  templateUrl: './rates-dialog.component.html',
  styleUrls: ['./rates-dialog.component.scss'],
})
export class RatesDialogComponent implements OnInit {

  @ViewChild(SetupRatesComponent, { static: false }) setupRatesComponent: SetupRatesComponent;
  @ViewChild(PaymentTermsComponent, { static: false }) paymentTermsComponent: PaymentTermsComponent;
  @ViewChild(ProviderAccountDetailsComponent, { static: false })
  providerAccountDetailsComponent: ProviderAccountDetailsComponent;

  dialogTitle: String;
  tab: number = 0;
  selectedIndex: number = 0;
  providerAccount: any;
  licensedOwnerKey: any;
  disablePaymentTerm: boolean = true;
  accountName: String = '';
  isParent: boolean;
  goNextPage: boolean = false;

  constructor(
    public dialog: MatDialogRef<RatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.providerAccount = this.data.providerAccount;
    this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
    this.accountName = this.providerAccount.accountName;
    this.isParent = this.data.isParent;

    if (!this.licensedOwnerKey) {
      this.disablePaymentTerm = false;
    } else {
      this.disablePaymentTerm = true;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.tab = tabChangeEvent.index;
    this.selectedIndex = tabChangeEvent.index;
  }

  closeDialog() {
    this.dialog.close();
  }

  next(goNext) {
    this.goNextPage = goNext;
    if (this.tab === 0) {
      this.providerAccountDetailsComponent.updateProviderAccount();
    }
    if (this.selectedIndex === 1) {
      this.setupRatesComponent.getProviderAccountPricing();
    }
    if (this.tab === 4) {
      this.paymentTermsComponent.updateProviderAccountDetails();
    }

    // if (this.tab === 7) {
    //   if (this.licensedOwnerKey) {
    //     this.selectedIndex = this.selectedIndex + 1;
    //   } else {
    //     this.dialog.close();
    //   }
    // }

    if (this.licensedOwnerKey) {
      if (this.tab === 6) {
        this.dialog.close();
      }
    } else {
      if (this.tab === 5) {
        this.dialog.close();
      }
    }

    if (goNext && this.tab !== 0) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  saveCompleted(status) {
    if (status === 'true' && this.goNextPage) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }
}
