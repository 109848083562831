<app-loader [loading]='loading'></app-loader>
<nb-card>
    <nb-card-header *ngIf="isLoggedInUser">Users</nb-card-header>
    <nb-card-body>
        <form [formGroup]="searchUser">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <input matInput placeholder="Name" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-select placeholder="Status" formControlName="userStatusKey">
                            <mat-option disabled>Select Status</mat-option>
                            <mat-option *ngFor="let status of userStatus | orderBy : 'statusKey'"
                                [value]="status.statusKey">
                                {{status.statusKey}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-select placeholder="Security Group" formControlName="securityGroup">
                            <mat-option disabled>Select Security Group</mat-option>
                            <mat-option *ngFor="let securityGroup of securityGroupData | orderBy : 'description'"
                                [value]="securityGroup.description">{{securityGroup.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-select placeholder="Is an Approver" formControlName="isApprover">
                            <mat-option value="1">Yes</mat-option>
                            <mat-option value="0">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-select placeholder="Is an Emergency Approver" formControlName="isEmergencyApprover">
                            <mat-option value="1">Yes</mat-option>
                            <mat-option value="0">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 text-right my-auto">
                    <button class="btn btn-sm btn-primary mr-1" (click)='getUsers()'>Search</button>
                    <button class="btn btn-sm btn-primary" (click)='clearFilters()'>Clear</button>
                </div>
            </div>
        </form>
        <div class="col-md-12 text-right mt-4">
            <button class="btn btn-primary btn-sm" [ngClass]="{'mr-2': isLoggedInUser}"
                (click)="OpenInviteUserDialog(0)">Invite User</button>
        </div>
        <div id="users" class="table-responsive mt-2">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th class="align-middle">Action</th>
                        <th sortable="firstName" (sort)="onSort($event)" class="align-middle" direction="asc">User Name
                        </th>
                        <th sortable="officePhone" (sort)="onSort($event)" class="align-middle">Office Phone</th>
                        <th sortable="description" (sort)="onSort($event)" class="align-middle">Security Group </th>
                        <th sortable="isEmergencyApprover" (sort)="onSort($event)" class="align-middle">Emergency
                            Approver</th>
                        <th sortable="isApprover" (sort)="onSort($event)" class="align-middle">Is Approver?</th>
                        <th sortable="email" (sort)="onSort($event)" class="align-middle">Email</th>
                        <th sortable="userStatusKey" (sort)="onSort($event)" class="align-middle">Status</th>
                    </tr>
                </thead>
                <tbody *ngIf="users.length > 0; else noData">
                    <tr *ngFor="let user of users">
                        <td>
                            <button class="btn btn-sm btn-default btnAnimation" nbTooltip="Edit"
                                (click)="openEditUserDialog(1, user)">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>
                        <td>{{user.firstName}} {{user.lastName}}</td>
                        <td>{{user.officePhone}}</td>
                        <td>{{user.description}}</td>
                        <td>{{user.isEmergencyApprover ? 'Yes' : 'No'}}</td>
                        <td>{{user.isApprover ? 'Yes' : 'No'}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user?.userStatusKey}}</td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="9" class="text-center">No data available</td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
        <div class="text-right m-2">
            <button *ngIf="isLoggedInUser" type="button" class="btn btn-primary btn-sm"
                (click)="nextPage()">Next</button>
        </div>
    </nb-card-body>
</nb-card>