import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { MatDialog } from '@angular/material/dialog';
import { ProfileService } from 'src/app/services/profile.service';
import { NbToastrService } from '@nebular/theme';
import { UtilityService } from 'src/app/services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
})
export class BillingDetailsComponent implements OnInit {

  @Input() showBack: boolean = false;
  @Input() showSaveNext: boolean = false;
  @Input() showSave: boolean = false;
  @Input() providerDetails: any;
  @Input() locationKey: string;
  @Output() saveCompleted = new EventEmitter<string>();

  billingDetailsForm: UntypedFormGroup;
  loading: boolean;
  paymentTerms: any;
  paymentMethods: any;
  billingDetails: any;
  providerKey: string;
  hasCentralBilling: any;
  goNextPage: boolean = false;
  defaultLocationKey: string;
  isParent: boolean = false;
  getChildData: any;
  securityGroupDetails: any = [];
  sideBarStatus = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private toast: NbToastrService,
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.providerKey = sessionStorage.getItem('providerKey');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');
    if (!!this.locationKey) {
      this.providerKey = this.locationKey;
      this.isParent = false;}
    else if (!this.providerDetails?.parentKey) {
        this.isParent = true;
      }
     else if (!!this.defaultLocationKey) {
      this.providerKey = this.defaultLocationKey;
      this.isParent = false;
    } else if (this.providerDetails != undefined) {
      this.providerKey = this.providerDetails.providerKey;
      this.isParent = true;
    } else if (sessionStorage.getItem('providerParentKey') != 'null' && sessionStorage.getItem('providerParentKey') != '0') {
      this.providerKey = sessionStorage.getItem('providerKey');
      this.isParent = false;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
      this.isParent = true;
    }

    this.billingDetailsForm = this.formBuilder.group({
      //hasCentralBilling: [{ value: '', disabled: !this.isParent }],
      hasCentralBilling: [null],
      billingEmail: [null, Validators.pattern(patterns.emailPattern)],
      billingFax: [null, Validators.pattern(patterns.mobnumPattern)],
      primaryBillingContact: [null],
      paymentTermKey: [null],
      paymentMethodKey: [null],
      providerKey: [''],
      billingPhone: [null, Validators.pattern(patterns.mobnumPattern)]
    });

    this.getPaymentTerms();
    this.getPaymentMethods();
    this.getBillingDetails();

    // get sidebar collapsed/expanded status
    this.utilityService.sidebarCollapsedStatus.subscribe(message => {
      this.sideBarStatus = message;
    });
  }

  getPaymentTerms() {
    const paymentData = { deleted: false };
    this.loading = true;
    this.profileService.getPaymentTerms(paymentData).subscribe((result: any) => {
      this.loading = false;
      const byDay = result.body;
      byDay.sort(function (a, b) {
        a = a.paymentTermKey.split(' ')[0];
        b = b.paymentTermKey.split(' ')[0];
        return a - b;
      });
      this.paymentTerms = byDay;
    }, (error) => {
      this.loading = false;
    });
  }

  getPaymentMethods() {
    const data = { deleted: false };
    this.loading = true;
    this.profileService.getPaymentMethods(data).subscribe((result: any) => {
      this.loading = false;
      this.paymentMethods = result.body;
    }, (error) => {
      this.loading = false;
    });
  }

  showOptions() {
    this.hasCentralBilling = this.billingDetailsForm.controls.hasCentralBilling.value;
    this.updateValidations();
  }

  getBillingDetails() {
    const billingData = {
      providerKey: this.providerKey,
      deleted: false,
    };
    this.loading = true;
    this.profileService.getProviderDetails(billingData).subscribe((result: any) => {
      this.billingDetails = result.body[0] || [];
      this.hasCentralBilling = this.billingDetails.hasCentralBilling;

      if((this.billingDetails.billingFax != null && this.billingDetails.billingFax != '' ) && this.billingDetails.billingFax.length == 12 && this.billingDetails.billingFax.startsWith("+1")){
        this.billingDetailsForm.patchValue({
          billingFax: this.billingDetails.billingFax.slice(2)})}
      else{ this.billingDetailsForm.patchValue({
        billingFax: null })}

      if((this.billingDetails.billingPhone != null && this.billingDetails.billingPhone != '' ) && this.billingDetails.billingPhone.length == 12 && this.billingDetails.billingPhone.startsWith("+1")){
        this.billingDetailsForm.patchValue({
          billingPhone: this.billingDetails.billingPhone.slice(2)})}
      else{ this.billingDetailsForm.patchValue({
        billingPhone: null })}    

      this.billingDetailsForm.patchValue({
        //hasCentralBilling: this.billingDetails.hasCentralBilling,
        hasCentralBilling: this.billingDetails.hasCentralBilling == null ? 1 : this.billingDetails.hasCentralBilling,
        billingEmail: this.billingDetails.billingEmail,
       // billingFax: this.billingDetails.billingFax || '',
        primaryBillingContact: this.billingDetails.primaryBillingContact,
        paymentTermKey: this.billingDetails.paymentTermKey,
        paymentMethodKey: this.billingDetails.paymentMethodKey,
        providerKey: this.providerKey,
        //billingPhone: this.billingDetails.billingPhone,
      });

      this.loading = false;

      this.updateValidations();
    }, (error) => {
      this.loading = false;
    });
  }

  updateProviderBillingDetails() {
    if (this.billingDetailsForm.invalid) {
      this.utilityService.validateAllFormFields(this.billingDetailsForm);
      return false;
    }

    this.hasCentralBilling = this.billingDetailsForm.controls.hasCentralBilling ? 1 : 0;

    if(this.billingDetailsForm.value.billingFax != '' && this.billingDetailsForm.value.billingFax != null && this.billingDetailsForm.value.billingFax.length == 10 ){
      this.billingDetailsForm.value.billingFax = '+1' + this.billingDetailsForm.value.billingFax    }
      else{this.billingDetailsForm.value.billingFax = null}
  
    if(this.billingDetailsForm.value.billingPhone != '' && this.billingDetailsForm.value.billingPhone != null && this.billingDetailsForm.value.billingPhone.length == 10 ){
      this.billingDetailsForm.value.billingPhone = '+1' + this.billingDetailsForm.value.billingPhone
    }
    else{this.billingDetailsForm.value.billingPhone = null}  
    
    if(this.billingDetailsForm.value.primaryBillingContact == ''){
      this.billingDetailsForm.value.primaryBillingContact = null
    }

    if(this.billingDetailsForm.value.billingEmail == ''){
      this.billingDetailsForm.value.billingEmail = null
    }

    this.loading = true;
    this.profileService.updateProviderDetails(this.billingDetailsForm.value).subscribe((result: any) => {
      this.loading = false;
      this.saveCompleted.emit('true');
      if (result.success) {
        this.toast.success(result.message[0], 'Success');
        if (this.isParent) {
          const data = {
            'parentKey': this.providerKey,
            'deleted': false,
          };

          this.profileService.getProviderDetails(data).subscribe((res: any) => {
            this.getChildData = res.body;
            if (this.getChildData.length > 0) {
              const childData = [];
              this.getChildData.forEach(item =>
                childData.push({
                  providerKey: item.providerKey,
                  hasCentralBilling: this.billingDetailsForm.get('hasCentralBilling').value,
                  updatedUserKey: sessionStorage.getItem('userKey'),
                }));
              this.profileService.updateProviderChildDetails(childData).subscribe((res: any) => { }, (error) => {
              });
            }
          }, (error) => {
            console.info(error);
          });
        }

        if (this.goNextPage) {
          this.utilityService.saveNextRoute();
          this.router.navigate([sessionStorage.getItem('nextPageLink')], { relativeTo: this.route });
        }
      } else {
        this.toast.warning(result.message[0], 'Error');
      }
    }, (error) => {
      this.saveCompleted.emit('false');
      this.loading = false;
    });
  }

  updateValidations() {
    if ((this.isParent) || (!this.isParent && !this.hasCentralBilling)) {
      this.billingDetailsForm.controls.billingEmail.setValidators([Validators.required,
      Validators.pattern(patterns.emailPattern)]);
      // this.billingDetailsForm.controls.primaryBillingContact.setValidators(Validators.required);
      this.billingDetailsForm.controls.billingPhone.setValidators([Validators.required, Validators.pattern(patterns.mobnumPattern)]);
    } else {
      this.billingDetailsForm.controls.billingEmail.setValidators(Validators.pattern(patterns.emailPattern));
      // this.billingDetailsForm.controls.primaryBillingContact.clearValidators();
      this.billingDetailsForm.controls.billingPhone.setValidators(Validators.pattern(patterns.mobnumPattern));
    }
    this.billingDetailsForm.controls.billingEmail.updateValueAndValidity();
    // this.billingDetailsForm.controls.primaryBillingContact.updateValueAndValidity();
    this.billingDetailsForm.controls.billingPhone.updateValueAndValidity();
  }

  saveNext() {
    this.updateProviderBillingDetails();
    this.goNextPage = true;
  }

  previousPage() {
    this.utilityService.savePreviousRoute();
    this.router.navigate([sessionStorage.getItem('previousPageLink')], { relativeTo: this.route });
  }

  handleError = (control: string, error: string) => {
    return this.billingDetailsForm.controls[control].hasError(error);
  }
}
