<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="col-md-12">
    <form [formGroup]="accountDetailForm">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field *ngIf="!isEdit">
                    <mat-label>Account Name</mat-label>
                    <input matInput placeholder="Account Name" formControlName="Name" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.Name.touched && accountDetailForm.controls.Name.errors">
                        <span *ngIf="accountDetailForm.controls.Name.errors.required">
                            Account Name is required.
                        </span>
                    </mat-error>
                </mat-form-field>
                <h5 class="account-name" *ngIf="isEdit && providerAccount">{{providerAccount.Name}}</h5>
            </div>
            <div class="col-md-6 text-center">
                <mat-checkbox formControlName="defaultRetailAccount">
                    <label>Default Retail Account</label>
                </mat-checkbox>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input type="text" matInput placeholder="Address" formControlName="address" autocomplete="off"
                        required ngx-google-places-autocomplete (onAddressChange)="getDetailedAddress($event)">

                    <mat-error
                        *ngIf="accountDetailForm.controls.address.touched && accountDetailForm.controls.address.errors">
                        <span *ngIf="accountDetailForm.controls.address.errors.required">
                            Address is required.
                        </span>
                        <span *ngIf="accountDetailForm.controls.address.errors.pattern">
                            Enter valid Address.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-3">
                <mat-form-field>
                    <input matInput placeholder="City" formControlName="city" autocomplete="off" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.city.touched && accountDetailForm.controls.city.errors">
                        <span *ngIf="accountDetailForm.controls.city.errors.required">
                            City is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-3">
                <mat-form-field>
                    <input matInput placeholder="State" formControlName="stateKey" autocomplete="off" required>
                    <mat-error *ngIf="accountDetailForm.controls.stateKey.errors">
                        <span>
                            State is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-2">
                <mat-form-field>
                    <input matInput placeholder="Zip" formControlName="zipCode" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.zipCode.touched && accountDetailForm.controls.zipCode.errors">
                        <span *ngIf="accountDetailForm.controls.zipCode.errors.required">
                            Zip code is required.
                        </span>
                        <span *ngIf="accountDetailForm.controls.zipCode.errors.pattern">
                            Enter valid Zip code.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <mat-form-field>
                    <input matInput mask="000-000-0000" placeholder="Primary Contact Phone"
                        formControlName="primaryContactPhone">
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-4"
                *ngIf="this.accountDetailForm.controls.afterHoursRatesAllowed.value || !isEdit">
                <mat-checkbox formControlName="afterHoursRatesAllowed">
                    <label>After Hour Rates Allowed</label>
                </mat-checkbox>
            </div>
            <div class="col-md-6 col-lg-4">
                <mat-checkbox formControlName="autoDispatch">
                    <label>Auto Dispatch Allowed</label>
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-2">
                <mat-form-field>
                    <mat-label>Select Rating</mat-label>
                    <mat-select formControlName="qualityRating" matNativeControl placeholder="Quality Rating">
                        <mat-option value="">Select Rating</mat-option>
                        <mat-option *ngFor="let value of rating" [value]="value">
                            {{value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-2">
                <mat-form-field>
                    <mat-label>Membership Status</mat-label>
                    <mat-select formControlName="membershipStatus" matNativeControl placeholder="Membership Status">
                        <mat-option value="">Select Status</mat-option>
                        <mat-option *ngFor="let val of membershipList" [value]="val.membershipStatusKey">
                            {{val.membershipStatusKey}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-lg-2">
                <mat-form-field>
                    <mat-label>Account Status</mat-label>
                    <mat-select (selectionChange)="showDiscontinuedReason()" formControlName="accountStatus"
                        matNativeControl placeholder="Account Status" required>
                        <mat-option value="">Select Status</mat-option>
                        <ng-container *ngFor="let val of userStatusDetails">
                            <mat-option *ngIf="val.isSelected" [value]="val.statusKey">
                                {{val.description}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="showDiscontinuedReasonFlag" class="col-md-6">
                <mat-form-field>
                    <mat-label>Discontinued Reason</mat-label>
                    <input matInput placeholder="Discontinued Reason" formControlName="providerDiscontinuedReason"
                        required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.providerDiscontinuedReason.touched && accountDetailForm.controls.providerDiscontinuedReason.errors">
                        <span *ngIf="accountDetailForm.controls.providerDiscontinuedReason.errors.required">
                            Discontinued Reason is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="showNotesReasonFlag" class="col-md-6">
                <mat-form-field>
                    <mat-label>Note</mat-label>
                    <input matInput formControlName="activeNotes" placeholder="Note" required>
                    <mat-error
                        *ngIf="accountDetailForm.controls.activeNotes.touched && accountDetailForm.controls.activeNotes.errors">
                        <span *ngIf="accountDetailForm.controls.activeNotes.errors.required">
                            Note is required.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>