import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userhoursLength: number;

  constructor(private http: HttpClient) { }

  getUsersDetails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/users/get`, data);
  }

  getUserLists(data = {}): Observable<any> {
    return this.http.post(`${environment.baseUrl}getUsersList`, data).pipe(
      map((response: any) => {
        if (environment.production) {
          const filteredData = response.body.filter(user => user.email.indexOf('yopmail.com') === -1);
          response.body = filteredData;
          return response;
        } else {
          return response;
        }
      }));
  }

  getLicensedOwnerProviderUsersDetails(email): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites/get`, email);
  }

  getSecurityGroups(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/securitygroups/get`, data);
  }

  getUserTypes(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/usertypes/get`, data);
  }

  getUserHoursDetails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/userhours/get`, data);
  }

  updateUserDetails(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}systemsecurities/users`, data);
  }

  updateUserHoursDetails(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/userhours`, data);
  }

  addUserDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/users`, data);
  }

  addUserHoursDetails(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/userhours`, data);
  }

  getUserSkills(arrReqData: any = {}): Observable<any> {
    if (!arrReqData.deleted) {
      arrReqData.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/userskills/get`, arrReqData);
  }

  getSkills(arrReqData: any = {}): Observable<any> {
    if (!arrReqData.deleted) {
      arrReqData.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}keyvaluepairs/skills/get`, arrReqData);
  }

  addEditSkills(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}getSecurityPoliciesSelect`, arrReqData);
  }

  // get user status
  getUserStatus(arrReqData: any = {}): Observable<any> {
    if (!arrReqData.deleted) {
      arrReqData.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}keyvaluepairs/userstatuses/get`, arrReqData);
  }

  // get job titles
  getJobTitles(arrReqData: any = {}): Observable<any> {
    if (!arrReqData.deleted) {
      arrReqData.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}keyvaluepairs/jobtitles/get`, arrReqData);
  }

  /**
 * It will add user security groups(access for menus)
 * @param data
 */
  addUserSecurityGroup(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/usersecuritygroups`, data);
  }

  addorEditSecurityGroups(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}getSecurityPoliciesSelect`, arrReqData);
  }

  // get securitygroups
  getGroups(postData: any = {}): Observable<any> {
    if (!postData.deleted) {
      postData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/securitygroups/get`, postData);
  }

  getSecurityGroupRoles(postData: any = {}): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getServicePlusRolesSelect`);
  }

  getSecurityGroupPolicies(postData: any = {}): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getSecurityPoliciesSelect`);
  }

  // add security role policies
  addUserSecurityRolePolicies(arrReqData): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securityrolepolicies`, arrReqData);
  }

  // add security role policies
  addUserSecurityGroupPolicies(arrReqData): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/securitygrouppolicies`, arrReqData);
  }

  getSecurityRoleDetails(secuirtyKey: any = {}): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getSecurityPoliciesSelect/` + secuirtyKey);
  }

  getUserSecurityGroups(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/usersecuritygroups/get`, data);
  }

  /**
   * It will update user security group
   * @param data { userKey, securityGroupKey }
   */
  updateUserSecurityGroup(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}systemsecurities/usersecuritygroups`, data);
  }

  setUserHours(usersHoursLength: number) {
    this.userhoursLength = usersHoursLength;
  }

  getUserHours() {
    return this.userhoursLength;
  }

  /***
  * Desc: Get user availability hours
  * @params postParams
  * @return result array
  */
  getUserAvailabilityHours(postParams: any = {}): Observable<any> {
    if (!postParams.deleted) {
      postParams.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/userhours/get`, postParams);
  }

  /***
  * Desc: Add user availability hours
  * @params postParams
  * @return result success
  */
  addAvailabilityHoursBatch(data: any = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/userhours/batch`, data);
  }

  /***
  * Desc: Update user availability hours
  * @params postParams
  * @return result success
  */
  updateUserAvailabilityHours(filter: any = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/userhours`, filter);
  }


  /***
  * Desc: Add user availability hours
  * @params postParams
  * @return result success
  */
  addUserSkills(data: any = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/userskills`, data);
  }

  /***
  * Desc: update user skills
  * @params postParams
  * @return result success
  */
  updateUserSkills(data: any = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/userskills`, data);
  }

  getUserInvitationDetails(params: any = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations/get`, params);
  }

  getUserInvitations(params = {}): Observable<any> {
    return this.http.post(`${environment.baseUrl}getUserInvitations`, params).pipe(
      map((response: any) => {
        if (environment.production) {
          const filteredData = response.body.filter(invitation => invitation.email.indexOf('yopmail.com') === -1);
          response.body = filteredData;
          return response;
        } else {
          return response;
        }
      })
    );
  }

  addUserInvitations(data: any = {}): Observable<any> {
    if (data.cellPhone && !data.cellPhone.startsWith('+')) {
      data.cellPhone = '+1' + data.cellPhone;
    }

    if (!data.cellPhone) {
      data.cellPhone = '+19999999999';
    }
    return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations`, data);
  }

  addUserInvitationsBatch(data: any = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations/batch`, data);
  }

  updateUserInvitations(data: any = {}): Observable<any> {
    if (data.cellPhone && !data.cellPhone.startsWith('+')) {
      data.cellPhone = '+1' + data.cellPhone;
    }

    if (!data.cellPhone) {
      data.cellPhone = '+19999999999';
    }
    return this.http.put(`${environment.apiUrl}systemsecurities/userinvitations`, data);
  }

  updateBatchUserInvitations(data: any = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}systemsecurities/userinvitations/batch`, data);
  }

  insertBatchCommunicationLogs(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationlogs/batch`, data);
  }

  checkEmailInUserInvitations(params = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations/get`, params);
  }

  getUserGroups(data = {}): Observable<any> {
    return this.http.post(`${environment.baseUrl}getUserSecurityGroups`, data);
  }

  getUserDetailsByAssociationKey(data = {}): Observable<any> {
    return this.http.post(`${environment.baseUrl}getUserDetailsByAssociationKey`, data);
  }
}
