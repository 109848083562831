<div *ngIf="loading" id="preloader">
  <div id="loader"></div>
</div>
<div class='container'>
  <div class="row mt-3 mb-3">
    <div class="col-md-9">
      <div class=" div-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th *ngFor="let day of days">{{ day }}</th>
            </tr>
          </thead>
          <tbody *ngIf="weekData.length > 0; else noData">
            <tr>
              <td *ngFor="let item of weekData">
                <div *ngIf="!(editDayFlag === item.daysOfWeekKey)">
                  {{ item.startTime | differenceInHoursBetweenTwoTimes: item.endTime}}
                  <button class="btn btn-default btn-sm btnAnimation mr-1 floatR" [disabled]="readOnlyUser" nbTooltip="Edit"
                    (click)="editDay(item)">
                    <i class="fas fa-pencil"></i>
                  </button>
                </div>
                <div *ngIf="editDayFlag === item.daysOfWeekKey">
                  <mat-form-field>
                    <input matInput placeholder="From" [ngxMatDatetimePicker]="MFromPicker" [value]="item.startTime" [(ngModel)]="item.startTime">
                    <ngx-mat-timepicker #MFromPicker>
                      [(ngModel)]="item.startTime" [disabled]="disabled" 
                      [showSpinners]="showSpinners"
                      [stepHour]="stepHour" [stepMinute]="stepMinute" 
                      [stepSecond]="stepSecond" 
                      [showSeconds]="showSeconds">
                    </ngx-mat-timepicker>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="To" [ngxMatDatetimePicker]="MToPicker" [value]="item.endTime" [(ngModel)]="item.endTime">
                    <ngx-mat-timepicker #MToPicker>
                      [(ngModel)]="item.endTime" [disabled]="disabled" 
                      [showSpinners]="showSpinners"
                      [stepHour]="stepHour" [stepMinute]="stepMinute" 
                      [stepSecond]="stepSecond" 
                      [showSeconds]="showSeconds">
                    </ngx-mat-timepicker>
                  </mat-form-field>
                  <button class="btn btn-default btn-sm btnAnimation ml-1"
                    (click)="updateAvailability(item.startTime, item.endTime, item.userHoursKey)">
                    <i class="far fa-check"></i>
                  </button>
                  <button class="btn btn-default btn-sm btnAnimation ml-1 floatR " (click)="closeDayData(editDayFlag)">
                    <i class="fal fa-times"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <ng-template #noData>
            <tbody>
                <tr>
                    <td colspan="7" class="text-center">No data available</td>
                </tr>
            </tbody>
        </ng-template>
        </table>
      </div>
    </div>

    <div class="col-md-3 ">
      <form [formGroup]="availabilityForm">
        <div>
          <div class="col-md-12">
            <button class='btn btn-primary btn-sm' [disabled]="readOnlyUser" (click)="updateAllAvailability24Hour()">24 Hour Available</button>
          </div> 
          <div class="col-md-12 mt-2">
            <mat-checkbox formControlName="outOfOffice"> Out of Office </mat-checkbox>
          </div>
          <div class="col-md-12 mt-2">
            <mat-form-field>
              <input matInput [matDatepicker]="expDate" placeholder="Expires at Midnight on:"
                  formControlName="OutOfOfficeExpireTimestamp" [min]="todayDate" (focus)="expDate.open()" required>
              <mat-datepicker-toggle matSuffix [for]="expDate"></mat-datepicker-toggle>
              <mat-datepicker #expDate></mat-datepicker>
          </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
