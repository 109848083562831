import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { NbToastrService } from '@nebular/theme';
import { patterns } from 'src/assets/patterns';

@Component({
  selector: 'app-add-edit-policy',
  templateUrl: './add-edit-policy.component.html',
  styleUrls: ['./add-edit-policy.component.scss']
})
export class AddEditPolicyComponent implements OnInit {

  policyDetailForm: UntypedFormGroup;
  eventNewPolicy = new EventEmitter();
  loading: boolean;
  policyTypes: any;
  isEdit: boolean;
  providerKey: string;
  userKey: string;
  todayDate: Date = new Date();
  parentKey: string;
  minDate = new Date();
  title = 'Add New Policy Type';

  constructor(
    public dialog: MatDialogRef<AddEditPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private providerAdminService: ProviderAdminService,
    private utilityService: UtilityService,
    private toast: NbToastrService,
  ) { }

  ngOnInit() {
    this.policyDetailForm = this.formBuilder.group({
      insuranceTypeKey: ['', Validators.required],
      policyExpDate: ['', Validators.required],
      policyRequirment: [''],
      policyNumber: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      insuredAmount: ['', [Validators.required, Validators.pattern(patterns.numberPattern)]],
    });

    this.providerKey = this.data.value.providerKey;
    this.parentKey = this.data.value.parentKey;
    this.getPolicyTypes();
    this.isEdit = false;

    if (this.data.value.parentKey === undefined) {
      this.isEdit = true;
      this.title = 'Edit Policy Type';
      this.policyDetailForm.controls.insuranceTypeKey.setValue(this.data.value.insuranceTypeKey);
      this.policyDetailForm.controls.policyExpDate.setValue(this.data.value.policyExpDate === null ?
        '' : new Date(this.data.value.policyExpDate));
      this.policyDetailForm.controls.policyRequirment.setValue(this.data.value.requirement);
      this.policyDetailForm.controls.policyNumber.setValue(this.data.value.policyNumber);
      this.policyDetailForm.controls.insuredAmount.setValue(this.data.value.insuredAmount);
    }

  }

  getPolicyTypes() {
    const filter = {
      providerKey: this.providerKey,
      deleted: false
    };
    this.loading = true;
    this.providerAdminService.getInsuranceTypes(filter).subscribe((res: any) => {
      this.policyTypes = res.body;
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  addNewPolicy() {
    if (this.policyDetailForm.invalid) {
      this.policyDetailForm.markAllAsTouched();
      return false;
    }
    if (this.isEdit) {
      this.editPolicyType();
    } else {
      this.loading = true;
      const filter = {
        providerKey: this.providerKey,
        insuranceTypeKey: this.policyDetailForm.controls.insuranceTypeKey.value,
        parentBrokerKey: this.parentKey,
        deleted: false,
      };

      this.providerAdminService.getProviderInsuranceDetails(filter).subscribe((res: any) => {
        this.loading = false;
        if (res.body.length > 0) {
          this.toast.warning('Policy Type already exists', 'Warning');
        } else {

          const formattedDate = this.utilityService.converDateInUnix(this.policyDetailForm.controls.policyExpDate.value);
          const insertData = {
            agencyName: '',
            agentName: '',
            agentPhone: '',
            agentEmail: '',
            agentFax: '',
            providerKey: this.providerKey,
            insertedUserKey: sessionStorage.getItem('userKey'),
            cartificateURL: '',
            deleted: false,
            providerInsuranceKey: '',
            systemDefault: true,
            updatedUserKey: '',
            parentBrokerKey: this.parentKey,
          };
          const json = Object.assign({ ...this.policyDetailForm.value, ...insertData });
          json.policyExpDate = formattedDate === 0 ? '' : formattedDate;

          this.loading = true;
          this.providerAdminService.addProviderInsurances(json).subscribe((res: any) => {
            this.closeDialog();
            this.policyDetailForm.reset();
            this.eventNewPolicy.emit();
            this.loading = false;
            this.isEdit = false;

            if (res.success) {
              this.toast.success(res.message[0], 'Success');
            } else {
              this.toast.warning(res.message[0], 'Error');
            }
          }, (error) => {
            console.log('error', error);
            this.loading = false;
          });
        }
      }, (error) => {
        console.log('error', error);
        this.loading = false;
      });
    }
  }

  editPolicyType() {
    const updateData = {
      providerInsuranceKey: this.data.value.providerInsuranceKey,
      updatedUserKey: sessionStorage.getItem('userKey'),
    };
    const formattedDate = this.utilityService.converDateInUnix(this.policyDetailForm.controls.policyExpDate.value);
    const policyDetailJson = Object.assign(this.policyDetailForm.value);
    policyDetailJson.policyExpDate = formattedDate === 0 ? '' : formattedDate;

    this.loading = true;
    this.providerAdminService.updateProviderInsurances({ ...policyDetailJson, ...updateData }).subscribe((res: any) => {
      this.closeDialog();
      this.policyDetailForm.reset();
      this.eventNewPolicy.emit();
      this.loading = false;
      this.isEdit = false;
      if (res.success) {
        this.toast.success(res.message[0], 'Success');
      } else {
        this.toast.warning(res.message[0], 'Error');
      }
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  getPolicyRequirement(event) {
    const filter = {
      insuranceTypeKey: event.value
    };
    this.loading = true;
    this.policyDetailForm.controls.policyRequirment.setValue('');
    this.providerAdminService.getLicensedOwnerInsuranceRequirements(filter).subscribe((res: any) => {
      if (res.body.length > 0) {
        this.policyDetailForm.controls.policyRequirment.setValue('Minimum Insured Amount Must be $' +
          res.body[0].minimumInsuredAmount + '. ' + res.body[0].notes);
      }
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  closeDialog() {
    this.dialog.close();
  }

  handleError = (control: string, error: string) => {
    return this.policyDetailForm.controls[control].hasError(error);
  }

}
