import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  customRegionMap: '';
}
@Component({
  selector: 'ngx-add-custom-region-dialog',
  templateUrl: './add-custom-region-dialog.component.html',
  styleUrls: ['./add-custom-region-dialog.component.scss']
})
export class AddCustomRegionDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

}
