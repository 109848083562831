<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-body>
        <form>
            <div class="col-md-12 mt-2 text-right mb-2">
                <button class=" btn btn-orange btn-sm" (click)="addNotes()">Add Note</button>
            </div>
            <div class="col-md-12">
                <div class="example-container border" *ngIf="dataSource; else noRecord">
                    <mat-table [dataSource]="dataSource" matSort>
                        <ng-container mat-header-cell matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Date/Time </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.date | timezoneConvertDate}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="note">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Notes </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.note}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="addedBy">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Added By </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.addedBy}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="privateForProvider">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Public </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.privateForProvider ? 'N': 'Y'}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="providerAccountNoteKey">
                            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Delete"
                                    (click)="deleteAccountNotes(row.providerAccountNoteKey)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                    </mat-table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
                </div>
                <ng-template #noRecord>
                    <div class="example-container border text-center p-2"> No Data available </div>
                </ng-template>
            </div>
        </form>
    </nb-card-body>
</nb-card>