import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { ProtocolService } from 'src/app/services/protocol.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { ApprovedProtocolsEditDialogComponent } from '../approved-protocols-edit-dialog/approved-protocols-edit-dialog.component';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-approved-protocols-list-dialog',
  templateUrl: './approved-protocols-list-dialog.component.html',
  styleUrls: ['./approved-protocols-list-dialog.component.scss']
})
export class ApprovedProtocolsListDialogComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  searchString: string;
  dialogTitle: string;
  arrProtocolDetails: any = [];
  loading = false;
  callerTypeKey: string;

  constructor(
    public dialogRef: MatDialogRef<ApprovedProtocolsListDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityservice: UtilityService,
    private dialogService: NbDialogService,
    private protocolService: ProtocolService,
    private toast: NbToastrService) { }

  ngOnInit() {
    this.callerTypeKey = this.data.callerTypeKey;
    this.dialogTitle = this.data.dialogTitle;
    this.getApprovedProtocolsList();
  }

  /***
    * @Desc: Get all Approved services by caller types
    * @returns success result
  */
  getApprovedProtocolsList() {

    this.loading = true;
    const licensedOwnerOfferingKey = this.data.data.licensedOwnerOfferingKey;

    const postParams = {
      licensedOwnerOfferingKey: this.data.data.licensedOwnerOfferingKey,
      callerTypeKey: this.callerTypeKey
    };

    this.protocolService.getCustomerProtocolsList(licensedOwnerOfferingKey).subscribe(response => {
      this.arrProtocolDetails = response.body;
      this.loading = false;
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  /***
    * @Desc: edit approved protocols
    * @returns success result
  */
  editCustomerProtocols(event: any) {

    const dialogRef = this.dialog.open(ApprovedProtocolsEditDialogComponent, {
      hasBackdrop: true,
      width: '50%',
      position: {
        top: '2%',
      },
      height: '50%',
      data: event
    });

    dialogRef.afterClosed().subscribe((reponse) => {
      this.getApprovedProtocolsList();
    });
  }

  /***
    * @Desc: remove approved protocols
    * @returns success result
  */
  removeCustomerProtocols(event: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        this.loading = true;
          const updateData = {
            customerProtocolKey: event.customerProtocolKey,
            updatedUserKey: event.insertedUserKey,
            deleted: 1,
          };

          this.protocolService.editCustomerProtocols(updateData)
          .subscribe(response => {
            if (response.success) {
              this.toast.success('Approved Protocol deleted Successfully!', 'Success');
            } else {
              this.toast.warning(response.message[0], 'Error');
            }
            this.getApprovedProtocolsList();
            this.loading = false;
          }, (error) => {
            console.log('error', error);
            this.loading = false;
          });
      }
    });
  }

  /***
  * @Desc: Export CSV protocol
  * @returns CSV result
  */
   exportToCSVServices() {
    const filterData = [];
    this.arrProtocolDetails.map(data => {
      const protocolsData = {
        offerings: data.offerings,
        supported: (data.supported === true) ? 'Yes' : 'No',
        operatingScheduleKey: data.operatingScheduleKey,
        agentProtocol: data.agentProtocol,
        providerProtocol: data.providerProtocol,
      };
      filterData.push(protocolsData);
    });

    if (filterData.length > 0) {
      this.loading = true;
      const options = {
        filename: 'Approved-protocol-list',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        headers: ['Service', 'Supported', 'Schedule', 'Agent Protocol', 'Provider Protocol']
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(filterData);
      this.loading = false;
      this.toast.success('CSV generated Successfully!', 'Success');
    } else {
      this.toast.warning('No data available.', 'Error');
    }
  }
  
  search(event) {
    this.searchString = event;
  }

  onSort({ column, direction }: SortEvent) {
    this.arrProtocolDetails = this.utilityservice.sortData(this.headers, column, direction, this.arrProtocolDetails);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
