import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BubbleService } from 'src/app/services/bubble.service';
import { RatesService } from 'src/app/services/rates.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from '../../services/utility.service';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-view-service-exclusion-list-dialog',
  templateUrl: './view-service-exclusion-list-dialog.component.html',
  styleUrls: ['./view-service-exclusion-list-dialog.component.scss']
})
export class ViewServiceExclusionListDialogComponent implements OnInit {
  isEdit: boolean;
  discountServiceExceptions = [];

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  constructor(
    public dialog: MatDialogRef<ViewServiceExclusionListDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    public bubbleService: BubbleService,
    public ratesService: RatesService,
    @Inject(MAT_DIALOG_DATA) public Data: any,
    private toast: NbToastrService,
    private utilityService: UtilityService,
    private dialogService: NbDialogService,
    private commonService: CommonService
  ) { }

  serviceExclusionDetailForm: UntypedFormGroup;
  loading: boolean = false;
  serviceDetails: any;
  subServiceDetails: any;
  licensedOwnerOffering: any;


  ngOnInit() {
    this.serviceExclusionDetailForm = this.formBuilder.group({
      service: [''],
      subService: [''],
    });
    this.isEdit = this.Data;
    this.getServiceExceptions();
    this.getServices();
  }

  getServices() {
    const data = {
      parentKey: null,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      deleted: false
    };

    this.loading = true;
    this.commonService.getLicensedOwnerServiceOfferingsDetails(data).subscribe((result: any) => {
      this.serviceDetails = result.body;
      this.loading = false;
    }, (error) => {
      console.log('error ' + error);
      this.loading = false;
    });
  }

  getSubServices($event) {
    this.loading = true;
    const filter = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      parentKey: this.serviceExclusionDetailForm.controls.service.value,
      deleted: false
    };

    this.commonService.getLicensedOwnerServiceOfferingsDetails(filter).subscribe(subServiceResponse => {
      this.subServiceDetails = subServiceResponse.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

  }

  getServiceExceptions() {
    this.loading = true;
    this.bubbleService.getServiceExceptions(sessionStorage.getItem('licensedOwnerKey')).subscribe(res => {
      this.discountServiceExceptions = res.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  updateService(providerApplies, subServiceId) {
    let data: any;
    if (providerApplies === 'deleteProviderDiscountApplies') {
      this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
        if (isDelete) {
          data = {
            licensedOwnerOfferingKey: subServiceId,
            providerDiscountApplies: true
          };
          this.updateServiceExceptions(data, providerApplies);
        }
      });
    } else {
      const subServiceKey = this.serviceExclusionDetailForm.controls.subService.value;
      if (subServiceKey === '' || subServiceKey === null) {
        this.toast.warning('Select Service and Subservice', 'Warning');
        return false;
      }
      data = {
        licensedOwnerOfferingKey: subServiceKey,
        providerDiscountApplies: false
      };
      this.updateServiceExceptions(data, providerApplies);
    }
  }

  updateServiceExceptions(data, providerApplies) {
    this.loading = true;
    this.ratesService.updateServiceData(data).subscribe((res: any) => {
      if (res.success) {
        if (providerApplies === 'deleteProviderDiscountApplies') {
          this.toast.success('Removed From List', 'Success');
        } else {
          this.toast.success('Added To List', 'Success');
        }
        this.serviceExclusionDetailForm.controls.subService.reset();
        this.serviceExclusionDetailForm.controls.service.reset();
        this.getServiceExceptions();
        this.subServiceDetails = [];
        this.loading = false;
      }
    }, (error) => {
      console.log('error,', error);
      this.loading = false;
    });
  }

  closeDialog() {
    this.dialog.close();
  }

  onSort({ column, direction }: SortEvent) {
    this.discountServiceExceptions = this.utilityService.sortData(this.headers, column, direction, this.discountServiceExceptions);
  }
}
