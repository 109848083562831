import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from './utility.service';
import { globalVariables } from 'src/assets/global_variables';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService) { }

  // API call to get customer details.
  getCustomerDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customers/get`, data);
  }

  // API call to update customer details.
  updateCustomerDetails(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customers`, data);
  }

  saveCustomer(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customers`, data);
  }

  // API call to get customer list
  getCustomerList(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}getCustomerDetails`, data);
  }

  // API call to get white label details
  getWhiteLabelDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/whitelabeldetails/get`, data);
  }

  // API call to update white label details
  updateWhiteLabelDetails(updateDetails): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/whitelabeldetails`, updateDetails);
  }

  // API call to update white label details
  addWhiteLabelDetails(addDetails): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/whitelabeldetails`, addDetails);
  }

  // API call to get licensed owner module subscriptions
  getLicensedOwnerModuleSubscriptions(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownermodulesubscriptions/get`, data);
  }

  addMultipleCustomers(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customers/batch`, data);
  }

  updateMultipleCustomers(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customers/batch`, data);
  }

  updateProfilePicture(updatedData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}uploadFile`, updatedData);
  }

  deleteProfilePicture(fileName: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}deleteFile`, fileName);
  }

  getProfileDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/customers/get`, data);
  }

  getCustomer(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customers/get`, data);
  }
  // get timezone
  getUsersTimezone(geocode) {
    return this.http.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${geocode.lat},
    ${geocode.lng}&timestamp=1458000000&key=${globalVariables.googleMapApiKey}`);
  }

  getCustomersWithUltimateParent(filter): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getCustomersWithUltimateParent`, filter);
  }

  getCustomerHierarchy(filter): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getCustomerHierarchy`, filter);
  }

  getTermsAndCondition(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getTermsAndConditions`, data);
  }

  addTermsAndCondition(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customertermsandconditions`, data);
  }

  updateTermsAndCondition(data: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customertermsandconditions`, data);
  }

  // get parent customer list
  getParentCustomers(filter): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getParentCustomers`, filter);
  }
}
