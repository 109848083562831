import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { MatDialogRef } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { UtilityService } from 'src/app/services/utility.service';
import { ProviderService } from 'src/app/services/provider.service';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { patterns } from 'src/assets/patterns';
import { environment } from 'src/environments/environment';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-edit-provider-users',
  templateUrl: './edit-provider-users.component.html',
  styleUrls: ['./edit-provider-users.component.scss']
})
export class EditProviderUsersComponent implements OnInit {

  userDetails: any = {
    firstName: '',
    officePhone: '',
    cellPhone: '',
    email: '',
    supervisor: '',
    city: '',
    userStatus: '',
  };

  profileDetailsForm: UntypedFormGroup;
  buttonText = 'Add Employee';
  loading: boolean;
  userKey: String;
  profileDetails: any;
  userTypeKey: any;
  disableFlag = false;
  providerKey: string;
  defaultLocationKey: string;
  saveButtonText = 'Add Employee';
  userStatusDetails: any;
  supervisorDetails: any;
  isUserProvider = false;
  isEdit = true;
  securityGroup: string;
  isEmailAvailable = false;
  selectedIndex = 0;
  fileData: any;
  imagePath: any;
  imgURL: string | ArrayBuffer = '../../../../assets/images/placeholder.png';
  isDisableMyProfile = false;
  isProvider = false;

  @Input() data;
  @Output() saveCompleted = new EventEmitter<any>();

  constructor(
    private usersService: UsersService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: NbToastrService,
    private utilityService: UtilityService,
    private providerService: ProviderService,
    private commonService: CommonService,
    private http: HttpClient,
    public dialog: MatDialogRef<EditProviderUsersComponent>,
    private imageCompress: NgxImageCompressService,
  ) { }

  ngOnInit() {
    this.buttonText = this.data.value;
    if (this.data.userKey === undefined || this.data.userKey === null) {
      this.userKey = sessionStorage.getItem('userKey');
      this.isDisableMyProfile = true;
    } else {
      this.userKey = this.data.userKey;
    }

    this.providerKey = sessionStorage.getItem('providerKey');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');

    if (this.data.locationKey && this.data.locationKey !== 'null') {
      this.providerKey = this.data.locationKey;
    } else if (this.defaultLocationKey && this.defaultLocationKey !== 'null') {
      this.providerKey = this.defaultLocationKey;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
      this.isProvider = true;
    }
    if (this.isProvider) {
      const img = sessionStorage.getItem('loginUserProfile');

      if (img && img !== 'null') {
        this.imgURL = atob(sessionStorage.getItem('loginUserProfile'));
      }
    }

    this.isUserProvider = false;
    this.profileDetailsForm = this.formBuilder.group({
      firstName: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.textPattern)]],
      lastName: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.textPattern)]],
      email: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.emailPattern)]],
      officePhone: ['', Validators.pattern(patterns.mobnumPattern)],
      cellPhone: ['', Validators.pattern(patterns.mobnumPattern)],
      supervisor: [''],
      password: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.passwordPattern)]],
      confirmPassword: ['', [Validators.required]],
      providerKey: [''],
      jobTitle: [''],
      userStatus: [{ value: 'Active', disabled: this.disableFlag }],
      okToTextMsg: [true],
      // profilePictureURL: [''],
    });

    if (this.buttonText === 'Edit Employee') {
      this.isEdit = false;
      this.saveButtonText = 'Save';
      this.getEmployeeDetails();
    } else {
      this.isEdit = true;
      this.saveButtonText = 'Add Employee';
    }

    this.getUserStatus();
    this.getUserSupervisors();
  }

  // get employee list
  getEmployeeDetails() {

    this.loading = true;
    const data = { userKey: this.userKey, deleted: false };
    this.usersService.getUsersDetails(data).subscribe(result => {
      this.profileDetails = result.body[0];
      this.imgURL = result.body[0].profilePictureURL ? atob(result.body[0].profilePictureURL) : this.imgURL;
      this.userDetails = result.body[0];
      this.userTypeKey = this.profileDetails.userTypeKey;
      this.isUserProvider = (this.userTypeKey === 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4') ? true : false;

      this.profileDetailsForm = this.formBuilder.group({
        firstName: this.profileDetails.firstName,
        lastName: this.profileDetails.lastName,
        email: this.profileDetails.email,
        officePhone: '',
        cellPhone: '',
        supervisor: this.profileDetails.supervisor,
        password: this.profileDetails.password,
        confirmPassword: this.profileDetails.password,
        providerKey: this.providerKey,
        jobTitle: this.profileDetails.title,
        userStatus: this.profileDetails.userStatusKey,
        okToTextMsg: this.profileDetails.okToTextMsg,
      });

      this.loading = false;

      if (this.data.UserSecurityGroup === 'Provider Billing') {
        this.profileDetailsForm.get('userStatus').setValue('Active');
        this.profileDetailsForm.get('userStatus').disable();
      }

      setTimeout(() => {
        this.profileDetailsForm.controls.officePhone.setValue(this.profileDetails.officePhone);
        this.profileDetailsForm.controls.cellPhone.setValue(this.profileDetails.cellPhone);
      }, 100);
    }, (error) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  // add employe details
  addEditEmployeeDetails() {
    if (this.profileDetailsForm.invalid || this.atleasetOneRequired()) {
      this.profileDetailsForm.markAllAsTouched();
      return false;
    }

    this.loading = true;
    const userData = {
      firstName: this.profileDetailsForm.controls.firstName.value,
      lastName: this.profileDetailsForm.controls.lastName.value,
      email: this.profileDetailsForm.controls.email.value,
      officePhone: this.profileDetailsForm.controls.officePhone.value,
      cellPhone: this.profileDetailsForm.controls.cellPhone.value,
      supervisor: this.profileDetailsForm.controls.supervisor.value,
      password: this.profileDetailsForm.controls.password.value,
      title: this.profileDetailsForm.controls.jobTitle.value,
      userStatusKey: this.profileDetailsForm.controls.userStatus.value,
      okToTextMsg: this.profileDetailsForm.controls.okToTextMsg.value,
    };

    if (this.buttonText === 'Edit Employee') {
      const data = Object.assign(userData, {
        updatedUserKey: sessionStorage.getItem('userKey'),
        userKey: this.userKey, deleted: false,
      });

      this.usersService.updateUser(data).subscribe((result: any) => {
        this.loading = false;
        if (this.userKey === sessionStorage.getItem('userKey')) {
          this.utilityService.setUserName(this.profileDetailsForm.controls.firstName.value.concat(' ',
            this.profileDetailsForm.controls.lastName.value));
        }
        this.toastrService.success('User details updated successfully!.', 'Success');
        const objData = {
          result: true,
          userKey: this.userKey,
        };
        this.saveCompleted.emit(objData);
      }, (error) => {
        this.loading = false;
        console.log(error);
        this.toastrService.danger('Oops! Something went wrong.', 'Danger');
      });
    } else {
      const insertData = {
        insertedUserKey: sessionStorage.getItem('userKey'),
        associationKey: this.providerKey,
        address: '',
        city: '',
        contactPriority: '',
        deleted: false,
        department: '',
        stateKey: '',
        updatedUserKey: '',
        userKey: '',
        userTypeKey: 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4', // Provider
      };

      // code for: check existing email address
      this.loading = true;
      const objParams = { emailAddress: userData.email };
      const licenseOwnerInviteReq = this.providerService.getProviderInviteDetails(objParams);
      const usersReq = this.usersService.getUsersDetails({ email: userData.email, deleted: false });

      // used forkjoin for check multiple tables
      forkJoin([licenseOwnerInviteReq, usersReq]).subscribe((result: any) => {

        // if following condition is true then the new employee will be added
        // otherwise will return error as < Email id is already exist >.
        if (result[0].body.length === 0 && result[1].body.length === 0) {
          this.isEmailAvailable = false;
          const data = Object.assign(userData, insertData);
          this.usersService.addUser(data).subscribe((res: any) => {
            this.addUserSecurityGroup(res.body[0].userKey, this.securityGroup);
            this.loading = false;
            this.toastrService.success('User details added successfully!.', 'Success');
            const objData = {
              result: true,
              userKey: res.body[0].userKey,
            };
            this.saveCompleted.emit(objData);
          }, (error) => {
            console.log('error', error);
            this.loading = false;
            this.toastrService.danger('Oops! Something went wrong.', 'Danger');
          });
        } else {
          // this.isEmailAvailable = true;
          this.loading = false;
          return false;
        }
      }, (error) => {
        this.loading = false;
        this.toastrService.danger('Oops! Something went wrong.', 'Danger');
      });
    }
  }

  preview(files: any, event: any) {
    if (files.length === 0) {
      return false;
    }
    const fileReader = new FileReader();
    fileReader.onload = (res: any) => {
      this.imageCompress.compressFile(res.target.result, -1, 50, 50).then(result => {
        this.utilityService.urltoFile(result, event.target.files[0].name, files[0].type).then((file) => {
          this.fileData = file;
          const formData = new FormData();
          formData.append('file', this.fileData);
          formData.append('fileType', 'image');

          const mimeType = files[0].type;
          if (mimeType.match(/image\/*/) == null) {
            this.toastrService.danger('Only image formats are supported.');
            return false;
          }
          const size = files[0].size;
          if (Math.round(size / 1024) > 5120) {
            this.toastrService.danger('Image size should not exceed 5MB.');
            return false;
          }

          const reader = new FileReader();
          this.imagePath = files;

          reader.readAsDataURL(files[0]);
          reader.onload = () => {
            this.commonService.updateProfilePicture(formData).subscribe(resFileDetail => {
              if (resFileDetail.status) {
                this.toastrService.success('Profile Uploaded.!', 'Success');
                this.loading = false;
              } else {
                this.toastrService.danger('Failed to upload profile.!', 'Error');
              }
              // this.profileDetailsForm.controls.profilePictureURL.setValue(resFileDetail['url']);
              const data = {
                userKey: this.userKey,
                profilePictureURL: resFileDetail.url,
              };

              this.imgURL = resFileDetail.url;

              this.loading = true;
              this.http.put<any>(`${environment.apiUrl}systemsecurities/users`, data).subscribe((response: any) => {
                if (this.userKey === sessionStorage.getItem('userKey')) {
                  sessionStorage.setItem('loginUserProfile', btoa(resFileDetail.url));
                  this.utilityService.setProfilePicture(resFileDetail.url);
                }
                this.loading = false;
              }, (error) => {
                this.loading = false;
                this.toastrService.danger(error.message, 'Error');
              });
            }, (error) => {
              this.loading = false;
              this.toastrService.danger('Failed to update profile picture.!', 'Error');
            });
          };
        }, error => {
          this.toastrService.danger('Failed to update profile picture.!', 'Error');
          console.log('Failed to update profile picture.!', error);
        });
      });
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  // get user status
  getUserStatus() {
    const data = {
      deleted: false,
    };
    this.commonService.getUserStatuses(data).subscribe(response => {
      this.userStatusDetails = response.body;
      if (this.data.UserSecurityGroup === 'Provider Billing') {
        this.profileDetailsForm.get('userStatus').setValue('Active');
        this.profileDetailsForm.get('userStatus').disable();
      }
      if (this.buttonText === 'Add Employee') {
        this.profileDetailsForm.get('userStatus').setValue('Active');
      }
    }, (error) => {
      console.log(error);
    });
  }

  // get user supervisors
  getUserSupervisors() {
    const supervisorFilter = {
      deleted: false,
      associationKey: this.providerKey,
    };

    this.usersService.getUserDetailsByAssociationKey(supervisorFilter).subscribe(response => {
      this.supervisorDetails = response.body;
    }, (error) => {
      console.log(error);
    });
  }

  addUserSecurityGroup(userKey, securityGroupKey) {
    const data = {
      deleted: false,
      systemDefault: true,
      securityGroupKey,
      userKey,
    };
    this.loading = true;
    this.usersService.addUserSecurityGroup(data).subscribe((res: any) => {
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  atleasetOneRequired() {
    const form = this.profileDetailsForm.value;
    if (form.officePhone || form.cellPhone) {
      return false;
    } else {
      return true;
    }
  }
}
