import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators, ReactiveFormsModule, UntypedFormGroup, FormsModule } from '@angular/forms';
import { GooglePlaceDirective, GooglePlaceModule } from 'ngx-google-places-autocomplete-esb';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { NbCardModule, NbMenuModule } from '@nebular/theme';
import { patterns } from 'src/assets/patterns';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { ProviderService } from 'src/app/services/provider.service';
import { SharedModule } from 'src/app/shared/shared.module';
//import { CapitalizeFirstLetterPipe } from 'src/app/shared/pipes/capitalizeFirtstLetterInWords';
import { pipe } from 'rxjs';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'ngx-edit-sublocation-data',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    GooglePlaceModule,
    MatInputModule,
    NbCardModule,
    NbMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatButtonModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskDirective, 
    NgxMaskPipe,

  ],
  templateUrl: './edit-sublocation-data.component.html',
  styleUrls: ['./edit-sublocation-data.component.scss']
})
export class EditSublocationDataComponent {

  constructor(
    private providerAdminService: ProviderAdminService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private commonService: CommonService,
    // private utilityService: UtilityService,
    private _snackBar: MatSnackBar,
    // private datePipe: DatePipe,
    // private locationService: LocationService,
    public dialogRef: MatDialogRef<EditSublocationDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    //public capitalizeFirstLetter: CapitalizeFirstLetterPipe,
    private providerService: ProviderService,

  ) { }

  subLocationForm: UntypedFormGroup;
  subLocation: any = [];
  statesData: any;
  searchAddress: any;
  formattedAddress: string;
  latitude = 33.013438;
  longitude = -96.772337;
  street_number: any;
  route: any;
  subpremise: any;

  serviceAndSubService: any = [];
  serviceCategory: any = [];

  serviceTowing: any = [];
  serviceMobileRepair: any = [];
  serviceTires: any = [];
  serviceCargo: any = [];
  serviceMajorShopRepair: any = [];
  serviceAccident: any = [];
  serviceScheduledMaintenance: any = [];
  serviceNoStart: any = [];
  serviceCallCenterService: any = [];
  serviceDriverAccommodation: any = [];
  serviceDriveAway: any = [];

  providerAccountstatuses: any = [];
  @Input() providerDetails: any;
  providerAccountDetails: any = [];

  servicesToAdd: any = [];
  servicesToRemove: any = [];


  ngOnInit(){
    //console.log(this.data)
    this.subLocation = this.data.sublocation;
    console.log(this.subLocation)

    this.subLocationForm = this.formBuilder.group({
      //locationName: ['', Validators.required],
      physicalAddress1: ['', Validators.required],
      physicalAddress2: [''],
      physicalCity: ['', Validators.required],
      physicalStateKey: ['', Validators.required],
      physicalPostalCode: ['', Validators.required],
      physicalAddress3: [''], //Validators.required
      physicalCountry: [''], //Validators.required
      physicalLatitude: ['', Validators.required],
      physicalLongitude: ['', Validators.required],
      businessHoursPhone: ['', [Validators.required, Validators.pattern(patterns.phonePattern2)]],
      afterHoursPhone: ['', [Validators.required, Validators.pattern(patterns.phonePattern2)]],
      dispatchEmail: ['', [Validators.required, Validators.pattern(patterns.emailPattern)]],
      billingEmail: ['', [Validators.pattern(patterns.emailPattern)]],
      billingPhone: ['', [Validators.pattern(patterns.phonePattern2)]],
      primaryBillingContact: [''],
      dispatchStatusKey: [''],
      providerKey: [''],
    })

    this.getSublocationDetails();
    this.getStates();
    this.getProviderServices();
//this.getStatus();
  }

  getSublocationDetails(){

    this.subLocationForm.patchValue({
      //locationName: this.subLocation.name,
      physicalAddress1: this.subLocation.physicalAddress1,
      physicalAddress2: this.subLocation.physicalAddress2,
      physicalCity: this.subLocation.physicalCity,
      physicalStateKey: this.subLocation.physicalStateKey,
      physicalPostalCode: this.subLocation.physicalPostalCode,
      physicalAddress3 : this.subLocation.physicalAddress3 || '',
      physicalCountry: this.subLocation.physicalCountry,
      physicalLatitude: this.subLocation.physicalLatitude,
      physicalLongitude: this.subLocation.physicalLongitude,
      businessHoursPhone: this.subLocation.businessHoursPhone,
      afterHoursPhone: this.subLocation.afterHoursPhone,
      dispatchEmail: this.subLocation.dispatchEmail,
      primaryBillingContact: this.subLocation.primaryBillingContact,
      billingPhone: this.subLocation.billingPhone,
      billingEmail: this.subLocation.billingEmail,
      dispatchStatusKey: this.subLocation.dispatchStatusKey, 
      providerKey: this.data.sublocation.providerKey,

    })
    this.subLocationForm.value
  }

  getStates(){
    if (sessionStorage.getItem('states') == null) {
      this.commonService.getstates().subscribe(result => {
        this.statesData = result.body;
        sessionStorage.setItem('states', JSON.stringify(result));
      });
    } else {
      this.statesData = JSON.parse(sessionStorage.getItem('states')).body;
    }   
  }

  // getStatus(){
  //   // const filter = {
  //   //   providerAccountKey: this.providerDetails.providerAccountKey,
  //   // };

  //   // this.providerService.getProviderAccounts(filter).subscribe({
  //   //   next: res => this.providerAccountDetails = res,
  //   //   error: (e) => console.log(e),
  //   //   complete: () => console.log(this.providerAccountDetails),
  //   // })

  //   const statusReq = this.commonService.getProviderAccountStatuses({ deleted: false }).subscribe(res =>
  //     this.providerAccountstatuses = res.body,
  //   )
  // }

  statusChanged(){
    console.log('changed status')
  }

  addressChanged(address: Address){
    console.log(address)

    this.searchAddress = address.address_components;

    //this.subLocationForm.controls.physicalAddress1.setValue(address.formatted_address);
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();

        this.searchAddress.forEach(element => {
      element.types.forEach(data => {

        switch(data) { 
          case 'street_number': { 
            this.street_number = element.long_name;
             break; 
          } 
          case 'route': { 
            this.route = element.long_name;
             break; 
          } 
            default: { 
              //statements; 
              break; 
            } }

        if (data === 'postal_code') {
          this.subLocationForm.controls.physicalPostalCode.setValue(element.long_name);
        } 
        else if (data === 'country') {
          this.subLocationForm.controls.physicalCountry.setValue(element.long_name);
        } 
        else if (data === 'administrative_area_level_1') {
          this.subLocationForm.controls.physicalStateKey.setValue(element.short_name);
        } 
        else if (data === 'locality') {
          this.subLocationForm.controls.physicalCity.setValue(element.long_name);
        } 
        else if (data === 'subpremise') {
          this.subLocationForm.controls.physicalAddress2.setValue(element.long_name)
        }
        else if (data === 'administrative_area_level_2'){
          this.subLocationForm.controls.physicalAddress3.setValue(element.long_name)
        }
      });

      this.subLocationForm.controls.physicalAddress1.setValue(this.street_number + ' ' + this.route);
        this.subLocationForm.controls.physicalLatitude.setValue(this.latitude);
        this.subLocationForm.controls.physicalLongitude.setValue(this.longitude)
    });

    console.log(this.subLocationForm.value)

  }

  onClickBusinessHoursPhone() {

    if(this.subLocationForm.controls.businessHoursPhone.value == null){
      console.log('null number')
      this.subLocationForm.controls.businessHoursPhone.setValue(`+1`);
    }
    else{
      const inputValue = this.subLocationForm.controls.businessHoursPhone.value;
      if (!inputValue.includes('+1'))
        this.subLocationForm.controls.businessHoursPhone.setValue(`+1${this.subLocationForm.controls.businessHoursPhone.value}`);
    }
  }

  onChangeBusinessHoursPhone() {
    const inputValue = this.subLocationForm.controls.businessHoursPhone.value;
    if (inputValue) this.onClickBusinessHoursPhone();
  }

  onFocusOutBusinessHoursPhone() {
    let inputValue = this.subLocationForm.controls.businessHoursPhone.value.replace('+1', '');
    if (inputValue === '') this.subLocationForm.controls.businessHoursPhone.setValue('');
  }

  onClickAfterHoursPhone() {
    if(this.subLocationForm.controls.afterHoursPhone.value == null){
      console.log('null number')
      this.subLocationForm.controls.afterHoursPhone.setValue(`+1`);
    }
    else{
      const inputValue = this.subLocationForm.controls.afterHoursPhone.value;
      if (!inputValue.includes('+1') )
        this.subLocationForm.controls.afterHoursPhone.setValue(`+1${this.subLocationForm.controls.afterHoursPhone.value}`);
    }
  }

  onChangeAfterHoursPhone() {
    const inputValue = this.subLocationForm.controls.afterHoursPhone.value;
    if (inputValue) this.onClickAfterHoursPhone();
  }

  onFocusOutAfterHoursPhone() {
    let inputValue = this.subLocationForm.controls.afterHoursPhone.value.replace('+1', '');
    if (inputValue === '') this.subLocationForm.controls.afterHoursPhone.setValue('');
  }

  onClickBillingPhone() {

    if(this.subLocationForm.controls.billingPhone.value == null){
      console.log('null number')
      this.subLocationForm.controls.billingPhone.setValue(`+1`);
    }
    else{
      const inputValue = this.subLocationForm.controls.billingPhone.value;
      if (!inputValue.includes('+1'))
      this.subLocationForm.controls.billingPhone.setValue(`+1${this.subLocationForm.controls.billingPhone.value}`);
    }
  }

  onChangeBillingPhone() {
    const inputValue = this.subLocationForm.controls.billingPhone.value;
    if (inputValue) this.onClickBillingPhone();
  }

  onFocusOutBillingPhone() {
    let inputValue = this.subLocationForm.controls.billingPhone.value.replace('+1', '');
    if (inputValue === '') this.subLocationForm.controls.billingPhone.setValue('');
  }

  getProviderServices(){
    this.providerAdminService.getProviderServices(this.data.sublocation.providerKey).subscribe({
      next: res => this.serviceAndSubService = res,
      error: (e) => console.log(e),
      complete: () => {
        //this.getServiceAndSubService(),
        
    //console.log(this.serviceAndSubService);

    this.serviceCategory = [...new Set(this.serviceAndSubService.map(item => item.ServiceName))]
    //console.log(this.serviceCategory)

    this.serviceTowing = this.serviceAndSubService.filter( obj => obj.ServiceName === 'TOWING');
    //console.log(this.serviceTowing)
    this.serviceAccident = this.serviceAndSubService.filter( obj => obj.ServiceName === 'ACCIDENT');
    this.serviceCallCenterService = this.serviceAndSubService.filter( obj => obj.ServiceName === 'CALL CENTER SERVICE');
    this.serviceCargo = this.serviceAndSubService.filter( obj => obj.ServiceName === 'CARGO');
    this.serviceDriverAccommodation = this.serviceAndSubService.filter( obj => obj.ServiceName === 'DRIVER ACCOMMODATION');
    this.serviceDriveAway = this.serviceAndSubService.filter( obj => obj.ServiceName === 'DRIVEAWAY');
    this.serviceMajorShopRepair = this.serviceAndSubService.filter( obj => obj.ServiceName === 'MAJOR SHOP REPAIR');
    this.serviceMobileRepair = this.serviceAndSubService.filter( obj => obj.ServiceName === 'MOBILE REPAIR');
    this.serviceNoStart = this.serviceAndSubService.filter( obj => obj.ServiceName === 'NO START');
    this.serviceScheduledMaintenance = this.serviceAndSubService.filter( obj => obj.ServiceName === 'SCHEDULED MAINTENANCE');
    this.serviceTires = this.serviceAndSubService.filter( obj => obj.ServiceName === 'TIRES');

      },
    })
  }

  getServiceAndSubService(){

    this.serviceCategory = [...new Set(this.serviceAndSubService.map(item => item.ServiceName))]
    //console.log(this.serviceCategory)

    this.serviceTowing = this.serviceAndSubService.filter( obj => obj.ServiceName === 'TOWING');
    //console.log(this.serviceTowing)
    this.serviceAccident = this.serviceAndSubService.filter( obj => obj.ServiceName === 'ACCIDENT');
    this.serviceCallCenterService = this.serviceAndSubService.filter( obj => obj.ServiceName === 'CALL CENTER SERVICE');
    this.serviceCargo = this.serviceAndSubService.filter( obj => obj.ServiceName === 'CARGO');
    this.serviceDriverAccommodation = this.serviceAndSubService.filter( obj => obj.ServiceName === 'DRIVER ACCOMMODATION');
    this.serviceDriveAway = this.serviceAndSubService.filter( obj => obj.ServiceName === 'DRIVEAWAY');
    this.serviceMajorShopRepair = this.serviceAndSubService.filter( obj => obj.ServiceName === 'MAJOR SHOP REPAIR');
    this.serviceMobileRepair = this.serviceAndSubService.filter( obj => obj.ServiceName === 'MOBILE REPAIR');
    this.serviceNoStart = this.serviceAndSubService.filter( obj => obj.ServiceName === 'NO START');
    this.serviceScheduledMaintenance = this.serviceAndSubService.filter( obj => obj.ServiceName === 'SCHEDULED MAINTENANCE');
    this.serviceTires = this.serviceAndSubService.filter( obj => obj.ServiceName === 'TIRES');

  }

  confirmEditLocation(){
    if(this.subLocationForm.invalid){
      this.openSnackBar('Please fill out all required fields with valid input.');
      return;}

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {header: 'Edit Sublocation Info', message: 'Are you sure you want to add these changes to this sublocation?',
        yesButton: 'Submit Changes'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result == true){
        this.updateLocationData1();
      }
      else{return};
    });
  }

  updateLocationData1(){
    this.providerService.updateProviderDetails(this.subLocationForm.value).subscribe({
      next: res => console.log(res),
      error: (e) => console.log(e),
      complete: () => {
        this.openSnackBar('Provider successfully updated.');
        this.updateLocationData2()},
    });
  }

  updateLocationData2() {
    for(let i = 0; i < this.servicesToAdd.length; i++){
      const data = {
        inProviderKey: this.data.sublocation.providerKey,
        inNetworkOwnerOfferingKey: this.servicesToAdd[i].inNetworkOwnerOfferingKey,
        inUserKey: sessionStorage.getItem('userKey'),
      }
      this.providerAdminService.insertProviderServices(data).subscribe({
        next: res => console.log(res),
        error: (e) => console.log(e),
        complete: () => {console.log('Sublocation successfully updated.')}
    })
    }

    for(let i = 0; i < this.servicesToRemove.length; i++){
      const data = {
        ProviderServiceOfferingKey: this.servicesToRemove[i].inProviderServiceOfferingKey 
      }
      this.providerAdminService.deleteProviderServiceOfferings(data).subscribe({
        next: res => console.log(res),
        error: (e) => console.log(e),
        complete: () => {//this.dialogRef.close(true)

        }
    })
    }
    //this.dialogRef.close(true)
  }

  serviceCheckBoxChange( service, caterory, event){
    if(event.checked == true && service.HasSubservice == 0){
      this.servicesToAdd.push({
        inProviderKey: this.data.sublocation.providerKey,
        inNetworkOwnerOfferingKey: service.SubserviceKey,
        inUserKey: sessionStorage.getItem('userKey'),
      })
    }
    else if(event.checked == true && service.HasSubservice == 1){
      this.servicesToRemove = this.servicesToRemove.filter(obj => obj.inProviderServiceOfferingKey  != service.ProviderServiceOfferingKey)
    }
    else if(event.checked == false && service.HasSubservice == 1){
      this.servicesToRemove.push({
        inProviderServiceOfferingKey: service.ProviderServiceOfferingKey 
      })
    }
    else if(event.checked == false && service.HasSubservice == 0){
      this.servicesToAdd = this.servicesToAdd.filter(obj => obj.inNetworkOwnerOfferingKey != service.SubserviceKey)

    }
  }

  towCheckBoxChange(towingService, serviceTowing, event){
    // console.log(event);
    // console.log(towingService)
  }

  openSnackBar(message): void {
    this._snackBar.open(message, '', { duration: 3000 })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  
}
