<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="row">
    <div class="col-md-12 mt-2">
        <div class="row">
            <div class="col-12">
                <p class="font-weight-bold mb-1">Hours of Operations</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mt-2">
                <mat-form-field>
                    <input type="text" matInput [(ngModel)]="currentTimezone" placeholder="TimeZone" disabled>
                </mat-form-field>
            </div>
            <div class="col-md-8 mt-2 text-right">
                <button type="button" *ngIf="isParent" class="btn btn-orange btn-sm mr-2 ml-2"
                    (click)="setHoursOfOperation()">
                    24 Hours Available
                </button>
                <button type="button" *ngIf="!isParent" [disabled]="!tierCanSetHours"
                    class="btn btn-orange btn-sm mr-2 ml-2" (click)="setHoursOfOperation()">
                    24 Hours Available
                </button>
                <button type="button" class="btn btn-orange btn-sm mr-2" (click)="openHolidayDialog()">
                    Holiday Hours
                </button>
            </div>
        </div>
        <div class="table-responsive mt-2">
            <table class="table table-bordered" [ngClass]="{'parent-data': parentData}">
                <thead>
                    <tr>
                        <th *ngFor="let day of Days">{{day}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td *ngFor="let data of weekData">
                            <div *ngIf="!(editDayFlag === data.dayOfWeek)">
                                <div>
                                    <div class="d-inline-block m-w-60" *ngIf="!data.closed">
                                        {{ data.openTime | differenceInHoursBetweenTwoTimes: data.closeTime}}
                                    </div>
                                    <div class="d-inline-block m-w-60" *ngIf="data.closed">
                                        Closed
                                    </div>
                                    <button *ngIf="isParent"
                                        class="btn btn-default btn-sm btnAnimation float-right d-inline-block"
                                        nbTooltip="Edit" (click)="editDay(data)">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                    <button *ngIf="tierCanSetHours && !isParent"
                                        class="btn btn-default btn-sm btnAnimation float-right d-inline-block"
                                        nbTooltip="Edit" (click)="editDay(data)">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                </div>
                            </div>

                            <div *ngIf="editDayFlag === data.dayOfWeek">
                                <mat-form-field>
                                    <input matInput placeholder="From" [(ngModel)]="openTime"
                                        [ngxMatDatetimePicker]="MFromPicker" [value]="data.openTime">
                                    <ngx-mat-timepicker #MFromPicker>
                                        [(ngModel)]="openTime" [disabled]="disabled" 
                                        [showSpinners]="showSpinners"
                                        [stepHour]="stepHour" [stepMinute]="stepMinute" 
                                        [stepSecond]="stepSecond" 
                                        [showSeconds]="showSeconds">
                                    </ngx-mat-timepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <input matInput placeholder="To" [(ngModel)]="closeTime" [ngxMatDatetimePicker]="MToPicker"
                                        [value]="data.closeTime">
                                    <ngx-mat-timepicker #MToPicker>
                                        [(ngModel)]="closeTime" [disabled]="disabled" 
                                        [showSpinners]="showSpinners"
                                        [stepHour]="stepHour" [stepMinute]="stepMinute" 
                                        [stepSecond]="stepSecond" 
                                        [showSeconds]="showSeconds">
                                    </ngx-mat-timepicker>
                                </mat-form-field>
                                <button class="btn btn-orange btn-sm ml-1" (click)="resetHoursOfOperation()">
                                    24 Hours
                                </button>
                                <button class="btn btn-orange btn-sm ml-1 " nbTooltip="CLose"
                                    (click)="updateHoursOfOperation(openTime, closeTime, providerHoursOfOperationKey, 1)">
                                    Close
                                </button>
                                <button class="btn btn-default btn-sm btnAnimation ml-2 mr-4 floatR"
                                    (click)="closeDayData(editDayFlag)" nbTooltip="Cancel">
                                    <i class="fal fa-times"></i>
                                </button>
                                <button class="btn btn-default btn-sm btnAnimation ml-1 floatR" nbTooltip="Save"
                                    (click)="updateHoursOfOperation(openTime, closeTime, providerHoursOfOperationKey, 0)">
                                    <i class="far fa-check"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <mat-checkbox *ngIf="isParent" [(ngModel)]="tierCanSetHours" (change)="setTiersCanSetHours($event)">
                    <label>Allow Locations to Change Hours</label></mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div class="text-right">
    <button *ngIf="showBack" matStepperPrevious mat-button class="btn btn-orange btn-sm p-2 mr-1">Back</button>
    <button *ngIf="showSave" mat-button class="btn btn-orange btn-sm p-2 ml-1 mr-1">Save</button>
    <button *ngIf="showSaveNext" matStepperNext mat-button class="btn btn-orange btn-sm p-2 ml-1">Save & Next</button>
</div>
