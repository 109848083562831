import { Deserializable } from "./deserializable.model";


export class DynamoProvider implements Deserializable {

    addressHashKey: number;
    addressId: string;
    _AxlePointColumn: string;
    _AxlePointKey: string;
    _AxlePointTable: string;
    address: string;
    addressGeoJson: string;
    geohash: number;
    name: string;
    providerContacts: [];
    providerId: string;
    providerKey: string;

    //! Getting from insert
    providerOfferings: string;
    providerTopLevelOfferings: [];
    providerTechnicianSkills: [];
    providerServiceCapabilities: [];
    providerNationalAccounts: [];
    physicalAddress1: string;
    physicalCity: string;
    physicalStateKey: string;
    mailingPostalCode: string;

    deserialize(input: any): this {
        this._AxlePointColumn = 'na';
        this._AxlePointKey = 'na';
        this._AxlePointTable = 'na';
        Object.assign(this, input);
        return this;
    }

    // ! Provider Contacts
    // {
    //     AfterHoursPhone: string;
    //     TollFreePhone: string;
    //     BusinessHoursPhone: string;
    //     DispatchEmail: string;
    // }

    // ! Address JSON
    // addressGeoJson: {
    //     type: string,
    //     coordinates: any
    // };

    // ! addressGeoJson: {
    //     type: string,
    //     coordinates: any
    // };

    prepareSave(data: any) {
        // this.deserialize(data);
        Object.assign(this, data);
        // this.addressGeoJson.type = 'Point';
        this._AxlePointColumn = 'na';
        this._AxlePointKey = 'na';
        this._AxlePointTable = 'na';
        this.addressHashKey = data.addressHashKey || '';
        this.addressId = data.addressId || '';
        this.address = data.address || '';
        this.geohash = data.geohash || 0;
        this.name = data.name || '';
        this.providerId = data.providerId || '';
        this.providerKey = data.providerKey || '';
        this.providerOfferings = data.providerOfferings || '';
        return this;
    }
}
