import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { FormSettingsDialogComponent } from '../form-settings-dialog/form-settings-dialog.component';
import { GlobalField, FieldsValue, FieldCategory } from '../model/global-field';

@Component({
  selector: 'app-create-dynamic-form',
  templateUrl: './create-dynamic-form.component.html',
  styleUrls: ['./create-dynamic-form.component.scss']
})
export class CreateDynamicFormComponent implements OnInit {

  arrFieldModels: any = [];
  reqHeader = new HttpHeaders({ "No-Auth": "True" });
  customForm = new UntypedFormGroup({});
  fieldSettingForm = new UntypedFormGroup({
    jsontext: new UntypedFormControl('')
  });
  editModal = {
    textType: '',
    textLabel: '',
    textPlaceholder: '',
    textValue: '',
    isRequired: false
  };
  report = false;
  reports = [];
  loading = false;
  currentGlobalField: GlobalField = new GlobalField();
  currentGlobalFieldJson = '';
  hover = false;
  value: FieldsValue = {
    id: '',
    label: '',
    value: ''
  };
  success = false;
  fieldModels: Array<GlobalField> = [
    {
      "id": "",
      "type": "text",
      "icon": "fa-terminal",
      "label": "Text",
      "description": "",
      "placeholder": "",
      "className": "form-control",
      "regex": "",
      "handle": true,
      "category": FieldCategory.Basic,
      "designclass": "btn-foursquare",
      "value": "",
      "required": false
    },
    {
      "type": "email",
      "icon": "fa-envelope",
      "required": false,
      "label": "Email",
      "description": "Enter your email",
      "placeholder": "Enter your email",
      "className": "form-control",
      "regex": "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\.([a-zA-Z]{2,5})$",
      "errorText": "Please enter a valid email",
      "handle": true,
      "category": FieldCategory.Basic,
      "designclass": "",
      "value": "",
    },
    {
      "type": "phone",
      "icon": "fa-phone",
      "label": "Phone",
      "description": "Enter your phone",
      "placeholder": "Enter your phone",
      "className": "form-control",
      "subtype": "text",
      "regex": "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
      "errorText": "Please enter a valid phone number",
      "handle": true,
      "category": FieldCategory.Basic,
      "designclass": "",
      "value": "",
      "required": false,
    },
    {
      "type": "select",
      "icon": "fa-list-alt",
      "label": "Select Box",
      "placeholder": "",
      "className": "form-control",
      "category": FieldCategory.Basic,
      "designclass": "btn-google",
      "value": "",
      "items": [],
      "required": false,
    },
    {
      "type": "checkbox",
      "label": "Checkbox",
      "icon": "fa-check-square",
      "description": "Checkbox",
      "inline": true,
      "listitems": [],
      "category": FieldCategory.Basic,
      "designclass": "btn-instagram",
      "required": false,
    },
    {
      "type": "radio",
      "icon": "fa-check-circle",
      "label": "Radio",
      "description": "Radio boxes",
      "listitems": [],
      "category": FieldCategory.Basic,
      "required": false,
    },
  ];
  modelFields: Array<GlobalField> = [];
  model: any = {
    name: 'App name...',
    description: 'App Description...',
    theme: {
      bgColor: 'ffffff',
      textColor: '555555',
      bannerImage: ''
    },
    fields: this.modelFields
  };
  title: string;

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    public dialogRef: MatDialogRef<CreateDynamicFormComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.title = this.data.title;
    this.arrFieldModels = (this.data.arrFieldModels) ? JSON.parse(this.data.arrFieldModels) : [];
  }

  onDragStart(event: DragEvent) {
    console.log('drag started', JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {
    console.log('drag ended', JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {
    console.log('draggable copied', JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {
    console.log('draggable linked', JSON.stringify(event, null, 2));
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragCanceled(event: DragEvent) {
    console.log('drag cancelled', JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {
    console.log('dragover', JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent, list?: any[]) {

    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {

      if (event.dropEffect === 'copy') {
        event.data.name = event.data.type + '-' + new Date().getTime();
        event.data.id = new Date().getTime();

        if (event.data.type === "tabcontainer") {
          let index = 1;
          const tabs: Array<GlobalField> = event.data.childs;
          tabs.forEach(function (tab) {
            index++;
            tab.id = new Date().getTime() + index;
          });
        }
      }
      let index = event.index;
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
    this.editModal.textType = event.data.type;
    this.editModal.textLabel = event.data.label;
    this.editModal.textPlaceholder = event.data.placeholder;
    this.editModal.textValue = event.data.value;
    this.editModal.isRequired = event.data.required;

    this.currentGlobalField = event.data;
    this.currentGlobalFieldJson = JSON.stringify(this.currentGlobalField, null, 4);
    this.fieldSettingForm.setValue({ jsontext: this.currentGlobalFieldJson });

    if (list && event.dropEffect === 'copy') {
      this.openModal(event.data, list, false);
    }
  }

  addValue(values: any) {
    values.push(this.value);
    this.value = { label: '', value: '' };
  }

  removeExistingFields(item: any, id: number) {

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const itemIndex = this.arrFieldModels.indexOf(item);
        if (itemIndex > -1) {
          this.arrFieldModels.splice(itemIndex, 1);
        }
      }
    });
  }

  // remove elements from DOM
  removeField(index: number, id: number) {

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const removeIndex = this.model.fields.indexOf(index);
        this.model.fields.splice(removeIndex, 1);
      }
    });
  }

  updateForm() {
    const input = new FormData;
    input.append('id', this.model._id);
    input.append('name', this.model.name);
    input.append('description', this.model.description);
    input.append('bannerImage', this.model.theme.bannerImage);
    input.append('bgColor', this.model.theme.bgColor);
    input.append('textColor', this.model.theme.textColor);
    input.append('attributes', JSON.stringify(this.model.attributes));
  }

  openModal(editModalDetails: any, fields: any = {}, isEdit: boolean = false) {

    const dialogRef = this.dialog.open(FormSettingsDialogComponent, {
      hasBackdrop: true,
      width: '95%',
      disableClose: true,
      position: {
        top: '5%',
      },
      data: { editModalDetails, isEdit }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let resEditModalDetails;
        if (Array.isArray(result.editModalDetails)) {
          resEditModalDetails = result.editModalDetails[0];
        } else {
          resEditModalDetails = result.editModalDetails;
        }

        this.modelFields.find(data => {
          if (data.id === resEditModalDetails.id) {
            data.label = result.editModal.textLabel;
            data.placeholder = result.editModal.textPlaceholder;
            data.value = result.editModal.textValue;
            data.required = result.editModal.isRequired;
            if (data.type === 'select') {
              data.items = result.arrValues;
            } else if (data.type === 'radio' || data.type === 'checkbox') {
              data.listitems = result.arrValues;
            }
          }
        });

        this.arrFieldModels.find(data => {
          if (data.id === resEditModalDetails.id) {
            data.label = result.editModal.textLabel;
            data.placeholder = result.editModal.textPlaceholder;
            data.value = result.editModal.textValue;
            data.required = result.editModal.isRequired;
            if (data.type === 'select') {
              data.items = result.arrValues;
            } else if (data.type === 'radio' || data.type === 'checkbox') {
              data.listitems = result.arrValues;
            }
          }
        });
      }
    });
  }

  arrayUpdate(list: Array<GlobalField>, field: GlobalField) {
    list.forEach((item) => {
      if (item.id === field.id) {
        Object.assign(item, field);
        return true;
      }
      if (item.childs != null && item.childs.length > 0) {
        this.arrayUpdate(item.childs, field);
      }
    });
  }

  toggleValue(item: any) {
    item.selected = !item.selected;
  }

  onSubmit() {
    const getJson = JSON.parse(this.currentGlobalFieldJson);
    getJson.type = (this.editModal.textType) ? this.editModal.textType : 'text';
    getJson.label = (this.editModal.textLabel) ? this.editModal.textLabel : getJson.label;
    getJson.placeholder = (this.editModal.textPlaceholder) ? this.editModal.textPlaceholder : getJson.placeholder;
    getJson.value = (this.editModal.textValue) ? this.editModal.textValue : '';
    getJson.required = (this.editModal.isRequired) ? this.editModal.isRequired : false;

    this.arrayUpdate(this.arrFieldModels, getJson);
    this.arrayUpdate(this.modelFields, getJson);
  }

  submitFormData(txt: any) {

    const tempArray = [...txt, ...this.arrFieldModels];
    if (tempArray.length === 0) {
      const msg = 'Please select atleast one form control.';
      this.toastrService.warning(msg, 'Warning');
      return false;
    }
    const postData = {
      jsonText: JSON.stringify(tempArray)
    };
    this.dialogRef.close(postData);
  }

  submit() {
    let valid = true;
    const validationArray = JSON.parse(JSON.stringify(this.model.attributes));
    validationArray.reverse().forEach(field => {
      if (field.required && !field.value && field.type !== 'checkbox') {
        valid = false;
        return false;
      }
      if (field.required && field.regex) {
        const regex = new RegExp(field.regex);
        if (regex.test(field.value) === false) {
          valid = false;
          return false;
        }
      }
      if (field.required && field.type === 'checkbox') {
        if (field.values.filter((r: any) => r.selected).length === 0) {
          valid = false;
          return false;
        }
      }
    });
    if (!valid) {
      return false;
    }
    const input = new FormData;
    input.append('formId', this.model._id);
    input.append('attributes', JSON.stringify(this.model.attributes));
  }

  openSettingsForm(item: any) {

    this.currentGlobalField = item;
    this.currentGlobalFieldJson = JSON.stringify(this.currentGlobalField, null, 4);

    this.editModal.textType = item.type;
    this.editModal.textLabel = item.label;
    this.editModal.textPlaceholder = item.placeholder;
    this.editModal.textValue = item.value;
    this.editModal.isRequired = item.required;

    this.openModal(item, [], true);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
