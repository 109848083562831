<nb-card>
    <nb-card-body>
        <div class="row">
            <div class="col-md-9">
                <h6>Services And Rate</h6>
            </div>
            <div class="col-md-3 form-group has-search  ">
                <span class="fa fa-search form-control-feedback"></span>
                <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search"
                    (input)="onInput($event)">
                <!-- <button class=" btn btn-orange btn-sm" (click)="addAccount(0)">Add Account</button> -->
            </div>
        </div>
        <div id="customersTab">
            <div class="table-responsive mt-2">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th sortable="accountName" (sort)="onSort($event)">
                                Account</th>
                            <th>Status</th>
                            <!-- <th>Payment Terms</th> -->
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let providerAccount of arrProviderAccount | filter:searchText">
                            <td> {{providerAccount.accountName}}
                                <button class="btn btn-dark cursor-default rounded-circle btn-sm floatR pb-2">
                                    <i class="fas fa-lock-alt"></i>
                                </button>
                            </td>
                            <td>{{providerAccount.accountStatus}}</td>
                            <!-- <td>COD</td> -->
                            <td>
                                <button class="btn btn-default btnAnimation" (click)="openRatesDialog(providerAccount)"
                                    nbTooltip="Edit">
                                    <i class="fas fa-pencil"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-2 text-right">
                <button type="button" class="btn btn-dark cursor-default rounded-circle btn-sm mr-1">
                    <i class="fas fa-lock-alt"></i>
                </button>
                <label>Rates Managed by Client</label>
            </div>
        </div>
    </nb-card-body>
</nb-card>