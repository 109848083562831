<div class="col-md-3 custom-height">
    <div class="btn-group btn-group-sm btn-group-vertical">
        <ng-container *ngFor="let menu of childMenus; let i=index">
            <button *ngIf="isViewMasterCustomerDetails" type="button" class="btn btn-primary btn-sm mb-1 custom-width" (click)="openEditAvailabilityDialog(i)">
                {{ menu }}
            </button>
            <button *ngIf="!isViewMasterCustomerDetails" type="button" [disabled]="!customerKey && !providerKey" class="btn btn-primary btn-sm mb-1 custom-width" (click)="openEditAvailabilityDialog(i)">
            {{ menu }}
        </button>
        </ng-container>
    </div>
</div>
