import { Component, Testability, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'ngx-servicerate',
  templateUrl: './servicerate.component.html',
  styleUrls: ['./servicerate.component.scss']
})

export class ServicerateComponent implements OnInit {
  tableFlag: boolean = false;
  constructor(private oauthService: OAuthService) { }

  ServiceRate = [
    { id: "1", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "2", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "3", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "4", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "5", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "6", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "7", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "8", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "9", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" },
    { id: "10", service: "Michelin New", subservice: "test", assembly: "test", isSelected: false, ratetype: "test" }
  ]

  AddRateType = [
    { id: "1", ratetype: "Cargo loadshift/Transfer-Hourly" },
    { id: "2", ratetype: "Mics- Secured Storage" },
    { id: "3", ratetype: "Prep- Detailed Cleaning" }
  ]

  addRateType(addrate) {
    for (let o of this.ServiceRate) {
      if (o.isSelected === true) {
        o.ratetype = addrate.ratetype;
      }
    }
  }

  ngOnInit() {
  }
  onCheckboxClick(service) {
    service.isSelected = !service.isSelected;
  }

  showRateType() {
    for (let o of this.ServiceRate) {
      if (o.isSelected === true) {
        return true;
      }
    }
  }
}