import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicQuestionsService {
  constructor(private http: HttpClient) { }

  addDynamicFormQuestions(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/dynamicforms`, data);
  }

  getDynamicFormQuestions(filter: any = {}): Observable<any> {
    if (filter.deleted === undefined) {
      filter.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/dynamicforms/get`, filter);
  }

  updateDynamicFormQuestions(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/dynamicforms`, data);
  }

}
