import { Component, Inject, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { patterns } from 'src/assets/patterns';
// import { ProviderAdditionalAddresses } from '../../models/provider-additional-addresses.model';
// import { Provider } from '../../models/providers.model';
// import { States } from '../../models/states.model';
// import { ProviderAdditionalAddressesService } from '../../services/provider-additional-addresses.service';
// import { ProvidersService } from '../../services/providers.service';
// import { StatesService } from '../../services/states.service';
// import { UserInformationService } from '../../services/user-information.service';
// import { SnackBarService } from '../../services/snack-bar.service';
// import { UtilityService } from '../../services/utility.service';
// import { SortableHeaderDirective } from '../../directive/sortable.directive';
// import { ProviderOfferingsService } from '../../services/provider-offerings.service';
import { forkJoin } from 'rxjs';
// import { CountiesService } from '../../services/counties.service';
// import { ProviderHoursOfOperationsService } from '../../services/provider-hours-of-operations.service';
// import { ProviderCapabilitiesService } from '../../services/provider-capabilities.service';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../services/provider.service';
import { PhonePipe } from '../../shared/pipes/phone.pipe'

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
  providers: [PhonePipe]
})
export class CompanyDetailsComponent implements OnInit {

//  @Input() parent: boolean;
  @Output() saveComplete = new EventEmitter<any>();

//  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  providerProfileForm: UntypedFormGroup;
  // provider: Provider = new Provider();
  // providerAdditionalAddresses: ProviderAdditionalAddresses = new ProviderAdditionalAddresses();
  searchState: string;
  searchSupervisor: string;
  addrLableText: string;
  latitude = 33.013438;
  longitude = -96.772337;
  loading = false;
//  statesData: States[] = [];
  zoneKey: number;
  countyKey: string;
  parent: boolean = true;

  searchAddress: any;
  bdStates: any;
  supervisors: any;
  bdCities: any;
  breakdownCity: any;
  breakState: any;
  manualAddress: any
  formatted_address: string;
  selectedLat: any;
  selectedLong: any;

  street_number: any;
  route: any;
  neighborhood: any;
  locality: any;
  administrative_area_level_2: any;
  administrative_area_level_1: any;
  country: any;
  postal_code: any;
  postal_code_suffix: any;
  subpremise: any;

  constructor(
    public dialogRef: MatDialogRef<CompanyDetailsComponent>,
    private formBuilder: UntypedFormBuilder,
    private providerService: ProviderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public phone: PhonePipe

  ) { }

  ngOnInit(): void {
    this.loadDetails();
  }

  loadDetails(): void {
    // this.getAllFormDropdowns();
    // this.provider = this.providersService.provider || new Provider();
    // this.providerAdditionalAddresses = this.providerAdditionalAddressesService.providerAdditionalAddresses
    //   || new ProviderAdditionalAddresses();
     this.addrLableText = this.parent ? '' : 'Physical';

    this.providerProfileForm = this.formBuilder.group({
      providerPK: [''],
      splocation: [''],
      additionalAddressPK: [''],
      companyName: [''],
      name: [''],
      ownerFirstName: [''],
      ownerLastName: [''],
      address1: ['', [Validators.required]],
      address2: [''],
      address3: ['', [Validators.required]],
      phoneNumber: ['', Validators.compose([Validators.required,Validators.pattern(patterns.phonePattern)])],
      emailAddress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      postalCode: ['', [Validators.required, Validators.pattern(patterns.zipCodePattern)]],
      supervisorTSLKey: [''],
      performsSameService: [false],
      allLocationsSameHours: [false],
      isSameCapabilities: [false],
    });

      this.getCompanyStates();


      this.providerService.getSupervisorList().subscribe((response: any) => {
        this.supervisors = response.body;
      }, (error) => {
          this.loading = false;
          console.error('CompanyDetailsComponent_ngOnInit', error);
      });


    // this.setFormValidation();
    // this.setFormData();
  }

  onNoClick(): void {
    this.dialogRef.close(0);
    // this.subscription.unsubscribe();
}



onChange(value:string) {

  if(value)
  {
    const ctrl = this.providerProfileForm.get('phoneNumber') as FormControl;

    if(isNaN(<any>value.charAt(0))) {
      ctrl.setValue(this.phone.transform(value), { emitEvent: false, emitViewToModelChange: false });
    } else {
      ctrl.setValue(this.phone.transform(value), { emitEvent: false, emitViewToModelChange: false });
    }
  }
}


  /**
   * Method to get selected address and set latitude and longitude
   * @param { address }, Searched Address
   */
  public handleAddressChange(address: Address) {
   
    this.providerProfileForm.reset();

    this.searchAddress = address.address_components;
    this.formatted_address = address.formatted_address;
    this.providerProfileForm.controls.address1.setValue(address.formatted_address);
    this.selectedLat = address.geometry.location.lat();
    this.selectedLong = address.geometry.location.lng();
    this.searchAddress.forEach(element => {
      element.types.forEach(data => {

        switch(data) { 
          case 'street_number': { 
            this.street_number = element.long_name;
             break; 
          } 
          case 'route': { 
            this.route = element.long_name;
             break; 
          } 
          case 'neighborhood': { 
            this.neighborhood = element.long_name;
            break; 
           } 
           case 'locality': { 
            this.locality = element.long_name;
              break; 
           } 
           case 'administrative_area_level_2': { 
            this.administrative_area_level_2 = element.long_name;
              break; 
           } 
           case 'administrative_area_level_1': { 
            this.administrative_area_level_1 = element.long_name;
              break; 
           } 
            case 'country': { 
              this.country = element.long_name;
              break; 
            } 
            case 'postal_code': { 
              this.postal_code = element.long_name;
              break; 
            } 
            case 'postal_code_suffix': { 
              this.postal_code_suffix = element.long_name;
              break; 
            } 
            case 'subpremise': { 
              this.subpremise = element.long_name;
              break; 
            } 
            default: { 
              //statements; 
              break; 
            } 
       } 


        if (data === 'postal_code') {
          this.providerProfileForm.controls.postalCode.setValue(element.long_name);
        } else if (data === 'country') {
          this.providerProfileForm.controls.country.setValue(element.long_name);
        } else if (data === 'administrative_area_level_1') {
          this.providerProfileForm.controls.state.setValue(element.short_name);
        } else if (data === 'locality') {
          this.providerProfileForm.controls.city.setValue(element.long_name);
        } else if (data === 'street_number') {
//          this.providerProfileForm.controls.address1.setValue(element.long_name);
        }
      });

    });
    this.providerProfileForm.controls.companyName.setValue(address.name);
    this.providerProfileForm.controls.phoneNumber.setValue(address.formatted_phone_number ? address.formatted_phone_number.replace(/\D/g,'') : '');
    this.providerProfileForm.controls.postalCode.setValue(this.postal_code);
    this.providerProfileForm.controls.address1.setValue(this.street_number + ' ' + this.route);
    this.providerProfileForm.controls.address2.setValue(this.subpremise);
    this.providerProfileForm.controls.address3.setValue(this.administrative_area_level_2);
  }
 
//  this.providerService

  addRequest(){

    if (this.providerProfileForm.invalid) {
      this.providerProfileForm.markAllAsTouched();
      return false;
    }

    const data = {
      'companyName' : this.providerProfileForm.get('companyName').value,
      'ownerFirstName' : this.providerProfileForm.get('ownerFirstName').value,
      'ownerLastName' : this.providerProfileForm.get('ownerLastName').value,
      'address1' : this.providerProfileForm.get('address1').value,
      'address2' : this.providerProfileForm.get('address2').value,
      'city' : this.providerProfileForm.get('city').value,
      'county' : this.providerProfileForm.get('address3').value,
      'latitude' : this.selectedLat,
      'longitude' : this.selectedLong,
      'stateKey' : this.providerProfileForm.get('state').value,
      'postalCode' : this.providerProfileForm.get('postalCode').value,
      'phoneNumber' : this.providerProfileForm.get('phoneNumber').value,
      'emailAddress' : this.providerProfileForm.get('emailAddress').value,
      'userKey' : 0,
      'axlePointCOMlCompanyKey' : 0,
      'axlePointADDlAddressKey' : 0,
      'supervisorTSLKey' : this.providerProfileForm.get('supervisorTSLKey').value,
      'woKey' : 0
    };

    this.loading = true;

    this.providerService.insertAddNewProvider(data).subscribe((serviceProviders: any) => {

      if (serviceProviders.body.length > 0) {

        const auxdata = {
          'companyName' : this.providerProfileForm.get('companyName').value,
          'ownerFirstName' : this.providerProfileForm.get('ownerFirstName').value,
          'ownerLastName' : this.providerProfileForm.get('ownerLastName').value,
          'address1' : this.providerProfileForm.get('address1').value,
          'address2' : this.providerProfileForm.get('address2').value,
          'city' : this.providerProfileForm.get('city').value,
          'county' : this.providerProfileForm.get('address3').value,
          'latitude' : this.selectedLat,
          'longitude' : this.selectedLong,
          'stateKey' : this.providerProfileForm.get('state').value,
          'postalCode' : this.providerProfileForm.get('postalCode').value,
          'phoneNumber' : this.providerProfileForm.get('phoneNumber').value,
          'emailAddress' : this.providerProfileForm.get('emailAddress').value,
          'userKey' : sessionStorage.getItem('userKey') ? sessionStorage.getItem('userKey') : '',
          'axlePointCOMlCompanyKey' : serviceProviders.body[0].tmp_COMlCompanyKey,
          'axlePointADDlAddressKey' : serviceProviders.body[0].tmp_ADDlAddressKey,
          'supervisorTSLKey' : this.providerProfileForm.get('supervisorTSLKey').value ? this.providerProfileForm.get('supervisorTSLKey').value : 0 ,
          'woKey' : 0

        };
    
        this.providerService.insertAddNewProviderAux(auxdata).subscribe((auxserviceProviders: any) => {

          this.dialogRef.close(0);

        }, (error: any) => {
          this.loading = false;
          console.error('CompanyDetailsComponent_addRequestaux', error);
        });
        
      }
      else
      {
 
          
      }
    }, (error: any) => {
      this.loading = false;
      console.error('CompanyDetailsComponent_addRequest', error);
    });


  }

  setFormData(): void {
    // if (this.parent) {
    //   this.providerProfileForm.patchValue({
    //     providerPK: this.provider.providerPK || '',
    //     companyName: this.provider.companyName || '',
    //     ownerFirstName: this.provider.ownerFirstName || '',
    //     ownerLastName: this.provider.ownerLastName || '',
    //     address1: this.provider.address1 || '',
    //     address2: this.provider.address2 || '',
    //     address3: this.provider.address3 || '',
    //     city: this.provider.city || '',
    //     state: this.provider.state || '',
    //     postalCode: this.provider.postalCode || '',
    //   });
    // } else {
    //   this.providerProfileForm.patchValue({
    //     additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK || '',
    //     providerPK: this.providerAdditionalAddresses.providerPK || '',
    //     name: this.providerAdditionalAddresses.name || '',
    //     address1: this.providerAdditionalAddresses.address1 || '',
    //     address2: this.providerAdditionalAddresses.address2 || '',
    //     address3: this.providerAdditionalAddresses.address3 || '',
    //     city: this.providerAdditionalAddresses.city || '',
    //     state: this.providerAdditionalAddresses.state || '',
    //     postalCode: this.providerAdditionalAddresses.postalCode || '',
    //     performsSameService: this.providerAdditionalAddresses.performsSameService || false,
    //     allLocationsSameHours: this.providerAdditionalAddresses.allLocationsSameHours || false,
    //     isSameCapabilities: this.providerAdditionalAddresses.isSameCapabilities || false
    //   });
    // }
  }

  getCompanyStates() {
    this.providerService.getCompanyStates().subscribe(res => {
      this.bdStates = res;
    }, err => {
      this.loading = false;
      console.error('CompanyDetailsComponent_getCompanyStates', err);
    });
  }


  setFormValidation(): void {
    // if (this.parent) {
    //   this.providerProfileForm.controls.companyName.setValidators([Validators.required, Validators.pattern(patterns.textPattern)]);
    //   this.providerProfileForm.controls.name.setValidators(null);
    //   this.providerProfileForm.controls.ownerFirstName.setValidators([Validators.required, Validators.pattern(patterns.namePattern)]);
    //   this.providerProfileForm.controls.ownerLastName.setValidators([Validators.required, Validators.pattern(patterns.lastNamePattern)]);
    // } else {
    //   this.providerProfileForm.controls.companyName.setValidators(null);
    //   this.providerProfileForm.controls.name.setValidators([Validators.required, Validators.pattern(patterns.textPattern)]);
    //   this.providerProfileForm.controls.ownerFirstName.setValidators(null);
    //   this.providerProfileForm.controls.ownerLastName.setValidators(null);
    // }
    // this.providerProfileForm.controls.companyName.updateValueAndValidity();
    // this.providerProfileForm.controls.name.updateValueAndValidity();
    // this.providerProfileForm.controls.ownerFirstName.updateValueAndValidity();
    // this.providerProfileForm.controls.ownerLastName.updateValueAndValidity();
  }

  getAllFormDropdowns(): void {
    // this.loading = true;
    // this.statesService.getStates(new States()).subscribe((response) => {
    //   this.loading = false;
    //   this.statesData = this.utilityService.sortData(this.headers, 'StateDescription', 'asc', response);
    // }, (error) => {
    //   this.loading = false;
    // });
  }

  handleError = (control: string, error: string) => {
    return this.providerProfileForm.controls[control].hasError(error);
  }

  public getDetailedAddress(address: Address): void {
    // this.latitude = address.geometry.location.lat();
    // this.longitude = address.geometry.location.lng();
    // address.address_components.forEach(element => {
    //   element.types.forEach(data => {
    //     switch (data) {
    //       case 'neighborhood':
    //         this.providerProfileForm.controls.address2.setValue(element.long_name);
    //         break;
    //       case 'administrative_area_level_2':
    //         this.providerProfileForm.controls.address3.setValue(element.long_name);
    //         break;
    //       case 'administrative_area_level_1':
    //         this.providerProfileForm.controls.state.setValue(element.short_name);
    //         break;
    //       case 'postal_code':
    //         this.providerProfileForm.controls.postalCode.setValue(element.long_name);
    //         break;
    //       case 'locality':
    //         this.providerProfileForm.controls.city.setValue(element.long_name);
    //         break;
    //     }
    //   });
    // });
    // this.providerProfileForm.controls.address1.setValue(address.name);
  }

  updateProviderDetails(): any {
    // if (this.providerProfileForm.invalid) {
    //   this.providerProfileForm.markAllAsTouched();
    //   return false;
    // }

    // const zoneReq = {
    //   county: this.providerProfileForm.controls.address3.value,
    //   stateKey: this.providerProfileForm.controls.state.value,
    // };
    // this.loading = true;
    // this.countyService.getCounties(zoneReq).subscribe(res => {
    //   this.loading = false;
    //   this.zoneKey = res[0]?.ZoneKey;
    //   this.countyKey = res[0]?.CountyKey;
    //   this.provider = Object.assign(this.provider, new Provider().prepareSave(this.providerProfileForm.value));
    //   this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();
    //   this.provider.zoneKey = this.zoneKey ? this.zoneKey : 0;
    //   this.provider.countyKey = this.countyKey ? this.countyKey : null;
    //   this.provider.latitude = this.latitude;
    //   this.provider.longitude = this.longitude;
    //   this.loading = true;
    //   this.providersService.updateProvider(this.provider).subscribe((result) => {
    //     this.loading = false;
    //     this.snackBarService.success(result.message);
    //     this.providersService.provider = this.provider;
    //     this.saveComplete.emit(true);
    //   }, (error) => {
    //     this.loading = false;
    //     this.saveComplete.emit(false);
    //   });
    // });
  }

  updateProviderAdditionalAddressDetails(): any {
    // if (this.providerProfileForm.invalid) {
    //   this.providerProfileForm.markAllAsTouched();
    //   return false;
    // }
    // const zoneReq = {
    //   county: this.providerProfileForm.controls.address3.value,
    //   stateKey: this.providerProfileForm.controls.state.value,
    // };
    // this.loading = true;
    // this.countyService.getCounties(zoneReq).subscribe(res => {
    //   this.loading = false;
    //   this.zoneKey = res[0]?.ZoneKey;
    //   this.countyKey = res[0]?.CountyKey;
    //   this.providerAdditionalAddresses = Object.assign(
    //     this.providerAdditionalAddresses, new ProviderAdditionalAddresses().prepareSave(this.providerProfileForm.value)
    //   );
    //   this.providerAdditionalAddresses.zoneKey = this.zoneKey ? this.zoneKey : 0;
    //   this.providerAdditionalAddresses.countyKey = this.countyKey ? this.countyKey : null;
    //   this.providerAdditionalAddresses.latitude = this.latitude;
    //   this.providerAdditionalAddresses.longitude = this.longitude;
    //   this.provider.updatedUserKey = this.userInformationService.userInformation.userInformationID.toString();
    //   const request = [];
    //   request.push(this.providerAdditionalAddressesService.updateProviderAdditionalAddresses(this.providerAdditionalAddresses));

    //   if (this.providerProfileForm.controls.performsSameService.value) {
    //     const data = {
    //       additionalAddressPK: this.providerAdditionalAddresses.additionalAddressPK,
    //       providerKey: this.provider.providerPK,
    //       userInformationId: this.userInformationService.userInformation.userInformationID.toString()
    //     };
    //     request.push(this.providerOfferingsService.copyProviderOfferingsToPAA(data));
    //   }

    //   if (this.providerProfileForm.controls.allLocationsSameHours.value) {
    //     const jsonData = {
    //       locationKey: this.providerAdditionalAddresses.additionalAddressPK,
    //       providerKey: this.provider.providerPK
    //     };
    //     request.push(this.hoursOfOperationService.copyProviderHoursOfOperation(jsonData));
    //   }

    //   if (this.providerProfileForm.controls.isSameCapabilities.value) {
    //     const insertData = {
    //       providerKey: this.provider.providerPK,
    //       locationKey: this.providerAdditionalAddresses.additionalAddressPK,
    //       userInformationId: this.userInformationService.userInformation.userInformationID.toString()
    //     };

    //     request.push(this.providerCapabilitiesService.copyProviderCapabilitiesToLocation(insertData));
    //   }

    //   this.loading = true;
    //   forkJoin([...request]).subscribe((result: any) => {
    //     this.loading = false;
    //     this.snackBarService.success(result[0].message);
    //     this.providerAdditionalAddressesService.providerAdditionalAddresses = this.providerAdditionalAddresses;
    //     this.saveComplete.emit(true);
    //   }, (error) => {
    //     this.loading = false;
    //     this.saveComplete.emit(false);
    //   });
    // });
  }
}
