<!-- <app-bubble-user-information></app-bubble-user-information>
<div class="row noMargin hiddenServicesScroll">
    <div class="col-sm-12">
        <app-loader [loading]="loading"></app-loader>
        <div class="tree-block">
            <div class="tree-pane">
                <div class="tree-paning-container">
                    <div class="tree-roots-elements">
                        <div class="tree-root tree-node tree-new-node" *ngIf="addNode">
                            <div class="rect">
                                <div><span>Add Service</span></div>
                            </div>
                        </div>
                        <bubble-tree-provider [levelData]="serviceData" [level]="level" [addNode]="addNode"
                            [editNode]="editNode" [delNode]="delNode" [isActiveNode]="isActiveNode"
                            [disableDragDrop]="disableDragDrop" [lastNode]="lastNode" [isSelected]="isSelected"
                            [providerAccount]="providerDetails" [parentRecord]="parentRecord">
                        </bubble-tree-provider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<app-loader [loading]="loading"></app-loader>
<div class="row m-2 p-1 border border-3" [ngClass]="parentRecord === true ? 'border-primary': 'border'">
    <div class="col-md-12">
        <div class="row p-2 m-2 mx-3 mt-2 border">
            <ng-container *ngFor="let data of serviceData">
                <tree-root [nodes]="[data]" #tree [options]="options" class="col-md-6 mt-2">
                    <ng-template #treeNodeTemplate let-node="node" let-index="index">
                        <mat-checkbox [checked]="node.data.deleted === 0" 
                            (change)="onSelectedChange(node.data, node.data.deleted)">
                            <strong>{{ node.data.name }}</strong>
                        </mat-checkbox>
                    </ng-template>
                </tree-root>
            </ng-container>
        </div>
        <div class="row my-2 p-2">
            <div class="col-md-12">
                <h6>You are listed in {{ parentServiceCount}} Service categories</h6>
            </div>
            <div class="col-md-12 p-1 shadow-sm">
                <span class="badge badge-pill badge-primary m-1 p-2"
                    *ngFor="let service of serviceNames">{{ service }}</span>
            </div>
        </div>
    </div>
</div>