<app-loader [loading]="loading"></app-loader>

<nb-card>
  <nb-card-header class="card border-primary">
    <form [formGroup]="providerAdminForm">
      <div class="row">
        <div class="col-md-12">
          Provider Management TEST
        </div>
      </div>

      <!-- <div class="row mt-2">  
        <button class="btn btnAnimation bottomMargin"
                mat-button 
                [matMenuTriggerFor]="menu" 
                nbTooltip="Menu">
            <mat-icon>menu</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-icon>home</mat-icon>
              <span>Home</span>
            </button>

            <button *ngIf="returnToParentOption" mat-menu-item (click)="returnToParent()">
              <mat-icon>refresh</mat-icon>
              <span>Parent</span>
            </button>

            <button mat-menu-item (click)="changeSearch()">
              <mat-icon>search</mat-icon>
              <span *ngIf="advancedSearch">Regular Search</span>
              <span *ngIf="!advancedSearch">Advanced Search</span>
            </button>
        </mat-menu>
      </div> -->

      <!-- <div class="col-md-12 noPadding">
        Provider Search
      </div>  -->
      <br>
      
      <div class="row mt-2">
        <div class="col-md-3 custom-select-height">
          <mat-form-field>
            <input matInput placeholder="Provider Name" formControlName="searchText">
          </mat-form-field>
        </div>

        <div class="col-md-2 custom-select-height"  *ngIf="advancedSearch">
          <mat-form-field>
            <input matInput placeholder="Provider ID" formControlName="providerIDText">
          </mat-form-field>
        </div>

        <div class="col-md-2 custom-select-height" *ngIf="advancedSearch">
          <mat-form-field>
            <input matInput matInput mask="000-000-0000" placeholder="Phone Number" formControlName="phoneNumberText" >
            <span *ngIf="providerAdminForm.controls.phoneNumberText.value != '' && providerAdminForm.controls.phoneNumberText.value != null " matTextPrefix>+1&nbsp;</span>
          </mat-form-field>
        </div>

      </div>

      <div *ngIf="advancedSearch">

      <div class="row mt-2"> &nbsp; </div>

      <div class="row mt-2">

          <div class="col-md-5 custom-select-height">
            <mat-form-field>
              <input matInput placeholder="Address Line 1" formControlName="addressText">
            </mat-form-field>
          </div>

          <div class="col-md-2 custom-select-height">
            <mat-form-field>
              <input matInput placeholder="CompanyFEIN" formControlName="companyFEIN">
            </mat-form-field>
          </div>
      
      </div>

      <div class="row mt-2"> &nbsp; </div>

      <div class="row mt-2">
       
        <div class="col-md-3 custom-select-height">
          <mat-form-field>
            <input matInput placeholder="City" formControlName="cityText">
          </mat-form-field>
        </div>

        <div class="col-md-2 custom-select-height">
          <mat-form-field>
            <mat-select placeholder="State" formControlName="stateText" 
            matNativeControl>
            <mat-option></mat-option>
            <mat-option *ngFor="let state of statesData | orderBy:'state'"
                [value]="state.stateKey">
                {{state.stateDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2 custom-select-height">
          <mat-form-field>
            <input matInput placeholder="Zip Code" formControlName="zipCodeText" minlength="5" maxlength="7" >
          </mat-form-field>
        </div>

        <div class="col-md-1 text-right">
          <button class="btn btn-primary btn-sm" (click)="getProviderParentLocations()">Search</button>
        </div>

      </div>
      </div>

      <div class="row mt-2"> &nbsp; </div>

      <!-- <div class="row mt-2">
        <div class="col-md-8 custom-select-height">
          <mat-form-field>
            <mat-select
                  formControlName="locationKey" 
                  placeholder="Select Location"
                  (selectionChange)="onSelectLocation($event)">

              <mat-option>
                <ngx-mat-select-search
                          [ngModelOptions]="{standalone: true}" 
                          [(ngModel)]="searchLocationList"
                          [placeholderLabel]="'Search...'" 
                          [noEntriesFoundLabel]="'Not found'"
                          name="search">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option>Select Location</mat-option>
            
              <mat-option *ngIf="providerSet" (click)="addLocation($event)">
                <mat-icon> add_location </mat-icon>
                  Add Location
              </mat-option>

              <mat-option *ngIf="providerSet" (click)="locationDetailsList()">
                <mat-icon> list_alt </mat-icon>
                  Location Details
              </mat-option>

              <mat-option
                  *ngFor="let providerLocation of providerLocationList | orderBy:'providerName' | selectFilter:searchLocationList:'providerName'"
                   [value]="providerLocation.providerKey">
                {{ providerLocation.locationName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-1 text-right">
          <button class="btn btn-primary btn-sm" (click)="getProviderParentLocations()">Search</button>
        </div>
        <div *ngIf="providerSet" class="col-md-2">
          <button class="btn btn-primary btn-sm" (click)="locationDetailsList()">Location Details</button>
        </div>
      </div> END class="row mt-2" -->

      <!-- Used to fix blue border around div container, but this needs to be fixed in CSS I think -->
      <!-- <div class="row mt-2"> &nbsp; </div> -->

      <!-- <div class="row mt-2" *ngIf="advancedSearch"> &nbsp; </div> -->

    </form>
  </nb-card-header>

  <nb-card-body *ngIf="providerSet">
    <div *ngIf="providerSet">
      <mat-tab-group
            class="alternate-theme"
            (selectedTabChange)="tabChanged($event)"
            [selectedIndex]="selectedIndex"
            [backgroundColor]="providerColor">
      <!--  [ngClass]="{'SThighlight' : providerSet}" -->

                        <!-- (next)="nextTab($event)" -->
        <mat-tab label="Profile Details">
          <app-provider-management-profile-details
                        [providerDetails]="providerDetails"
                       
                        [showSave]='true'>
          </app-provider-management-profile-details>
        </mat-tab>
                        <!-- (next)="nextTab($event)" -->
        <mat-tab label="Account Details">
          <app-provider-admin-details
                        *ngIf="selectedIndex == 1"
                         [providerDetails]="providerDetails"                          
                         >
          </app-provider-admin-details>
        </mat-tab>

        <mat-tab label="Service Rates">
          <app-provider-admin-rates 
                        *ngIf="selectedIndex == 2" 
                         [providerDetails]="providerDetails" >
          </app-provider-admin-rates>
        </mat-tab>

        <mat-tab label="Sublocation Details">
          <app-provider-admin-services 
                        *ngIf="selectedIndex == 3"
                         [providerDetails]="providerDetails" >
          </app-provider-admin-services>
        </mat-tab>

        <mat-tab label="Insurance Requirements">
          <app-provider-admin-insurance 
                        *ngIf="selectedIndex == 4" 
                         [providerDetails]="providerDetails" 
                         [isAddButton]="isAddButton">
          </app-provider-admin-insurance>
        </mat-tab>

        <mat-tab [disabled]="isDiscount" label="Discounts">
          <app-provider-admin-discount 
                        *ngIf="selectedIndex == 5" 
                         [providerDetails]="providerDetails" 
                         [isEdit]="true">
          </app-provider-admin-discount>
        </mat-tab>

        <mat-tab label="Notes & Attachments">
          <app-provider-admin-notes 
                        *ngIf="selectedIndex == 6" 
                         [providerDetails]="providerDetails" >
          </app-provider-admin-notes>
        </mat-tab>

        <mat-tab label="ETAs">
          <app-provider-account-eta 
                        *ngIf="selectedIndex == 7" 
                         [providerDetails]="providerDetails" 
                         [isParent]="true">
          </app-provider-account-eta>
        </mat-tab>

        <mat-tab label="Services">
          <app-provider-management-services 
                        *ngIf="selectedIndex == 8" 
                         [providerDetails]="providerDetails">
          </app-provider-management-services>
        </mat-tab>

    <!-- <mat-tab [disabled]="isDiscount" label="Default Discounts">
            <app-provider-admin-default-discount 
                          *ngIf="selectedIndex == 9"
                            [providerDetails]="providerDetails" 
                            [isEdit]="true">
            </app-provider-admin-default-discount>
         </mat-tab> -->
      </mat-tab-group>
    </div>
  </nb-card-body>

  <nb-card-footer class="text-right" *ngIf="providerSet">
    <!-- <button *ngIf="selectedIndex == 0 || selectedIndex == 1" 
             class="btn btn-primary btn-sm"
             (click)="nextPage()">
        Save & Next 
    </button> -->

    <!-- <button *ngIf="selectedIndex != 0 && selectedIndex != 1" 
             class="btn btn-primary btn-sm"
             (click)="nextPage()">
        Next
    </button> -->
  </nb-card-footer>
</nb-card>