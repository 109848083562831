<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h5 class="modal-title">{{dialogTitle}}</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-4">
            <ngx-search-input (search)="search($event)"></ngx-search-input>
        </div>
        <div class="col-md-8 text-right" *ngIf="isLicensedOwnerProtocol">
            <button class="btn btn-primary btn-sm" (click)="addLicensedOwnerProtocol()">Add New</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="table-responsive protocol-list-tbl">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th sortable="licensedOwnerOfferingKey" (sort)="onSort($event)" class="cursor-pointer align-middle" direction="asc">Service</th>
                            <th sortable="callerTypeKey" (sort)="onSort($event)" class="cursor-pointer align-middle">Caller Type</th>
                            <th sortable="operatingScheduleKey" (sort)="onSort($event)" class="align-middle">Schedule</th>
                            <th sortable="supported" (sort)="onSort($event)" class="cursor-pointer align-middle">Supported</th>
                            <th sortable="systemDefault" (sort)="onSort($event)" class="cursor-pointer align-middle">Default</th>
                            <th sortable="updatedBy" (sort)="onSort($event)" class="cursor-pointer align-middle">Updated By</th>
                            <th sortable="updatedTimestamp" (sort)="onSort($event)" class="cursor-pointer align-middle">Updated Time</th>
                            <th *ngIf="isLicensedOwnerProtocol">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="arrProtocolDetails.length > 0; else noData">
                        <tr *ngFor="let item of arrProtocolDetails | filter : searchString">
                            <td>{{item.offerings}}</td>
                            <td>{{item.callerTypeKey}}</td>
                            <td>{{item.operatingScheduleKey}}</td>
                            <td>{{ item.supported == true ? 'Yes' : 'No' }}</td>
                            <td>{{ item.systemDefault == true ? 'Yes' : 'No' }}</td>
                            <td>{{ item.updatedBy }}</td>
                            <td>{{ item.updatedTimestamp | date:'MMM dd, yyyy dd:mm' }}</td>
                            <td *ngIf="isLicensedOwnerProtocol">
                                <div>
                                    <button class="btn btn-default btnAnimation" nbTooltip="Edit" (click)="editLicensedOwnerProtocols(item)">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                    <button  class="btn btn-default btnAnimation" nbTooltip="Delete" (click)="removeLicensedOwnerProtocols(item)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </div>                                
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="8">No data available.</td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer text-right">
    <button type="button" class="btn btn-success btn-sm mr-1" (click)="exportToCSVServices()">Print Report</button>
</div>
