<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">
        Discount - Service Exceptions
    </h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="col-md-12">
    <form [formGroup]="serviceExclusionDetailForm">
        <div class="row mt-2" *ngIf="isEdit">
            <div class="col-md-5">
                <mat-form-field>
                    <mat-select (selectionChange)="getSubServices($event)" formControlName="service" matNativeControl
                        placeholder="Service">
                        <mat-option disabled>Select Service</mat-option>
                        <ng-container *ngFor="let val of serviceDetails | orderBy : 'description'">
                            <mat-option *ngIf="val.parentKey == null || val.parentKey == '0'" [value]="val.networkOwnerOfferingKey">
                                {{val.description}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <mat-form-field>
                    <mat-select formControlName="subService" matNativeControl placeholder="SubService">
                        <mat-option disabled>Select Sub Service</mat-option>
                        <mat-option *ngFor="let val of subServiceDetails | orderBy : 'description'"
                            [value]="val.licensedOwnerOfferingKey">
                            {{val.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 mt-3">
                <button class="btn btn-primary btn-sm" (click)="updateService('addProviderDiscountApplies', '') ">Add To
                    List</button>
            </div>
        </div>
        <div class="row">
            <div class="table-responsive mt-3">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th sortable="Level_1_Desc" (sort)="onSort($event)">Service</th>
                            <th sortable="Level_2_Desc" (sort)="onSort($event)">Sub Service</th>
                            <th *ngIf="isEdit">Delete</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="discountServiceExceptions.length > 0 else noData">
                        <tr *ngFor="let data of discountServiceExceptions">
                            <td>{{data.Level_1_Desc}}</td>
                            <td>{{data.Level_2_Desc}}</td>
                            <td *ngIf="isEdit">
                                <button class="btn btn-default btnAnimation" nbTooltip="Delete"
                                    (click)="updateService('deleteProviderDiscountApplies',data.subServiceId)">
                                    <i class="fas fa-times"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td *ngIf="!isEdit" colspan="2" class="text-center">No Data available</td>
                                <td *ngIf="isEdit" colspan="3" class="text-center">No Data available</td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </form>
</div>