import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BubbleService {

  selectedTab: string;

  public bubbleData = new Subject<any>();
  public bubbleLoader = new Subject<any>();
  public customProtocolBubbleData = new Subject<any>();
  public customProtocolBubbleLoader = new Subject<any>();
  serviceNetworkData: any;
  constructor(
    private http: HttpClient
  ) { }

  setBubbleTreeData() {
    this.bubbleData.next();
  }

  getBubbleTreeData(): Observable<any> {
    return this.bubbleData.asObservable();
  }

  setCustomProtocolBubbleTreeData() {
    this.customProtocolBubbleData.next();
  }

  getCustomProtocolBubbleTreeData(): Observable<any> {
    return this.customProtocolBubbleData.asObservable();
  }

  getProviderAccountKey(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts/get`, data);
  }

  setUpServiceAndRates(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}setUpServiceAndRates`, data);
  }

  setserviceNetworkData(serviceNetworkData: any) {
    this.serviceNetworkData = serviceNetworkData;
  }

  getserviceNetworkData() {
    return this.serviceNetworkData;
  }

  getProviderServiceOfferings(arrProviderServiceOfferingsFilters): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderServiceOfferings`, arrProviderServiceOfferingsFilters);
  }

  getProviderServiceOfferingsWithoutlicensedOwnerKey(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderServiceOfferingsWithoutlicensedOwnerKey`, data);
  }
  getProviderServiceOfferingRestrictions(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getProviderServiceOfferingRestrictions/${data.providerKey}`);
  }

  getProviderService(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerserviceofferings/get`, data);
  }

  setSelectedTab(tab) {
    this.selectedTab = tab;
  }

  getSelectedTab() {
    return this.selectedTab;
  }

  setBubbleLoader(message: boolean) {
    this.bubbleLoader.next(message);
  }

  updateActivateInActivateProviderServiceOfferings(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}updateActivateInActivateProviderServiceOfferings`, arrReqData);
  }

  // update provider service offerings
  updateProviderServiceOfferings(updateData): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/providerserviceofferings/batch`, updateData);
  }
  
  addDeleteProviderOfferingsRestrictions(reqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}addDeleteProviderOfferingsRestrictions`, reqData);
  }

  getLicensedOwnerOfferings(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerofferings/get`, data);
  }

  getServiceExceptions(licensedOwnerKey): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getGetDiscountServiceExceptions/${licensedOwnerKey}`);
  }
}
