import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public servicePlusOption: any;
  public cognitoOption: any;
  public imageURL = new Subject<any>();
  public companyName = new Subject<any>();
  public userName = new Subject<any>();
  public cognitoClaims = new Subject<any>();
  public sidebarCollapsedStatus = new Subject<any>();

  constructor(
    private datePipe: DatePipe,
  ) { }


  setCognitoClaims() {
    this.cognitoClaims.next();
  }
  
  setSidebarStatus(sidebarCollapsedStatus: Boolean) {
    this.sidebarCollapsedStatus.next(sidebarCollapsedStatus);
  }


  savePreviousRoute() {
    const url = window.location.href.split('#/pages/');
    sessionStorage.setItem('nodeLink', url[1]);
    const menuUltimateChild = JSON.parse(sessionStorage.getItem('menuUltimateChild'));
    const index = menuUltimateChild.indexOf(sessionStorage.getItem('nodeLink'));
    if (!!menuUltimateChild[index - 1]) {
      sessionStorage.setItem('previousPageLink', '/pages/' + menuUltimateChild[index - 1]);
    }
  }
  
  saveNextRoute() {
    const url = window.location.href.split('pages/');
    sessionStorage.setItem('nodeLink', 'pages/' + url[1]);
    const menuUltimateChild = JSON.parse(sessionStorage.getItem('menuUltimateChild'));
    const index = menuUltimateChild.indexOf(url[1]);
    if (!!menuUltimateChild[index + 1]) {
      sessionStorage.setItem('nextPageLink', '/pages/' + menuUltimateChild[index + 1]);
    }
  }

  compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  sortData(headers, column, direction, arrList) {
    headers?.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    let data;
    if (direction === '') {
      data = [...arrList];
    } else {
      data = [...arrList].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
    return data;
  }

  setProfilePicture(url: string) {
    this.imageURL.next(url);
  }

  converDateInUnix(date) {
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    const unixDate = (new Date(formattedDate)).getTime();
    return unixDate;
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  replaceStringVariables(str, templateVariables) {
    const mapObj = templateVariables;
    const variables = Object.keys(mapObj).join('|');

    const re = new RegExp(variables, 'g');
    return str.replace(re, function (matched) {
      return mapObj[matched];
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getCurrentTimestamp() {
    const formattedDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss.SSS');
    const currentTimestamp = (new Date(formattedDate)).getTime();
    return currentTimestamp;
  }

  setCompanyName(companyName: string) {
    this.companyName.next(companyName);
  }

  sortJsonByKey(array, key, order) {
    return array.sort(function (a, b) {
      if (order === 'DESC') {
        return ((a[key] > b[key]) ? -1 : ((a[key] < b[key]) ? 1 : 0));
      } else {
        return ((a[key] < b[key]) ? -1 : ((a[key] > b[key]) ? 1 : 0));
      }
    });
  }

  setUserName(userName: string) {
    this.userName.next(userName);
  }

  getYears(start: number) {
    const currentYear = new Date().getFullYear();
    const end = currentYear + 1;
    const yearArray = (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
    return yearArray.reverse();
  }

  transform(time: any): any {
    if (time == null || time === "0000-00-00 00:00:00" || time === "-") {
      return time;
    }
    var formatDate = moment.tz(moment(time), moment.tz.guess());
    return formatDate.tz('America/New_York');
  }

  urltoFile(url, filename, mimeType): any {
    return (fetch(url)
      .then((res) => { return res.arrayBuffer(); })
      .then((buf) => { return new File([buf], filename, { type: mimeType }); })
    );
  }
}
