import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HoursOfOperationsService {

  constructor(private http: HttpClient) { }

  getHoursOfOperation(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customerhoursofoperation/get`, data);
  }

  editHoursOfOperation(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customerhoursofoperation`, data);
  }

  insertHoursRules(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customeruseoffhourprotocolsprotocols`, data);
  }

  updateHours(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customeruseoffhourprotocolsprotocols`, data);
  }

  deleteHours(postData): Observable<any> {
    const data: any = {
      body: { customerUseOffHourProtocolsProtocolKey: postData.customerUseOffHourProtocolsProtocolKey }
    };
    return this.http.delete<any>(`${environment.apiUrl}fleetassist/customeruseoffhourprotocolsprotocols`, data);
  }

  addHoursOfOperationBatch(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customerhoursofoperation/batch`, data);
  }

  updatCustomerHoursOfOperationBatch(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customerhoursofoperation/batch`, data);
  }

  getProviderHoursOfOperation(data: any = {}) {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerhoursofoperation/get`, data);
  }

  updateHoursOfOperation(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerhoursofoperation`, data);
  }

  updateHoursOfOperationBatch(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerhoursofoperation/batch`, data);
  }

  getDayOfObservance(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/holidays/get`, data);
  }

  getProviderHolidayHoursOfOperation(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerholidayhoursofoperation/get`, data);
  }

  saveHolidayHours(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerholidayhoursofoperation`, data);
  }

  updateProviderHolidayHours(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerholidayhoursofoperation`, data);
  }

  deleteProviderHolidayHours(providerHolidayHour): Observable<any> {
    const json: any = {
      providerHolidayHoursOfOperationKey: providerHolidayHour.providerHolidayHoursOfOperationKey,
    };
    const deleteProviderHolidayHoursData: any = {
      body: json,
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providerholidayhoursofoperation`,
    deleteProviderHolidayHoursData);
  }
}
