<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<bubble-user-information-index></bubble-user-information-index>

<div class="offset-md-8 col-md-4 text-right has-search">
    <input type="text" class="form-control" [(ngModel)]="searchString" placeholder="Search" (input)="onInput($event)">
</div>
<div class="row mx-3 mt-2 border p-4">
    <!-- <ngx-checkbox-tree-ui *ngIf="providerCapabilities" [levelData]="providerCapabilities" [level]="level"
            [isSelected]="isSelected" [parentRecord]="parentRecord" [lastNode]="lastNode" [providerKey]="providerKey">
        </ngx-checkbox-tree-ui> -->
    <ng-container *ngFor="let data of serviceData | recursiveFilter: searchString :  'name'">
        <tree-root [nodes]="[data]" #tree [options]="options" class="col-md-4 mt-2">
            <ng-template #treeNodeTemplate let-node="node" let-index="index">
                <mat-checkbox [checked]="node.data.deleted === 0" (change)="onSelectedChange(node, $event)"
                    [ngClass]="{'parentRecord': parentRecord === true }">
                    <strong>{{ node.data.name }}</strong>
                </mat-checkbox>
            </ng-template>
        </tree-root>
    </ng-container>
</div>

<!-- <div class="row">
    <div class="col mx-5 mt-3 border p-4">
            <bubble-tree-ui [levelData]="serviceData" [level]="level" [addNode]="addNode"
                [editNode]="editNode" [delNode]="delNode" [isActiveNode]="isActiveNode"
                [disableDragDrop]="disableDragDrop" [lastNode]="lastNode" [isSelected]="isSelected"
                [providerAccount]="providerAccount" [parentRecord]="parentRecord">
            </bubble-tree-ui>
        </div>
    </div> -->