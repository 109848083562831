import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DynamoProvider } from '../shared/models/dynamo-provider.model';

@Injectable({
  providedIn: 'root'
})
export class DynamoDBService {

  //To store request data get from ProviderReq
  dynamoProviderData = new Subject<DynamoProvider>();
  public loader = new Subject<any>();
  requested = true;

  constructor(
    private http: HttpClient
  ) { }

  geoHashInsert(providerKey: any) {
    const reqData = {
      providerKey: providerKey,
      companyKeyStart: null,
      companyKeyStop: null,
      providerIdStart: null,
      providerIdStop: null
    };
    this.setLoader(true);
    this.requested = false;
    this.http.post<any>(`${environment.apiUrl}geo/insert`, reqData).pipe(
      map((res: any) => res?.insertRequest?.sourceAddresses.map((dynamoProvider: DynamoProvider) => new DynamoProvider().deserialize(dynamoProvider)))).subscribe(insertedData => {
        this.setLoader(false);
        if (insertedData) {
          const responseData = insertedData[0];
          responseData.addressGeoJson = responseData?.geoPoint ? JSON.stringify(responseData.geoPoint) : "";
          // sessionStorage.setItem('geoHashProvider', JSON.stringify(responseData));
          this.DynamoProviderDetails();
        }
      }, error => {
        console.log(error);
        this.setLoader(false);
      });
  }

  geoHashUpdate(reqData: any) {
    this.setLoader(true);
    this.http.post<any>(`${environment.apiUrl}geo/update`, reqData).pipe(
      map((res: any) => res?.updateRequest?.addresses.map((dynamoProvider: DynamoProvider) => new DynamoProvider().deserialize(dynamoProvider)))).subscribe(updateRequest => {
        this.setLoader(false);
        const updatedData = updateRequest[0];
        const geoJSON = JSON.parse(updatedData?.addressGeoJson);
        sessionStorage.setItem('coordinates', JSON.stringify(geoJSON.coordinates));
        if (updateRequest.length)
          sessionStorage.setItem('geoHashProvider', JSON.stringify(updateRequest[0]));
      }, error => {
        console.log(error);
        this.setLoader(false);
      });
  }

  DynamoProviderDetails() {
    const coords = JSON.parse(sessionStorage.getItem('coordinates'));
    if (!coords) return;
    const reqData = {
      radius: 1000,
      point: {
        coordinates: JSON.parse(sessionStorage.getItem('coordinates'))
      }
    }
    this.setLoader(true);
    this.getGeoHashProviders(reqData).subscribe(res => {
      this.setLoader(false);
      const response = res?.addresses || [];
      const providerData = response.find(provider => provider.providerKey === sessionStorage.getItem('providerKey'));
      if (providerData) sessionStorage.setItem('geoHashProvider', JSON.stringify(providerData));
      else {
        if (this.requested)
          this.geoHashInsert(sessionStorage.getItem('providerKey')); // ! If Provider not found in result inserted provider in Geohash
      }

    }, error => {
      this.setLoader(false);
      console.log(error);
    });
  }

  getGeoHashProviders(reqData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}geo/search`, reqData);
  }

  setDynamoProviderData(data: DynamoProvider) {
    this.dynamoProviderData.next(data);
  }

  getDynamoProviderData(): Observable<any> {
    return this.dynamoProviderData.asObservable();
  }

  setLoader(message: boolean) {
    this.loader.next(message);
  }

}
