import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class SetupService {

  constructor(
    private http: HttpClient,
  ) { }

  /**
	 * It will add new user into cognito pool
	 * @param data 
	 */
  addNewUserToCognito(data): Observable<any> {
    return this.http.post<any>(`${environment.cognitoSignupUrl}`, data);
  }

  /**
	 * It will return LicensedOwnerProviderInviteKey to register new user in cognito
	 * @param email = email id of invitted user
	 */
  getLicensedOwnerProviderInviteKey(email): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites/get`, email);
  }

  /**
	 * It will add default provider
	 * @param data
	 */
  addDefaultProvider(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providers`, data);
  }

  /**
	 * It will add default provider account
	 * @param data
	 */
  addProviderAccount(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts`, data);
  }

  /**
	 * It will return provider data to check user signup is completed or not 
	 * @param providerKey
	 */
  checkUserStatus(providerKey): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providers/get`, providerKey);
  }

  /**
	 * It will update set provider key in users table 
	 * @param data = userKey and providerKey
	 */
  updateUserDetails(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}systemsecurities/users`, data);
  }

  updateUserStatus(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}systemsecurities/users`, data);
  }

  /**
	 * It will check if user is already signup with service provider 
	 * @param data = email Address
	 */
  checkIfUserExist(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/users/get`, data);
  }

  // update provider invite email response time
  setInviteEmailDetails(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites`, data);
  }

  addDefaultHoursOfOperation(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerhoursofoperation/batch`, data);
  }

  getProviderDefaultAccountKey(data: any = {}) {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts/get`, data);
  }

  addDefaultLocation(providerKey): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}SetUpLocation/` + providerKey);
  }

  getLicensedOwnerDetails(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedowners/get`, data);
  }

  /**
  * It will add user security groups(access for menus)
  * @param data 
  */
  addUserSecurityGroup(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}systemsecurities/usersecuritygroups`, data);
  }

  // update profile invite steps
  updateProfileInviteStepsOnSignUp(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites`, data);
  }

  // get profile invite steps
  getProfileInvitesSteps(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}fleetassist/licensedownerproviderinvites/get`, data);
  }

  // update profile invite steps
  updateProfileInviteSteps(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites`, data);
  }

  /**
   * API to get licensed owner counter value to update provider ID
   * @param data
   */
  getLicensedOwnerCounterValue(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getCounterDetails`, data);
  }

  /**
  * API to update licensed owner counter value with latest provider ID
  * @param data
  */
  updateLicensedOwnerCounterValue(data): Observable<any> {
    return this.http.put<any>(`${environment.baseUrl}updateCounterDetails`, data);
  }

  // get licensed owner module subscriptions
  getLicensedOwnerModuleSubscriptions(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownermodulesubscriptions/get`, data);
  }

  // insert provider account batch
  addProviderAccountBatch(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts/batch`, data);
  }

}
