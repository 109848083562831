import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bubble-user-information-index',
  templateUrl: './bubble-user-information-index.component.html',
  styleUrls: ['./bubble-user-information-index.component.scss']
})
export class BubbleUserInformationIndexComponent implements OnInit {

  constructor() { }

  @Input() showProtocolIndexs = false;
  @Input() showThresholdIndexs = false;
  @Input() isCustomProtocol = false;
  @Input() showRemoveService = true;
  @Input() isCapability = false;
  @Input() showOfferingQuestionIndexes = false;
  addServiceLebel = 'Add Service';
  removeServiceLebel = 'Remove Service';

  ngOnInit() {
    if (this.isCapability) {
      this.addServiceLebel = 'Add';
      this.removeServiceLebel = 'Remove';
    }
  }

}
