<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="row">
    <div class="col-md-6 px-1">
        <label>
            <span class="font-weight-bold">
                Status:
            </span>
            {{isActive?'Active' : 'Not Active'}}
        </label>
    </div>
    <div class="col-md-6 text-right">
        <button class="btn btn-primary btn-sm mt-2" (click)="activeDeactiveTechnician()">{{isActive ? 'Deactivate' :
            'Reactivate'}}</button>
    </div>
</div>

<div class="row px-1">
    <label class="font-weight-bold" for="header">General Information</label>
</div>

<form [formGroup]="technicianForm">
    <div class="row">
        <div class="col-md-3 pl-0">
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Cell Phone</mat-label>
                <input matInput formControlName="cellPhone" mask="000-000-0000">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>
        </div>
    </div>

    <div class="row px-1">
        <label class="font-weight-bold" for="header">Skill(s) & Expertise Level</label>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div formArrayName="skills">
                <div class="row" *ngFor="let skill of aliasesArrayControl; let i = index" [formGroupName]="i">
                    <div class="col-md-3">
                        <mat-form-field *ngIf="skills?.length" appearance="outline">
                            <mat-label>Skills</mat-label>
                            <mat-select formControlName="skill">
                                <mat-option *ngFor="let skill of skills" [value]="skill.skillKey">
                                    {{skill.skillKey}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Expertise: 1-Low, 5-High</mat-label>
                            <mat-select formControlName="expertiseLevel">
                                <mat-option *ngFor="let level of expertiseLevel" [value]="level">
                                    {{level}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3 mt-3">
                        <button class="float-left clear-btn" mat-icon-button aria-label="Remove/clear"
                            (click)="removeField(i)" matTooltip="Remove">
                            <mat-icon>highlight_off</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pl-2">
            <button type="button" class="btn btn-link link-color font-weight-bold" (click)="addSkills()">+ Add
                Additional Skills</button>
        </div>
    </div>
    <div class="row float-right mr-2">
        <button type="button" class="btn btn-primary btn-md mb-2 float-right"
            (click)="addUpdateProviderTechnicians()">Save</button>
    </div>
</form>