import { Deserializable } from '../pages/provider-match/models/deserializable.model';

export class UserExperiance implements Deserializable {
    associationKey: string;
    deleted: boolean;
    insertedTimestamp: string;
    insertedUserKey: string;
    isApprover: boolean;
    isEmergencyApprover: boolean;
    subDomainKey: string;
    systemDefault: boolean;
    updatedTimestamp: string;
    updatedUserKey: string;
    userExperianceKey: string;
    userKey: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    prepareSave(data: any): any {
        this.deserialize(data);
        this.systemDefault = false;
        this.deleted = false;
        return this;
      }
}

