import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TechnicianSkills } from '../models/technician-skills.model';
import { Technician } from '../models/technician.model';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  constructor(
    private http: HttpClient
  ) { }

  // get skills
  getSkills(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/skills/get`, data).pipe(
      map((res: any) => res.body.map((technicianSkills: TechnicianSkills) => new TechnicianSkills().deserialize(technicianSkills))));
  }

  // get provider technicians
  getTechnicians(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}api/providerTechniciansSelect`, data).pipe(
      map((res: any) => res.body.map((technician: Technician) => new Technician().deserialize(technician))));
  }

  // add provider technicians
  addProviderTechnicians(data: any): Observable<any> {
    // return this.http.post<any>(`${environment.apiUrl}serviceplus/providertechnicians`, data)
    return this.http.post<any>(`${environment.baseUrl}api/providerTechniciansInsert`, data);
  }

  // update provider technicians
  updateProviderTechnicians(data: any): Observable<any> {
    // return this.http.put<any>(`${environment.apiUrl}serviceplus/providertechnicians`, data);
    return this.http.put<any>(`${environment.baseUrl}api/providerTechniciansUpdate`, data);
  }
  
  // get provider technician skills
  getProviderTechnicianSkills(data: any = {}):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providertechnicianskills/get`, data)
  }

  // add provider technician skills
  addProviderTechnicianSkills(data: any = {}):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providertechnicianskills`, data)
  }

  // update provider technician skills
  updateProviderTechnicianSkills(data: any = {}):Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providertechnicianskills`, data)
  }
 deleteProviderTechnicianSkills(data: any = {}):Observable<any> {
  const deleteTechnicalSkill: any = {
    body: { providerTechnicianSkillKey: data }
  };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providertechnicianskills`, deleteTechnicalSkill)
  }
}
