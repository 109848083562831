<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body service-template">
    <div class="row">
        <div class="col-md-2 px-0">
            <p class="text-danger">Drag and Drop Selected Form</p>
            <ng-container *ngFor="let item of fieldModels" class="pt-1">
                <a *ngIf="item.category==0" [dndDraggable]="item" [dndEffectAllowed]="'copy'"
                    (dndEnd)="onDragEnd($event)" class="btn btn-block btn-social position-relative text-left border">
                    <span [ngClass]="item.icon" class="fa position-absolute"></span>
                    <span class="ml-4">{{item.label}}</span>
                </a>
            </ng-container>
        </div>
        <div class="col-md-10">
            <form role="form" [formGroup]="customForm" class="border p-3">
                <div class="row" *ngIf="arrFieldModels.length > 0">
                    <div class="col-12">
                        <ng-container *ngFor="let item of arrFieldModels; let i= index;">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div *ngIf="item.type=='text'">
                                        <div class="row">
                                            <div class="col-10">
                                                <mat-form-field>
                                                    <input matInput type="text" [value]="item.value" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(item, item.id)">
                                                    <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.type=='email'">
                                        <div class="row">
                                            <div class="col-10">
                                                <mat-form-field>
                                                    <input matInput type="email" [value]="item.value" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(item, item.id)">
                                                    <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.type=='phone'">
                                        <div class="row">
                                            <div class="col-10">
                                            <mat-form-field>
                                                <input matInput type="email" [value]="item.value" id="{{item.name}}"
                                                    placeholder="{{item.placeholder}}">
                                            </mat-form-field>
                                        </div>
                                            <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(item, item.id)">
                                                    <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.type=='number'">
                                        <div class="row">
                                            <div class="col-10">
                                            <mat-form-field>
                                                <input matInput type="number" [value]="item.value" id="{{item.name}}"
                                                    min="{{item.min}}" max="{{item.max}}"
                                                    placeholder="{{item.placeholder}}">
                                            </mat-form-field>
                                        </div>
                                            <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(item, item.id)">
                                                    <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.type=='select'">
                                        <div class="row">
                                            <div class="col-10">
                                            <mat-form-field>
                                                <mat-select [placeholder]="item.placeholder">
                                                    <mat-option disabled>Select Option</mat-option>
                                                    <mat-option *ngFor="let data of item.items" [value]="data.id">
                                                        {{data.text}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                            <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(item, item.id)">
                                                    <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.type=='textarea'">
                                        <div class="row">
                                            <div class="col-10">
                                            <mat-form-field class="example-full-width">
                                                <textarea matInput id="{{item.name}}"
                                                    placeholder="{{item.placeholder}}">{{item.value}}</textarea>
                                            </mat-form-field>
                                        </div>
                                            <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(item, item.id)">
                                                    <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                        <div *ngIf="item.type=='checkbox'">
                                        <div class="row">
                                            <div class="col-12">
                                            <label class="form-check-label">
                                                {{item.placeholder}}
                                            </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-10">
                                                <mat-checkbox *ngFor="let v of item.listitems" class="example-margin" [value]="v.id"
                                                name="{{v.text}}" (click)="toggleValue(v)">{{v.text}}
                                            </mat-checkbox>        
                                        </div>
                                        <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                                                    <span class="fa fa-trash"
                                                        style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog"
                                                        style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                        <div *ngIf="item.type=='radio'">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="form-check-label">
                                                    {{item.placeholder}}
                                                    <span *ngIf="item.required == 'true'" class="text-danger">*</span>
                                            </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-10">
                                                <div *ngFor="let v of item.listitems" class="form-check-inline">
                                                <label class="form-check-label">
                                                        <input class="form-check-input" type="radio" [value]="v.id" name="{{item.text}}">
                                                        {{v.text}}
                                                        <span class="checkmark"></span>
                                                </label>
                                                </div>
                                            </div>
                                            <div class="col-2 setting-icons m-auto">
                                                <a class="btn btn-social-icon" (click)="removeField(item, item.id)">
                                                    <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                                <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                                    <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div dndDropzone (dndDragover)="onDragover($event)" (dndDrop)="onDrop($event, model.fields)"
                            class="dnd-drop-area box-body" [ngClass]="{'empty':model.fields.length == 0}"
                            [style.backgroundColor]="model.theme.bgColor" [style.color]="model.theme.textColor">
                            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ $implicit: model.fields }">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ng-template #recursiveListTmpl let-list>
        <div class="row">
            <div class="col-12" [id]="item.id" *ngFor="let item of list; let i = index;"
                (dndStart)="onDragStart($event)" (dndCanceled)="onDragCanceled($event)"
                (dndMoved)="onDragged(item, list, 'move')" [dndEffectAllowed]="'all'" [dndDraggable]="item">
                <div class="form-group">
                    <div *ngIf="item.type == 'text'">
                <div class="row">
                    <div class="col-10">
                        <mat-form-field>
                            <input matInput type="text" [value]="item.value" id="{{item.name}}"
                                placeholder="{{item.placeholder}}">
                        </mat-form-field>
                    </div>
                    <div class="col-2 setting-icons m-auto">
                            <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                                <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                            <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                        </div>
                </div>
            </div>
            <div *ngIf="item.type=='email'">
                <div class="row">
                    <div class="col-10">
                        <mat-form-field>
                            <input matInput type="email" [value]="item.value" id="{{item.name}}"
                                placeholder="{{item.placeholder}}">
                        </mat-form-field>
                    </div>
                    <div class="col-2 setting-icons m-auto">
                            <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                                <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                            <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                        </div>
                </div>
            </div>
            <div *ngIf="item.type=='phone'">
                <div class="row">
                    <div class="col-10">
                        <mat-form-field>
                            <input matInput type="text" [value]="item.value" id="{{item.name}}"
                                placeholder="{{item.placeholder}}">
                        </mat-form-field>
                    </div>
                    <div class="col-2 setting-icons m-auto">
                            <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                                <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                            <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                        </div>
                </div>
            </div>
            <div *ngIf="item.type=='number'">
                <div class="row">
                    <div class="col-10">
                        <mat-form-field>
                            <input matInput type="number" [value]="item.value" id="{{item.name}}" min="{{item.min}}"
                                max="{{item.max}}" placeholder="{{item.placeholder}}">
                        </mat-form-field>
                    </div>
                    <div class="col-2 setting-icons m-auto">
                            <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                                <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                            <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                        </div>
                </div>
            </div>
            <div *ngIf="item.type=='select'">
                <div class="row">
                    <div class="col-10">
                        <mat-form-field>
                            <mat-select [placeholder]="item.placeholder">
                                <mat-option disabled>Select Option</mat-option>
                                <mat-option *ngFor="let data of item.items" [value]="data.id">
                                    {{data.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 setting-icons m-auto">
                            <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                                <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                            <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                        </div>
                </div>
            </div>
            <div *ngIf="item.type=='textarea'">
                <div class="row">
                    <div class="col-10">
                        <mat-form-field class="example-full-width">
                            <textarea matInput id="{{item.name}}"
                                placeholder="{{item.placeholder}}">{{item.value}}</textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-2 setting-icons m-auto">
                        <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                            <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                        </a>
                        <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                            <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                        </a>
                    </div>
                </div>
            </div>
                    <div *ngIf="item.type=='checkbox'">
                <div class="row">
                    <div class="col-12">
                            <label class="form-check-label">
                                {{item.placeholder}}
                            </label>
                        </div>
                </div>
                <div class="row">
                    <div class="col-10">
                        <mat-checkbox *ngFor="let v of item.listitems" class="example-margin" [value]="v.id"
                        name="{{v.text}}" (click)="toggleValue(v)">{{v.text}}
                    </mat-checkbox>        
                </div>
                <div class="col-2 setting-icons m-auto">
                            <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                                <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                            <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                                <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                            </a>
                        </div>
            </div>
                    </div>
                    <div *ngIf="item.type=='radio'">
            <div class="row">
                <div class="col-12">
                            <label class="form-check-label">
                                {{item.placeholder}}
                                <span *ngIf="item.required == 'true'" class="text-danger">*</span>
                            </label>
                            </div>
                        </div>
            <div class="row">
                <div class="col-10">
                        <div *ngFor="let v of item.listitems" class="form-check-inline">
                            <label class="form-check-label">
                                <input class="form-check-input" type="radio" [value]="v.id" name="{{item.text}}">
                                {{v.text}}
                                <span class="checkmark"></span>
                            </label>
                    </div>
                </div>
                <div class="col-2 setting-icons m-auto">
                    <a class="btn btn-social-icon" (click)="removeField(i, item.id)">
                        <span class="fa fa-trash" style="line-height: 24px; font-size: 0.8em;"></span>
                    </a>
                    <a class="btn btn-social-icon" (click)="openSettingsForm(item)">
                        <span class="fa fa-cog" style="line-height: 24px; font-size: 0.8em;"></span>
                    </a>
                </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="dnd-placeholder" dndPlaceholderRef>
        </div>
    </ng-template>
</div>
<div class="modal-footer text-right">
    <button type="submit" class="btn btn-primary float-right btn-sm"
        (click)="submitFormData(model.fields)">Submit</button>
</div>
