import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddSkillsLevelExpertiseDailogComponent } from '../add-skills-level-expertise-dailog/add-skills-level-expertise-dailog.component';
import { UserService } from 'src/app/services/user.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';

@Component({
  selector: 'ngx-skills-level-expertise-dailog',
  templateUrl: './skills-level-expertise-dailog.component.html',
  styleUrls: ['./skills-level-expertise-dailog.component.scss']
})
export class SkillsLevelExpertiseDailogComponent implements OnInit {

  @Input() userDetails: any;
  loading: boolean = false;
  skillsData: any = [];
  userKey: string = '';

  constructor(
    private dialog: MatDialog,
    public dialogs: MatDialogRef<SkillsLevelExpertiseDailogComponent>,
    private userService: UserService,
    private dialogService: NbDialogService,
    private toast: NbToastrService) {}

  ngOnInit() {
    this.userKey = (this.userDetails.userKey) ? this.userDetails.userKey : sessionStorage.getItem('userKey');
    this.getUserSkills();
  }

  // get user skills
  getUserSkills() {

    this.loading = true;
    const postParams = {
      userKey: this.userKey,
      deleted: 0,
    };
    this.userService.getUserSkills(postParams).subscribe(response => {
      this.loading = false;
      this.skillsData = response.body;
    }, (error) => {
      this.loading = false;
      this.toast.danger(error, 'Error');
    });
  }

  /***
    * Desc: open add user skills dialog
    * @params postParams
    * @return result success
  */
  addUserSkills(value: any) {

    const data = { value, edit: false, userKey: this.userKey };
    const dialogRef = this.dialog.open(AddSkillsLevelExpertiseDailogComponent, {
      width: '30%',
      position: {
        top: '5%',
      },
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserSkills();
    });
  }

  /***
    * Desc: open edit user skills dialog
    * @params postParams
    * @return result success
  */
  editUserSkills(value: any) {

    const data = { value, edit: true };
    const dialogRef = this.dialog.open(AddSkillsLevelExpertiseDailogComponent, {
      width: '30%',
      position: {
        top: '5%',
      },
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserSkills();
    });
  }

  /***
    * Desc: remove user skills
    * @params postParams
    * @return result success
  */
  removeUserSkills(event: any) {

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        this.loading = true;
        const updateData = {
          userSkillKey: event.userSkillKey,
          userKey: event.userKey,
          updatedTimestamp: '',
          updatedUserKey: event.userKey,
          deleted: true,
        };
        this.userService.updateUserSkills(updateData).subscribe(res => {
          this.toast.success('User Skills deleted SuccessFully!', 'Success');
          this.getUserSkills();
          this.loading = false;
        }, (error) => {
          this.loading = false;
          this.toast.danger(error, 'Error');
        });
      }
    });
  }

  closeDialog() {
    this.dialogs.close();
  }

}
