<div class="container">
    <div *ngIf="loading" id="preloader">
        <div id="loader"></div>
    </div>
    <div class="row mt-2">
        <div class="col col-md-4">
            <ngx-search-input (search)="search($event)"></ngx-search-input>
        </div>
        <div class="col col-md-8 text-right">
            <button class="btn btn-primary btn-sm" [disabled]="(readOnlyUser && isCustomerUser) || disableEdit" (click)="addNewSecurityGroup()">Add
                New</button>
        </div>
    </div>
    <div class="table-responsive scecurity-group-tbl">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th sortable="description" class="align-middle" (sort)="onSort($event)" direction="asc">Security
                        Group</th>
                    <th sortable="securityLevel" class="align-middle" (sort)="onSort($event)">Security Level</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="isAddRow" [formGroup]="userSecurityGroupForm">
                    <td colspan="" class="form-group">
                        <mat-form-field>
                            <mat-select formControlName="securityGroupKey" required placeholder="Select Security Group"
                                (selectionChange)="getSelectedSecurityLevel($event)">
                                <mat-option *ngFor="let data of securityGroups | orderBy : 'description'"
                                    [value]="data.description">
                                    {{data.description}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="handleError('securityGroupKey', 'required')">
                                Security Group is required
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td class="form-group">
                        <mat-form-field>
                            <input matInput formControlName="securityLevel" placeholder="SecurityLevel"
                                autocomplete="off">
                        </mat-form-field>
                    </td>
                    <td class="form-group">
                        <span>
                            <button class="btn btn-default btn-sm btnAnimation" nbTooltip="Add"
                                (click)="addNewSecurityGroupDetails()">
                                <i class="fas fa-check"></i>
                            </button>
                            <button class="btn btn-default btn-sm btnAnimation" (click)="cancelAddSecurityGroup()"
                                nbTooltip="Cancel">
                                <i class="fas fa-times"></i>
                            </button>
                        </span>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="userSecurityGroups.length > 0; else noData">
                <tr *ngFor="let data of userSecurityGroups | filter : searchString; let i = index;" [formGroup]="userSecurityGroupForm">
                    <td>
                        <span *ngIf="!(data.editMode)">{{data.description}}</span>
                        <span *ngIf="data.editMode">
                            <mat-form-field>
                                <mat-select formControlName="securityGroupKey" [(ngModel)]="data.description" required placeholder="Select Security Group"
                                    (selectionChange)="getSelectedSecurityLevel($event)">
                                    <mat-option *ngFor="let securityGroup of securityGroups | orderBy : 'description'"
                                        [value]="securityGroup.description">
                                        {{securityGroup.description}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="handleError('securityGroupKey', 'required')">
                                    Security Group is required
                                </mat-error>
                            </mat-form-field>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!(data.editMode)">{{data.securityLevel}}</span>
                        <span *ngIf="data.editMode">
                            <mat-form-field>
                                <input matInput [(ngModel)]="data.securityLevel" formControlName="securityLevel" placeholder="SecurityLevel"
                                autocomplete="off">
                            </mat-form-field>
                        </span>
                    </td>
                    <td *ngIf="isCustomerUser">
                        <button *ngIf="!(data.editMode)" class="btn btn-default btnAnimation" nbTooltip="Edit"
                            (click)="editSecurityGroup(i)" [disabled]="disableEdit">
                            <i class="fas fa-pencil"></i>
                        </button>
                        <button *ngIf="data.editMode" class="btn btn-default btnAnimation" nbTooltip="Update"
                            (click)="updateSecurityGroup(data)">
                            <i class="fas fa-check"></i>
                        </button>
                        <button *ngIf="data.editMode" class="btn btn-default btnAnimation"
                            (click)="cancelSecurityGroup(i)" nbTooltip="Cancel">
                            <i class="fas fa-times"></i>
                        </button>
                    </td>
                    <td *ngIf="!isCustomerUser">
                        <button *ngIf="!(data.editMode)" class="btn btn-default btnAnimation" nbTooltip="Delete"
                            (click)="deleteUserSecurityGroup(data)" [disabled]="disableEdit">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <ng-template #noData>
                <tbody *ngIf="!isAddRow">
                    <tr>
                        <td colspan="3" class="text-center">No Data Available</td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
</div>