import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';

@Component({
  selector: 'app-provider-admin-list',
  templateUrl: './provider-admin-list.component.html',
  styleUrls: ['./provider-admin-list.component.scss']
})
export class ProviderAdminListComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  providersList: any = [];
  providersList2: any = [];
  loading: boolean;
  searchCompleted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ProviderAdminListComponent>,
    @Inject(MAT_DIALOG_DATA) public searchText: any,
    @Inject(MAT_DIALOG_DATA) public searchData: any,
    private utilityService: UtilityService,
    private providerAdminService: ProviderAdminService
  ) { }

  ngOnInit(): void {
    //this.getProvidersAdminList(); 
    this.getProviderParentLocations();
  }

  getProviderParentLocations(){
    const data2 = this.searchData
    this.providerAdminService.getProviderParentLocations(data2).subscribe({
      next: res => {
        this.providersList2 = res.body,
        this.onSort({ column: 'Name', direction: 'asc' });
      },
      error: (e) => console.log(e),
      complete: () => this.searchCompleted = true,
     }
  )
  }
  getProvidersAdminList() {
    const data = {
      searchText: this.searchText,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      location: false,
      parentKey: ''
    };

    this.loading = true;
    this.providerAdminService.getProviderAdminList(data).subscribe(result => {
      this.loading = false;
      this.providersList = result.body;
      this.onSort({ column: 'providerName', direction: 'asc' });
    }, (error) => {
      this.loading = false;
      console.log('error,', error);
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.providersList2 = this.utilityService.sortData(this.headers, column, direction, this.providersList2);
  }

  // onSort({ column, direction }: SortEvent) {
  //   this.providersList = this.utilityService.sortData(this.headers, column, direction, this.providersList);
  // }

  

  selectProvider(data: any) {
    this.dialogRef.close(data);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
