import { Component, OnInit, Input, OnChanges, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { UsersService } from 'src/app/services/users.service';
import { Notes } from 'src/app/models/notes.model';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AddNotesDialogComponent } from 'src/app/dialogs/add-notes-dialog/add-notes-dialog.component';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { LocalDataSource } from 'ngx-smart-table';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component'
import { AddAttachmentsDialogComponent } from 'src/app/dialogs/add-attachments-dialog/add-attachments-dialog.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-provider-admin-notes',
  templateUrl: './provider-admin-notes.component.html',
  styleUrls: ['./provider-admin-notes.component.scss']
})
export class ProviderAdminNotesComponent implements OnInit, OnChanges {

  @Input() providerDetails;
 // @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  loading = false;
  notes: any = [];
  users: any = [];
  settings: any;

  notesData: any = [];
  userName: any;
  arrowDirection: any;

  filesData: any = [];

  @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['insertedTimestamp','note', 'userName', 'action'];

  constructor(
    private providerService: ProviderAdminService,
    private usersService: UsersService,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private toast: NbToastrService,
    private dialogService: NbDialogService,
    private datePipe: DatePipe,
    private commmonService: CommonService
  ) { }

  ngOnInit() {
    // this.settings = {
    //   pager: {
    //     display: true,
    //     perPage: 10,
    //   },
    //   columns: {
    //     insertedTimestamp: {
    //       title: 'Date/Time'
    //     },
    //     note: {
    //       title: 'Note'
    //     },
    //     userName: {
    //       title: 'Added By'
    //     },
    //     privateForAccount: {
    //       title: 'Public'
    //     },
    //   },
    //   actions: {
    //     add: false,
    //     edit: false,
    //     select: false,
    //   },
    //   delete: {
    //     deleteButtonContent: '<i class="fa fa-trash-alt fa-lg" aria-hidden="true"></i>',
    //     confirmDelete: true
    //   },
    // };
  }

  ngOnChanges() {
    if (this.providerDetails.providerAccountKey) {
      this.getUsers();
    }
    else if (this.providerDetails.ProviderAccountKey) {
      this.providerDetails.providerAccountKey = this.providerDetails.ProviderAccountKey;
      this.getUsers();
    }
    this.getAttachments();
  }

  getUsers() {
    const filter = { deleted: false };
    this.loading = true;
    this.usersService.getUsers(filter).subscribe(results => {
      this.loading = false;
      this.users = results.body || [];
      this.getNotes();
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getNotes() {
    this.notes = [];
    const filter = {
      providerAccountKey: this.providerDetails.providerAccountKey,
      // privateForProvider: false,
      deleted: false
    };
    this.loading = true;
    this.providerService.getProviderAccountNotes(filter).subscribe(result => {
      this.loading = false;
      result.body.forEach(element => {
        if (this.users.find(a => a.userKey === element.insertedUserKey)) {
          this.notes.push(new Notes(element, this.users));
        }
      });
      //this.onSort({ column: 'insertedTimestamp', direction: 'desc' });
      //this.notes.map(obj => obj.insertedTimestamp = this.datePipe.transform(obj.insertedTimestamp, 'MMM dd, yyyy HH:mm'));
      this.notes = new LocalDataSource(this.notes);
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

    this.notesData = [];
    this.providerService.getProviderAccountNotes(filter).subscribe({
      next: res => this.notesData = res.body,
      error: (e) => console.error(e),
      complete: () => {
     //   this.notesData.map(obj => obj.insertedTimestamp = this.datePipe.transform(obj.insertedTimestamp, 'MMM dd, yyyy HH:mm'));

        this.notesData.forEach(element => {
          if (this.users.find(a => a.userKey === element.insertedUserKey)) {
            const user = this.users.filter(item => item.userKey === element.insertedUserKey);
            this.userName = user[0].firstName + ' ' + user[0].lastName;
            element.userName = this.userName
          }
        });
        //this.onSort({ column: 'insertedTimestamp', direction: 'desc' });
        this.dataSource = new MatTableDataSource(this.notesData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log(this.notesData)}
    })

  }

  addNotes() {
    const dialogRef = this.dialog.open(AddNotesDialogComponent, {
      width: '50%',
      disableClose: true,
      // position: {
      //   top: '4%'
      // },
      data: {
        providerAccountKey: this.providerDetails.providerAccountKey,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getUsers();
    });
  }

  deleteNote(note) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const deleteData = {
          providerAccountNoteKey: note.data.providerAccountNoteKey 
        };
        if (!!note.data.providerAccountNoteKey) {
          this.loading = true;
          this.providerService.deleteProviderAccountNotes(deleteData).subscribe(result => {
            this.loading = false;
            if (result.success) {
              this.getUsers();
              this.toast.success(result.message[0], 'Success');
            } else {
              this.toast.warning(result.message[0], 'Error');
            }
          }, (error) => {
            console.log(error);
            this.loading = false;
          });
        }
      }
    });
  }

  deleteNote2(note) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: {header: 'Delete Note', message: 'Are you sure you want to delete this note?',
      yesButton: 'Yes'
    }
  });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if(result == true){
        const deleteData = {
          providerAccountNoteKey: note.providerAccountNoteKey 
        };
        if (!!note.providerAccountNoteKey) {
          this.loading = true;
          this.providerService.deleteProviderAccountNotes(deleteData).subscribe(result => {
            this.loading = false;
            if (result.success) {
              this.getUsers();
              this.toast.success(result.message[0], 'Success');
            } else {
              this.toast.warning(result.message[0], 'Error');
            }
          }, (error) => {
            console.log(error);
            this.loading = false;
          });
      }}
    })
  }

  addAttachment(){
    let dialogRef = this.dialog.open(AddAttachmentsDialogComponent, {
      data: {header: 'Delete Note', message: 'Are you sure you want to delete this note?', ProviderId: this.providerDetails.ProviderId,
        yesButton: 'Yes'
      }
    });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        console.log('The dialog was closed');
    })
  }

  getAttachments(){
    this.commmonService.SelectProviderAttachmentsForServicePlus(this.providerDetails.ProviderId).subscribe({
      next: res => {
        this.filesData = res.body;
        console.log(this.filesData)
        //this.onSort({ column: 'Name', direction: 'asc' });
      },
      error: (e) => console.log(e),
      complete: () =>  console.log('done' ), //this.searchCompleted = true,
     }
  )
  }

  deleteAttachments(files){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: {header: 'Delete Attachment', message: 'Are you sure you want to delete this attachment?',
      yesButton: 'Yes'
    }
  });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result)
      if(result == true){
        const deleteAttachmentData = {
          inPATlKey: files.PATlKey 
        };
        this.commmonService.deleteProviderAttachmentsFromServicePlus(files.PATlKey).subscribe({
          next: res => console.log(res),
          error: (e) => console.log(e),
          complete: () => this.getAttachments()
        })
   }})
  }

  // onSort({ column, direction }: SortEvent) {
  //   this.notesData = this.utilityService.sortData(this.headers, column, direction, this.notesData);
  //   this.arrowDirection = direction;
  // }

}
