import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'ngx-employee-availability',
  templateUrl: './employee-availability.component.html',
  styleUrls: ['./employee-availability.component.scss']
})
export class EmployeeAvailabilityComponent implements OnInit {

  @Input() userDetails: any;
  loading = false;
  editAvailabilityFlag = false;
  weekData: any;
  startTime: any;
  endTime: any;
  userHoursKey: any;
  //minDate = new Date();
  availabilityForm: UntypedFormGroup;
  arrDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  userKey: string;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  constructor(
    private userService: UserService,
    private toast: NbToastrService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {

    this.userKey = (this.userDetails.userKey) ? this.userDetails.userKey : sessionStorage.getItem('userKey');
    this.availabilityForm = this.formBuilder.group({
      available: [''],
      outOfOffice: [''],
      outOfOfficeExpDate: [''],
    });
    this.getUserAvailabilityHours();
  }

  /***
   * Desc: Get user availability hours
   * @params postParams
   * @return result array
   */
  getUserAvailabilityHours() {

    this.loading = true;
    this.weekData = [];
    const postParams = {
      userKey: this.userKey,
    };
    this.userService.getUserAvailabilityHours(postParams).subscribe((res: any) => {
      this.weekData = res.body;
      if (this.weekData.length === 0 || this.weekData.length === 1) {
        this.addDefaultHours();
      } else {
        // if (this.weekData[0].available24Hours === true) {
        //   this.availabilityForm.controls.available.setValue(true);
        // } else {
        //   this.availabilityForm.controls.available.setValue(false);
        // }
        this.weekData.map(date => {
          date.startTime = date.startTime.slice(0, 5);
          date.endTime = date.endTime.slice(0, 5);
          return date;
        });
        const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };
        this.weekData.sort(function (a, b) {
          return order[a.daysOfWeekKey] - order[b.daysOfWeekKey];
        });
        // console.log('gg', this.availabilityForm.controls.available.value);
        // if (this.availabilityForm.controls.available.value === false) {
        //   this.weekData.map(date => {
        //     date.startTime = moment(date.startTime, 'HH:mm').format('hh:mm A');
        //     date.endTime = moment(date.endTime, 'HH:mm').format('hh:mm A');
        //   });
        // }
      }
      this.loading = false;
    }, (error) => {
      this.toast.danger(error.message[0], 'Error');
      this.loading = false;
    });
  }

  /***
   * Desc: Add user availability hours
   * @params postParams
   * @return result success
  */
  addDefaultHours() {
    const operationData = [];
    this.arrDays.forEach(day => {
      operationData.push({
        // available24Hours: true,
        daysOfWeekKey: day,
        deleted: false,
        endTime: '8:00:00',
        insertedUserKey: this.userKey,
        startTime: '8:00:00',
        systemDefault: false,
        updatedUserKey: '',
        userKey: this.userKey,
      });
    });

    this.loading = true;
    this.userService.addAvailabilityHoursBatch(operationData).subscribe(result => {
      this.loading = false;
      this.getUserAvailabilityHours();
    }, (error) => {
      this.loading = false;
      this.toast.danger(error, 'Error');
    });
  }

  /***
   * Desc: Update user availability hours
   * @params postParams
   * @return result success
  */
  updateUserAvailabilityHours(item: any) {
    this.loading = true;
    const updatePostPrams = {
      startTime: item.startTime.slice(0, 5) + ':00',
      endTime: item.endTime.slice(0, 5) + ':00',
      userHoursKey: item.userHoursKey,
      userKey: this.userKey,
      updatedUserKey: this.userKey,
    };

    this.userService.updateUserAvailabilityHours(updatePostPrams).subscribe((result: any) => {
      this.editAvailabilityFlag = !updatePostPrams;
      this.toast.success('User Availability time updated Successfully!', 'Success');
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.toast.danger(error.message[0], 'Error');
    });
  }

  /***
   * Desc: Update user availability hours
   * @params postParams
   * @return result success
  */
  hoursAvailabilityOnChange(event: any) {
    this.loading = true;
    const updateReq = [];
    this.weekData.forEach(element => {
      const updateData = {
        userHoursKey: element.userHoursKey,
        // available24Hours: event.checked,
        updatedUserKey: this.userKey,
      };
      updateReq.push(this.userService.updateUserAvailabilityHours(updateData));
    });

    forkJoin(updateReq).subscribe(result => {
      this.loading = false;
      this.toast.success('User hours updated Successfully!', 'Success');
    }, (error) => {
      this.toast.danger(error, 'Error');
      this.loading = false;
    });
  }

  /***
   * Desc: edit availbility hours
   * @params item
   * @return result success
  */
  editAvailabilityHours(item: any) {
    this.editAvailabilityFlag = item.daysOfWeekKey;
  }

  /***
   * Desc: cancel edit availbility hours operation
   * @params item
   * @return result
  */
  closeDayData(daysOfWeekKey: any) {
    this.editAvailabilityFlag = !daysOfWeekKey;
  }

}
