<nb-layout class="welcomeScreen">
    <nb-layout-column>
        <div class="container">
            <div class="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="text"></div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>