import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbToastrService, NbDialogService, NbComponentStatus } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { SystemSecuruityMenuService } from 'src/app/services/system-securuity-menu.service';
import { SortableHeaderDirective } from '../directive/sortable.directive';

@Component({
  selector: 'ngx-bubble-tree-security-group',
  templateUrl: './bubble-tree-security-group.component.html',
  styleUrls: ['./bubble-tree-security-group.component.scss']
})
export class BubbleTreeSecurityGroupComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private toastrService: NbToastrService,
    private systemSecurityService: SystemSecuruityMenuService,
    private dialogService: NbDialogService) { }

  // static members
  public static treeRecursiveOperationException = false;

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  // Input data to manipulate bubble tree data and features
  @Input() levelData: any; // Input data
  @Input() parentKey: any; // Input data
  @Input() level: number; // Level of bubble tree
  @Input() isActiveNode: boolean; // add remove active inactive checkbox
  @Input() isSelected: number; // Number of level from which nodes can be selected (If -1 then no node will have select option)
  @Input() isAllParentActive: number;
  @Input() showSelectDeselect = true;
  @Input() isParentActive = false;
  @Input() securityGroupKey: string;

  loading: boolean;
  userKey: string;
  levelKey: any = null;
  securityRoleKey: string;
  permissionDescription: string;
  isDataLoaded: boolean;
  permissionData: any = [];
  lastNode: any;

  ngOnInit() {
    this.userKey = sessionStorage.getItem('userKey');
    this.setExpandedTreeLevel();
  }

  // method to set level of tree
  setExpandedTreeLevel() {
    const expandArr = JSON.parse(sessionStorage.getItem('expandedLayer1'));
    this.level = this.level + 1;
    this.levelKey = expandArr[this.level - 1];
  }

  showNetwork(jsonObj) {

    this.parentKey = jsonObj.dynamicMenuKey;
    let expandArr = JSON.parse(sessionStorage.getItem('expandedLayer1'));
    sessionStorage.setItem('parentSelected', jsonObj.name);
    if (this.levelKey == null) {
      this.levelKey = jsonObj.dynamicMenuKey;
      expandArr.push(jsonObj.dynamicMenuKey);
    } else if (this.levelKey === jsonObj.dynamicMenuKey) {
      this.levelKey = null;
      expandArr = expandArr.slice(0, this.level - 1);
    } else {
      this.levelKey = jsonObj.dynamicMenuKey;
      if (this.level > expandArr.length) {
        expandArr.push(jsonObj.dynamicMenuKey);
      } else {
        expandArr = expandArr.slice(0, this.level - 1);
        expandArr.push(jsonObj.dynamicMenuKey);
      }
    }
    sessionStorage.setItem('expandedLayer1', JSON.stringify(expandArr));
  }

  showToast(status: NbComponentStatus, message) {
    this.toastrService.show(status, message, { status });
  }

  updateMenuPermission(levelData: any, permission: string) {
    this.loading = true;
    let inOldSecurityPolicyKey = '';
    let inNewSecurityPolicyKey = '';
    this.securityRoleKey = levelData.securityRoleKey;
    const data = {
      deleted: false,
      securityRoleKey: levelData.securityRoleKey
    };
    this.systemSecurityService.getSecurityRolePolicies(data).subscribe(response => {
      if (response.body.length) {
        response.body.forEach(element => {
          if (element.securityPolicyDescription === permission) {
            inNewSecurityPolicyKey = element.securityRolePolicyKey;
          } else {
            inOldSecurityPolicyKey = element.securityRolePolicyKey;
          }
        });
        const securityRoleData = {
          oldSecurityPolicyKey: inOldSecurityPolicyKey,
          newSecurityPolicyKey: inNewSecurityPolicyKey,
          securityGroupKey: this.securityGroupKey,
          userKey: this.userKey,
        };
        this.systemSecurityService.updateUserSecurityPermission(securityRoleData).subscribe(updateResponse => {
          this.loading = false;
          if (updateResponse) {
            this.showToast('success', 'Menu security policy updated to ' + permission);
            // update child level permissions
            if (levelData.children) {
              this.addUpdateChildMenuPermissions(levelData.children, permission);
            }
          } else {
            this.showToast('danger', 'Failed to update');
          }
        }, (error) => {
          console.log(error);
          this.loading = false;
        });
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getSecurityGroupPolicies() {
    this.isDataLoaded = false;
    this.loading = true;
    this.permissionDescription = '';
    this.permissionData = [];
    const data = {
      securityGroupKey: this.securityGroupKey,
      securityRolePoliciesKey: this.securityRoleKey
    };
    this.systemSecurityService.getSecurityGroupPolicies(data).subscribe(response => {
      this.isDataLoaded = true;
      this.loading = false;
      if (response.body.length > 0) {
        this.permissionDescription = response.body[0].securityPolicyDescription;
        this.permissionData = response.body[0];
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  clearPermission(levelData: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        if (this.permissionData.securityGroupPolicyKey) {
          this.loading = true;
          const securityRoleData = {
            securityGroupPolicyKey: this.permissionData.securityGroupPolicyKey,
            securityGroupKey: this.securityGroupKey,
            updatedUserKey: sessionStorage.getItem('userKey'),
            deleted: true,
          };
          this.systemSecurityService.deleteUserSecurityPermission(securityRoleData).subscribe(updateResponse => {
            if (updateResponse) {
              this.showToast('success', 'Security Group Menu Permission deleted Successfully! ');
            } else {
              this.showToast('danger', 'Failed to update');
            }
            this.loading = false;
          }, (error) => {
            this.loading = false;
            console.log(error);
          });
        } else {
          this.showToast('danger', 'No Permissions available to delete.');
        }
      }
    });
  }

  checkSecurityGroupPermissionExists(levelData: any) {
    this.securityRoleKey = levelData.securityRoleKey;
    if (this.securityRoleKey) {
      this.getSecurityGroupPolicies();
    } else {
      this.showToast('warning', 'Security group is not assigned to this menu, Please contact Administrator');
    }
  }

  addUpdateChildMenuPermissions(levelDataArray: any, permission: string) {

    levelDataArray.map(levelData => {
      let inOldSecurityPolicyKey = '';
      let inNewSecurityPolicyKey = '';
      this.securityRoleKey = levelData.securityRoleKey;
      const objData = {
        deleted: false,
        securityRoleKey: levelData.securityRoleKey
      };
      this.systemSecurityService.getSecurityRolePolicies(objData).subscribe(response => {
        if (response.body.length) {
          response.body.forEach(element => {
            if (element.securityPolicyDescription === permission) {
              inNewSecurityPolicyKey = element.securityRolePolicyKey;
            } else {
              inOldSecurityPolicyKey = element.securityRolePolicyKey;
  }
          });
          const securityRoleData = {
            oldSecurityPolicyKey: inOldSecurityPolicyKey,
            newSecurityPolicyKey: inNewSecurityPolicyKey,
            securityGroupKey: this.securityGroupKey,
            userKey: this.userKey
          };

          this.systemSecurityService.updateUserSecurityPermission(securityRoleData).subscribe(updateResponse => {
            this.loading = false;
            if (updateResponse) {
              this.showToast('success', 'Menu security policy updated to ' + permission);
              // update child level permissions
              if (levelData.children) {
                this.addUpdateChildMenuPermissions(levelData.children, permission);
              }
            } else {
              this.showToast('danger', 'Failed to update');
            }
          }, (error) => {
            console.log(error);
            this.loading = false;
          });
        }
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    });
  }
}
