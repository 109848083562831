<div class="modal-header">
    <h6 class="modal-title">{{ title }}</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="policyDetailForm">
        <div *ngIf="loading" id="preloader">
            <div id="loader"></div>
        </div>
        <div class="row noMargin">
            <div class="col-md-4">
                <mat-form-field>
                    <mat-select formControlName="insuranceTypeKey" matNativeControl placeholder="Policy Type" readonly
                        (selectionChange)="getPolicyRequirement($event)" [disabled]="isEdit" required>
                        <mat-option *ngFor="let policyType of policyTypes | orderBy:'insuranceTypeKey'"
                            [value]="policyType.insuranceTypeKey">
                            {{policyType.insuranceTypeKey}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('insuranceTypeKey', 'required')">
                        Policy Type is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-8">
                <mat-form-field>
                    <input matInput placeholder="Policy Requirement" formControlName="policyRequirment" readonly>
                </mat-form-field>
            </div>
        </div>
        <div class="row noMargin">
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput [matDatepicker]="expDate" placeholder="Expiration Date"
                        formControlName="policyExpDate" [min]="minDate" (focus)="expDate.open()" required>
                    <mat-datepicker-toggle matSuffix [for]="expDate"></mat-datepicker-toggle>
                    <mat-datepicker #expDate></mat-datepicker>
                    <mat-error *ngIf="handleError('policyExpDate', 'required')">
                        Expiration Date is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Policy Number" formControlName="policyNumber" required>
                    <mat-error *ngIf="handleError('policyNumber', 'required')">
                        Policy Number is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('policyNumber', 'pattern')">
                        Enter valid Policy Number.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Insured Amount" formControlName="insuredAmount" required>
                    <span matPrefix *ngIf="policyDetailForm.controls.insuredAmount.value">$&nbsp;</span>
                    <mat-error *ngIf="handleError('insuredAmount', 'required')">
                        Insured Amount is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('insuredAmount', 'pattern')">
                        Enter Valid Insured Amount.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm mr-2" type="button" (click)="addNewPolicy()">Save</button>
</div>