import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { MatDialog, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { UtilityService } from 'src/app/services/utility.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';

@Component({
  selector: 'app-provider-management-billing',
  templateUrl: './provider-management-billing.component.html',
  styleUrls: ['./provider-management-billing.component.scss']
})
export class ProviderManagementBillingComponent implements OnInit {
  @Input() providerInputKey: string;
  @Input() isMasterLicensedOwner: boolean;

  billingDetailsForm: UntypedFormGroup;
  loading: boolean;
  paymentTerms: any;
  paymentMethods: any;
  billingDetails: any;
  providerKey: string;
  hasCentralBilling: number;
  isParent = false;
  getChildData: any;

  constructor(private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toast: NbToastrService,
    private utilityService: UtilityService,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<ProviderManagementBillingComponent>,
    private providerAdminService: ProviderAdminService
  ) { }

  ngOnInit() {
    this.providerKey = this.providerInputKey;

    this.billingDetailsForm = this.formBuilder.group({
    //hasCentralBilling: [{value: null, disabled: (!this.isParent)}],
      hasCentralBilling: [null],
      billingEmail: [null, Validators.pattern(patterns.emailPattern)],
      billingFax: [null, Validators.pattern(patterns.mobnumPattern)],
      primaryBillingContact: [null],
      paymentTermKey: [null],
      paymentMethodKey: [null],
      providerKey: [''],
      billingPhone: [null, Validators.pattern(patterns.mobnumPattern)]
    });

    this.getPaymentTerms();
    this.getPaymentMethods();
    this.getBillingDetails();
  }

  getPaymentTerms() {
    const paymentData = { deleted: false };
    this.loading = true;
    this.providerService.getPaymentTerms(paymentData).subscribe((result: any) => {
      this.loading = false;
      const byDay = result.body;
      byDay.sort(function (a, b) {
        a = a.paymentTermKey.split(' ')[0];
        b = b.paymentTermKey.split(' ')[0];
        return a - b;
      });
      this.paymentTerms = byDay;
    }, (error) => {
      this.loading = false;
    });
  }

  getPaymentMethods() {
    const data = { deleted: false };
    this.loading = true;
    this.providerService.getPaymentMethods(data).subscribe((result: any) => {
      this.loading = false;
      this.paymentMethods = result.body;
    }, (error) => {
      this.loading = false;
    });
  }

  showOptions() {
    this.hasCentralBilling = this.billingDetailsForm.controls.hasCentralBilling.value;
  }

  getBillingDetails() {
    const billingData = {
      providerKey: this.providerKey,
      deleted: false,
    };
    this.loading = true;
    this.providerService.getProviderDetails(billingData).subscribe((result: any) => {
      this.billingDetails = result.body[0] || [];
      this.hasCentralBilling = this.billingDetails.hasCentralBilling;

      if((this.billingDetails.billingFax != null && this.billingDetails.billingFax != '' ) && this.billingDetails.billingFax.length == 12 && this.billingDetails.billingFax.startsWith("+1")){
        this.billingDetailsForm.patchValue({
          billingFax: this.billingDetails.billingFax.slice(2)})}
      else{ this.billingDetailsForm.patchValue({
        billingFax: null })}

      if((this.billingDetails.billingPhone != null && this.billingDetails.billingPhone != '' ) && this.billingDetails.billingPhone.length == 12 && this.billingDetails.billingPhone.startsWith("+1")){
        this.billingDetailsForm.patchValue({
          billingPhone: this.billingDetails.billingPhone.slice(2)})}
      else{ this.billingDetailsForm.patchValue({
        billingPhone: null })}

      this.billingDetailsForm.patchValue({
        hasCentralBilling: this.billingDetails.hasCentralBilling == null ? 1 : this.billingDetails.hasCentralBilling,
        billingEmail: this.billingDetails.billingEmail,
        //billingFax: this.billingDetails.billingFax,
        primaryBillingContact: this.billingDetails.primaryBillingContact,
        paymentTermKey: this.billingDetails.paymentTermKey,
        paymentMethodKey: this.billingDetails.paymentMethodKey,
        providerKey: this.providerKey,
        //billingPhone: [null, Validators.pattern(patterns.mobnumPattern)],
      });

      if (this.billingDetails.parentKey && this.billingDetails.parentKey !== '0') {
        this.isParent = false;
      } else {
        this.isParent = true;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  updateProviderBillingDetails() {
    if (this.billingDetailsForm.invalid) {
      this.utilityService.validateAllFormFields(this.billingDetailsForm);
      return false;
    }

    this.hasCentralBilling = this.billingDetailsForm.controls.hasCentralBilling ? 1 : 0;

    if(this.billingDetailsForm.value.billingFax != '' && this.billingDetailsForm.value.billingFax != null && this.billingDetailsForm.value.billingFax.length == 10 ){
      this.billingDetailsForm.value.billingFax = '+1' + this.billingDetailsForm.value.billingFax
    }
    else{this.billingDetailsForm.value.billingFax = null}

    if(this.billingDetailsForm.value.billingPhone != '' && this.billingDetailsForm.value.billingPhone != null && this.billingDetailsForm.value.billingPhone.length == 10 ){
      this.billingDetailsForm.value.billingPhone = '+1' + this.billingDetailsForm.value.billingPhone
    }
    else{this.billingDetailsForm.value.billingPhone = null}

    if(this.billingDetailsForm.value.primaryBillingContact == ''){
      this.billingDetailsForm.value.primaryBillingContact = null
    }

    if(this.billingDetailsForm.value.billingEmail == ''){
      this.billingDetailsForm.value.billingEmail = null
    }

    this.loading = true;
    this.providerService.updateProviderDetails(this.billingDetailsForm.value).subscribe((result: any) => {
      this.loading = false;
      if (result.success) {
        this.toast.success(result.message[0], 'Success');
        if (this.isParent) {
          const data = {
            parentKey: this.providerKey,
            deleted: false,
          };

          this.providerService.getProviderDetails(data).subscribe((res: any) => {
            this.getChildData = res.body;
            if (this.getChildData.length > 0) {
              const childData = [];
              this.getChildData.forEach(item =>
                childData.push({
                  providerKey: item.providerKey,
                  hasCentralBilling: this.billingDetailsForm.get('hasCentralBilling').value,
                  updatedUserKey: sessionStorage.getItem('userKey'),
                }));
              this.providerAdminService.updateProviderChildDetails(childData).subscribe((res: any) => { }, (error) => {
              });
            }
          }, (error) => {
            console.info(error);
          });
        }
      } else {
        this.toast.warning(result.message[0], 'Error');
      }
    }, (error) => {
      this.loading = false;
    });
    this.dialogRef.close();
  }
}
