import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-account-insurance',
  templateUrl: './account-insurance.component.html',
  styleUrls: ['./account-insurance.component.scss']
})
export class AccountInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
