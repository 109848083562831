
<div class="row">
    <ng-container *ngFor="let data of levelData">
        <div class="p-2" [ngClass]="{'col-md-12 ml-4': data.level > 1, 'col-md-4': data.level == 1}">
            <div>
                <ng-container *ngIf="data.children != null && data.children[0] != undefined">
                    <span class="cursor-pointer" (click)="showNetwork(data)"
                        [ngClass]="{'d-none': data.key == levelKey}">
                        <i class="fa fa-plus mr-2"></i>
                    </span>
                    <span class="cursor-pointer" (click)="showNetwork(data)"
                        [ngClass]="{'d-none': data.key !== levelKey}">
                        <i class="fa fa-minus mr-2"></i>
                    </span>
                </ng-container>
                <label class="container-chk-custom"
                    [ngClass]="{'ml-4': data.children == null || data.children[0] == undefined}">
                    <input type="checkbox" *ngIf="data.isAllParentActive == 1"
                        (change)="activateInactivate(data.deleted, data)"
                        [checked]="data.deleted == 0 && !parentRecord">
                    <span class="checkmark"></span>
                </label>
                <span>{{ data.name }}</span>
            </div>

            <!-- load data if data has children -->
            <ng-container *ngIf="data.key == levelKey">
                <bubble-tree-ui [levelData]="data.children" [level]="level" [addNode]="addNode" [editNode]="editNode"
                    [delNode]="delNode" [isActiveNode]="data.deleted == 0" [disableDragDrop]="disableDragDrop"
                    [lastNode]="lastNode" [isSelected]="isSelected" [providerAccount]="providerAccount"
                    [parentRecord]="parentRecord" *ngIf="data.children != null && data.children[0] != undefined">
                </bubble-tree-ui>
            </ng-container>
        </div>
    </ng-container>
</div>

