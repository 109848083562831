import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { MatDialog, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { patterns } from 'src/assets/patterns';
import { UtilityService } from 'src/app/services/utility.service';
import { UsersService } from 'src/app/services/users.service';
import { CommonService } from 'src/app/services/common.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { forkJoin } from 'rxjs';
import { ProviderElectronicDispatchService } from 'src/app/services/provider-electronic-dispatch.service';
import { SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-provider-management-dispatch',
  templateUrl: './provider-management-dispatch.component.html',
  styleUrls: ['./provider-management-dispatch.component.scss'],
})
export class ProviderManagementDispatchComponent implements OnInit {
  @Input() providerInputKey: string;
  @Input() isMasterLicensedOwner: boolean;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  dispatchDetailsForm: UntypedFormGroup;
  loading: boolean;
  providerKey: any;
  dispatchDetails: any;
  hasCentralDispatch: number;
  dispatchStatus: any;
  isParent = true;
  isDispatchStatusEditable = false;
  dispatchSoftwares = [];
  electronicDispatchEmails = [];
  electronicDispatchPhones = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private toast: NbToastrService,
    private utilityService: UtilityService,
    private usersService: UsersService,
    public formBuilders: FormBuilder, 
    private commonService: CommonService,
    private providerService: ProviderService,
    private providerAdminService: ProviderAdminService,
    private providerElectronicDispatchService: ProviderElectronicDispatchService,
    private dialogService: NbDialogService,
    public dialogRef: MatDialogRef<ProviderManagementDispatchComponent>,

  ) { }

  ngOnInit() {
    this.providerKey = this.providerInputKey;

    this.dispatchDetailsForm = this.formBuilder.group({
      tollFreePhone: [null],
      businessHoursPhone: [null],
      afterHoursPhone: [null],
      dispatchEmail: [null, Validators.pattern(patterns.emailPattern)],
      dispatchFax: [null],
      primaryDispatchContact: [null],
      dispatchStatusKey: [''],
      hasCentralDispatch: [{ value: null, disabled: (this.isMasterLicensedOwner || !this.isParent) }],
      providerKey: [''],
      dispatchSolution: [null],
      providerDispatchSoftwareKey: [null],
      otherDispatchSoftware: [null],
      respondEmailServiceRequest: [false],
      electronicDispatchEmail: [null, Validators.pattern(patterns.emailPattern)],
      respondPhoneServiceRequest: [false],
      electronicDispatchPhone: [null]
    });

    this.initDropdownsAndOtherDetals();
  }

  handleError = (control: string, error: string) => {
    return this.dispatchDetailsForm.controls[control].hasError(error);
  }

  initDropdownsAndOtherDetals(): void {
    const userData = {
      userTypeKey: 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4',
      userKey: sessionStorage.getItem('userKey'),
      deleted: false,
    };

    let data = {
      providerKey: this.providerKey,
      deleted: false,
    }

    const userDetailsReq = this.usersService.getUsersDetails(userData);
    const dispatchStatusReq = this.commonService.getProviderDispatchStatuses({ deleted: false });
    const providerdispatchsoftwaresReq = this.providerService.getproviderdispatchsoftwares({ deleted: false });
    const providerRequest = this.providerService.getProviderDetails(data);
    const electronicEmailReq = this.providerElectronicDispatchService.getProviderElectronicDispatchEmails(
      { providerKey: this.providerKey, deleted: false });
    const electronicPhoneReq = this.providerElectronicDispatchService.getProviderElectronicDispatchPhoneNumbers(
      { providerKey: this.providerKey, deleted: false });

    this.loading = true;
    forkJoin([userDetailsReq, dispatchStatusReq, providerdispatchsoftwaresReq,
      providerRequest, electronicEmailReq, electronicPhoneReq]).subscribe((
        [userDetailsRes, dispatchStatusRes, providerdispatchsoftwaresRes, providerResponse,
          electronicEmailResponse, electronicPhoneResponse]) => {
        this.loading = false;
        if (userDetailsRes.body.length > 0) {
          this.isDispatchStatusEditable = true;
        }
        this.dispatchStatus = this.utilityService.sortData(this.headers, 'statusKey', 'asc', dispatchStatusRes.body);
        // tslint:disable-next-line: max-line-length
        this.dispatchSoftwares = this.utilityService.sortData(this.headers, 'providerDispatchSoftwareKey', 'asc', providerdispatchsoftwaresRes.body);
        this.dispatchDetails = providerResponse.body[0] || [];
        this.electronicDispatchEmails = electronicEmailResponse.body || [];
        this.electronicDispatchPhones = electronicPhoneResponse.body || [];
        this.setDispatchDetails();
      }, (error) => {
        this.loading = false;
      });
  }

  setDispatchDetails() {
    // if((this.dispatchDetailsForm.value.dispatchFax != '' || this.dispatchDetailsForm.value.dispatchFax != null) && !this.dispatchDetailsForm.value.dispatchFax.startsWith("+1") ){
    //   console.log('value does not need +1 removed.')
    // }

    if((this.dispatchDetails.dispatchFax != null && this.dispatchDetails.dispatchFax != '') && this.dispatchDetails.dispatchFax.startsWith("+1") && this.dispatchDetails.dispatchFax.length == 12){
      this.dispatchDetailsForm.patchValue({
        dispatchFax: this.dispatchDetails.dispatchFax.slice(2)})}
    else{ this.dispatchDetailsForm.patchValue({
      dispatchFax: null})}

    if((this.dispatchDetails.tollFreePhone != null && this.dispatchDetails.tollFreePhone != '') && this.dispatchDetails.tollFreePhone.startsWith("+1") && this.dispatchDetails.tollFreePhone.length == 12){
      this.dispatchDetailsForm.patchValue({
        tollFreePhone: this.dispatchDetails.tollFreePhone.slice(2)})}
    else{ this.dispatchDetailsForm.patchValue({
      tollFreePhone: null})}    
    
    if((this.dispatchDetails.afterHoursPhone != null && this.dispatchDetails.afterHoursPhone != '') && this.dispatchDetails.afterHoursPhone.startsWith("+1") && this.dispatchDetails.afterHoursPhone.length == 12){
      this.dispatchDetailsForm.patchValue({
        afterHoursPhone: this.dispatchDetails.afterHoursPhone.slice(2)})}
    else{ this.dispatchDetailsForm.patchValue({
      afterHoursPhone: null})}     

    if((this.dispatchDetails.businessHoursPhone != null && this.dispatchDetails.businessHoursPhone != '' ) && this.dispatchDetails.businessHoursPhone.startsWith("+1") && this.dispatchDetails.businessHoursPhone.length == 12){
      this.dispatchDetailsForm.patchValue({
        businessHoursPhone: this.dispatchDetails.businessHoursPhone.slice(2)})}
    else{ this.dispatchDetailsForm.patchValue({
      businessHoursPhone: null })}
    

    this.hasCentralDispatch = this.dispatchDetails.hasCentralDispatch;
    this.dispatchDetailsForm.patchValue({
      //tollFreePhone: this.dispatchDetails.tollFreePhone || '',
      //businessHoursPhone: this.dispatchDetails.businessHoursPhone || '',
      //afterHoursPhone: this.dispatchDetails.afterHoursPhone || '',
      dispatchEmail: this.dispatchDetails.dispatchEmail || null,
      //dispatchFax: this.dispatchDetails.dispatchFax || '',
      primaryDispatchContact: this.dispatchDetails.primaryDispatchContact || null,
      dispatchStatusKey: this.dispatchDetails.dispatchStatusKey ,
      hasCentralDispatch: this.dispatchDetails.hasCentralDispatch == null ? 1 : this.dispatchDetails.hasCentralDispatch,
      providerKey: this.providerKey,
      providerDispatchSoftwareKey: this.dispatchDetails.providerDispatchSoftwareKey || null,
    });
    // tslint:disable-next-line: radix
    if (this.dispatchDetails.parentKey && this.dispatchDetails.parentKey !== '0') {
      this.isParent = false;
    } else {
      this.isParent = true;
    }
    if (this.dispatchDetails.providerDispatchSoftwareKey) {
      this.dispatchDetailsForm.controls.dispatchSolution.setValue(true);
    } else if (this.electronicDispatchEmails.length > 0 || this.electronicDispatchPhones.length > 0) {
      this.dispatchDetailsForm.controls.dispatchSolution.setValue(false);
    } else {
      this.dispatchDetailsForm.controls.dispatchSolution.setValue(null);
    }

    this.dispatchDetailsForm.controls.respondEmailServiceRequest.setValue(this.electronicDispatchEmails.length > 0 ? true : false);
    this.dispatchDetailsForm.controls.respondPhoneServiceRequest.setValue(this.electronicDispatchPhones.length > 0 ? true : false);
    this.setFormValidation();
  }

  setFormValidation(): void {
    if ((this.isParent) || (!this.isParent && !this.hasCentralDispatch)) {
      this.dispatchDetailsForm.controls.businessHoursPhone.setValidators([Validators.required, Validators.minLength(9)]);
      this.dispatchDetailsForm.controls.businessHoursPhone.updateValueAndValidity();
      this.setDispatchSolutionValidation(false);
      this.setDispatchSoftwareValidation(false);
    }
  }

  showOptions() {
    this.hasCentralDispatch = this.dispatchDetailsForm.controls.hasCentralDispatch ? 1 : 0;
  }

  setDispatchSolutionValidation(clearData: boolean = true): void {
    if (clearData) {
      this.dispatchDetailsForm.controls.providerDispatchSoftwareKey.setValue('');
      // this.dispatchDetailsForm.controls.respondEmailServiceRequest.setValue('');
      // this.dispatchDetailsForm.controls.respondPhoneServiceRequest.setValue('');
    }

    this.dispatchDetailsForm.controls.providerDispatchSoftwareKey.setValidators(null);

    if (this.dispatchDetailsForm.controls.dispatchSolution.value) {
      this.dispatchDetailsForm.controls.providerDispatchSoftwareKey.setValidators(Validators.required);
    }

    this.dispatchDetailsForm.controls.respondEmailServiceRequest.setValidators(Validators.required);
    this.dispatchDetailsForm.controls.respondPhoneServiceRequest.setValidators(Validators.required);

    this.dispatchDetailsForm.controls.providerDispatchSoftwareKey.updateValueAndValidity();
    this.dispatchDetailsForm.controls.respondEmailServiceRequest.updateValueAndValidity();
    this.dispatchDetailsForm.controls.respondPhoneServiceRequest.updateValueAndValidity();
  }

  setDispatchSoftwareValidation(clearData: boolean = true): void {
    if (clearData) {
      this.dispatchDetailsForm.controls.otherDispatchSoftware.setValue('');
    }
    if (this.dispatchDetailsForm.controls.providerDispatchSoftwareKey.value === 'Other') {
      this.dispatchDetailsForm.controls.otherDispatchSoftware.setValidators(Validators.required);
    } else {
      this.dispatchDetailsForm.controls.otherDispatchSoftware.setValidators(null);
    }
    this.dispatchDetailsForm.controls.otherDispatchSoftware.updateValueAndValidity();
  }

  addEmailAndPhone(value, showWarning = true): any {
    const newEmail = this.dispatchDetailsForm.controls.electronicDispatchEmail.value;
    const newPhone = this.dispatchDetailsForm.controls.electronicDispatchPhone.value;

    if (value === 'email' && !newEmail) {
      this.toast.warning('Please enter Email Address', 'Warning');
      return false;
    } else if (value === 'phone' && !newPhone) {
      this.toast.warning('Please enter Phone Number', 'Warning');
      return false;
    }

    const data = {
      deleted: false,
      insertedUserKey: sessionStorage.getItem('userKey'),
      providerElectronicDispatchEmailKey: value === 'email' ? newEmail : null,
      providerElectronicDispatchPhoneNumberKey: value === 'phone' ? newPhone : null,
      providerkey: this.providerKey,
      systemDefault: false,
      updatedUserKey: ''
    };
    if (value === 'email' && newEmail && this.dispatchDetailsForm.controls.electronicDispatchEmail.valid) {
      if (!(this.electronicDispatchEmails.some(x => x.providerElectronicDispatchEmailKey.toLowerCase() === newEmail.toLowerCase()))) {
        this.loading = true;
        this.providerElectronicDispatchService.insertProviderElectronicDispatchEmails(data).subscribe((result) => {
          this.loading = false;
          this.toast.success(result.message[0], 'Success');
          this.electronicDispatchEmails.push(data);
          this.dispatchDetailsForm.controls.electronicDispatchEmail.setValue('');
        }, (error) => {
          this.loading = false;
        });
      } else if (showWarning) {
        this.toast.warning('Email already added', 'Warning');
      }
    } else if (value === 'phone' && newPhone && this.dispatchDetailsForm.controls.electronicDispatchPhone.valid) {
      if (!(this.electronicDispatchPhones.some(x => x.providerElectronicDispatchPhoneNumberKey === newPhone))) {
        this.loading = true;
        this.providerElectronicDispatchService.insertProviderElectronicDispatchPhoneNumbers(data).subscribe((result) => {
          this.loading = false;
          this.toast.success(result.message[0], 'Success');
          this.electronicDispatchPhones.push(data);
          this.dispatchDetailsForm.controls.electronicDispatchPhone.setValue('');
        }, (error) => {
          this.loading = false;
        });
      } else if (showWarning) {
        this.toast.warning('Phone number already added', 'Warning');
      }
    }
  }

  updateProviderDispatchDetails(): any {
    if (this.dispatchDetailsForm.invalid) {
      this.utilityService.validateAllFormFields(this.dispatchDetailsForm);
      return false;
    }

    // if (this.dispatchDetailsForm.controls.dispatchSolution.value === false) {
    if (this.dispatchDetailsForm.controls.respondEmailServiceRequest.value
      && this.electronicDispatchEmails.length === 0
      && !this.dispatchDetailsForm.controls.electronicDispatchEmail.value
    ) {
      this.toast.warning('Please enter Email(s) for Electronic Dispatching', 'Warning');
      return false;
    }
    if (this.dispatchDetailsForm.controls.respondPhoneServiceRequest.value
      && this.electronicDispatchPhones.length === 0
      && !this.dispatchDetailsForm.controls.electronicDispatchPhone.value
    ) {
      this.toast.warning('Please enter Cell Phone(s) for Electronic Text Message(s)', 'Warning');
      return false;
    }
    // }

    this.upatedProviderElectronicDispatchingDetails();
    this.updateProviderDetails();
  }

  upatedProviderElectronicDispatchingDetails(): void {
    // if (this.dispatchDetailsForm.controls.dispatchSolution.value === false) {
    const newEmail = this.dispatchDetailsForm.controls.electronicDispatchEmail.value;
    const newPhone = this.dispatchDetailsForm.controls.electronicDispatchPhone.value;

    if (this.dispatchDetailsForm.controls.respondEmailServiceRequest.value
      && this.dispatchDetailsForm.controls.electronicDispatchEmail.value) {
      this.addEmailAndPhone('email', false);
    } else if (!this.dispatchDetailsForm.controls.respondEmailServiceRequest.value) {
      this.deleteProviderEmailsForDispatching();
    }
    if (this.dispatchDetailsForm.controls.respondPhoneServiceRequest.value
      && this.dispatchDetailsForm.controls.electronicDispatchPhone.value) {
      this.addEmailAndPhone('phone', false);
    } else if (!this.dispatchDetailsForm.controls.respondPhoneServiceRequest.value) {
      this.deleteProviderPhonesForDispatching();
    }
    // }
  }

  deleteProviderEmailsForDispatching(emailKey = null): void {
    const reuest = [];
    if (this.electronicDispatchEmails.length > 0 && emailKey == null) {
      this.electronicDispatchEmails.forEach(emails => {
        reuest.push(this.providerElectronicDispatchService.deleteProviderElectronicDispatchEmails(
          { providerElectronicDispatchEmailKey: emails.providerElectronicDispatchEmailKey }
        ));
      });
      this.deleteProviderElectronicEmailsAndPhones(reuest, 'email');
    } else if (emailKey) {
      this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
        if (isDelete) {
          reuest.push(this.providerElectronicDispatchService.deleteProviderElectronicDispatchEmails(
            { providerElectronicDispatchEmailKey: emailKey }
          ));
          this.deleteProviderElectronicEmailsAndPhones(reuest, 'email', emailKey);
        }
      });
    }
  }

  deleteProviderPhonesForDispatching(phoneKey = null): void {
    const reuest = [];
    if (this.electronicDispatchPhones.length > 0 && phoneKey == null) {
      this.electronicDispatchPhones.forEach(phone => {
        reuest.push(this.providerElectronicDispatchService.deleteProviderElectronicDispatchPhoneNumbers(
          { providerElectronicDispatchPhoneNumberKey: phone.providerElectronicDispatchPhoneNumberKey }
        ));
      });
      this.deleteProviderElectronicEmailsAndPhones(reuest, 'phone');
    } else if (phoneKey) {
      this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
        if (isDelete) {
          reuest.push(this.providerElectronicDispatchService.deleteProviderElectronicDispatchPhoneNumbers(
            { providerElectronicDispatchPhoneNumberKey: phoneKey }
          ));
          this.deleteProviderElectronicEmailsAndPhones(reuest, 'phone', phoneKey);
        }
      });
    }
  }

  deleteProviderElectronicEmailsAndPhones(requests: any, type: string, Key = ''): void {
    this.loading = true;
    if (requests.length > 0) {
      forkJoin([...requests]).subscribe(() => {
        this.loading = false;
        if (type === 'email') {
          if (Key) {
            this.electronicDispatchEmails = this.electronicDispatchEmails.filter(email => email.providerElectronicDispatchEmailKey !== Key);
            this.toast.success('Delete processed successfully.', 'Success');
          } else {
            this.electronicDispatchEmails = [];
          }
        } if (type === 'phone') {
          if (Key) {
            this.electronicDispatchPhones = this.electronicDispatchPhones.filter(email =>
              email.providerElectronicDispatchPhoneNumberKey !== Key);
            this.toast.success('Delete processed successfully.', 'Success');
          } else {
            this.electronicDispatchPhones = [];
          }
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }

  updateProviderDetails(): void {
    const providerRequest = [];
    if (this.dispatchDetailsForm.controls.providerDispatchSoftwareKey.value === 'Other') {
      const dispatchSoftware = this.utilityService.capitalizeFirstLetter(this.dispatchDetailsForm.controls.otherDispatchSoftware.value);
      if (!(this.dispatchSoftwares.some(x => x.providerDispatchSoftwareKey === dispatchSoftware.trim()))) {
        const data = {
          deleted: false,
          insertedUserKey: sessionStorage.getItem('userKey'),
          providerDispatchSoftwareKey: dispatchSoftware.trim(),
          systemDefault: false,
          updatedUserKey: ''
        };
        providerRequest.push(this.providerService.insertproviderdispatchsoftwares(data));
        this.dispatchSoftwares.push(data);
        this.dispatchSoftwares = this.utilityService.sortData(this.headers, 'providerDispatchSoftwareKey', 'asc', this.dispatchSoftwares);
      }
      this.dispatchDetailsForm.controls.providerDispatchSoftwareKey.setValue(dispatchSoftware.trim());
    }


    //  TURN THESE VALUES TO NULL IF THEY ARE ''
    if(this.dispatchDetailsForm.value.tollFreePhone != '' && this.dispatchDetailsForm.value.tollFreePhone != null && this.dispatchDetailsForm.value.tollFreePhone.length == 10){
      this.dispatchDetailsForm.value.tollFreePhone = '+1' + this.dispatchDetailsForm.value.tollFreePhone
    }
    else{this.dispatchDetailsForm.value.tollFreePhone = null}

    if(this.dispatchDetailsForm.value.businessHoursPhone != '' && this.dispatchDetailsForm.value.businessHoursPhone != null && this.dispatchDetailsForm.value.businessHoursPhone.length == 10 ){
      this.dispatchDetailsForm.value.businessHoursPhone = '+1' + this.dispatchDetailsForm.value.businessHoursPhone
    }
    else{this.dispatchDetailsForm.value.businessHoursPhone = null}

    if(this.dispatchDetailsForm.value.afterHoursPhone != '' && this.dispatchDetailsForm.value.afterHoursPhone != null && this.dispatchDetailsForm.value.afterHoursPhone.length == 10 ){
      this.dispatchDetailsForm.value.afterHoursPhone = '+1' + this.dispatchDetailsForm.value.afterHoursPhone
    }
    else{this.dispatchDetailsForm.value.afterHoursPhone = null}

    if(this.dispatchDetailsForm.value.dispatchFax != '' && this.dispatchDetailsForm.value.dispatchFax != null && this.dispatchDetailsForm.value.dispatchFax.length == 10){
      this.dispatchDetailsForm.value.dispatchFax = '+1' + this.dispatchDetailsForm.value.dispatchFax
    }
    else{this.dispatchDetailsForm.value.dispatchFax = null}

    if(this.dispatchDetailsForm.value.dispatchEmail == '' ){
      this.dispatchDetailsForm.value.dispatchEmail = null
    }

    // this.dispatchDetailsForm.patchValue({
    //   tollFreePhone: ('+1' + this.dispatchDetails.tollFreePhone) || '',
    //   businessHoursPhone: ('+1' + this.dispatchDetails.businessHoursPhone) || '',
    //   afterHoursPhone: ('+1' + this.dispatchDetails.afterHoursPhone) || '',
    //   dispatchFax: ('+1' + this.dispatchDetails.dispatchFax) || '',})

    // if(this.dispatchDetailsForm.value == ''){
    //   this.dispatchDetailsForm
    // }

    providerRequest.push(this.providerService.updateProviderDetails(this.dispatchDetailsForm.value));

    if (this.isParent) {
      providerRequest.push(this.providerService.getProviderDetails({ parentKey: this.providerKey }))
    }

    this.loading = true;
    forkJoin([...providerRequest]).subscribe((result: any) => {
      this.loading = false;
      this.toast.success('update (ServicePlus_Providers) processed successfully.', 'Success');
      if (this.isParent) {
        if (result[result.length - 1].body.length > 0) {
          const childData = [];
          result[result.length - 1].body.forEach(item =>
            childData.push({
              providerKey: item.providerKey,
              hasCentralDispatch: this.dispatchDetailsForm.get('hasCentralDispatch').value,
              updatedUserKey: sessionStorage.getItem('userKey'),
            }));
          this.providerAdminService.updateProviderChildDetails(childData).subscribe(() => {
          }, (error) => {
          });
        }
      }
    }, (error) => {
      this.loading = false;
    });
    this.dialogRef.close();
  }
}
