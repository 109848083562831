import { Component, OnInit, ViewChildren, QueryList, Input, Output, EventEmitter } from '@angular/core';
import { SortableDirective } from '../directive/sortable.directiveReg';
import { CapabilitiesService } from 'src/app/services/capabilities.service';
import { CheckBoxService } from 'src/app/services/check-box.service';
import { DynamoDBService } from 'src/app/services/dynamo-db.service';
import { ITreeOptions } from 'angular-tree-component';

@Component({
  selector: 'ngx-provider-capabilities',
  templateUrl: './provider-capabilities.component.html',
  styleUrls: ['./provider-capabilities.component.scss'],
})
export class ProviderCapabilitiesComponent implements OnInit {

  @Input() locationKey: string;
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  loading = false;
  providerKey: string;
  defaultLocationKey: string;
  addRemoveCapabilities = []

  //For checkbox tree
  lastNode = -1;
  level: number = 0;
  isSelected: number = -1;
  parentRecord: boolean;
  expandLayer: any = [];
  providerCapabilities: any;
  searchString: string;
  selectedCapabilities = [];
  selectedKey: any;

  options: ITreeOptions = {
    animateExpand: true,
    isExpandedField: 'expanded'
  };
  selectedCount: number;

  constructor(
    private checkBoxService: CheckBoxService,
    private capabilitiesService: CapabilitiesService,
    private dynamoDBService: DynamoDBService
  ) {
    this.dynamoDBService.loader.subscribe(message => {
      this.loading = message;
    });
  }

  ngOnInit() {

    // ? Get Geohash Provider
    if (!sessionStorage.getItem('geoHashProvider')) this.dynamoDBService.DynamoProviderDetails();

    this.checkBoxService.setCheckBoxNetworkData(null);
    sessionStorage.removeItem('expandedLayer');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');

    if (!!this.locationKey) {
      this.providerKey = this.locationKey;
    } else if (!!this.defaultLocationKey) {
      this.providerKey = this.defaultLocationKey;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
    }

    this.getProviderServiceCapabilities();
    sessionStorage.setItem('expandedLayer', JSON.stringify(this.expandLayer));

    this.checkBoxService.getCheckBoxData().subscribe(message => {
      this.providerCapabilities = JSON.parse(this.checkBoxService.getCheckBoxNetworkData());
      this.parentRecord = false;
    });

    this.checkBoxService.checkBoxLoader.subscribe(message => {
      this.loading = message;
    });
  }

  getProviderServiceCapabilities() {
    const filter = {
      providerKey: this.providerKey
    };

    this.loading = true;
    this.capabilitiesService.getProviderCapabilitiesByProvider(filter).subscribe(result => {
      this.loading = false;
      this.providerCapabilities = result;

      if (this.selectedKey) this.expandSelectedSearchNode(this.providerCapabilities, this.selectedKey);

    }, (error) => {
      this.loading = false;
    });
  }

  expandSelectedSearchNode(array, value) {
    let found = false;
    array.forEach(element => {
      element.expanded = this.expandSelectedSearchNode(element.children || [], value) || element.providerCapabilityKey === value;
      found = found || element.expanded;
    });
    return found;
  }

  onSelectedChange(data, event): void {
    this.updateChildNodeCheckbox(data.data, event.checked);
    this.updateParentNodeCheckbox(data);
    this.countCapability();

    const filter = {
      isSelected: event.checked,
      providerCapabilityKey: data.data.providerCapabilityKey,
      providerKey: this.providerKey,
    };

    this.addRemoveCapability(data.description, data.isSelected);

    //Update provider into Dynamo Db
    this.updateGeoHash();

    this.loading = true;
    this.capabilitiesService.addDeleteProviderCapabilities(filter).subscribe(() => {
      this.loading = false;

      if (event) data.treeModel.expandAll();
      // else data.treeModel.collapseAll();

      this.countCapability();

      if (this.searchString) {
        this.selectedKey = data.data.providerCapabilityKey;
        this.searchString = '';
        this.getProviderServiceCapabilities()
      }
      // this.snackBarService.success('Capabilities updated successfully.');
    }, (error) => {
      this.loading = false;
    });
  }

  countCapability(): void {
    this.selectedCount = 0;
    this.selectedCapabilities = [];
    this.providerCapabilities.forEach(service => {
      if (service.isSelected === true) {
        this.selectedCount += 1;
        this.selectedCapabilities.push(service.name);
      }
      if (service?.children?.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  addItemRecursively(data): void {
    data.forEach(service => {
      if (service.isSelected === true) {
        this.selectedCapabilities.push(service.name);
      }
      if (service.children.length > 0) {
        this.addItemRecursively(service.children);
      }
    });
  }

  public updateChildNodeCheckbox(node, checked): void {
    node.isSelected = checked;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
    }
  }

  public updateParentNodeCheckbox(node): void {
    if (!node) { return; }

    let allChildrenChecked = true;
    let noChildChecked = true;

    if (node.children && node.children.length > 0) {
      for (const child of node?.children) {
        if (!child.data.isSelected) {
          allChildrenChecked = false;
        }
        if (child.data.isSelected) {
          noChildChecked = false;
        }
      }

      if (allChildrenChecked) {
        node.data.isSelected = true;
      } else if (noChildChecked) {
        node.data.isSelected = false;
      } else {
        node.data.isSelected = true;
      }
    }
    this.updateParentNodeCheckbox(node.parent);
  }

  addRemoveCapability(offering, deleted) {
    this.addRemoveCapabilities = [];
    const selectedOffering = this.providerCapabilities.filter(x => x.isSelected).map((el) => el.description)
    this.addRemoveCapabilities = deleted ? [...selectedOffering] : [...selectedOffering, offering];
    if (deleted) {
      const index = this.addRemoveCapabilities.indexOf(offering);
      this.addRemoveCapabilities.splice(index, 1)
    }
  }

  // ? Update GeoHash Provider
  updateGeoHash() {

    const providerUpdateReq = JSON.parse(sessionStorage.getItem('geoHashProvider'));

    if (!providerUpdateReq) return;
    providerUpdateReq.providerServiceCapabilities = JSON.stringify(this.addRemoveCapabilities);
    this.dynamoDBService.geoHashUpdate([providerUpdateReq]);
  }

  onInput(val: string) {
    this.search.emit(this.searchString);
  }

}

