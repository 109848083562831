import { Deserializable } from "../../models/deserializable.model";

export class Technician implements Deserializable  {

    deleted: boolean
    active: boolean
    cellPhone: string
    email: string
    firstName: string
    lastName: string
    providerKey: string
    insertedTimestamp: string
    insertedUserKey: string
    updatedUserKey: string
    providerTechnicianKey: string
    systemDefault: true
    updatedTimestamp: string

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
    prepareUpdate(data: any): any {
        this.deserialize(data);
        this.insertedUserKey = sessionStorage.getItem('userKey');
        this.deleted = false;
        return this;
    }
}