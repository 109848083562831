import { Component, OnInit, Input, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { ProfileService } from 'src/app/services/profile.service';
import { RatesService } from 'src/app/services/rates.service';
import { BubbleService } from 'src/app/services/bubble-service.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SortEvent, SortableDirective } from '../directive/sortable.directiveReg';
import { b64DecodeUnicode } from '@nebular/auth';
import * as L from 'leaflet';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ProviderAccountService } from 'src/app/services/provider-account.service';
import { AccountPricingExceptionApprovalDialogComponent } from 'src/app/dialogs/account-pricing-exception-approval-dialog/account-pricing-exception-approval-dialog.component';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'ngx-setup-rates',
  templateUrl: './setup-rates.component.html',
  styleUrls: ['./setup-rates.component.scss'],
})

export class SetupRatesComponent implements OnInit {
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  @Input() providerAccount: any = null;
  @Input() showBack: boolean = false;
  @Input() showNext: boolean = false;
  @Input() isParent: boolean = true;

  providerKey: any;
  providerAccountKey: any;
  licensedOwnerKey: any;
  loading: boolean;
  arrProviderAccountPricing: any = [];
  ProviderDetails: any;
  arrPaymentTerms: any;
  strPaymentTermKey: string;
  arrTempRates: any;
  disableEdit: boolean = false;
  arrLineItemPricingTypes: any;
  checkTireRate: any;
  isDisallowed: boolean = true;
  accountName: String = '';
  defaultLocationKey: string;
  rateRangeData: any;
  emergencyRateUpdate: any;
  scheduledRateUpdate: any;
  InvalidRate: boolean = false;
  getParentRecord: boolean = false;
  searchText: string;
  regionsList: any = [];
  matchedRegion = [];
  afterHoursRatesAllowed: boolean = false;
  lineItemTypeGroupData = [];
  lineItemTypeGroups = [];
  isLicensedOwnerKey = false;
  emergencyRate: any;
  scheduledRate: any;
  afterHoursRate: any;
  updateLineItemToDeleteReq = [];
  updateLineItemNotDeleteReq = [];
  insertLineItemReq = [];
  emergencyRateApproved: any;
  emergencyAfterHoursRateApproved: any;
  scheduledRateApproved: any;
  isEmergencyRate: boolean;
  isEmergencyAfterHoursRate: boolean;
  isScheduleRate: boolean;
  isValidRateSubsription = false;
  parentProviderKey = (sessionStorage.getItem('providerParentKey') != 'null'
    && sessionStorage.getItem('providerParentKey') !== '0') ?
    sessionStorage.getItem('providerParentKey') : sessionStorage.getItem('providerKey');

  constructor(
    public dialog: MatDialog,
    private toast: NbToastrService,
    private profileService: ProfileService,
    private ratesService: RatesService,
    private bubbleService: BubbleService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private setupService: SetupService,
    private providerAccountService: ProviderAccountService,
  ) { }

  ngOnInit() {
    if (this.providerAccount !== undefined && this.providerAccount != null) {
      this.providerAccountKey = this.providerAccount.providerAccountKey;
      this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
      this.accountName = this.providerAccount.accountName;
      this.providerKey = this.providerAccount.providerKey;
      this.afterHoursRatesAllowed = this.providerAccount.afterHoursRatesAllowed || false;
    } else {
      this.providerAccountKey = sessionStorage.getItem('providerAccountKey');
      this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
      this.accountName = sessionStorage.getItem('licensedOwnerName');
      this.providerKey = sessionStorage.getItem('providerKey');
      this.afterHoursRatesAllowed = sessionStorage.getItem('afterHoursRatesAllowed') === 'true' ? true : false;
    }

    if (this.licensedOwnerKey !== '') {
      this.isLicensedOwnerKey = true;
      this.checkValidRateSubscriptionAccounts();
    }

    if (this.isParent) {
      this.getParentRecord = false;
      this.getProviderAccountPricing();
      if (this.licensedOwnerKey !== '') {
        this.getLineItemTypeGroups();
      }
    } else {
      const data = {
        'providerAccountKey': this.providerAccountKey,
        'providerKey': this.providerKey,
        'deleted': false,
      };

      this.loading = true;
      this.ratesService.getProviderPricingDetails(data).subscribe((result: any) => {
        this.loading = false;
        /* Didn't understand why this condition was added */
        // if (result.body.length === 0 &&
        // (sessionStorage.getItem('licensedOwnerKey') == null ||
        // sessionStorage.getItem('licensedOwnerKey') === undefined)) {
        if (result.body.length === 0) {
          this.getParentRecord = true;

          const filterData = {
            'accountName': this.accountName,
            'licensedOwnerKey': this.licensedOwnerKey,
            'providerKey': this.parentProviderKey,
            'deleted': false,
          };

          //  Get parent's account key to fetch and insert data
          this.loading = true;
          this.bubbleService.getProviderAccountKey(filterData).subscribe(proResult => {
            this.loading = false;
            this.providerAccountKey = proResult.body[0].providerAccountKey;
            this.getProviderAccountPricing();
            if (this.licensedOwnerKey !== '') {
              this.getLineItemTypeGroups();
            }
          }, (error) => {
            this.loading = false;
          });
        } else {
          this.getParentRecord = false;
          this.getProviderAccountPricing();
          if (this.licensedOwnerKey !== '') {
            this.getLineItemTypeGroups();
          }
        }

      }, (error) => {
        this.loading = false;
      });
    }

    this.onloadListing();
  }

  checkValidRateSubscriptionAccounts() {
    const filter = {
      deleted: false,
      moduleKey: 'Provider Rate Approval',
      licensedOwnerKey: this.licensedOwnerKey,
    };

    this.setupService.getLicensedOwnerModuleSubscriptions(filter).subscribe(res => {
      if (res.success && res.body.length > 0) {
        const currentTimestamp = this.utilityService.getCurrentTimestamp();
        if (res.body[0].subscriptionStart <= currentTimestamp && res.body[0].subscriptionEnd >= currentTimestamp) {
          this.isValidRateSubsription = true;
        }
      }
    });
  }

  // Get provider account pricing and if not exist insert provider account pricing
  getProviderAccountPricing() {
    this.arrProviderAccountPricing = [];
    const data = {
      providerKey: this.getParentRecord ? this.parentProviderKey : this.providerKey,
      providerAccountKey: this.providerAccountKey,
      licensedOwnerKey: this.licensedOwnerKey ? this.licensedOwnerKey : '',
    };

    this.loading = true;
    this.ratesService.getProviderAccountPricing(data).subscribe((result: any) => {
      this.loading = false;
      if (result.length > 0) {
        this.arrProviderAccountPricing = result.map(res => { res.editMode = false; return res; });
        this.onSort({ column: 'lineItemKey', direction: 'asc' });
      } else {
        this.getParentRecord = false;
      }
    }, (error) => {
      this.loading = false;
      console.info(error);
    });
  }

  onloadListing() {
    this.loading = true;
    const providerFilter = {
      providerKey: this.providerKey,
    };
    const regionFilter = { licensedOwnerKey: this.licensedOwnerKey, deleted: false };

    const providerReq = this.profileService.getProviderDetails(providerFilter);
    const PaymentTearmsReq = this.profileService.getPaymentTerms({ deleted: false });
    const regionsReq = this.ratesService.getLicensedOwnerRegions(regionFilter);
    const pricingTypesReq = this.ratesService.getLineItemPricingTypes({ deleted: false });

    forkJoin([providerReq, PaymentTearmsReq, regionsReq, pricingTypesReq]).subscribe(result => {
      this.loading = false;
      if (result[0].body.length) {
        this.ProviderDetails = result[0].body[0];
        this.strPaymentTermKey = this.ProviderDetails.paymentTermKey;
        if (!this.ProviderDetails.tierCanSetRates && !this.isParent) {
          this.isDisallowed = false;
        }

        this.getProviderStateKey(this.ProviderDetails.physicalStateKey);
      }
      if (result[1].body.length) {
        this.arrPaymentTerms = result[1].body;
      }
      if (result[2].body.length) {
        this.regionsList = result[2].body;
      }
      if (result[3].body.length) {
        this.arrLineItemPricingTypes = result[3].body;
      }
    }, (error) => {
      this.loading = false;
      console.info(error);
    });
  }

  // Get provider state key and country key from provider state description
  getProviderStateKey(providerState) {
    const stateFilter: any = { deleted: false };
    if (this.ProviderDetails.physicalStateKey.length > 2) {
      stateFilter.stateDescription = providerState;
    } else {
      stateFilter.stateKey = providerState;
    }
    this.commonService.getstates(stateFilter).subscribe(result => {
      if (result.success) {
        this.ProviderDetails.countryKey = result.body[0].countryKey;
        this.ProviderDetails.stateKey = result.body[0].stateKey;
        this.getproviderRegion();
      }
    });
  }

  // match provider physicsal address with regions to select region
  getproviderRegion() {
    this.regionsList.forEach(region => {
      if (region.customRegionParameters === null ||
        b64DecodeUnicode(region.customRegionParameters) === '[]' ||
        b64DecodeUnicode(region.customRegionParameters) === '{}') {
        if (region.stateKey && region.stateKey.includes(this.ProviderDetails.stateKey)) {
          this.matchedRegion.push(region);
        } else if (!region.stateKey && this.ProviderDetails.countryKey == region.countryKey) {
          this.matchedRegion.push(region);
        }
      } else {
        const customRegionJson = JSON.parse(b64DecodeUnicode(region.customRegionParameters));
        if (!customRegionJson.radius && customRegionJson.latlng !== undefined) {
          const arrLatLong = customRegionJson.latlng.map(d => Object.values(d));
          const polygon = L.polygon(arrLatLong);

          if (polygon.getBounds().contains([this.ProviderDetails.physicalLatitude,
          this.ProviderDetails.physicalLongitude])) {
            this.matchedRegion.push(region);
          } else if (region.stateKey && region.stateKey.includes(this.ProviderDetails.stateKey)) {
            this.matchedRegion.push(region);
          }

        } else {
          const circ = L.circle(customRegionJson.latlng, { radius: customRegionJson.radius });

          if (circ.getLatLng().distanceTo(
            [this.ProviderDetails.physicalLatitude, this.ProviderDetails.physicalLongitude]) < circ.getRadius()) {
            this.matchedRegion.push(region);
          } else if (region.stateKey !== '' && region.stateKey.includes(this.ProviderDetails.stateKey)) {
            this.matchedRegion.push(region);
          }
        }
      }
    });
  }

  editRates(providerAccountPricing) {
    this.loading = true;
    this.InvalidRate = false;

    this.arrProviderAccountPricing.map(lineitem => {
      if (providerAccountPricing.providerAccountPricingKey === lineitem.providerAccountPricingKey) {
        lineitem.editMode = true;
        this.emergencyRate = lineitem.emergencyRate;
        this.scheduledRate = lineitem.scheduledRate;
        this.afterHoursRate = lineitem.afterHoursRate;
      }
      return lineitem;
    });
    this.disableEdit = true;

    if (providerAccountPricing.emergencyRate > 0 || providerAccountPricing.emergencyRate !== null) {
      this.isEmergencyRate = true;
    } else {
      this.isEmergencyRate = false;
    }

    if (providerAccountPricing.afterHoursRate > 0 || providerAccountPricing.afterHoursRate !== null) {
      this.isEmergencyAfterHoursRate = true;
    } else {
      this.isEmergencyAfterHoursRate = false;
    }

    if (providerAccountPricing.scheduledRate > 0 || providerAccountPricing.scheduledRate !== null) {
      this.isScheduleRate = true;
    } else {
      this.isScheduleRate = false;
    }

    this.emergencyRateApproved = [];
    this.emergencyAfterHoursRateApproved = [];
    this.scheduledRateApproved = [];

    const emergencyRateData = {
      licensedOwnerKey: this.licensedOwnerKey,
      lineItemPricingCategories: 'Emergency Rate',
      providerAccountPricingKey: providerAccountPricing.providerAccountPricingKey,
    };

    const emergencyAfterHoursRateData = {
      licensedOwnerKey: this.licensedOwnerKey,
      lineItemPricingCategories: 'Emergency After-Hours Rate',
      providerAccountPricingKey: providerAccountPricing.providerAccountPricingKey,
    };

    const scheduledRateData = {
      licensedOwnerKey: this.licensedOwnerKey,
      lineItemPricingCategories: 'Scheduled Rate',
      providerAccountPricingKey: providerAccountPricing.providerAccountPricingKey,
    };

    const emergencyRate = this.ratesService.getProviderAccountPricingException(emergencyRateData);
    const emergencyAfterHoursRate = this.ratesService.getProviderAccountPricingException(emergencyAfterHoursRateData);
    const scheduledRate = this.ratesService.getProviderAccountPricingException(scheduledRateData);

    forkJoin([emergencyRate, emergencyAfterHoursRate, scheduledRate]).subscribe(result => {
      this.emergencyRateApproved = result[0].body[0];
      this.emergencyAfterHoursRateApproved = result[1].body[0];
      this.scheduledRateApproved = result[2].body[0];
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  cancelRates(data) {
    this.InvalidRate = false;
    this.arrProviderAccountPricing.map(lineitem => {
      if (data.providerAccountPricingKey === lineitem.providerAccountPricingKey) {
        lineitem.emergencyRate = this.emergencyRate;
        lineitem.scheduledRate = this.scheduledRate;
        lineitem.afterHoursRate = this.afterHoursRate;
        lineitem.editMode = false;
      }
      return lineitem;
    });
    this.disableEdit = false;
  }

  checkProviderAccountPricing() {
    const data = {
      providerKey: this.providerKey,
      providerAccountKey: this.providerAccountKey,
      licensedOwnerKey: this.licensedOwnerKey ? this.licensedOwnerKey : '',
    };
    this.ratesService.getProviderAccountPricing(data).subscribe((result: any) => {
      this.arrProviderAccountPricing = result;
      this.onSort({ column: 'lineItemKey', direction: 'asc' });
    });
  }

  saveRates(providerAccountPricingDetails) {
    if (this.getParentRecord) {
      const data = {
        locationKey: this.providerKey,
        providerKey: sessionStorage.getItem('providerKey'),
        licensedOwnerKey: this.licensedOwnerKey,
        // licensedOwnerKey: null,
        providerAccountKey: this.providerAccountKey,
        // providerAccountKey: null,
        locationAccountKey: this.providerAccount.providerAccountKey,
        // locationAccountKey: null,
      };

      this.loading = true;
      this.bubbleService.setUpServiceAndRates(data).subscribe(result => {
        this.loading = false;
        this.providerAccountKey = this.providerAccount.providerAccountKey;
        this.bubbleService.setBubbleTreeData();
        this.updateSelectedRecord(providerAccountPricingDetails);
      }, (error) => {
        this.loading = false;
      });
    } else {
      if (this.licensedOwnerKey === '' || !this.isValidRateSubsription) {
        const updatePricing = {
          providerAccountPricingKey: providerAccountPricingDetails.providerAccountPricingKey,
          emergencyRate: providerAccountPricingDetails.emergencyRate,
          scheduledRate: providerAccountPricingDetails.scheduledRate,
          lineItemPricingTypeKey: providerAccountPricingDetails.lineItemPricingTypeKey,
          afterHoursRate: providerAccountPricingDetails.afterHoursRate,
          updatedUserKey: sessionStorage.getItem('userKey'),
          emergencyRateApproved: (providerAccountPricingDetails.emergencyRate === ''
            || providerAccountPricingDetails.emergencyRate == null) ? null : 1,
          scheduledRateApproved: (providerAccountPricingDetails.scheduledRate === ''
            || providerAccountPricingDetails.scheduledRate == null) ? null : 1,
          afterHoursRateApproved: (providerAccountPricingDetails.afterHoursRate === ''
            || providerAccountPricingDetails.afterHoursRate == null) ? null : 1,
        };
        this.saveProviderPricing(updatePricing);
      }
      if (this.matchedRegion.length === 0) {
        this.arrProviderAccountPricing.map(price => { price.editMode = false; return price; });
        this.disableEdit = false;
        return false;
      } else {
        this.loading = true;
        let emergencyApproved = 0;
        let scheduledApproved = 0;
        let afterHoursApproved = 0;
        if (providerAccountPricingDetails.emergencyRateApproved === 1) {
          emergencyApproved = 1;
        }

        if (providerAccountPricingDetails.scheduledRateApproved === 1) {
          scheduledApproved = 1;
        }

        if (providerAccountPricingDetails.afterHoursRateApproved === 1) {
          afterHoursApproved = 1;
        }
        const updatePricing = {
          providerAccountPricingKey: providerAccountPricingDetails.providerAccountPricingKey,
          emergencyRate: providerAccountPricingDetails.emergencyRate,
          scheduledRate: providerAccountPricingDetails.scheduledRate,
          lineItemPricingTypeKey: providerAccountPricingDetails.lineItemPricingTypeKey,
          afterHoursRate: providerAccountPricingDetails.afterHoursRate,
          emergencyRateApproved: (providerAccountPricingDetails.emergencyRate === ''
            || providerAccountPricingDetails.emergencyRate == null) ? null : emergencyApproved,
          scheduledRateApproved: (providerAccountPricingDetails.scheduledRate === ''
            || providerAccountPricingDetails.scheduledRate == null) ? null : scheduledApproved,
          afterHoursRateApproved: (providerAccountPricingDetails.afterHoursRate === ''
            || providerAccountPricingDetails.afterHoursRate == null) ? null : afterHoursApproved,
          updatedUserKey: sessionStorage.getItem('userKey'),
        };
        const self = this;
        let resultLength = 0;
        const promise = new Promise<void>(function (resolve, reject) {
          self.matchedRegion.forEach((region, index) => {
            const filter = {
              specializedPricingRegionKey: region.specializedPricingRegionKey,
              lineItemKey: providerAccountPricingDetails.lineItemKey,
              lineItemPricingTypeKey: providerAccountPricingDetails.lineItemPricingTypeKey,
              deleted: false,
            };

            self.ratesService.getLicensedOwnerSpecializedPricingRegions(filter).subscribe(result => {
              resultLength += 1;
              if (result.body.length > 0) {
                if (updatePricing.emergencyRateApproved === 1 &&
                  updatePricing.scheduledRateApproved === 1 &&
                  updatePricing.afterHoursRateApproved) {
                  resolve();
                  return false;
                } else {
                  if (providerAccountPricingDetails.emergencyRate <= result.body[0].emergencyRateTo &&
                    updatePricing.emergencyRateApproved === 0) {
                    updatePricing.emergencyRateApproved = (providerAccountPricingDetails.emergencyRate === ''
                      || providerAccountPricingDetails.emergencyRate == null) ? null : 1;
                  }

                  if (providerAccountPricingDetails.scheduledRate <= result.body[0].scheduledRateTo &&
                    updatePricing.scheduledRateApproved === 0) {
                    updatePricing.scheduledRateApproved = (providerAccountPricingDetails.scheduledRate === ''
                      || providerAccountPricingDetails.scheduledRate == null) ? null : 1;
                  }

                  if (providerAccountPricingDetails.afterHoursRate <= result.body[0].afterHoursRateTo &&
                    updatePricing.afterHoursRateApproved === 0) {
                    updatePricing.afterHoursRateApproved = (providerAccountPricingDetails.afterHoursRate === ''
                      || providerAccountPricingDetails.afterHoursRate == null) ? null : 1;
                  }
                }
                if (resultLength === self.matchedRegion.length) {
                  resolve();
                }
              } else {
                if (resultLength === self.matchedRegion.length) {
                  resolve();
                }
              }
            });
          });
        });

        promise.then(() => {
          this.saveProviderPricing(updatePricing);
        });
      }
    }
  }

  saveProviderPricing(updatePricing) {
    this.ratesService.putProviderAccountPricing(updatePricing).subscribe(result => {
      this.arrProviderAccountPricing.map(price => { price.editMode = false; return price; });
      this.loading = false;
      if (result.success) {
        this.disableEdit = false;
        this.checkProviderAccountPricing();
        this.updateAccountStatus(updatePricing);
        this.updatePricingExceptionRequestedRateRecord(updatePricing);
      } else {
        this.loading = false;
        this.toast.warning(result.message[0], 'Error');
      }
    }, (error) => {
      this.loading = false;
      this.disableEdit = false;
    });
  }

  updateAccountStatus(updatePricing) {
    if (this.providerAccount.accountStatus === 'InActive' &&
      (updatePricing.afterHoursRateApproved === 1 ||
        updatePricing.emergencyRateApproved === 1 ||
        updatePricing.scheduledRateApproved === 1)) {

      const updateAccountData = {
        accountStatus: 'Active',
        providerAccountKey: this.providerAccount.providerAccountKey,
        updatedUserKey: sessionStorage.getItem('userKey'),
      };
      this.providerAccountService.updateProviderAccounts(updateAccountData).subscribe(res => {
        if (res.success) {
          console.info('Account is activated.');
        }
      }, error => {
        console.info('Error Account status update');
      });
    }
  }

  isDecimal(value) {
    const pattern = /^(\d+\.)?\d+$/g;
    if (value) {
      if (value.toString().match(pattern) != null) {
        this.InvalidRate = false;
      } else {
        this.InvalidRate = true;
      }
    }
  }

  updateSelectedRecord(providerAccountPricingDetails) {
    const pricingFilter = {
      'providerAccountKey': this.providerAccountKey,
      'providerKey': this.providerKey,
      'lineItemKey': providerAccountPricingDetails.lineItemKey,
      'lineItemPricingTypeKey': providerAccountPricingDetails.lineItemPricingTypeKey,
      'deleted': false,
    };

    this.loading = true;
    this.ratesService.getProviderPricingDetails(pricingFilter).subscribe((result: any) => {
      this.loading = false;
      const data = {
        'providerAccountPricingKey': result.body[0].providerAccountPricingKey,
        'emergencyRate': providerAccountPricingDetails.emergencyRate,
        'scheduledRate': providerAccountPricingDetails.scheduledRate,
        'afterHoursRate': providerAccountPricingDetails.afterHoursRate,
        'lineItemPricingTypeKey': providerAccountPricingDetails.lineItemPricingTypeKey,
        'updatedUserKey': sessionStorage.getItem('userKey'),
      };
      this.loading = true;
      this.ratesService.putProviderAccountPricing(data).subscribe((res: any) => {
        this.loading = true;
        this.getParentRecord = false;
        this.disableEdit = false;
        this.updatePricingExceptionRequestedRateRecord(data);
        this.getProviderAccountPricing();
      }, (error) => {
        this.loading = false;
      });
    }, (error) => {
      this.loading = false;
    });
  }

  onInput(val: string) {
    this.search.emit(this.searchText);
  }

  onSort({ column, direction }: SortEvent) {
    this.arrProviderAccountPricing = this.utilityService.sortData(
      this.headers,
      column,
      direction,
      this.arrProviderAccountPricing);
  }

  getLineItemTypeGroups() {
    const data = {
      providerKey: this.getParentRecord ? this.parentProviderKey : this.providerKey,
      providerAccountKey: this.providerAccountKey,
      licensedOwnerKey: this.licensedOwnerKey,
    };
    this.loading = true;
    this.ratesService.getLicensedOwnerLineItemTypeGroup(data).subscribe((res: any) => {
      this.loading = false;
      const arrLineItemTypeGroup = res;
      if (arrLineItemTypeGroup.length > 0) {
        arrLineItemTypeGroup.forEach(lineItemTypeGroup => {
          if (!this.lineItemTypeGroupData.some(e =>
            e.serviceId === lineItemTypeGroup.LicensedOwnerServiceOfferingKey)) {
            const temp = {
              'serviceId': lineItemTypeGroup.LicensedOwnerServiceOfferingKey,
              'serviceName': lineItemTypeGroup.Description,
              'children': [],
            };
            this.lineItemTypeGroupData.push(temp);
          }
        });
        this.assignLineItemTypeGroup(this.lineItemTypeGroupData, arrLineItemTypeGroup);
        this.lineItemTypeGroups = this.lineItemTypeGroupData;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  assignLineItemTypeGroup(lineItemTypeGroupData, arrLineItemTypeGroup) {
    lineItemTypeGroupData.forEach(arrdata => {
      arrLineItemTypeGroup.forEach(element => {
        if (arrdata.serviceId === element.LicensedOwnerServiceOfferingKey &&
          !arrdata.children.some(e => e.LineItemTypeGroupName === element.LicensedOwnerLineItemTypeGroupNameKey)) {
          const temp = {
            'lineItemTypeGroupName': element.LicensedOwnerLineItemTypeGroupNameKey,
            'licensedOwnerServiceOfferingKey': element.LicensedOwnerServiceOfferingKey,
            'isSelected': false,
          };
          arrdata.children.push(temp);
        }
      });
    });
  }

  selectRateTypeGroup(serviceId, selectedChild) {
    this.lineItemTypeGroups.map((lineItem) => {
      if (lineItem.serviceId === serviceId) {
        lineItem.children.map((child) => {
          if (child.lineItemTypeGroupName === selectedChild.lineItemTypeGroupName) {
            child.isSelected = true;
          } else {
            child.isSelected = false;
          }
          return child;
        });
      }
      return lineItem;
    });
    this.addToRateList();
  }

  addToRateList() {
    if (!this.getParentRecord) {
      this.insertRatesIntoProviderPricing();
    } else {
      const data = {
        locationKey: this.providerKey,
        providerKey: sessionStorage.getItem('providerKey'),
        licensedOwnerKey: this.licensedOwnerKey,
        // licensedOwnerKey: null,
        providerAccountKey: this.providerAccountKey,
        // providerAccountKey: null,
        locationAccountKey: this.providerAccount.providerAccountKey,
        // locationAccountKey: null,
      };

      this.loading = true;
      this.bubbleService.setUpServiceAndRates(data).subscribe(result => {
        this.loading = false;
        this.getParentRecord = false;
        this.providerAccountKey = this.providerAccount.providerAccountKey;
        this.bubbleService.setBubbleTreeData();
        this.insertRatesIntoProviderPricing();
      }, (error) => {
        this.loading = false;
      });
    }
  }

  insertRatesIntoProviderPricing() {
    let resultLengthSel = 0;
    let resultLengthNotSel = 0;
    let finalCall = false;
    for (const offerings of this.lineItemTypeGroups) {
      if (offerings.children.some(rateType => rateType.isSelected === true)) {
        const selectedLineItem = offerings.children.find(lineItem => lineItem.isSelected);
        selectedLineItem.isSelected = false;
        const seletedLineItemData = {
          'deleted': false,
          'licensedOwnerKey': this.licensedOwnerKey,
          'licensedOwnerLineItemTypeGroupNameKey': selectedLineItem.lineItemTypeGroupName,
          'licensedOwnerServiceOfferingKey': selectedLineItem.licensedOwnerServiceOfferingKey,
        };

        const notSeletedLineItemData = {
          'deleted': false,
          'licensedOwnerKey': this.licensedOwnerKey,
          'licensedOwnerServiceOfferingKey': selectedLineItem.licensedOwnerServiceOfferingKey,
        };

        const seletedLineItemReq = this.ratesService.getLicensedownerLineItemTypeGroupsList(seletedLineItemData);
        const notSeletedLineItemReq = this.ratesService.getLicensedownerOfferingsLineItemAssociations(notSeletedLineItemData);

        // tslint:disable-next-line: max-line-length
        this.loading = true;
        this.updateLineItemToDeleteReq = [];
        this.updateLineItemNotDeleteReq = [];
        this.insertLineItemReq = [];
        forkJoin([seletedLineItemReq, notSeletedLineItemReq]).subscribe(result => {
          if (result[0].body.length > 0) {
            const arrSelectedLineItem = result[0].body.filter((lineItem, index, array) => {
              return array.findIndex(element => element.lineItemKey === lineItem.lineItemKey) === index;
            });
            this.updateSelectedLineItem(arrSelectedLineItem).then(() => {
              resultLengthSel += 1;
              if (this.lineItemTypeGroups.length === resultLengthSel
                && this.lineItemTypeGroups.length === resultLengthNotSel) {
                if (!finalCall) {
                  finalCall = true;
                  this.callFinalProcess();
                }
              }
            });
          }
          if (result[1].body.length > 0) {
            const arrNotSelectedLineItem = result[1].body.filter((lineItem, index, array) => {
              return array.findIndex(element => element.lineItemKey === lineItem.lineItemKey) === index;
            });
            this.updateNotSelectedLineItem(arrNotSelectedLineItem, selectedLineItem).then(() => {
              resultLengthNotSel += 1;
              if (this.lineItemTypeGroups.length === resultLengthSel
                && this.lineItemTypeGroups.length === resultLengthNotSel) {
                if (!finalCall) {
                  finalCall = true;
                  this.callFinalProcess();
                }
              }
            });
          }
        }, (error) => {
          this.loading = false;
          console.info(error);
        });
      } else {
        resultLengthSel += 1;
        resultLengthNotSel += 1;
        if (this.lineItemTypeGroups.length === resultLengthSel
          && this.lineItemTypeGroups.length === resultLengthNotSel) {
          this.callFinalProcess();
        }
      }
    }
  }

  callFinalProcess() {
    const finalReq = [];
    if (this.insertLineItemReq.length > 0) {
      finalReq.push(this.ratesService.saveBatchProviderAccountPricing(this.insertLineItemReq));
    }
    if (this.updateLineItemToDeleteReq.length > 0) {
      finalReq.push(this.ratesService.updateProviderPricingLineItems(this.updateLineItemToDeleteReq));
    }
    if (finalReq.length > 0) {
      forkJoin([...finalReq]).subscribe(() => {
        if (this.updateLineItemNotDeleteReq.length > 0) {
          this.ratesService.updateProviderPricingLineItems(this.updateLineItemNotDeleteReq).subscribe(() => {
            this.getProviderAccountPricing();
          }, (error) => {
            this.loading = false;
          });
        } else {
          this.getProviderAccountPricing();
        }
      }, (error) => {
        this.loading = false;
      });
    } else {
      if (this.updateLineItemNotDeleteReq.length > 0) {
        this.ratesService.updateProviderPricingLineItems(this.updateLineItemNotDeleteReq).subscribe(() => {
          this.getProviderAccountPricing();
        }, (error) => {
          this.loading = false;
        });
      } else {
        this.getProviderAccountPricing();
      }
    }
  }

  updateSelectedLineItem(LineItems) {
    const self = this;
    let countLineItem = 0;
    return new Promise<void>((resolve) => {
      LineItems.forEach(lineItem => {
        const lineItemPricingType = lineItem.licensedOwnerLineItemTypeGroupNameKey.split('-');
        const arrFilterProvPricingsRegion = [];
        self.regionsList.forEach(region => {
          const filter = {
            specializedPricingRegionKey: region.specializedPricingRegionKey,
            lineItemKey: lineItem.lineItemKey,
            lineItemPricingTypeKey: lineItemPricingType[lineItemPricingType.length - 1].trim(),
            deleted: false,
          };
          const request = self.ratesService.getLicensedOwnerSpecializedPricingRegions(filter);
          arrFilterProvPricingsRegion.push(request);
        });

        if (arrFilterProvPricingsRegion.length > 0) {
          forkJoin([...arrFilterProvPricingsRegion]).subscribe(result => {
            if (result.length > 0) {
              if (result.some(region => region['body'].length > 0)) {
                const data = {
                  'lineItemKey': lineItem.lineItemKey,
                  'lineItemPricingTypeKey': lineItemPricingType[lineItemPricingType.length - 1].trim(),
                  'providerAccountKey': self.providerAccountKey,
                  'providerKey': self.providerKey,
                };
                self.ratesService.getProviderPricingDetails(data).subscribe(res => {
                  if (res.body.length > 0) {
                    const request = {
                      'deleted': false,
                      'updatedUserKey': sessionStorage.getItem('userKey'),
                      'providerAccountPricingKey': res.body[0].providerAccountPricingKey,
                    };
                    this.updateLineItemNotDeleteReq.push(request);
                    countLineItem += 1;
                    if (countLineItem === LineItems.length) {
                      resolve();
                    }
                  } else {
                    const lineItemPricingType = lineItem.licensedOwnerLineItemTypeGroupNameKey.split('-');
                    const request = {
                      'lineItemKey': lineItem.lineItemKey,
                      'lineItemPricingTypeKey': lineItemPricingType[lineItemPricingType.length - 1].trim(),
                      'providerAccountKey': self.providerAccountKey,
                      'providerKey': self.providerKey,
                      'updatedUserKey': '',
                      'insertedUserKey': sessionStorage.getItem('userKey'),
                      'deleted': false,
                    };
                    this.insertLineItemReq.push(request);
                    countLineItem += 1;
                    if (countLineItem === LineItems.length) {
                      resolve();
                    }
                  }
                }, (error) => {
                });
              } else {
                countLineItem += 1;
                if (countLineItem === LineItems.length) {
                  resolve();
                }
              }
            } else {
              countLineItem += 1;
              if (countLineItem === LineItems.length) {
                resolve();
              }
            }
          });
        } else {
          countLineItem += 1;
          if (countLineItem === LineItems.length) {
            resolve();
          }
        }
      });
    });
  }

  updateNotSelectedLineItem(lineItems, lineItemGroup) {
    const self = this;
    return new Promise<void>((resolve) => {
      const reqProviderPricing = [];
      self.ratesService.resolveRates.subscribe(() => {
        resolve();
      });
      lineItems.forEach(lineItem => {
        const data = {
          'lineItemKey': lineItem.lineItemKey,
          'providerAccountKey': self.providerAccountKey,
          'providerKey': self.providerKey,
          'deleted': false,
        };
        reqProviderPricing.push(self.ratesService.getProviderPricingDetails(data));
      });

      forkJoin([...reqProviderPricing]).subscribe((response) => {
        const getLineItemReq = [];
        const getLineItemToUpdate = [];
        response.forEach(res => {
          if (res['body'].length > 0) {
            res['body'].forEach(providerAccount => {
              const isPresetForOtherOffering = {
                'licensedOwnerKey': self.licensedOwnerKey,
                'lineItemKey': providerAccount.lineItemKey,
                'deleted': false,
              };
              getLineItemReq.push(self.ratesService.getLicensedownerOfferingsLineItemAssociations(isPresetForOtherOffering));
            });
          }
        });

        if (getLineItemReq.length > 0) {
          forkJoin([...getLineItemReq]).subscribe(lineItemRes => {
            let pricingCount = 0;
            lineItemRes.forEach(providerAccount => {
              const offeringRequest = [];
              const offeringLORequest = [];
              providerAccount['body'].forEach(offering => {
                if (offering.licensedOwnerServiceOfferingKey !== lineItemGroup.licensedOwnerServiceOfferingKey) {
                  const isServiceProvidedBySP = {
                    'offeringKey': offering.licensedOwnerServiceOfferingKey,
                    'providerAccountKey': self.providerAccountKey,
                    'providerKey': self.providerKey,
                    'deleted': false,
                  };

                  const isServiceProvidedByLO = {
                    'licensedOwnerOfferingKey': offering.licensedOwnerServiceOfferingKey,
                    'licensedOwnerKey': self.licensedOwnerKey,
                    'deleted': false,
                  };
                  offeringRequest.push(this.bubbleService.getProviderService(isServiceProvidedBySP));
                  offeringLORequest.push(this.bubbleService.getLicensedOwnerOfferings(isServiceProvidedByLO));
                }
              });
              if (offeringRequest.length > 0) {
                forkJoin([...offeringRequest]).subscribe(offeringRes => {
                  if (!(offeringRes.some(offeringSP => offeringSP['body'].length > 0))) {
                    const data = {
                      'lineItemKey': providerAccount['body'][0].lineItemKey,
                      'providerAccountKey': self.providerAccountKey,
                      'providerKey': self.providerKey,
                      'deleted': false,
                    };
                    getLineItemToUpdate.push(self.ratesService.getProviderPricingDetails(data));
                    pricingCount += 1;
                    if (pricingCount === lineItemRes.length) {
                      this.updateRequest(getLineItemToUpdate);
                    }
                  } else {
                    forkJoin([...offeringLORequest]).subscribe(offeringResLO => {
                      if (!(offeringResLO.some(offeringLO => offeringLO['body'].length > 0))) {
                        const data = {
                          'lineItemKey': providerAccount['body'][0].lineItemKey,
                          'providerAccountKey': self.providerAccountKey,
                          'providerKey': self.providerKey,
                          'deleted': false,
                        };
                        getLineItemToUpdate.push(self.ratesService.getProviderPricingDetails(data));
                        pricingCount += 1;
                      } else {
                        pricingCount += 1;
                      }
                      if (pricingCount === lineItemRes.length) {
                        this.updateRequest(getLineItemToUpdate);
                      }
                    });
                  }
                });
              } else {
                const data = {
                  'lineItemKey': providerAccount['body'][0].lineItemKey,
                  'providerAccountKey': self.providerAccountKey,
                  'providerKey': self.providerKey,
                  'deleted': false,
                };
                getLineItemToUpdate.push(self.ratesService.getProviderPricingDetails(data));
                pricingCount += 1;
                if (pricingCount === lineItemRes.length) {
                  this.updateRequest(getLineItemToUpdate);
                }
              }

            });
          });
        } else {
          resolve();
        }

      }, (error) => {
        this.loading = false;
      });
    });
  }

  updateRequest(getLineItemToUpdate) {
    if (getLineItemToUpdate.length > 0) {
      forkJoin([...getLineItemToUpdate]).subscribe(result => {
        result.forEach(lineItem => {
          lineItem['body'].forEach(element => {
            this.updateLineItemToDeleteReq.push({
              'deleted': true,
              'updatedUserKey': sessionStorage.getItem('userKey'),
              'providerAccountPricingKey': element.providerAccountPricingKey,
            });
            this.ratesService.setResolveRates();
          });
        });
      });
    } else {
      this.ratesService.setResolveRates();
    }
  }

  setAfterHoursRates(providerAccountPricing) {
    if (providerAccountPricing.afterHoursRateApproved !== '1') {
      providerAccountPricing.afterHoursRate = providerAccountPricing.emergencyRate;
    }
  }

  openAccountPricingException(accountPricingExceptionData) {
    const dialogRef = this.dialog.open(AccountPricingExceptionApprovalDialogComponent, {
      width: '70%',
      disableClose: true,
      position: {
        top: '2%',
      },
      data: {
        accountPricingExceptionData,
      },
    });
  }

  updatePricingExceptionRequestedRateRecord(data) {
    if (this.emergencyRateApproved) {
      const emergencyRateExceptionData = {
        providerAccountPricingExceptionKey: this.emergencyRateApproved.providerAccountPricingExceptionKey,
        requestedRate: data.emergencyRate,
      };
      this.ratesService.updateProviderAccountPricingException(emergencyRateExceptionData).subscribe(res => { });
    }
    if (this.emergencyAfterHoursRateApproved) {
      const emergencyAfterHoursRateExceptionData = {
        providerAccountPricingExceptionKey: this.emergencyAfterHoursRateApproved.providerAccountPricingExceptionKey,
        requestedRate: data.afterHoursRate,
      };
      this.ratesService.updateProviderAccountPricingException(emergencyAfterHoursRateExceptionData).subscribe(res => { });
    }
    if (this.scheduledRateApproved) {
      const scheduledRateExceptionData = {
        providerAccountPricingExceptionKey: this.scheduledRateApproved.providerAccountPricingExceptionKey,
        requestedRate: data.scheduledRate,
      };
      this.ratesService.updateProviderAccountPricingException(scheduledRateExceptionData).subscribe(res => { });
    }
  }

}
