import { Component, OnInit, ViewChild, Inject, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { AddUserIntoInviteProcessComponent } from '../user-invite-process/add-user-into-invite-process/add-user-into-invite-process.component';
import { UploadFileModelerComponent } from '../user-invite-process/upload-file-modeler/upload-file-modeler.component';
import { CommunicationTemplatesComponent } from '../user-invite-process/communication-templates/communication-templates.component';
import { UserInviteHistoryComponent } from '../user-invite-process/user-invite-history/user-invite-history.component';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-provider-user-invite-process',
  templateUrl: './provider-user-invite-process.component.html',
  styleUrls: ['./provider-user-invite-process.component.scss']
})
export class ProviderUserInviteProcessComponent implements OnInit {
  @ViewChild(AddUserIntoInviteProcessComponent, { static: true }) addUserIntoInviteProcess: AddUserIntoInviteProcessComponent;
  @ViewChild(UploadFileModelerComponent, { static: true }) uploadFileModeler: UploadFileModelerComponent;
  @ViewChild(CommunicationTemplatesComponent, { static: true }) communicationTemplate: CommunicationTemplatesComponent;
  @ViewChild(UserInviteHistoryComponent, { static: true }) inviteHistory: UserInviteHistoryComponent;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  selectedIndex = 0;
  userInvitesList: any = [];
  userInviteSentList: any = [];
  loading = false;
  templateKey: string;
  locationDetails: any;
  providerKey: string;
  securityGroups: any = [];
  isSelected = false;
  userTypeKey = 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4';
  departmentList: any = [];

  constructor(
    private userInviteProcess: UserInviteProcess,
    private commonService: CommonService,
    public dialog: MatDialogRef<ProviderUserInviteProcessComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.providerKey = this.data.providerKey;
    this.templateKey = this.data.templateKey;
    this.initDropdowns();
    this.getUserInvitations();
  }

  initDropdowns() {
    const securityFilter = {
      platform: 'ServicePlus',
      userTypeKey: 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4',
      deleted: false,
    };
    const deptFilter = {
      deleted: false,
    };

    const securityGrpReq = this.userInviteProcess.getSecurityGroups(securityFilter);
    const deptReq = this.commonService.getDepartments(deptFilter);

    this.loading = true;
    forkJoin([securityGrpReq, deptReq]).subscribe(result => {
      this.loading = false;
      this.securityGroups = result[0].body || [];
      this.departmentList = result[1].body || [];
    }, (error) => {
      this.loading = false;
    });
  }

  selectedInvitesChanged(userInvitationKeys) {
    this.communicationTemplate.sendInvitations(userInvitationKeys);
  }

  userListChanged(event) {
    this.getUserInvitations();
  }

  getUserInvitations() {
    this.isSelected = false;
    const objParams = {
      userKey: sessionStorage.getItem('userKey'),
      associationKey: this.providerKey,
      emailDate: null,
    };
    this.loading = true;
    this.userInviteProcess.getUserInvitations(objParams).subscribe(result => {
      if (result.success) {
        this.userInvitesList = result.body.filter(d => d.emailDate === null);
        this.userInviteSentList = result.body.filter(d => d.emailDate !== null);
      }
      this.loading = false;
    }, (error) => {
      console.log('Error', error.message);
    });
  }

  sendInviteWithEditedTemplate() {
    if (this.isSelected === true) {
      this.reSendInvitations();
    } else {
      const userInvitationKeys = [];
      this.userInvitesList.map(obj => {
        if (obj.userInvitationKey) {
          userInvitationKeys.push(obj.userInvitationKey);
        }
      });
      this.communicationTemplate.sendInvitations(userInvitationKeys);
    }
  }

  reSendInvitations() {
    const isValid = this.communicationTemplate.isEmailParamsValid();
    if (isValid) {
      this.inviteHistory.resendInvitations();
    }
  }

  sendInvitations() {
    if (this.selectedIndex === 0 || this.selectedIndex === 1) {
      const userInvitationKeys = [];
      this.userInvitesList.map(obj => {
        if (obj.userInvitationKey) {
          userInvitationKeys.push(obj.userInvitationKey);
        }
      });
      this.communicationTemplate.sendInvitations(userInvitationKeys);
    }
  }

  selecionChanged(status) {
    this.isSelected = status;
  }

  viewTemplate() {
    this.selectedIndex = 2;
  }

  tabChanged(event: any) {
    this.selectedIndex = event.index;
  }

  next() {
    this.selectedIndex = this.selectedIndex + 1;
  }

  closeDialog() {
    this.dialog.close();
  }
}
