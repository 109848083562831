import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddVendorEmployeeComponent } from 'src/app/dialogs/add-vendor-employee/add-vendor-employee.component';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableDirective, SortEvent } from 'src/app/shared/directive/sortable.directiveReg';
import { UserInviteProcessComponent } from '../../dialogs/user-invite-process/user-invite-process.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ngx-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  @Input() locationUser: any;
  @Input() locationKey: string;
  @Input() isParent: boolean;
  @Input() locationDetails: any;

  disableFlag: boolean = false;
  loading: boolean = false;
  employeeData: any = [];
  companyName: string;
  providerKey: string;
  defaultLocationKey: string;
  providerDetails: any;
  searchString: string;
  loggedInUserDetails: any;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    public formBuilder: UntypedFormBuilder,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    let isLocation = false;
    this.providerDetails;
    this.companyName = this.isParent ? this.locationDetails.name : sessionStorage.getItem('SPCompanyName');
    this.providerKey = sessionStorage.getItem('providerKey');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');
    if (!!this.locationKey) {
      isLocation = true;
      this.providerKey = this.locationKey;
    } else if (!!this.defaultLocationKey) {
      isLocation = true;
      this.providerKey = this.defaultLocationKey;
    } else if (this.providerDetails !== undefined) {
      this.providerKey = this.providerDetails.providerKey;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
    }

    if (sessionStorage.getItem('pagePermission') === 'Read' && !isLocation) {
      this.disableFlag = true;
    }

    this.getEmployeesList();
  }

  // get employee list details.
  getEmployeesList() {
    let associationKey = sessionStorage.getItem('providerParentKey');

    if (associationKey === 'null' || !associationKey) {
      associationKey = sessionStorage.getItem('providerKey');
    }

    const empFilter = {
      associationKey: this.providerKey,
      userTypeKey: sessionStorage.getItem('userTypeKey'),
      deleted: false,
    };

    const filter = {
      associationKey: sessionStorage.getItem('providerKey'),
      userTypeKey: sessionStorage.getItem('userTypeKey'),
      deleted: false,
    };

    const employeeReq = this.userService.getUserLists(empFilter);
    const loggedInUserReq = this.userService.getUserLists(filter);

    this.loading = true;
    forkJoin([employeeReq, loggedInUserReq]).subscribe((result: any) => {
      this.loggedInUserDetails = result[1].body.find(d => d.userKey === sessionStorage.getItem('userKey'));
      const employeeData = result[0].body.filter(d => d.userKey !== sessionStorage.getItem('userKey'));
      this.employeeData = this.utilityService.sortJsonByKey(employeeData, 'insertedTimestamp', 'DESC');

      this.loading = false;
    }, (error) => {
      console.info('error,', error);
      this.loading = false;
    });
  }

  openUserInviteDialog() {
    // const userKey = employee.userKey;
    const dialogRef = this.dialog.open(UserInviteProcessComponent, {
      hasBackdrop: false,
      width: '85%',
      height: '85%',
      position: {
        top: '5%',
      },
      data: { 'locationKey': this.providerKey, 'locationDetails': this.locationDetails },
    });

    // on close reload employee details
    dialogRef.afterClosed().subscribe(result => {
      this.getEmployeesList();
    });
  }

  addNewVendorEmployee(type, user) {
    const vendorEmployeeDialog = this.dialog.open(AddVendorEmployeeComponent, {
      width: '85%',
      height: '90%',
      position: {
        top: '4%',
      },
      data: { 'value': type, 'userKey': user.userKey },
    });

    // on close reload employee details
    vendorEmployeeDialog.afterClosed().subscribe(result => {
      this.getEmployeesList();
    });
  }

  search(event: any) {
    this.searchString = event;
  }

  onSort({ column, direction }: SortEvent) {
    this.employeeData = this.utilityService.sortData(this.headers, column, direction, this.employeeData);
  }
}
