import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BillingDetailsComponent } from 'src/app/shared/billing-details/billing-details.component';
import { SetupDispatchDetailsComponent } from 'src/app/shared/setup-dispatch-details/setup-dispatch-details.component';
import { LocationDetailsComponent } from 'src/app/shared/location-details/location-details.component';

@Component({
  selector: 'ngx-add-location-dialog',
  templateUrl: './add-location-dialog.component.html',
  styleUrls: ['./add-location-dialog.component.scss'],
})

export class AddLocationDialogComponent implements OnInit {

  @ViewChild(BillingDetailsComponent, { static: false }) billingDetails: BillingDetailsComponent;
  @ViewChild(SetupDispatchDetailsComponent, { static: false }) dispatchDetails: SetupDispatchDetailsComponent;
  @ViewChild(LocationDetailsComponent, { static: false }) locationDetails: LocationDetailsComponent;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  selectedIndex: number = 0;
  saveDispatchDetails: boolean = false;
  goNextPage: boolean = false;
  locationKey: string;
  providerLocation: any;
  providerAccount: any = [];

  constructor(
    public dialog: MatDialogRef<AddLocationDialogComponent>,
    public userDialog: MatDialog,
  ) { }

  ngOnInit() {
    sessionStorage.removeItem('defaultNewLocationKey');
  }

  closeDialog() {
    this.dialog.close();
    sessionStorage.removeItem('defaultNewLocationKey');
  }

  saveCompleted(status) {
    if (status === 'true' && this.goNextPage) {
      this.locationKey = sessionStorage.getItem('defaultNewLocationKey');
      this.providerAccount.providerKey = this.locationKey;
      this.stepper.selected.completed = true;
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  getNewLocation(data) {
    this.providerLocation = data;
  }

  currentStage(event) {
    this.selectedIndex = event.selectedIndex;
  }

  previous() {
    this.selectedIndex = this.selectedIndex - 1;
  }

  next(goNext) {
    if (this.selectedIndex === 0) {
      if (sessionStorage.getItem('defaultNewLocationKey')) {
        this.locationDetails.addUpdateLocationDetails();
      } else {
        this.locationDetails.addLocationDetails();
      }
    }

    if (this.selectedIndex === 1) {
      this.dispatchDetails.updateProviderDispatchDetails();
    }

    if (this.selectedIndex === 2) {
      this.billingDetails.updateProviderBillingDetails();
    }

    const arrNoNextPage = [0, 1, 2];
    this.goNextPage = goNext;

    if (goNext && arrNoNextPage.indexOf(this.selectedIndex) === -1) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }
}
