import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserInviteProcess {

    constructor(private http: HttpClient) { }

    getSecurityGroups(data: any = {}): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/securitygroups/get`, data);
    }

    getUserInvitations(params = {}): Observable<any> {
        return this.http.post(`${environment.baseUrl}getUserInvitations`, params).pipe(
            map((response: any) => {
                if (environment.production) {
                    const filteredData = response.body.filter(user => user.email && user.email.indexOf('yopmail.com') === -1);
                    response.body = filteredData;
                    return response;
                } else {
                    return response;
                }
            })
        );
    }

    checkEmailInUserInvitations(params: any = {}): Observable<any> {
        if (params.deleted === undefined) {
            params.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations/get`, params);
    }

    addUserInvitations(data: any = {}): Observable<any> {
        if (data.cellPhone && !data.cellPhone.startsWith('+')) {
            data.cellPhone = '+1' + data.cellPhone;
        }

        if (!data.cellPhone) {
            data.cellPhone = '+19999999999';
        }
        return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations`, data);
    }

    addUserInvitationsBatch(data: any = {}): Observable<any> {
        return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations/batch`, data);
    }

    updateUserInvitations(data: any = {}): Observable<any> {
        if (data.cellPhone && !data.cellPhone.startsWith('+')) {
            data.cellPhone = '+1' + data.cellPhone;
        }

        if (!data.cellPhone) {
            data.cellPhone = '+19999999999';
        }
        return this.http.put(`${environment.apiUrl}systemsecurities/userinvitations`, data);
    }

    updateBatchUserInvitations(data: any = {}): Observable<any> {
        return this.http.put(`${environment.apiUrl}systemsecurities/userinvitations/batch`, data);
    }

    // get communication email template
    getCommunicationEmailTemplate(data): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationtemplateemails/get`, data);
    }

    // get communication template variables
    getCommunicationTemplateVariables(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}getCommunicationTemplateVariables`, data);
    }

    // update communication templates
    getAllCommunicationTemplateVariables(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationtemplatevariables/get`, data);
    }

    // update communication templates
    updateCommunicationEmailTemplate(data): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}systemsecurities/communicationtemplateemails`, data);
    }

    // update communication logs
    updateCommunicationLogs(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationlogs`, data);
    }

    insertBatchCommunicationLogs(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationlogs/batch`, data);
    }

    // send invite emails to Licensed Owner users
    sendInviteEmails(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}inviteEmailSend`, data);
    }

    // get communication logs
    getCommunicationLogs(data: any = {}): Observable<any> {
        if (data.deleted === undefined) {
            data.deleted = false;
        }
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationlogs/get`, data);
    }

    // send invite email
    inviteUser(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}inviteUser`, data);
    }

    // get communication templates names
    getCommunicationTemplateNames(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationtemplates/get`, data);
    }

    // get communication template messages
    getCommunicationTemplateMessages(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}systemsecurities/communicationtemplatemessages/get`, data);
    }

    // update communication template messages
    updateCommunicationTemplateMessages(data): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}systemsecurities/communicationtemplatemessages`, data);
    }

    // get user invitations
    getUserinvitations(params: any = {}): Observable<any> {
        if (params.deleted === undefined) {
            params.deleted = false;
        }
        return this.http.post(`${environment.apiUrl}systemsecurities/userinvitations/get`, params);
    }

}
