import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule, MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatRadioButton, MatRadioModule, MatRadioChange } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { NbCardModule, NbMenuModule } from '@nebular/theme';
import { Observable } from 'rxjs';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete-esb';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'ngx-provider-search',
  standalone: true,
  imports: [
    NbCardModule,
    NbMenuModule,
    SharedModule,
    CommonModule,
    MatTableModule,
    FormsModule,
    GoogleMapsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    GooglePlaceModule,
    MatFormFieldModule, 
    MatPaginatorModule  
  ],
  templateUrl: './provider-search.component.html',
  styleUrls: ['./provider-search.component.scss']
})
export class ProviderSearchComponent {

  ngOnInit(): void {
    this.services$ = this.commonService.getVendorServices();
  }

  constructor(
    private providerAdminService: ProviderAdminService,
    private commonService: CommonService,
  ) { }

  displayedColumns: string[] = ['name', 'address', 'distance', 'port', 'email', 'phone', 'terms', 'network'];
  dataSource = new MatTableDataSource<Provider>([]);
  providers: Provider[] = [];
  serviceData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  services$: Observable<[]>
  subservices$: Observable<[]>

  loading: boolean = false;
  hasGeolocation: boolean = false;
  center: google.maps.LatLngLiteral = { lat: 37.422131, lng: -122.084801 };

  distances: Distance[] = [
    { value: 5, viewValue: 'Within 5 miles' },
    { value: 10, viewValue: 'Within 10 miles' },
    { value: 15, viewValue: 'Within 15 miles' },
    { value: 25, viewValue: 'Within 25 miles' },
    { value: 35, viewValue: 'Within 35 miles' },
    { value: 50, viewValue: 'Within 50 miles' },
    { value: 100, viewValue: 'Within 100 miles' },
  ];

  location: SearchLocation = {
    latitude: this.center.lat,
    longitude: this.center.lng,
    range: 50,
    subservice: 0
  }

  filters: FilterCriteria = {
    networkStatus: 'both',
    showDNU: true
  }

  handleAddressChange(address){
    console.log(address);
    this.hasGeolocation = true;
    this.center = address.geometry.location.toJSON();
    this.location.latitude = this.center.lat;
    this.location.longitude = this.center.lng;
  }

  onSearch(): void {
    console.log('this is a search');
    this.searchProviders(this.location);
  }

  searchProviders(location: SearchLocation): void {
    this.loading = true;

    this.providerAdminService.getProviderSearchV3(location).subscribe((data: Provider[]) => {
      this.loading = false;
      this.providers = data;
      this.dataSource.paginator = this.paginator;
      this.onFilterChange(this.filters);
    })
  }

  byMiles(x, y) {
    return x.Distance - y.Distance;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNetworkFilterChange(event: MatRadioChange): void {
    this.filters.networkStatus = event.value;
    this.onFilterChange(this.filters);
  }

  onDNUFilterChange(event: MatCheckboxChange): void {
    this.filters.showDNU = event.checked;
    this.onFilterChange(this.filters);
  }

  onFilterChange(filters: FilterCriteria): void {
    let filteredProviders = this.providers;
  
    // Apply network status filter
      switch (filters.networkStatus) {
        case 'in':
          filteredProviders = filteredProviders.filter(x => x.InNetwork);
          break;
        case 'out':
          filteredProviders = filteredProviders.filter(x => !x.InNetwork);
          break;
      }
  
    // Apply DNU filter
    if (!filters.showDNU) {
      filteredProviders = filteredProviders.filter(x => !x.IsDNU);
    }
  
    // Sort and update data source
    filteredProviders.sort(this.byMiles);
    this.dataSource.data = filteredProviders;
    this.paginator.firstPage();
  }

  getSubservices(service): void {
    if (service) {
     this.subservices$ = this.commonService.getSubService(service);
     console.log(service)
    } else {
      this.location.subservice = 0;
    }
  }


}


export interface Provider {
  ProviderKey: string,
  ProviderId: number,
  ParentKey: string,
  Name: string,
  PhysicalAddress1: string,
  PhysicalCity: string,
  PhysicalStateKey: string,
  PhysicalLatitude: number,
  PhysicalLongitude: number,
  Distance: number,
  IsDNU: boolean,
  DispatchEmail: string,
  TollFreePhone: string,
  BusinessHoursPhone: string,
  AfterHoursPhone: string,
  InNetwork: boolean,
  PaymentTermKey: string,
}

interface Distance {
  value: number;
  viewValue: string;
}

interface SearchLocation {
  latitude: number;
  longitude: number;
  range: number;
  subservice: number;
}

interface FilterCriteria {
  networkStatus?: 'in' | 'out' | 'both';
  showDNU?: boolean;
}