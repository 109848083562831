import { Component, OnInit, Inject, Optional, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NationalAccountService } from 'src/app/services/national-account.service';
import { NbToastrService } from '@nebular/theme';
import { patterns } from 'src/assets/patterns';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-national-account-dialog',
  templateUrl: './national-account-dialog.component.html',
  styleUrls: ['./national-account-dialog.component.scss']
})
export class NationalAccountDialogComponent implements OnInit {

  addServiceForm: UntypedFormGroup;
  loading = false;
  insertNationalAccountData: any;
  buttonText: string;
  licensedOwnerOfferingData: any;
  subServiceData: any;
  licensedOwnerOffering: any;
  eventNationalAccounts = new EventEmitter();
  accountList: any;
  customerKey: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private nationalAccountService: NationalAccountService,
    public dialogRef: MatDialogRef<NationalAccountDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService,
    private commonService: CommonService
  ) {
    this.buttonText = data.value;
  }

  ngOnInit() {
    if (this.data.customerKey) {
      this.customerKey = this.data.customerKey;
    } else {
      this.customerKey = sessionStorage.getItem('customerKey');
    }

    this.addServiceForm = this.formBuilder.group({
      serviceType: ['', [Validators.required]],
      subServiceType: [''],
      accountName: [''],
      priority: ['', [Validators.required, Validators.pattern(patterns.numberPattern)]],
      accountNumber: ['', [Validators.required]],
      pOMask: ['', [Validators.required]]
    });

    this.getServiceData();

    if (this.buttonText === 'Save') {
      let serviceId = this.data.data.serviceId;
      let subServiceId = this.data.data.subServiceId;
      if (this.data.data.serviceId === null) {
        serviceId = this.data.data.subServiceId;
        subServiceId = '';
      }
      this.addServiceForm.get('serviceType').setValue(serviceId);
      this.addServiceForm.get('subServiceType').setValue(subServiceId);
      this.addServiceForm.get('accountName').setValue(this.data.data.nationalAccountKey);
      this.addServiceForm.get('priority').setValue(this.data.data.priority);
      this.addServiceForm.get('accountNumber').setValue(this.data.data.accountNumber);
      this.addServiceForm.get('pOMask').setValue(this.data.data.pOMask);
    }
    this.getNationalAccountList();
  }


  getNationalAccountList() {
    const filter = {};
    this.nationalAccountService.getNationalAccountList(filter).subscribe((res: any) => {
      this.accountList = res.body;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  getServiceData() {
    this.loading = true;
    const filter = {
      parentKey: null,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey')
    };

    this.commonService.getLicensedOwnerServiceOfferingsDetails(filter).subscribe(serviceResponse => {
      this.licensedOwnerOfferingData = serviceResponse.body;
      if (serviceResponse.success) {
        if (this.buttonText === 'Save') {
          this.getSubservice();
        }
      } else {
        this.toast.warning(serviceResponse.message[0], 'Error');
      }
      this.loading = false;
    }, (error) => {
      this.toast.warning(error, 'Error');
      console.log(error);
      this.loading = false;
    });
  }

  getSubservice() {
    this.loading = true;
    const filter = {
      parentKey: this.addServiceForm.controls.serviceType.value,
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey')
    };

    this.commonService.getLicensedOwnerServiceOfferingsDetails(filter).subscribe(subServiceResponse => {
      this.subServiceData = subServiceResponse.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  insertData() {

    if (this.addServiceForm.invalid) {
      this.addServiceForm.markAllAsTouched();
      return false;
    }
    this.loading = true;
    const serviceTypeId = this.addServiceForm.controls.serviceType.value;
    const subServiceTypeId = this.addServiceForm.controls.subServiceType.value;
    const licensedOwnerOfferingKey = (subServiceTypeId) ? subServiceTypeId : serviceTypeId;
    if (this.buttonText === 'Save') {
      this.insertNationalAccountData = {
        customerKey: this.customerKey,
        nationalAccountKey: this.addServiceForm.controls.accountName.value,
        nationalBillingAccountKey: this.data.data.nationalBillingAccountKey,
        priority: this.addServiceForm.controls.priority.value,
        accountNumber: this.addServiceForm.controls.accountNumber.value,
        pOMask: this.addServiceForm.controls.pOMask.value,
        updatedUserKey: sessionStorage.getItem('userKey'),
        updatedTimestamp: (new Date).getTime(),
        licensedOwnerOfferingKey
      };
      this.loading = true;
      this.nationalAccountService.updateNationalAccountDetails(this.insertNationalAccountData).subscribe(res => {
        if (res.success) {
          this.toast.success(res.message[0], 'Success');
        } else {
          this.toast.warning(res.message[0], 'Error');
        }
        this.loading = false;
        this.eventNationalAccounts.emit();
        this.dialogRef.close();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.insertNationalAccountData = {
        nationalAccountKey: this.addServiceForm.controls.accountName.value,
        customerKey: this.customerKey,
        deleted: false,
        insertedTimestamp: (new Date).getTime(),
        insertedUserKey: sessionStorage.getItem('userKey'),
        priority: this.addServiceForm.controls.priority.value,
        accountNumber: this.addServiceForm.controls.accountNumber.value,
        pomask: this.addServiceForm.controls.pOMask.value,
        systemDefault: true,
        updatedTimestamp: '',
        updatedUserKey: '',
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        licensedOwnerOfferingKey
      };
      this.loading = true;
      this.nationalAccountService.insertNationalAccountDetails(this.insertNationalAccountData).subscribe(res => {
        if (res.success) {
          this.toast.success(res.message[0], 'Success');
        } else {
          this.toast.warning(res.message[0], 'Error');
        }
        this.loading = false;
        this.eventNationalAccounts.emit();
        this.dialogRef.close();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public errorHandling = (control: string, error: string) => {
    return this.addServiceForm.controls[control].hasError(error);
  }
}
