import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'differenceInHoursBetweenTwoTimes'
})
export class DifferenceInHoursBetweenTwoTimesPipe implements PipeTransform {

  transform(strStartTime: any, strEndTime: any): any {

    let date1 = new Date();
    let date2 = new Date();
    let day = 1;

    strStartTime = strStartTime.trim();
    strEndTime = strEndTime.trim();
    let strStartTimeAnteMeridiemPostMeridiem = strStartTime.slice(-2);
    let strEndTimeAnteMeridiemPostMeridiem = strEndTime.slice(-2);
    strStartTimeAnteMeridiemPostMeridiem = strStartTimeAnteMeridiemPostMeridiem.toUpperCase();
    strEndTimeAnteMeridiemPostMeridiem = strEndTimeAnteMeridiemPostMeridiem.toUpperCase();

    const arrAMPM = ['AM', 'PM'];

    // Get next day timing if Ante Meridiem & Post Meridiem is same

    if ((arrAMPM.includes(strStartTimeAnteMeridiemPostMeridiem)) &&
      (arrAMPM.includes(strEndTimeAnteMeridiemPostMeridiem)) &&
      (strStartTimeAnteMeridiemPostMeridiem === strEndTimeAnteMeridiemPostMeridiem)) {
      day = 2;
    }

    date1 = new Date('Jan 1, 2020 ' + strStartTime);
    date2 = new Date('Jan ' + day + ', 2020 ' + strEndTime);

    let diff = date2.getTime() - date1.getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;

    const minutes = Math.floor(diff / 1000 / 60);

    hours = hours % 24;
    if ((hours === 0 && minutes === 0) || (hours === 23 && minutes === 59)) {
      return '24 Hours';
    } else {
      return strStartTime + ' - ' + strEndTime;
    }
  }

}
