import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TierFlatNode, TierNode } from 'src/app/services/hierarchy.service';
import { HierarchyService } from '../../services/hierarchy.service';
import { TierService } from '../../services/tier.service';
import { CustomerService } from '../../services/customer.service';
import { MatDialog } from '@angular/material/dialog';
import { MyProfileDialogComponent } from 'src/app/dialogs/my-profile-dialog/my-profile-dialog.component';
import { forkJoin } from 'rxjs';
import { SortEvent, SortableHeaderDirective } from '../directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-customer-hierarchy',
  templateUrl: './customer-hierarchy.component.html',
  styleUrls: ['./customer-hierarchy.component.scss']
})

export class CustomerHierarchyComponent implements OnInit {

  @Input() inCustomerKey: string;
  @Input() customerHierarchy = false;
  @Output() customerHierarchyDetails = new EventEmitter<any>();
  @Input() isViewMasterCustomerDetails = false;
  @Input() isLicensedOwnerCustomer = false;
  @Input() isClearSearch = false;
  @Input() isAddMasterCustomerDetails = false;
  @Input() isMasterCsutomer = false;
  @Input() masterCustomerKey = '';
  @Input() selectedCustomerKey: string;
  @Input() isAdminCustomer = false;

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TierFlatNode, TierNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TierNode, TierFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TierFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TierFlatNode>;

  treeFlattener: MatTreeFlattener<TierNode, TierFlatNode>;

  dataSource: MatTreeFlatDataSource<TierNode, TierFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TierFlatNode>(true /* multiple */);
  tempNode: TierNode;
  loading: boolean;
  customerTierStructureData: any;
  customerData: any = [];
  resultTiers: any = [];
  customerTireStructureRes: any = [];
  tempParentNode: any;
  customerAllData: any;
  customerKey: string;
  customerHierarchyData: any;
  customerHierarchyTree: any;
  ultimateParent = [];
  selectedCustomerHierarchy = '';
  selectedChildCustomer: any;
  isLicensedOwner: boolean;
  isChildCustomer = false;
  customerTierStructureKey: any;
  ultimateParentKey: any;
  tiers: any = [];
  isDomainBreakdown: boolean;
  hierarchyForm: UntypedFormGroup;
  disableBtn: boolean;
  licensedOwnerKey: string;

  constructor(private hierarchyService: HierarchyService,
    private tierService: TierService,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private formBuilder: UntypedFormBuilder,
    private toast: NbToastrService) {

  }

  ngOnInit() {
  }

  ngOnChanges(): void {

    let formValues = { value: '', disabled: false };
    if (!this.customerKey && !this.isLicensedOwnerCustomer) {
      // for licensed owner
      formValues = { value: '', disabled: true };
    }
    if (this.inCustomerKey) {
      this.customerKey = this.inCustomerKey;
    } else {
      this.customerKey = sessionStorage.getItem('customerKey');
    }

    this.hierarchyForm = this.formBuilder.group({
      tierName: [formValues],
      tierNickname: [formValues]
    });

    this.checkChildCustomer();
    this.setTree();
    this.isDomainBreakdown = sessionStorage.getItem('subDomainKey') === 'breakdownnow' ? true : false;
    this.setHierarchyDropdown();

    this.isChildCustomer = false;
    if (this.isClearSearch || this.isAddMasterCustomerDetails) {
      this.tiers = [];
    }
    if (!this.customerKey) {
      this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
      if (sessionStorage.getItem('licensedOwnerKey')) {
        this.isLicensedOwner = true;
      }
    } else {
      if (sessionStorage.getItem('licensedOwnerKey')) {
        this.isLicensedOwner = true;
      }
    }
  }

  setHierarchyDropdown() {
    let tier = this.tierService.getTierName();
    this.hierarchyForm.controls.tierName.setValue(tier.tierName ? tier.tierName : '');
    this.hierarchyForm.controls.tierNickname.setValue(tier.tierNickname ? tier.tierNickname : '');
  }

  drop($event) {

  }

  dragStart() { }
  dragHover($event) { }
  dragHoverEnd() { }

  dragEnd() { }

  setTree() {
    this.loading = true;
    const customer = this.customerService.getCustomerDetails({ licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey') });
    const tier = this.tierService.getTierDetails({ customerKey: this.customerKey });

    forkJoin([customer, tier]).subscribe(result => {
      this.customerAllData = result[0].body;
      this.customerTierStructureData = result[1].body;

      this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
        this.isExpandable, this.getChildren);
      // this.treeControl = new FlatTreeControl<TierFlatNode>(this.getLevel, this.isExpandable);
      this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
      if (this.customerHierarchy) {
        this.onSort({ column: 'customerName', direction: 'asc' });
        this.getCustomerHierarchy(this.customerAllData, this.inCustomerKey);
      } else {
        this.getTreeData(this.customerTierStructureData);
      }
      this.loading = false;
      this.hierarchyService.dataChange.subscribe(data => {
        this.dataSource.data = data;
      });
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

    this.treeControl = new FlatTreeControl<TierFlatNode>(this.getLevel, this.isExpandable);
  }

  getLevel = (node: TierFlatNode) => node.level;

  isExpandable = (node: TierFlatNode) => node.expandable;

  getChildren = (node: TierNode): TierNode[] => node.children;

  hasChild = (_: number, _nodeData: TierFlatNode) => _nodeData.expandable = true;

  hasNoContent = (_: number, _nodeData: TierFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TierNode, level: number) => {
    let count = 0;
    if (!this.customerHierarchy) {
      const tier = this.customerTierStructureData.find(value => value.tierName === node.item);

      if (tier) {
        count = this.customerAllData.filter(value => value.customerTierStructureKey === tier.customerTierStructureKey).length;
      }
    }

    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TierFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.customerKey = node.customerKey;
    flatNode.customerName = node.item;
    flatNode.expandable = !!node.children;
    flatNode.isCustomer = count ? true : false;
    flatNode.count = count;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    this.treeControl.expand(flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TierFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TierFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TierFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TierFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TierFlatNode): void {
    let parent: TierFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TierFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TierFlatNode): TierFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TierFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this.tempParentNode = parentNode;

    if (!this.tempParentNode.customerTierStructureKey) {
      const filter = {
        customerKey: '01714f65-c841-46a2-a49b-2c7e54da48c7',
        tierName: parentNode.item
      };
      this.tierService.getTierDetails(filter).subscribe(result => {
        this.tempParentNode = result.body[0];
      }, (error) => {
        console.log(error);
      });
    }

    if (!parentNode.children) {
      parentNode.children = [];
    }

    if (this.tempNode) {
      const lastElement = this.tempNode.children[this.tempNode.children.length - 1];
      if (lastElement.item === '') {
        this.tempNode.children.pop();
      }
    }
    this.tempNode = parentNode;
    this.hierarchyService.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: TierFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.hierarchyService.updateItem(nestedNode!, itemValue);
    // const tierData = {
    //   customerKey: '01714f65-c841-46a2-a49b-2c7e54da48c7',
    //   tierName: itemValue,
    //   tierNickname: this.tempParentNode.customerTierStructureKey,
    //   insertedTimestamp: new Date().getTime,
    //   updatedTimestamp: new Date().getTime,
    //   insertedUserKey: sessionStorage.getItem('userKey'),
    //   updatedUserKey: sessionStorage.getItem('userKey'),
    //   systemDefault: true,
    //   deleted: false
    // };

    // this.tierService.addTier(tierData).subscribe(res => {
    //   this.hierarchyService.updateItem(nestedNode!, itemValue);
    //   this.loading = false;
    // }, (error) => {
    //   console.log(error);
    //   this.loading = false;
    // });
  }

  getTreeData(tiers) {
    this.resultTiers = [];
    this.createTiers(tiers);
  }

  createTiers(tiers) {
    let level = 1;
    tiers.forEach(tier => {
      if (tier.tierLevel === level) {
        const parentTier = {
          item: tier.tierName,
          customerTierStructureKey: tier.customerTierStructureKey,
          children: []
        };

        this.resultTiers.push(parentTier);
      }
    });
    this.recursiveCreateTiers(tiers, this.resultTiers, ++level);
    this.hierarchyService.dataChange.next(this.resultTiers);
    this.hierarchyService.setTreeData(this.dataSource.data);
  }

  recursiveCreateTiers(tiers, resultTiers, level) {
    resultTiers.forEach(tierChild => {
      tiers.forEach(tier => {
        if (tier.tierLevel === level) {
          const childTier = {
            item: tier.tierName,
            customerTierStructureKey: tier.customerTierStructureKey,
            children: []
          };
          tierChild.children.push(childTier);
        }
      });
      this.recursiveCreateTiers(tiers, tierChild.children, ++level);
    });
  }

  displayCustomers(data) {
    if (this.customerHierarchy) {
      this.customerHierarchyDetails.emit(data);
    } else {
      this.loading = true;
      const tierData = this.customerTierStructureData.find(value => value.tierName === data.item);
      this.customerService.getCustomerDetails({ customerTierStructureKey: tierData.customerTierStructureKey }).subscribe(res => {
        this.customerData = res.body;
        this.loading = false;
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }

  displayProfile(customer: any) {
    this.dialog.open(MyProfileDialogComponent, {
      width: '85%',
      position: {
        top: '4%',
      },
      data: customer
    });
  }

  getCustomerHierarchy(customers, childCustomerKey) {
    this.customerHierarchyData = [];
    this.customerHierarchyTree = [];
    const parentKey = [];
    customers.forEach(customer => {
      if (customer.customerKey === childCustomerKey) {
        const customerName = {
          item: customer.customerName,
          customerKey: customer.customerKey,
          parentKey: customer.parentKey,
          children: []
        };
        this.customerHierarchyData.push(customerName);
        parentKey.push(customerName);
      }
    });
    this.selectedCustomerHierarchy = parentKey[0].customerKey;
    if (this.customerHierarchyData[0].parentKey === null || this.customerHierarchyData[0].parentKey === '0') {
      this.customerHierarchyTree.push(this.customerHierarchyData[0]);
      this.createCustomerHierarchy(customers, parentKey);
    } else {
      this.recursiveCustomerHierarchy(customers, parentKey);
      this.customerHierarchyData.reverse();
      this.customerHierarchyTree.push(this.customerHierarchyData[0]);
      // this.createCustomerHierarchy(customers, this.customerHierarchyTree);
      this.recursiveCustomerHierarchyTree(this.customerHierarchyTree, this.customerHierarchyData);
      this.recursiveCreateCustomerHierarchy(customers, this.selectedChildCustomer);
    }
    this.hierarchyService.dataChange.next(this.customerHierarchyTree);
    this.hierarchyService.setTreeData(this.dataSource.data);
  }

  createCustomerHierarchy(customers, parentKey) {
    this.ultimateParent = parentKey;
    this.ultimateParent.forEach(parent => {
      customers.forEach(customer => {
        if (customer.parentKey === parent.customerKey) {
          const childCustomer = {
            item: customer.customerName,
            customerKey: customer.customerKey,
            parentKey: customer.parentKey,
            children: []
          };
          parent.children.push(childCustomer);
        }
      });
      this.recursiveCreateCustomerHierarchy(customers, parent.children);
    });
  }

  // recursive function to create customer hierarchy
  recursiveCreateCustomerHierarchy(customers, childs) {
    childs.forEach(child => {
      customers.forEach(customer => {
        if (customer.parentKey === child.customerKey) {
          const childCustomer = {
            item: customer.customerName,
            customerKey: customer.customerKey,
            parentKey: customer.parentKey,
            children: []
          };
          child.children.push(childCustomer);
        }
      });
      this.recursiveCreateCustomerHierarchy(customers, child.children);
    });
  }

  // recursive function to set customer hierarchy tree
  recursiveCustomerHierarchyTree(customerHierarchyTree, customerHierarchyData) {
    this.selectedChildCustomer = customerHierarchyTree.length > 0 ? customerHierarchyTree : this.selectedChildCustomer;
    customerHierarchyTree.forEach(customerHierarchy => {
      customerHierarchyData.forEach(data => {
        if (customerHierarchy.customerKey === data.parentKey) {
          const childCustomer = {
            item: data.item,
            customerKey: data.customerKey,
            parentKey: data.parentKey,
            children: []
          };
          customerHierarchy.children.push(childCustomer);
        }
      });
      this.recursiveCustomerHierarchyTree(customerHierarchy.children, this.customerHierarchyData);
    });
  }

  // recursive function to get customer hierarchy
  recursiveCustomerHierarchy(customers, parentKey) {
    parentKey.forEach(parent => {
      customers.forEach(customer => {
        if (customer.customerKey === parent.parentKey) {
          const childCustomer = {
            item: customer.customerName,
            customerKey: customer.customerKey,
            parentKey: customer.parentKey,
            children: []
          };
          this.customerHierarchyData.push(childCustomer);
          if (childCustomer.parentKey !== null) {
            if (childCustomer.parentKey !== '0') {
              parent = [];
              parent.push(childCustomer);
            } else {
              parent = [];
            }
          } else {
            parent = [];
          }
        }
      });
      this.recursiveCustomerHierarchy(customers, parent);
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.customerAllData = this.utilityService.sortData(this.headers, column, direction, this.customerAllData);
  }

  checkIsLicensedOwner() {
    if (!this.customerKey) {
      if (sessionStorage.getItem('licensedOwnerKey')) {
        this.isLicensedOwner = true;
      }
    } else if (this.customerKey === 'new') {
      this.isLicensedOwner = false;
      return false;
    } else {
      if (sessionStorage.getItem('licensedOwnerKey')) {
        this.isLicensedOwner = true;
      }
    }
  }

  initializeDropDowns() {
    const filter = { customerKey: this.isChildCustomer ? this.ultimateParentKey : this.customerKey };
    const objContactCenter = { deleted: false };
    // to get ultimate parent tier level
    const tierLevelFilter = { customerTierStructureKey: this.customerTierStructureKey };

    const tiersReq = this.tierService.getTierDetails(filter);
    const tiersLevelReq = this.tierService.getTierDetails(tierLevelFilter);
    this.loading = true;

    forkJoin([tiersReq, tiersLevelReq]).subscribe(results => {
      if (sessionStorage.getItem('customerKey') && this.isChildCustomer && !this.isViewMasterCustomerDetails) {
        // this.tiers = [];
        const ultimateParentTierLevel = results[1].body[0];
        results[0].body.forEach(tier => {
          if (tier.tierLevel > ultimateParentTierLevel.tierLevel) {
            this.tiers.push(tier);
          }
        });
      } else {
        if (filter.customerKey || this.isViewMasterCustomerDetails) {
          this.tiers = results[0].body || [];
        }
      }
      if (this.tiers.length > 0) {
        this.tiers = this.tiers.sort((a, b) => {
          a = parseInt(a.tierLevel);
          b = parseInt(b.tierLevel);
          return a - b;
        });
      }
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  checkChildCustomer() {
    const filter = {
      customerKey: this.customerKey
    };

    this.customerService.getCustomerDetails(filter).subscribe(res => {
      const customerDetails = res.body[0];
      if (customerDetails.parentKey) {
        this.getUltimateParentCustomer();
      } else {
        this.isChildCustomer = false;
        this.initializeDropDowns();
      }
    }, (error) => {
      console.log(error);
    });
  }

  getUltimateParentCustomer() {
    const filter = {
      licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
      searchText: null,
      ultimateFilter: 0,
      ultimateParentKey: null,
      customerKey: this.customerKey
    };

    this.customerService.getCustomersWithUltimateParent(filter).subscribe(res => {
      if (res.success) {
        const customer = res.body[0];
        this.isChildCustomer = customer.ultimateParentKey ? true : false;
        this.ultimateParentKey = customer.ultimateParentKey;
        this.getUltimateParentTier();
      }
    }, (error) => {
      console.log(error);
    });
  }

  getUltimateParentTier() {
    const filter = {
      customerKey: this.ultimateParentKey
    };

    this.customerService.getCustomerDetails(filter).subscribe(res => {
      if (res.success) {
        this.customerTierStructureKey = res.body[0].customerTierStructureKey;
        this.initializeDropDowns();
      }
    }, (error) => {
      console.log(error);
    });
  }

  tierChanged(structureKey, tier) {
    if (tier === 'name') {
      this.hierarchyForm.controls.tierNickname.setValue(structureKey.value);
    } else {
      this.hierarchyForm.controls.tierName.setValue(structureKey.value);
    }
  }

  saveHierarchy() {
    if (this.customerKey) {
      const params = {
        customerTierStructureKey: this.hierarchyForm.controls.tierName.value,
        customerKey: this.customerKey,
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        insertedUserKey: sessionStorage.getItem('userKey'),
        systemDefault: true,
        deleted: false
      };

      this.loading = true;
      this.customerService.updateCustomerDetails(params).subscribe(result => {
        if (result.success) {
          this.toast.success(result.message[0], 'Success');
          this.loading = false;
        } else {
          this.toast.warning(result.message[0], 'Error');
          this.loading = false;
        }
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }

}
