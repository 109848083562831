import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LineItemService {

  constructor(private http: HttpClient) { }

  getServiceRateMatchMatrix(licensedOwnerKey: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getServiceRateMatchMatrix/${licensedOwnerKey}`);
  }

  getLineItem(postData: any = {}): Observable<any> {
    if (postData.deleted === undefined) {
      postData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/lineitems/get`, postData);
  }

  addLineItemAssociations(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerofferingslineitemassociations`, postData);
  }

  updateLineItemAssociation(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerofferingslineitemassociations`, postData);
  }

  batchAddLineItemAssociations(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerofferingslineitemassociations/batch`, postData);
  }
  batchUpdateLineItemAssociations(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerofferingslineitemassociations/batch`, postData);
  }

  updateLineItem(postData): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}keyvaluepairs/lineitems`, postData);
  }

  getLineItemsByStandardJob(postData: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getLineItemsByStandardJob`, postData);
  }
}
