import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { VendorEquipmentDialogComponent } from '../vendor-equipment-dialog/vendor-equipment-dialog.component';
import { patterns } from 'src/assets/patterns';
import { AssetService } from 'src/app/services/asset-service.service';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { NbToastrService } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'ngx-add-edit-asset-dialog',
  templateUrl: './add-edit-asset-dialog.component.html',
  styleUrls: ['./add-edit-asset-dialog.component.scss'],
})
export class AddEditAssetDialogComponent implements OnInit {

  dialogTitle: string;
  loading: boolean = false;
  onAssetDataChange = new EventEmitter();

  // add / edit asset formgroups
  assetFormOne: UntypedFormGroup;
  assetFormTwo: UntypedFormGroup;
  assetFormThree: UntypedFormGroup;

  // Dropdown data objects
  makeData: any;
  vehicleStatusesData: any;
  equipmentVocationsData: any;
  statesData: any;
  equipmentCategoriesData: any;
  bodyStyleConfigurationsData: any;
  engineSizesData: any;
  severityOfServiceData: any;
  engineManufacturersData: any;
  axleConfigurationsData: any;
  cabTypesData: any;
  powerSourcesData: any;
  transmissionTypesData: any;
  oilTypesData: any;
  powerTakeOffsData: any;
  trailerLengthsData: any;
  keyData: any;
  providerKey: string;
  additionalDetails: any;
  lastUpdatedUser: string;

  constructor(
    public dialog: MatDialogRef<AddEditAssetDialogComponent>,
    public dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private assetService: AssetService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private toast: NbToastrService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    if (this.data.assetData.providerVehicleKey) {
      const data = {
        providerVehicleKey: this.data.assetData.providerVehicleKey,
        deleted: false,
      };
      this.assetService.getProviderVehicleAdditionalDetails(data).subscribe(result => {
        this.additionalDetails = result.body[0];
        // set asset form details.
        this.setAssetDetails();
      }, (error) => {
        this.loading = false;
      });
      this.getProviderDetails();
    } else {
      this.dialogTitle = 'Add Asset';
    }

    this.keyData = {
      insertedUserKey: '12d190b3-3887-4576-9332-ac0603692b06',
      deleted: false,
    };

    if (!!this.data.providerKey) {
      this.providerKey = this.data.providerKey;
    } else {
      sessionStorage.getItem('providerKey');
    }
    this.assetFormOne = this.formBuilder.group({
      assetDescription: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      externalAssetId: ['', Validators.required],
      year: ['', [Validators.required, Validators.pattern(patterns.yearPattern)]],
      makeKey: ['', Validators.required],
      model: ['', Validators.required],
      color: ['', [Validators.required, Validators.pattern(patterns.onlyTextPattern)]],
      vin: ['', Validators.required],
      vehicleStatusKey: ['', Validators.required],
      license: [''],
      licenseStateKey: [''],
      licenseExpDate: [''],
      note: [''],
    });

    this.assetFormTwo = this.formBuilder.group({
      hotalert: ['', Validators.pattern(patterns.textPattern)],
      inServiceDate: [''],
      inActiveSoldDate: [''],
      equipmentVocationKey: [''],
      equipmentCategoryKey: [''],
      engineSizeKey: [''],
      severityOfserviceKey: [''],
      bodyStyleConfigurationKey: [''],
      engineManufacturerKey: [''],
      axleConfigurationKey: [''],
      cabTypeKey: [''],
      powerSourceKey: [''],
    });

    this.assetFormThree = this.formBuilder.group({
      transmissionTypeKey: [''],
      dotnumber: [''],
      trailerLength: [''],
      powerTakeOff: [''],
      oilTypeKey: [''],
      lastValidatedMileage: ['', Validators.min(0)],
      lastValidatedHours: ['', Validators.min(0)],
      updatedTimestamp: [{ value: '', disabled: true }],
      updatedBy: [{ value: '', disabled: true }],
    });

    // Method to initialize dropdowns
    this.initializeDropDowns();
  }

  setAssetDetails() {
    if (this.data.editStatus === 1) {
      this.dialogTitle = 'Edit Asset';

      this.assetFormOne.setValue({
        assetDescription: this.data.assetData.assetDescription,
        externalAssetId: this.data.assetData.externalAssetId,
        year: this.data.assetData.year === 0 ? '' : this.data.assetData.year,
        makeKey: this.data.assetData.makeKey,
        model: this.data.assetData.model,
        color: this.data.assetData.color,
        vin: this.data.assetData.vin,
        vehicleStatusKey: this.data.assetData.vehicleStatusKey,
        license: this.data.assetData.license,
        licenseStateKey: this.data.assetData.licenseStateKey,
        licenseExpDate: this.data.assetData.licenseExpDate == null ? '' : this.datePipe.transform(this.data.assetData.licenseExpDate, 'yyyy-MM-dd'),
        note: this.data.assetData.note,
      });

      this.assetFormTwo.setValue({
        hotalert: this.data.assetData.hotalert,
        inServiceDate: this.data.assetData.inServiceDate == null ? '' : this.datePipe.transform(this.data.assetData.inServiceDate, 'yyyy-MM-dd'),
        inActiveSoldDate: this.data.assetData.inActiveSoldDate == null ? '' : this.datePipe.transform(this.data.assetData.inActiveSoldDate, 'yyyy-MM-dd'),
        equipmentVocationKey: this.data.assetData.equipmentVocationKey,
        equipmentCategoryKey: this.data.assetData.equipmentCategoryKey,
        engineSizeKey: this.additionalDetails.engineSizeKey,
        severityOfserviceKey: this.additionalDetails.severityOfserviceKey,
        bodyStyleConfigurationKey: this.data.assetData.bodyStyleConfigurationKey,
        engineManufacturerKey: this.additionalDetails.engineManufacturerKey,
        axleConfigurationKey: this.additionalDetails.axleConfigurationKey,
        cabTypeKey: this.additionalDetails.cabTypeKey,
        powerSourceKey: this.additionalDetails.powerSourceKey,
      });

      this.assetFormThree.setValue({
        transmissionTypeKey: this.additionalDetails.transmissionTypeKey,
        dotnumber: this.data.assetData.dotnumber,
        trailerLength: this.additionalDetails.trailerLength,
        powerTakeOff: this.additionalDetails.powerTakeOff,
        oilTypeKey: this.additionalDetails.oilTypeKey,
        lastValidatedMileage: this.additionalDetails.lastValidatedMileage == 0 ? '' : this.additionalDetails.lastValidatedMileage,
        lastValidatedHours: this.additionalDetails.lastValidatedHours == 0 ? '' : this.additionalDetails.lastValidatedHours,
        updatedBy: this.lastUpdatedUser == null ? '' : this.lastUpdatedUser,
        updatedTimestamp: this.data.assetData.updatedTimestamp == null ? '' : this.datePipe.transform(this.data.assetData.updatedTimestamp, 'yyyy-MM-dd'),
      });
    }
  }

  initializeDropDowns() {
    this.getMakes();
    this.getVehicleStatuses();
    this.getStates();
    this.getEquipmentVocations();
    this.getEquipmentCategories();
    this.getBodyStyleConfigurations();
    this.getEngineSizes();
    this.getSeverityOfService();
    this.getEngineManufacturers();
    this.getAxleConfigurations();
    this.getCabTypes();
    this.getPowerSources();
    this.getTransmissionTypes();
    this.getTrailerLengths();
    this.getPowerTakeOffs();
    this.getOilTypes();
  }

  getProviderDetails() {
    if (!!this.data.assetData.updatedUserKey) {
      const data = {
        providerKey: this.data.assetData.updatedUserKey,
        'deleted': false,
      };
      this.userService.getUsersDetails(data).subscribe((result: any) => {
        if (result.body[0]) {
          this.lastUpdatedUser = result.body[0].firstName + ' ' + result.body[0].lastName;
        }
        this.loading = false;
      }, (error) => {
        this.loading = false;
      });
    }
  }

  getMakes() {
    if (sessionStorage.getItem('makes') == null) {
      this.assetService.getMakes(this.keyData).subscribe(result => {
        this.makeData = result.body;
        sessionStorage.setItem('makes', JSON.stringify(result));
      });
    } else {
      this.makeData = JSON.parse(sessionStorage.getItem('makes')).body;
    }
  }

  getVehicleStatuses() {
    if (sessionStorage.getItem('VehicleStatuses') == null) {
      this.assetService.getVehicleStatuses(this.keyData).subscribe(result => {
        this.vehicleStatusesData = result.body;
        sessionStorage.setItem('VehicleStatuses', JSON.stringify(result));
      });
    } else {
      this.vehicleStatusesData = JSON.parse(sessionStorage.getItem('VehicleStatuses')).body;
    }
  }

  getStates() {
    if (sessionStorage.getItem('states') == null) {
      this.commonService.getstates().subscribe(result => {
        this.statesData = result.body;
        sessionStorage.setItem('states', JSON.stringify(result));
      });
    } else {
      this.statesData = JSON.parse(sessionStorage.getItem('states')).body;
    }
  }

  getEquipmentVocations() {
    if (sessionStorage.getItem('EquipmentVocations') == null) {
      this.assetService.getEquipmentVocations(this.keyData).subscribe(result => {
        this.equipmentVocationsData = result.body;
        sessionStorage.setItem('EquipmentVocations', JSON.stringify(result));
      });
    } else {
      this.equipmentVocationsData = JSON.parse(sessionStorage.getItem('EquipmentVocations')).body;
    }
  }

  getEquipmentCategories() {
    if (sessionStorage.getItem('EquipmentCategories') == null) {
      this.assetService.getEquipmentCategories(this.keyData).subscribe(result => {
        this.equipmentCategoriesData = result.body;
        sessionStorage.setItem('EquipmentCategories', JSON.stringify(result));
      });
    } else {
      this.equipmentCategoriesData = JSON.parse(sessionStorage.getItem('EquipmentCategories')).body;
    }
  }

  getBodyStyleConfigurations() {
    if (sessionStorage.getItem('BodyStyleConfigurations') == null) {
      this.assetService.getBodyStyleConfigurations(this.keyData).subscribe(result => {
        this.bodyStyleConfigurationsData = result.body;
        sessionStorage.setItem('BodyStyleConfigurations', JSON.stringify(result));
      });
    } else {
      this.bodyStyleConfigurationsData = JSON.parse(sessionStorage.getItem('BodyStyleConfigurations')).body;
    }
  }

  getEngineSizes() {
    if (sessionStorage.getItem('EngineSizes') == null) {
      this.assetService.getEngineSizes(this.keyData).subscribe(result => {
        this.engineSizesData = result.body;
        sessionStorage.setItem('EngineSizes', JSON.stringify(result));
      });
    } else {
      this.engineSizesData = JSON.parse(sessionStorage.getItem('EngineSizes')).body;
    }
  }

  getSeverityOfService() {
    if (sessionStorage.getItem('SeverityOfService') == null) {
      this.assetService.getSeverityOfService(this.keyData).subscribe(result => {
        this.severityOfServiceData = result.body;
        sessionStorage.setItem('SeverityOfService', JSON.stringify(result));
      });
    } else {
      this.severityOfServiceData = JSON.parse(sessionStorage.getItem('SeverityOfService')).body;
    }
  }

  getEngineManufacturers() {
    if (sessionStorage.getItem('EngineManufacturers') == null) {
      this.assetService.getEngineManufacturers(this.keyData).subscribe(result => {
        this.engineManufacturersData = result.body;
        sessionStorage.setItem('EngineManufacturers', JSON.stringify(result));
      });
    } else {
      this.engineManufacturersData = JSON.parse(sessionStorage.getItem('EngineManufacturers')).body;
    }
  }

  getAxleConfigurations() {
    if (sessionStorage.getItem('AxleConfigurations') == null) {
      this.assetService.getAxleConfigurations(this.keyData).subscribe(result => {
        this.axleConfigurationsData = result.body;
        sessionStorage.setItem('AxleConfigurations', JSON.stringify(result));
      });
    } else {
      this.axleConfigurationsData = JSON.parse(sessionStorage.getItem('AxleConfigurations')).body;
    }
  }

  getCabTypes() {
    if (sessionStorage.getItem('CabTypes') == null) {
      this.assetService.getCabTypes(this.keyData).subscribe(result => {
        this.cabTypesData = result.body;
        sessionStorage.setItem('CabTypes', JSON.stringify(result));
      });
    } else {
      this.cabTypesData = JSON.parse(sessionStorage.getItem('CabTypes')).body;
    }
  }

  getPowerSources() {
    if (sessionStorage.getItem('PowerSources') == null) {
      this.assetService.getPowerSources(this.keyData).subscribe(result => {
        this.powerSourcesData = result.body;
        sessionStorage.setItem('PowerSources', JSON.stringify(result));
      });
    } else {
      this.powerSourcesData = JSON.parse(sessionStorage.getItem('PowerSources')).body;
    }
  }

  getTransmissionTypes() {
    if (sessionStorage.getItem('TransmissionTypes') == null) {
      this.assetService.getTransmissionTypes(this.keyData).subscribe(result => {
        this.transmissionTypesData = result.body;
        sessionStorage.setItem('TransmissionTypes', JSON.stringify(result));
      });
    } else {
      this.transmissionTypesData = JSON.parse(sessionStorage.getItem('TransmissionTypes')).body;
    }
  }

  getTrailerLengths() {
    if (sessionStorage.getItem('TrailerLengths') == null) {
      this.assetService.getTrailerLengths(this.keyData).subscribe(result => {
        this.trailerLengthsData = result.body;
        sessionStorage.setItem('TrailerLengths', JSON.stringify(result));
      });
    } else {
      this.trailerLengthsData = JSON.parse(sessionStorage.getItem('TrailerLengths')).body;
    }
  }

  getPowerTakeOffs() {
    if (sessionStorage.getItem('PowerTakeOffs') == null) {
      this.assetService.getPowerTakeOffs(this.keyData).subscribe(result => {
        this.powerTakeOffsData = result.body;
        sessionStorage.setItem('PowerTakeOffs', JSON.stringify(result));
      });
    } else {
      this.powerTakeOffsData = JSON.parse(sessionStorage.getItem('PowerTakeOffs')).body;
    }
  }

  getOilTypes() {
    if (sessionStorage.getItem('OilTypes') == null) {
      this.assetService.getOilTypes(this.keyData).subscribe(result => {
        this.oilTypesData = result.body;
        sessionStorage.setItem('OilTypes', JSON.stringify(result));
      });
    } else {
      this.oilTypesData = JSON.parse(sessionStorage.getItem('OilTypes')).body;
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  openEquipment() {
    this.dialogRef.open(VendorEquipmentDialogComponent, {
      hasBackdrop: false,
      width: '70%',
      height: '85%',
      position: {
        top: '4%',
      },
      data: { locationKey: this.providerKey },
    });
  }

  addEditAssetDetails() {

    if (this.assetFormThree.invalid) {
      this.assetFormThree.markAllAsTouched();
      return false;
    }

    const providerVehicleData = {
      hotalert: this.assetFormTwo.controls.hotalert.value,
      inServiceDate: this.assetFormTwo.controls.inServiceDate.value,
      inActiveSoldDate: this.assetFormTwo.controls.inActiveSoldDate.value,
      equipmentVocationKey: this.assetFormTwo.controls.equipmentVocationKey.value,
      equipmentCategoryKey: this.assetFormTwo.controls.equipmentCategoryKey.value,
      bodyStyleConfigurationKey: this.assetFormTwo.controls.bodyStyleConfigurationKey.value,
      dotnumber: this.assetFormThree.controls.dotnumber.value,
    };

    const additionalDetailsData = {
      engineSizeKey: this.assetFormTwo.controls.engineSizeKey.value,
      severityOfserviceKey: this.assetFormTwo.controls.severityOfserviceKey.value,
      engineManufacturerKey: this.assetFormTwo.controls.engineManufacturerKey.value,
      axleConfigurationKey: this.assetFormTwo.controls.axleConfigurationKey.value,
      cabTypeKey: this.assetFormTwo.controls.cabTypeKey.value,
      powerSourceKey: this.assetFormTwo.controls.powerSourceKey.value,
      transmissionTypeKey: this.assetFormThree.controls.transmissionTypeKey.value,
      trailerLength: this.assetFormThree.controls.trailerLength.value,
      powerTakeOff: this.assetFormThree.controls.powerTakeOff.value,
      oilTypeKey: this.assetFormThree.controls.oilTypeKey.value,
      lastValidatedMileage: this.assetFormThree.controls.lastValidatedMileage.value,
      lastValidatedHours: this.assetFormThree.controls.lastValidatedHours.value,
    };

    if (this.data.editStatus === 1) {
      const updateUserData = {
        updateUserKey: sessionStorage.getItem('providerKey'),
        providerVehicleKey: this.data.assetData.providerVehicleKey,
      };
      const data = Object.assign(this.assetFormOne.value, providerVehicleData, updateUserData);
      this.loading = true;
      this.assetService.updateProviderVehiclesDetails(data).subscribe((res: any) => {
        const updateAddData = {
          updateUserKey: sessionStorage.getItem('providerKey'),
          providerVehiclesAdditionalDetailKey: this.additionalDetails.providerVehiclesAdditionalDetailKey,
        };
        const addData = Object.assign(additionalDetailsData, updateAddData);
        this.assetService.updateProviderVehiclesAddDetails(addData).subscribe((res: any) => {
          this.loading = false;
          this.onAssetDataChange.emit();
          this.dialog.close();
          if (res.success) {
            this.toast.success(res.message[0], 'Success');
          } else {
            this.toast.warning(res.message[0], 'Error');
          }
        }, (error) => {
          this.loading = false;
        });
      }, (error) => {
        this.loading = false;
      });
    } else {

      const vehicleAdditionalData = {
        avghoursPerMonth: 0,
        avgmilesPerMonth: 0,
        axleManufacturerKey: '',
        currentAssumedHours: 0,
        currentAssumedHoursDate: '',
        currentAssumedMileage: 0,
        currentAssumedMileagedate: '',
        deleted: false,
        driveTireSizeKey: '',
        driveTypeKey: '',
        engineModel: '',
        insertedUserKey: sessionStorage.getItem('providerKey'),
        isBillable: true,
        lastValidatedHoursDate: '',
        lastValidatedMileageDate: '',
        leaseCompanyKey: '',
        lifeCycleExpiration: '',
        managedEndDate: '',
        managedStartDate: '',
        pmcompliance: true,
        providerVehicleKey: '',
        purchaseGroupKey: '',
        refrigerationManufacturerKey: '',
        refrigerationModel: '',
        stateInspection: true,
        steerTireSizeKey: '',
        suspensionTypeKey: '',
        systemDefault: false,
        transmissionManufacturerKey: '',
        transmissionModel: '',
        unladenWeight: 0,
        updatedUserKey: '',
      };
      const vehicleData = {
        assetClassKey: '',
        assetId: 0,
        assetTypeGroupKey: '',
        deleted: false,
        detailDescription: '',
        insertedUserKey: sessionStorage.getItem('providerKey'),
        isManaged: true,
        providerKey: this.providerKey,
        serialNumber: '',
        systemDefault: false,
        updatedUserKey: '',
        vindecoded: true,
      };

      const insertData = Object.assign(this.assetFormOne.value, vehicleData, providerVehicleData);
      this.loading = true;
      this.assetService.addProviderVehiclesDetails(insertData).subscribe((res: any) => {
        if (res.body[0]) {
          const detailsData = Object.assign(vehicleAdditionalData, { 'ProviderVehicleKey': res.body[0].providerVehicleKey }, additionalDetailsData);
          this.assetService.addProviderVehiclesAdditionalDetails(detailsData).subscribe((res: any) => {
            this.loading = false;
            this.onAssetDataChange.emit();
            this.dialog.close();
            if (res.success) {
              this.toast.success(res.message[0], 'Success');
            } else {
              this.toast.warning(res.message[0], 'Error');
            }
          }, (error) => {
            this.loading = false;
          });
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }
}
