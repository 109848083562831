import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {

  constructor(
    private http: HttpClient,
  ) { }

  // API call to get location data.
  getLocations(arrReqFilterData: any = {}): Observable<any> {
    if (arrReqFilterData.deleted === undefined) {
      arrReqFilterData.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providers/get`, arrReqFilterData);
  }

  // Add locartion details
  addLocationDetails(arrReqData): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/providers`, arrReqData);
  }

  // Update locartion details
  updateLocationDetails(arrReqData): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/providers`, arrReqData);
  }

}
