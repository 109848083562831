import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserDetailsComponent } from 'src/app/pages/customer-user-list/user-details/user-details.component';
import { AvailabilityComponent } from 'src/app/pages/customer-user-list/availability/availability.component';
import { NbDialogService } from '@nebular/theme';
import { HasUnsavedGuardComponent } from 'src/app/shared/has-unsaved-guard/has-unsaved-guard.component';
import { SecurityGroupsComponent } from 'src/app/pages/customer-user-list/security-groups/security-groups.component';
import { ApprovalComponent } from 'src/app/shared/approval/approval.component';
import { EditDirectReportsComponent } from 'src/app/dialogs/edit-direct-reports/edit-direct-reports.component';

@Component({
  selector: 'app-customer-user-deails-dialog',
  templateUrl: './customer-user-deails-dialog.component.html',
  styleUrls: ['./customer-user-deails-dialog.component.scss']
})
export class CustomerUserDeailsDialogComponent implements OnInit {

  @ViewChild(UserDetailsComponent) private userDetails: UserDetailsComponent;
  @ViewChild(AvailabilityComponent, { static: true }) private availability: AvailabilityComponent;
  @ViewChild(SecurityGroupsComponent, { static: true }) private securityGroups: SecurityGroupsComponent;
  @ViewChild(ApprovalComponent, { static: true }) private approval: ApprovalComponent;

  selectedIndex = 0 ;
  readOnlyUser = false;
  isCustomer = false;
  constructor(public dialog: MatDialogRef<CustomerUserDeailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public userDetail: any,
              private dialogService: NbDialogService, public dialogRef: MatDialog) { }

  ngOnInit() {
    if (sessionStorage.getItem('customerKey')) {
      this.isCustomer = true;
    }
    this.readOnlyUser = this.userDetail.readOnlyUser || false;
  }

  closeDialog() {
    if (this.checkUnsavedChages()) {
      this.dialogService.open(HasUnsavedGuardComponent, { hasBackdrop: true, closeOnBackdropClick: false }).onClose.subscribe((status) => {
        if (status) {
          this.update();
        } else {
          this.dialog.close();
        }
      });
    } else {
      this.dialog.close();
    }
  }

  tabChanged(event) {
    if (this.checkUnsavedChages() === true) {
      this.dialogService.open(HasUnsavedGuardComponent, { hasBackdrop: true, closeOnBackdropClick: false }).onClose.subscribe((status) => {
        if (status) {
          this.update();
        } else {
          this.refreshTabData();
          this.selectedIndex = event.index;
        }
      });
    } else {
      this.refreshTabData();
      this.selectedIndex = event.index;
    }
  }

  checkUnsavedChages(): boolean {
    switch (this.selectedIndex) {
      case 0:
        return this.userDetails.canDeactivate();
      case 1:
        return this.availability.canDeactivate();
      case 2:
        return this.approval.canDeactivate();
      case 3:
        return this.securityGroups.canDeactivate();
    }
  }

  editUserDetails() {
    this.userDetails.editUserDetails();
  }

  editDirectReports() {
    const dialogRef = this.dialogRef.open(EditDirectReportsComponent, {
      width: '90%',
      disableClose: true,
      position: {
        top: '5%',
      },
      data: {
        userKey: this.userDetail.userKey
      }
    });
  }

  next() {
    if (this.checkUnsavedChages()) {
      this.dialogService.open(HasUnsavedGuardComponent, { hasBackdrop: true, closeOnBackdropClick: false }).onClose.subscribe((status) => {
        if (status) {
          this.update();
        } else {
          this.selectedIndex = this.selectedIndex + 1;
        }
      });
    } else {
      this.refreshTabData();
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  back() {
    if (this.checkUnsavedChages()) {
      this.dialogService.open(HasUnsavedGuardComponent, { hasBackdrop: true, closeOnBackdropClick: false }).onClose.subscribe((status) => {
        if (status) {
          this.update();
        } else {
          this.selectedIndex = this.selectedIndex - 1;
        }
      });
    } else {
      this.refreshTabData();
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

  saveCompleted(event) {
    this.selectedIndex = this.selectedIndex + 1;
  }

  update() {
    switch (this.selectedIndex) {
      case 0:
        this.userDetails.updateUser();
        break;
      case 1:
        this.availability.updateAll();
        break;
      case 2:
        this.approval.updateAll('overall Threshold');
        this.approval.updateAll('Service Exception');
        break;
      case 3:
        this.securityGroups.updateUnsavedDetails();
        break;
    }
  }

  refreshTabData() {
    switch (this.selectedIndex) {
      case 0:
        this.userDetails.refresh();
        break;
      case 1:
        this.availability.refresh();
        break;
      case 2:
        this.approval.refresh();
        break;
      case 3:
        this.securityGroups.refresh();
        break;
    }
  }

}
