import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ngx-vendor-equipment-dialog',
  templateUrl: './vendor-equipment-dialog.component.html',
  styleUrls: ['./vendor-equipment-dialog.component.scss']
})
export class VendorEquipmentDialogComponent implements OnInit {
  locationKey: string;

  constructor(
    public dialog: MatDialogRef<VendorEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.locationKey = this.data.locationKey;
  }

  closeDialog() {
    this.dialog.close();
  }

}
