import { Component, OnInit, Inject } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  loading: boolean;
  userGroupData: any;
  userCommunication: any = [];
  userCommunicationFilter: any;

  constructor(private userService: UsersService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.userCommunicationFilter = {
      "userKey": this.data.userKey
    };
    this.getUserGroup();
    this.getUserSubscribedCommunications();
  }

  getUserGroup() {
    this.loading = true;
    this.userService.getUserGroup(this.userCommunicationFilter).subscribe((res: any) => {
      this.userGroupData = res.body;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

  getUserSubscribedCommunications() {
    this.loading = true;
    this.userService.getUserSubscribedCommunications(this.userCommunicationFilter).subscribe((res: any) => {
      this.userCommunication = res.body;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }
}
