import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { BubbleService } from 'src/app/services/bubble.service';
import { RatesService } from 'src/app/services/rates.service';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { b64DecodeUnicode } from '@nebular/auth';
import * as L from 'leaflet';
import { AccountPricingExceptionApprovalDialogComponent } from 'src/app/dialogs/account-pricing-exception-approval-dialog/account-pricing-exception-approval-dialog.component';
import { ProviderService } from '../../../services/provider.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { CurrencyInputPipe } from 'src/app/shared/pipes/currencyInputPipe';
import { ConfirmationDialogComponent } from '../../provider-match/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-provider-admin-rates',
  templateUrl: './provider-admin-rates.component.html',
  styleUrls: ['./provider-admin-rates.component.scss']
})
export class ProviderAdminRatesComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  @Input() providerDetails: any = null;
  @Input() showBack: boolean = false;
  @Input() showNext: boolean = false;

  providerKey: any;
  providerAccountKey: any;
  licensedOwnerKey: any;
  loading: boolean;
  arrProviderAccountPricing: any = [];
  ProviderDetails: any;
  arrPaymentTerms: any;
  strPaymentTermKey: string;
  arrTempRates: any;
  disableEdit: boolean = false;
  arrLineItemPricingTypes: any;
  checkTireRate: any;
  isDisallowed: boolean = true;
  accountName: String = '';
  defaultLocationKey: string;
  rateRangeData: any;
  emergencyRateUpdate: any;
  scheduledRateUpdate: any;
  InvalidRate: boolean = false;
  getParentRecord: boolean = false;
  regionsList: any = [];
  matchedRegion = [];
  afterHoursRatesAllowed: boolean = false;
  lineItemTypeGroupData = [];
  lineItemTypeGroups = [];
  isLicensedOwnerKey = false;
  emergencyRate: any;
  scheduledRate: any;
  afterHoursRate: any;
  emergencyRateApproved: any;
  emergencyAfterHoursRateApproved: any;
  scheduledRateApproved: any;
  isEmergencyRate: boolean;
  isEmergencyAfterHoursRate: boolean;
  isScheduleRate: boolean;
  searchString: any;

  serviceLineItemsList: any = [];
  filterService: any;
  showServices: any = [];
  newRateAmount: any;
  servicesProvidedByProvider: any = [];
  originalShowService: any = [];

  constructor(
    public dialog: MatDialog,
    private toast: NbToastrService,
    private ratesService: RatesService,
    private bubbleService: BubbleService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private providerService: ProviderService,
    private providerAdminService: ProviderAdminService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {

    if (this.providerDetails.providerAccountKey || this.providerDetails.ProviderAccountKey) {
    //???  this.getProviderServiceLineItemsForServicePlus();
      this.selectProviderOfferedSubservices();}

    // if (this.providerDetails.providerAccountKey || this.providerDetails.ProviderAccountKey) {
    //   this.getProviderServiceLineItemsForServicePlus();
    //   this.getAccountDetails();
    //   if (this.providerDetails !== undefined && this.providerDetails != null) {
    //     this.providerAccountKey = this.providerDetails.providerAccountKey;
    //     this.licensedOwnerKey = this.providerDetails.licensedOwnerKey || this.providerDetails.LicensedOwnerKey ;
    //     this.accountName = this.providerDetails.providerName || this.providerDetails.Name;
    //     this.providerKey = this.providerDetails.providerKey || this.providerDetails.ProviderKey;
    //   } else {
    //     this.providerAccountKey = sessionStorage.getItem('providerAccountKey');
    //     this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
    //     this.accountName = sessionStorage.getItem('licensedOwnerName');
    //     this.providerKey = sessionStorage.getItem('providerKey');
    //     this.afterHoursRatesAllowed = sessionStorage.getItem('afterHoursRatesAllowed') === 'true' ? true : false;
    //   }

    //   if (this.licensedOwnerKey !== '') {
    //     this.isLicensedOwnerKey = true;
    //   }

    //   if (this.providerDetails.isParent) {
    //     this.getParentRecord = false;
    //     this.getProviderAccountPricing();
    //     if (this.licensedOwnerKey !== '') {
    //       this.getLineItemTypeGroups();
    //     }
    //   } else {
    //     const data = {
    //       'providerAccountKey': this.providerAccountKey,
    //       'providerKey': this.providerKey,
    //       'deleted': false,
    //     };

    //     this.loading = true;
    //     this.ratesService.getProviderPricingDetails(data).subscribe((result: any) => {
    //       this.loading = false;
    //       if (result.body.length === 0) {
    //         this.getParentRecord = true;

    //         const filterData = {
    //           accountName: sessionStorage.getItem('licensedOwnerName'),
    //           licensedOwnerKey: this.licensedOwnerKey,
    //           providerKey: this.providerDetails.parentProviderKey,
    //           deleted: false,
    //         };

    //         //  Get parent's account key to fetch and insert data
    //         this.loading = true;
    //         this.bubbleService.getProviderAccountKey(filterData).subscribe(proResult => {
    //           this.loading = false;
    //           this.providerAccountKey = proResult.body[0].providerAccountKey;
    //           this.getProviderAccountPricing();
    //           if (this.licensedOwnerKey !== '') {
    //             this.getLineItemTypeGroups();
    //           }
    //         }, (error) => {
    //           this.loading = false;
    //         });
    //       } else {
    //         this.getProviderAccountPricing();
    //         if (this.licensedOwnerKey !== '') {
    //           this.getLineItemTypeGroups();
    //         }
    //       }

    //     }, (error) => {
    //       this.loading = false;
    //     });
    //   }
    //   this.disableEdit = false;
    //   this.onloadListing();
    // }
  }

  // // Get provider account pricing and if not exist insert provider account pricing
  // getProviderAccountPricing() {
  //   this.arrProviderAccountPricing = [];
  //   const data = {
  //     providerKey: this.getParentRecord ? this.providerDetails.parentProviderKey : this.providerKey,
  //     providerAccountKey: this.providerAccountKey,
  //     licensedOwnerKey: this.licensedOwnerKey,
  //   };

  //   this.loading = true;
  //   this.ratesService.getProviderAccountPricing(data).subscribe((result: any) => {
  //     this.loading = false;
  //     if (result.length > 0) {
  //       this.arrProviderAccountPricing = result.map(res => { res.editMode = false; return res; });
  //       this.onSort({ column: 'lineItemKey', direction: 'asc' });
  //     } else {
  //       this.getParentRecord = false;
  //     }
  //   }, (error) => {
  //     this.loading = false;
  //     console.info(error);
  //   });
  // }

  // onloadListing() {
  //   this.loading = true;
  //   const providerFilter = {
  //     providerKey: this.providerKey,
  //   };
  //   const regionFilter = { licensedOwnerKey: this.licensedOwnerKey, deleted: false };

  //   const providerReq = this.ratesService.getProviderDetails(providerFilter);
  //   const PaymentTearmsReq = this.ratesService.getPaymentTerms({ deleted: false });
  //   const regionsReq = this.ratesService.getRegionList(regionFilter);
  //   const pricingTypesReq = this.ratesService.getLineItemPricingTypes({ deleted: false });

  //   forkJoin([providerReq, PaymentTearmsReq, regionsReq, pricingTypesReq]).subscribe(result => {
  //     this.loading = false;
  //     if (result[0].body.length) {
  //       this.ProviderDetails = result[0].body[0];
  //       if (!this.ProviderDetails.tierCanSetRates && !this.providerDetails.isParent) {
  //         this.isDisallowed = false;
  //       }
  //       this.getProviderStateKey(this.ProviderDetails.physicalStateKey || this.providerDetails.MailingStateKey);
  //     }
  //     if (result[1].body.length) {
  //       this.arrPaymentTerms = result[1].body;
  //     }
  //     if (result[2].body.length) {
  //       this.regionsList = result[2].body;
  //     }
  //     if (result[3].body.length) {
  //       this.arrLineItemPricingTypes = result[3].body;
  //     }
  //   }, (error) => {
  //     this.loading = false;
  //     console.info(error);
  //   });
  // }

  // // Get provider state key and country key from provider state description
  // getProviderStateKey(providerState) {
  //   let stateFilter: any = { deleted: false }

  //   if(this.ProviderDetails.physicalStateKey != null){
  //     if (this.ProviderDetails.physicalStateKey.length > 2 ) {
  //       stateFilter.stateDescription = providerState;
  //     } else {
  //       stateFilter.stateKey = providerState;
  //   }}

  //   else if(this.ProviderDetails.mailingStateKey != null){
  //     if (this.ProviderDetails.mailingStateKey.length > 2 ) {
  //       stateFilter.stateDescription = providerState;
  //     } else {
  //       stateFilter.stateKey = providerState;
  //   }}

  //   this.commonService.getstates(stateFilter).subscribe(result => {
  //     if (result.success) {
  //       this.ProviderDetails.countryKey = result.body[0].countryKey;
  //       this.ProviderDetails.stateKey = result.body[0].stateKey;
  //       this.getproviderRegion();
  //     }
  //   });
  // }

  // // match provider physicsal address with regions to select region
  // getproviderRegion() {
  //   this.matchedRegion = [];
  //   this.regionsList.forEach(region => {
  //     if (region.customRegionParameters === null ||
  //       b64DecodeUnicode(region.customRegionParameters) === '[]' ||
  //       b64DecodeUnicode(region.customRegionParameters) === '{}') {
  //       if (region.stateKey && region.stateKey.includes(this.ProviderDetails.stateKey)) {
  //         this.matchedRegion.push(region);
  //       } else if (!region.stateKey && this.ProviderDetails.countryKey == region.countryKey) {
  //         this.matchedRegion.push(region);
  //       }
  //     } else {
  //       const customRegionJson = JSON.parse(b64DecodeUnicode(region.customRegionParameters));
  //       if (!customRegionJson.radius && customRegionJson.latlng !== undefined) {
  //         const arrLatLong = customRegionJson.latlng.map(d => Object.values(d));
  //         const polygon = L.polygon(arrLatLong);

  //         if (polygon.getBounds().contains([this.ProviderDetails.physicalLatitude,
  //         this.ProviderDetails.physicalLongitude])) {
  //           this.matchedRegion.push(region);
  //         } else if (region.stateKey && region.stateKey.includes(this.ProviderDetails.stateKey)) {
  //           this.matchedRegion.push(region);
  //         }

  //       } else {
  //         const circ = L.circle(customRegionJson.latlng, { radius: customRegionJson.radius });

  //         if (circ.getLatLng().distanceTo(
  //           [this.ProviderDetails.physicalLatitude, this.ProviderDetails.physicalLongitude]) < circ.getRadius()) {
  //           this.matchedRegion.push(region);
  //         } else if (region.stateKey && region.stateKey.includes(this.ProviderDetails.stateKey)) {
  //           this.matchedRegion.push(region);
  //         }
  //       }
  //     }
  //   });
  // }

  // editRates(providerAccountPricing) {
  //   this.loading = true;
  //   this.InvalidRate = false;

  //   this.arrProviderAccountPricing.map(lineitem => {
  //     if (providerAccountPricing.providerAccountPricingKey === lineitem.providerAccountPricingKey) {
  //       lineitem.editMode = true;
  //       // this.arrTempRates = lineitem;

  //       this.emergencyRate = lineitem.emergencyRate;
  //       this.scheduledRate = lineitem.scheduledRate;
  //       this.afterHoursRate = lineitem.afterHoursRate;
  //     }
  //     return lineitem;
  //   });
  //   this.disableEdit = true;

  //   if (providerAccountPricing.emergencyRate > 0 || providerAccountPricing.emergencyRate !== null) {
  //     this.isEmergencyRate = true;
  //   } else {
  //     this.isEmergencyRate = false;
  //   }

  //   if (providerAccountPricing.afterHoursRate > 0 || providerAccountPricing.afterHoursRate !== null) {
  //     this.isEmergencyAfterHoursRate = true;
  //   } else {
  //     this.isEmergencyAfterHoursRate = false;
  //   }

  //   if (providerAccountPricing.scheduledRate > 0 || providerAccountPricing.scheduledRate !== null) {
  //     this.isScheduleRate = true;
  //   } else {
  //     this.isScheduleRate = false;
  //   }

  //   this.emergencyRateApproved = [];
  //   this.emergencyAfterHoursRateApproved = [];
  //   this.scheduledRateApproved = [];

  //   const emergencyRateData = {
  //     licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
  //     lineItemPricingCategories: 'Emergency Rate',
  //     providerAccountPricingKey: providerAccountPricing.providerAccountPricingKey
  //   };

  //   const emergencyAfterHoursRateData = {
  //     licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
  //     lineItemPricingCategories: 'Emergency After-Hours Rate',
  //     providerAccountPricingKey: providerAccountPricing.providerAccountPricingKey
  //   };

  //   const scheduledRateData = {
  //     licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
  //     lineItemPricingCategories: 'Scheduled Rate',
  //     providerAccountPricingKey: providerAccountPricing.providerAccountPricingKey
  //   };

  //   const emergencyRate = this.ratesService.getProviderAccountPricingException(emergencyRateData);
  //   const emergencyAfterHoursRate = this.ratesService.getProviderAccountPricingException(emergencyAfterHoursRateData);
  //   const scheduledRate = this.ratesService.getProviderAccountPricingException(scheduledRateData);

  //   forkJoin([emergencyRate, emergencyAfterHoursRate, scheduledRate]).subscribe(result => {
  //     this.emergencyRateApproved = result[0].body[0];
  //     this.emergencyAfterHoursRateApproved = result[1].body[0];
  //     this.scheduledRateApproved = result[2].body[0];
  //     this.loading = false;
  //   }, (error) => {
  //     this.loading = false;
  //     console.log(error);
  //   });
  // }

  // cancelRates(data) {
  //   this.InvalidRate = false;
  //   this.arrProviderAccountPricing.map(lineitem => {
  //     if (data.providerAccountPricingKey === lineitem.providerAccountPricingKey) {

  //       lineitem.emergencyRate = this.emergencyRate;
  //       lineitem.scheduledRate = this.scheduledRate;
  //       lineitem.afterHoursRate = this.afterHoursRate;

  //       lineitem.editMode = false;
  //     }
  //     return lineitem;
  //   });
  //   this.disableEdit = false;
  // }

  // checkProviderAccountPricing() {
  //   const data = {
  //     providerKey: this.providerKey,
  //     providerAccountKey: this.providerAccountKey,
  //     licensedOwnerKey: this.licensedOwnerKey,
  //   };
  //   this.ratesService.getProviderAccountPricing(data).subscribe((result: any) => {
  //     this.arrProviderAccountPricing = result;
  //     this.onSort({ column: 'lineItemKey', direction: 'asc' });
  //   });
  // }

  // saveRates(providerAccountPricingDetails) {
  //   if (this.getParentRecord) {
  //     const data = {
  //       locationKey: this.providerKey,
  //       providerKey: this.providerDetails.parentProviderKey,
  //       licensedOwnerKey: this.licensedOwnerKey,
  //       providerAccountKey: this.providerDetails.parentProviderAccountKey,
  //       locationAccountKey: this.providerDetails.providerAccountKey,
  //     };

  //     this.loading = true;

  //     this.bubbleService.setUpServiceAndRates(data).subscribe(result => {
  //       this.loading = false;
  //       this.providerAccountKey = this.providerDetails.providerAccountKey;
  //       this.bubbleService.setBubbleTreeData();
  //       this.updateSelectedRecord(providerAccountPricingDetails);
  //     }, (error) => {
  //       this.loading = false;
  //     });
  //   } else {
  //     if (this.licensedOwnerKey === '') {
  //       const updatePricing = {
  //         providerAccountPricingKey: providerAccountPricingDetails.providerAccountPricingKey,
  //         emergencyRate: providerAccountPricingDetails.emergencyRate,
  //         scheduledRate: providerAccountPricingDetails.scheduledRate,
  //         lineItemPricingTypeKey: providerAccountPricingDetails.lineItemPricingTypeKey,
  //         afterHoursRate: providerAccountPricingDetails.afterHoursRate,
  //         updatedUserKey: sessionStorage.getItem('userKey'),
  //         emergencyRateApproved: (providerAccountPricingDetails.emergencyRate === '' || providerAccountPricingDetails.emergencyRate == null) ? null : 1,
  //         scheduledRateApproved: (providerAccountPricingDetails.scheduledRate === '' || providerAccountPricingDetails.scheduledRate == null) ? null : 1,
  //         afterHoursRateApproved: (providerAccountPricingDetails.afterHoursRate === '' || providerAccountPricingDetails.afterHoursRate == null) ? null : 1,
  //       };
  //       this.saveProviderPricing(updatePricing);
  //     }
  //     if (this.matchedRegion.length === 0) {
  //       this.arrProviderAccountPricing.map(price => { price.editMode = false; return price; });
  //       this.disableEdit = false;
  //       return false;
  //     } else {
  //       this.loading = true;
  //       let emergencyApproved = 0;
  //       let scheduledApproved = 0;
  //       let afterHoursApproved = 0;
  //       if (providerAccountPricingDetails.emergencyRateApproved === 1) {
  //         emergencyApproved = 1;
  //       }

  //       if (providerAccountPricingDetails.scheduledRateApproved === 1) {
  //         scheduledApproved = 1;
  //       }

  //       if (providerAccountPricingDetails.afterHoursRateApproved === 1) {
  //         afterHoursApproved = 1;
  //       }
  //       const updatePricing = {
  //         providerAccountPricingKey: providerAccountPricingDetails.providerAccountPricingKey,
  //         emergencyRate: providerAccountPricingDetails.emergencyRate,
  //         scheduledRate: providerAccountPricingDetails.scheduledRate,
  //         lineItemPricingTypeKey: providerAccountPricingDetails.lineItemPricingTypeKey,
  //         afterHoursRate: providerAccountPricingDetails.afterHoursRate,
  //         emergencyRateApproved: (providerAccountPricingDetails.emergencyRate === '' || providerAccountPricingDetails.emergencyRate == null) ? null : emergencyApproved,
  //         scheduledRateApproved: (providerAccountPricingDetails.scheduledRate === '' || providerAccountPricingDetails.scheduledRate == null) ? null : scheduledApproved,
  //         afterHoursRateApproved: (providerAccountPricingDetails.afterHoursRate === '' || providerAccountPricingDetails.afterHoursRate == null) ? null : afterHoursApproved,
  //         updatedUserKey: sessionStorage.getItem('userKey'),
  //       };
  //       const self = this;
  //       let resultLength = 0;
  //       const promise = new Promise<void>(function (resolve, reject) {
  //         self.matchedRegion.forEach((region, index) => {
  //           const filter = {
  //             specializedPricingRegionKey: region.specializedPricingRegionKey,
  //             lineItemKey: providerAccountPricingDetails.lineItemKey,
  //             lineItemPricingTypeKey: providerAccountPricingDetails.lineItemPricingTypeKey,
  //             deleted: false,
  //           };

  //           self.ratesService.getLicensedOwnerSpecializedPricingRegions(filter).subscribe(result => {
  //             resultLength += 1;
  //             if (result.body.length > 0) {
  //               if (updatePricing.emergencyRateApproved === 1 &&
  //                 updatePricing.scheduledRateApproved === 1 &&
  //                 updatePricing.afterHoursRateApproved) {
  //                 resolve();
  //                 return false;
  //               } else {
  //                 if (providerAccountPricingDetails.emergencyRate <= result.body[0].emergencyRateTo &&
  //                   updatePricing.emergencyRateApproved === 0) {
  //                   updatePricing.emergencyRateApproved = (providerAccountPricingDetails.emergencyRate === '' || providerAccountPricingDetails.emergencyRate == null) ? null : 1;
  //                   if (self.emergencyRateApproved) {
  //                     const emergencyRateExceptionData = {
  //                       providerAccountPricingExceptionKey: self.emergencyRateApproved.providerAccountPricingExceptionKey,
  //                       rateApproved: 1
  //                     };
  //                     self.ratesService.updateProviderAccountPricingException(emergencyRateExceptionData).subscribe(res => { });
  //                   }
  //                 }

  //                 if (providerAccountPricingDetails.scheduledRate <= result.body[0].scheduledRateTo &&
  //                   updatePricing.scheduledRateApproved === 0) {
  //                   updatePricing.scheduledRateApproved = (providerAccountPricingDetails.scheduledRate === '' || providerAccountPricingDetails.scheduledRate == null) ? null : 1;
  //                   if (self.scheduledRateApproved) {
  //                     const scheduledRateExceptionData = {
  //                       providerAccountPricingExceptionKey: self.scheduledRateApproved.providerAccountPricingExceptionKey,
  //                       rateApproved: 1
  //                     };
  //                     self.ratesService.updateProviderAccountPricingException(scheduledRateExceptionData).subscribe(res => { });
  //                   }
  //                 }

  //                 if (providerAccountPricingDetails.afterHoursRate <= result.body[0].afterHoursRateTo &&
  //                   updatePricing.afterHoursRateApproved === 0) {
  //                   updatePricing.afterHoursRateApproved = (providerAccountPricingDetails.afterHoursRate === '' || providerAccountPricingDetails.afterHoursRate == null) ? null : 1;
  //                   if (self.emergencyAfterHoursRateApproved) {
  //                     const emergencyAfterHoursRateExceptionData = {
  //                       providerAccountPricingExceptionKey: self.emergencyAfterHoursRateApproved.providerAccountPricingExceptionKey,
  //                       rateApproved: 1
  //                     };
  //                     self.ratesService.updateProviderAccountPricingException(emergencyAfterHoursRateExceptionData).subscribe(res => { });
  //                   }
  //                 }
  //               }
  //               if (resultLength === self.matchedRegion.length) {
  //                 resolve();
  //               }
  //             } else {
  //               if (resultLength === self.matchedRegion.length) {
  //                 resolve();
  //               }
  //             }
  //           });
  //         });
  //       });

  //       promise.then(() => {
  //         this.saveProviderPricing(updatePricing);
  //       });
  //     }
  //   }
  // }

  // saveProviderPricing(updatePricing) {
  //   this.ratesService.putProviderAccountPricing(updatePricing).subscribe(result => {
  //     this.arrProviderAccountPricing.map(price => { price.editMode = false; return price; });
  //     this.loading = false;
  //     if (result.success) {
  //       this.disableEdit = false;
  //       this.checkProviderAccountPricing();
  //       this.updateAccountStatus(updatePricing);
  //       this.updatePricingExceptionRequestedRateRecord(updatePricing);
  //     } else {
  //       this.loading = false;
  //       this.toast.warning(result.message[0], 'Error');
  //     }
  //   }, (error) => {
  //     this.loading = false;
  //     this.disableEdit = false;
  //   });
  // }

  // updateAccountStatus(updatePricing) {
  //   if (this.providerDetails.accountStatus === 'InActive' &&
  //     (updatePricing.afterHoursRateApproved === 1 ||
  //       updatePricing.emergencyRateApproved === 1 ||
  //       updatePricing.scheduledRateApproved === 1)) {

  //     const updateAccountData = {
  //       accountStatus: 'Active',
  //       providerAccountKey: this.providerDetails.providerAccountKey,
  //       updatedUserKey: sessionStorage.getItem('userKey'),
  //     };
  //     this.ratesService.updateProviderAccounts(updateAccountData).subscribe(res => {
  //       if (res.success) {
  //         console.info('Account is activated.');
  //       }
  //     }, error => {
  //       console.info('Error Account status update');
  //     })
  //   }
  // }

  // isDecimal(value) {
  //   const pattern = /^(\d+\.)?\d+$/g;
  //   if (value) {
  //     if (value.toString().match(pattern) != null) {
  //       this.InvalidRate = false;
  //     } else {
  //       this.InvalidRate = true;
  //     }
  //   }
  // }

  // updateSelectedRecord(providerAccountPricingDetails) {
  //   const pricingFilter = {
  //     'providerAccountKey': this.providerAccountKey,
  //     'providerKey': this.providerKey,
  //     'lineItemKey': providerAccountPricingDetails.lineItemKey,
  //     'deleted': false
  //   };

  //   this.loading = true;
  //   this.ratesService.getProviderPricingDetails(pricingFilter).subscribe((result: any) => {
  //     this.loading = false;
  //     const data = {
  //       'providerAccountPricingKey': result.body[0].providerAccountPricingKey,
  //       'emergencyRate': providerAccountPricingDetails.emergencyRate,
  //       'scheduledRate': providerAccountPricingDetails.scheduledRate,
  //       'afterHoursRate': providerAccountPricingDetails.afterHoursRate,
  //       'lineItemPricingTypeKey': providerAccountPricingDetails.lineItemPricingTypeKey,
  //       'updatedUserKey': sessionStorage.getItem('userKey')
  //     };
  //     this.loading = true;
  //     this.ratesService.putProviderAccountPricing(data).subscribe((res: any) => {
  //       this.loading = true;
  //       this.getParentRecord = false;
  //       this.disableEdit = false;
  //       this.getProviderAccountPricing();
  //     }, (error) => {
  //       this.loading = false;
  //     });
  //   }, (error) => {
  //     this.loading = false;
  //   });
  // }

  // // onSort({ column, direction }: SortEvent) {
  // //   this.arrProviderAccountPricing = this.utilityService.sortData(
  // //     this.headers,
  // //     column,
  // //     direction,
  // //     this.arrProviderAccountPricing
  // //   );
  // // }

  // getLineItemTypeGroups() {
  //   const data = {
  //     providerKey: this.getParentRecord ? this.providerDetails.parentProviderKey : this.providerKey,
  //     providerAccountKey: this.providerAccountKey,
  //     licensedOwnerKey: this.licensedOwnerKey,
  //   };
  //   this.loading = true;
  //   this.ratesService.getLicensedOwnerLineItemTypeGroup(data).subscribe((res: any) => {
  //     this.loading = false;
  //     const arrLineItemTypeGroup = res;
  //     if (arrLineItemTypeGroup.length > 0) {
  //       arrLineItemTypeGroup.forEach(lineItemTypeGroup => {
  //         if (!this.lineItemTypeGroupData.some(e =>
  //           e.serviceId === lineItemTypeGroup.LicensedOwnerServiceOfferingKey)) {
  //           const temp = {
  //             'serviceId': lineItemTypeGroup.LicensedOwnerServiceOfferingKey,
  //             'serviceName': lineItemTypeGroup.Description,
  //             'children': [],
  //           };
  //           this.lineItemTypeGroupData.push(temp);
  //         }
  //       });
  //       this.assignLineItemTypeGroup(this.lineItemTypeGroupData, arrLineItemTypeGroup);
  //       this.lineItemTypeGroups = this.lineItemTypeGroupData;
  //     }
  //   }, (error) => {
  //     this.loading = false;
  //   });
  // }

  // assignLineItemTypeGroup(lineItemTypeGroupData, arrLineItemTypeGroup) {
  //   lineItemTypeGroupData.forEach(arrdata => {
  //     arrLineItemTypeGroup.forEach(element => {
  //       if (arrdata.serviceId === element.LicensedOwnerServiceOfferingKey &&
  //         !arrdata.children.some(e => e.LineItemTypeGroupName === element.LicensedOwnerLineItemTypeGroupNameKey)) {
  //         const temp = {
  //           'lineItemTypeGroupName': element.LicensedOwnerLineItemTypeGroupNameKey,
  //           'licensedOwnerServiceOfferingKey': element.LicensedOwnerServiceOfferingKey,
  //           'isSelected': false,
  //         };
  //         arrdata.children.push(temp);
  //       }
  //     });
  //   });
  // }

  // setAfterHoursRates(providerAccountPricing) {
  //   if (providerAccountPricing.afterHoursRateApproved !== '1') {
  //     providerAccountPricing.afterHoursRate = providerAccountPricing.emergencyRate;
  //   }
  // }

  // openAccountPricingException(accountPricingExceptionData, providerAccountPricingData, lineItemPricingCategoryKey) {
  //   const dialogRef = this.dialog.open(AccountPricingExceptionApprovalDialogComponent, {
  //     width: '70%',
  //     disableClose: true,
  //     position: {
  //       top: '2%'
  //     },
  //     data: {
  //       accountPricingExceptionData,
  //       providerAccountPricingData,
  //       lineItemPricingCategoryKey,
  //       matchedRegion: this.matchedRegion
  //     }
  //   });

  //   dialogRef.componentInstance.eventAccountPricingException.subscribe(value => {
  //     providerAccountPricingData.editMode = !providerAccountPricingData.editMode;
  //     this.disableEdit = !this.disableEdit;
  //     this.checkProviderAccountPricing();
  //   });
  // }

  // updatePricingExceptionRequestedRateRecord(data) {
  //   if (this.emergencyRateApproved) {
  //     const emergencyRateExceptionData = {
  //       providerAccountPricingExceptionKey: this.emergencyRateApproved.providerAccountPricingExceptionKey,
  //       requestedRate: data.emergencyRate
  //     };
  //     this.ratesService.updateProviderAccountPricingException(emergencyRateExceptionData).subscribe(res => { });
  //   }
  //   if (this.emergencyAfterHoursRateApproved) {
  //     const emergencyAfterHoursRateExceptionData = {
  //       providerAccountPricingExceptionKey: this.emergencyAfterHoursRateApproved.providerAccountPricingExceptionKey,
  //       requestedRate: data.afterHoursRate
  //     };
  //     this.ratesService.updateProviderAccountPricingException(emergencyAfterHoursRateExceptionData).subscribe(res => { });
  //   }
  //   if (this.scheduledRateApproved) {
  //     const scheduledRateExceptionData = {
  //       providerAccountPricingExceptionKey: this.scheduledRateApproved.providerAccountPricingExceptionKey,
  //       requestedRate: data.scheduledRate
  //     };
  //     this.ratesService.updateProviderAccountPricingException(scheduledRateExceptionData).subscribe(res => { });
  //   }
  // }
  // getAccountDetails() {
  //   if(this.providerDetails.providerAccountKey == null || this.providerDetails.providerAccountKey == undefined){
  //     this.providerDetails.providerAccountKey = this.providerDetails.ProviderAccountKey
  //   }
  //   this.providerService.getProviderAccounts({ providerAccountKey: this.providerDetails.providerAccountKey }).subscribe(result => {
  //     const providerDetailsRes = result.body[0];
  //     this.afterHoursRatesAllowed = providerDetailsRes.afterHoursRatesAllowed;
  //     this.strPaymentTermKey = providerDetailsRes.paymentTermKey;
  //   }, (error) => {
  //     console.log(error);
  //     this.loading = false;
  //   });
  // }

  selectProviderOfferedSubservices(){
    this.providerAdminService.selectProviderOfferedSubservices(this.providerDetails.ProviderKey).subscribe({
      next: res => this.servicesProvidedByProvider = res.body,
      error: (e) => console.log(e),
      complete: () => { this.getProviderServiceLineItemsForServicePlus()}
      
    })
  }

  getProviderServiceLineItemsForServicePlus(){
    this.providerAdminService.selectProviderServiceLineItemsForServicePlus(this.providerDetails.ProviderId).subscribe({
      next: res => {this.serviceLineItemsList = res.body, console.log(res), this.showServices = this.serviceLineItemsList},
      error: (e) => console.log(e),
      complete: () => {
        const ASViKeyNum = this.servicesProvidedByProvider.map(items => items._AxlePointKey).toString()
        console.log(ASViKeyNum)
        this.showServices = this.showServices.filter(item => ASViKeyNum.includes(item.LBSCODlCodeKey))
        this.originalShowService = JSON.parse(JSON.stringify(this.showServices))
        this.onSort({ column: 'ASVvcName', direction: 'asc' });
    }
    })
  }

  onSort({ column, direction }: SortEvent) {
    this.serviceLineItemsList = this.utilityService.sortData(this.headers, column, direction, this.serviceLineItemsList,);
   // this.arrowDirection = direction;
  }

  filterServices(event: Event){
    let filteredServiceList = this.serviceLineItemsList;
    this.showServices = [];
    let filteredItem = this.filterService.toLowerCase();
    console.log(filteredItem)
    filteredServiceList = this.serviceLineItemsList.filter(item => Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filteredItem)))
    this.showServices = filteredServiceList
  }

  search(event) {
    this.searchString = event;
  }

  deleteServiceLineItem(service){
    const data = {
      inProviderId : this.providerDetails.ProviderId,
      inServiceLineKey : service.ASViKey,
    }

    this.providerAdminService.deleteProviderServiceLineItemsForServicePlus(data).subscribe({
      next: res => {console.log(res)},
      error: (e) => console.log(e),
      complete: () => { this.getProviderServiceLineItemsForServicePlus()
    }
    })
  }

  editServiceLineItem(service){

    if(!service.PACASViService){

      const Data ={
        inProviderId : this.providerDetails.ProviderId,
        inServiceLineKey: service.ASViKey,
        inLinePrice: service.PACfPrice,
        inUpdatedUserName: sessionStorage.userName,
      }

      this.providerAdminService.insertProviderServiceLineItemsForServicePlus(Data).subscribe({
        next: res => {console.log(res)},
        error: (e) => console.log(e),
        complete: () => { this.getProviderServiceLineItemsForServicePlus()
      }
      })
    }

    else if(service.PACASViService){
      
      const Data ={
        inProviderId : this.providerDetails.ProviderId,
        inServiceLineKey: service.ASViKey,
        inLinePrice: service.PACfPrice,
        inUpdatedUserName: sessionStorage.userName,
      }

      this.providerAdminService.updateProviderServiceLineItemsForServicePlus(Data).subscribe({
        next: res => {console.log(res)},
        error: (e) => console.log(e),
        complete: () => { this.getProviderServiceLineItemsForServicePlus()
      }
      })
    } 
  }

  addMultipleServiceLineAmounts(){
  // console.log(showServices)
  // console.log(this.originalShowService)

  for(let i = 0; this.showServices.length; i++){
    if(this.showServices[i].PACfPrice == this.originalShowService[i].PACfPrice){
      console.log('do not add service here')
    }
    else if (this.showServices[i].PACfPrice != this.originalShowService[i].PACfPrice){
      if(this.showServices[i].PACfPrice == '' || this.showServices[i].PACfPrice == null){
        this.deleteServiceLineItem(this.showServices[i])
      }
      else {this.editServiceLineItem(this.showServices[i])}
    }
  }}

  saveServiceLineChanges(){
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {header: 'Save Service Line Changes', message: 'Are you sure you want to save the changes made to the service line items?',
          yesButton: 'Yes'
        }
      });
    dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if(result == true){
          this.addMultipleServiceLineAmounts()
        }
    })  
  }


}

