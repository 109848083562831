<app-loader [loading]='loading'></app-loader>
<nb-card>
  <nb-card-header class="card-header bgDialogHeader text-center">National Account
    <div class="pull-right">
      <a class="boxclose" (click)="onNoClick()"></a>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="modal-body">
      <form [formGroup]="addServiceForm" class="p-3 border">
        <div class="row">
          <div class="col-12 col-md-4">
            <mat-form-field>
              <mat-select placeholder="Service Type" formControlName="serviceType" (selectionChange)="getSubservice()"
                required>
                <mat-option disabled>Select Service</mat-option>
                <ng-container *ngFor="let licensedOwnerOffering of licensedOwnerOfferingData | orderBy : 'description'">
                  <mat-option *ngIf="licensedOwnerOffering.parentKey == null || licensedOwnerOffering.parentKey == 0"
                    [value]="licensedOwnerOffering.networkOwnerOfferingKey">
                    {{licensedOwnerOffering.description}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="errorHandling('serviceType', 'required')">Please select Service Type</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field>
              <mat-select placeholder="Subservice Type" formControlName="subServiceType">
                <mat-option disabled>Select Sub Service</mat-option>
                <mat-option *ngFor="let subService of subServiceData | orderBy : 'description'"
                  [value]="subService.licensedOwnerOfferingKey">{{subService.description}}</mat-option>
              </mat-select>
              <mat-error *ngIf="errorHandling('subServiceType', 'required')">Please select Sub Service Type</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field>
              <!-- <input matInput placeholder="Account Name" formControlName="accountName" required /> -->
              <mat-select placeholder="Account Name" formControlName="accountName">
                <mat-option disabled>Select Account Name</mat-option>
                <mat-option *ngFor="let list of accountList | orderBy : 'nationalAccountKey'"
                  [value]="list.nationalAccountKey">
                  {{list.nationalAccountKey}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="errorHandling('accountName', 'required')">
                Account Name is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <mat-form-field>
              <input matInput placeholder="Priority" formControlName="priority" required />
              <mat-error *ngIf="errorHandling('priority', 'required')">
                Priority is required
              </mat-error>
              <mat-error *ngIf="errorHandling('priority', 'pattern')">
                Enter Valid Priority
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field>
              <input matInput placeholder="Account Number" formControlName="accountNumber" required />
              <mat-error *ngIf="errorHandling('accountNumber', 'required')">
                Account Number is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field>
              <input matInput placeholder="PO Mask" formControlName="pOMask" required />
              <mat-error *ngIf="errorHandling('pOMask', 'required')">
                PO mask is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <button class="btn btn-primary btn-sm pull-right mr-2" (click)="insertData()">{{buttonText}}</button>
          </div>
        </div>
      </form>
    </div>
  </nb-card-body>
</nb-card>