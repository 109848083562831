import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], column: string, order: string = 'ASC'): any {
    if (value && value.length > 0) {
      value.sort((a: any, b: any) => {
        if (a[column]?.toString().trim().toLowerCase() < b[column]?.toString().trim().toLowerCase()) {
          return -1;
        } else if (a[column]?.toString().trim().toLowerCase() > b[column]?.toString().trim().toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      return value;
    }
    return value;
  }
}
