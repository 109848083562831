/*** Angular Core ***/
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule }                    from '@angular/platform-browser';
import { NgModule }                         from '@angular/core';
import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';
import { HttpClientModule }                 from '@angular/common/http';
import { DatePipe }                         from '@angular/common';

/*** Root Imports ***/
import { AppRoutingModule } from './app-routing.module';
import { AppComponent }     from './app.component';
import { BASE_URL }         from 'src/app.tokens';
import { OAuthModule }      from 'angular-oauth2-oidc';
import { environment }      from 'src/environments/environment';

/*** Angular Material ***/
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule }    from '@angular/material/checkbox';
import { MatDatepickerModule }  from '@angular/material/datepicker';
import { MatExpansionModule }   from '@angular/material/expansion';
import { MatFormFieldModule }   from '@angular/material/form-field';
import { MatIconModule }        from '@angular/material/icon';
import { MatInputModule }       from '@angular/material/input';
import { MatPaginatorModule }   from '@angular/material/paginator';
import { MatRadioModule }       from '@angular/material/radio';
import { MatSelectModule }      from '@angular/material/select';
import { MatStepperModule }     from '@angular/material/stepper';
import { MatTableModule }       from '@angular/material/table';
import { MatSnackBarModule }    from '@angular/material/snack-bar';
import { MatTabsModule }        from '@angular/material/tabs';
import { NgxMatMomentModule }   from '@angular-material-components/moment-adapter'; //NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS

/*** Additional @ Imports ***/
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ThemeModule }    from './@theme/theme.module';

/*** NGX ***/
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { GooglePlaceModule }        from "ngx-google-places-autocomplete-esb";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPanZoomModule }         from 'ngx-panzoom';

/*** Nebular ***/
import { NbSidebarModule, NbMenuModule, NbToastrModule, NbCardModule,
         NbTooltipModule, NbDialogModule, NbLayoutModule } from '@nebular/theme';

/*** Components ***/
import { AccountInsuranceComponent }                from './dialogs/rates-dialog/account-insurance/account-insurance.component';
import { AccountNotesComponent }                    from './dialogs/rates-dialog/account-notes/account-notes.component';
import { AddCustomRegionDialogComponent }           from './dialogs/add-custom-region-dialog/add-custom-region-dialog.component';
import { AddCustomerAccountDialogComponent }        from './dialogs/add-customer-account-dialog/add-customer-account-dialog.component';
import { AddEditAssetDialogComponent }              from './dialogs/add-edit-asset-dialog/add-edit-asset-dialog.component';
import { AddEditEmployeeDialogsComponent }          from '../app/dialogs/add-edit-employee-dialogs/add-edit-employee-dialogs.component';
import { AddEditNetworkDialogComponent }            from './dialogs/add-edit-network-dialog/add-edit-network-dialog.component';
import { AddLocationDialogComponent }               from './dialogs/add-location-dialog/add-location-dialog.component';
import { AddSecurityMenuDialogComponent }           from './dialogs/add-security-menu-dialog/add-security-menu-dialog.component';
import { AddSkillsLevelExpertiseDailogComponent }   from './dialogs/add-skills-level-expertise-dailog/add-skills-level-expertise-dailog.component';
import { AddSystemRolesDialogComponent }            from './dialogs/add-system-roles-dialog/add-system-roles-dialog.component';
import { AddUserIntoInviteProcessComponent }        from '../app/dialogs/user-invite-process/add-user-into-invite-process/add-user-into-invite-process.component';
import { AddVendorEmployeeComponent }               from '../app/dialogs/add-vendor-employee/add-vendor-employee.component';
import { CommunicationTemplatesComponent }          from '../app/dialogs/user-invite-process/communication-templates/communication-templates.component';
import { CompanyDetailsComponent }                  from './dialogs/company-details/company-details.component';
import { ConfirmDeleteComponent }                   from './commons/confirm-delete/confirm-delete.component';
import { CustomerInviteProcessComponent }           from '../app/dialogs/customer-invite-process/customer-invite-process.component';
import { CustomerPreferencesComponent }             from './dialogs/rates-dialog/customer-preferences/customer-preferences.component';
import { EditSecurityGroupListDataComponent }       from './dialogs/edit-security-group-list-data/edit-security-group-list-data.component';
import { EmployeeAvailabilityComponent }            from '../app/dialogs/employee-availability/employee-availability.component';
import { LocationAccountsComponent }                from './shared/location-accounts/location-accounts.component';
import { LocationAssignedAssetDetailsComponent }    from './dialogs/location-detail-dialog/location-assigned-asset-details/location-assigned-asset-details.component';
import { LocationDetailDialogComponent }            from './dialogs/location-detail-dialog/location-detail-dialog.component';
import { PageNotFoundComponent }                    from '../app/shared/page-not-found/page-not-found.component';
import { PaymentTermsComponent }                    from './dialogs/rates-dialog/payment-terms/payment-terms.component';
import { ProviderUserInviteProcessComponent }       from '../app/dialogs/provider-user-invite-process/provider-user-invite-process.component';
import { RatesDialogComponent }                     from './dialogs/rates-dialog/rates-dialog.component';
import { SkillsLevelExpertiseDailogComponent }      from '../app/dialogs/skills-level-expertise-dailog/skills-level-expertise-dailog.component';
import { SplashScreenComponent }                    from './splash-screen/splash-screen.component';
import { SystemAccessRoleDailogComponent }          from '../app/dialogs/system-access-role-dailog/system-access-role-dailog.component';
import { TileComponent }                            from '../../src/app/shared/bubble-tree-ui/tile';
import { UploadFileDialogComponent }                from './dialogs/upload-file-dialog/upload-file-dialog.component';
import { UploadFileModelerComponent }               from '../app/dialogs/user-invite-process/upload-file-modeler/upload-file-modeler.component';
import { UserInviteHistoryComponent }               from '../app/dialogs/user-invite-process/user-invite-history/user-invite-history.component';
import { UserInviteProcessComponent }               from '../app/dialogs/user-invite-process/user-invite-process.component';
import { VendorDiscountsComponent }                 from './dialogs/rates-dialog/vendor-discounts/vendor-discounts.component';
import { VendorEquipmentDialogComponent }           from './dialogs/vendor-equipment-dialog/vendor-equipment-dialog.component';
import { ViewLineItemExclusionListDialogComponent } from './dialogs/view-line-item-exclusion-list-dialog/view-line-item-exclusion-list-dialog.component';
import { ViewServiceExclusionListComponent }        from './dialogs/view-service-exclusion-list/view-service-exclusion-list.component';

/*** NonComponents ***/
import { CompareValidatorModule }    from '../app/compare-validator'
import { ShareModuleModule }         from './share-module/share-module.module';
import { SharedModule }              from './shared/shared.module';
import { SelectCountryService }      from './pages/select-country.service';
import { ToggleFullscreenDirective } from '../../src/app/shared/bubble-tree-ui/fullscreen.directive';
import { WheelDirective }            from './wheel.directive'

/*** Commented Out Imports ***/
// import { AddVendorEmployeeComponent } from '../../src/app/dialogs/add-vendor-employee/add-vendor-employee.component';
// import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
// import { AddUserIntoInviteProcessComponent } from  '../app/dialogs/user-invite-process/add-user-into-invite-process/add-user-into-invite-process.component';


import {
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
  NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "l, LTS"
  },
  display: {
    dateInput: "l, LTS",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    AccountInsuranceComponent,
    AccountNotesComponent,
    AddCustomRegionDialogComponent,
    AddCustomerAccountDialogComponent,
    AddEditAssetDialogComponent,
    AddEditEmployeeDialogsComponent,
    AddEditNetworkDialogComponent,
    AddLocationDialogComponent,
    AddSecurityMenuDialogComponent,
    AddSkillsLevelExpertiseDailogComponent,
    AddSystemRolesDialogComponent,
    AddUserIntoInviteProcessComponent,
    AddVendorEmployeeComponent,
    AppComponent,
    CommunicationTemplatesComponent,
    CompanyDetailsComponent,
    ConfirmDeleteComponent,
    CustomerInviteProcessComponent,
    CustomerPreferencesComponent,
    EditSecurityGroupListDataComponent,
    EmployeeAvailabilityComponent,
    LocationAccountsComponent,
    LocationAssignedAssetDetailsComponent,
    LocationDetailDialogComponent,
    PageNotFoundComponent,
    PaymentTermsComponent,
    ProviderUserInviteProcessComponent,
    RatesDialogComponent,
    SkillsLevelExpertiseDailogComponent,
    SplashScreenComponent,
    SystemAccessRoleDailogComponent,
    TileComponent,
    ToggleFullscreenDirective,
    UploadFileDialogComponent,
    UploadFileModelerComponent,
    UserInviteHistoryComponent,
    UserInviteProcessComponent,
    VendorDiscountsComponent,
    VendorEquipmentDialogComponent,
    ViewLineItemExclusionListDialogComponent,
    ViewServiceExclusionListComponent,
    WheelDirective,

    // AddUserIntoInviteProcessComponent,
    // AddVendorEmployeeComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CKEditorModule,
    CompareValidatorModule,
    FormsModule,
    GooglePlaceModule,
    HttpClientModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatSnackBarModule,
    NbCardModule,
    NbDialogModule.forRoot(),
    NbLayoutModule,
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    NbTooltipModule,
    NgxMaskDirective, NgxMaskPipe,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    NgxMatNativeDateModule,
    NgxMatSelectSearchModule,
    NgxMatTimepickerModule,
    NgxPanZoomModule,
    ReactiveFormsModule,
    ShareModuleModule,
    SharedModule,
    ThemeModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl, environment.breakDownApi, environment.apiUrl, environment.dispatchUrl],
        sendAccessToken: true
      }
    }),
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
      SelectCountryService,
      DatePipe,
      provideNgxMask(),
    { provide: BASE_URL, useValue: 'http://www.angular.at' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
