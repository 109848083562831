import { Component, OnInit, QueryList, ViewChildren, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { NbToastrService } from '@nebular/theme';
import { MatDialog } from '@angular/material/dialog';
import { EmailDetailsDialogComponent } from '../email-details-dialog/email-details-dialog.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-user-invite-history',
  templateUrl: './user-invite-history.component.html',
  styleUrls: ['./user-invite-history.component.scss']
})
export class UserInviteHistoryComponent implements OnInit, OnChanges {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() userInvitationList: any = [];
  @Input() isCustomer = false;
  @Output() listChanged = new EventEmitter<boolean>();
  @Output() userInviteList = new EventEmitter<any>();
  @Output() usersSelected = new EventEmitter<boolean>();

  licensedOwnerName: string;
  searchString: string;
  loading = false;
  userInvitesList: any = [];
  selectedAll: false;
  resendInvite: any = [];
  communicationLogs: any = [];
  sendEmailList: any = [];
  usersList: any = [];

  constructor(
    private userInviteProcess: UserInviteProcess,
    private commonService: CommonService,
    private utilityService: UtilityService,
    private toast: NbToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.licensedOwnerName = sessionStorage.getItem('licensedOwnerName');
    this.getUserInvitations();
  }

  ngOnChanges() {
    this.getUserInvitations();
    this.getUserList();
  }

  getUserInvitations() {
    this.resendInvite = [];
    this.selectedAll = false;
    this.userInvitesList = this.userInvitationList;
    this.userInvitesList.map(obj => obj.selected = false);
    if (this.userInvitesList.length > 0) {
      this.onSort({ column: 'emailDate', direction: 'desc' });
    }
  }

  resendInvitations() {

    // removed invited and signed up userd from resend invite list.
    this.usersList.map((data) => {
      this.resendInvite = this.resendInvite.filter(e => e.email !== data.email);
    });

    if (this.resendInvite.length > 0) {
      this.resendInvite.map(obj => delete obj.userInvitationKey);
      this.loading = true;
      this.userInviteProcess.addUserInvitationsBatch(this.resendInvite).subscribe(result => {
        if (result.success) {
          if (result.body.length > 0) {
            const userInvitationKeys = result.body.map(obj => obj.userInvitationKey) || [];
            this.userInviteList.emit(userInvitationKeys);
          }
        } else {
          this.userInviteList.emit([]);
          this.toast.warning(result.message[0], 'Error');
        }
        this.loading = false;
      }, (error) => {
        this.loading = false;
        this.toast.warning(error, 'Error');
      });
    } else {
      this.toast.warning('Please select emails to resend invite or The User is already registered with the System.', 'Warning');
    }
  }

  getUserList() {

    this.usersList = [];
    const objParam = { deleted: false };
    this.commonService.getUsersList(objParam).subscribe((result) => {
      this.usersList = result.body;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  openEmailDetails(user) {
    this.dialog.open(EmailDetailsDialogComponent, {
      width: '70%',
      height: '90%',
      disableClose: true,
      position: {
        top: '2%'
      },
      data: user,
    });
  }

  selectAll() {
    this.userInvitesList.map(obj => obj.selected = this.selectedAll);
    if (this.selectedAll) {
      this.userInvitesList.forEach(user => {
        this.setUserInvitations(user);
      });
    } else {
      this.resendInvite = [];
    }
    this.usersSelected.emit(this.resendInvite.length > 0 ? true : false);
  }

  checkIfAllSelected(user) {
    this.selectedAll = this.userInvitesList.every((item: any) => {
      return item.selected === true;
    });
    if (user.selected === true) {
      this.setUserInvitations(user);
    } else {
      this.resendInvite = this.resendInvite.filter(e => e.userInvitationKey !== user.userInvitationKey);
    }
    this.usersSelected.emit(this.resendInvite.length > 0 ? true : false);
  }

  setUserInvitations(user) {
    this.resendInvite.push({
      userInvitationKey: user.userInvitationKey,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      cellPhone: user.cellPhone,
      securityGroupKey: user.securityGroupKey,
      departmentKey: user.departmentKey,
      supervisor: user.supervisorKey,
      contactCenterUserRoleKey: user.contactCenterUserRoleKey,
      deleted: false,
      associationKey: user.associationKey,
      userTypeKey: user.userTypeKey,
      insertedUserKey: sessionStorage.getItem('userKey'),
      systemDefault: true,
      updatedUserKey: '',
      emailResponseDate: null,
      emailDate: null,
      subDomainKey: sessionStorage.getItem('subDomainKey') || null,
    });
  }

  search(event) {
    this.searchString = event;
  }

  onSort({ column, direction }: SortEvent) {
    this.userInvitesList = this.utilityService.sortData(this.headers, column, direction, this.userInvitesList);
  }
}
