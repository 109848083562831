<app-loader [loading]="loading"></app-loader>
<div class="row">
    <div class="col-md-12" *ngIf="isParent">
        <h6>{{companyName}}</h6>
    </div>
    <div class="col-12 col-md-4 mt-2">
        <ngx-search-input (search)="search($event)"></ngx-search-input>
    </div>
    <div class="col-12 col-md-8 text-right mt-2">
        <button type="button" *ngIf="!disableFlag" class="btn btn-primary btn-sm" (click)="openUserInviteDialog()"
            [disabled]="isMasterLicensedOwner">
            Add New User(s)
        </button>
    </div>
</div>

<div class="row">
    <div class="col-12 employee-section">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Job Title</th>
                        <th>Status</th>
                        <th *ngIf="!disableFlag">Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="employeeData; else noData">
                    <tr *ngFor="let employee of employeeData | filter : searchString;">
                        <td>{{companyName}}</td>
                        <td>{{employee.firstName}}</td>
                        <td>{{employee.lastName}}</td>
                        <td>{{employee.cellPhone}}</td>
                        <td>{{employee.email}}</td>
                        <td>{{employee.title}}</td>
                        <td>{{employee.userStatusKey}}</td>
                        <td *ngIf="!disableFlag">
                            <button class="btn btn-sm btn-default btn-sm btnAnimation"
                                [disabled]="isMasterLicensedOwner"
                                (click)="addNewVendorEmployee('Edit Employee', employee)" nbTooltip="Edit">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="9" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </div>
</div>