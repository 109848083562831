import { Component, OnInit } from '@angular/core';
import { BubbleService } from 'src/app/services/bubble-service.service';

@Component({
  selector: 'bubble-user-information-index',
  templateUrl: './bubble-user-information-index.component.html',
  styleUrls: ['./bubble-user-information-index.component.scss']
})
export class BubbleUserInformationIndexComponent implements OnInit {
  isParentRecord = false;

  constructor(private bubbleService: BubbleService) { }

  ngOnInit() {
    this.bubbleService.isParentRecord.subscribe(message => {
      this.isParentRecord = message;
    });
  }

}
