<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="discountPopup">
    <div class="w3-panel">
        <p>
            <strong>{{accountName}}</strong> will pay a discounted rate for your services. This discount will be
            reflected on the payment for the invoices you submit.
        </p>
    </div>
    <div class="row noMargin">
        <div class="col-4 mt-2 form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search"
                (input)="onInput($event)">
        </div>
        <div class="offset-2 col-3 mt-2 text-right form-group has-search">
            <button class="btn btn-sm btn-orange" (click)="openLineItemExclusionList()">
                View Line Item Exclusion List
            </button>
        </div>
        <div class="col-3 mt-2 form-group has-search">
            <button class="btn btn-sm btn-orange" (click)="openServiceExclusionList()">
                View Service Exclusion List
            </button>
        </div>
    </div>
    <div class="col-md-12 mt-2">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th sortable="Description" (sort)="onSort($event)" direction="asc">Service</th>
                        <th sortable="Description" (sort)="onSort($event)" direction="asc">Discount Type</th>
                        <th sortable="DiscountAmount" (sort)="onSort($event)" direction="asc">Discounts</th>
                        <th sortable="DiscountNotes" (sort)="onSort($event)" direction="asc">Note</th>
                    </tr>
                </thead>
                <tbody [hidden]="isNoData">
                    <tr *ngFor="let discounts of discountData | filter : searchText;  let i = index;">
                        <td>{{discounts.Description}}</td>
                        <td>{{discounts.DiscountTypeKey}}</td>
                        <td>{{discounts.DiscountAmount}} <span *ngIf="discounts.DiscountTypeKey == 'Percent'">%</span>
                        </td>
                        <td>{{discounts.DiscountNotes == null ? '': discounts.DiscountNotes }} </td>
                    </tr>
                </tbody>
                <tbody [hidden]="!isNoData">
                    <tr>
                        <td class="text-center" colspan="4"> No Data Available</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>