<h1 mat-dialog-title>Hi {{data.companyName}}</h1>
<div mat-dialog-content>
    <!-- <p>What's your favorite animal?</p> -->
    <mat-form-field>
        <mat-label>Company Name</mat-label>
        <input matInput [(ngModel)]="data.companyName">
    </mat-form-field>

    <div style="display: flex; justify-content: space-between;">
        <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput [(ngModel)]="data.ownerFirstName">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput [(ngModel)]="data.ownerLastName">
        </mat-form-field>
    </div>

    <mat-form-field>
        <mat-label>Phone</mat-label>
        <input matInput [(ngModel)]="data.businessHoursPhone">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="data.dispatchEmail">
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="data.companyName" cdkFocusInitial>Ok</button>
</div>