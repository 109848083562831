<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">{{dialogHeader}}</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <nb-card>
        <nb-card-body>
            <form [formGroup]="userSkillsForm">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-select formControlName="skillKey" required placeholder="Select Skills"
                                [disabled]="dialogHeader == 'Edit Skills'">
                                <mat-option *ngFor="let data of skillsData | orderBy:'skillKey'"
                                    [value]="data.skillKey">{{data.skillKey}}</mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="userSkillsForm.controls.skillKey.touched && userSkillsForm.controls.skillKey.errors">
                                <span>Skill is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-select formControlName="levelOfExpertise" required
                                placeholder="Select Expertise Level">
                                <mat-option *ngFor="let number of arrayOne(6); let i = index;" [value]="i">
                                    {{i}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="userSkillsForm.controls.levelOfExpertise.touched && userSkillsForm.controls.levelOfExpertise.errors">
                                <span>Expertise Level is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </nb-card-body>
    </nb-card>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-orange btn-sm" (click)="addEditSkills()">{{buttonText}}</button>
</div>