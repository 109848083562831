<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<nb-card>
    <nb-card-body>
        <!-- <div class="row">
            <div class="col-12 text-right mb-2">
                <button class="btn btn-sm btn-orange" [disabled]="isSecurityGroup" (click)="addNewGroups()"
                    title="Add New Security Groups">Add
                    New</button>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th sortable="securityLevel" (sort)="onSort($event)">
                                    Security Group - Security Level
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of groupData; let i = index">
                                <td>
                                    <span *ngIf="!(item.editMode)">
                                        {{item.description}} - {{item.securityLevel}}
                                    </span>
                                    <span *ngIf="item.editMode">
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="item.description" required
                                                placeholder="Select Group name">
                                                <mat-option
                                                    *ngFor="let val of securityGroupData | orderBy:'securityLevel'"
                                                    [value]="val.description">
                                                    {{val.description}} - {{val.securityLevel}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                <span>Group Name is required.</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="isReadOnly">No action Available</span>
                                    <button *ngIf="!(item.editMode) && !isReadOnly" class="btn btn-default btnAnimation"
                                        nbTooltip="Edit" (click)="editSecurityGroup(i)">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                    <button *ngIf="item.editMode" class="btn btn-default btnAnimation"
                                        nbTooltip="Update" (click)="updateSecurityGroup(item)">
                                        <i class="fas fa-check"></i>
                                    </button>
                                    <button *ngIf="item.editMode" class="btn btn-default btnAnimation"
                                        (click)="cancelSecurityGroup(i)" nbTooltip="Cancel">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </td>
                                <!-- <td>
                                        <button class="btn btn-default btn-sm btnAnimation ml-2" (click)="deleteGroup(item)" nbTooltip="Delete" disabled>
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>