<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="w3-panel">
    <label>
        Note: Do not select capabilities you cannot support; doing so is violation of the Terms & Conditions of
        membership in the Service Plus Network.
    </label>
</div>
<div class="offset-md-8 col-md-4 text-right has-search">
    <input type="text" class="form-control" [(ngModel)]="searchString" placeholder="Search" (input)="onInput($event)">
</div>
<div class="row mx-3 mt-2 border p-4">
    <!-- <ngx-checkbox-tree-ui *ngIf="providerCapabilities" [levelData]="providerCapabilities" [level]="level"
            [isSelected]="isSelected" [parentRecord]="parentRecord" [lastNode]="lastNode" [providerKey]="providerKey">
        </ngx-checkbox-tree-ui> -->
    <ng-container *ngFor="let data of providerCapabilities | recursiveFilter: searchString :  'description'">
        <tree-root [nodes]="[data]" #tree [options]="options" class="col-md-4 mt-2">
            <ng-template #treeNodeTemplate let-node="node" let-index="index">
                <mat-checkbox [checked]="node.data.isSelected" (change)="onSelectedChange(node, $event)">
                    <strong>{{ node.data.description }}</strong>
                </mat-checkbox>
            </ng-template>
        </tree-root>
    </ng-container>
</div>