import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BillingDetailsComponent } from 'src/app/shared/billing-details/billing-details.component';
import { SetupDispatchDetailsComponent } from 'src/app/shared/setup-dispatch-details/setup-dispatch-details.component';
import { LocationDetailsComponent } from '../../shared/location-details/location-details.component';

@Component({
  selector: 'ngx-location-detail-dialog',
  templateUrl: './location-detail-dialog.component.html',
  styleUrls: ['./location-detail-dialog.component.scss'],
})
export class LocationDetailDialogComponent implements OnInit {

  @ViewChild(LocationDetailsComponent, { static: false }) locationDetails: LocationDetailsComponent;
  @ViewChild(BillingDetailsComponent, { static: false }) billingDetails: BillingDetailsComponent;
  @ViewChild(SetupDispatchDetailsComponent, { static: false }) dispatchDetails: SetupDispatchDetailsComponent;

  selectedIndex: number = 0;
  providerDetails: any;
  locationKey: string;
  providerAccount: any = [];

  constructor(
    public dialog: MatDialogRef<LocationDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.providerDetails = this.data.providerDetails;
    this.locationKey = this.data.locationKey;
    this.providerAccount.providerKey = this.locationKey;
  }

  closeDialog() {
    this.dialog.close();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedIndex = tabChangeEvent.index;
  }

  saveCompleted(event) {
    if (event) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  next() {
    if (this.selectedIndex === 0) {
      this.locationDetails.addUpdateLocationDetails();
    }
    if (this.selectedIndex === 1) {
      this.dispatchDetails.updateProviderDispatchDetails();
    }
    if (this.selectedIndex === 2) {
      this.billingDetails.updateProviderBillingDetails();
    }

    if (this.selectedIndex !== 0 && this.selectedIndex !== 1 && this.selectedIndex !== 2) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  saveTechnician() { }
}
