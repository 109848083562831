import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  userDetails: any;

  user: any;
  showHeader: number;
  imageURL: any;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  userName: any;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private oauthService: OAuthService) {
  }

  ngOnInit() {
    this.userDetails = [{ firstname: sessionStorage.getItem('userName'), lastname: '' }];
    this.commonService.userName.subscribe(message => {
      this.userName = message.toString();
      if (this.userName != '' || this.userName != "null" || this.userName != "undefined") {
        this.userDetails = [{ firstname: this.userName, lastname: '' }];
      }
    });
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  navigateHome() {

  }

  startSearch() {

  }

  gotoMyProfile() {
    this.router.navigate(["../pages/profile/my-profile/"], { relativeTo: this.route });
  }

  logout() {
    this.oauthService.logOut();
  }
}
