<nb-card>
  <nb-card-header class="card-header">Provider View</nb-card-header>
  <nb-card-body>
    <div class="col-md-12">
      <div class="table-responsive mt-2 divtable">
      <table class="table table-bordered table-fixed">
        <thead>
          <tr>
            <th>Service</th>
            <th>% Discount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let discountproviderview of DiscountProviderView">
            <td>
                <mat-form-field class="tdMargin">
                    <mat-select placeholder="Select service">
                        <mat-option value="option 1" selected>Towing</mat-option>
                        <mat-option value="option 2">Mobile Repair</mat-option>
                        <mat-option value="option 2">Shop Service</mat-option>
                        <mat-option value="option 2">TTN Recovery</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td class="tdMargin tdAlign">
                  {{discountproviderview.discount}}%
              </td>
          </tr>
          <tr *ngIf="flag">
            <td>
              <mat-form-field class="tdMargin">
                  <mat-select placeholder="Add new service">
                      <mat-option value="option 1" selected>Towing</mat-option>
                      <mat-option value="option 2">Mobile Repair</mat-option>
                      <mat-option value="option 2">Shop Service</mat-option>
                      <mat-option value="option 2">TTN Recovery</mat-option>
                  </mat-select>
              </mat-form-field>
          </td>
          <td>
            <mat-form-field class="col-md-5">
              <input matInput [value]="">
            </mat-form-field>
            </td>
          </tr>
        </tbody>
      
      <!-- <tbody>
              <tr>
                    <td>
                          <mat-form-field>
                              <mat-select placeholder="Add new service">
                                  <mat-option value="option 1" selected>Towing</mat-option>
                                  <mat-option value="option 2">Mobile Repair</mat-option>
                                  <mat-option value="option 2">Shop Service</mat-option>
                                  <mat-option value="option 2">TTN Recovery</mat-option> 
                              </mat-select>
                          </mat-form-field>
                    </td>
                    <td>
                          <mat-form-field class="col-md-5">
                              <input matInput [value]="">
                          </mat-form-field>
                    </td>
              </tr>
      </tbody> -->
      </table>
    </div>
    </div>
    <div class="text-right">
        <button class="btn btn-primary btn-sm mt-2 btn-margin" (click)="test()">Add</button>
      <button class="btn btn-primary btn-sm mt-2">Close</button>
    </div>
</nb-card-body>
</nb-card>
