<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-body>
        <div class="row">
            <div class="col text-right mb-2">
                <button class="btn btn-sm btn-orange btn-md" (click)="addUserSkills('add')">Add Skills</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="table-responsive user-skills-tbl">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Skills</th>
                                <th>Level of Expertise</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="skillsData.length > 0; else noDataAvailable">
                            <tr *ngFor="let item of skillsData">
                                <td>{{item.skillKey}}</td>
                                <td>{{item.levelOfExpertise}}</td>
                                <td>
                                    <button class="btn btn-sm btn-default btn-sm btnAnimation"
                                        (click)="editUserSkills(item)" nbTooltip="Edit Skills">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                    <button class="btn btn-sm btn-default btn-sm btnAnimation ml-2"
                                        (click)="removeUserSkills(item)" nbTooltip="Delete Skills">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <ng-template #noDataAvailable>
                            <tbody>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        No Data Available
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>