import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddUserIntoInviteProcessComponent } from './add-user-into-invite-process/add-user-into-invite-process.component';
import { UploadFileModelerComponent } from './upload-file-modeler/upload-file-modeler.component';
import { CommunicationTemplatesComponent } from './communication-templates/communication-templates.component';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { UserInviteHistoryComponent } from './user-invite-history/user-invite-history.component';
import { CommonService } from 'src/app/services/common.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-user-invite-process',
  templateUrl: './user-invite-process.component.html',
  styleUrls: ['./user-invite-process.component.scss']
})
export class UserInviteProcessComponent implements OnInit {

  @ViewChild(AddUserIntoInviteProcessComponent, { static: true }) addUserIntoInviteProcess: AddUserIntoInviteProcessComponent;
  @ViewChild(UploadFileModelerComponent, { static: true }) uploadFileModeler: UploadFileModelerComponent;
  @ViewChild(CommunicationTemplatesComponent, { static: true }) communicationTemplate: CommunicationTemplatesComponent;
  @ViewChild(UserInviteHistoryComponent, { static: true }) inviteHistory: UserInviteHistoryComponent;

  loading = false;
  selectedIndex = 0;
  securityGroups: any = [];
  departmentList: any = [];
  rolesList: any = [];
  userInvitesList: any = [];
  userInviteSentList: any = [];
  isSelected = false;
  userTypeKey = sessionStorage.getItem('userTypeKey');
  associationKey = sessionStorage.getItem('licensedOwnerKey');
  templateKey = 'e673cbb5-0dd4-46be-9531-feee790f4d79';

  constructor(
    private commonService: CommonService,
    public dialog: MatDialogRef<UserInviteProcessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userInviteProcess: UserInviteProcess, ) {
  }

  ngOnInit() {
    this.initDropdowns();
    this.getUserInvitations();
  }

  initDropdowns() {
    const securityFilter = {
      platform: 'FleetAssist',
      userTypeKey: this.userTypeKey,
      deleted: false,
    };
    const roleFilter = {
      deleted: false,
    };
    const deptFilter = {
      deleted: false,
    };
    const securityGrpReq = this.userInviteProcess.getSecurityGroups(securityFilter);
    const userRolesReq = this.commonService.getContactCenterUserRoles(roleFilter);
    const deptReq = this.commonService.getDepartments(deptFilter);

    this.loading = true;
    forkJoin([securityGrpReq, userRolesReq, deptReq]).subscribe(result => {
      this.loading = false;
      this.securityGroups = result[0].body || [];
      this.rolesList = result[1].body || [];
      this.departmentList = result[2].body || [];
    }, (error) => {
      this.loading = false;
    });
  }

  getUserInvitations() {
    this.isSelected = false;
    this.userInvitesList = [];
    this.loading = true;
    const inviteFilter = {
      userKey: sessionStorage.getItem('userKey'),
      userTypeKey: this.userTypeKey,
      isEmailSent: false
    };
    const historyFilter = {
      userKey: sessionStorage.getItem('userKey'),
      userTypeKey: this.userTypeKey,
      isEmailSent: true
    };
    const inviteReq = this.userInviteProcess.getUserInvitations(inviteFilter);
    const inviteHistoryReq = this.userInviteProcess.getUserInvitations(historyFilter);

    this.loading = true;
    forkJoin([inviteReq, inviteHistoryReq]).subscribe(result => {
      this.loading = false;
      this.userInvitesList = result[0].body || [];
      this.userInviteSentList = result[1].body || [];
    }, (error) => {
      this.loading = false;
    });
  }

  sendInvitations() {
    if (this.selectedIndex === 0 || this.selectedIndex === 1) {
      const userInvitationKeys = [];
      this.userInvitesList.map(obj => {
        if (obj.userInvitationKey) {
          userInvitationKeys.push(obj.userInvitationKey);
        }
      });
      this.communicationTemplate.sendInvitations(userInvitationKeys);
    }
  }

  userListChanged(event) {
    if (event === true) {
      this.getUserInvitations();
    }
  }

  sendInviteWithEditedTemplate() {
    if (this.isSelected === true) {
      this.reSendInvitations();
    } else {
      const userInvitationKeys = [];
      this.userInvitesList.map(obj => {
        if (obj.userInvitationKey) {
          userInvitationKeys.push(obj.userInvitationKey);
        }
      });
      this.communicationTemplate.sendInvitations(userInvitationKeys);
    }
  }

  reSendInvitations() {
    const isValid = this.communicationTemplate.isEmailParamsValid();
    if (isValid) {
      this.inviteHistory.resendInvitations();
    }
  }

  tabChanged(event: any) {
    this.selectedIndex = event.index;
  }

  viewTemplate() {
    this.selectedIndex = 2;
  }

  selecionChanged(status) {
    this.isSelected = status;
  }

  selectedInvitesChanged(userInvitationKeys) {
    this.communicationTemplate.sendInvitations(userInvitationKeys);
  }

  closeDialog() {
    this.dialog.close();
  }
}
