<div class="modal-header">
    <h6 class="modal-title">Add New Broker / Agency</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="borderPadding">
<div class="modal-body">
    <form [formGroup]="brokerDetailForm">
        <div *ngIf="loading" id="preloader">
            <div id="loader"></div>
        </div>
        <div class="row noMargin">
            <div class="col-md-3">
                <mat-form-field>
                    <input matInput placeholder="Broker Agency" formControlName="agencyName" required>
                    <mat-error *ngIf="handleError('agencyName', 'required')">
                        Broker Agency is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('agencyName', 'pattern')">
                        Enter valid Broker Agency.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <input matInput placeholder="Agent Name" formControlName="agentName" required>
                    <mat-error *ngIf="handleError('agentName', 'required')">
                        Agent Name is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('agentName', 'pattern')">
                        Enter valid Agent Name.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <input matInput placeholder="Agent Phone" mask="000-000-0000" formControlName="agentPhone">
                    <span *ngIf="brokerDetailForm.controls.agentPhone.value != '' && brokerDetailForm.controls.agentPhone.value != null " matTextPrefix>+1&nbsp;</span>
                    <mat-error *ngIf="handleError('agentPhone', 'required')">
                        Enter valid Agent Phone.
                    </mat-error>
                    <mat-error *ngIf="handleError('agentPhone', 'pattern')">
                        Enter valid Agent Phone.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <input matInput placeholder="Agent Email" formControlName="agentEmail" required>
                    <mat-error *ngIf="handleError('agentEmail', 'required')">
                        Agent Email is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('agentEmail', 'pattern')">
                        Enter valid Agent Email.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field>
                    <!-- <input matInput placeholder="Agent Fax" mask="000-000-0000" formControlName="agentFax">
                    <mat-error *ngIf="handleError('agentFax', 'pattern')">
                        Enter valid Agent Fax.
                    </mat-error> -->
                    <mat-label>Policy Expiration Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="policyExpirationDate">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="handleError('policyExpirationDate','required')">
                        Policy Expiration Date Required.
                    </mat-error>
                    <mat-error *ngIf="handleError('policyExpirationDate', 'pastDate')">
                        Policy Cannot Be Expired.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm mr-2" type="button" (click)="addNewBroker()">Save</button>
</div>
</div>