import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { NbToastrService } from '@nebular/theme';
import { MatDialog } from '@angular/material/dialog';
import { AddEditHolidayDialogComponent } from 'src/app/dialogs/add-edit-holiday-dialog/add-edit-holiday-dialog.component';
import { HoursOfOperationService } from 'src/app/services/hours-of-operation.service';
import { SetupService } from 'src/app/services/setup.service';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'ngx-hours-of-operation',
  templateUrl: './hours-of-operation.component.html',
  styleUrls: ['./hours-of-operation.component.scss']
})

export class HoursOfOperationComponent implements OnInit {

  @Input() showBack: boolean = false;
  @Input() showSaveNext: boolean = false;
  @Input() showSave: boolean = false;
  @Input() isParent: boolean = true;
  @Input() providerDetails: any;
  @Input() locationKey: string;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';


  loading: boolean;
  weekData: any = [];
  editDayFlag: boolean = false;
  Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  providerKey: string;
  defaultLocationKey: string;
  openTime: any;
  closeTime: any;
  providerHoursOfOperationKey: any;
  tierCanSetHours = true;
  currentTimezone: any;
  getChildData: any;
  parentData: boolean = false;

  constructor(
    private hoursOfOperationService: HoursOfOperationService,
    private toast: NbToastrService,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private setupService: SetupService,
  ) { }

  ngOnInit() {
    this.providerKey = sessionStorage.getItem('providerKey');
    this.defaultLocationKey = sessionStorage.getItem('defaultLocationKey');
    if (!!this.locationKey) {
      this.providerKey = this.locationKey;
      this.isParent = false;
      this.tierCanSetHours = sessionStorage.getItem('tierCanSetHours') === 'true' ? true : false;
    } else if (!!this.defaultLocationKey) {
      this.providerKey = this.defaultLocationKey;
      this.isParent = false;
      this.tierCanSetHours = sessionStorage.getItem('tierCanSetHours') === 'true' ? true : false;
    } else if (this.providerDetails != undefined) {
      this.providerKey = this.providerDetails.providerKey;
      this.isParent = true;
    } else if (sessionStorage.getItem('providerKey') != 'null') {
      this.providerKey = sessionStorage.getItem('providerKey');
      this.isParent = false;
    }
    this.getCurrentTimeZone();
    this.getHoursOfOperation();
  }

  getCurrentTimeZone() {
    let data = {
      providerKey: this.providerKey,
      deleted: false
    }
    this.profileService.getProviderDetails(data).subscribe((result: any) => {
      this.currentTimezone = result.body[0]?.timeZoneKey
    }, (error) => {
      console.log(error);
    });
  }

  getHoursOfOperation() {
    this.loading = true;
    this.Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    this.weekData = [];
    const data = {
      providerKey: this.providerKey,
      deleted: false
    }

    this.loading = true;
    this.hoursOfOperationService.getHoursOfOperation(data).subscribe(res => {
      this.loading = false;
      if (res.body.length > 0) {
        this.parentData = false;
        this.Days.forEach(obj => {
          res.body.forEach(weekday => {
            if (obj === weekday.dayOfWeek) {
              this.weekData.push(weekday);
            }
          });
        });
      } else {
        this.parentData = true;
        const data = {
          providerKey: sessionStorage.getItem('providerKey'),
          deleted: false
        }
        this.loading = true;
        this.hoursOfOperationService.getHoursOfOperation(data).subscribe(res => {
          this.loading = false;
          this.Days.forEach(obj => {
            res.body.forEach(weekday => {
              if (obj === weekday.dayOfWeek) {
                this.weekData.push(weekday);
              }
            });
          });
        }, (error) => {
          this.loading = false;
          console.log(error);
        })
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    })
  }

  editDay(data) {
    this.providerHoursOfOperationKey = data.providerHoursOfOperationKey;
    this.editDayFlag = data.dayOfWeek;
    this.openTime = data.openTime;
    this.closeTime = data.closeTime;
  }

  closeDayData(dayOfWeek) {
    this.editDayFlag = !dayOfWeek;
  }

  updateHoursOfOperation(fromTime, toTime, HoursOfOperationKey, isClosed = 0) {
    this.editDayFlag = false;
    if (this.parentData) {
      let operationData = [];
      this.weekData.forEach(item => {
        let data = {
          'openTime': HoursOfOperationKey === item.providerHoursOfOperationKey ? fromTime : item.openTime,
          'closeTime': HoursOfOperationKey === item.providerHoursOfOperationKey ? toTime : item.closeTime,
          'dayOfWeek': item.dayOfWeek,
          'deleted': false,
          'systemDefault': false,
          'providerKey': this.providerKey,
          'insertedUserKey': sessionStorage.getItem('userKey'),
          'updatedUserKey': sessionStorage.getItem('userKey'),
          'closed': isClosed
        }
        operationData.push(data);
      });

      this.loading = true;
      this.setupService.addDefaultHoursOfOperation(operationData).subscribe((res: any) => {
        this.loading = false;
        this.getHoursOfOperation();
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    } else {
      const editData = {
        openTime: fromTime,
        closeTime: toTime,
        providerHoursOfOperationKey: HoursOfOperationKey,
        providerKey: this.providerKey,
        updatedUserKey: sessionStorage.getItem("userKey"),
        closed: isClosed
      };

      this.loading = true;
      this.hoursOfOperationService.updateHoursOfOperation(editData).subscribe((result: any) => {
        if (result.success) {
          this.toast.success(result.message[0], 'Success');
          this.getHoursOfOperation();
        } else {
          this.toast.warning(result.message[0], 'Error');
        }

        this.loading = false;
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    }
  }

  openHolidayDialog() {
    let dialogRef = this.dialog.open(AddEditHolidayDialogComponent, {
      hasBackdrop: false,
      width: '70%',
      height: '85%',
      position: {
        top: "3%",
      },
      data: { 'providerKey': this.providerKey }
    });
  }

  setTiersCanSetHours(event) {
    let data = {
      "providerKey": this.providerKey,
      "tierCanSetHours": event.checked,
      "updatedUserKey": sessionStorage.getItem('userKey')
    };

    this.profileService.updateProviderDetails(data).subscribe((result: any) => {
      sessionStorage.setItem("tierCanSetHours", event.checked)
    }, (error) => {
      this.loading = false;
      console.log(error);
    });

    let getParentKey = {
      "parentKey": this.providerKey,
      'deleted': false
    };

    this.profileService.getProviderDetails(getParentKey).subscribe((res: any) => {
      this.getChildData = res.body;
      if (this.getChildData.length > 0) {
        let childData = [];
        this.getChildData.forEach(x => childData.push({ providerKey: x.providerKey, tierCanSetHours: event.checked, updatedUserKey: sessionStorage.getItem('userKey') }))

        this.profileService.updateProviderChildDetails(childData).subscribe((res: any) => { }, (error) => {
          console.log(error);
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  setHoursOfOperation() {
    if (this.parentData) {
      let operationData = [];
      this.Days.forEach(day => {
        let data = {
          'openTime': '8:00 AM',
          'closeTime': '8:00 AM',
          'dayOfWeek': day,
          'deleted': false,
          'systemDefault': false,
          'providerKey': this.providerKey,
          'insertedUserKey': sessionStorage.getItem('userKey'),
          'updatedUserKey': sessionStorage.getItem('userKey')
        }
        operationData.push(data);
      });

      this.loading = true;
      this.setupService.addDefaultHoursOfOperation(operationData).subscribe((res: any) => {
        this.loading = false;
        this.getHoursOfOperation();
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    } else {
      let operationData = [];
      this.weekData.forEach(item => {
        let data = {
          'providerHoursOfOperationKey': item.providerHoursOfOperationKey,
          'openTime': '8:00 AM',
          'closeTime': '8:00 AM',
          'deleted': false,
          'providerKey': this.providerKey,
          'updatedUserKey': sessionStorage.getItem('userKey')
        }
        operationData.push(data);
      });

      this.loading = true;
      this.hoursOfOperationService.updateHoursOfOperationBatch(operationData).subscribe((res: any) => {
        this.loading = false;
        this.getHoursOfOperation();
      }, (error) => {
        this.loading = false;
        console.log(error);
      });
    }
  }

  resetHoursOfOperation() {
    this.openTime = '8:00 AM';
    this.closeTime = '8:00 AM';
  }

}
