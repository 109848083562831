import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, Router, RouteConfigLoadEnd } from '@angular/router';
import { RouteLoaderService } from './services/route-loader.service';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'login/oauth2/code/cognito',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'welcome',
    component: SplashScreenComponent
  },
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: '**', redirectTo: 'welcome' },
];

const config: ExtraOptions = {
    useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  isRequestSent = false;
  constructor(private router: Router,
    private routeLoader: RouteLoaderService) {
    this.router.events.subscribe(async routerEvent => {
      if (routerEvent instanceof RouteConfigLoadEnd) {
        const securityGroupKey = sessionStorage.getItem('securityGroupKey');
        if (securityGroupKey && !this.isRequestSent) {
          const objParams = {
            userKey: sessionStorage.getItem('userKey'),
            securityGroupKey: securityGroupKey,
          };
          this.isRequestSent = true;
          this.routeLoader.load(objParams).subscribe(res => {
            this.router.navigateByUrl(this.router.url);
          });
        }
      }
    });
  }
}
