import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ngx-search-input',
  styleUrls: ['./search-input.component.scss'],
  template: `
  <div class="form-group has-search">
  <span class="fa fa-search form-control-feedback"></span>
  <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search" (input)="onInput($event)">
</div>
`,
})
export class SearchInputComponent {
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  searchText: string;
  onInput(val: string) {
    this.search.emit(this.searchText);
  }
}
