<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-body>
        <div class="row">
            <div class="col-md-6" *ngIf="showOwnBy">
                <span class="font-weight-bold">Owned By: </span> &nbsp; {{customerName}}
            </div>
            <div [ngClass]="showOwnBy? 'offset-md-3': 'offset-md-9'" class="col-md-3 text-right">
                <ngx-search-input (search)="search($event)"></ngx-search-input>
            </div>
        </div>
        <div class="table-responsive mt-2">
            <table class="table table-bordered table-striped rates" [ngClass]="{'parent': isParentData}">
                <thead>
                    <tr>
                        <th sortable="customerName" (sort)="onSort($event)" class="align-middle" direction="asc">
                            Customer Name </th>
                        <th class="align-middle text-center"> Preferred </th>
                        <th sortable="dnuReason" (sort)="onSort($event)" class="align-middle"> Do Not Use Reason
                        </th>
                        <th sortable="updatedByUser" (sort)="onSort($event)" class="align-middle"> Updated By </th>
                        <th sortable="updatedTimestamp" (sort)="onSort($event)" class="align-middle"> Update
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="preferenceData.length > 0; else noData">
                    <tr *ngFor="let item of preferenceData | filter : searchString;">
                        <td>{{item.customerName}}</td>
                        <td class="text-center">
                            <span *ngIf="item.preferred">
                                <i class="fas fa-circle text-success fa-2x" aria-hidden="true"></i>
                            </span>
                            <span *ngIf="!item.preferred"></span>
                        </td>
                        <td>{{item.dnuReason}}</td>
                        <td>{{item.updatedByUser}}</td>
                        <td>{{item.updatedTimestamp | timezoneConvertDate}}</td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="5" class="text-center">No Data available</td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </nb-card-body>
</nb-card>