import { Component, OnInit, ViewChildren, QueryList, Inject } from '@angular/core';
import { ProviderService } from 'src/app/services/provider.service';
import { ProviderAdminService } from 'src/app/services/provider-admin.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';

@Component({
  selector: 'app-account-requirements-dialog',
  templateUrl: './account-requirements-dialog.component.html',
  styleUrls: ['./account-requirements-dialog.component.scss']
})
export class AccountRequirementsDialogComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  loading = false;
  insuranceRequirements: any = [];
  selectedPolicyDetails: any = [];

  constructor(
    public dialog: MatDialogRef<AccountRequirementsDialogComponent>,
    private providerService: ProviderService,
    private providerAdminService: ProviderAdminService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.selectedPolicyDetails = this.data.policyDetail;
    this.getAccountRequirements();
  }

  closeDialog() {
    this.dialog.close();
  }

  getAccountRequirements() {
    if (this.selectedPolicyDetails.insuranceTypeKey) {
      const filter = {
        insuranceTypeKey: this.selectedPolicyDetails.insuranceTypeKey,
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        deleted: false,
      };

      this.loading = true;
      this.providerAdminService.getLicensedOwnerInsuranceRequirements(filter).subscribe((result: any) => {
        this.loading = false;
        const insuranceRequirements = result.body;

        insuranceRequirements.map(obj => {
          obj.accountName = sessionStorage.getItem('licensedOwnerName');

          if (obj.minimumInsuredAmount <= this.selectedPolicyDetails.insuredAmount) {
            this.insuranceRequirements.push(obj);
          }
        });
        this.onSort({ column: 'accountName', direction: 'asc' });
      }, (error) => {
        this.loading = false;
      });
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.insuranceRequirements = this.utilityService.sortData(this.headers, column, direction,
      this.insuranceRequirements);
  }

}
