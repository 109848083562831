import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CapabilitiesService {

  constructor(
    private http: HttpClient,
  ) { }

  // Get provider capabilities
  getProviderCapabilities(filter: any = {}): Observable<any> {
    if (!filter.deleted) {
      filter.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/providercapabilities/get`, filter);
  }

  // Get makes
  getMakes(filter: any = {}): Observable<any> {
    if (!filter.deleted) {
      filter.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/makes/get`, filter);
  }

  // Get provider service capabilities
  getProviderServiceCapabilities(filter: any = {}): Observable<any> {
    if (!filter.deleted) {
      filter.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerservicecapabilities/get`, filter);
  }

  // Add provider service capabilities
  addProviderServiceCapabilities(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerservicecapabilities`, data);
  }

  // Add provider service capabilities batch
  addProviderServiceCapabilitiesBatch(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerservicecapabilities/batch`, data);
  }

  // Update provider service capabilities
  updateProviderServiceCapabilities(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerservicecapabilities`, data);
  }

  // Delete provider service capabilities
  deleteProviderServiceCapabilities(data): Observable<any> {
    const deleteData: any = {
      body: { providerServiceCapabilityKey: data.providerServiceCapabilityKey },
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providerservicecapabilities`, deleteData);
  }

  // Get provider capability warranty makes
  getProviderCapabilityWarrantyMakes(filter: any = {}): Observable<any> {
    if (!filter.deleted) {
      filter.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providercapabilitywarrantymakes/get`, filter);
  }

  // Add provider capability warranty makes batch
  addProviderCapabilityWarrantyMakesBatch(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providercapabilitywarrantymakes/batch`, data);
  }

  // Update provider capability warranty makes
  updateProviderCapabilityWarrantyMakes(data: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providercapabilitywarrantymakes`, data);
  }

  // Update provider capability warranty makes batch
  updateProviderCapabilityWarrantyMakesBatch(data: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providercapabilitywarrantymakes/batch`, data);
  }

  // Delete provider capability warranty makes
  deleteProviderCapabilityWarrantyMakes(data): Observable<any> {
    const deleteData: any = {
      body: { providerCapabilityWarrantyMakeKey: data.providerCapabilityWarrantyMakeKey },
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providercapabilitywarrantymakes`, deleteData);
  }

  // Get provider capabilities by provider
  getProviderCapabilitiesByProvider(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderCapabilitiesByProvider`, data);
  }

  // Add or delete provider capabilities
  addDeleteProviderCapabilities(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}addDeleteProviderCapabilities`, data);
  }
}
