import { Component, OnInit, Input, ViewChildren, Output, EventEmitter, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditAssetDialogComponent } from '../../add-edit-asset-dialog/add-edit-asset-dialog.component';
import { AssetService } from 'src/app/services/asset-service.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { SortEvent, SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'ngx-location-assigned-asset-details',
  templateUrl: './location-assigned-asset-details.component.html',
  styleUrls: ['./location-assigned-asset-details.component.scss']
})
export class LocationAssignedAssetDetailsComponent implements OnInit {
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  loading: boolean = false;
  assetListData: any = [];
  providerKey: string;
  @Input() locationKey: string;
  searchText: string;

  constructor(
    public dialog: MatDialog,
    private assetService: AssetService,
    private utilityService: UtilityService,
    private toast: NbToastrService,
    private dialogService: NbDialogService, ) { }

  ngOnInit() {
    if (!!this.locationKey) {
      this.providerKey = this.locationKey;
    } else {
      this.providerKey = sessionStorage.getItem('providerKey');
    }

    this.getProviderVehicleDetails();
  }

  getProviderVehicleDetails() {
    const assetData = {
      providerKey: this.providerKey,
      deleted: false
    };
    this.loading = true;
    this.assetService.getProviderVehicleDetails(assetData).subscribe(result => {
      this.assetListData = result.body;
      this.onSort({ column: 'assetDescription', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  deleteProviderVehiclesDetails(assetData) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const data = {
          providerVehicleKey: assetData.providerVehicleKey,
        };

        this.loading = true;
        this.assetService.deleteProviderVehiclesDetails(data).subscribe(result => {
          if (result.body[0]) {
            this.assetService.getProviderVehicleAdditionalDetails({ providerVehicleKey: result.body[0].providerVehicleKey }).subscribe(result => {
              if (result.body[0]) {
                const deleteData = {
                  providerVehiclesAdditionalDetailKey: result.body[0].providerVehiclesAdditionalDetailKey,
                };
                this.assetService.deleteProviderVehiclesAdditionalDetails(deleteData).subscribe(result => {
                  this.loading = false;
                  if (result.success) {
                    this.toast.success(result.message[0], 'Success');
                  } else {
                    this.toast.warning(result.message[0], 'Error');
                  }
                  this.getProviderVehicleDetails();
                }, (error) => {
                  this.loading = false;
                });
              } else {
                this.loading = false;
              }
            }, (error) => {
              this.loading = false;
            });
          }
        }, (error) => {
          this.loading = false;
        });
      }
    });

  }

  openAssetsDialog(assetData, editStatus) {
    let dialogRef = this.dialog.open(AddEditAssetDialogComponent, {
      hasBackdrop:false,
      width: '85%',
      height: '85%',
      position: {
        top: '4%',
      },
      data: { assetData: assetData, editStatus: editStatus, providerKey: this.providerKey }
    });

    dialogRef.componentInstance.onAssetDataChange.subscribe(() => {
      this.getProviderVehicleDetails();
    });
  }


  onInput(val: string) {
    this.search.emit(this.searchText);
  }

  onSort({ column, direction }: SortEvent) {
    this.assetListData = this.utilityService.sortData(this.headers, column, direction, this.assetListData);
  }

}
