<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="container">
    <div class="row">
        <div class="col-12 col-md-6">
            <h6 *ngIf="!isCustomer">{{licensedOwnerName}} Invite Users</h6>
        </div>
        <div class="col-12 col-md-6 text-right">
            <app-instructions [dataDictionaryKey]="dataDictionaryKey" [schema]="schema"></app-instructions>
        </div>
    </div>
    <form [formGroup]="addProviderForm" class="p-3 mb-4 border" #FormDirective="ngForm">
        <div class="row">
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <input matInput formControlName="firstName" autocomplete="off" placeholder="First Name" required>
                    <mat-error *ngIf="handleError('firstName', 'required')">
                        First Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <input matInput formControlName="lastName" autocomplete="off" placeholder="Last Name" required>
                    <mat-error *ngIf="handleError('lastName', 'required')">
                        Last Name is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <input matInput formControlName="email" required (keyup)="checkEmailAlreadyExists($event)"
                        autocomplete="off" placeholder="Email">
                    <mat-error *ngIf="handleError('email', 'required')">
                        Email is required.
                    </mat-error>
                    <mat-error *ngIf="handleError('email', 'pattern')">
                        Enter valid Email.
                    </mat-error>
                    <mat-error *ngIf="handleError('email', 'isEmailExists')">
                        An Account already exists with this email. Please try another email to add user.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <input matInput formControlName="cellPhone" mask="000-000-0000" autocomplete="off"
                        placeholder="Cell Phone">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4" *ngIf="!isCustomer">
                <mat-form-field>
                    <mat-select formControlName="departmentKey" required placeholder="Department"
                        (selectionChange)="getUsersByDepartment($event)">
                        <mat-option disabled>Department</mat-option>
                        <mat-option *ngFor="let department of departmentList  | orderBy : 'departmentKey'"
                            [value]="department.departmentKey">
                            {{department.departmentKey}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('departmentKey', 'required')">
                        Department is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4" *ngIf="!isCustomer">
                <mat-form-field>
                    <mat-select formControlName="supervisor" placeholder="Supervisor">
                        <mat-option disabled>Supervisor</mat-option>
                        <mat-option *ngFor="let supervisor of usersList | orderBy : 'firstName'"
                            [value]="supervisor.userKey">
                            {{supervisor.lastName}} {{supervisor.firstName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('supervisor', 'required')">
                        Supervisor is required.
                    </mat-error>
                </mat-form-field>
                <div class="text-danger small"
                    *ngIf="addProviderForm.controls.supervisor.value && !addProviderForm.controls.departmentKey.value">
                    Please select Department before selecting Supervisor.
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="!isCustomer">
                <mat-form-field>
                    <mat-select formControlName="contactCenterUserRoleKey" required placeholder="Role">
                        <mat-option disabled>Select Role</mat-option>
                        <mat-option *ngFor="let role of rolesList | orderBy : 'contactCenterUserRoleKey'"
                            [value]="role.contactCenterUserRoleKey">
                            {{role.contactCenterUserRoleKey}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('contactCenterUserRoleKey', 'required')">
                        Role is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-select formControlName="securityGroupKey" required placeholder="Security Group">
                        <mat-option disabled>Security Group</mat-option>
                        <mat-option *ngFor="let group of securityGroups | orderBy : 'securityLevel'"
                            [value]="group.securityGroupKey">
                            {{group.description}} - {{group.securityLevel}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="handleError('securityGroupKey', 'required')">
                        Security Group is required.
                    </mat-error>
                </mat-form-field>
                <div class="text-danger small"
                    *ngIf="addProviderForm.controls.securityGroupKey.value && !addProviderForm.controls.departmentKey.value  && !isCustomer">
                    Please select Department before selecting User Security Group.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-right">
                <button class="btn btn-sm btn-success" (click)="addToInviteList(FormDirective)">Add to Invite
                    List</button>
            </div>
        </div>
    </form>
    <div class="table-responsive">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th sortable="firstName" (sort)="onSort($event)" class="align-middle" direction="asc">First Name
                    </th>
                    <th sortable="lastName" (sort)="onSort($event)" class="align-middle">Last Name</th>
                    <th sortable="email" (sort)="onSort($event)" class="align-middle">Email</th>
                    <th sortable="cellPhone" (sort)="onSort($event)" class="align-middle">Cell Phone</th>
                    <th sortable="departmentKey" (sort)="onSort($event)" class="align-middle" *ngIf="!isCustomer">
                        Department</th>
                    <th sortable="supervisor" (sort)="onSort($event)" class="align-middle" *ngIf="!isCustomer">
                        Supervisor</th>
                    <th sortable="contactCenterUserRoleKey" (sort)="onSort($event)" class="align-middle"
                        *ngIf="!isCustomer">Role</th>
                    <th sortable="description" (sort)="onSort($event)" class="align-middle">Security Group</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody *ngIf="userInvitesList.length > 0; else noData">
                <ng-container *ngFor="let user of userInvitesList let i = index">
                    <tr [ngClass]="{'custom-color': user.invalid}">
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.firstName}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.firstName" #firstName="ngModel" required
                                        placeholder="First Name">
                                    <mat-error *ngIf="handleError('firstName', 'required')">
                                        First Name is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.lastName}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.lastName" #lastName="ngModel" required
                                        placeholder="Last Name">
                                    <mat-error *ngIf="handleError('lastName', 'required')">
                                        Last Name is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.email}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.email" #email="ngModel" required
                                        [pattern]="emailPattern" (change)="checkEmailAlreadyExists($event, user)"
                                        placeholder="Email">
                                    <mat-error *ngIf="handleError('email', 'required')">
                                        Email is required.
                                    </mat-error>
                                    <mat-error *ngIf="handleError('email', 'pattern')">
                                        Enter valid Email.
                                    </mat-error>
                                    <div *ngIf="isEmailExists">
                                        <span class="text-danger">An Account already exists with this email. Please try
                                            another email to add user.</span>
                                    </div>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.cellPhone}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <input matInput [(ngModel)]="user.cellPhone" #cellPhone="ngModel" required
                                        mask="000-000-0000" placeholder="Cell Phone">
                                    <mat-error *ngIf="handleError('cellPhone', 'required')">
                                        Cell Phone number is required.
                                    </mat-error>
                                    <mat-error *ngIf="handleError('cellPhone', 'pattern')">
                                        Enter valid Cell Phone number.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td *ngIf="!isCustomer">
                            <span *ngIf="!(user.editMode)">
                                {{user.departmentKey}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.departmentKey" #departmentKey="ngModel" required
                                        placeholder="Department" (selectionChange)="getUsersByDepartment($event)">
                                        <mat-option disabled>Select Department</mat-option>
                                        <mat-option
                                            *ngFor="let department of departmentList  | orderBy : 'departmentKey'"
                                            [value]="department.departmentKey">
                                            {{department.departmentKey}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('departmentKey', 'required')">
                                        Department is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td *ngIf="!isCustomer">
                            <span *ngIf="!(user.editMode)">
                                {{user.supervisor}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.supervisorKey" #supervisor="ngModel"
                                        placeholder="Supervisor">
                                        <mat-option value=''>Select Supervisor</mat-option>
                                        <mat-option *ngFor="let supervisor of usersList | orderBy : 'lastName'"
                                            [value]="supervisor.userKey">
                                            {{supervisor.firstName}} {{supervisor.lastName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('supervisor', 'required')">
                                        Supervisor is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td *ngIf="!isCustomer">
                            <span *ngIf="!(user.editMode)">
                                {{user.contactCenterUserRoleKey}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.contactCenterUserRoleKey"
                                        #contactCenterUserRoleKey="ngModel" required placeholder="Role">
                                        <mat-option disabled>Select Role</mat-option>
                                        <mat-option
                                            *ngFor="let role of rolesList | orderBy : 'contactCenterUserRoleKey'"
                                            [value]="role.contactCenterUserRoleKey">
                                            {{role.contactCenterUserRoleKey}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('contactCenterUserRoleKey', 'required')">
                                        Role is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!(user.editMode)">
                                {{user.description}}
                            </span>
                            <span *ngIf="(user.editMode)">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="user.securityGroupKey" #securityGroupKey="ngModel" required
                                        placeholder="Security Group">
                                        <mat-option disabled>Security Group</mat-option>
                                        <mat-option *ngFor="let group of securityGroups"
                                            [value]="group.securityGroupKey">
                                            {{group.description}} - {{group.securityLevel}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="handleError('securityGroupKey', 'required')">
                                        Security Group is required.
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </td>
                        <td>
                            <button *ngIf="!(user.editMode)" class="btn btn-default btnAnimation" nbTooltip="Edit"
                                (click)="editInlineUserInvites(i)" [disabled]="disableEdit">
                                <i class="fas fa-pencil"></i>
                            </button>
                            <button *ngIf="!(user.editMode)" class="btn btn-default btnAnimation" nbTooltip="Delete"
                                (click)="deleteUserInviteDetails(user)" [disabled]="disableEdit">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button *ngIf="!(user.editMode)" class=" btn btn-default btnAnimation" nbTooltip="info"
                                (click)="openEmailDetails(user)">
                                <i class="fas fa-info-circle"></i>
                            </button>
                            <button *ngIf="user.editMode" class="btn btn-default btnAnimation" nbTooltip="Update"
                                (click)="updateUserInvitations(user)">
                                <i class="fas fa-check"></i>
                            </button>
                            <button *ngIf="user.editMode" class="btn btn-default btnAnimation"
                                (click)="cancelUpdateUserInvite(i)" nbTooltip="Cancel">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody>
                <ng-template #noData>
                    <tr>
                        <td *ngIf="!isCustomer" class="text-center" colspan="9">No data available.</td>
                        <td *ngIf="isCustomer" class="text-center" colspan="6">No data available.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>