import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ngx-smart-table';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AddLimitsComponent } from '../add-limits/add-limits.component';
import { UsersService } from 'src/app/services/users.service';
import { EditDirectReportsDialogComponent } from 'src/app/dialogs/edit-direct-reports-dialog/edit-direct-reports-dialog.component';

@Component({
  selector: 'app-edit-direct-reports',
  templateUrl: './edit-direct-reports.component.html',
  styleUrls: ['./edit-direct-reports.component.scss']
})
export class EditDirectReportsComponent implements OnInit {
  loading = false;
  searchString: string;
  settings: any;
  source: LocalDataSource;
  users: any;

  constructor(public dialogRef: MatDialogRef<AddLimitsComponent>,
              public dialog: MatDialog,
              private usersService: UsersService) { }

  ngOnInit() {
    this.settings = {
      actions: {
        delete: false,
        add: false,
        edit: false,
        select: true,
        custom: [
          {
            name: 'edit',
            title: '<i class="fas fa-pencil fa-lg" title="Edit"></i> ',
          }
        ],
      },
      pager: {
        display: true,
        perPage: 5,
      },
      columns: {
        lastName: {
          title: 'Last Name'
        },
        firstName: {
          title: 'First Name'
        },
        supervisor_Full_Name: {
          title: 'Supervisor'
        },
        title: {
          title: 'Job Title'
        },
        email: {
          title: 'Email'
        },
        cellPhone: {
          title: 'Cell Phone'
        },
        officePhone: {
          title: 'Office Phone'
        },
        departmentKey: {
          title: 'Department'
        },
        description: {
          title: 'Status'
        },
      }
    };

    this.getUsers();
  }

  getUsers() {
    this.loading = true;
    const userKey = sessionStorage.getItem('userKey');
    this.usersService.getDirectReports(userKey).subscribe(result => {
      this.users = result;
      this.source = new LocalDataSource(this.users);
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  onCustom(event) {
    this.openEditDirectReportDialog(event.data);
  }

  openEditDirectReportDialog(userDetails) {
    const dialogRef = this.dialog.open(EditDirectReportsDialogComponent, {
      width: '70%',
      disableClose: true,
      position: {
        top: '4%',
      },
      data: userDetails
    });

    dialogRef.afterClosed().subscribe(isValue => {
      this.getUsers();
  });
  }

  close() {
    this.dialogRef.close();
  }

}
