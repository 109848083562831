<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="row">
    <div class="offset-md-8 col-md-4 mt-2">
        <ngx-search-input (search)="search($event)"></ngx-search-input>
    </div>
    <div class="col-md-12 pl-4 pr-4">
        <div class="table-responsive divHeight mt-2">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th sortable="description" (sort)="onSort($event)" class="align-middle" direction="asc">Description</th>
                        <th sortable="userType" (sort)="onSort($event)" class="align-middle">User Type</th>
                        <th sortable="platform" (sort)="onSort($event)" class="align-middle">Platform</th>
                        <th sortable="createdBy" (sort)="onSort($event)" class="align-middle">Created By</th>
                        <th sortable="updatedBy" (sort)="onSort($event)" class="align-middle">Updated By</th>
                        <th sortable="updatedDate" (sort)="onSort($event)" class="align-middle">Updated Date</th>
                        <th sortable="deleted" (sort)="onSort($event)" class="align-middle">Deleted</th>
                        <th sortable="securityLevel" (sort)="onSort($event)" class="align-middle">Security Level</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody *ngIf="serviceGroupData; else noData">
                    <tr *ngFor="let data of serviceGroupData | filter : searchString;">
                        <td>{{data.description}}</td>
                        <td>{{data.userType}}</td>
                        <td>{{data.platform}}</td>
                        <td>{{data.createdBy}}</td>
                        <td>{{data.updatedBy}}</td>
                        <td>{{data.updatedDate | date:'dd MMM yyyy'}}</td>
                        <td>{{data.deleted ? 'Yes' : 'No'}}</td>
                        <td>{{data.securityLevel}}</td>
                        <td>
                            <button class="btn btn-sm btn-default btn-sm btnAnimation"
                                (click)='editServiceGroup(data, "edit")' nbTooltip="Edit">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td colspan="9" class="text-center">
                                No Data available
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </div>
</div>