<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<nb-card>
    <nb-card-body>
        <div class="row noMargin">
            <div class="col-md-6 mt-2 form-group has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search"
                    (input)="onInput($event)">
            </div>

            <div class="col-md-6 text-right">
                <button class="btn btn-orange btn-sm mt-2" (click)="openAssetsDialog(0,0)">Add Asset</button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="table-responsive mt-2">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th sortable="assetDescription" (sort)="onSort($event)" direction="asc">Description</th>
                            <th sortable="year" (sort)="onSort($event)" direction="asc">Year</th>
                            <th sortable="makeKey" (sort)="onSort($event)" direction="asc">Make</th>
                            <th sortable="model" (sort)="onSort($event)" direction="asc">Model</th>
                            <th sortable="vin" (sort)="onSort($event)" direction="asc">VIN</th>
                            <th sortable="vehicleStatusKey" (sort)="onSort($event)" direction="asc">Status</th>
                            <th sortable="license" (sort)="onSort($event)" direction="asc">License</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let asset of assetListData | filter : searchText;  let i = index;">
                            <td>{{asset.assetDescription}}</td>
                            <td>{{asset.year}}</td>
                            <td>{{asset.makeKey}}</td>
                            <td>{{asset.model}}</td>
                            <td>{{asset.vin}}</td>
                            <td>{{asset.vehicleStatusKey}}</td>
                            <td>{{asset.license}}</td>
                            <td>
                                <button class="btn btn-default btn-sm btnAnimation mr-1" nbTooltip="Edit"
                                    (click)="openAssetsDialog(asset,1)">
                                    <i class="fas fa-pencil"></i>
                                </button>
                                <button class="btn btn-default btn-sm btnAnimation ml-2" nbTooltip="Delete"
                                    (click)="deleteProviderVehiclesDetails(asset)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </nb-card-body>
</nb-card>