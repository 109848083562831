import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubbleTreeUiComponent } from './bubble-tree-ui/bubble-tree-ui.component';
import { DndModule } from 'ngx-drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { SortableHeaderDirective } from './directive/sortable.directive';
import { BubbleTreeSecurityGroupComponent } from './bubble-tree-security-group/bubble-tree-security-group.component';
import { OrderByPipe } from './pipe/order-by.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { NbTooltipModule } from '@nebular/theme';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NbCardModule } from '@nebular/theme';
import { CheckboxTreeUIComponent } from './checkbox-tree-ui/checkbox-tree-ui.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BubbleTreeProviderComponent } from './bubble-tree-provider/bubble-tree-provider.component';
import { BubbleUserInformationIndexComponent } from './bubble-tree-ui/bubble-user-information-index/bubble-user-information-index.component';
import { BubbleRatesInformationComponent } from './bubble-tree-ui/bubble-rates-information/bubble-rates-information.component';
import { ThemeModule } from '../@theme/theme.module';



const NB_MODULES = [
  NbCardModule,
];

const MAT_MODULES = [
  MatCheckboxModule,
  MatMenuModule,
  MatIconModule,
];

@NgModule({
  declarations: [
    BubbleTreeUiComponent,
    BubbleTreeProviderComponent,
    SortableHeaderDirective,
    OrderByPipe,
    FilterPipe,
    BubbleTreeSecurityGroupComponent,
    PageNotFoundComponent,
    CheckboxTreeUIComponent,
    BubbleUserInformationIndexComponent,
    BubbleRatesInformationComponent,
  ],
  imports: [
    CommonModule,
    ThemeModule,
    DndModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    NbTooltipModule,
    ...MAT_MODULES,
    ...NB_MODULES,

  ],
  exports: [
    BubbleTreeUiComponent,
    OrderByPipe,
    BubbleTreeSecurityGroupComponent,
    FilterPipe,
    ...MAT_MODULES,
    ...NB_MODULES,
    BubbleTreeUiComponent,
    PageNotFoundComponent,
    CheckboxTreeUIComponent,
    BubbleTreeProviderComponent,
    BubbleUserInformationIndexComponent,
    BubbleRatesInformationComponent,
  ],
})

export class ShareModuleModule { }
