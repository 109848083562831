import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BubbleService } from 'src/app/services/bubble-service.service';
import { SortEvent, SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'ngx-view-service-exclusion-list',
  templateUrl: './view-service-exclusion-list.component.html',
  styleUrls: ['./view-service-exclusion-list.component.scss']
})
export class ViewServiceExclusionListComponent implements OnInit {

  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  constructor(
    public dialog: MatDialogRef<ViewServiceExclusionListComponent>,
    public formBuilder: UntypedFormBuilder,
    public bubbleService: BubbleService,
    public utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  serviceExclusionDetailForm: UntypedFormGroup;
  loading: boolean = false;
  serviceDetails: any;
  subServiceDetails: any;
  licensedOwnerOffering: any;
  discountServiceExceptions = [];
  isEdit: false;

  ngOnInit() {
    this.serviceExclusionDetailForm = this.formBuilder.group({
      service: [''],
      subService: [''],
    });
    this.getServiceExceptions();
    this.getServices();
  }

  getServices() {

    let data = {
      "parentKey": "NULL",
      "deleted": false
    };

    this.loading = true;
    this.bubbleService.getLicensedOwnerOfferings(data).subscribe((result: any) => {
      this.serviceDetails = result.body;
      this.loading = false;
    }, (error) => {
      console.log('error ' + error);
      this.loading = false;
    });
  }

  getSubServices($event) {

    const service = this.serviceExclusionDetailForm.controls.service.value;
    this.licensedOwnerOffering = this.serviceDetails.find(item => item.licensedOwnerOfferingKey === service);

    if (this.licensedOwnerOffering === undefined) {
      return false;
    }

    this.loading = true;
    const filter = {
      parentKey: this.licensedOwnerOffering.networkOwnerOfferingKey,
      deleted: false
    };

    this.bubbleService.getLicensedOwnerOfferings(filter).subscribe(subServiceResponse => {
      this.subServiceDetails = subServiceResponse.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

  }

  closeDialog() {
    this.dialog.close();
  }

  getServiceExceptions() {
    this.loading = true;
    this.bubbleService.getServiceExceptions(this.data.licensedOwnerKey).subscribe(res => {
      this.discountServiceExceptions = res.body;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.discountServiceExceptions = this.utilityService.sortData(this.headers, column, direction, this.discountServiceExceptions);
  }

}
