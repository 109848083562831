import { ServicerateComponent } from './service_rate/servicerate.component';
import { DiscountsetupComponent } from './discountsetup/discountsetup.component';
import { DiscountProviderViewComponent } from './discountproviderview/discountproviderview.component';
import { OfferingsComponent } from './offerings/offerings.component';
import { SystemSecurityMenuItemsComponent } from './system-security-menu-items/system-security-menu-items.component';
import { PagesComponent } from './pages.component';
import { PageNotFoundComponent } from '../share-module/page-not-found/page-not-found.component';
import { NagSetupMaintenanceComponent } from './nag-setup-maintenance/nag-setup-maintenance.component';
import { ProviderAdminComponent } from './provider-admin/provider-admin.component';
import { ProviderMigrationComponent } from './provider-migration/provider-migration.component';
import { ProviderSearchComponent } from './provider-search/provider-search.component';

export const pagesComponents = {
    'ServicerateComponent': ServicerateComponent,
    'DiscountsetupComponent': DiscountsetupComponent,
    'DiscountProviderViewComponent': DiscountProviderViewComponent,
    'OfferingsComponent': OfferingsComponent,
    'SystemSecurityMenuItemsComponent': SystemSecurityMenuItemsComponent,
    'NagSetupMaintenanceComponent':NagSetupMaintenanceComponent,
    'PageNotFoundComponent': PageNotFoundComponent,
    'ProviderAdminComponent': ProviderAdminComponent,
    'ProviderMigrationComponent' : ProviderMigrationComponent,
    'ProviderSearchComponent' : ProviderSearchComponent,
    'PagesComponent': PagesComponent,
};