import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { Address } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { CommonService } from 'src/app/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NbToastrService } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { SetupService } from 'src/app/services/setup.service';
import { forkJoin } from 'rxjs';
import { ProviderAccountService } from 'src/app/services/provider-account.service';

@Component({
  selector: 'ngx-provider-account-details',
  templateUrl: './provider-account-details.component.html',
  styleUrls: ['./provider-account-details.component.scss']
})
export class ProviderAccountDetailsComponentNew implements OnInit {

  @Input() providerAccount;
  @Input() isParent;
  accountDetailForm: UntypedFormGroup;
  userStatusDetails: any;
  loading: boolean;
  rating = [];
  isEdit: boolean = false;
  showDiscontinuedReasonFlag: boolean = false;
  showNotesReasonFlag: boolean = false;
  @Output() saveCompleted = new EventEmitter<string>()
  enteredBy: any;
  updatedDate: any;
  actStatus: any;
  statusKey: any;
  contactCenterTeamDetails: any;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public commonService: CommonService,
    public utilityService: UtilityService,
    public providerAccountService: ProviderAccountService,
    private userService: UserService,
    private toastrService: NbToastrService,
    private setupService: SetupService,
  ) { }

  ngOnInit() {
    this.accountDetailForm = this.formBuilder.group({
      accountName: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      address1: ['', [Validators.required, Validators.pattern(patterns.textPattern)]],
      address2: ['', [Validators.pattern(patterns.textPattern)]],
      address3: ['', [Validators.pattern(patterns.textPattern)]],
      city: ['', Validators.required],
      stateKey: ['', Validators.required],
      postalCode: ['', [Validators.required, Validators.pattern(patterns.alphanumericZipCodePattern)]],
      dispatchEmail: ['', Validators.email],
      dispatchPhone: [''],
      primaryContactPhone: [''],
      defaultRetailAccount: [{ value: false, disabled: !this.isParent }],
      afterHoursRatesAllowed: [false],
      autoDispatch: [false],
      qualityRating: [''],
      accountStatus: ['Active', Validators.required],
      providerDiscontinuedReason: [''],
      activeNotes: [''],
    });
    // location user cannot edit default location and it will be always disabled
    if (!this.isParent) {
      this.checkDefaultAccounts();
    }
    this.getProviderAccountStatus();
    this.setRatingDropDown(100);

    if (this.providerAccount) {
      this.isEdit = true;
      this.accountDetailForm.controls.qualityRating.disable();
      if (this.providerAccount.licensedOwnerKey === '') {
        this.getProviderAccountDetails();
      } else {
        this.getLicensedOwnerDetails();
      }

      if (this.providerAccount.licensedOwnerKey !== '') {
        this.accountDetailForm.controls.accountName.disable();
        this.accountDetailForm.controls.address1.disable();
        this.accountDetailForm.controls.address2.disable();
        this.accountDetailForm.controls.address3.disable();
        this.accountDetailForm.controls.city.disable();
        this.accountDetailForm.controls.stateKey.disable();
        this.accountDetailForm.controls.postalCode.disable();
        this.accountDetailForm.controls.primaryContactPhone.disable();
        this.accountDetailForm.controls.afterHoursRatesAllowed.disable();
        this.accountDetailForm.controls.autoDispatch.disable();
        this.accountDetailForm.controls.qualityRating.disable();
      }
    } else {
      this.accountDetailForm.controls.accountStatus.disable();
    }
  }

  checkDefaultAccounts() {
    const data = {
      'providerKey': this.providerAccount ? this.providerAccount.providerKey : sessionStorage.getItem('providerKey'),
      'defaultRetailAccount': true,
      'deleted': false,
    };

    this.setupService.getProviderDefaultAccountKey(data).subscribe((res: any) => {
      if (res.body.length > 0) {
        this.accountDetailForm.controls.defaultRetailAccount.disable();
      }
    }, (error) => {
      this.loading = false;
      console.info(error);
    });
  }

  getLicensedOwnerDetails() {
    const filter = {
      'licensedOwnerKey': this.providerAccount.licensedOwnerKey,
      deleted: false,
      contactCenterTeamKey: 'Operations'
    }
    const licensedOwnerDataReq = { 'licensedOwnerKey': this.providerAccount.licensedOwnerKey }
    const providerAccountDataReq = { 'providerAccountKey': this.providerAccount.providerAccountKey }
    const providerAccountData = this.setupService.getProviderDefaultAccountKey(providerAccountDataReq);
    const licensedOwnerData = this.setupService.getLicensedOwnerDetails(licensedOwnerDataReq);
    const contactCenterTeamDetailsthis = this.providerAccountService.getLicensedOwnerContactCenterTeamDetails(filter);

    this.loading = true;
    forkJoin([providerAccountData, licensedOwnerData, contactCenterTeamDetailsthis]).subscribe(result => {
      this.loading = false;
     this.contactCenterTeamDetails = result[2].body[0];
      this.setAccountDetailForm(result[0].body[0], result[1].body[0]);
    });
  }

  getProviderAccountDetails() {
    const data = {
      'providerAccountKey': this.providerAccount.providerAccountKey,
    }
    this.loading = true;
    this.setupService.getProviderDefaultAccountKey(data).subscribe((res: any) => {
      this.loading = false;
      this.setAccountDetailForm(res.body[0], 0);
    }, (error) => {
      this.loading = false;
      console.info(error);
    });
  }

  setAccountDetailForm(providerDetails: any, loDetails: any) {
    this.accountDetailForm.setValue({
      accountName: loDetails === 0 ? providerDetails.accountName : loDetails.licensedOwnerName,
      address1: loDetails === 0 ? providerDetails.address1 : loDetails.mailingAddress,
      address2: loDetails === 0 ? providerDetails.address2 : '',
      address3: loDetails === 0 ? providerDetails.address3 : '',
      city: loDetails === 0 ? providerDetails.city : loDetails.mailingCity,
      stateKey: loDetails === 0 ? providerDetails.stateKey : loDetails.mailingStateKey,
      postalCode: loDetails === 0 ? providerDetails.postalCode : loDetails.mailingZipCode,
      dispatchEmail: loDetails === 0 ? providerDetails.dispatchEmail : providerDetails.dispatchEmail !== '' ? providerDetails.dispatchEmail : this.contactCenterTeamDetails.teamEmail,
      dispatchPhone: loDetails === 0 ? providerDetails.dispatchPhone : providerDetails.dispatchPhone !== '' ? providerDetails.dispatchPhone : this.contactCenterTeamDetails.teamPhone,
      primaryContactPhone: loDetails === 0 ? providerDetails.primaryContactPhone : loDetails.phoneNumber,
      defaultRetailAccount: providerDetails.defaultRetailAccount,
      afterHoursRatesAllowed: providerDetails.afterHoursRatesAllowed,
      autoDispatch: providerDetails.autoDispatch,
      qualityRating: providerDetails.qualityRating,
      accountStatus: providerDetails.accountStatus,
      providerDiscontinuedReason: providerDetails.providerDiscontinuedReason,
      activeNotes: '',
    });


    if (providerDetails.defaultRetailAccount && this.isParent) {
      this.accountDetailForm.controls.defaultRetailAccount.enable();
    }

    if (this.accountDetailForm.controls.accountStatus.value === 'Do Not Use') {
      this.accountDetailForm.controls.accountStatus.disable();
      this.userStatusDetails = this.userStatusDetails.map(status => {
        status.isSelected = true;
        return status;
      });
    }

    this.actStatus = this.accountDetailForm.controls.accountStatus.value;
  }

  showDiscontinuedReason() {
    if (this.accountDetailForm.get('accountStatus').value === 'InActive') {
      this.showDiscontinuedReasonFlag = true;
      this.showNotesReasonFlag = false;
      this.accountDetailForm.get('providerDiscontinuedReason').setValidators(Validators.required);
      this.accountDetailForm.get('providerDiscontinuedReason').updateValueAndValidity();
      this.accountDetailForm.get('activeNotes').clearValidators();
      this.accountDetailForm.get('activeNotes').updateValueAndValidity();
    } else {
      this.showDiscontinuedReasonFlag = false;
      this.showNotesReasonFlag = true;
      this.accountDetailForm.get('providerDiscontinuedReason').clearValidators();
      this.accountDetailForm.get('providerDiscontinuedReason').updateValueAndValidity();
    }
  }

  getProviderAccountStatus() {
    const data = {
      deleted: false,
    }
    this.commonService.getProviderAccountStatuses(data).subscribe(response => {
      this.userStatusDetails = response.body.map(status => {
        Object.assign(status, { isSelected: true });
        if (status.statusKey === 'Do Not Use') {
          status.isSelected = false;
        }
        return status;
      });
    }, (error) => {
      console.info(error);
    });
  }

  handleError = (control: string, error: string) => {
    return this.accountDetailForm.controls[control].hasError(error);
  }

  setRatingDropDown(value) {
    for (let i = 1; i <= value; i++) {
      this.rating.push(i);
    }
  }

  // get detailed address on change
  public getDetailedAddress(address: Address) {
    let streetNumber = '';
    address.address_components.forEach(element => {
      element.types.forEach(data => {
        switch (data) {
          case 'neighborhood':
            this.accountDetailForm.controls.address2.setValue(element.long_name);
            break;

          case 'administrative_area_level_2':
            this.accountDetailForm.controls.address3.setValue(element.long_name);
            break;
          case 'administrative_area_level_1':
            this.accountDetailForm.controls.stateKey.setValue(element.long_name);
            break;
          case 'postal_code':
            this.accountDetailForm.controls.postalCode.setValue(element.long_name);
            break;
          case 'locality':
            this.accountDetailForm.controls.city.setValue(element.long_name);
            break;
        }
      });
    });
    this.accountDetailForm.controls.address1.setValue(address.name);
  }

  addProviderAccount() {
    if (this.accountDetailForm.invalid) {
      this.utilityService.validateAllFormFields(this.accountDetailForm);
      this.saveCompleted.emit('false');
      return false;
    }

    const insertData = {
      providerKey: sessionStorage.getItem('providerKey'),
      insertedUserKey: sessionStorage.getItem('userKey'),
      licensedOwnerKey: '',
      deleted: false,
      updatedUserKey: ''
    };

    this.loading = true;
    const data = Object.assign(this.accountDetailForm.value, insertData);
    data.defaultRetailAccount = this.accountDetailForm.controls.defaultRetailAccount.value;
    data.accountStatus = this.accountDetailForm.controls.accountStatus.value;
    this.providerAccountService.addDefaultProvideraccounts(data).subscribe((res: any) => {
      if (res.success) {
        this.toastrService.success(res.message[0], 'Success');
        this.saveCompleted.emit('true');
      } else {
        this.toastrService.danger(res.message[0], 'Error');
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });

  }

  updateProviderAccount() {

    this.statusKey = this.accountDetailForm.controls.accountStatus.value;
    if (this.accountDetailForm.invalid) {
      this.accountDetailForm.markAllAsTouched();
      this.saveCompleted.emit('false');
      return false;
    }

    if (this.actStatus !== this.accountDetailForm.controls.accountStatus.value) {

      const status = this.accountDetailForm.controls.accountStatus.value;

      let note = '';
      if (this.statusKey === 'InActive') {
        note = 'The ' + this.providerAccount.accountName + '  InActive' + ' ' + this.accountDetailForm.controls.providerDiscontinuedReason.value;
      } else {
        note = 'The ' + this.providerAccount.accountName + ' status has been moved to ' + status;
      }

      const notesData = {
        note: note,
        privateForAccount: false,
        privateForProvider: false,
        providerAccountKey: this.providerAccount.providerAccountKey,
        insertedUserKey: sessionStorage.getItem('userKey'),
        updatedUserKey: '',
        systemDefault: false,
        deleted: false,
      }

      this.providerAccountService.addProviderAccountNotes(notesData).subscribe(() => {
        this.loading = false;
        this.showNotesReasonFlag = false;
        this.accountDetailForm.controls.activeNotes.setValue('');
      }, (error) => {
        this.loading = false;
      });
    }

    const insertData = {
      providerAccountKey: this.providerAccount.providerAccountKey,
      updatedUserKey: sessionStorage.getItem('userKey'),
    };

    this.loading = true;
    const data = Object.assign(this.accountDetailForm.value, insertData);
    this.providerAccountService.updateProviderAccounts(data).subscribe((res: any) => {
      this.loading = false;
      this.toastrService.success(res.message[0], 'Success');
      this.saveCompleted.emit('true');
    }, (error) => {
      this.loading = false;
      this.toastrService.danger('Failed to save', 'Failed');
    });
  }

}
