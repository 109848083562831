import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {

  header = 'Confirm Delete';
  message = 'Are you sure you want to delete?';

  constructor(protected ref: NbDialogRef<ConfirmDeleteComponent>) { }

  ngOnInit() {
  }
  submit(val) {
    this.ref.close(val);
  }
}
