<div class="modal-header">
    <h6 class="modal-title">Add Account</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <ngx-provider-account-details (saveCompleted)='saveCompleted($event)'></ngx-provider-account-details>
</div>
<div class="text-right mt-2" *ngIf="!isEdit">
    <button mat-button class="btn btn-orange btn-sm" (click)="addProviderAccount()">Save & Next</button>
</div>