import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditNagDialogComponent } from '../add-edit-nag-dialog/add-edit-nag-dialog.component';
import { UsersService } from '../provider-match/services/users.service';


@Component({
  selector: 'ngx-nag-setup-maintenance',
  templateUrl: './nag-setup-maintenance.component.html',
  styleUrls: ['./nag-setup-maintenance.component.scss']
})
export class NagSetupMaintenanceComponent implements OnInit {

  loading: boolean = false;
  nagDirectories: any = []

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this.getProviderDetails();
  }

  getProviderDetails() {
    this.loading = true;
    this.usersService.getProviderDetails().subscribe(response => {
      this.nagDirectories = response?.body || [];
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  addEditNag(isEdit: boolean, item: any = {}) {
    this.dialog.open(AddEditNagDialogComponent, {
      width: '80%',
      height: '80%',
      position: {
        top: '5%'
      },
      data: { isEdit: isEdit, ...item },
    });
  }

}
