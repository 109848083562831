import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { SortEvent, SortableHeaderDirective } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { CustomerService } from 'src/app/services/customer.service';
import { UserExperianceService } from 'src/app/services/user-experiance.service';

@Component({
  selector: 'app-security-groups',
  templateUrl: './security-groups.component.html',
  styleUrls: ['./security-groups.component.scss']
})
export class SecurityGroupsComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  @Input() userDetails: any;
  userSecurityGroupForm: UntypedFormGroup;
  loading: boolean;
  userSecurityGroups: any = [];
  securityGroups: any = [];
  searchString: string;
  userTypeKey: string;
  userKey: string;
  isAddRow: boolean;
  disableEdit: boolean;
  tempPricing: any = [];
  loggedInuserSecurityGroups: any = [];
  securityLevel: any;
  userDepartment: string;
  readOnlyUser = false;
  tempUserGroup: any;
  editFlag = false;
  platForm = 'FleetAssist';
  @Input() isCustomerUser = false;
  @Input() isProviderUser = false;

  constructor(
    private usersService: UsersService,
    private utilityService: UtilityService,
    private toast: NbToastrService,
    private formBuilder: UntypedFormBuilder,
    private dialogService: NbDialogService,
    private customerService: CustomerService,
    private userExperianceService: UserExperianceService) { }

  ngOnInit() {
    this.isAddRow = false;
    this.userTypeKey = this.isCustomerUser ? '90dc6b51-9731-439b-bd21-40685f989f24' : '9c4ef5b4-cadb-400b-adf4-05b33798f1fd';
    this.readOnlyUser = this.userDetails.readOnlyUser || false;
    this.userDepartment = sessionStorage.getItem('departmentKey');
    this.userKey = this.userDetails.userKey;
    this.userSecurityGroupForm = this.formBuilder.group({
      securityGroupKey: [''],
      securityLevel: [{ value: '', disabled: true }]
    });

    if (this.isProviderUser && this.isCustomerUser) {
      this.userTypeKey = 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4';
      this.platForm = 'ServicePlus';
    }

    if (this.userDetails.readOnlyUser) {
      this.userSecurityGroupForm.disable();
      this.disableEdit = this.userDetails.readOnlyUser;
    }
    this.getUserSecurityGroups();
    this.getLoggedinUserSecurityGroups();
  }

  /****
   * @Desc: Get User Security Groups
   * @params objParams
   * @result array response of All user security Groups
   */
  getUserSecurityGroups() {

    this.loading = true;
    const objParams = {
      userKey: this.userKey,
      platform: this.platForm
    };
    this.usersService.getUserSecurityGroups(objParams).subscribe(res => {
      this.userSecurityGroups = res.body;
      if (this.userSecurityGroups.length > 0) {
        this.readOnlyUser = true;
      }
      this.onSort({ column: 'description', direction: 'asc' });
      this.loading = false;
    }, (error => {
      console.log(error);
    }));
  }

  getLoggedinUserSecurityGroups() {

    const objParams = {
      userKey: sessionStorage.getItem('userKey'),
      platform: 'FleetAssist'
    };
    this.usersService.getUserSecurityGroups(objParams).subscribe(res => {
      this.loggedInuserSecurityGroups = res.body;
      this.securityLevel = res.body[0].securityLevel;
      this.getSecurityGroups();
    }, (error => {
      console.log(error);
    }));
  }

  /****
   * @Desc: Get Security Groups
   * @params objParams
   * @result array response of All security Groups
   */
  getSecurityGroups() {

    const objParams = {
      userTypeKey: this.userTypeKey,
      platform: this.platForm,
      deleted: false,
    };
    this.usersService.getSecurityGroupByPlatform(objParams).subscribe(res => {
      const tempData = [];
      if (this.isCustomerUser) {
        this.securityGroups = res.body;
      } else {
        if (this.userDepartment === 'HR') {
          this.securityGroups = res.body;
        } else {
          res.body.map((data) => {
            if (data.securityLevel <= this.securityLevel) {
              tempData.push(data);
            }
          });
          this.securityGroups = tempData;
        }
      }
      this.onSort({ column: 'description', direction: 'asc' });
    }, (error => {
      console.log(error);
    }));
  }

  addNewSecurityGroup() {
    this.isAddRow = true;
    this.userSecurityGroups.map(obj => obj.editMode = false);
  }

  addNewSecurityGroupDetails() {

    if (this.userSecurityGroupForm.invalid) {
      this.userSecurityGroupForm.markAllAsTouched();
      return false;
    }
    const insertdata = {
      userKey: this.userKey,
      securityGroupKey: this.securityGroups.filter(item => item.description === this.userSecurityGroupForm.controls.securityGroupKey.value)[0].securityGroupKey,
      insertedUserKey: sessionStorage.getItem('userKey'),
      insertedTimestamp: new Date().getTime(),
      updatedTimestamp: new Date().getTime(),
      systemDefault: true,
      deleted: false,
    };

    this.loading = true;
    this.usersService.addUserSecurityGroup(insertdata).subscribe((res: any) => {
      this.cancelAddSecurityGroup();
      if (res.success) {
        this.toast.success('User Security Group added successfully!', 'Success');
      } else {
        this.toast.warning(res.message[0], 'Error');
      }
      this.getUserSecurityGroups();
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  cancelAddSecurityGroup() {
    this.isAddRow = false;
    this.userSecurityGroupForm.reset();
  }

  deleteUserSecurityGroup(data) {

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        this.loading = true;
        const postParams = {
          userSecurityGroupKey: data.userSecurityGroupKey,
          securityGroupKey: data.securityGroupKey,
          updatedUserKey: sessionStorage.getItem('userKey'),
          userKey: data.userKey,
          deleted: true,
        };
        this.usersService.updateUserGroups(postParams).subscribe(res => {
          if (res.success) {
            this.toast.success('User Group deleted successfully!', 'Success');
            this.getUserSecurityGroups();
          } else {
            this.toast.warning(res.message[0], 'Error');
          }
          this.loading = false;
        });
      }
    });
  }

  getSelectedSecurityLevel(event: any) {
    this.securityGroups.map((data) => {
      if (event.value === data.description) {
        this.userSecurityGroupForm.controls.securityLevel.setValue(data.securityLevel);
      }
    });
  }

  updateUnsavedDetails() {
    if (this.isAddRow) {
      this.addNewSecurityGroupDetails();
    } else {
      this.updateSecurityGroup(this.tempUserGroup);
    }
  }

  canDeactivate(): boolean {
    return (this.userSecurityGroupForm.dirty && this.userSecurityGroupForm.touched) && (!!this.isAddRow || !!this.editFlag);
  }

  refresh() {
    this.ngOnInit();
  }

  search(event) {
    this.searchString = event;
  }

  /****
  * Method to update user group
  */
  updateSecurityGroup(securityGroup) {
    this.loading = true;
    const data = {
      userSecurityGroupKey: securityGroup.userSecurityGroupKey,
      securityGroupKey: this.securityGroups.filter(item => item.description === this.userSecurityGroupForm.controls.securityGroupKey.value)[0].securityGroupKey
    };

    this.usersService.updateUserGroups(data).subscribe(res => {
      if (res.success) {
        this.toast.success('User Group updated successfully!', 'Success');
        this.getUserSecurityGroups();
        this.editFlag = false;
        if (!this.userDetails.isApprover && !this.isProviderUser) {
          this.checkDefaultSecurityGroup(data);
        }
      } else {
        this.toast.warning(res.message[0], 'Error');
      }
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  /****
   * Method to set isApprover = true if systemDefault = 1
   */
  checkDefaultSecurityGroup(data) {
    const filter = {
      systemDefault: true,
      securityGroupKey: data.securityGroupKey
    };

    this.usersService.getSecurityGroupByPlatform(filter).subscribe(res => {
      if (res.body.length > 0) {
        this.setUserApprover();
      } else if (this.userDetails.parentKey) {
        const customerFilter = {
          customerKey: this.userDetails.parentKey,
          deleted: false
        };

        this.customerService.getCustomerDetails(customerFilter).subscribe(customerRes => {
          const customer = customerRes.body[0];
          const authFilter = {
            securityGroupKey: data.securityGroupKey,
            customerTierStructureKey: customer.customerTierStructureKey
          };
          this.checkThresholdInParentCustomer(authFilter);
        });
      }
    });
  }

  /****
   * Method to set isApprover = true
   */
  setUserApprover() {
    const data = {
      isApprover: true,
      userExperianceKey: this.userDetails.userExperianceKey,
    };

    this.userExperianceService.updateUserExperiances(data).subscribe(res => { });
  }

  /****
   * Method to set isApprover = true when the parent customer has a record for the
   * SecurityGroupKey and CustomerTierStructureKey in AuthorizationThresholds
   */
  checkThresholdInParentCustomer(data) {
    const filter = {
      associationKey: this.userDetails.parentKey,
      securityGroupKey: data.securityGroupKey,
      customerTierStructureKey: data.customerTierStructureKey
    };

    this.usersService.getAuthorizationThresholds(filter).subscribe(res => {
      if (res.body.length > 0) {
        const thresholds = res.body.find(item => {
          if (item.userKey) {
            return item;
          }
        });

        if (thresholds) {
          this.setUserApprover();
        }
      }
    });
  }

  editSecurityGroup(index) {
    this.tempUserGroup = { ...{}, ...this.userSecurityGroups[index] };
    this.userSecurityGroups[index].editMode = !this.userSecurityGroups[index].editMode;
    this.editFlag = true;
  }

  cancelSecurityGroup(index) {
    this.userSecurityGroups[index].editMode = !this.userSecurityGroups[index].editMode;
    this.userSecurityGroups[index] = this.tempUserGroup;
    this.editFlag = false;
  }

  onSort({ column, direction }: SortEvent) {
    this.userSecurityGroups = this.utilityService.sortData(this.headers, column, direction, this.userSecurityGroups);
  }

  handleError = (control: string, error: string) => {
    return this.userSecurityGroupForm.controls[control].hasError(error);
  }


}
