import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTableModule, MatTable } from '@angular/material/table';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';

@Component({
  selector: 'ngx-location-details-list',
  standalone: true,
  imports: [ 
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
  ],
  templateUrl: './location-details-list.component.html',
  styleUrls: ['./location-details-list.component.scss']
})
export class LocationDetailsListComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort , { static: true }) sort: MatSort;
  dataSource: any = [];
  displayedColumns: string[] = ['accountName',];

  ngOnInit(){
    console.log(this.data);
    this.dataSource = this.data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort
  }

}
