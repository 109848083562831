<div *ngIf="loading" id="preloader">
        <div id="loader"></div>
    </div>
    <div class="modal-header">
        <h6 class="modal-title">{{operation}}</h6>
        <button type="button" class="close" (click)="closeDialog()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="row noMargin">
            <div class="col-md-10 mt-3">
                <div class="row">
                    <mat-form-field class="col-md-6">
                        <input matInput placeholder="Name" [(ngModel)]="name" required>
                    </mat-form-field>
                </div>
                <div class="row iconBox icon-selector mt-2 border border-secondary rounded-lg">
                    <div class="col-md-1" *ngFor="let item of iconJson; ">
                        <input [(ngModel)]="icon" [attr.id]="item.class" type="radio"
                            [value]="item.class" name="Icons" />
                        <label nbTooltip="{{item.description}}" nbTooltipPlacement="top" [attr.for]="item.class"
                            class="ic m-1"><i [attr.class]="item.class"></i></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-sm mr-2" type="submit" (click)="addEditProcess()">Save</button>
        <button class="btn btn-primary btn-sm" type="button" (click)="closeDialog()">Cancel</button>
    </div>