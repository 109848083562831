<div class="row">
    <div class="col-md-12">
        <div class="row rectangle custom-font">
            <div class="col-md-2 pr-1 pl-1">
                <i class="far fa-rectangle-landscape fa-3x green-rect"></i>
                <span class="rect-text"> Offered - Rates Approved</span>
            </div>
            <div class="col-md-2 pr-1 pl-1">
                <i class="far fa-rectangle-landscape fa-3x yellow-rect"></i>
                <span class="rect-text"> Rates Not Approved</span>
            </div>
            <div class="col-md-2 pr-1 pl-1">
                <i class="far fa-rectangle-landscape fa-3x blue-rect"></i>
                <span class="rect-text"> Selected at Corp</span>
            </div>
            <div class="col-md-2 pr-1 pl-1">
                <i class="far fa-rectangle-landscape fa-3x red-rect"></i>
                <span class="rect-text"> Not Offered</span>
            </div>
            <div class="col-md-2 pr-1 pl-1">
                <i class="far fa-rectangle-landscape fa-3x gray-rect"></i>
                <span class="rect-text"> Restricted</span>
            </div>
        </div>
    </div>
</div>