import { Deserializable } from "./deserializable.model";

export class BreakdownProvider implements Deserializable {

    acceptsAmEx: boolean;
    acceptsBusinessChecks: boolean;
    acceptsComCheck: boolean;
    acceptsDiscover: boolean;
    acceptsMasterCard: boolean;
    acceptsPersonalChecks: boolean;
    acceptsTCheck: boolean;
    acceptsVisa: boolean;
    address1: string;
    address2: string;
    address3: string;
    allLocationsSameHours: boolean;
    axlePointADDlAddressKey: number;
    axlePointCOMlCompanyKey: number;
    billingEmail: string;
    billingPhone: string;
    brochureUploadURL: string;
    city: string;
    companyName: string;
    deleted: boolean;
    description: string;
    dispatchEmail: string;
    dispatchFax: string;
    dispatchPhone: string;
    facebook: string;
    insertedUserKey: string;
    instagram: string;
    linkedIn: string;
    logoURL: string;
    onboard: boolean;
    ownerFirstName: string;
    ownerLastName: string;
    prospect: boolean;
    postalCode: string;
    providerPK: string;
    plan: string;
    state: string;
    systemDefault: boolean;
    twitter: string;
    updatedUserKey: string;
    useCenteralBilling: boolean;
    useCenteralDispatch: boolean;
    website: string;
    userInformationID: number;
    fullAddress: string;

    //Add for location 
    tollFreePhone: string;
    businessHoursPhone: string;
    afterHoursPhone: string;
    insertedTimestamp: string;

    ///newly added
    companyFEIN: string;
    category: string;
    workOrder: number;
    removalReason: string;
    
    deserialize(input: any): any {
        Object.assign(this, input);
        this.city = input.city;
        this.fullAddress = (this.address1 || '') + ', ' + (this.city || '') + ', ' + (this.state || '');
        return this;
    }

    prepareSave(data: any): any {
        this.deserialize(data);
        this.systemDefault = false;
        this.deleted = false;
        return this;
    }

}

