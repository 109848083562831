import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { patterns } from 'src/assets/patterns';
import { NbToastrService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import { UsersService } from 'src/app/services/users.service';
import { CommonService } from 'src/app/services/common.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-edit-user-details-dialog',
  templateUrl: './edit-user-details-dialog.component.html',
  styleUrls: ['./edit-user-details-dialog.component.scss']
})
export class EditUserDetailsDialogComponent implements OnInit {

  @ViewChild('file') myInputVariable: ElementRef;
  editUserDetailsForm: UntypedFormGroup;
  disableFlag = false;
  loading: boolean;
  isRequired = false;
  datePipe = new DatePipe('en-US');
  fileData: any;
  imgURL: string | ArrayBuffer = '../assets/images/placeholder.png';
  imagePath: any;
  oldImageUrl: string;
  readOnlyUser: false;

  constructor(
    public dialog: MatDialogRef<EditUserDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private toastrService: NbToastrService,
    private commonService: CommonService,
    private imageCompress: NgxImageCompressService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.editUserDetailsForm = this.formBuilder.group({
      firstName: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.textPattern)]
      ],
      lastName: [
        { value: '', disabled: this.disableFlag },
        [Validators.required, Validators.pattern(patterns.textPattern)]
      ],
      officePhone: ['', [Validators.pattern(patterns.mobnumPattern)]],
      cellPhone: ['', Validators.pattern(patterns.mobnumPattern)],
      isOutOfOffice: [''],
      outOfficeExpDate: [''],
      okToTextMsg: [true],
    });
    this.setUserDetailsForm();
  }

  atleasetOneRequired() {
    const form = this.editUserDetailsForm.value;
    if (form.officePhone || form.cellPhone) {
      return false;
    } else {
      return true;
    }
  }

  setUserDetailsForm() {
    this.editUserDetailsForm.setValue({
      firstName: this.data.profileDetails.firstName,
      lastName: this.data.profileDetails.lastName,
      officePhone: this.data.profileDetails.officePhone,
      cellPhone: this.data.profileDetails.cellPhone,
      isOutOfOffice: this.data.profileDetails.outOfOffice,
      outOfficeExpDate: this.data.profileDetails.outOfOfficeExpireTimestamp
        ? this.datePipe.transform(this.data.profileDetails.outOfOfficeExpireTimestamp, 'yyyy-MM-dd')
        : '',
      okToTextMsg: this.data.profileDetails.okToTextMsg,
    });

    if (this.data.profileDetails.profilePictureURL) {
      this.imgURL = atob(this.data.profileDetails.profilePictureURL);
      this.oldImageUrl = this.imgURL;
    }
  }

  closeDialog() {
    this.dialog.close(0);
  }

  updateDetails() {

    if (this.editUserDetailsForm.invalid || this.atleasetOneRequired()) {
      this.editUserDetailsForm.markAllAsTouched();
      return false;
    }

    const outOfOfficeDateTime = this.datePipe.transform(this.editUserDetailsForm.controls.outOfficeExpDate.value, 'MM/dd/yyyy') + ' ' + '23:59:59';
    const dateString = outOfOfficeDateTime;
    const timeStamp = new Date(dateString).getTime();

    const data = {
      firstName: this.editUserDetailsForm.controls.firstName.value,
      lastName: this.editUserDetailsForm.controls.lastName.value,
      officePhone: this.editUserDetailsForm.controls.officePhone.value,
      cellPhone: this.editUserDetailsForm.controls.cellPhone.value,
      outOfOffice: this.editUserDetailsForm.controls.isOutOfOffice.value ? true : false,
      outOfOfficeExpireTimestamp: isNaN(timeStamp) ? '' : timeStamp,
      updatedUserKey: sessionStorage.getItem('userKey'),
      okToTextMsg: this.editUserDetailsForm.controls.okToTextMsg.value,
      userKey: sessionStorage.getItem('userKey'),
    };
    this.usersService.updateUser(data).subscribe((result: any) => {
      if (result.success) {
        this.toastrService.success(result.message, 'Success');
        this.dialog.close(1);
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
      this.toastrService.danger('Oops! Something went wrong.', 'Danger');
    });
  }

  setOutOfOffice(event) {
    if (event.checked) {
      this.editUserDetailsForm.controls.outOfficeExpDate.setValidators(Validators.required);
    } else {
      this.editUserDetailsForm.controls.outOfficeExpDate.setValidators(null);
    }
    this.editUserDetailsForm.controls.outOfficeExpDate.updateValueAndValidity();
  }

  preview(files: any, event: any) {
    if (files.length === 0) {
      return false;
    }
    const fileReader = new FileReader();
    fileReader.onload = (res: any) => {
      this.imageCompress.compressFile(res.target.result, -1, 50, 50).then(result => {
        this.utilityService.urltoFile(result, event.target.files[0].name, files[0].type).then((file) => {
          this.fileData = file;
          const formData = new FormData();
          formData.append('file', this.fileData);
          formData.append('fileType', 'image');

          const mimeType = files[0].type;
          if (mimeType.match(/image\/*/) == null) {
            this.toastrService.danger('Only image formats are supported.');
            return false;
          }

          const size = files[0].size;
          if (Math.round(size / 1024) > 5120) {
            this.toastrService.danger('Image size should not exceed 5MB.');
            this.myInputVariable.nativeElement.value = '';
            return false;
          }

          this.loading = true;
          const reader = new FileReader();
          this.imagePath = files;

          reader.readAsDataURL(files[0]);
          reader.onload = () => {

            this.commonService.updateProfilePicture(formData).subscribe(resFileDetail => {
              if (resFileDetail.status) {
                this.toastrService.success('Profile Uploaded.!', 'Success');
                if (this.oldImageUrl) {
                  const deleteImg = new FormData();
                  deleteImg.append('file_name', this.oldImageUrl);
                  this.commonService.deleteProfilePicture(deleteImg).subscribe((response: any) => {
                    this.loading = false;
                  }, (error) => {
                    this.loading = false;
                  });
                }

              } else {
                this.toastrService.danger('Failed to upload profile.!', 'Error');
              }
              const data = {
                userKey: this.data.profileDetails.userKey,
                profilePictureURL: resFileDetail.url
              };

              this.imgURL = resFileDetail.url;

              this.usersService.updateUser(data).subscribe((response: any) => {
                this.loading = false;
                this.usersService.setProfilePicture(resFileDetail.url);
              }, (error) => {
                this.loading = false;
                this.toastrService.danger(error.message, 'Error');
              });
            }, (error) => {
              this.loading = false;
              this.toastrService.danger('Failed to update profile picture.!', 'Error');
            });
          };
        }, error => {
          this.toastrService.danger('Failed to update profile picture.!', 'Error');
          console.log('Failed to update profile picture.!', error);
        });
      });
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

}
