<div *ngFor="let levelData of levelData; let i = index;" class="tree-root tree-child tree-node">
    <div class="line-to"></div>
    <div class="tree-element-container tree-element-has-children" [nbTooltip]="levelData.name">
        <div class="tree-element-main" [dndDraggable]="levelData" [dndEffectAllowed]="draggable.effectAllowed"
            [dndDisableIf]="draggable.disable" dndDropzone>
            <div class="rect" (click)="showNetwork(levelData)" [ngClass]="{
                    'bubble-restricted' : levelData.isRestricted == 1 && restricted && levelData.isRestricted == true,
                    'bubble-selected': levelData.deleted == 0 && !parentRecord,
                    'bubble-deselected': levelData.deleted == 1,
                    'pointer-cursor': !(level == lastNode || !(levelData.deleted == 0) || !(levelData.children != null && levelData.children[0] != undefined)),
                    'default-cursor': level == lastNode || !(levelData.deleted == 0) || !(levelData.children != null && levelData.children[0] != undefined),
                    'bubble-parent': levelData.deleted == 0 && parentRecord
                }">
                <span *ngIf="levelData.isAllParentActive == 1" class="selection-icon-wrapper-provider"
                    (click)="activateInactivate(levelData.deleted, levelData)">
                    <i [title]="levelData.deleted == 0 ? 'Deselect' : 'Select'" class="fad fa-lg selection-icon"
                        [ngClass]="{
                            'select-mark': levelData.deleted == 1, 
                            'deselect-mark': levelData.deleted == 0,
                            'fa-check-circle': levelData.deleted == 1,
                            'fa-window-close': levelData.deleted == 0
                        }"></i>
                </span>
                <span *ngIf="restricted" class="restrict-icon"
                    (click)="restrictDerestrict(levelData.isRestricted, levelData)">
                    <i [title]="levelData.isRestricted == 0 ? 'Restrict' : 'DeRestrict'" class="fad fa-lg selection-icon"
                        [ngClass]="{
                            'select-mark': levelData.isRestricted == 0, 
                            'deselect-mark': levelData.isRestricted == 1,
                            'fa-check-circle': levelData.isRestricted == 0,
                            'fa-window-close': levelData.isRestricted == 1
                        }"></i>
                </span>
                <div class="buttons">
                    <div class="delete button-border" *ngIf="levelData.deleted == 0 && delNode && isActiveNode"></div>
                    <div class="edit button-border" *ngIf="levelData.deleted == 0 && editNode && isActiveNode">
                        <i class="fas fa-pencil-alt"></i>
                    </div>
                    <div *ngIf="!(level == lastNode) && levelData.deleted == 0" class="button-border"
                        [ngClass]="{'toggler' : levelData.children != null && levelData.children[0] != undefined}">
                    </div>
                    <div class="add button-border"
                        *ngIf="levelData.deleted == 0 && addNode && !(level == lastNode) && isActiveNode"></div>
                </div>
                <mat-checkbox class="disable-services" (change)="selectNode($event,levelData)"
                    *ngIf="level >= isSelected && isSelected != -1">
                </mat-checkbox>
                <div class="classContent">
                    <span class="ic mt-3">
                        <i [attr.class]="levelData.icon"></i>
                    </span>
                    <br>
                    <span class="divText">{{ levelData.name }}</span>
                </div>
            </div>
        </div>
        <div class="tree-children" *ngIf="levelData.key == levelKey && levelData.deleted == 0">
            <div class="tree-elements-group">
                <bubble-tree-provider [restricted]="restricted" [levelData]="levelData.children" [level]="level"
                    [addNode]="addNode" [editNode]="editNode" [delNode]="delNode"
                    [isActiveNode]="levelData.deleted == 0" [disableDragDrop]="disableDragDrop" [lastNode]="lastNode"
                    [isSelected]="isSelected" [providerAccount]="providerAccount" [parentRecord]="parentRecord"
                    *ngIf="levelData.children != null && levelData.children[0] != undefined">
                </bubble-tree-provider>
            </div>
        </div>
    </div>
</div>
