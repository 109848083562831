<div class="modal-header">
    <h6>User Details</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close" nbTooltipPlacement="top">
      &times;
    </button>
  </div>
  <div class="modal-body details">
    <mat-tab-group class="m-1" [selectedIndex]="selectedIndex" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Details">
          <app-user-details [userDetail]="userDetail" (saveCompleted)= "saveCompleted($event)"></app-user-details>
        </mat-tab>
        <mat-tab label="Availability">
          <app-availability (saveCompleted)="saveCompleted($event)"></app-availability>
        </mat-tab>
        <mat-tab label="Authorization">
          <app-approval [userData]="userDetail"></app-approval>
        </mat-tab>
      <mat-tab label="Security Groups">
        <ng-container>
          <app-security-groups [isCustomerUser]="true" [userDetails]="userDetail"></app-security-groups>
        </ng-container>
      </mat-tab>
      <mat-tab label="Communication">
          <app-notifications></app-notifications>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="modal-footer">
      <div class="row">
        <div class="col-12 text-right mt-3">
            <button *ngIf="selectedIndex == 0 && readOnlyUser" type="button" class="btn btn-primary btn-sm ml-1" (click)="editDirectReports()">Edit Direct Reports</button>
            <button *ngIf="selectedIndex == 0 && readOnlyUser" type="button" class="btn btn-primary btn-sm ml-1" (click)="editUserDetails()">Edit</button>
            <button *ngIf="selectedIndex > 0" class="btn btn-primary btn-sm" (click)="back()">Back</button>
            <button *ngIf="(selectedIndex == 0 || selectedIndex == 1) && !readOnlyUser" class="btn btn-primary btn-sm ml-1" (click)="update()">Save & Next</button>
            <button *ngIf="selectedIndex >= 0 && selectedIndex < 4" class="btn btn-primary btn-sm ml-1" (click)="next()">Next</button>
        </div>
      </div>
  </div>
    
  