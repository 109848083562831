<div class="modal-header">
    <h5 class="modal-title">Add Attachments</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
    nbTooltipPlacement="top">&times;</button>
</div>

<div class="borderPadding"> 
<div class="modal-body ">
    <form [formGroup]="addAttachmentForm">
    <div class="">
        <div class="row"> 
            <div class="col-6 centerAlign">
                <img class="w-100" [src]="imagePreview" height="auto" *ngIf="imagePreview">
                <br>
                <span class="textAlign">{{fileName || "No file uploaded yet."}} </span>  
            </div>

            <div class="col-6 centerAlign"> 
                <mat-form-field >
                    <mat-select placeholder="Purpose*" formControlName="purpose" 
                    matNativeControl required>
                        <mat-option *ngFor="let type of attachmentTypes | orderBy : 'AttachmentTypeName'"
                        [value]="type.AxlepointReference" >
                        {{type.AttachmentTypeName }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <input type="file" class="file-input" formControlName="attachment" 
                (change)="onFileSelected($event)" #fileUpload>
    
                <div class="file-upload">
                    <label class="btn btn-primary image-button d-block mt-2"  (click)="fileUpload.click()">
                        {{ fileName ?  'Select Different File' : 'Select File'}}
                    </label>
                </div>
            </div>   
        </div>  
    </div>
    </form>    

    <br>
</div>

<div class="modal-footer text-right">
    <button class="btn btn-primary btn-sm" (click)="addAttachment()">Add Attachment</button>
    <button class="btn btn-primary btn-sm" (click)='closeDialog()'>Cancel</button>
</div>
</div>