<app-loader [loading]="loading"></app-loader>
<div class="modal-header">
    <h6 class="modal-title">Account Pricing Exception Approval</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
        nbTooltipPlacement="top">&times;</button>
</div>
<form [formGroup]="accountPricingExceptionForm">
    <div class="modal-body">
        <div class="row mt-1">
            <div class="col-md-4 font-weight">
                Custom Line Item Rate Request for:
            </div>
            <div class="col-md-4">
                <span>{{accountPricingExceptionApprovalDetails.lineItemKey}}</span>
            </div>
            <div class="col-md-4">
                <span>{{accountPricingExceptionApprovalDetails.lineItemPricingCategoryKey}}</span>
            </div>
            <div class="col-md-4 mt-3">
                <span class="font-weight">Threshold Rate: </span>
                <span>${{accountPricingExceptionApprovalDetails.maxThresholdRate}}</span>
            </div>
            <div class="col-md-4 mt-3">
                <span class="font-weight">Requested Rate: </span>
                <span>${{accountPricingExceptionApprovalDetails.requestedRate}}</span>
            </div>
        </div>
        <div class="row mt-3 ">
            <div class="col-md-4">
                <span class="font-weight">1st Approver: </span>
                <span>{{accountPricingExceptionApprovalDetails.firstApproverUser}}</span>
            </div>
            <div class="col-md-4">
                <span class="font-weight">Requested Date: </span>
                <span>{{accountPricingExceptionApprovalDetails.firstApprovalTimestamp}}</span>
            </div>
            <div class="col-md-12 boxBorder">
                <mat-form-field class="example-full-width">
                    <mat-label class="font-weight">1st Approver Note:</mat-label>
                    <textarea [readonly]="isFirstApprover" formControlName="firstApproverNote"
                        [(ngModel)]="accountPricingExceptionApprovalDetails.firstApproverNote" rows="4" cols="40"
                        matInput></textarea>
                    <mat-error *ngIf="handleError('firstApproverNote', 'required')">
                        First Approver Note is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <span class="font-weight">2nd Approver: </span>
                <span>{{accountPricingExceptionApprovalDetails.secondApproverUser}}</span>
            </div>
            <div class="col-md-2">
                <mat-slide-toggle labelPosition="before" [disabled]="isDisabled" formControlName="rateApproved"
                    [(ngModel)]="accountPricingExceptionApprovalDetails.rateApproved"
                    [checked]="accountPricingExceptionApprovalDetails.rateApproved">Approved
                </mat-slide-toggle>
            </div>
            <div class="col-md-6">
                <span class="font-weight">Approval Request Response Date: </span>
                <span>{{accountPricingExceptionApprovalDetails.secondApprovalTimestamp}}</span>
            </div>
            <div class="col-md-12 boxBorder">
                <mat-form-field class="example-full-width">
                    <mat-label class="font-weight">2nd Approver Note:</mat-label>
                    <textarea [readonly]="isSecondApprover" formControlName="secondApproverNote"
                        [(ngModel)]="accountPricingExceptionApprovalDetails.secondApproverNote" rows="4" cols="20"
                        matInput></textarea>
                    <mat-error>
                        Second Approver Note is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm" type="button">View Previous</button>
    <button class="btn btn-primary btn-sm" type="button" (click)="saveAccountPricingException()" [disabled]="isButton">Save & Close</button>
</div>