<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>

<div class="modal-header">
    <h5 class="modal-title">Edit Approved Services</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="editApprovedProtocolsForm">    
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <textarea cdkTextareaAutosize cdkAutosizeMaxRows="6" matInput formControlName="agentProtocol" required placeholder="Agent Protocol"> </textarea>
                    <mat-error *ngIf="handleError('agentProtocol', 'required')">
                            Agent Protocol is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="w-100">
                    <textarea cdkTextareaAutosize cdkAutosizeMaxRows="6" matInput formControlName="providerProtocol" required placeholder="Provider Protocol"></textarea>
                    <mat-error *ngIf="handleError('providerProtocol', 'required')">
                            Provider Protocol is required.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer text-right">
    <button class="btn btn-primary btn-sm" (click)="editApprovedProtocols()">Save</button>
</div>
