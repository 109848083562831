import { Component, OnInit, Input, OnChanges, SimpleChanges, HostBinding, ViewEncapsulation,Renderer2, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { NbComponentStatus, NbToastrService } from '@nebular/theme';
import { BubbleService } from 'src/app/services/bubble.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'bubble-tree-provider',
  templateUrl: './bubble-tree-provider.component.html',
  styleUrls: ['./bubble-tree-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class BubbleTreeProviderComponent implements OnInit, OnChanges {

  // static members
  public static treeRecursiveOperationException = false;

  // Input data to manipulate bubble tree data and features
  @Input() levelData: any; // Input data
  @Input() level: number; // Level of bubble tree
  @Input() addNode: boolean; // add remove add button
  @Input() editNode: boolean; // add remove edit button
  @Input() delNode: boolean; // add remove delete button
  @Input() isActiveNode: boolean; // add remove active inactive checkbox
  @Input() disableDragDrop: boolean; // enable disable drag drop feature
  @Input() lastNode: number; // Number of levels to show (If set to -1 tree can go to n levels)
  @Input() isSelected: number; // Number of level from which nodes can be selected (If -1 then no node will have select option)
  @Input() providerAccount: any; // If bubble called from locationp
  @Input() parentRecord: boolean; // If record are of parent profile
  @Input() restricted = false; // If record are of parent profile

  // Instance members
  networkJson: any;
  levelKey: any = null;
  initialTree: any;

  // Tree drag and drop config
  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: null,
    effectAllowed: 'all',
    disable: false,
    handle: false,
  };

  providerKey: string;
  licensedOwnerKey: string;
  serviceData: any;
  updateRestrictedServices: any = [];

  constructor(
    public dialog: MatDialog,
    private bubbleService: BubbleService,
    private toastrService: NbToastrService

  ) { }

  ngOnInit() { 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.providerAccount) {
      this.setExpandedTreeLevel();
      this.draggable.disable = this.disableDragDrop;

      if (this.providerAccount != null || this.providerAccount !== undefined) {
        this.providerKey = this.providerAccount.providerKey;
      }
    }
  }

  showToast(status: NbComponentStatus, message) {
    this.toastrService.show(status, message, { status });
  }

  // method to set level of tree
  setExpandedTreeLevel() {
    const expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    this.level = this.level + 1;
    if (!!expandArr) {
      this.levelKey = expandArr[this.level - 1];
    }
  }

  showNetwork(jsonObj) {
    // Restrict method execution (Expanding tree) if node is last node or node is not active or node is having no childrens
    if (
      this.level == this.lastNode
      || !(jsonObj.deleted == 0)
      || !(jsonObj.children != null && jsonObj.children[0] != undefined)) {
      return false;
    }

    let expandArr = JSON.parse(sessionStorage.getItem('expandedLayer'));
    sessionStorage.setItem('parentSelected', jsonObj.name);
    if (this.levelKey == null) {
      this.levelKey = jsonObj.key;
      expandArr.push(jsonObj.key);
    } else if (this.levelKey == jsonObj.key) {
      this.levelKey = null;
      expandArr = expandArr.slice(0, this.level - 1);
    } else {
      this.levelKey = jsonObj.key;
      if (this.level > expandArr.length) {
        expandArr.push(jsonObj.key);
      } else {
        expandArr = expandArr.slice(0, this.level - 1);
        expandArr.push(jsonObj.key);
      }
    }
    sessionStorage.setItem('expandedLayer', JSON.stringify(expandArr));
  }

  // method to update tree active inactive status
  activateInactivate(activeState, OperationData) {
    const isDeleted = !activeState ? 1 : 0;

    if (this.parentRecord) {
      this.addProviderAccountDataIntoLocation(isDeleted, OperationData);
    } else {
      const arrReqData = {
        isDeleted,
        networkOwnerOfferingKey: OperationData.key,
        providerKey: this.providerKey,
        providerServiceOfferingKey: OperationData.providerServiceOfferingKey,
      };

      this.bubbleService.setBubbleLoader(true);
      this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
        this.bubbleService.setBubbleLoader(false);
        if (isDeleted == 1) {
          this.restrictDerestrict(isDeleted, OperationData)
        }
        this.refreshOfferings(this.providerKey);
      }, (error) => {
        this.bubbleService.setBubbleLoader(false);
      });

      // this.networkJson = JSON.parse(this.bubbleService.getserviceNetworkData());
      // this.functionActiveInactiveNode(this.networkJson, OperationData, isDeleted);
      // this.setMainTreeJson();
    }
  }

  // recursive method to update tree active inactive status
  functionActiveInactiveNode(networkJson: any, nodeToUpdate: AnimationKeyframesSequenceMetadata, activeFlag: number) {
    const nodeToUpdateJson = JSON.stringify(nodeToUpdate, null);

    // tslint:disable-next-line:forin
    for (const i in networkJson) {
      const tempNode = networkJson[i];
      const tempNodeJson = JSON.stringify(tempNode, null);
      // check if interacted node mathced with any node from nodes in iteration context
      if (tempNodeJson === nodeToUpdateJson) {
        tempNode.deleted = activeFlag;
        if (tempNode.children) {
          // method to set all children active or inactive
          this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
        }
        break;
      }

      // check if child nodes exist. If exist then recursively match interacted node with any node from child nodes in recursive context
      if (tempNode.children) {
        this.functionActiveInactiveNode(tempNode.children, nodeToUpdate, activeFlag);
      }
    }
  }

  // method to set all children active or inactive
  makeAllBelowActiveInactive(childNode, activeFlag) {
    // tslint:disable-next-line:forin
    for (const i in childNode) {
      const tempNode = childNode[i];
      tempNode.deleted = activeFlag;
      if (tempNode.children) {
        this.makeAllBelowActiveInactive(tempNode.children, activeFlag);
      }
    }
  }

  setMainTreeJson() {
    this.bubbleService.setserviceNetworkData(JSON.stringify(this.networkJson));
    this.bubbleService.setBubbleTreeData();
  }

  // method to get selected nodes
  selectNode(event, OperationData) {
    const OperationArr = {
      ServiceName: sessionStorage.getItem('parentSelected'),
      SubServiceName: OperationData.name
    };
    const OperationDataJson = JSON.stringify(OperationArr);
    let index = 0;
    let selectArr;
    selectArr = JSON.parse(sessionStorage.getItem('serviceETA')) == null ? [] : JSON.parse(sessionStorage.getItem('serviceETA'));

    if (event.checked) {
      selectArr.push(OperationArr);
    } else {
      // tslint:disable-next-line:forin
      for (const i in selectArr) {
        const tempNode = selectArr[i];
        const tempNodeJson = JSON.stringify(tempNode, null);

        if (tempNodeJson === OperationDataJson) {
          selectArr.splice(index, 1);
          break;
        }
        index++;
      }
    }
    sessionStorage.setItem('serviceETA', JSON.stringify(selectArr));
  }

  addProviderAccountDataIntoLocation(isDeleted, OperationData) {
    const data = {
      locationKey: this.providerKey,
      providerKey: this.providerAccount.parentProviderKey,
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.setUpServiceAndRates(data).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      this.refreshOfferings(this.providerKey, true, isDeleted, OperationData);
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }

  updateSelectedRecord(isDeleted, OperationData) {
    // const data = {
    //   providerKey: this.providerKey,
    //   networkOwnerOfferingKey: OperationData.key,
    // };

    // this.bubbleService.setBubbleLoader(true);
    // this.bubbleService.getProviderService(data).subscribe(result => {
    //   this.bubbleService.setBubbleLoader(false);
    const arrReqData = {
      isDeleted,
      networkOwnerOfferingKey: OperationData.key,
      providerKey: this.providerKey,
      // providerServiceOfferingKey: result.body[0].providerServiceOfferingKey,
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.updateActivateInActivateProviderServiceOfferings(arrReqData).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      this.refreshOfferings(this.providerKey);
    });

    // this.networkJson = JSON.parse(this.bubbleService.getserviceNetworkData());
    // this.functionActiveInactiveNode(this.networkJson, OperationData, isDeleted);
    // this.setMainTreeJson();
    // }, (error) => {
    //   this.bubbleService.setBubbleLoader(false);
    // });
  }

  restrictDerestrict(restrictState, OperationData) {
    if (this.parentRecord) {
      return false;
    }

    this.updateRestrictedServices = [];
    this.networkJson = JSON.parse(this.bubbleService.getserviceNetworkData());
    const isRestricted = !restrictState ? 1 : 0;

    this.licensedOwnerKey = sessionStorage.getItem('licensedOwnerKey');
    // if (this.providerAccount !== null || this.providerAccount !== undefined) {
    //   this.licensedOwnerKey = this.providerAccount.licensedOwnerKey;
    // }
    //  this.updateRestricted(OperationData, isRestricted);

    const deleted = !isRestricted ? true : false;
    const reqData = {
      deleted,
      networkOwnerOfferingKey: OperationData.key,
      providerKey: this.providerKey,
      licensedOwnerKey:  this.licensedOwnerKey,
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.addDeleteProviderOfferingsRestrictions(reqData).subscribe(result => {

      this.bubbleService.setBubbleLoader(false);
      this.refreshOfferings(this.providerKey);
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
    // this.functionRestrictDerestrict(this.networkJson, OperationData, isRestricted);
    // this.setMainTreeJson();
  }

  updateRestricted(OperationData, isRestricted) {
    if (OperationData.providerServiceOfferingKey) {
      this.updateRestrictedServices.push({
        isRestricted,
        providerServiceOfferingKey: OperationData.providerServiceOfferingKey,
        updatedUserKey: sessionStorage.getItem('userKey'),
      });
    }

    if (OperationData.children) {
      OperationData.children.forEach(element => {
        this.updateRestricted(element, isRestricted);
      });
    }
  }

  functionRestrictDerestrict(networkJson, OperationData, restrictState) {
    const nodeToUpdateJson = JSON.stringify(OperationData, null);

    // tslint:disable-next-line:forin
    for (const i in networkJson) {
      const tempNode = networkJson[i];
      const tempNodeJson = JSON.stringify(tempNode, null);
      // check if interacted node mathced with any node from nodes in iteration context
      if (tempNodeJson === nodeToUpdateJson) {
        tempNode.isRestricted = restrictState;
        if (tempNode.children) {
          // method to set all children restrict or derestrict
          this.makeAllBelowrestrictDerestrict(tempNode.children, restrictState);
        }
        break;
      }
      // check if child nodes exist. If exist then recursively match interacted node with any node from child nodes in recursive context
      if (tempNode.children) {
        this.functionRestrictDerestrict(tempNode.children, OperationData, restrictState);
      }
    }
  }

  makeAllBelowrestrictDerestrict(childNode, isRestricted) {
    // tslint:disable-next-line:forin
    for (const i in childNode) {
      const tempNode = childNode[i];
      tempNode.isRestricted = isRestricted;
      if (tempNode.children) {
        this.makeAllBelowrestrictDerestrict(tempNode.children, isRestricted);
      }
    }
  }

  refreshOfferings(providerKey, updateRecord = false, isDeleted = false, OperationData = {}) {
    const filter = {
      providerKey
    };

    this.bubbleService.setBubbleLoader(true);
    this.bubbleService.getProviderServiceOfferingRestrictions(filter).subscribe(result => {
      this.bubbleService.setBubbleLoader(false);
      this.serviceData = result;
      this.networkJson = result;
      this.setMainTreeJson();

      if (updateRecord) {
        this.updateSelectedRecord(isDeleted, OperationData);
      }
    }, (error) => {
      this.bubbleService.setBubbleLoader(false);
    });
  }
}
