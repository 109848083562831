import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { ProviderAccountService } from 'src/app/services/provider-account.service';
import { UserService } from 'src/app/services/user.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddNotesDialogComponent } from '../../add-notes-dialog/add-notes-dialog.component';
import { UtilityService } from 'src/app/services/utility.service';
import { SortEvent, SortableDirective } from 'src/app/shared/directive/sortable.directiveReg';

@Component({
  selector: 'ngx-account-notes',
  templateUrl: './account-notes.component.html',
  styleUrls: ['./account-notes.component.scss'],
})

export class AccountNotesComponent implements OnInit {
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  // Input provider details
  @Input() providerAccount;
  @Input() isParent;

  loading: boolean;
  providerAccountKey: string;
  enteredBy: string;
  notesDetails: any = [];

  displayedColumns = ['date', 'note', 'addedBy', 'privateForProvider', 'providerAccountNoteKey'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private providerAccountService: ProviderAccountService,
    private userService: UserService,
    private utilityService: UtilityService,
    private dialogService: NbDialogService,
    private toast: NbToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.providerAccountKey = this.providerAccount.providerAccountKey;
    this.getProviderAccountNotes();
  }

  getProviderAccountNotes() {
    const data = {
      providerAccountKey: this.providerAccountKey,
      privateForAccount: false,
      deleted: false,
    };
    this.notesDetails = [];

    this.loading = true;
    this.providerAccountService.getProviderAccountNotes(data).subscribe(result => {
      result.body.forEach(element => {

        const userData = {
          'userKey': element.insertedUserKey,
          'deleted': false,
        };
        this.userService.getUsersDetails(userData).subscribe((res: any) => {
          const addedBy = res.body.length > 0 ? res.body[0].firstName + ' ' + res.body[0].lastName : '';

          const userTypeData = {
            'userTypeKey': res.body[0].userTypeKey,
            'deleted': false,
          };

          this.userService.getUserTypes(userTypeData).subscribe((res: any) => {
            const userType = res.body[0].description;

            this.notesDetails.push({
              providerAccountNoteKey: element.providerAccountNoteKey,
              date: element.insertedTimestamp,
              note: element.note,
              addedBy: addedBy,
              privateForProvider: element.privateForProvider,
            });

            this.onSort({ column: 'date', direction: 'desc' });
            this.dataSource = new MatTableDataSource(this.notesDetails);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        }, (error) => {
          this.loading = false;
        });
      });
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  addNotes() {
    const dialogRef = this.dialog.open(AddNotesDialogComponent, {
      hasBackdrop: false,
      width: '50%',
      position: {
        top: '10%',
      },
      data: { providerAccountKey: this.providerAccountKey },
    });

    // on close reload Accounts details
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.getProviderAccountNotes();
      }
    });
  }

  deleteAccountNotes(providerAccountNoteKey) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const data = {
          providerAccountNoteKey: providerAccountNoteKey,
        };

        this.loading = true;
        this.providerAccountService.deleteProviderAccountNotes(data).subscribe(result => {
          this.loading = false;
          if (result.success) {
            this.toast.success(result.message[0], 'Success');
          } else {
            this.toast.warning(result.message[0], 'Error');
          }
          this.getProviderAccountNotes();
        }, (error) => {
          this.loading = false;
        });
      }
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.notesDetails = this.utilityService.sortData(this.headers, column, direction, this.notesDetails);
  }

}
