import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserInviteProcess } from 'src/app/services/user-invite-process.service';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-email-details-dialog',
  templateUrl: './email-details-dialog.component.html',
  styleUrls: ['./email-details-dialog.component.scss']
})

export class EmailDetailsDialogComponent implements OnInit {

  loading = false;
  associationKey: string;
  emailData: any;
  emailStatus: any;

  constructor(
    public dialog: MatDialogRef<EmailDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userInviteProcess: UserInviteProcess,
    private usersService: UsersService
  ) { }

  ngOnInit() {
    this.associationKey = this.data.userInvitationKey || this.data.licensedOwnerProviderInviteKey;
    if (this.associationKey) {
      this.getLogData();
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  getLogData() {
    const logFilter = { associationKey: this.associationKey };
    const statusFilter = { association: 'Communication', deleted: false };

    this.loading = true;
    this.userInviteProcess.getCommunicationLogs(logFilter).subscribe(result => {
      if (result.success && result.body.length) {
        this.emailData = result.body[0];
        this.emailData.body = atob(this.emailData.body);
        this.emailStatus = this.emailData.statusKey;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      console.log(error.message);
    });
  }

}
