import { Component, OnInit, Output, EventEmitter, Input, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AddSystemRolesDialogComponent } from '../add-system-roles-dialog/add-system-roles-dialog.component';
import { ProfileService } from 'src/app/services/profile.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SortableDirective, SortEvent } from 'src/app/shared/directive/sortable.directiveReg';

@Component({
  selector: 'ngx-system-access-role-dailog',
  templateUrl: './system-access-role-dailog.component.html',
  styleUrls: ['./system-access-role-dailog.component.scss'],
})
export class SystemAccessRoleDailogComponent implements OnInit {
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  @Input() userDetails: any;
  loading: boolean = false;
  groupData: any = [];
  groupDetails: any;
  userKey: string;
  levelData: any = [];
  tempSecurityGroupData: any;
  securityGroupData: any = [];
  userSecurityGroupKey: string;
  isSecurityGroup = false;
  loggedInuserSecurityGroups: any;
  securityLevel: any;
  isReadOnly = false;

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private utilityService: UtilityService,
    public dialogs: MatDialogRef<SystemAccessRoleDailogComponent>
  ) { }

  ngOnInit() {
    this.userKey = (this.userDetails.userKey) ? this.userDetails.userKey : sessionStorage.getItem('userKey');
    this.getLoggedinUserSecurityGroups();

    if (!this.userDetails.userKey) {
      this.isReadOnly = true;
    }
  }

  getLoggedinUserSecurityGroups() {
    const objParams = {
      userKey: sessionStorage.getItem('userKey'),
      platform: 'ServicePlus',
    };
    this.userService.getUserGroups(objParams).subscribe(res => {
      this.loggedInuserSecurityGroups = res.body;
      this.securityLevel = res.body[0].securityLevel;
      this.userSecurityGroups();
    }, (error => {
      console.log(error);
    }));
  }

  userSecurityGroups() {
    this.loading = true;
    const data = {
      userKey: this.userKey,
    };

    this.userService.getUserSecurityGroups(data).subscribe((response: any) => {
      const securityGroupDetails = response.body[0];
      if (securityGroupDetails) {
        this.isSecurityGroup = true;
        this.userSecurityGroupKey = securityGroupDetails.userSecurityGroupKey;
        this.getGroups(securityGroupDetails.securityGroupKey);
      }
      this.loading = false;
    }, (error) => {
      console.info('error', error);
      this.loading = false;
    });
  }

  // get securitygroups
  getGroups(securityGroupKey: string) {
    this.groupData = [];
    const data = {
      securityGroupKey: securityGroupKey,
      deleted: false,
    };

    this.userService.getGroups(data).subscribe((response) => {
      if (response.body.length > 0) {
        this.groupData.push(response.body[0]);
        this.onSort({ column: 'securityLevel', direction: 'asc' });
        this.findLevel();
        this.getSecurityGroups();
      }
    }, (error) => {
      console.info('error', error);
      this.loading = false;
    });
  }

  // delete group
  deleteGroup(event: any) {

    const securityGroupKey = event.securityGroupKey;

    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        this.loading = true;
        this.profileService.deleteSecurityGroups(securityGroupKey).subscribe(res => {
          this.toastrService.success('Security Group deleted successfully!.', 'Success');
          this.groupData.length = 0;
          this.userSecurityGroups();
          this.loading = false;
        }, (error) => {
          this.loading = false;
          console.info(error);
        });
      }
    });
  }

  // function for getting security level as descending order
  findLevel() {
    this.groupData.forEach(element => {
      this.levelData.push(element.securityLevel);
    });
    this.levelData.sort((a, b) => b - a);
  }

  // open add ned groups dialog
  addNewGroups() {
    // call function getting level as descending order
    this.findLevel();

    const dialogRef = this.dialog.open(AddSystemRolesDialogComponent, {
      width: '30%',
      position: {
        top: '5%',
      },
      data: { userKey: this.userKey, level: this.levelData[0] }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        this.loading = true;
        this.groupData.length = 0;
        this.userSecurityGroups();
        this.loading = false;
      }
    });
  }

  // get user groups
  getSecurityGroups() {
    this.securityGroupData = [];
    const postData = {
      'platform': 'ServicePlus',
      'userTypeKey': 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4',
      'deleted': false,
    };

    this.userService.getGroups(postData).subscribe((response) => {
      response.body.forEach(element => {
        if (element.securityLevel >= this.securityLevel) {
          this.securityGroupData.push(element);
        }
      });
      this.onSort({ column: 'description', direction: 'asc' });
    }, (error) => {
      console.log('error', error);
    });
  }

  // update user group
  updateSecurityGroup(data) {
    this.loading = true;
    const securityGroups = {
      userSecurityGroupKey: this.userSecurityGroupKey,
      securityGroupKey: this.securityGroupData.find(item => item.description === data.description).securityGroupKey,
    };

    this.userService.updateUserSecurityGroup(securityGroups).subscribe(res => {
      this.toastrService.success('Security Groups updated SuccessFully!', 'Success');
      this.userSecurityGroups();
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log('error', error);
    });
  }

  editSecurityGroup(index) {
    this.tempSecurityGroupData = { ...{}, ...this.groupData[index] };
    this.groupData[index].editMode = !this.groupData[index].editMode;
  }

  cancelSecurityGroup(index) {
    this.groupData[index].editMode = !this.groupData[index].editMode;
    this.groupData[index] = this.tempSecurityGroupData;
  }

  onSort({ column, direction }: SortEvent) {
    this.groupData = this.utilityService.sortData(this.headers, column, direction, this.groupData);
  }

  // close dialog
  closeDialog() {
    this.dialogs.close();
  }

}
