<app-loader [loading]="loading"></app-loader>
<h1 mat-dialog-title>Provider Verification for {{data.companyName}}</h1>

<div mat-dialog-content>

    <div *ngIf="currentStep == 1">
        <div>
            <strong>Similar locations found for {{data.companyName}} located at {{data.fullAddress}}. Ensure this application is not a duplicate of one of these locations.</strong>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="table-responsive mt-2">
                    <table matSort class="table table-bordered rates">
                        <thead class="text-center">
                            <tr>
                                <th mat-sort-header="Name" sortable="Name" (sort)="onSort($event)" direction="asc"> Location Name
                                </th>
                                <th> Distance </th>
                                <th> Address </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="duplicateParentData && duplicateParentData.length > 0; else noData">
                            <tr *ngFor="let duplicateParentData of duplicateParentData; let i = index;">
                                <td> {{duplicateParentData.Name}}</td>
                                <td> {{duplicateParentData.DistanceInMiles}} &nbsp; Miles </td>
                                <td > {{duplicateParentData.PhysicalAddress1}}, &nbsp; {{duplicateParentData.PhysicalCity}}, &nbsp; {{duplicateParentData.PhysicalStateKey }}, &nbsp; {{duplicateParentData.PhysicalPostalCode  }} </td>
                            </tr>
                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No Similar Providers Found
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
     
        <div class="row">
            <div class="col-6">
                <strong>Is this application a new provider/location?</strong>
            </div>
        </div>
        <br>
        <div class="row">    
            <div class="col-5">
                <button class="btn btn-primary btn-sm" (click)="locationDuplicationCheck(0)">
                    No, This location is a duplicate
                </button>   
            </div>
            <div class="col-6">
                <button class="btn btn-primary btn-sm" (click)="locationDuplicationCheck(1)">
                     Yes, This is a new location
                </button>  
            </div>
        </div>
    </div>

    <div *ngIf="currentStep == 2">
        <div class="row">
            <div class="col-6">
                <strong>Is this a parent location or a sublocation?</strong>
            </div>
        </div>
        <div class="row m-2">
            <mat-radio-group aria-label="Select an option">
                <mat-radio-button (click)="locationType('parent')" value="1">Parent Location</mat-radio-button>
                <mat-radio-button (click)="locationType('sublocation')" value="2">Sublocation</mat-radio-button>
              </mat-radio-group>
        </div>

        <div>
        <form [formGroup]="providerApplicationForm">    
            <div class="row">
                <div class="col-10"> <strong>Application Information</strong> 
                    <span *ngIf="addAsProviderType == 'Parent'"> <strong> - Add Provider As Parent Location</strong></span> 
                    <span *ngIf="addAsProviderType == 'Sublocation'"> <strong> - Add Provider As Subloocation</strong></span> 
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <mat-form-field >
                        <mat-label>Company or Location Name</mat-label>
                        <input formControlName="providerName" matInput>
                    </mat-form-field>
                </div>
                <div class="col-3" *ngIf="addAsProviderType == 'Parent'">
                    <mat-form-field >
                        <mat-label>Company FEIN</mat-label>
                        <input formControlName="companyFEIN" matInput  minlength="10" maxlength="10" (keyup)="FEINFormat($event)">
                    </mat-form-field>
                </div>

            </div>
            <!-- <div class="row mt-2">
                <div class="col-6">
                    <mat-form-field >
                        <mat-label>Provider Address Line 1</mat-label>
                        <input formControlName="addressLine1" matInput>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field >
                        <mat-label>Address Line 2</mat-label>
                        <input formControlName="addressLine2" matInput>
                    </mat-form-field>
                </div>
            </div> -->

            <div class="row mt-2">
                <div class="col-md-9">
                    <mat-form-field appearance="outline">
                        <mat-label>Provider Address</mat-label>
                        <input [value]="selectedParentLocation.locationAddress" readonly matInput> 
                    </mat-form-field>
                </div> 
            </div>

            <!-- <div class="row mt-2">
                <div class="col-3">
                    <mat-form-field >
                        <mat-label>Provider City</mat-label>
                        <input formControlName="city" matInput>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>State</mat-label>
                        <mat-select placeholder="State" matNativeControl formControlName="stateKey">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let state of statesData | orderBy:'state'"
                            [value]="state.stateKey">
                            {{state.stateDescription}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div> 

                <div class="col-3">
                    <mat-form-field >
                        <mat-label>Zipcode</mat-label>
                        <input formControlName="zipCode" matInput>
                    </mat-form-field>
                </div>
            </div> -->
        </form>
        </div>
       
        <div *ngIf="!isParentLocation">
            <div *ngIf="!isParentSelected">
                <div class="row">
                    <div class="col-10"> <strong>Select a Parent Location </strong> </div>
                </div>
            </div>
            <div *ngIf="isParentSelected">
                <div class="row">
                    <div class="col-10"> <strong>Selected Parent Location </strong> </div>
                </div>
            </div>
          
            <form [formGroup]="providerSearchForm">
            <div *ngIf="!isParentSelected"> 
                <div class="row mt-2">
                    <div class="col-md-5">
                      <mat-form-field>
                        <input matInput placeholder="Provider Name" formControlName="providerName">
                      </mat-form-field>
                    </div>
            
                    <div class="col-md-4">
                      <mat-form-field>
                        <input matInput placeholder="Provider ID" formControlName="providerID">
                      </mat-form-field>
                    </div>            
                </div>

                <div class="row mt-2">
                    <div class="col-md-9">
                      <mat-form-field>
                        <input matInput placeholder="Provider Address" formControlName="providerName">
                      </mat-form-field>
                    </div>           
                </div>

                <div class="row mt-2">
                    <div class="col-md-3">
                      <mat-form-field>
                        <input matInput placeholder="City" formControlName="city">
                      </mat-form-field>
                    </div>
            
                    <div class="col-md-3">
                        <mat-form-field>
                          <mat-select placeholder="State" formControlName="stateKey" 
                          matNativeControl>
                          <mat-option></mat-option>
                          <mat-option *ngFor="let state of statesData | orderBy:'state'"
                              [value]="state.stateKey">
                              {{state.stateDescription}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div> 

                    <div class="col-md-3">
                      <mat-form-field>
                        <input matInput placeholder="Zidcode" formControlName="zipCode">
                      </mat-form-field>
                    </div>

                    <div class="col-md-3 centerItem">
                        <div class=" centerItem">
                            <button class="btn btn-primary btn-sm" (click)="getParentLocation()">
                                 Search Parent
                            </button>
                        </div>
                    </div>
                </div>
            </div>

                <div *ngIf="isParentSelected" >
                <div  class="row mt-2">
                    <div class="col-md-9"> 
                        <mat-form-field appearance="outline">
                            <mat-label>Selected Parent Name</mat-label>
                            <input [value]="selectedParentLocation.name" readonly matInput>
                        </mat-form-field>
                    </div>                      
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <mat-form-field appearance="outline">
                            <mat-label>Selected Parent Location</mat-label>
                            <input [value]="selectedParentLocation.address" readonly matInput>
                        </mat-form-field>
                    </div> 
                    <div class="col-md-3 centerItem">
                        <div class="centerItem">
                            <button class="btn btn-primary btn-sm" (click)="redoParentSearch()">
                                    Change Parent
                            </button>
                        </div>
                    </div>
                </div>
                </div>    
            </form>
        </div>

        <!-- <div *ngIf=" isParentSelected">
            <div class="row">
                <div class="col-10"> <strong>Selected as Parent Location </strong> </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <mat-form-field >
                        <mat-label>Company Name</mat-label>
                        <input [value]="data.companyName" matInput>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field >
                        <mat-label>Company FEIN</mat-label>
                        <input [value]="data.companyFEIN" matInput>
                    </mat-form-field>
                </div>

            </div>
            <div class="row mt-2">
                <div class="col-5">
                    <mat-form-field >
                        <mat-label>Provider Address Line 1</mat-label>
                        <input [value]="data.address1" matInput>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field >
                        <mat-label>Provider Address Line 2</mat-label>
                        <input [value]="data.address2" matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-4">
                    <mat-form-field >
                        <mat-label>Provider City</mat-label>
                        <input [value]="data.city" matInput>
                    </mat-form-field>
                </div>
     
                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>State</mat-label>
                        <mat-select placeholder="State" matNativeControl>
                        <mat-option></mat-option>
                        <mat-option *ngFor="let state of statesData | orderBy:'state'"
                            [value]="state.stateKey">
                            {{state.stateDescription}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div> 


                <div class="col-2">
                    <mat-form-field >
                        <mat-label>Postalcode</mat-label>
                        <input [value]="data.postalCode" matInput>
                    </mat-form-field>
                </div>
            </div>
        </div> -->
        <br>
        <div>
            <button class="btn btn-primary btn-sm" (click)="submitProvider()">Add Provider to Onboarding </button>
            <!-- [disabled]="this.isParentSelected == false" -->
        </div>
    </div>

</div>

<!-- <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="submitProvider()">Add Customer To Only Used For Specific Client</button> 
</div> -->