import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ProviderService {

  emailDetails: any = [];

  constructor(
    private http: HttpClient) { }

  // Get Search Result for Provider
  getProvidersList(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}getProviderList`, data);
  }

  // Add new licensed owner provider invites
  addLicensedOwnerProviderInvites(providerData): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites`, providerData);
  }

  // Add provider invites batch
  addProviderInvitesBatch(providerData): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites/batch`, providerData);
  }


  insertAddNewProvider(data): Observable<any> {
    return this.http.post<any>(`${environment.dispatchUrl}api/callIntake/insertAddNewProvider/`, data);
  }

  insertAddNewProviderAux(data): Observable<any> {
    return this.http.post<any>(`${environment.dispatchUrl}api/callIntake/insertAddNewProviderAux/`, data);
  }

  // Get licensed owner provider invites
  getProviderInviteDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites/get`, data);
  }

  // Delete provider invite
  deleteProviderInvite(providerData): Observable<any> {
    const data: any = {
      body: { licensedOwnerProviderInviteKey: providerData.licensedOwnerProviderInviteKey }
    };
    return this.http.delete<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites`, data);
  }

  // get communication templates
  getCommunicationTemplates(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/communicationtemplates/get`, data);
  }

  // get license owner provider invite responses
  getLicenseOwnProviderInviteRes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/licenseownerproviderinviteresponses/get`, data);
  }

  // update communication templates
  updateCommunicationTemplates(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/communicationtemplates`, data);
  }

  // send invite emails to provider
  sendInviteEmails(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}inviteEmailSend`, data);
  }

  // update communication templates
  updateLicenseOwnerProviderInvites(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}fleetassist/licensedownerproviderinvites`, data);
  }

  // Get Existing Provider Profile Update list
  getExistingProviderProfileUpdate(licensedOwnerKey): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getExistingProviderProfileUpdate/${licensedOwnerKey}`);
  }

  // Get provider details
  getProviders(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providers/get`, data);
  }

  // Get provider account details
  getProviderAccounts(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccounts/get`, data);
  }

  getServiceProviderDiscountProgram(arrReqData): Observable<any> {
    return this.http.post(`${environment.baseUrl}getServiceProviderDiscountProgram`, arrReqData);
  }

  getProviderServiceOfferingsETA(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderServiceOfferingsETA`, data);
  }

  // get provider offering ETA
  getProviderServiceOfferingsETAs(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providerofferingetas/get`, data);
  }

  getProviderDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providers/get`, data);
  }

  getSupervisorList(): Observable<any> {
    return this.http.get(`${environment.dispatchUrl}api/callIntake/getSupervisorList`); 
  }

  getCompanyStates(): Observable<any> {
    return this.http.get<any>(`${environment.dispatchUrl}api/WorkOrder/getStates`);
  }

  updateProviderDetails(dispatchData): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/providers`, dispatchData);
  }

  updateProviderApplication(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}providermembershipapplications/providers`, data);
  }

  getPaymentTerms(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/paymentterms/get`, data);
  }

  getPaymentMethods(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/paymentmethods/get`, data);
  }

  updateProviderOfferingETA(postData: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providerofferingetas`, postData);
  }

  // get membership statuses
  getMembershipStatuses(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/membershipstatuses/get`, data);
  }

  // Update provider accounts
  updateProviderAccounts(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/provideraccounts`, data);
  }

  getProviderAccountNotes(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountnotes/get`, data);
  }

  deleteProviderAccountNotes(data): Observable<any> {
    const deleteNotesData: any = {
      body: { providerAccountNoteKey: data.providerAccountNoteKey }
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, deleteNotesData);
  }

  addProviderAccountNotes(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountnotes`, data);
  }

  getLineItems(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/lineitems/get`, data);
  }

  // Update licensed owner provider invites
  updateLicensedOwnerProviderInvites(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/licensedownerproviderinvites/batch`, data);
  }

  // get locations of provider
  getLocationsOfProvider(data): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}getGetLocationsForProvider/${data.parentKey}`);
  }

  addProviderAccountProviderPreferences(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountproviderpreferences`, data);
  }

  getProviderAccountProviderPreference(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getProviderAccountProviderPreference`, data);
  }

  // Update provider Preferences
  updateProvideraccountproviderpreferences(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/provideraccountproviderpreferences`, data);
  }

  getCustomerOwnedProviderPreference(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getCustomerOwnedProviderPreference`, data);
  }

  // get communication categories
  getCommunicationCategories(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/communicationcategories/get`, data);
  }

  getEmailDetails(): Observable<any> {
    return this.emailDetails;
  }

  setEmailDetails(data) {
    this.emailDetails = data;
  }

  getCustomerNotes(data): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customernotes/get`, data);
  }

  addCustomerNotes(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}fleetassist/customernotes`, data);
  }

  deleteCustomerNotes(data): Observable<any> {
    const deleteNotesData: any = {
      body: { customerNoteKey: data.customerNoteKey }
    };
    return this.http.delete<any>(`${environment.apiUrl}fleetassist/customernotes`, deleteNotesData);
  }

  // update customer notes
  updateCustomerNotes(data): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}fleetassist/customernotes`, data);
  }

  getProviderAccountProviderPreferences(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/provideraccountproviderpreferences/get`, data);
  }

  getLicensedOwnerProviderInvites(providerData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getLicensedOwnerProviderInvites`, providerData);
  }

  getproviderdispatchsoftwares(data): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/providerdispatchsoftwares/get`, data);
  }

  insertproviderdispatchsoftwares(data): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/providerdispatchsoftwares`, data);
  }

}
