import { Component, OnInit, ViewChildren, Output, EventEmitter, QueryList, Input, OnChanges } from '@angular/core';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { BubbleService } from 'src/app/services/bubble.service';
import { UtilityService } from 'src/app/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { ProviderService } from 'src/app/services/provider.service';
import { AddEditEtaDialogComponent } from 'src/app/dialogs/add-edit-eta-dialog/add-edit-eta-dialog.component';

@Component({
  selector: 'app-provider-account-eta',
  templateUrl: './provider-account-eta.component.html',
  styleUrls: ['./provider-account-eta.component.scss']
})
export class ProviderAccountEtaComponent implements OnInit, OnChanges {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;


  @Input() providerDetails;
  @Input() isParent;

  loading = false;
  providerETAData: any = [];
  providerKey: any;
  defaultLocationKey: string;
  providerAccountKey: any;
  licensedOwnerKey: any;
  accountName: any;
  parentRecord = false;
  searchText: string;
  searchString: any;

  constructor(
    private dialog: MatDialog,
    private bubbleService: BubbleService,
    private utilityService: UtilityService,
    private providerService: ProviderService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.providerAccountKey = this.providerDetails.providerAccountKey;
    this.licensedOwnerKey = this.providerDetails.licensedOwnerKey;
    this.accountName = this.providerDetails.accountName;
    this.providerKey = this.providerDetails.providerKey;
    if (this.providerKey) {
      if (this.isParent) {
        this.getProviderServiceOfferingsETA();
      } else {
        this.getLocationServiceOfferingsETA();
      }
    }
  }

  // get provider offerings ETA
  getProviderServiceOfferingsETA() {
    let data = {};
    if (this.licensedOwnerKey !== '') {
      data = {
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        providerAccountKey: this.providerAccountKey,
        providerKey: this.parentRecord ? this.providerDetails.ProviderKey : this.providerKey
      };
    } else {
      data = {
        licensedOwnerKey: '',
        providerAccountKey: this.providerAccountKey,
        providerKey: this.parentRecord ? this.providerDetails.ProviderKey : this.providerKey
      };
    }
    console.log(data);
    this.loading = true;
    this.providerService.getProviderServiceOfferingsETA(data).subscribe((response) => {
      this.providerETAData = response;
      this.onSort({ column: 'ServiceName', direction: 'asc' });
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log('error', error);
    });
  }


  openEditETADialog(value) {
    let dialogRef = this.dialog.open(AddEditEtaDialogComponent, {
      width: '33%',
      position: {
        top: '4%'
      },
      data: {
        data: value,
        parentRecord: this.parentRecord,
        providerAccount: this.providerDetails,
        ProviderAccountKey: this.providerAccountKey
      }
    });

    dialogRef.componentInstance.updateETA.subscribe(result => {
      this.parentRecord = false;
      this.providerAccountKey = this.providerDetails.ProviderAccountKey;
      this.getProviderServiceOfferingsETA();
    });
  }

  getLocationServiceOfferingsETA() {
    const data = {

      ProviderAccountKey: this.providerAccountKey,
      ProviderKey: this.providerKey
    };
    this.loading = true;
    this.providerService.getProviderServiceOfferingsETAs(data).subscribe((response) => {
      if (response.body.length === 0) {
        this.parentRecord = true;
      } else {
        this.parentRecord = false;
      }

      if (this.isParent) {
        this.parentRecord = false;
      }

      if (this.parentRecord) {
        const data = {
          accountName: this.accountName,
          licensedOwnerKey: this.providerDetails.licensedOwnerKey,
          ProviderKey: this.providerDetails.ProviderKey,
        };
        this.loading = true;
        this.bubbleService.getProviderAccountKey(data).subscribe(result => {
          this.loading = false;
          // this.ProviderAccountKey = result.body[0].ProviderAccountKey;
          this.getProviderServiceOfferingsETA();
        }, (error) => {
          this.loading = false;
        });
      } else {
        this.getProviderServiceOfferingsETA();
      }

      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log('error', error);
    });
  }

  search(event) {
    this.searchString = event;
  }

  onSort({ column, direction }: SortEvent) {
    this.providerETAData = this.utilityService.sortData(this.headers, column, direction, this.providerETAData);
  }
}
