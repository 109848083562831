import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { UsersService } from 'src/app/pages/provider-match/services/users.service';
import { DynamicMenuService } from './services/dynamic-menu.service';

interface MenuNode {
  name?: string;
  children?: MenuNode[];
  link?: string;
  color?: string;
  bordercolor?: string;
  isExpanded?: boolean;
  showMenu?: String;
  permission?: String;
}

var treedata: MenuNode[] = [];
var arrcolor: string[] = [
  '#25ace0',
  '#243e8f',
  '#a7cd45',
  '#7441a2',
  '#3D70FF'
];

@Component({
  selector: 'ngx-tree-menu-sidebar',
  templateUrl: './tree-menu-sidebar.component.html',
  styleUrls: ['./tree-menu-sidebar.component.scss']
})
export class TreeMenuSidebarComponent implements OnInit {

  treeControl = new NestedTreeControl<MenuNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<MenuNode>();
  strTitle: string;
  systemMenus = [];
  menuJson: any;
  cognitoResponse: any;
  menuFlag: boolean = false;
  menuUltimateChild = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private usersService: UsersService,
    private dynamicMenuService: DynamicMenuService,
  ) {
    if (sessionStorage.getItem('claims') != null) {
      this.cognitoResponse = JSON.parse(sessionStorage.getItem('claims'));
      this.systemMenus = JSON.parse(sessionStorage.getItem('systemMenus'));
      if (sessionStorage.getItem('systemMenus') == null || this.systemMenus.length == 0) {
        this.systemMenus = [];
        this.checkSecurityGroupExists();
      } else {
        this.setMenus();
      }
    }

    this.commonService.systemMenus.subscribe(message => {
      this.cognitoResponse = message;
      this.checkSecurityGroupExists();
    });

    this.setMenus();
  }

  ngOnInit() { }

  setMenus() {
    if (this.systemMenus.length > 0) {
      this.convertJson2Tree(this.systemMenus, 0);
      this.dataSource.data = treedata;
      this.treeControl.dataNodes = treedata;
      this.treeControl.expand(treedata[0]);
    }
  }

  convertJson2Tree(data, depth) {
    if (depth > 0) {
      let arrtree = [];
      for (let i = 0; i < data.length; i++) {
        let eachtree: MenuNode = { name: '', link: '', color: '', bordercolor: '', permission: '', children: [] };
        eachtree.name = data[i].label;
        eachtree.link = data[i].link;
        eachtree.color = (depth >= arrcolor.length) ? arrcolor[arrcolor.length - 1] : arrcolor[depth];
        eachtree.bordercolor = (depth >= arrcolor.length) ? arrcolor[arrcolor.length - 1] : arrcolor[depth - 1];
        eachtree.permission = data[i].permission;
        if (data[i].children != undefined) {
          eachtree.children = this.convertJson2Tree(data[i].children, depth + 1);
          if (eachtree.children == undefined) eachtree.children = [];
        } else {
          eachtree.children = [];
        }
        arrtree.push(eachtree);
      }
      return arrtree;
    } else {
      for (let i = 0; i < data.length; i++) {
        treedata[i] = { name: '', link: '', color: '', bordercolor: '', permission: '', children: [] };
        treedata[i].name = data[i].label;
        // treedata[i].name = data[i].title;
        treedata[i].link = data[i].link;
        treedata[i].color = arrcolor[0];
        treedata[i].bordercolor = arrcolor[0];
        treedata[i].permission = data[i].permission;
        if (data[i].children != undefined) {
          treedata[i].children = this.convertJson2Tree(data[i].children, depth + 1);
        }
      }
      return treedata;
    }
  }

  onSetColor(node) {
    document.documentElement.style.setProperty('--node-focus-color', node.color);
  }

  hasChild = (_: number, node: MenuNode) => !!node.children && node.children.length > 0;

  navigateToPage(nodeData) {
    if (nodeData.children == null || nodeData.children.length == 0) {
      sessionStorage.setItem('pagePermission', nodeData.permission);
      this.router.navigate([nodeData.link], { relativeTo: this.route });
    }
  }

  checkSecurityGroupExists() {
    if (!sessionStorage.getItem('securityGroupKey')) {
      const objParams = {
        userKey: this.cognitoResponse.sub,
        deleted: false,
      };

      this.usersService.getUserSecurityGroups(objParams).subscribe(res => {
        if (res.body.length > 0) {
          sessionStorage.setItem('securityGroupKey', res.body[0].securityGroupKey);
          this.createMenu();
        } else {
          console.log("User Don't have security Group, not able to load menus");
        }
      });
    } else {
      this.createMenu();
    }
  }

  createMenu() {
    this.menuFlag = true;
    const objParams = {
      userKey: this.cognitoResponse.sub,
      securityGroupKey: sessionStorage.getItem('securityGroupKey'),
    };

    this.dynamicMenuService.getDynamicMenusJSON(objParams).subscribe(resultMenu => {
      resultMenu.forEach(dynamicMenu => {
        if (dynamicMenu.system === 'ServicePlus Admin System') {
          if (!this.systemMenus.some(e => e.label === dynamicMenu.menu)) {
            let temp = {
              'label': dynamicMenu.menu,
              'link': dynamicMenu.menuKey,
              'permission': dynamicMenu.subMenu1Key != null ? '' : dynamicMenu.policy,
              'children': []
            }
            this.systemMenus.push(temp);
          }
        }
      });

      this.assignSubMenuOne(this.systemMenus, resultMenu);
      sessionStorage.setItem('systemMenus', JSON.stringify(this.systemMenus));
      this.setMenus();
      this.createMenuUltimateChildArray(this.systemMenus);
      sessionStorage.setItem('menuUltimateChild', JSON.stringify(this.menuUltimateChild));
    }, (error) => {
      console.log(error);
    });
  }

  assignSubMenuOne(systemMenus, dynamicMenu) {
    systemMenus.forEach(systemMenus => {
      dynamicMenu.forEach(dynamicMenu => {
        if (dynamicMenu.system === 'ServicePlus Admin System') {
          if (dynamicMenu.menu === systemMenus.label && dynamicMenu.subMenu1 != null) {
            if (!systemMenus.children.some(e => e.label === dynamicMenu.subMenu1)) {
              let temp = {
                'label': dynamicMenu.subMenu1,
                // 'link': systemMenus.link + '/' + dynamicMenu.subMenu1Key,
                'link': dynamicMenu.subMenu1Key,
                'permission': dynamicMenu.subMenu2Key != null ? '' : dynamicMenu.policy,
                'children': []
              }
              systemMenus.children.push(temp);
            }
          }
        }
      });
      this.assignSubMenuTwo(systemMenus.children, dynamicMenu);
    });
  }

  assignSubMenuTwo(systemMenus, dynamicMenu) {
    systemMenus.forEach(systemMenus => {
      dynamicMenu.forEach(dynamicMenu => {
        if (dynamicMenu.system === 'ServicePlus Admin System') {
          if (dynamicMenu.subMenu1 === systemMenus.label && dynamicMenu.subMenu2 != null) {
            if (!systemMenus.children.some(e => e.label === dynamicMenu.subMenu2)) {
              let temp = {
                'label': dynamicMenu.subMenu2,
                // 'link': systemMenus.link + '/' + dynamicMenu.subMenu2Key,
                'link': dynamicMenu.subMenu2Key,
                'permission': dynamicMenu.subMenu3Key != null ? '' : dynamicMenu.policy,
                'children': []
              }
              systemMenus.children.push(temp);
            }
          }
        }
      });
      this.assignSubMenuThree(systemMenus.children, dynamicMenu);
    });
  }

  assignSubMenuThree(systemMenus, dynamicMenu) {
    systemMenus.forEach(systemMenus => {
      dynamicMenu.forEach(dynamicMenu => {
        if (dynamicMenu.system === 'ServicePlus Admin System') {
          if (dynamicMenu.subMenu2 === systemMenus.label && dynamicMenu.subMenu3 != null) {
            if (!systemMenus.children.some(e => e.label === dynamicMenu.subMenu3)) {
              let temp = {
                'label': dynamicMenu.subMenu3,
                // 'link': systemMenus.link + '/' + dynamicMenu.subMenu3Key,
                'link': '/pages/' + dynamicMenu.subMenu3Key,
                'permission': dynamicMenu.policy,
                'children': []
              }
              systemMenus.children.push(temp);
            }
          }
        }
      });
    });
  }

  createMenuUltimateChildArray(networkJson: any) {
    for (let i in networkJson) {
      let tempNode = networkJson[i];
      if (tempNode.children.length == 0) {
        this.menuUltimateChild.push(tempNode.link);
      } else {
        this.createMenuUltimateChildArray(tempNode.children);
      }
    }
  }
}
