<div class="modal-header">
    <h6 class="modal-title">Edit Sublocation Information
    </h6>
    <button class="close"
            type="button"  
            (click)="closeDialog()" 
            nbTooltip="Close"
            nbTooltipPlacement="top">&times;
    </button>
</div>


<div class="paddingClass m-2"> 

<!-- <h5>Edit Sublocation Information </h5>
<hr> -->

<div>
    <div class="row">
        <label class="col-12 alignText"><strong> Location Name: {{subLocation.name}} &nbsp;| &nbsp; Location ID: {{subLocation.providerId}}</strong></label>
    </div> 
    <br>
<form [formGroup]="subLocationForm">
<div class="row"> 
<div class="col">     
    <div class="row">
        <label class="col-12"><strong>Physical Address Information</strong></label>
    </div>
    <div class="row">
        <div class="col-7">
            <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput ngx-google-places-autocomplete formControlName="physicalAddress1" #placesRef="ngx-places" (onAddressChange)="addressChanged($event)"/>
            </mat-form-field>
        </div>
        <div class="col-5">
            <mat-form-field>
                <mat-label>Address Line 2</mat-label>
                <input matInput formControlName="physicalAddress2" >
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <mat-form-field class="col-5">
            <mat-label>City</mat-label>
            <input type="text" matInput formControlName="physicalCity">
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>State</mat-label>
            <mat-select placeholder="Select State" formControlName="physicalStateKey" 
            matNativeControl>
            <mat-option></mat-option>
            <mat-option *ngFor="let state of statesData | orderBy:'state'"
                [value]="state.stateKey">
                {{state.stateDescription}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-3">
            <mat-label>Zipcode</mat-label>
            <input matInput formControlName="physicalPostalCode">
        </mat-form-field>
    </div>

    <!-- <div class="row">
        <div class="col-3">
            <mat-form-field>
                <mat-label>Zipcode</mat-label>
                <input matInput placeholder="Zipcode" formControlName="locationZipCode">
            </mat-form-field>
        </div>
    </div> -->

    <hr>

    <div class="row">
        <label class="col-12"><strong>Location Contant Information</strong></label>
    </div>

    <div class="row">
        <mat-form-field class="col-3">
            <mat-label>Dispatch Day Phone</mat-label>
            <input matInput maxlength="12" formControlName="businessHoursPhone" (focusout)="onFocusOutBusinessHoursPhone()" (click)="onClickBusinessHoursPhone()" (change)="onChangeBusinessHoursPhone()">
            <mat-error
                *ngIf="subLocationForm.controls.businessHoursPhone.touched && subLocationForm.controls.businessHoursPhone.errors">
                <span *ngIf="subLocationForm.controls.businessHoursPhone.errors.pattern"> Invalid phone number</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
            <mat-label>Dispatch Night Phone</mat-label>
            <input matInput maxlength="12" formControlName="afterHoursPhone" (focusout)="onFocusOutAfterHoursPhone()" (click)="onClickAfterHoursPhone()" (change)="onChangeAfterHoursPhone()">
            <mat-error
                *ngIf="subLocationForm.controls.afterHoursPhone .touched && subLocationForm.controls.afterHoursPhone .errors">
                <span *ngIf="subLocationForm.controls.afterHoursPhone .errors.pattern"> Invalid phone number</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-5">
            <mat-label>Dispatch Email</mat-label>
            <input matInput formControlName="dispatchEmail">
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="col-3">
            <mat-label>Billing Contact</mat-label>
            <input matInput formControlName="primaryBillingContact">
        </mat-form-field>
        <mat-form-field class="col-3">
            <mat-label>Billing Phone</mat-label>
            <input matInput maxlength="12" formControlName="billingPhone" (focusout)="onFocusOutBillingPhone()" (click)="onClickBillingPhone()" (change)="onChangeBillingPhone()" >
            <mat-error
                *ngIf="subLocationForm.controls.billingPhone.touched && subLocationForm.controls.billingPhone.errors">
                <span *ngIf="subLocationForm.controls.billingPhone.errors.pattern"> Invalid phone number</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-5">
            <mat-label>Billing Email</mat-label>
            <input matInput formControlName="billingEmail">
        </mat-form-field>
    </div>

    <hr>

    <!-- <hr> -->

    <div class="row">
        <label class="col-12"><strong>Location Status</strong></label>
    </div>
    <div class="row">
        <div class="col-4">
            <!-- <mat-checkbox formControlName="deleted">
                Location Is Active
            </mat-checkbox> -->
            <mat-form-field>
            <mat-label>Account Status</mat-label>
                <mat-select placeholder="Account Status" formControlName="dispatchStatusKey"
                (selectionChange)="statusChanged()">
                    <mat-option value='Active'>Active</mat-option>
                    <mat-option value='InActive'>InActive</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

</div> 

<div class="col-4">

    <div class="row">
        <label class="col-12"><strong>Services Offered</strong></label>
    </div>

    <div class="borderClass ">
    <div>  
        <!-- <div class="row m-1"> 
            <div class="col-8">
            <div> <strong> Service </strong></div>    
            </div>
            <div class="col-3">
                <div> <strong>Offered </strong></div>
            </div>
        </div> -->
     <br>

        <!-- <div class="row m-1">
            <div class="col-8">
                Service Name
            </div>
            <div class="col-3">
                <mat-checkbox
                class="example-margin"
            ></mat-checkbox>
            </div>
        </div> -->

        <!-- <div *ngFor="let provider of serviceAndSubService | orderBy:'ServiceName'" class="m-1 ml-2">
             <div class="row">
                <div class="col-8">
                    {{provider.SubserviceName}}
                </div>
                       <div class="col-4">
                    <mat-checkbox [value]="provider"
                    class="example-margin"
                ></mat-checkbox>
                </div>
             </div> 
        </div> -->

        <div class="row">
            <div class="col-8 ml-2">
               <strong> Accident</strong> 
            </div>
        </div>

        <div *ngFor="let accidentService of serviceAccident | orderBy:'SubserviceName' " class=" ml-4" >
            <div class="row">
               <div class="col-8 alignContent">
                   {{accidentService.SubserviceName | capitalizeFirstLetter}}
               </div>
                <div class="col-4">
                   <mat-checkbox [value]="accidentService" [checked]="accidentService.HasSubservice === 1"
                   class="example-margin" (change)="serviceCheckBoxChange(accidentService, serviceAccident, $event)"
                   ></mat-checkbox>
               </div>
            </div> 
       </div>

       <div class="row ">
        <div class="col-8 ml-2">
            <strong>Call Center Service </strong>
        </div>
    </div>
    <div *ngFor="let callCenterService of serviceCallCenterService | orderBy:'SubserviceName' " class="ml-4" >
        <div class="row">
           <div class="col-8 alignContent">
               {{callCenterService.SubserviceName | capitalizeFirstLetter}}
           </div>
            <div class="col-4">
               <mat-checkbox [value]="callCenterService" [checked]="callCenterService.HasSubservice === 1"
               class="example-margin" (change)="serviceCheckBoxChange(callCenterService, serviceCallCenterService, $event)"
               ></mat-checkbox>
           </div>
        </div> 
   </div>

    <div class="row">
        <div class="col-8 ml-2">
            <strong>Cargo</strong>
        </div>
    </div>
    <div *ngFor="let cargoService of serviceCargo | orderBy:'SubserviceName' " class="ml-4" >
        <div class="row">
        <div class="col-8 alignContent">
            {{cargoService.SubserviceName | capitalizeFirstLetter}}
        </div>
        <div class="col-4">
            <mat-checkbox [value]="cargoService" [checked]="cargoService.HasSubservice === 1"
            class="example-margin" (change)="serviceCheckBoxChange(cargoService, serviceCargo, $event)"
            ></mat-checkbox>
        </div>
        </div> 
    </div>

   <div class="row">
    <div class="col-8 ml-2">
       <strong>Driver Accommodation</strong> 
    </div>
    </div>
    <div *ngFor="let driverService of serviceDriverAccommodation | orderBy:'SubserviceName' " class="ml-4" >
        <div class="row">
        <div class="col-8 alignContent">
            {{driverService.SubserviceName | capitalizeFirstLetter}}
        </div>
        <div class="col-4">
            <mat-checkbox [value]="driverService" [checked]="driverService.HasSubservice === 1"
            class="example-margin" (change)="serviceCheckBoxChange(driverService, serviceDriverAccommodation, $event)"
            ></mat-checkbox>
        </div>
        </div> 
    </div>

    <div class="row">
        <div class="col-8 ml-2">
           <strong>Driveaway</strong> 
        </div>
        </div>
        <div *ngFor="let driveAwayService of serviceDriveAway | orderBy:'SubserviceName' " class="ml-4" >
            <div class="row">
            <div class="col-8 alignContent">
                {{driveAwayService.SubserviceName | capitalizeFirstLetter}}
            </div>
            <div class="col-4">
                <mat-checkbox [value]="driveAwayService" [checked]="driveAwayService.HasSubservice === 1"
                class="example-margin" (change)="serviceCheckBoxChange(driveAwayService, serviceDriveAway, $event)"
                ></mat-checkbox>
            </div>
            </div> 
        </div>

    <div class="row">
        <div class="col-8 ml-2">
            <strong>Major Shop Repair</strong>
        </div>
    </div>
    <div *ngFor="let shopRepair of serviceMajorShopRepair | orderBy:'SubserviceName' " class="ml-4" >
        <div class="row">
        <div class="col-8 alignContent">
            {{shopRepair.SubserviceName | capitalizeFirstLetter}}
        </div>
        <div class="col-4">
            <mat-checkbox [value]="shopRepair" [checked]="shopRepair.HasSubservice === 1"
            class="example-margin" (change)="serviceCheckBoxChange(shopRepair, serviceMajorShopRepair, $event)"
            ></mat-checkbox>
        </div>
        </div> 
    </div>

    <div class="row">
        <div class="col-8 ml-2">
            <strong>Mobile Repair</strong>
        </div>
    </div>
    <div *ngFor="let mobileRepair of serviceMobileRepair | orderBy:'SubserviceName'; let i = index  " class="ml-4" >
        <div class="row">
        <div class="col-8 alignContent">
            {{mobileRepair.SubserviceName | capitalizeFirstLetter }}
        </div>
        <div class="col-4">
            <mat-checkbox [value]="mobileRepair"[checked]="mobileRepair.HasSubservice === 1"
            class="example-margin" (change)="serviceCheckBoxChange(mobileRepair, serviceMobileRepair, $event)"
            ></mat-checkbox>
        </div>
        </div> 
    </div>

    <div class="row">
        <div class="col-8 ml-2">
            <strong>No Start</strong>
        </div>
    </div>
    <div *ngFor="let noStart of serviceNoStart | orderBy:'SubserviceName' " class="ml-4" >
        <div class="row">
        <div class="col-8 alignContent">
            {{noStart.SubserviceName | capitalizeFirstLetter}}
        </div>
        <div class="col-4">
            <mat-checkbox [value]="noStart" [checked]="noStart.HasSubservice === 1"
            class="example-margin" (change)="serviceCheckBoxChange(noStart, serviceNoStart, $event)"
            ></mat-checkbox>
        </div>
        </div> 
    </div>

    <div class="row">
        <div class="col-8 ml-2">
            <strong>Scheduled Maintenance</strong>
        </div>
    </div>
    <div *ngFor="let scheduledMaintenance of serviceScheduledMaintenance | orderBy:'SubserviceName' " class="ml-4" >
        <div class="row">
        <div class="col-8 alignContent">
            {{scheduledMaintenance.SubserviceName | capitalizeFirstLetter}}
        </div>
        <div class="col-4">
            <mat-checkbox [value]="scheduledMaintenance" [checked]="scheduledMaintenance.HasSubservice === 1"
            class="example-margin" (change)="serviceCheckBoxChange(scheduledMaintenance, serviceScheduledMaintenance, $event)"
            ></mat-checkbox>
        </div>
        </div> 
    </div>

    <div class="row">
        <div class="col-8 ml-2">
            <strong>Tires</strong>
        </div>
    </div>
    <div *ngFor="let tireService of serviceTires | orderBy:'SubserviceName' " class="ml-4" >
        <div class="row">
        <div class="col-8 alignContent">
            {{tireService.SubserviceName | capitalizeFirstLetter }}
        </div>
        <div class="col-4">
            <mat-checkbox [value]="tireService" [checked]="tireService.HasSubservice === 1"
            class="example-margin" (change)="serviceCheckBoxChange(tireService, serviceTires, $event)"
            ></mat-checkbox>
        </div>
        </div> 
    </div>

        <div class="row">
            <div class="col-8 ml-2">
                <strong>Towing</strong>
            </div>
        </div>

        <div *ngFor="let towingService of serviceTowing | orderBy:'SubserviceName' " class="ml-4" >
            <div class="row">
               <div class="col-8 alignContent">
                   {{towingService.SubserviceName | capitalizeFirstLetter}}
               </div>
                <div class="col-4">
                   <mat-checkbox [value]="towingService" [checked]="towingService.HasSubservice === 1" 
                   class="example-margin" (change)="serviceCheckBoxChange(towingService, serviceTowing, $event)"
                   ></mat-checkbox>
               </div>
            </div> 
       </div>


    </div>  
    </div>

    <br>
    <br>

    <div class="row">
        <div class="col">
            <button class="btn btn-primary btn-sm" (click)="confirmEditLocation()">Submit Changes</button>
        </div>
    </div>
    
    
</div>

</div> 

</form>    
</div>

</div>