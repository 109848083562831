<div *ngFor="let name of userGroupData">
  <label> User Group :
    {{ name.securityGroupKey }} </label>
</div>
<div class="mt-2 table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Status</th>
        <th>Timeline Milestone</th>
        <th>Communication Method</th>
        <th>Subscribed</th>
      </tr>
    </thead>
    <tbody *ngIf="userCommunication.length > 0; else noData">
      <tr *ngFor="let data of userCommunication">
        <td></td>
        <td>{{data.licensedOwnerMilestoneKey}}</td>
        <td>{{data.communicationTypeKey}}</td>
        <td>
          <mat-slide-toggle [(ngModel)]="data.subscribed">
          </mat-slide-toggle>
        </td>
      </tr>
    </tbody>
    <ng-template #noData>
      <tbody>
        <tr>
          <td colspan="5" class="text-center">No data available</td>
        </tr>
      </tbody>
    </ng-template>
  </table>
</div>
<!-- <div class="col-md-12 text-right mb-2 mt-3">
  <button class="btn btn-primary btn-sm">Save & Next</button>
</div> -->
