<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6>Double Click on the Variable Name to Add to the Template</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12 col-md-8">
            <ngx-search-input (search)="search($event)"></ngx-search-input>
        </div>
        <div class="col-md-4 text-right">
            <app-instructions [dataDictionaryKey]="dataDictionaryKey" [schema]="schema"></app-instructions>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-bordered table-striped">
            <thead>
            </thead>
            <tbody *ngIf="allTemplateVariables.length > 0; else noData">
                <tr *ngFor="let data of allTemplateVariables | filter : searchString; let i = index">
                    <td (dblclick)="getVariableDetails(data)">
                        {{data.communicationTemplateVariableKey}}
                    </td>
                </tr>
            </tbody>
            <ng-template #noData>
                <tbody>
                    <tr>
                        <td>
                            No Data available.
                        </td>
                    </tr>
                </tbody>
            </ng-template>
            
        </table>
    </div>
</div>
<div class="modal-footer">
    
</div>
