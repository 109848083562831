import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { Question } from '../../dynamic-questions/model/question.model';
import { CreateDynamicFormComponent } from '../create-dynamic-form-modal/create-dynamic-form.component';
import { DynamicYesNoFormComponent } from '../dynamic-yes-no-form/dynamic-yes-no-form.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-add-sub-question',
  templateUrl: './add-sub-question.component.html',
  styleUrls: ['./add-sub-question.component.scss']
})
export class AddSubQuestionComponent implements OnInit {

  @Input() arrQuestions?: any = [];
  @Output() subQuestionChanged = new EventEmitter();
  loading = false;
  userKey: string;
  otherTextDescriptions: string;
  questionsResponseTypes: any = [];
  arrSubQuestions: any = [];
  isLoadChildQuestions = false;
  responseTypes = ['form', 'YesNo'];

  constructor(
    public dialog: MatDialog,
    private dialogService: NbDialogService) { }

  ngOnInit() {
  }

  trackByQustionKey(index: number, question: any) {
    return question.key;
  }

  isFormPresent(item) {
    if (item.type === 'form' && Object.keys(item.components).length > 0) {
      return true;
    } else if (item.type === 'YesNo' && item.childrens.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  openFormComponent(event, question) {

    if (event.value === 'form') {
      const dialogRef = this.dialog.open(CreateDynamicFormComponent, {
        hasBackdrop: true,
        width: '90%',
        height: '80%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: { title: question.title }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.jsonText) {
          question.components = result.jsonText;
        }
      });
    } else if (event.value === 'YesNo') {
      const dialogRef = this.dialog.open(DynamicYesNoFormComponent, {
        hasBackdrop: true,
        width: '50%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: {
          question
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          const arrQuestions = result.arrQuestions.filter(obj => obj.title);
          question.display = 'YesNo';
          question.childrens = arrQuestions;
        }
      });
    }
  }

  addSubQuestion(item: any = {}) {
    const question = new Question();
    question.order = item.childrens.length + 1;
    question.parentKey = item.key;
    item.childrens.push(question);
  }

  previewFormDeatils(question: Question) {
    if (question.type === 'form') {
      const dialogRef = this.dialog.open(CreateDynamicFormComponent, {
        hasBackdrop: true,
        width: '90%',
        height: '80%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: {
          title: question.title,
          arrFieldModels: question.components,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.jsonText) {
          question.display = 'Form';
          question.components = result.jsonText;
        }
      });

    } else if (question.type === 'YesNo') {
      const dialogRef = this.dialog.open(DynamicYesNoFormComponent, {
        hasBackdrop: true,
        width: '66%',
        disableClose: true,
        position: {
          top: '5%',
        },
        data: {
          question
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          question.display = 'YesNo';
          question.childrens = result.arrQuestions;
        }
      });
    }
  }

  removeFormElements(item: any) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const itemIndex = this.arrQuestions.indexOf(item);
        if (itemIndex > -1) {
          this.arrQuestions.splice(itemIndex, 1);
          this.subQuestionChanged.emit('removed');
        }
      }
    });
  }

  clearFormDetails(item: any) {
    if (item.type === 'form') {
      item.components = '';
      item.type = '';
    } else if (item.type === 'YesNo') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        height: 'auto',
        maxHeight: '50%',
        width: '33%',
        data: {
          label: 'Do you also want to remove Child Questions?'
        }
      });

      dialogRef.afterClosed().subscribe(isValue => {
        if (isValue) {
          item.type = '';
          item.display = '';
          item.childrens = [];
        }
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arrQuestions, event.previousIndex, event.currentIndex);
    this.resetQuestionsOrder();
  }

  resetQuestionsOrder() {
    this.arrQuestions.map((questions, index) => {
      questions.order = index + 1;
    });
  }
}
