<nb-card>
    <nb-card-header class="card-header">Rate Type</nb-card-header>
    <nb-card-body>
    <div class="col-md-12 p-1">            
        <div class="div-border">
        <div class="mt-4 table-div">
        <table class="table table-bordered table-fixed">
          <thead>
            <tr>
              <th>Service Type</th>
              <th>SubService</th>
              <th>Assembly</th>
              <th>Action</th>
              <th>Rate Type</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let servicerate of ServiceRate">
              <td>{{servicerate.service}}</td>
              <td>{{servicerate.subservice}}</td>
              <td>{{servicerate.assembly}}</td>
              <td><mat-checkbox (change)="onCheckboxClick(servicerate)"></mat-checkbox></td>
              <td>{{servicerate.ratetype}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-container *ngIf="showRateType()">
      <div class="div-table">
      <table class="table table-bordered table-margin">
          <thead>
              <tr>
                  <th>Rate Type</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let addratetype of AddRateType">
                <td>{{addratetype.ratetype}}</td>
                <td><button (click)="addRateType(addratetype)" class="btn btn-primary btn-sm">Apply This Rate</button></td>
            </tr>
          </tbody>
      </table>     
    </div>
  </ng-container>
</div>
</div>      
</nb-card-body>
</nb-card>