<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h5 class="modal-title">{{dialogTitle}}</h5>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="Close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <ng-container *ngFor="let item of arrQuestions | orderBy: 'order'; let i = index; trackBy:trackByQustionKey">
        <fieldset class="border m-0 p-2 mt-2">
            <legend class="p-1 mb-0 w-auto">
                <span *ngIf="item.order == 1">Yes:</span>
                <span *ngIf="item.order == 2">No:</span>
            </legend>
            <div class="row">
                <div class="col-12 col-md-8">
                    <mat-form-field>
                        <input matInput [(ngModel)]="item.title" [placeholder]="arrTitlePlaceHolder[i]"
                            autocomplete="off">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field>
                        <mat-select [(ngModel)]="item.type" (selectionChange)="openFormComponent($event, item)"
                            placeholder="Select Action">
                            <mat-option disabled>Select Action</mat-option>
                            <mat-option value="form">Input form</mat-option>
                            <mat-option value="none">Do Nothing</mat-option>
                            <!-- <mat-option value="YesNo">Yes/No</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2 my-auto" *ngIf="isFormPresent(item)">
                    <button class="btn btn-sm btn-primary mr-1" (click)="previewFormDeatils(item)">Edit</button>
                </div>
            </div>
        </fieldset>
    </ng-container>
</div>
<div class="modal-footer text-right">
    <button class="btn btn-sm btn-primary" (click)="addYesNoFormDetails()">Submit</button>
</div>
