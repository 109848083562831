<app-loader [loading]="loading"></app-loader>

<nb-card>
    <!-- <nb-card-header class="card-header">
        These are the default discounts that Providers will be paid for services rendered. This will be reflected on the
        invoice payments submitted.
    </nb-card-header> -->
    <nb-card-body>
        <div class="row">
            <div class="w3-panel col-md-10">
                <label>
                    This page is currently under construction. Please check back later.
                    <!-- These are the default discounts that Providers will be reflected on the
                    invoice payments submitted to providers for services rendered. -->
                </label>
            </div>
            <!-- <div class="col-md-2 text-right">
                <app-instructions [dataDictionaryKey]="dataDictionaryKey" [schema]="schema"></app-instructions>
            </div> -->
        </div>
        <!-- <div class="row">
            <div class="col-md-4">
                <ngx-search-input (search)="search($event)"></ngx-search-input>
            </div>
            <div class="col-md-8 text-right">
                <button class="btn btn-primary btn-sm" (click)="addRow()">Add</button>
            </div>

        </div> -->
        <div class="row">
            <div class="col">
                <div class="table-responsive custom-height discount-table">
                    <table class="table table-bordered table-fixed mt-2 table-striped">
                        <thead>
                            <tr>
                                <th sortable="Description" (sort)="onSort($event)" class="align-middle" direction="asc">
                                    Services</th>
                                <th sortable="DiscountTypeKey" (sort)="onSort($event)" class="align-middle">Discount
                                    Type</th>
                                <th sortable="DiscountAmount" (sort)="onSort($event)" class="align-middle">Discount</th>
                                <th sortable="notes" (sort)="onSort($event)" class="align-middle">Note</th>
                                <th *ngIf="isEdit || isDiscountEditable">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="licensedOwnerDiscount.length > 0; else noData">
                            <tr *ngIf="isAddRow" [formGroup]="discountForm">
                                <td class="form-group">
                                    <div class="row div-width">
                                        <div class="col-md-4">
                                            <mat-form-field>
                                                <mat-select formControlName="licensedOwnerOfferingKey" (selectionChange)="getSubservice()" required
                                                    placeholder="Select Service">
                                                    <mat-option
                                                        *ngFor="let licenedOwner of licensedOwnerDetails | orderBy : 'description'"
                                                        [value]="licenedOwner">
                                                        {{ licenedOwner.description }}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="discountForm.controls.licensedOwnerOfferingKey.touched && discountForm.controls.licensedOwnerOfferingKey.errors">
                                                    <span
                                                        *ngIf="discountForm.controls.licensedOwnerOfferingKey.errors.required">
                                                        Service is required.
                                                    </span>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field>
                                                <mat-select formControlName="subServicesKey" (selectionChange)="getAssembly()"
                                                    placeholder="Select Sub Service">
                                                    <mat-option
                                                        *ngFor="let data of subServices | orderBy : 'description'"
                                                        [value]="data">
                                                        {{ data.description }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field>
                                                <mat-select formControlName="assembly"
                                                    placeholder="Select Assembly">
                                                    <mat-option
                                                        *ngFor="let data of assembly | orderBy : 'description'"
                                                        [value]="data.licensedOwnerOfferingKey">
                                                        {{ data.description }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </td>
                                <td class="form-group">
                                    <mat-form-field>
                                        <mat-select formControlName="discountTypeKey" required
                                            placeholder="Select Discount Type">
                                            <mat-option
                                                *ngFor="let types of discountTypes | orderBy : 'discountTypeKey'"
                                                [value]="types.discountTypeKey">
                                                {{ types.discountTypeKey }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="discountForm.controls.discountTypeKey.touched && discountForm.controls.discountTypeKey.errors">
                                            <span *ngIf="discountForm.controls.discountTypeKey.errors.required">
                                                Discount type is required.
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                                <td class="form-group">
                                    <mat-form-field>
                                        <input matInput formControlName="discountAmount" required
                                            placeholder="Discount Amount" autocomplete="off">
                                        <mat-error
                                            *ngIf="discountForm.controls.discountAmount.touched && discountForm.controls.discountAmount.errors">
                                            <span *ngIf="discountForm.controls.discountAmount.errors.required">
                                                Discount amount is required.
                                            </span>
                                            <span *ngIf="discountForm.controls.discountAmount.errors.pattern">
                                                Enter Valid Discount amount.
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                                <td class="form-group">
                                    <mat-form-field>
                                        <input matInput placeholder="Discount Note" formControlName="notes" required
                                            autocomplete="off">
                                        <mat-error
                                            *ngIf="discountForm.controls.notes.touched && discountForm.controls.notes.errors">
                                            <span *ngIf="discountForm.controls.notes.errors.required">
                                                Discount note is required.
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                                <td class="form-group">
                                    <button class="btn btn-default btn-sm btnAnimation" (click)="addDiscount()">
                                        <i class="far fa-check"></i>
                                    </button>
                                    <button class="btn btn-default btn-sm btnAnimation" (click)="cancelAddDiscount()"
                                        nbTooltip="Cancel">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngFor="let discount of licensedOwnerDiscount | filter : searchString; let i = index">
                                <td>
                                    {{discount.Description}}
                                </td>
                                <td>
                                    <span *ngIf="!(discount.editMode)">
                                        {{discount.DiscountTypeKey}}
                                    </span>
                                    <span *ngIf="discount.editMode">
                                        <mat-form-field>
                                            <mat-select [(ngModel)]="discount.DiscountTypeKey"
                                                #discountTypeKey="ngModel" required placeholder="Discount Yype">
                                                <mat-option
                                                    *ngFor="let types of discountTypes | orderBy : 'discountTypeKey'"
                                                    [value]="types.discountTypeKey">
                                                    {{ types.discountTypeKey }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error [hidden]="discountTypeKey.valid">
                                                Discount type is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="!(discount.editMode)">
                                        {{discount.DiscountAmount}}
                                    </span>
                                    <span *ngIf="discount.editMode">
                                        <mat-form-field>
                                            <input matInput [(ngModel)]="discount.DiscountAmount"
                                                #discountAmount="ngModel" required autocomplete="off"
                                                placeholder="Discount Amount">
                                            <mat-error [hidden]="discountAmount.valid">
                                                Discount amount is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="!(discount.editMode)">
                                        {{discount.DiscountNote}}
                                    </span>
                                    <span *ngIf="discount.editMode">
                                        <mat-form-field>
                                            <input matInput placeholder="Discount Note"
                                                [(ngModel)]="discount.DiscountNote" #notes="ngModel" required
                                                autocomplete="off">
                                            <mat-error [hidden]="notes.valid">
                                                Discount note is required.
                                            </mat-error>
                                        </mat-form-field>
                                    </span>
                                </td>
                                <td *ngIf="isEdit || isDiscountEditable">
                                    <button *ngIf="!(discount.editMode)" class="btn btn-default btnAnimation"
                                        nbTooltip="Edit" (click)="editDiscount(discount)" [disabled]="disableEdit">
                                        <i class="fas fa-pencil"></i>
                                    </button>
                                    <button *ngIf="!(discount.editMode)" class="btn btn-default btnAnimation"
                                        nbTooltip="Delete" (click)="deleteCustomDiscountDetails(discount)"
                                        [disabled]="disableEdit">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                    <button *ngIf="discount.editMode" class="btn btn-default btnAnimation"
                                        nbTooltip="Update" (click)="updateCustomDiscountDetails(discount)">
                                        <i class="fas fa-check"></i>
                                    </button>
                                    <button *ngIf="discount.editMode" class="btn btn-default btnAnimation"
                                        (click)="cancelDiscount(discount)" nbTooltip="Cancel">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <tr *ngIf="!isAddRow">
                                    <td colspan="5" class="text-center">No Data Available</td>
                                </tr>
                                <tr *ngIf="isAddRow" [formGroup]="discountForm">
                                    <td class="form-group">
                                        <mat-form-field>
                                            <mat-select formControlName="licensedOwnerOfferingKey" required
                                                placeholder="Select Service">
                                                <mat-option
                                                    *ngFor="let licenedOwner of licensedOwnerDetails | orderBy : 'description'"
                                                    [value]="licenedOwner.licensedOwnerOfferingKey">
                                                    {{ licenedOwner.description }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="discountForm.controls.licensedOwnerOfferingKey.touched && discountForm.controls.licensedOwnerOfferingKey.errors">
                                                <span
                                                    *ngIf="discountForm.controls.licensedOwnerOfferingKey.errors.required">
                                                    Service is required.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td class="form-group">
                                        <mat-form-field>
                                            <mat-select formControlName="discountTypeKey" required
                                                placeholder="Select Discount Type">
                                                <mat-option
                                                    *ngFor="let types of discountTypes | orderBy : 'discountTypeKey'"
                                                    [value]="types.discountTypeKey">
                                                    {{ types.discountTypeKey }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="discountForm.controls.discountTypeKey.touched && discountForm.controls.discountTypeKey.errors">
                                                <span *ngIf="discountForm.controls.discountTypeKey.errors.required">
                                                    Discount type is required.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td class="form-group">
                                        <mat-form-field>
                                            <input matInput type="number" formControlName="discountAmount" required
                                                placeholder="Discount Amount" autocomplete="off">
                                            <mat-error
                                                *ngIf="discountForm.controls.discountAmount.touched && discountForm.controls.discountAmount.errors">
                                                <span *ngIf="discountForm.controls.discountAmount.errors.required">
                                                    Discount amount is required.
                                                </span>
                                                <span *ngIf="discountForm.controls.discountAmount.errors.pattern">
                                                    Enter Valid Discount amount.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td class="form-group">
                                        <mat-form-field>
                                            <input matInput formControlName="notes" required placeholder="Discount Note"
                                                autocomplete="off">
                                            <mat-error
                                                *ngIf="discountForm.controls.notes.touched && discountForm.controls.notes.errors">
                                                <span *ngIf="discountForm.controls.notes.errors.required">
                                                    Discount note is required.
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td class="form-group">
                                        <button class="btn btn-default btn-sm btnAnimation" (click)="addDiscount()">
                                            <i class="far fa-check"></i>
                                        </button>
                                        <button class="btn btn-default btn-sm btnAnimation"
                                            (click)="cancelAddDiscount()" nbTooltip="Cancel">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-12 text-right mt-3">
            <button class="btn btn-primary btn-sm mr-2"
                (click)="openLineItemExclusion()">{{lineItemButtonText}}</button>
            <button class="btn btn-primary btn-sm mr-2" *ngIf="isEdit" (click)="openServiceExclusionList()">Edit Service
                Exceptions</button>
        </div> -->
    </nb-card-body>
</nb-card>