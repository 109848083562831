<h1 mat-dialog-title>Delete Confirmation</h1>
<div mat-dialog-content>
    <div>
    Are you sure you want to delete the application for {{data.companyName}}?
    </div>
    <br>
    <div >
        <mat-form-field>
            <mat-label>Reason for deleting application</mat-label>
            <textarea matInput maxlength="224" placeholder="Please state the reason for the Deletion" [formControl]="reasonForApplicationDelete"></textarea>
            <!-- <mat-error *ngIf="handleError('note', 'required')">
                Note is required.
            </mat-error> -->
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <!-- <button mat-button [mat-dialog-close]="data.companyName" cdkFocusInitial>Ok</button> -->
    <button mat-button (click)="deleteProviderApplication()">Delete Provider</button> 
</div>