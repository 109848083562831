import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { UserService } from 'src/app/services/user.service';
import { ProviderInvites } from 'src/app/models/provider-invites.model';
import { forkJoin } from 'rxjs';
import { patterns } from 'src/assets/patterns';

@Component({
  selector: 'ngx-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit {

  @ViewChild('csvReader', { static: true }) csvReader: any;
  csvRecords: any;
  insertMultipleServiceProvider: any = [];
  loading: boolean;
  fileData: File = null;
  userKey: string;
  providerKey: string;
  userTypeKey: string;
  emailRegex: any;
  mobRegex: any;
  notLoadedRecords = [];

  constructor(
    public dialog: MatDialogRef<UploadFileDialogComponent>,
    public dialogRef: MatDialog,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: NbToastrService,
    private fileUploadService: FileUploadService) { }

  ngOnInit() {
    this.userKey = sessionStorage.getItem('userKey');
    this.providerKey = sessionStorage.getItem('providerKey');
    this.userTypeKey = 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4';
    this.emailRegex = new RegExp(patterns.emailPattern);
    this.mobRegex = new RegExp(patterns.mobnumPattern);
  }

  uploadFiles() {
    if (this.fileData === undefined || this.fileData == null) {
      this.toast.warning('Please Select File', 'Error');
      return false;
    } else {
      this.loading = true;
      // return false;
      this.userService.addUserInvitationsBatch(this.insertMultipleServiceProvider).subscribe((res) => {
        this.loading = false;
        this.toast.success(res.message[0], 'Success');
      }, (error) => {
        this.loading = false;
      });
      this.dialog.close(this.notLoadedRecords);
    }
  }

  onFileSelectedMethod(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

    const files = fileInput.srcElement.files;
    const isCSVFile = this.fileUploadService.isCSVFile(files[0]);
    if (isCSVFile) {
      const input = fileInput.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = (data) => {
        const csvData: any = reader.result;
        const csvRecordsArray = csvData.split(/\r\n|\n/);
        if (csvRecordsArray[1] === '') {
          this.toast.warning('File is empty', 'Error');
          this.fileUploadService.fileReset(this.csvReader);
          return false;
        }
        const headersRow = this.fileUploadService.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = () => {
        this.toast.warning('error occured while reading file!', 'Error');
      };
    } else {
      this.toast.warning('Please import valid .csv file.', 'Error');
      this.fileUploadService.fileReset(this.csvReader);
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const tmpEmails: any = [];
    let recordCount = 0;
    const totalRecordInserted = csvRecordsArray.length - 2;
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const inviteExpDate = new Date();
    inviteExpDate.setDate(inviteExpDate.getDate() + 30);

    this.loading = true;
    csvRecordsArray.forEach((element, index) => {
      const providerInvites: ProviderInvites = new ProviderInvites();
      const data = element.split(',');

      if (data.length === headerLength) {
        if (index !== 0) {
          const description = data[4].trim();
          const objParams = {
            userTypeKey: 'c9dc716d-b1fe-45e6-8cd7-78a7c21c8fd4',
            description: description,
            deleted: false,
          };
          const userEmailExists = this.userService.checkEmailInUserInvitations({ email: data[2].trim(), deleted: false });
          const securityGroupsReq = this.userService.getSecurityGroups(objParams);
          forkJoin([securityGroupsReq, userEmailExists]).subscribe(result => {
            recordCount = recordCount + 1;
            const isValidEmail = this.emailRegex.test(data[2].trim());
            const isValidCellPhone = this.mobRegex.test(data[3].trim());

              providerInvites.firstName = data[0].trim();
              providerInvites.lastName = data[1].trim();
              providerInvites.email = data[2].trim();
              providerInvites.cellPhone = data[3].trim() ? ('+1' + data[3].trim()) : '+19999999999';
              providerInvites.securityGroupKey = result[0].body.length > 0 ? result[0].body[0].securityGroupKey : null;
              providerInvites.associationKey = this.providerKey;
              providerInvites.userTypeKey = this.userTypeKey;
              providerInvites.deleted = false;
              providerInvites.insertedUserKey = this.userKey;
              providerInvites.systemDefault = true;
              providerInvites.updatedUserKey = '';
              providerInvites.emailResponseDate = null;
              providerInvites.emailDate = null;

            if (result[0].body.length > 0 && isValidEmail &&
              !tmpEmails.find(x => x.email === data[2].trim()) && result[1].body.length === 0 &&
              data[0].trim() && data[1].trim() && data[2].trim() && (data[3].trim() ? isValidCellPhone : true) &&
              data[4].trim()) {
              this.insertMultipleServiceProvider.push(providerInvites);
              tmpEmails.push({
                email: data[2].trim(),
              });
            } else {
              this.notLoadedRecords.push(providerInvites);
            }

            if (totalRecordInserted === recordCount) {
              this.loading = false;
            }
          }, (error) => {
          });
        }
      }
    });
    return this.insertMultipleServiceProvider;
  }

  closeDialog() {
    this.dialog.close();
  }
}
