import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ServiceThresholdsComponent } from '../service-thresholds/service-thresholds.component';
import { UsersService } from 'src/app/services/users.service';
import { AddLimitsComponent } from '../add-limits/add-limits.component';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDeleteComponent } from 'src/app/commons/confirm-delete/confirm-delete.component';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-threshold-list-dialog',
  templateUrl: './threshold-list-dialog.component.html',
  styleUrls: ['./threshold-list-dialog.component.scss']
})
export class ThresholdListDialogComponent implements OnInit {
  serviceThresholds = [];
  loading = false;
  userDetails: any;
  disableEdit: boolean;
  tempServiceThreshold: any;
  OperatingSchedules = [];
  licensedOwnerOfferingKey: string;
  customerAuthorization = false;

  constructor(private usersService: UsersService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ServiceThresholdsComponent>,
              public dialog: MatDialog,
              private commonService: CommonService,
              private dialogService: NbDialogService) { }

  ngOnInit() {
    this.userDetails = this.data.userDetails;
    this.customerAuthorization = this.userDetails.customerAuthorization;
    this.licensedOwnerOfferingKey = this.data.data.licensedOwnerOfferingKey;
    this.getOperatingSchedules();
    this.getServiceExceptionData();
  }

  getServiceExceptionData() {
    this.loading = true;
    const overallLimitsFilter = {
      customerKey: this.userDetails.associationKey,
      userKey: this.userDetails.userKey,
      overallLimits: false
    };
    this.usersService.getAuthorizationThresholdsDetails(overallLimitsFilter).subscribe(res => {
      if (res.success) {
        this.serviceThresholds = res.body;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  getOperatingSchedules() {
    this.commonService.getOperatingSchedules().subscribe(res => {
      this.OperatingSchedules = res.body;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  update(serviceThreshold) {
      if (serviceThreshold.minimumThreshold === '' || serviceThreshold.quantityMinimum === '') {
        return false;
      } else {
        const data = {
          authorizationThresholdKey: serviceThreshold.authorizationThresholdKey,
          operatingScheduleKey: serviceThreshold.operatingScheduleKey,
          minimumThreshold: serviceThreshold.minimumThreshold,
          maximumThreshold: serviceThreshold.maximumThreshold,
          quantityMinimum: serviceThreshold.quantityMinimum,
          quantityMaximum: serviceThreshold.quantityMaximum
        };
        this.usersService.updateAuthorizationThresholds(data).subscribe(res => {
          this.loading = false;
          this.getServiceExceptionData();
      }, (error) => {
        console.log(error);
        this.loading = false;
    });
  }
  }

  delete(serviceThreshold) {
    this.dialogService.open(ConfirmDeleteComponent).onClose.subscribe((isDelete) => {
      if (isDelete) {
        const data = {
          authorizationThresholdKey: serviceThreshold.authorizationThresholdKey,
          deleted: true
        };
        this.loading = true;
        this.usersService.updateAuthorizationThresholds(data).subscribe(res => {
          this.loading = false;
          this.getServiceExceptionData();
        }, (error) => {
          console.log(error);
          this.loading = false;
        });
      }
    });
  }

  edit(index: number) {
    this.tempServiceThreshold = { ...{}, ...this.serviceThresholds[index] };
    this.serviceThresholds[index].editMode = !this.serviceThresholds[index].editMode;
    this.disableEdit = !this.disableEdit;
  }

  cancel(index: number) {
    this.serviceThresholds[index].editMode = !this.serviceThresholds[index].editMode;
    this.disableEdit = !this.disableEdit;
    this.serviceThresholds[index] = this.tempServiceThreshold;
  }

  addThreshold(isOverallLimit) {
    const dialogRef = this.dialog.open(AddLimitsComponent, {
      hasBackdrop: true,
      width: '70%',
      position: {
        top: '5%',
      },
      data: {
        licensedOwnerOfferingKey: this.licensedOwnerOfferingKey,
        isOverallLimit,
        userDetails: this.userDetails
      }
    });

    dialogRef.afterClosed().subscribe((reponse) => {
      this.getServiceExceptionData();
    });
  }

  close() {
    this.dialogRef.close();
  }

}
