import { Component, OnInit, ViewChild, Inject, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HierarchyDialogsComponent } from 'src/app/dialogs/hierarchy-dialogs/hierarchy-dialogs.component';
import { SortableHeaderDirective, SortEvent } from 'src/app/shared/directive/sortable.directive';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-customer-search-dialog',
  templateUrl: './customer-search-dialog.component.html',
  styleUrls: ['./customer-search-dialog.component.scss']
})
export class CustomerSearchDialogComponent implements OnInit {

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  customerDetails: any;
  ultimateParentkey: string;
  loading = false;
  customerList: any = [];
  eventCustomer = new EventEmitter();

  constructor(
    private customerService: CustomerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomerSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.customerDetails = this.data;
    this.search();
  }

  search() {
    let req;
    let filter: any;
    if (this.customerDetails.customerProfile) {
      filter = {
        customerKey: sessionStorage.getItem('customerKey'),
        searchtext: this.customerDetails.searchText
      };
      // get nth level customer hierarchy
      req = this.customerService.getCustomerHierarchy(filter);
    } else {
      filter = {
        licensedOwnerKey: sessionStorage.getItem('licensedOwnerKey'),
        searchText: this.customerDetails.searchText,
        ultimateFilter: 0,
        ultimateParentKey: this.customerDetails.ultimateParentkey
      };
      req = this.customerService.getCustomersWithUltimateParent(filter);
    }

    this.loading = true;
    req.subscribe(res => {
        if (res.success) {
          this.customerList = res.body;
          this.customerList.map(obj => obj.state = obj.state ? obj.state : '');
          this.onSort({ column: 'customerName', direction: 'asc' });
        }
        this.loading = false;
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
  }

  getCustomerAdminDetails(data) {
    this.eventCustomer.emit(data);
    this.closeDialog();
  }

  viewHierarchy(data) {
    const dialogRef = this.dialog.open(HierarchyDialogsComponent, {
      width: '40%',
      disableClose: true,
      position: {
        top: '4%',
      },
      data: { customerKey: data.customerKey, isCustomerHierarchy: true }
    });

    dialogRef.componentInstance.eventCustomerHierarchy.subscribe(value => {
      this.eventCustomer.emit(value);
      this.dialogRef.close();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSort({ column, direction }: SortEvent) {
    this.customerList = this.utilityService.sortData(this.headers, column, direction, this.customerList);
  }

}
