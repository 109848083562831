import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Technician } from '../models/technician.model';
import { TechnicianSkills } from '../models/technician-skills.model';
@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  constructor(
    private http: HttpClient
  ) { }
  getProviders(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providers/get`, data);
  }

  getTechnicians(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.baseUrl}api/providerTechniciansSelect`, data).pipe(
      map((res: any) => res.body.map((technician: Technician) => new Technician().deserialize(technician))));
  }
  updateProviderTechnicians(data: any): Observable<any> {
    return this.http.put<any>(`${environment.baseUrl}api/providerTechniciansUpdate`, data);
  }
  updateProviderTechnicianSkills(data: any = {}): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}serviceplus/providertechnicianskills`, data)
  }
  getProviderTechnicianSkills(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providertechnicianskills/get`, data)
  }
  addProviderTechnicianSkills(data: any = {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providertechnicianskills`, data)
  }
  addProviderTechnicians(data: any): Observable<any> {
    // return this.http.post<any>(`${environment.apiUrl}serviceplus/providertechnicians`, data)
    return this.http.post<any>(`${environment.baseUrl}api/providerTechniciansInsert`, data);
  }
  deleteProviderTechnicianSkills(data: any = {}): Observable<any> {
    const deleteTechnicalSkill: any = {
      body: { providerTechnicianSkillKey: data }
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providertechnicianskills`, deleteTechnicalSkill)
  }
  getSkills(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/skills/get`, data).pipe(
      map((res: any) => res.body.map((technicianSkills: TechnicianSkills) => new TechnicianSkills().deserialize(technicianSkills))));
  }
}
