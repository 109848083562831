<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="modal-header">
    <h6 class="modal-title">{{ title }}</h6>
    <button type="button" class="close" (click)="closeDialog()" nbTooltip="close"
        nbTooltipPlacement="top">&times;</button>
</div>
<div class="modal-body">
    <form [formGroup]="nagForm">
        <div class="row m-0">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-9">
                        Status: {{ nagForm.controls.status.value ? 'Active': 'Deactive' }}
                    </div>
                    <div class="col-md-3 text-right">
                        <button type="button" class="btn btn-sm btn-primary"
                            (click)="updateStatus()">
                            {{ nagForm.controls.status.value ? 'Deactivate' : 'Activate' }}
                        </button>
                    </div>
                    <div class="col-md-12 mt-1">
                        <mat-form-field appearance="outline">
                            <mat-label>Provider Name</mat-label>
                            <mat-select formControlName="providerName" matNativeControl placeholder="Provider Name">
                                <mat-option disabled="true">Select Provider Name</mat-option>
                                <mat-option *ngFor="let provider of providers" [value]="provider.name">
                                    {{ provider.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>NAG User</mat-label>
                            <mat-select formControlName="userName" matNativeControl placeholder="NAG User">
                                <mat-option disabled="true">Select NAG User</mat-option>
                                <mat-option *ngFor="let user of users" [value]="user.name">
                                    {{ user.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Service Group</mat-label>
                            <mat-select formControlName="serviceGroupKey" matNativeControl placeholder="Service Group">
                                <mat-option disabled="true">Select Service Group</mat-option>
                                <mat-option *ngFor="let serviceGroup of serviceGroups"
                                    [value]="serviceGroup.description">
                                    {{ serviceGroup.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-8">
                        Physical Address
                    </div>
                    <div class="col-md-4 text-right">
                        <button *ngIf="nagForm.controls.physicalAddress.disabled"
                            class="btn btn-sm btn-default btn-sm btnAnimation" (click)="editAddress()" nbTooltip="Edit">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button *ngIf="!nagForm.controls.physicalAddress.disabled"
                            class="btn btn-sm btn-default btn-sm btnAnimation" (click)="cancelAddress()"
                            nbTooltip="Cancel">
                            <i class="fa fa-times"></i>
                        </button>
                        <button *ngIf="!nagForm.controls.physicalAddress.disabled" class="btn btn-default btnAnimation"
                            nbTooltip="Update" (click)="saveAddress()">
                            <i class="fas fa-check"></i>
                        </button>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Physical Address</mat-label>
                            <input matInput formControlName="physicalAddress" placeholder="Physical Address">
                        </mat-form-field>
                    </div>
                    <div class="col-md-8">
                        Contact Information
                    </div>
                    <div class="col-md-4 text-right">
                        <button *ngIf="nagForm.controls.phone.disabled"
                            class="btn btn-sm btn-default btn-sm btnAnimation" (click)="editContact()" nbTooltip="Edit">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button *ngIf="!nagForm.controls.phone.disabled"
                            class="btn btn-sm btn-default btn-sm btnAnimation" (click)="cancelContact()"
                            nbTooltip="Cancel">
                            <i class="fa fa-times"></i>
                        </button>
                        <button *ngIf="!nagForm.controls.phone.disabled" class="btn btn-default btnAnimation"
                            nbTooltip="Update" (click)="saveContact()">
                            <i class="fas fa-check"></i>
                        </button>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Phone</mat-label>
                            <input matInput mask="000-000-0000" formControlName="phone" placeholder="Phone">
                            <mat-error *ngIf="handleError('phone', 'pattern')">
                                Enter valid Phone.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" placeholder="Email">
                            <mat-error *ngIf="handleError('email', 'pattern')">
                                Enter valid Email.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h6>Select Country, State or County</h6>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Country</mat-label>
                            <mat-select formControlName="country" matNativeControl placeholder="Country">
                                <mat-option disabled="true">Select Country</mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.countryKey">
                                    {{ country.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select formControlName="stateKey" matNativeControl placeholder="State">
                                <mat-option disabled="true">Select State</mat-option>
                                <mat-option *ngFor="let state of states" [value]="state.stateKey">
                                    {{ state.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Select Counties</mat-label>
                            <mat-select formControlName="countyKey" matNativeControl placeholder="Select Counties">
                                <mat-option disabled="true">Select Counties</mat-option>
                                <mat-option *ngFor="let county of counties" [value]="county.countyKey">
                                    {{ county.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <label>Alternatively, please click and select your counties on the interactive map
                            below.</label>
                    </div>
                    <div class="col-md-12">
                        <highcharts-chart id="container" [Highcharts]="Highcharts" [constructorType]="chartConstructor"
                            [options]="chartOptions">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-md-12 text-right">
            <button type="button" class="btn btn-sm btn-primary" (click)="closeDialog()">
                Save
            </button>
        </div>
    </div>
</div>