<div class="modal-header">
  <h6 class="modal-title">{{modalTitle}}</h6>
  <button class="close"
          type="button"  
          (click)="closeDialog()" 
          nbTooltip="Close"
          nbTooltipPlacement="top">&times;
  </button>
</div>

<div class="modal-body">
  <!-- Customer Admin -->
  <!-- <ng-container *ngIf="platForm == 'FleetAssist'">
        <app-authorization *ngIf="selectedIndex === 0" [customerInputKey]="customerKey" [customerAdmin]="true">
        </app-authorization>
        <app-hours-of-operations [customerInputKey]="customerKey" *ngIf="selectedIndex === 1"></app-hours-of-operations>
        <app-customer-users-list [customerInputKey]="customerKey" [customerAdmin]="true" *ngIf="selectedIndex === 2">
        </app-customer-users-list>
        <app-customer-asset-dialog [customerInputKey]="customerKey" *ngIf="selectedIndex === 3">
        </app-customer-asset-dialog>
        <app-customer-note [customerInputKey]="customerKey" [userType]="userType" *ngIf="selectedIndex === 4">
        </app-customer-note>
        <app-national-accounts [customerInputKey]="customerKey" *ngIf="selectedIndex === 5"></app-national-accounts>
    </ng-container> -->

  <!-- Provider Management -->
  <ng-container *ngIf="platForm == 'ServiceProvider'">
    <app-provider-management-billing 
                  *ngIf="selectedIndex === 0" 
                   [isMasterLicensedOwner]="isMasterLicensedOwner" 
                   [providerInputKey]="providerKey">
    </app-provider-management-billing>

    <app-provider-management-dispatch 
                  *ngIf="selectedIndex === 1" 
                   [isMasterLicensedOwner]="isMasterLicensedOwner" 
                   [providerInputKey]="providerKey">
    </app-provider-management-dispatch>

    <app-provider-management-customer-preferences 
                  *ngIf="selectedIndex === 4" 
                   [providerInputKey]="providerKey" >
    </app-provider-management-customer-preferences>

    <app-provider-management-national-accounts 
                  *ngIf="selectedIndex === 2" 
                   [isMasterLicensedOwner]="isMasterLicensedOwner"
                   [providerInputKey]="providerKey" >
    </app-provider-management-national-accounts>

    <app-provider-management-hrs-of-operation 
                  *ngIf="selectedIndex === 3" 
                   [isMasterLicensedOwner]="isMasterLicensedOwner"
                   [providerInputKey]="providerKey">
    </app-provider-management-hrs-of-operation>

    <app-provider-management-users 
                  *ngIf="selectedIndex === 5" 
                   [isMasterLicensedOwner]="isMasterLicensedOwner" 
                   [providerInputKey]="providerKey">
    </app-provider-management-users>

    <app-provider-capabilities               
                  *ngIf="selectedIndex === 6" 
                   [providerInputKey]="providerKey" >
    </app-provider-capabilities>

    <app-provider-technical-expertise 
                  *ngIf="selectedIndex === 7" 
                   [providerInputKey]="providerKey" >
    </app-provider-technical-expertise>
  </ng-container>
</div>