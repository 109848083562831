import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalVariables } from '../../assets/global_variables';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  constructor(
    private http: HttpClient,
  ) { }

  getProviderDetails(data: any = {}): Observable<any> {
    if (data.deleted === undefined) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providers/get`, data);
  }

  updateProviderDetails(dispatchData): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/providers`, dispatchData);
  }

  updateProviderChildDetails(childData): Observable<any> {
    return this.http.put(`${environment.apiUrl}serviceplus/providers/batch`, childData);
  }

  addDefaultProvider(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providers`, data);
  }

  getPreferredCustomerAndDND(providerKey) {
    return this.http.get(`${environment.baseUrl}getPreferredCustomerAndDND/${providerKey}`);
  }

  getNationalAccounts(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}keyvaluepairs/nationalaccounts/get`, data);
  }

  getProviderNationalAccounts(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providernationalaccounts/get`, data);
  }

  addNationalAccounts(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}serviceplus/providernationalaccounts`, data);
  }

  deleteNationalAccounts(data): Observable<any> {
    const json: any = {
      'providerNationalAccountKey': data.providerNationalAccountKey,
    };
    const deleteNationalAccountsData: any = {
      body: json,
    };
    return this.http.delete<any>(`${environment.apiUrl}serviceplus/providernationalaccounts`,
      deleteNationalAccountsData);
  }

  updateProfilePicture(updatedData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}uploadFile`, updatedData);
  }

  deleteProfilePicture(fileName: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}deleteFile`, fileName);
  }

  getDispatchStatus(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/providerdispatchstatuses/get`, data);
  }

  getPaymentTerms(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/paymentterms/get`, data);
  }

  getPaymentMethods(data: any = {}): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/paymentmethods/get`, data);
  }

  // delete security group
  deleteSecurityGroups(securityGroupKey: string) {
    const deleteSecurityGroupsData: any = {
      body: { securityGroupKey: securityGroupKey },
    };
    return this.http.delete<any>(`${environment.apiUrl}systemsecurities/usersecuritygroups`, deleteSecurityGroupsData);
  }

  getUsersTimezone(geocode) {
    return this.http.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${geocode.lat},${geocode.lng}&timestamp=1458000000&key=${globalVariables.googleMapApiKey}`);
  }

  // get child exist for parent
  getChildExist(arrData: any = {}): Observable<any> {
    if (!arrData.deleted) {
      arrData.deleted = false;
    }
    return this.http.post(`${environment.apiUrl}serviceplus/providers/get`, arrData);
  }

  getAddressDetails(address) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${globalVariables.googleMapApiKey}`);
  }

  // get locations of provider
  getProviderLocations(data): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}getGetLocationsForProvider`, data);
  }

  getproviderdispatchsoftwares(data): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/providerdispatchsoftwares/get`, data);
  }

  insertproviderdispatchsoftwares(data): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}keyvaluepairs/providerdispatchsoftwares`, data);
  }

  getProviderTermsConditions(data): Observable<any> {
    if (!data.deleted) {
      data.deleted = false;
    }
    return this.http.post<any>(`${environment.apiUrl}serviceplus/providertermsconditions/get`, data);
  }

}
