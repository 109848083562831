<div class="modal-header">
    <h6>Service Threshold</h6>
    <button type="button" class="close" (click)="close()" nbTooltip="close" nbTooltipPlacement="top">&times;</button>
</div>
<div class="row">
    <div class="col-12 col-md-11">
        <app-bubble-user-information-index [showThresholdIndexs]="true"></app-bubble-user-information-index>
    </div>
</div>
<div class="row hiddenServicesScroll">
    <div class="col-sm-12">
        <div *ngIf="loading" id="preloader">
            <div id="loader"></div>
        </div>
        <div class="tree-block">
            <div class="tree-pane">
                <div class="tree-paning-container">
                    <div class="tree-roots-elements">
                        <bubble-tree-ui [isLicensedOwnerProtocol]="true" [showThreshold]="true" [userDetails]="userDetails"
                            [showSelectDeselect]="false" [isServiceThreshold]="true" [levelData]="serviceData" [level]="level" [addNode]="addNode"
                            [editNode]="editNode" [delNode]="delNode" [isActiveNode]="isActiveNode"
                            [disableDragDrop]="disableDragDrop" [lastNode]="lastNode" [isSelected]="isSelected"
                            [isAllParentActive]="1">
                        </bubble-tree-ui>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>