<h1 mat-dialog-title>Add New Provider Application</h1>
<div mat-dialog-content [formGroup]="providerApplicationForm">
    <mat-form-field>
        <mat-label>Company Name</mat-label>
        <input matInput formControlName="companyName">
    </mat-form-field>

    <div style="display: flex;">
        <mat-form-field class="col-4 pl-0">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="ownerFirstName">
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="ownerLastName" >
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>Phone Number</mat-label>
            <input type="tel" minlength="10" maxlength="10" matInput formControlName="phoneNumber"> 
            <span matTextPrefix>+1&nbsp;</span>
        </mat-form-field>
    </div>

    <div style="display: flex;">
        <mat-form-field class="col-6 pl-0">
            <mat-label>Email Address</mat-label>
            <input type="email" matInput formControlName="emailAddress" >
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>FEIN Number</mat-label>
            <input matInput minlength="10" maxlength="10" formControlName="einNumber" (keyup)="FEINFormat($event)" >
        </mat-form-field>
    </div>

    <mat-form-field>
        <mat-label>Service Provider Location</mat-label>
        <input matInput ngx-google-places-autocomplete formControlName="providerAddress" #placesRef="ngx-places" (onAddressChange)="addressChanged($event)"/>
    </mat-form-field>

    <div style="display: flex;">
        <mat-form-field class="col-6 pl-0">
            <mat-label>Address Line 1</mat-label>
            <input matInput formControlName="address1">
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>Address Line 2</mat-label>
            <input matInput formControlName="address2" >
        </mat-form-field>
    </div>

    <div style="display: flex;">
        <mat-form-field class="col-4 pl-0">
            <mat-label>City</mat-label>
            <input type="text" matInput formControlName="city">
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-select placeholder="State*" formControlName="state" 
            matNativeControl>
            <mat-option></mat-option>
            <mat-option *ngFor="let state of statesData | orderBy:'state'"
                [value]="state.stateKey">
                {{state.stateDescription}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>Zip Code</mat-label>
            <input matInput formControlName="postalCode">
        </mat-form-field>
    </div>

    <div style="display: flex;">
        <mat-form-field class="col-4 pl-0">
            <mat-label>County</mat-label>
            <input type="text" matInput formControlName="county">
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-label>Country</mat-label>
            <input type="text" matInput formControlName="country" >
        </mat-form-field>
        <mat-form-field class="col-4">
            <mat-select placeholder="Service Category*" formControlName="category" 
            matNativeControl>
                <mat-option></mat-option>
                <mat-option value='Auxiliary'>Auxiliary</mat-option>
                <mat-option value='OEM'>OEM</mat-option>
                <mat-option value='Police Provider'>Police Provider</mat-option>
                <mat-option value='Tire Dealer'>Tire Dealer</mat-option>
                <mat-option value='Truckstop'>Truckstop</mat-option>
                <mat-option value='Web Lead'>Web Lead</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <!-- <div class="row">
        <div class="col-4">
            <label> Add As Police Provider </label>
            <mat-checkbox>
               
            </mat-checkbox>
        </div>
        <div class="col-4">
            <label> Add To Auxilium </label>
            <mat-checkbox  >
           
            </mat-checkbox>
        </div>
   
    </div> -->

</div>    

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button (click)="submitApplication()" cdkFocusInitial>Ok</button>
</div>

