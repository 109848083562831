<div *ngIf="loading" id="preloader">
    <div id="loader"></div>
</div>
<div class="container my-2 px-1">
    <div class="row">
        <div *ngIf="!isCustomer" class="col col-md-8">
            <h6>{{licensedOwnerName}} Invite Users</h6>
        </div>
        <div [ngClass]="isCustomer ? 'offset-md-8' : ''" class="col-12 col-md-4 invite-history-search">
            <ngx-search-input (search)="search($event)"></ngx-search-input>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th class="text-center align-middle">
                        Select<br>
                        <mat-checkbox [(ngModel)]="selectedAll" (change)="selectAll()"></mat-checkbox>
                    </th>
                    <th sortable="firstName" (sort)="onSort($event)" class="align-middle">First Name</th>
                    <th sortable="lastName" (sort)="onSort($event)" class="align-middle">Last Name</th>
                    <th sortable="email" (sort)="onSort($event)" class="align-middle">Email</th>
                    <th sortable="cellPhone" (sort)="onSort($event)" class="align-middle">Cell Phone</th>
                    <th sortable="departmentKey" (sort)="onSort($event)" class="align-middle" *ngIf="!isCustomer">
                        Department</th>
                    <th sortable="supervisor" (sort)="onSort($event)" class="align-middle" *ngIf="!isCustomer">
                        Supervisor</th>
                    <th sortable="contactCenterUserRoleKey" (sort)="onSort($event)" class="align-middle"
                        *ngIf="!isCustomer">Role</th>
                    <th sortable="description" (sort)="onSort($event)" class="align-middle">Security Group</th>
                    <th sortable="enteredBy" (sort)="onSort($event)" class="align-middle">Entered By</th>
                    <th sortable="emailDate" (sort)="onSort($event)" class="align-middle" direction="desc">Email Sent
                        Date</th>
                    <th class="align-middle">Response Date</th>
                    <th class="align-middle">View Email</th>
                </tr>
            </thead>
            <tbody *ngIf="userInvitesList.length > 0; else noData">
                <tr *ngFor="let user of userInvitesList | filter : searchString; let i = index">
                    <td class="text-center align-middle">
                        <mat-checkbox [(ngModel)]="user.selected" (change)="checkIfAllSelected(user)"></mat-checkbox>
                    </td>
                    <td>{{user.firstName}}</td>
                    <td>{{user.lastName}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.cellPhone}}</td>
                    <td *ngIf="!isCustomer">{{user.departmentKey}}</td>
                    <td *ngIf="!isCustomer">{{user.supervisor}}</td>
                    <td *ngIf="!isCustomer">{{user.contactCenterUserRoleKey}}</td>
                    <td>{{user.description}}</td>
                    <td>{{user.enteredBy}}</td>
                    <td>{{user.emailDate | date:"MM/dd/yyyy"}}</td>
                    <td>{{user.emailResponseDate | date:"MM/dd/yyyy"}}</td>
                    <td>
                        <button class=" btn btn-default btnAnimation" nbTooltip="info" (click)="openEmailDetails(user)">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <ng-template #noData>
                    <tr>
                        <td *ngIf="!isCustomer" class="text-center" colspan="14">No data available.</td>
                        <td *ngIf="isCustomer" class="text-center" colspan="11">No data available.</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>