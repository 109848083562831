import { Component, OnInit, Input, Inject } from '@angular/core';
// import { PanZoomConfig } from 'ng2-panzoom';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BubbleService } from 'src/app/services/bubble.service';
import { AddEditNetworkDialogComponent } from 'src/app/dialogs/add-edit-network-dialog/add-edit-network-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { UtilityService } from 'src/app/services/utility.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-service-thresholds',
  templateUrl: './service-thresholds.component.html',
  styleUrls: ['./service-thresholds.component.scss']
})
export class ServiceThresholdsComponent implements OnInit {
  loading: boolean;
  serviceData: any = [];

  // Params required for bubble tree
  expandLayer: any = [];
  level = 0;
  addNode = false; // If false do not show add button on node.
  editNode = false; // If false do not show edit button on node.
  delNode = false; // If false do not show delete button on node.
  isActiveNode = true; // If false do not show active/inactive checkbox on node.
  disableDragDrop = true; // If true disable Drag and Drop.
  lastNode = 4; // If -1 then bubble tree can go upto n layers
  isSelected = -1; // If -1 then no node will have select option
  flagHideButtons = false;
  userDetails: any;

  constructor(public dialog: MatDialog,
              private oauthService: OAuthService,
              private data: BubbleService,
              private route: ActivatedRoute,
              private usersService: UsersService,
              private utilityService: UtilityService,
              @Inject(MAT_DIALOG_DATA) public details: any,
              public dialogRef: MatDialogRef<ServiceThresholdsComponent>) { }

  ngOnInit() {
    this.userDetails = this.details.userDetails;
    this.route.paramMap.subscribe(params => {
      // tslint:disable-next-line: no-string-literal
      if (params['params'].flag === 1) {
        this.flagHideButtons = true;
      }
    });

    this.addNode = this.flagHideButtons;
    this.editNode = this.flagHideButtons;
    this.delNode = this.flagHideButtons;
    this.disableDragDrop = !this.flagHideButtons;

    localStorage.removeItem('serviceNetworkThreshold');
    sessionStorage.removeItem('expandedLayer');

    const licenesedOwnerKey = sessionStorage.getItem('licensedOwnerKey');

    this.getLicensedOwnerOfferings(licenesedOwnerKey);
    sessionStorage.setItem('expandedLayer', JSON.stringify(this.expandLayer));

    this.data.getBubbleTreeData().subscribe(() => {
      this.serviceData = JSON.parse(localStorage.getItem('serviceNetworkThreshold'));
    });
  }

  getLicensedOwnerOfferings(licenesedOwnerKey) {
    this.loading = true;
    const data = {
      associationKey: this.userDetails.associationKey,
      licensedOwnerKey: licenesedOwnerKey,
      userKey: this.userDetails.userKey
    };
    this.usersService.getLicensedOwnerOfferingsThreshold(data).subscribe(result => {
      this.serviceData = result;

      if (localStorage.getItem('serviceNetworkThreshold') == null || localStorage.getItem('serviceNetworkThreshold') === undefined) {
        localStorage.setItem('serviceNetworkThreshold', JSON.stringify(this.serviceData));
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
