export class LicensedOwnerProtocol {
    public customerProtocolKey: string;
    public customerKey: string;
    public licensedOwnerKey: string;
    public callerTypeKey: string;
    public licensedOwnerOfferingKey: string;
    public supported: boolean;
    public operatingScheduleKey: string;
    public systemDefault: boolean;
    public offerings: string;
    public updatedBy: string;
    public updatedTimestamp: string;

    constructor(data: any) {
      this.customerProtocolKey = data.customerProtocolKey;
      this.customerKey = data.customerKey;
      this.offerings = data.offerings;
      this.licensedOwnerKey = data.licensedOwnerKey;
      this.callerTypeKey = data.callerTypeKey;
      this.licensedOwnerOfferingKey = data.licensedOwnerOfferingKey;
      this.supported = data.supported;
      this.operatingScheduleKey = data.operatingScheduleKey;
      this.systemDefault = data.systemDefault;
      this.updatedBy = data.updatedBy;
      this.updatedTimestamp = data.updatedTimestamp;
    }
  }
